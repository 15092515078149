import { createAction } from 'utils/createAction';

import * as Types from './types';
// Ban Geomap Get Actions
export const banGeomapGetRequest = createAction(Types.BAN_GEOMAP_GET_REQUEST);
export const banGeomapGetSuccess = createAction(Types.BAN_GEOMAP_GET_SUCCESS);
export const banGeomapGetError = createAction(Types.BAN_GEOMAP_GET_ERROR);
// Ban Geomap Get District Actions
export const banGeomapGetDistrictError = createAction(
  Types.BAN_GEOMAP_GET_DISTRICT_ERROR,
);

export const banGeomapGetDistrictRequest = createAction(
  Types.BAN_GEOMAP_GET_DISTRICT_REQUEST,
);

export const banGeomapGetDistrictSuccess = createAction(
  Types.BAN_GEOMAP_GET_DISTRICT_SUCCESS,
);
// Ban Geomap Get Vendors Actions
export const banGeomapGetVendorsRequest = createAction(
  Types.BAN_GEOMAP_GET_VENDORS_REQUEST,
);

export const banGeomapGetVendorsError = createAction(
  Types.BAN_GEOMAP_GET_VENDORS_ERROR,
);

export const banGeomapGetVendorsSuccess = createAction(
  Types.BAN_GEOMAP_GET_VENDORS_SUCCESS,
);

// Ban Geomap Set Selected District
export const banGeomapSetSelectedDistrict = createAction(
  Types.BAN_GEOMAP_SET_SELECTED_DISTRICT,
);

// Ban Geomap Get Neighbourhood Actions
export const banGeomapGetNeighbourhoodError = createAction(
  Types.BAN_GEOMAP_GET_NEIGHBOURHOOD_ERROR,
);

export const banGeomapGetNeighbourhoodRequest = createAction(
  Types.BAN_GEOMAP_GET_NEIGHBOURHOOD_REQUEST,
);

export const banGeomapGetNeighbourhoodSuccess = createAction(
  Types.BAN_GEOMAP_GET_NEIGHBOURHOOD_SUCCESS,
);
// Ban Geomap Set Neighbourhood is Visible
export const setNeighbourhoodisVisible = createAction(
  Types.SET_NEIGHBOURHOOD_IS_VISIBLE,
);

// Ban Geomap Set Show Only Selected Vendor
export const banGeomapSetShowOnlySelectedVendor = createAction(
  Types.BAN_GEOMAP_SET_SHOW_ONLY_SELECTED_VENDOR,
);
// Ban Geomap Set Edited Map
export const banGeomapSetEditedMap = createAction(
  Types.BAN_GEOMAP_SET_EDITED_MAP,
);

// Ban Geomap Set City
export const banGeomapSetCity = createAction(Types.BAN_GEOMAP_SET_CITY);
// Ban geomap set Selected Vendor
export const banGeomapSetSelectedVendor = createAction(
  Types.BAN_GEOMAP_SET_SELECTED_VENDOR,
);
// Ban geomap set Brand
export const banGeomapSetBrand = createAction(Types.BAN_GEOMAP_SET_BRAND);

// Ban geomap set Interval
export const banGeomapSetInterval = createAction(Types.BAN_GEOMAP_SET_INTERVAL);

// Ban geomap set Center
export const banGeomapSetCenter = createAction(Types.BAN_GEOMAP_SET_CENTER);

// Ban geomap reset state
export const banGeomapResetState = createAction(Types.BAN_GEOMAP_RESET_STATE);

// Ban geomap save
export const banGeomapSaveRequest = createAction(Types.BAN_GEOMAP_SAVE_REQUEST);
export const banGeomapSaveSuccess = createAction(Types.BAN_GEOMAP_SAVE_SUCCESS);
export const banGeomapSaveError = createAction(Types.BAN_GEOMAP_SAVE_ERROR);

// Ban geomap get banned vendors
export const banGeomapGetBannedVendorsRequest = createAction(
  Types.BAN_GEOMAP_GET_BANNED_VENDORS_REQUEST,
);
export const banGeomapGetBannedVendorsSuccess = createAction(
  Types.BAN_GEOMAP_GET_BANNED_VENDORS_SUCCESS,
);
export const banGeomapGetBannedVendorsError = createAction(
  Types.BAN_GEOMAP_GET_BANNED_VENDORS_ERROR,
);
