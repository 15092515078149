import { Table as AntdTable } from 'antd';
import styled from 'styled-components';

export const Table = styled(AntdTable)`
  .ant-table-content {
    border-radius: 8px;
    border: 1px solid #d4d4d4;
  }
  .ant-table-cell-fix-right {
    transition: all 0.3s ease-in-out;
    &:hover {
      z-index: 999;
    }
  }
  .ant-table-thead {
    tr {
      th {
        background: #f0f1f3 !important;
        /* font-style: normal; */
        padding: 24px 12px !important;
        font-weight: 400 !important;
        color: #191919 !important;
        font-size: 14px;
        line-height: 18px;
        width: auto !important;
        overflow-wrap: normal !important;
        white-space: nowrap !important;
        &::before {
          display: none !important;
        }
      }
    }
  }

  .ant-pagination-disabled {
  }

  .ant-pagination {
    li {
      height: 36px !important;
    }
    color: #5d3ebc !important ;
    svg {
      color: #5d3ebc !important ;
      fill: #5d3ebc !important ;
    }
    .ant-pagination-options {
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        color: #5d3ebc;
        font-size: 14px;
        font-weight: 600;
      }
    }
    .ant-pagination-item-active {
      a {
        color: #5d3ebc;
      }
      color: #5d3ebc;
    }
    .ant-pagination-jump-next {
      .ant-pagination-item-link {
        height: 100% !important;
        line-height: 36px !important;
      }
    }
    .ant-pagination-jump-prev {
      .ant-pagination-item-link {
        height: 100% !important;
        line-height: 36px !important;
      }
    }
    .ant-select {
      display: flex;
      height: 36px;
      padding: 0px 12px;
      align-items: center;
      border-radius: 8px;
      border: 1px solid #f3f0fe;
      background: #f3f0fe;
      .ant-select-selector {
        border: none !important;
        background: transparent !important;
      }
      .ant-select-focused {
        border: none !important;
        outline: none !important;
        box-shadow: none;
      }
    }

    .ant-pagination-item {
      border-radius: 8px;
      display: flex;
      width: 36px;
      height: 36px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #f3f0fe;
      border: 0px;
      &:hover {
        a {
          color: #5d3ebc;
        }
      }
    }
  }

  .ant-pagination-prev {
  }
`;
