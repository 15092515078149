import { financialInfoTypesValues } from 'constants/financials';
import store from 'store/configureStore';

export const createRequestBody = (formData, isIndividual, vendorInfo) => {
  const {
    bankAccountName,
    email,
    firmManager,
    firmTitle,
    firmType,
    iban,
    identityNumber,
    openAddress,
    taxNumber,
    taxOffice,
    bank,
    managerPhoneNumber,
    financialInfoType,
    city,
    district,
    selectFirmVendor,
    vendorPhoneNumber,
  } = formData;
  const bankCodes = store.getState().financialInformation.bankCodes.data || [];

  const selectedBank = bank.value
    ? { id: String(bank.value), name: bank.label }
    : bankCodes.find(code => code.value === bank);

  const isReferenced = selectFirmVendor !== undefined;

  const data = {
    id: vendorInfo.id,
    financialInfoDetail: {
      ...(!isReferenced && {
        financialInfo: {
          bank: selectedBank.id
            ? selectedBank
            : { id: String(selectedBank.value), name: selectedBank.label },
          bankAccountName,
          email,
          firmManager,
          managerPhoneNumber,
          firmTitle,
          firmType: firmType.value ? firmType.value : firmType,
          iban,
          openAddress,
          taxOffice,
          town: district,
          city: city && city.value ? city.value : city,
          vendorPhone: vendorPhoneNumber,
          ...(isIndividual
            ? {
                identityNumber, // eslint-disable-line
              } // eslint-disable-line
            : { taxNumber }),
        },
      }),
      referenceId: selectFirmVendor,
      referencedType: financialInfoType,
      isReferenced,
      updateReferencedRecords:
        financialInfoType !== financialInfoTypesValues.NEW,
    },
  };

  return data;
};
