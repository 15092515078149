import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Empty, Space } from 'antd';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from '@getir/web-components';

import Layout from 'components/Layout';
import * as CS from 'common/style';
import * as ROUTES from 'constants/routes';
import * as BrandActions from 'containers/Stores/Brands/actions';

import * as RequestActions from '../actions';
import requestActionsData from '../constants/requestActions';
import { requestStatuses } from '../constants/requestStatuses';

import * as S from './style';
import { useColumns } from './useColumns';

const actionRow = {
  brandId: null,
  categoryId: null,
  oldPrice: null,
  newPrice: null,
};

const createNewProductList = products =>
  products
    .filter(product => product.brandId !== null)
    .map(item => ({
      newPrice: item.newPrice,
      productId: item.productId,
    }));

const BulkChange = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [products, setProducts] = useState([]);
  const [currentRequestId, setCurrentRequestId] = useState(undefined);
  const [currentRequestStatus, setCurrentRequestStatus] = useState(undefined);

  const brands = useSelector(state => state.brand.brands);

  useEffect(() => {
    const {
      parsedActions,
      requestId,
      requestStatus,
    } = history.location.state.data;
    const { productList } = parsedActions;
    setCurrentRequestId(requestId);
    setCurrentRequestStatus(requestStatus);
    if (requestStatus === requestStatuses.WAITING) {
      setProducts([...productList, actionRow]);

      return;
    }
    setProducts(productList);
  }, [history.location.state]);

  useEffect(() => {
    if (brands === undefined) dispatch(BrandActions.brandListRequest());
  }, [dispatch, brands]);

  const setPriceData = (index, key, val) => {
    const updatedProducts = [...products];
    updatedProducts[index][key] = Number(val);
    setProducts(updatedProducts);
  };

  const handleCancelButtonClick = () => {
    const updatedList = createNewProductList(products);
    const requestData = {
      requestId: currentRequestId,
      status: requestActionsData.DECLINE,
      bulkChangeProductList: updatedList,
    };

    dispatch(RequestActions.approveRequestRequest(requestData));
  };
  const handleApproveButtonClick = () => {
    const updatedList = createNewProductList(products);
    const requestData = {
      requestId: currentRequestId,
      status: requestActionsData.APPROVE,
      bulkChangeProductList: updatedList,
    };

    dispatch(RequestActions.approveRequestRequest(requestData));
  };

  const columns = useColumns(
    setPriceData,
    currentRequestStatus,
    handleCancelButtonClick,
    handleApproveButtonClick,
    { brands },
  );

  return (
    <Layout>
      <CS.PageHeader>
        <CS.PageTitle>
          <Space size="small">
            <Icon
              name="arrow-left"
              onClick={() => history.push(ROUTES.REQUESTS)}
              title={t('backToList')}
              size={16}
            />
            {t('requests:bulkChange.pageTitle')}
          </Space>
        </CS.PageTitle>
      </CS.PageHeader>
      <CS.PageBody>
        <S.StyledTable
          columns={columns}
          dataSource={products}
          pagination={{ size: 'small', position: ['bottomCenter'] }}
          locale={{ emptyText: <Empty description={t('table.noData')} /> }}
          rowKey="id"
          scroll={{ x: 1000 }}
        />
      </CS.PageBody>
    </Layout>
  );
};

export default BulkChange;
