import styled from 'styled-components';

import Modal from 'components/v1/Modal';

export const StyledModal = styled(Modal)`
  button {
    width: 100%;
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  button {
    width: 100%;
  }
`;
