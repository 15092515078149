import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@getir/web-components';
import { Empty, Space, Alert, Table, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import Layout from 'components/Layout';
import SelectBoxAntd from 'components/Antd/Elements/SelectBox';
import * as CS from 'common/style';
import * as ROUTES from 'constants/routes';
import history from 'utils/history';
import { exportExcelDetail } from 'utils/createExcelUrls';
import {
  TRANSACTION_TYPE_TRANSLATE,
  TRANSACTION_TYPE_REVERSE,
  TRANSACTION_TYPES,
} from 'constants/transactionTypes';

import * as TransactionsActions from '../actions';

import * as S from './style';

const { Text } = Typography;

const DetailOrder = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [transactionType, setTransactionType] = useState(undefined);
  const [selectedDate, setSelectedDate] = useState(undefined);

  const [transactionId, setTransactionId] = useState(undefined);
  const transactionTypes = [
    {
      value: TRANSACTION_TYPES.PAYMENT,
      label: t('financialTransactions:detailPage.payment'),
    },
    {
      value: TRANSACTION_TYPES.REFUND,
      label: t('financialTransactions:detailPage.refund'),
    },
    {
      value: TRANSACTION_TYPES.PARTIAL_REFUND,
      label: t('financialTransactions:detailPage.partialRefund'),
    },
    {
      value: TRANSACTION_TYPES.CANCEL,
      label: t('financialTransactions:detailPage.cancel'),
    },
    {
      value: TRANSACTION_TYPES.MANUEL_DEBIT,
      label: t('financialTransactions:detailPage.manuelDebit'),
    },
    {
      value: TRANSACTION_TYPES.MANUEL_RECEIVABLE,
      label: t('financialTransactions:detailPage.manuelReceivable'),
    },
  ];
  const orderList = useSelector(
    state => state.financialTransactions.transactionDetail.data,
  )?.map((order, index) => ({
    ...order,
    id: index,
  }));

  const dayEndCharge = useSelector(
    state => state.financialTransactions.transactionDetail.dayEndCharge,
  );

  const columns = [
    {
      title: t('financialTransactions:detailPage.date'),
      dataIndex: 'date',
      render: text => dayjs(text).format('DD.MM.YYYY'),
    },
    {
      title: t('financialTransactions:detailPage.documentDate'),
      dataIndex: 'documentDate',
      render: (text, data) =>
        text
          ? dayjs(text).format('DD.MM.YYYY')
          : dayjs(data.date).format('DD.MM.YYYY'),
    },
    {
      title: t('financialTransactions:detailPage.confirmationCode'),
      dataIndex: 'confirmationCode',
    },
    {
      title: t('financialTransactions:detailPage.transactionType'),
      dataIndex: 'type',
      render: type =>
        t(TRANSACTION_TYPE_TRANSLATE[TRANSACTION_TYPE_REVERSE[type]]),
    },
    {
      title: t('financialTransactions:detailPage.orderAmount'),
      dataIndex: 'orderAmount',
    },
    {
      title: t('financialTransactions:detailPage.orderAmountGetir'),
      dataIndex: 'orderAmountWithCampaign',
    },
    {
      title: t('financialTransactions:detailPage.commissionAmount'),
      dataIndex: 'commissionAmount',
    },
    {
      title: t('financialTransactions:detailPage.vendorReceviable'),
      dataIndex: 'vendorReceivable',
    },
  ];

  useEffect(() => {
    if (history.location.state) {
      const { id } = history.location.state;
      setTransactionId(id);
    }

    return () => {
      setTransactionId(undefined);
    };
  }, []);

  useEffect(() => {
    if (orderList && orderList.length) {
      const d = new Date(orderList[0].date);
      setSelectedDate(dayjs(d).format('DD.MM.YYYY'));
    }
  }, [orderList]);

  const handleChangeTransactionType = value => {
    setTransactionType(value);
  };

  const clearFilters = () => {
    if (transactionType === undefined) return;
    setTransactionType(undefined);

    const detailData = {
      id: transactionId,
      transactionType: TRANSACTION_TYPES.ALL,
    };

    dispatch(TransactionsActions.getTransactionDetailRequest(detailData));
  };

  const handleClickFilter = () => {
    if (transactionType === undefined) return;

    const detailData = {
      id: transactionId,
      transactionType,
    };

    dispatch(TransactionsActions.getTransactionDetailRequest(detailData));
  };

  const exportToExcelDetail = () => {
    const excelData = {
      id: transactionId,
      transactionType:
        transactionType === undefined ? TRANSACTION_TYPES.ALL : transactionType,
    };
    const url = exportExcelDetail(excelData);
    window.open(url, '_blank');
  };

  const addSummary = () => (
    <>
      <Table.Summary.Row>
        <Table.Summary.Cell key="title" colSpan={3}>
          {t('financialTransactions:detailPage.endOfDay')}{' '}
        </Table.Summary.Cell>
        <Table.Summary.Cell key="totalOrderAmount">
          <Text>₺{dayEndCharge?.totalOrderAmount}</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell key="totalOrderAmountWithCampaign">
          <Text>₺{dayEndCharge?.totalOrderAmountWithCampaign}</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell key="totalCommissionAmount">
          <Text>₺{dayEndCharge?.totalCommissionAmount}</Text>
        </Table.Summary.Cell>
        <Table.Summary.Cell key="vendorReceivable">
          <Text>₺{dayEndCharge?.vendorReceivable}</Text>
        </Table.Summary.Cell>
      </Table.Summary.Row>
    </>
  );

  return (
    <Layout>
      <CS.PageHeader>
        <CS.PageTitle>
          <S.StyledIcon
            name="arrow-left"
            size={16}
            format="small"
            onClick={() => history.push(ROUTES.FINANCIAL_TRANSACTIONS)}
          />
          {t('financialTransactions:detailPage.pageTitle')} - {selectedDate}
        </CS.PageTitle>
        <Button onClick={exportToExcelDetail}>{t('excel')}</Button>
      </CS.PageHeader>
      <CS.PageBody>
        <Alert
          message={t('financialTransactions:detailPage.infoMessage')}
          type="warning"
          showIcon
        />
        <S.FiltersArea>
          <SelectBoxAntd
            placeholder={t(
              'financialTransactions:detailPage.transactionTypeSelect',
            )}
            options={transactionTypes}
            onChange={handleChangeTransactionType}
            value={transactionType}
          />
          <Space size="small">
            <Button kind="secondary" onClick={clearFilters}>
              {t('clear')}
            </Button>
            <Button onClick={handleClickFilter}>{t('filter')}</Button>
          </Space>
        </S.FiltersArea>
        <S.StyledTable
          dataSource={orderList}
          columns={columns}
          pagination={{ size: 'small', showSizeChanger: true }}
          scroll={{ x: 1000 }}
          bordered
          locale={{ emptyText: <Empty description={t('table.noData')} /> }}
          summary={addSummary}
          rowKey={record => `${record?.id}${record?.date}`}
        />
      </CS.PageBody>
    </Layout>
  );
};

export default DetailOrder;
