import React from 'react';
import PropTypes from 'prop-types';

import * as S from './style';

const Pagination = ({
  current,
  defaultCurrent,
  defaultPageSize,
  hideOnSinglePage,
  pageSize,
  pageSizeOptions,
  onChange,
  total,
  onShowSizeChange,
  showSizeChanger,
}) => (
  <S.PaginationContainer
    current={current}
    defaultCurrent={defaultCurrent}
    defaultPageSize={defaultPageSize}
    hideOnSinglePage={hideOnSinglePage}
    pageSize={pageSize}
    pageSizeOptions={pageSizeOptions}
    onChange={onChange}
    total={total}
    onShowSizeChange={onShowSizeChange}
    showSizeChanger={showSizeChanger}
    responsive
    showLessItems
  ></S.PaginationContainer>
);

Pagination.propTypes = {
  current: PropTypes.number,
  defaultCurrent: PropTypes.number,
  defaultPageSize: PropTypes.number,
  hideOnSinglePage: PropTypes.bool,
  pageSize: PropTypes.number,
  pageSizeOptions: PropTypes.array,
  onChange: PropTypes.func,
  total: PropTypes.number,
  onShowSizeChange: PropTypes.func,
  showSizeChanger: PropTypes.bool,
};

Pagination.defaultProps = {
  current: 1,
  defaultCurrent: 1,
  showSizeChanger: true,
  defaultPageSize: 10,
  hideOnSinglePage: false,
  pageSize: 10,
  pageSizeOptions: [10, 20, 30, 40],
  onChange: () => {},
  total: 0,
  onShowSizeChange: () => {},
};

export default Pagination;
