const extractProductsDataFrom = (bulk, brands, categories) =>
  bulk.map(item => ({
    id: item.id,
    categoryName: (() => {
      if (categories && item.category)
        return categories.find(
          categoryItem => categoryItem.id === item.category.id,
        )?.categoryNameTR;

      return '';
    })(),
    brandName: (() => {
      if (brands && item.brand)
        return brands.find(brandItem => brandItem.id === item.brand.id)
          ?.brandName;

      return '';
    })(),
    productName: item.productName,
    productImageUrl: item.productImageUrl || undefined,
  }));

export { extractProductsDataFrom };
