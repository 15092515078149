import { email } from '@sideway/address';

import {
  validateIBAN,
  validatePhone,
  validateKepAddress,
  validateMersisNo,
} from 'utils/validations';

const validations = (t, isIndividual) => ({
  firmName: {
    required: t('requiredFieldText'),
  },
  commissionRate: {
    required: t('requiredFieldText'),
  },
  payoutTypes: {
    required: t('requiredFieldText'),
  },
  manager: {
    required: t('requiredFieldText'),
  },
  kepAddress: {
    required: t('requiredFieldText'),
    validate: value => {
      const isValidKepAddress = validateKepAddress(value);

      return isValidKepAddress || t('firm:validKepAddress');
    },
  },
  mersisNo: {
    required: t('requiredFieldText'),
    validate: value => {
      const isValidMersisNo = validateMersisNo(value);

      return isValidMersisNo || t('firm:validMersisNo');
    },
  },
  managerTelNumber: {
    required: t('requiredFieldText'),
    validate: value => {
      if (value.length > 0) {
        const isValidPhone = validatePhone(value);

        return isValidPhone || t('phoneValid');
      }

      return true;
    },
  },
  firmManagerMail: {
    required: t('requiredFieldText'),
    validate: value => {
      const isValidEmail = email.isValid(value);

      return isValidEmail || t('emailValid');
    },
  },
  keyAccountManager: {
    required: t('requiredFieldText'),
  },
  title: {
    required: t('requiredFieldText'),
  },
  type: {
    required: t('requiredFieldText'),
  },
  taxOffice: {
    required: t('requiredFieldText'),
  },
  ...(!isIndividual && {
    taxNumber: {
      required: t('requiredFieldText'),
      validate: value => {
        const isValidTaxNumber = value && value.length === 10;

        return isValidTaxNumber || t('financialInformation:validTaxNo');
      },
    },
  }),
  ...(isIndividual && {
    idNumber: {
      required: t('requiredFieldText'),
      validate: value => {
        const isValidIdNumber = value && value.length === 11;

        return isValidIdNumber || t('financialInformation:validIdNumber');
      },
    },
  }),
  bank: {
    required: t('requiredFieldText'),
  },
  iban: {
    required: t('requiredFieldText'),
    validate: value => {
      const isValidIBAN = validateIBAN(value);

      return isValidIBAN || t('financialInformation:validIBAN');
    },
  },
  bankAccountName: {
    required: t('requiredFieldText'),
  },
  email: {
    required: t('requiredFieldText'),
    validate: value => {
      const isValidEmail = email.isValid(value);

      return isValidEmail || t('emailValid');
    },
  },
  cityId: {
    required: t('requiredFieldText'),
  },
  address: {
    required: t('requiredFieldText'),
  },
});

export { validations };
