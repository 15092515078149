import { Day } from '../constants';

import { getEmptyInterval } from './common';

export default function getInitialAlternativeMapIntervals(numberOfIntervals) {
  const daysOfWeeks = Object.keys(Day);
  const initialAlternativeMapIntervals = daysOfWeeks.map(dayOfWeek => {
    const intervals = [...Array(numberOfIntervals).keys()].map(() =>
      getEmptyInterval(),
    );

    return {
      dayOfWeek,
      intervals,
    };
  });

  return initialAlternativeMapIntervals;
}
