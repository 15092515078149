import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { LANGUAGE_NAMESPACES } from 'common/constants';

const appPackageVersion = require('../../package.json').version;

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
      queryStringParams: {
        v: process.env.REACT_APP_VERSION || appPackageVersion,
      },
    },
    defaultNS: 'common',
    ns: LANGUAGE_NAMESPACES,
    lng: 'tr',
    fallbackLng: 'tr',
    debug: false,
    react: {
      useSuspense: false,
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
