import React, { useRef } from 'react';
import { Circle, Map, Placemark, YMaps } from 'react-yandex-maps';
import PropTypes from 'prop-types';

import DeliveryIcon from 'common/svg/deliveryIcon.svg';
import VendorIcon from 'common/svg/vendorIcon.svg';

import * as S from './style';

const MapViewer = ({ vendorAddress, deliveryAddress, isScheduled }) => {
  const mapRef = useRef();
  const [vendorLat, vendorLong] = vendorAddress;
  const [deliveryLat, deliveryLong] = deliveryAddress;

  return (
    <S.YandexMapWrapper isScheduled={isScheduled}>
      <YMaps
        query={{ lang: 'tr_TR', apikey: process.env.REACT_APP_YANDEX_MAP_KEY }}
      >
        <Map
          width="100%"
          height="100%"
          state={{ center: [vendorLat, vendorLong], zoom: 11 }}
          instanceRef={ref => {
            mapRef.current = ref;
          }}
        >
          {!isScheduled && (
            <Placemark
              geometry={[deliveryLat, deliveryLong]}
              options={{
                iconLayout: 'default#image',
                iconImageHref: DeliveryIcon,
                iconImageSize: [58, 58],
                iconImageOffset: [-29, -58],
                draggable: false,
              }}
            />
          )}
          {isScheduled && (
            <Circle
              geometry={[[deliveryLat, deliveryLong], 300]}
              options={{
                draggable: false,
                fillColor: '#5D3EBC77',
                strokeColor: '#7849f7',
                strokeOpacity: 0.8,
                strokeWidth: 2,
              }}
            />
          )}
          <Placemark
            geometry={[vendorLat, vendorLong]}
            options={{
              iconLayout: 'default#image',
              iconImageHref: VendorIcon,
              iconImageSize: [58, 58],
              iconImageOffset: [-29, -58],
            }}
          />
        </Map>
      </YMaps>
    </S.YandexMapWrapper>
  );
};

MapViewer.propTypes = {
  vendorAddress: PropTypes.array,
  deliveryAddress: PropTypes.array,
  isScheduled: PropTypes.bool,
};

MapViewer.defaultProps = {
  vendorAddress: undefined,
  deliveryAddress: undefined,
  isScheduled: false,
};

export default MapViewer;
