import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styles';

const SelectBox = ({
  size,
  options,
  value,
  labelInValue,
  onChange,
  ...props
}) => (
  <S.SelectBoxWrapper>
    <S.SelectBox
      options={options}
      value={value}
      labelInValue={labelInValue}
      onChange={onChange}
      $size={size}
      {...props}
    />
  </S.SelectBoxWrapper>
);

SelectBox.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  labelInValue: PropTypes.bool,
};

SelectBox.defaultProps = {
  size: 'medium',
  value: undefined,
  options: [],
  onChange: () => {},
  labelInValue: false,
};

export default SelectBox;
