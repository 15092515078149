import * as Types from './types';

const initialState = {
  undertakingContent: {
    data: undefined,
    error: undefined,
  },
  approveUndertaking: {
    data: undefined,
    error: undefined,
  },
};

function UndertakingModalReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_UNDERTAKING_DOC_SUCCESS:
      return {
        ...state,
        undertakingContent: {
          data: payload,
        },
      };
    case Types.GET_UNDERTAKING_DOC_ERROR:
      return {
        ...state,
        undertakingContent: {
          error: payload,
        },
      };
    case Types.APPROVE_UNDERTAKING_DOC_SUCCESS:
      return {
        ...state,
        approveUndertaking: {
          data: payload,
        },
      };
    case Types.APPROVE_UNDERTAKING_DOC_ERROR:
      return {
        ...state,
        approveUndertaking: {
          error: payload,
        },
      };
    default:
      return state;
  }
}

export default UndertakingModalReducer;
