export const GET_USER_LIST_REQUEST = 'USERS_PERMS_GET_USER_LIST_REQUEST';
export const GET_USER_LIST_SUCCESS = 'USERS_PERMS_GET_USER_LIST_SUCCESS';
export const GET_USER_LIST_ERROR = 'USERS_PERMS_GET_USER_LIST_ERROR';

export const CREATE_USER_REQUEST = 'USERS_PERMS_CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'USERS_PERMS_CREATE_USER_SUCCESS';
export const CREATE_USER_ERROR = 'USERS_PERMS_CREATE_USER_ERROR';

export const UPDATE_USER_REQUEST = 'USERS_PERMS_UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'USERS_PERMS_UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'USERS_PERMS_UPDATE_USER_ERROR';

export const DELETE_USER_REQUEST = 'USERS_PERMS_DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'USERS_PERMS_DELETE_USER_SUCCESS';
export const DELETE_USER_ERROR = 'USERS_PERMS_DELETE_USER_ERROR';

export const GET_ROLES_BY_ROLE_GROUP_REQUEST =
  'USERS_PERMS_GET_ROLES_BY_ROLE_GROUP_REQUEST';
export const GET_ROLES_BY_ROLE_GROUP_SUCCESS =
  'USERS_PERMS_GET_ROLES_BY_ROLE_GROUP_SUCCESS';
export const GET_ROLES_BY_ROLE_GROUP_ERROR =
  'USERS_PERMS_GET_ROLES_BY_ROLE_GROUP_ERROR';

export const GET_ROLES_BY_USER_ID_REQUEST =
  'USERS_PERMS_GET_ROLES_BY_USER_ID_REQUEST';
export const GET_ROLES_BY_USER_ID_SUCCESS =
  'USERS_PERMS_GET_ROLES_BY_USER_ID_SUCCESS';
export const GET_ROLES_BY_USER_ID_ERROR =
  'USERS_PERMS_GET_ROLES_BY_USER_ID_ERROR';
