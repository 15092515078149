import {
  validatePhone,
  validateMersisNo,
  validateKepAddress,
} from 'utils/validations';
import translate from 'utils/translate';

const validationRules = {
  name: {
    required: translate('requiredFieldText'),
  },
  payerReferenceCode: {},
  referenceCode: {},
  kepAddress: {
    validate: value => {
      if (!value) return true;

      const isValidKepAddress = validateKepAddress(value);

      return isValidKepAddress || translate('firm:validKepAddress');
    },
  },
  mersisNo: {
    validate: value => {
      if (!value) return true;
      const isValidMersisNo = validateMersisNo(value);

      return isValidMersisNo || translate('firm:validMersisNo');
    },
  },
  phone: {
    required: translate('requiredFieldText'),
    validate: value => {
      const isValidPhone = validatePhone(value);

      return isValidPhone || translate('phoneValid');
    },
  },
  commissionRate: {
    required: translate('requiredFieldText'),
  },
  label: {},
  minDeliveryTime: {
    required: translate('requiredFieldText'),
  },
  maxDeliveryTime: {
    required: translate('requiredFieldText'),
  },
  minBasketAmount: {},
  cashOnDelivery: {},
  scheduledOrder: {},
  operationalManager: {
    required: translate('requiredFieldText'),
  },
  regionManager: {
    required: translate('requiredFieldText'),
  },
  firm: {
    required: translate('requiredFieldText'),
  },
  brand: {
    required: translate('requiredFieldText'),
  },
  city: {
    required: translate('requiredFieldText'),
  },
  district: {
    required: translate('requiredFieldText'),
  },
  town: {
    required: translate('requiredFieldText'),
  },
  address: {
    required: translate('requiredFieldText'),
    validate: value => {
      const isValidAddress = value?.length >= 11;

      return isValidAddress || translate('vendor:validAddress');
    },
  },
  searchAddress: {
    required: translate('requiredFieldText'),
  },
};

export { validationRules };
