import React from 'react';
import PropTypes from 'prop-types';
import { ConfigProvider, Result } from 'antd';
import { useTranslation } from 'react-i18next';

import Button from 'components/v1/Button';

import * as S from './style';

const SapErrorModal = ({ onClose }) => {
  const { t } = useTranslation();

  return (
    <ConfigProvider
      result={{
        style: {
          padding: '0px 20px 20px 20px',
          paddingTop: 0,
        },
      }}
    >
      <Result
        status="warning"
        subTitle={t('vendor:sapVendorSaleOpenErrorContent')}
        extra={
          <S.footerContainer>
            <Button onClick={onClose} type="primary" key="console">
              {t('ok')}
            </Button>
          </S.footerContainer>
        }
      />
    </ConfigProvider>
  );
};

SapErrorModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default SapErrorModal;
