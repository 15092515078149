import React from 'react';
import PropTypes from 'prop-types';

import { ClockIcon, SuccessIcon, XCircleIcon } from '../Icons';

import * as S from './styles';

const Badge = ({ children, type, icon }) => {
  const badgeIcon = {
    success: <SuccessIcon fill="#05751F" />,
    failed: <XCircleIcon />,
    waiting: <ClockIcon />,
  };

  return (
    <S.BadgeContainer $type={type}>
      <S.BadgeIcon>{icon || badgeIcon[type]}</S.BadgeIcon>
      {children}
    </S.BadgeContainer>
  );
};

Badge.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.node,
  type: PropTypes.oneOf([
    'primary',
    // 'secondary',
    'success',
    'failed',
    'waiting',
    // 'info',
  ]).isRequired,
};

Badge.defaultProps = {
  icon: null,
};

export default Badge;
