import * as Types from './types';

const initialState = {
  userList: undefined,
  roleList: {
    data: undefined,
    error: undefined,
  },
  error: {},
};

function BrandReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_USERS_BY_FIRM_SUCCESS:
      return {
        ...state,
        userList: payload,
      };
    case Types.GET_USERS_BY_FIRM_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.GET_USERS_BY_VENDOR_SUCCESS:
      return {
        ...state,
        userList: payload.payload,
      };
    case Types.GET_USERS_BY_VENDOR_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.GET_ROLES_BY_ROLE_GROUP_SUCCESS:
      return {
        ...state,
        roleList: {
          data: payload,
        },
      };
    case Types.GET_ROLES_BY_ROLE_GROUP_ERROR:
      return {
        ...state,
        roleList: {
          error: payload,
        },
      };
    case Types.SET_USER_LIST:
      return {
        ...state,
        userList: payload,
      };

    default:
      return state;
  }
}

export default BrandReducer;
