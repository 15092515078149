import { useEffect, useState } from 'react';

/**
 * A hook for handling dynamic initial value
 * @template T
 * @param {T} propValue Initial value for the hook
 * @returns {[T, (T) => void]}
 */
export default function useStateWithProp(propValue) {
  const [value, setValue] = useState(propValue);

  useEffect(() => {
    setValue(propValue);
  }, [propValue]);

  return [value, setValue];
}
