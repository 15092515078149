import React, { useEffect } from 'react';
import { Space, Divider } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { Icon } from '@getir/web-components';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Input from 'components/v1/Input';
import Layout from 'components/Layout';
import InputWrapper from 'components/InputWrapper';
import * as ROUTES from 'constants/routes';
import * as CommonStyle from 'common/style';
import Button from 'components/v1/Button';

import { getCategoryRequest, updateCategoryRequest } from '../actions';
import { useValidations } from '../helpers/useValidations';

import * as S from './style';

const Edit = () => {
  const history = useHistory();
  const { t } = useTranslation(['common', 'categories']);
  const validationRules = useValidations();
  const dispatch = useDispatch();
  const { id } = useParams();
  const categoryInfo = useSelector(state => state.category.categoryInfo);

  useEffect(() => {
    dispatch(getCategoryRequest(id));
  }, [dispatch, id]);

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    mode: 'onBlur',
  });

  useEffect(() => {
    if (Object.keys(categoryInfo).length > 0) {
      setValue('categoryNameTR', categoryInfo.categoryNameTR, {
        shouldValidate: true,
      });
      setValue('categoryNameEN', categoryInfo.categoryNameEN, {
        shouldValidate: true,
      });
      setValue('vatRate', categoryInfo.vatRate, { shouldValidate: true });
    }
  }, [categoryInfo, setValue]);

  const onSubmit = formData => {
    const reqData = {
      categoryNameTR: formData.categoryNameTR,
      categoryNameEN: formData.categoryNameEN,
      vatRate: Number(formData.vatRate),
      id,
    };
    dispatch(updateCategoryRequest(reqData));
  };

  const handleChange = e => {
    const inputValue = e;
    const reg = /^-?\d*(\.\d*)?$/;
    if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
      if (Number(inputValue) > 100) {
        setValue('vatRate', '100');

        return;
      }
      setValue('vatRate', inputValue);
    }
  };

  return (
    <Layout>
      <S.PageHeader>
        <S.IconContainer>
          <Icon
            name="arrow-left"
            onClick={() => history.push(ROUTES.LIST_CATEGORY)}
          />
          <S.PageTitle>{t('categories:editScreenTitle')}</S.PageTitle>
        </S.IconContainer>
      </S.PageHeader>
      <S.PageContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputWrapper
            fieldName="categoryNameTR"
            control={control}
            rules={validationRules.categoryNameTR}
            placeholder={t('categories:placeholders.categoryNameTR')}
            header={t('categories:placeholders.categoryNameTR')}
            errors={errors}
          />
          <InputWrapper
            fieldName="categoryNameEN"
            control={control}
            rules={validationRules.categoryNameEN}
            placeholder={t('categories:placeholders.categoryNameEN')}
            header={t('categories:placeholders.categoryNameEN')}
            errors={errors}
          />
          <Controller
            name="vatRate"
            control={control}
            rules={validationRules.vatRate}
            render={({ field }) => (
              <Input
                placeholder={t('categories:placeholders.taxRate')}
                size="large"
                {...field}
                onChange={e => {
                  handleChange(e.target.value);
                }}
              />
            )}
          />
          {errors.vatRate && (
            <CommonStyle.ErrorMessage>
              {errors.vatRate.message}
            </CommonStyle.ErrorMessage>
          )}
          <Divider />
          <Space size="middle">
            <Button
              kind="secondary"
              onClick={() => history.push(ROUTES.LIST_CATEGORY)}
            >
              {t('common:cancel')}
            </Button>
            <Button type="submit">{t('common:save')}</Button>
          </Space>
        </form>
      </S.PageContent>
    </Layout>
  );
};

export default Edit;
