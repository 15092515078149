const PopupSubmitStatus = {
  COMPLETE: 'COMPLETE',
  IGNORED: 'IGNORED',
};

const DevelopmentPhase = {
  PHASE_1: 0,
  PHASE_2: 1,
};

export { PopupSubmitStatus, DevelopmentPhase };
