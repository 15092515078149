import styled from 'styled-components';
import { Button, Input, Icon } from '@getir/web-components';

import COLORS from 'constants/colors';
import breakpoint from 'constants/breakpoint';

const MainWrapper = styled.div`
  display: grid;
  grid-template-columns: 0.89fr 1fr;
  grid-template-rows: 1fr 60px;
  height: 100%;
  background-color: rgb(248, 248, 248);

  @media screen and (max-width: ${breakpoint.TABLET_MINI.MIN}px) {
    grid-template-columns: 1fr;
    grid-template-rows: 0.4fr 0.83fr 0.07853fr;
  }

  @media screen and (max-width: ${breakpoint.MOBILE.MAX}px) {
    display: flex;
    flex-direction: column;
    background-color: rgb(245, 245, 245);
  }
`;

const LoginLeftContainer = styled.div`
  display: grid;
  place-content: center start;
  grid-template-columns: 0.844fr;
  gap: 24px;
  background-color: ${COLORS.mainGetirColor};
  padding: 60px;

  @media screen and (max-width: ${breakpoint.MOBILE.MAX}px) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px 0;
  }
`;

const Label = styled.label`
  display: block;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 2px;
  letter-spacing: 0.014em;
  color: #5d3ebc;
`;

const LoginRightContainer = styled.div`
  display: grid;
  background-color: rgb(248, 248, 248);
  grid-template-columns: minmax(auto, 445px);
  grid-template-rows: 392px;
  place-content: center;
  position: relative;

  @media screen and (max-width: ${breakpoint.MOBILE.MAX}px) {
    display: inline-block;
    background-color: ${COLORS.mainGetirColor};
  }
`;

const LoginWelcomeImage = styled.img`
  object-fit: contain;
  width: 302px;
  height: 135px;
  border-radius: 3px;
`;

const LoginForm = styled.form`
  display: grid;
  place-content: center;
  gap: 40px;
  background: rgb(255, 255, 255);
  box-shadow: rgb(206 206 206 / 25%) 4px 4px 30px;
  border-radius: 16px;
  grid-template-columns: minmax(auto, 384px);
  grid-template-rows: auto;
  position: absolute;
  place-self: center;
  padding: 36px 32px;

  @media screen and (max-width: ${breakpoint.MOBILE.MAX}px) {
    position: static;
    border-radius: 16px 16px 0 0;
  }
`;

const FormHeader = styled.div`
  display: grid;
  place-items: center;
`;

const ForgotPasswordFormHeader = styled.div`
  display: grid;
  grid-template-columns: 16px 1fr;
  place-items: center;
`;

const FormTitle = styled.p`
  margin: 0px;
  color: rgb(93, 62, 188);
  letter-spacing: 0.014em;

  @media screen and (min-width: ${breakpoint.DESKTOP.MIN}px) {
    font-size: 16px;
  }
`;

const FormBody = styled.div`
  display: grid;
  gap: 16px;

  ${Input.S.Input} {
    border: none;
  }
`;

const LoginResultText = styled.p`
  margin: 0;
  color: ${COLORS.mainGetirColor};
  place-self: center;
`;

const FormFooter = styled.div`
  display: grid;
  gap: 16px;
  place-items: center;

  button {
    width: 100%;
  }

  ${Button.S.Wrapper} {
    width: 100%;
    border-radius: 0;
    height: 48px;
  }
`;

const ForgotPasswordText = styled.p`
  margin: 0;
  color: ${COLORS.mainGetirColor};
  cursor: pointer;
`;

const ErrorMessage = styled.span`
  display: inline-block;
  color: rgb(219, 71, 30);
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.16px;
  margin-top: 8px;
`;

const IconProvider = styled(Icon)`
  cursor: pointer;
`;

const RecaptchaWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonText = styled.span`
  margin-left: 5px;
`;

export {
  MainWrapper,
  LoginLeftContainer,
  LoginRightContainer,
  LoginWelcomeImage,
  LoginForm,
  FormHeader,
  FormTitle,
  FormBody,
  FormFooter,
  ForgotPasswordText,
  ErrorMessage,
  IconProvider,
  ForgotPasswordFormHeader,
  LoginResultText,
  RecaptchaWrapper,
  ButtonText,
  Label,
};
