import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import L from 'leaflet';

import store from 'store/configureStore';

import { createFeatureCollection } from '../utils/polygonUtils';
import { MAP_COLORS } from '../constant';
import { reverseCoordinates } from '../common';

const useSelectedNeighbourhoodPolygon = ({
  geomapStateKey,
  Actions,
  mapInstance,
}) => {
  const editedMap = useSelector(state => state[geomapStateKey].editedMap);
  const dispatch = useDispatch();
  const handleNeighbourhoodPolygonClick = useCallback(
    polygonData => {
      const { selectedVendor } = store.getState()[geomapStateKey];
      if (!selectedVendor) return;
      const newEditedMap = editedMap.features.filter(
        f =>
          f.properties.neighbourhood !== polygonData.properties.neighbourhood,
      );

      const createdFeature = createFeatureCollection(newEditedMap);

      dispatch(Actions.setEditedMap(createdFeature));
    },
    [Actions, dispatch, editedMap, geomapStateKey],
  );
  const selectedNeigborhoodPolygons = useRef([]);

  useEffect(() => {
    if (mapInstance) {
      const polygons = [];
      const pane = mapInstance.createPane('selectedNeighbourhoodPolygon');
      pane.style.zIndex = 403;

      editedMap?.features?.forEach(nbhood => {
        const createdPolygon = L.polygon(
          reverseCoordinates(nbhood.geometry.coordinates),
          {
            fillColor: MAP_COLORS.selectedNewNeigborhood,
            color: '#000000',
            fillOpacity: 0.25,
            weight: 1,
            pane: 'selectedNeighbourhoodPolygon',
          },
        );

        createdPolygon.on('click', () => {
          handleNeighbourhoodPolygonClick(nbhood);
        });
        createdPolygon.bindPopup(nbhood.properties.neighbourhoodName);
        createdPolygon.on('mouseover', () => {
          createdPolygon.openPopup();
        });
        createdPolygon.on('mouseout', () => {
          createdPolygon.closePopup();
        });

        createdPolygon.addTo(mapInstance);

        polygons.push(createdPolygon);
      });

      selectedNeigborhoodPolygons.current = [...polygons];
    }

    return () => {
      selectedNeigborhoodPolygons.current.forEach(p => {
        p.remove();
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editedMap]);
};

export default useSelectedNeighbourhoodPolygon;
