import styled from 'styled-components';
import { Select } from 'antd';

import COLORS from 'constants/colors';

const Container = styled.div`
  width: 100%;
`;

/* eslint-disable */
const SelectContainer = styled(Select)`
  width: 100%;
  border: none;
  outline: none;
  height: 50px;
  margin-bottom: 0px !important;
  margin-top: 3px !important;

  .ant-select-item-option-content{
      width: 100%;
  }

  .ant-select-selector {
    min-height: 50px !important;
    border: ${props => {
      if (props.haserror) return '2px solid #db471e !important';

      return '2px solid #dfdee2 !important';
    }};
    border-radius: 4px !important;
    padding-left: 14px !important;
    &:hover {
      border: 2px solid ${COLORS.mainGetirColor} !important;
    }
  }

  .ant-select-selection-placeholder {
    line-height: 44px !important;
    color: #838287 !important;
  }

  .ant-select-selection-item,
  .ant-select-selection-search {
    padding-top: 8px !important;
    color: #191919 !important;
    font-size: 14px !important;
  }

  .ant-select-selection-overflow {
    .ant-select-selection-item {
      padding-top: 0 !important;
      color: #191919 !important;
      font-size: 14px !important;
    }
  }

  .ant-select-arrow {
    color: ${COLORS.mainGetirColor} !important;
  }
`;
/* eslint-enable */

const ErrorWrapper = styled.div`
  position: absolute;
  right: 5px;
  pointer-events: none;
  top: -4px;
`;

export const SelectBoxPlaceholder = styled.span`
  color: rgba(0, 0, 0, 0.85);
`;

export { SelectContainer, Container, ErrorWrapper };
