import { createAction } from 'utils/createAction';

import * as Types from './types';

// get perm list
export const getPermListRequest = createAction(Types.GET_PERM_LIST_REQUEST);
export const getPermListSuccess = createAction(Types.GET_PERM_LIST_SUCCESS);
export const getPermListError = createAction(Types.GET_PERM_LIST_ERROR);

// get perm children
export const getPermListChildrenRequest = createAction(
  Types.GET_PERM_LIST_CHILDREN_REQUEST,
);
export const getPermListChildrenSuccess = createAction(
  Types.GET_PERM_LIST_CHILDREN_SUCCESS,
);
export const getPermListChildrenError = createAction(
  Types.GET_PERM_LIST_CHILDREN_ERROR,
);

// get role list
export const getRoleListRequest = createAction(Types.GET_ROLE_LIST_REQUEST);
export const getRoleListSuccess = createAction(Types.GET_ROLE_LIST_SUCCESS);
export const getRoleListError = createAction(Types.GET_ROLE_LIST_ERROR);

// update perm
export const updatePermRequest = createAction(Types.UPDATE_PERM_REQUEST);
export const updatePermSuccess = createAction(Types.UPDATE_PERM_SUCCESS);
export const updatePermError = createAction(Types.UPDATE_PERM_ERROR);
