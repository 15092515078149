import React, { useState, useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  MoreOutlined,
  CloseCircleOutlined,
  KeyOutlined,
  UserSwitchOutlined,
  MenuOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useVirtualizer } from '@tanstack/react-virtual';
import { useTranslation } from 'react-i18next';

import * as UsersActions from 'containers/StoreDetails/Users/actions';
import * as VendorActions from 'containers/Stores/Vendor/actions';
import useQuery from 'hooks/useQuery';
import * as FirmActions from 'containers/Stores/Firm/actions';
import { LocationIcon, SubMenuIcon, BackIcon } from 'components/v1/Icons';
import Input from 'components/v1/Input';
import useDebounce from 'hooks/useDebounce';

import * as MainHeaderActions from '../MainHeader/actions';

import * as S from './style';
import { MENU_SCREENS } from './constants';

const MobileMenu = ({
  handleUserLogout,
  handleChangePassword,
  roleListOptions,
  handleChangeRole,
  isRoleListVisible,
  selectedRole,
}) => {
  const [selectedMenu, setSelectedMenu] = useState(MENU_SCREENS.HOME);
  const [isOpen, setOpen] = useState(false);
  const history = useHistory();
  const params = useQuery();
  const { t } = useTranslation();
  const [filterVendorValue, setFilterVendorValue] = useState('');
  const [filterCityValue, setFilterCityValue] = useState('');
  const debouncedVendorFilterValue = useDebounce(filterVendorValue, 300);
  const debouncedCityFilterValue = useDebounce(filterCityValue, 300);

  const dispatch = useDispatch();
  const cityId = useSelector(state => state.mainHeader.cityId);
  const vendor = useSelector(state => state.mainHeader.vendor);
  const cities = useSelector(state => state.mainHeader.cities);
  const vendors = useSelector(state => state.mainHeader.vendors);

  useEffect(() => {
    if (cities.length === 0) {
      dispatch(MainHeaderActions.getCitiesRequest());
      dispatch(MainHeaderActions.getVendorsRequest());
    }
  }, [cities.length, dispatch]);

  const vendorListParentRef = useRef();
  const cityListParentRef = useRef();

  const filteredVendors = useMemo(() => {
    const filteredData = vendors.filter(v => {
      const filterText = `${v.label}`.toLocaleLowerCase('tr');

      return filterText.includes(
        debouncedVendorFilterValue.toLocaleLowerCase('tr'),
      );
    });

    return filteredData;
  }, [debouncedVendorFilterValue, vendors]);

  const filteredCities = useMemo(() => {
    if (!cities) return [];
    const filteredData = cities.filter(v => {
      const filterText = `${v.label}`.toLocaleLowerCase('tr');

      return filterText.includes(
        debouncedCityFilterValue.toLocaleLowerCase('tr'),
      );
    });

    return filteredData;
  }, [cities, debouncedCityFilterValue]);

  const vendorListVirtualizer = useVirtualizer({
    count: filteredVendors.length,
    getScrollElement: () => vendorListParentRef.current,
    estimateSize: () => 42,
  });

  const cityListVirtualizer = useVirtualizer({
    count: filteredCities.length,
    getScrollElement: () => cityListParentRef.current,
    estimateSize: () => 42,
  });

  const addQueryParametrs = (name, value) => {
    const searchParam = new URLSearchParams(window.location.search);
    searchParam.set(name, value);
    history.replace(`?${searchParam.toString()}`, history.location.state);
  };

  const clearQueryParametrs = value => {
    const searchParam = new URLSearchParams(window.location.search);
    searchParam.delete(value);

    history.replace(`?${searchParam.toString()}`);
  };

  useEffect(() => {
    if (vendors && vendors.length === 1) {
      dispatch(MainHeaderActions.setVendor(vendors[0]));
      dispatch(MainHeaderActions.getVendorInfoRequest(vendors[0].value));
      addQueryParametrs('vendorId', vendors[0].value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendors, dispatch]);

  useEffect(() => {
    if (cityId) {
      addQueryParametrs('cityId', cityId);
    }
    if (vendor) {
      addQueryParametrs('vendorId', vendor.value);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (filteredCities.length === 0) return;
    cityListVirtualizer.scrollToIndex(0, { align: 'start' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredCities]);

  useEffect(() => {
    if (filteredVendors.length === 0) return;
    vendorListVirtualizer.scrollToIndex(0, { align: 'start' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredVendors]);

  useEffect(() => {
    if (selectedMenu === MENU_SCREENS.SELECTED_VENDOR && vendor) {
      vendorListVirtualizer.scrollToIndex(
        vendors.findIndex(v => v.value === vendor?.value),
        { align: 'start' },
      );
    }

    if (selectedMenu === MENU_SCREENS.SELECTED_CITY && cityId) {
      cityListVirtualizer.scrollToIndex(
        cities.findIndex(v => v.value === cityId),
        { align: 'start' },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMenu]);

  const handleUserLogoutClick = () => {
    setOpen(false);
    handleUserLogout();
  };

  const handleChangePasswordClick = () => {
    setOpen(false);
    handleChangePassword(true);
  };

  const handleChangeRoleClick = role => {
    setOpen(false);
    handleChangeRole(role);
  };

  const handleChangeCityClick = (value, isInit) => {
    dispatch(MainHeaderActions.setCityId(value));
    dispatch(
      MainHeaderActions.getVendorsRequest({
        cityId: value,
      }),
    );
    dispatch(MainHeaderActions.setVendor(undefined));
    dispatch(
      MainHeaderActions.getVendorInfoSuccess({
        workingHours: undefined,
        id: undefined,
      }),
    );
    dispatch(MainHeaderActions.getWorkingHoursInfoSuccess(''));
    dispatch(UsersActions.setUserList([]));
    dispatch(VendorActions.vendorListSuccess([]));

    if (value === undefined || value === null) {
      clearQueryParametrs('cityId');

      return;
    }
    addQueryParametrs('cityId', value);

    if (!isInit) {
      clearQueryParametrs('vendorId');
    }
  };

  const handleChangeVendorClick = selectedVendor => {
    const currentVendor = vendors.find(
      item => item.label === selectedVendor.label,
    );
    dispatch(MainHeaderActions.setVendor(currentVendor));

    if (currentVendor.isFirm) {
      dispatch(
        MainHeaderActions.getVendorInfoSuccess({
          workingHours: undefined,
          id: undefined,
        }),
      );
      dispatch(FirmActions.getFirmInfoRequest(currentVendor.value));
    } else {
      dispatch(MainHeaderActions.getVendorInfoRequest(currentVendor.value));
      dispatch(
        MainHeaderActions.getWorkingHoursInfoRequest(currentVendor.value),
      );
      dispatch(FirmActions.getFirmInfoSuccess({}));
    }
    addQueryParametrs('vendorId', currentVendor.value);
  };

  useEffect(() => {
    const queryCityId = params.get('cityId');
    const queryVendorId = params.get('vendorId');
    const isCityExistAndDefined =
      queryCityId !== null && cityId !== undefined && cities.length > 0;

    if (isCityExistAndDefined) {
      if (!cities.some(item => item.value === queryCityId)) {
        clearQueryParametrs('cityId');
      } else {
        handleChangeCityClick(queryCityId, true);
      }
    }
    if (queryVendorId !== null && vendor === undefined) {
      const currentVendor = vendors.find(item => item.value === queryVendorId);
      if (currentVendor === undefined) return;

      handleChangeVendorClick(currentVendor);
    }
    // eslint-disable-next-line
  }, [cities]);

  const selectedCity = useMemo(() => cities.find(c => c.value === cityId), [
    cities,
    cityId,
  ]);

  const handleVendorFilterChange = event => {
    const { value } = event.target;

    setFilterVendorValue(value);
  };
  const handleCityFilterChange = event => {
    const { value } = event.target;

    setFilterCityValue(value);
  };

  const MenuItems = {
    [MENU_SCREENS.HOME]: (
      <S.MobileMenuWrapper>
        <S.MenuItem
          key={MENU_SCREENS.SELECTED_VENDOR}
          onClick={() => {
            setSelectedMenu(MENU_SCREENS.SELECTED_VENDOR);
          }}
        >
          <MenuOutlined />
          {vendor && vendor.label ? (
            <span>{vendor.label}</span>
          ) : (
            <span>{t('common:vendor')}</span>
          )}
          <S.SubMenuIcon>
            <SubMenuIcon />
          </S.SubMenuIcon>
        </S.MenuItem>
        <S.MenuItem
          key={MENU_SCREENS.SELECTED_CITY}
          onClick={() => setSelectedMenu(MENU_SCREENS.SELECTED_CITY)}
        >
          <LocationIcon />
          {selectedCity ? (
            <span>{selectedCity.label}</span>
          ) : (
            <span>{t('common:city')}</span>
          )}
          <SubMenuIcon />
        </S.MenuItem>
        <S.MenuItem
          key={MENU_SCREENS.SELECTED_ROLE}
          onClick={() => setSelectedMenu(MENU_SCREENS.SELECTED_ROLE)}
        >
          <UserSwitchOutlined />
          {selectedRole ? (
            <span>{selectedRole.label}</span>
          ) : (
            <span>{t('common:role')}</span>
          )}
          <SubMenuIcon />
        </S.MenuItem>
        <S.MenuItem key="changePass" onClick={handleChangePasswordClick}>
          <KeyOutlined />
          <span>{t('header:changePassword')}</span>
        </S.MenuItem>
        <S.MenuItem key="logout" onClick={handleUserLogoutClick}>
          <CloseCircleOutlined />
          <span>{t('common:logout')}</span>
        </S.MenuItem>
      </S.MobileMenuWrapper>
    ),
    [MENU_SCREENS.SELECTED_VENDOR]: (
      <S.MobileMenuWrapper>
        <S.MenuItem onClick={() => setSelectedMenu(MENU_SCREENS.HOME)}>
          <BackIcon />
          <span>{t('common:chooseVendor')}</span>
        </S.MenuItem>
        {vendors && vendors.length > 4 && (
          <Input
            placeholder={t('common:search')}
            style={{ marginBottom: '2px' }}
            size="medium"
            prefix={<SearchOutlined width={20} color="#5D3EBC" height={20} />}
            allowClear
            value={filterVendorValue}
            onChange={handleVendorFilterChange}
          />
        )}

        <div
          ref={vendorListParentRef}
          style={{
            height: `400px`,
            overflow: 'auto', // Make it scroll!
            width: '100%',
          }}
        >
          <div
            style={{
              height: `${vendorListVirtualizer.getTotalSize()}px`,
              width: '100%',
              position: 'relative',
            }}
          >
            {vendorListVirtualizer.getVirtualItems().map(virtualItem => (
              <S.MenuItem
                key={virtualItem.key}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  marginBottom: '2px',
                  width: '100%',
                  height: `${virtualItem.size}px`,
                  transform: `translateY(${virtualItem.start}px)`,
                }}
                $isSelected={
                  filteredVendors[virtualItem.index]?.value === vendor?.value
                }
                onClick={() =>
                  handleChangeVendorClick(filteredVendors[virtualItem.index])
                }
              >
                <span>{filteredVendors[virtualItem.index].label}</span>
              </S.MenuItem>
            ))}
          </div>
        </div>
      </S.MobileMenuWrapper>
    ),
    [MENU_SCREENS.SELECTED_CITY]: (
      <S.MobileMenuWrapper>
        <S.MenuItem onClick={() => setSelectedMenu(MENU_SCREENS.HOME)}>
          <BackIcon />
          <span>{t('common:chooseCity')}</span>
        </S.MenuItem>
        <Input
          placeholder={t('common:search')}
          style={{ marginBottom: '2px' }}
          size="medium"
          prefix={<SearchOutlined width={20} color="#5D3EBC" height={20} />}
          allowClear
          value={filterCityValue}
          onChange={handleCityFilterChange}
        />
        <div
          ref={cityListParentRef}
          style={{
            height: `400px`,
            overflow: 'auto', // Make it scroll!
            width: '100%',
          }}
        >
          <div
            style={{
              height: `${cityListVirtualizer.getTotalSize()}px`,
              width: '100%',
              position: 'relative',
            }}
          >
            {cityListVirtualizer.getVirtualItems().map(virtualItem => (
              <S.MenuItem
                key={virtualItem.key}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  marginBottom: '2px',
                  width: '100%',
                  height: `${virtualItem.size}px`,
                  transform: `translateY(${virtualItem.start}px)`,
                }}
                $isSelected={
                  filteredCities[virtualItem.index]?.value === cityId
                }
                onClick={() =>
                  handleChangeCityClick(
                    filteredCities[virtualItem.index].value,
                    false,
                  )
                }
              >
                <span>{filteredCities[virtualItem.index].label}</span>
              </S.MenuItem>
            ))}
          </div>
        </div>
      </S.MobileMenuWrapper>
    ),
  };

  if (isRoleListVisible) {
    MenuItems[MENU_SCREENS.SELECTED_ROLE] = (
      <S.MobileMenuWrapper>
        <S.MenuItem onClick={() => setSelectedMenu(MENU_SCREENS.HOME)}>
          <BackIcon />
          <span>{t('common:chooseRole')}</span>
        </S.MenuItem>
        {roleListOptions.map(role => (
          <S.MenuItem
            $isSelected={role.value === selectedRole.value}
            key={role.value}
            onClick={() => handleChangeRoleClick(role)}
          >
            <span>{role.label}</span>
          </S.MenuItem>
        ))}
      </S.MobileMenuWrapper>
    );
  }

  return (
    <S.MobileMenuDrop
      onOpenChange={e => {
        if (!e) setSelectedMenu(MENU_SCREENS.HOME);

        setOpen(e);
      }}
      trigger={['click']}
      open={isOpen}
      dropdownRender={() => MenuItems[selectedMenu] || MenuItems.home}
    >
      <MoreOutlined
        onClick={() => {
          setOpen(!isOpen);
        }}
      />
    </S.MobileMenuDrop>
  );
};

MobileMenu.propTypes = {
  handleUserLogout: PropTypes.func.isRequired,
  handleChangePassword: PropTypes.func.isRequired,
  roleListOptions: PropTypes.arrayOf(PropTypes.object),
  handleChangeRole: PropTypes.func.isRequired,
  isRoleListVisible: PropTypes.bool,
  selectedRole: PropTypes.object.isRequired,
};

MobileMenu.defaultProps = {
  isRoleListVisible: false,
  roleListOptions: [],
};

export default MobileMenu;
