import { put, call, takeLatest } from 'redux-saga/effects';

import { getRequest } from 'lib/axios';
import * as LoaderActions from 'components/Loader/actions';
import translate from 'utils/translate';
import { open } from 'utils/notification';
import { approveUndertakingApi } from 'services/UndertakingModal/api';

import * as UndertakingActions from './actions';
import * as Types from './types';

function* getUndertakingDocSaga({ payload }) {
  yield put(LoaderActions.showLoader());
  try {
    const result = yield call(getRequest, payload);
    if (result.data) {
      yield put(UndertakingActions.getUndertakingDocSuccess(result.data));
    } else {
      open('warning', translate('notif.warnTitle'), result.message);
    }
  } catch (error) {
    yield put(UndertakingActions.getUndertakingDocError(error.message));
  }
  yield put(LoaderActions.hideLoader());
}

function* approveUndertakingDocSaga({ payload }) {
  yield put(LoaderActions.showLoader());

  try {
    const result = yield call(approveUndertakingApi, payload);
    if (result) {
      yield put(
        UndertakingActions.approveUndertakingDocSuccess(result.data.payload),
      );
    }
  } catch (error) {
    yield put(UndertakingActions.approveUndertakingDocError(error.message));
  }
  yield put(LoaderActions.hideLoader());
}

export function* rootSaga() {
  yield takeLatest(Types.GET_UNDERTAKING_DOC_REQUEST, getUndertakingDocSaga);
  yield takeLatest(
    Types.APPROVE_UNDERTAKING_DOC_REQUEST,
    approveUndertakingDocSaga,
  );
}

export default rootSaga;
