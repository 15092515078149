import React, { useEffect } from 'react';
import { Map, Placemark, YMaps, ZoomControl } from 'react-yandex-maps';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import markerIcon from 'public/images/icons/marker.png';
import * as S from 'common/style';

import * as MapModalActions from './actions';

const MapModal = ({ vendor }) => {
  const { orderDetail, showModal, mapModalVendor } = useSelector(
    state => state.mapModal,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (vendor) {
      dispatch(MapModalActions.getMapModalVendorRequest(vendor.id));
    }
  }, [vendor, dispatch]);

  if (!orderDetail) return <div></div>;

  const closeModal = () => {
    dispatch(MapModalActions.resetMapModal());
  };

  const checkStatement =
    orderDetail.deliveryAddress.lat && orderDetail.deliveryAddress.lon;

  return (
    <S.StyledModal
      isOpen={showModal}
      width={600}
      footerOptions={{
        customFooter: <div></div>,
      }}
      onClose={closeModal}
      headerOptions={{
        closeButton: true,
        title: 'Map',
      }}
    >
      <YMaps
        query={{ lang: 'tr_TR', apikey: process.env.REACT_APP_YANDEX_MAP_KEY }}
        version="2.1"
      >
        {checkStatement && (
          <Map
            height="300px"
            state={{
              center: [
                orderDetail.deliveryAddress.lat,
                orderDetail.deliveryAddress.lon,
              ],
              zoom: 13,
            }}
            width="100%"
          >
            <ZoomControl
              options={{
                size: 'small',
                zoomDuration: 500,
              }}
            />
            <Placemark
              geometry={[
                orderDetail.deliveryAddress.lat,
                orderDetail.deliveryAddress.lon,
              ]}
              options={{
                iconLayout: 'default#image',
                iconImageHref: `${markerIcon}`,
                iconImageSize: [71, 74],
                iconImageOffset: [-29, -58],
              }}
            />
            <Placemark
              geometry={[
                mapModalVendor.data && mapModalVendor.data.address.latitude,
                mapModalVendor.data && mapModalVendor.data.address.longitude,
              ]}
              options={{
                iconLayout: 'default#image',
                iconImageHref: `${markerIcon}`,
                iconImageSize: [71, 74],
                iconImageOffset: [-29, -58],
              }}
            />
          </Map>
        )}
      </YMaps>
    </S.StyledModal>
  );
};

MapModal.propTypes = {
  vendor: PropTypes.object,
};

MapModal.defaultProps = {
  vendor: undefined,
};

export { MapModal };
