export const BILLING_DETAIL_OPEN = 'BILLING_DETAIL_OPEN';
export const BILLING_DETAIL_CLOSE = 'BILLING_DETAIL_CLOSE';
export const BILLING_DETAIL_RESET_STATES = 'BILLING_DETAIL_RESET_STATES';

export const BILLING_DETAIL_UPLOAD_REQUEST = 'BILLING_DETAIL_UPLOAD_REQUEST';
export const BILLING_DETAIL_UPLOAD_SUCCESS = 'BILLING_DETAIL_UPLOAD_SUCCESS';
export const BILLING_DETAIL_UPLOAD_ERROR = 'BILLING_DETAIL_UPLOAD_ERROR';

export const BILLING_DETAIL_SAVE_BUTTON_DISABLED =
  'BILLING_DETAIL_SAVE_BUTTON_DISABLED';
export const BILLING_DETAIL_CANCEL_BUTTON_DISABLED =
  'BILLING_DETAIL_CANCEL_BUTTON_DISABLED';

export const BILLING_DETAIL_IS_UPLOADING = 'BILLING_DETAIL_IS_UPLOADING';
export const BILLING_DETAIL_IS_SUCCESSFUL = 'BILLING_DETAIL_IS_SUCCESSFUL';

export const BILLING_DETAIL_DELETE_IMAGE_REQUEST =
  'BILLING_DETAIL_DELETE_IMAGE_REQUEST';
export const BILLING_DETAIL_DELETE_IMAGE_SUCCESS =
  'BILLING_DETAIL_DELETE_IMAGE_SUCCESS';
export const BILLING_DETAIL_DELETE_IMAGE_ERROR =
  'BILLING_DETAIL_DELETE_IMAGE_ERROR';

export const BILLING_DETAIL_ADMIN_APPROVE_REQUEST =
  'BILLING_DETAIL_ADMIN_APPROVE_REQUEST';

export const BILLING_DETAIL_ADMIN_CANCEL_REQUEST =
  'BILLING_DETAIL_ADMIN_APPROVE_SUCCESS';

export const BILLING_DETAIL_SET_MODAL_MODE = 'BILLING_DETAIL_SET_MODAL_MODE';

// Admin refuse
export const BILLING_DETAIL_ADMIN_REFUSE_REQUEST =
  'BILLING_DETAIL_ADMIN_REFUSE_REQUEST';
export const BILLING_DETAIL_ADMIN_REFUSE_SUCCESS =
  'BILLING_DETAIL_ADMIN_REFUSE_SUCCESS';
export const BILLING_DETAIL_ADMIN_REFUSE_ERROR =
  'BILLING_DETAIL_ADMIN_REFUSE_ERROR';

// Get Billing Image
export const BILLING_DETAIL_GET_BILLING_IMAGE_REQUEST =
  'BILLING_DETAIL_GET_BILLING_IMAGE_REQUEST';
export const BILLING_DETAIL_GET_BILLING_IMAGE_SUCCESS =
  'BILLING_DETAIL_GET_BILLING_IMAGE_SUCCESS';
export const BILLING_DETAIL_GET_BILLING_IMAGE_ERROR =
  'BILLING_DETAIL_GET_BILLING_IMAGE_ERROR';

// Get Billing Detail
export const BILLING_DETAIL_BILLING_DETAIL_REQUEST =
  'BILLING_DETAIL_BILLING_DETAIL_REQUEST';

export const BILLING_DETAIL_BILLING_DETAIL_SUCCESS =
  'BILLING_DETAIL_BILLING_DETAIL_SUCCESS';

export const BILLING_DETAIL_BILLING_DETAIL_ERROR =
  'BILLING_DETAIL_BILLING_DETAIL_ERROR';
