import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import L from 'leaflet';

import { MAP_COLORS } from '../constant';
import { reverseCoordinates } from '../common';

const useVendorPolygon = ({ geomapStateKey, Actions, mapInstance }) => {
  const selectedVendor = useSelector(
    state => state[geomapStateKey].selectedVendor,
  );
  const showOnlySelectedVendor = useSelector(
    state => state[geomapStateKey].showOnlySelectedVendor,
  );

  const map = useSelector(state => state[geomapStateKey].map);

  const dispatch = useDispatch();

  const handlePolygonClick = useCallback(
    polygonData => {
      if (
        selectedVendor &&
        selectedVendor === polygonData.properties.warehouse
      ) {
        return;
      }

      dispatch(Actions.setSelectedVendor(polygonData.properties.warehouse));
    },
    [Actions, dispatch, selectedVendor],
  );

  const vendorPolygonsRef = useRef([]);

  useEffect(() => {
    if (mapInstance) {
      const polygons = [];
      const pane = mapInstance.createPane('vendorPolygon');
      pane.style.zIndex = 401;
      map?.features.forEach(polygonObject => {
        const isSelectedVendor =
          selectedVendor === polygonObject.properties.warehouse;

        if (showOnlySelectedVendor && !isSelectedVendor) {
          return;
        }

        if (showOnlySelectedVendor && isSelectedVendor) {
          const createdPolygon = L.polygon(
            reverseCoordinates(polygonObject.geometry.coordinates),
            {
              fillColor: MAP_COLORS.selectedVendor,
              color: '#000000',
              fillOpacity: 0.5,
              weight: 2,
              opacity: 1,
              interactive: true,
              pane: 'vendorPolygon',
            },
          );

          createdPolygon.on('click', () => {
            handlePolygonClick(polygonObject);
          });

          createdPolygon.addTo(mapInstance);

          polygons.push({
            instance: createdPolygon,
            data: polygonObject.properties,
          });

          return;
        }

        if (isSelectedVendor) {
          const createdPolygon = L.polygon(
            reverseCoordinates(polygonObject.geometry.coordinates),
            {
              fillColor: MAP_COLORS.selectedVendor,
              color: '#000000',
              fillOpacity: 0.5,
              weight: 2,
              opacity: 1,
              interactive: true,
              pane: 'vendorPolygon',
            },
          );

          createdPolygon.on('click', () => {
            handlePolygonClick(polygonObject);
          });

          createdPolygon.addTo(mapInstance);
          polygons.push({
            instance: createdPolygon,
            data: polygonObject.properties,
          });

          return;
        }

        const createdPolygon = L.polygon(
          reverseCoordinates(polygonObject.geometry.coordinates),
          {
            fillColor: MAP_COLORS.firmVendors,
            color: '#000000',
            fillOpacity: 0.3,
            weight: 1,
            pane: 'vendorPolygon',
            opacity: 1,
          },
        );

        createdPolygon.on('click', () => {
          handlePolygonClick(polygonObject);
        });

        createdPolygon.addTo(mapInstance);

        polygons.push({
          instance: createdPolygon,
          data: polygonObject.properties,
        });
      });

      vendorPolygonsRef.current = [...polygons];
    }

    return () => {
      vendorPolygonsRef.current.forEach(p => {
        p.instance.remove();
      });
      vendorPolygonsRef.current = [];
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, showOnlySelectedVendor, selectedVendor]);
};

export default useVendorPolygon;
