import {
  defaultStartTime,
  defaultEndTime,
} from './constants/defaultWorkingHours';

const getDataSource = () => [
  {
    dayOfWeek: 1,
    startTime: defaultStartTime,
    endTime: defaultEndTime,
  },
  {
    dayOfWeek: 2,
    startTime: defaultStartTime,
    endTime: defaultEndTime,
  },
  {
    dayOfWeek: 3,
    startTime: defaultStartTime,
    endTime: defaultEndTime,
  },
  {
    dayOfWeek: 4,
    startTime: defaultStartTime,
    endTime: defaultEndTime,
  },
  {
    dayOfWeek: 5,
    startTime: defaultStartTime,
    endTime: defaultEndTime,
  },
  {
    dayOfWeek: 6,
    startTime: defaultStartTime,
    endTime: defaultEndTime,
  },
  {
    dayOfWeek: 7,
    startTime: defaultStartTime,
    endTime: defaultEndTime,
  },
];

export { getDataSource };
