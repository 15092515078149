import { createAction } from 'utils/createAction';

import * as Types from './types';

// getFirms
export const getFirmRequest = createAction(Types.GET_FIRM_REQUEST);
export const getFirmSuccess = createAction(Types.GET_FIRM_SUCCESS);
export const getFirmError = createAction(Types.GET_FIRM_ERROR);

// getOperationalManagers
export const getOperationalManagersRequest = createAction(
  Types.GET_OPERATIONAL_MANAGER_REQUEST,
);

export const getOperationalManagersError = createAction(
  Types.GET_OPERATIONAL_MANAGER_ERROR,
);
export const getOperationalManagersSuccess = createAction(
  Types.GET_OPERATIONAL_MANAGER_SUCCESS,
);

// getRegionManagers

export const getRegionManagersRequest = createAction(
  Types.GET_REGION_MANAGER_REQUEST,
);
export const getRegionManagersError = createAction(
  Types.GET_REGION_MANAGER_ERROR,
);
export const getRegionManagersSuccess = createAction(
  Types.GET_REGION_MANAGER_SUCCESS,
);
