import { takeLatest, put, call } from 'redux-saga/effects';

import {
  getOrderApi,
  createAddressNoteApi,
  updateAddressNoteApi,
  getAddressNoteApi,
  deleteAddressNoteApi,
} from 'services/OrderDetail/api';
import { createStandardSaga } from 'utils/createStandardSaga';
import * as LoaderActions from 'components/Loader/actions';
import { open } from 'utils/notification';
import translate from 'utils/translate';

import * as OrderDetailActions from './actions';
import * as Types from './types';

const getOrderDetailSaga = createStandardSaga({
  callApi: getOrderApi,
  successAction: OrderDetailActions.getOrderDetailSuccess,
  errorAction: OrderDetailActions.getOrderDetailError,
});

const getOrderDetailWithActionsSaga = createStandardSaga({
  callApi: getOrderApi,
  successAction: OrderDetailActions.getOrderDetailWithActionsSuccess,
  errorAction: OrderDetailActions.getOrderDetailWithActionsError,
});

const getAddressNoteSaga = createStandardSaga({
  callApi: getAddressNoteApi,
  successAction: OrderDetailActions.getAddressNoteSuccess,
  errorAction: OrderDetailActions.getAddressNoteError,
});

function* createAddressNoteSaga({ payload }) {
  const { addressNote } = payload;
  yield put(LoaderActions.showLoader());
  try {
    const result = yield call(createAddressNoteApi, payload);
    if (result.data) {
      yield put(OrderDetailActions.createAddressNoteSuccess(payload));
      yield put(OrderDetailActions.getAddressNoteSuccess({ addressNote }));
      open('success', '', translate('orderdetailmodal:successNoteCreate'));
    } else {
      open('warning', translate('common:notif.warnTitle'), result.message);
    }
  } catch (error) {
    yield put(OrderDetailActions.createAddressNoteError(error.message));
  }

  yield put(LoaderActions.hideLoader());
}

const updateAddressNoteSaga = createStandardSaga({
  callApi: updateAddressNoteApi,
  successAction: OrderDetailActions.updateAddressNoteSuccess,
  errorAction: OrderDetailActions.updateAddressNoteError,
  successMessage: 'orderdetailmodal:successNoteUpdate',
  isSuccessMessageTitleVisible: false,
});

const deleteAddressNoteSaga = createStandardSaga({
  callApi: deleteAddressNoteApi,
  successAction: OrderDetailActions.deleteAddressNoteSuccess,
  errorAction: OrderDetailActions.deleteAddressNoteError,
  successMessage: 'orderdetailmodal:successNoteDelete',
  isSuccessMessageTitleVisible: false,
});

export function* rootSaga() {
  yield takeLatest(Types.ORDERDETAIL_REQUEST, getOrderDetailSaga);
  yield takeLatest(
    Types.ORDERDETAILWITHACTIONS_REQUEST,
    getOrderDetailWithActionsSaga,
  );
  yield takeLatest(Types.GET_ADDRESS_NOTE_REQUEST, getAddressNoteSaga);
  yield takeLatest(Types.CREATE_ADDRESS_NOTE_REQUEST, createAddressNoteSaga);
  yield takeLatest(Types.UPDATE_ADDRESS_NOTE_REQUEST, updateAddressNoteSaga);
  yield takeLatest(Types.DELETE_ADDRESS_NOTE_REQUEST, deleteAddressNoteSaga);
}

export default rootSaga;
