import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Button from 'components/v1/Button';
import AuthorizedComponent from 'components/AuthorizedComponent/AuthorizedComponent';

import * as S from './styles';
import {
  MODAL_MODES,
  authorizedComponentIds,
  invoiceStatus,
} from './constants';

const Footer = ({
  onOkButtonClickHandler,
  onCancelButtonClickHandler,
  denideBillingHandler,
  approveBillingHandler,
  isAdmin,
}) => {
  const {
    isSuccessful,
    isUploading,
    modalDetail: { mode: modalMode },
    billingDetail: { status },
    isSaveButtonDisabled: isSaveButtonDisabledFromUploader,
  } = useSelector(state => state.billingDetailModal);

  const { t } = useTranslation('billingUploadModal');

  const [isCancelVisible, setIsCancelVisible] = useState(true);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);
  const [okButtonText, setOkButtonText] = useState('ok');
  const [cancelButtonText, setCancelButtonText] = useState('cancel');

  const isAdminPanelVisible = useMemo(() => {
    if (!isAdmin) {
      return false;
    }

    if (modalMode === MODAL_MODES.UPLOAD && isSuccessful) {
      return true;
    }

    if (modalMode === MODAL_MODES.DETAIL && status === invoiceStatus.PENDING) {
      return true;
    }

    return false;
  }, [isAdmin, isSuccessful, modalMode, status]);

  useEffect(() => {
    if (modalMode === MODAL_MODES.DETAIL && status === invoiceStatus.APPROVED) {
      setIsCancelVisible(false);
    } else {
      setIsCancelVisible(true);
    }
  }, [modalMode, status]);

  useEffect(() => {
    if (modalMode === MODAL_MODES.UPLOAD && !isSuccessful) {
      setOkButtonText('upload');
    } else {
      setOkButtonText('ok');
    }
  }, [modalMode, isSuccessful]);

  useEffect(() => {
    if (
      modalMode === MODAL_MODES.DETAIL ||
      (modalMode === MODAL_MODES.UPLOAD && isSuccessful)
    ) {
      setCancelButtonText('delete');
    } else {
      setCancelButtonText('cancel');
    }
  }, [isSuccessful, modalMode]);

  useEffect(() => {
    if (
      isUploading ||
      (modalMode === MODAL_MODES.DETAIL && !isSuccessful && !status)
    ) {
      setIsSaveButtonDisabled(true);
    } else if (modalMode === MODAL_MODES.UPLOAD) {
      setIsSaveButtonDisabled(isSaveButtonDisabledFromUploader);
    } else {
      setIsSaveButtonDisabled(false);
    }
  }, [
    isSaveButtonDisabledFromUploader,
    isSuccessful,
    isUploading,
    modalMode,
    status,
  ]);

  return (
    <S.FooterContainer>
      {isAdminPanelVisible && (
        <S.AdminController>
          <Button
            kind="primary"
            id="accept-button"
            onClick={approveBillingHandler}
          >
            {t('approve')}
          </Button>
          <Button
            id="denide-button"
            kind="primary"
            onClick={denideBillingHandler}
          >
            {t('reject')}
          </Button>
        </S.AdminController>
      )}
      {isCancelVisible && modalMode === MODAL_MODES.DETAIL && (
        <AuthorizedComponent componentId={authorizedComponentIds.deleteButton}>
          <Button
            id="delete-button"
            kind="secondary"
            onClick={onCancelButtonClickHandler}
            disabled={isUploading}
          >
            {t(cancelButtonText)}
          </Button>
        </AuthorizedComponent>
      )}

      {isCancelVisible && modalMode !== MODAL_MODES.DETAIL && (
        <Button
          id="delete-button"
          kind="secondary"
          onClick={onCancelButtonClickHandler}
          disabled={isUploading}
        >
          {t(cancelButtonText)}
        </Button>
      )}

      {!isCancelVisible && isAdmin && (
        <Button
          id="delete-button"
          kind="secondary"
          onClick={onCancelButtonClickHandler}
          disabled={isUploading}
        >
          {t(cancelButtonText)}
        </Button>
      )}
      <Button
        kind="primary"
        onClick={onOkButtonClickHandler}
        disabled={isSaveButtonDisabled}
      >
        {t(okButtonText)}
      </Button>
    </S.FooterContainer>
  );
};

Footer.propTypes = {
  onCancelButtonClickHandler: PropTypes.func.isRequired,
  onOkButtonClickHandler: PropTypes.func.isRequired,
  denideBillingHandler: PropTypes.func.isRequired,
  approveBillingHandler: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

export default Footer;
