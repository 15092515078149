import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';

import Button from 'components/v1/Button';
import { VENDOR_SALE_STATUS_TRANSLATIONKEY } from 'constants/vendorSaleStatus';

const useVendorColumns = (handleEditVendor, handleDeleteVendor) => {
  const { t } = useTranslation();

  return [
    {
      title: t('vendor:status'),
      dataIndex: 'status',
      render: status => t(VENDOR_SALE_STATUS_TRANSLATIONKEY[status]),
    },
    {
      title: t('vendor:vendorName'),
      dataIndex: 'vendorName',
    },
    {
      title: t('vendor:city'),
      dataIndex: 'city',
    },
    {
      title: t('vendor:district'),
      dataIndex: 'district',
    },
    {
      title: t('vendor:town'),
      dataIndex: 'town',
    },
    {
      title: t('vendor:firm'),
      dataIndex: 'firmName',
    },
    {
      title: t('vendor:brands'),
      dataIndex: 'brands',
      render: brandList => brandList.map(brand => brand.brandName).join(', '),
    },
    {
      title: '',
      key: 'settings',
      render: (_, record) => (
        <Space size="middle">
          <Button kind="secondary" onClick={() => handleEditVendor(record)}>
            {t('edit')}
          </Button>
          <Button
            kind="secondary"
            style={{
              backgroundColor: 'transparent',
              padding: 0,
              dispaly: 'flex',
            }}
            onClick={() => handleDeleteVendor(record)}
          >
            <DeleteOutlined />
          </Button>
        </Space>
      ),
      align: 'right',
      width: '100',
      fixed: 'right',
    },
  ];
};
export { useVendorColumns };
