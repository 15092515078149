import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Select, Popover } from 'antd';
import { utilities } from '@getir/web-components';
import {
  KeyOutlined,
  LogoutOutlined,
  GlobalOutlined,
  MenuOutlined,
  LeftCircleOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import useResponsive from 'hooks/useResponsive';
import MobileMenu from 'components/MobileMenu';
import useUserInfo from 'hooks/useUserInfo';
import * as ROUTES from 'constants/routes';
import cookies from 'utils/cookie';
import copyToClipboard from 'utils/copyToClipboard';
import GetirLogo from 'common/svg/logo.svg';
import * as MainHeaderActions from 'components/MainHeader/actions';
import { logout } from 'utils/auth';
import { setItem, getItem } from 'utils/localStorage';
import { AuthorizationRoleNames } from 'constants/enums/authorizationRoleNames';
import MainHeader from 'components/MainHeader';

import * as HeaderActions from './actions';
import ChangePasswordModal from './ChangePasswordModal';
import * as S from './style';

const { noop } = utilities;

const Header = ({ collapsed, toggle }) => {
  const workingHours = useSelector(state => state.mainHeader.workingHours);
  const vendorId = useSelector(state => state.mainHeader.vendorInfo.id);
  const { data: roleList } = useSelector(state => state.header.roleListByUser);
  const dispatch = useDispatch();
  const { userInfo } = useUserInfo();
  const { t } = useTranslation();
  const { isTabletView, isMobileView, isMiniTabletView } = useResponsive();

  const isHeaderMobileView = isMobileView || isMiniTabletView || isTabletView;

  const savedRole = getItem('currentRole');

  const [isChangePasswordVisible, setIsChangePasswordVisible] = useState(false);
  const [selectedRole, setSelectedRole] = useState(undefined);
  const [isRoleListVisible, setIsRoleListVisible] = useState(undefined);

  useEffect(() => {
    if (Object.keys(userInfo).length > 0) {
      const { role, userId } = userInfo;
      setSelectedRole(role);
      dispatch(HeaderActions.getRolesByUserIdRequest(userId));
    }
  }, [userInfo, dispatch]);

  useEffect(() => {
    if (roleList) {
      setIsRoleListVisible(
        roleList.some(
          role =>
            role.roleName === AuthorizationRoleNames.ADMIN ||
            role.roleName === AuthorizationRoleNames.VENDOR_MANAGER,
        ),
      );
    }
  }, [roleList, isHeaderMobileView]);

  const handleAddNewUserSave = () => {
    setIsChangePasswordVisible(false);
  };

  const handleAddNewUserClose = () => {
    setIsChangePasswordVisible(false);
  };

  const googleSignOut = useCallback(
    () =>
      new Promise((resolve, reject) => {
        try {
          const googleAuthInstance = window.gapi?.auth2?.getAuthInstance();

          if (googleAuthInstance === undefined) resolve(true);

          googleAuthInstance.signOut().then(() => {
            googleAuthInstance.disconnect();
            resolve(true);
          });
        } catch (error) {
          reject(error);
        }
      }),
    [],
  );

  const userLogout = useCallback(() => {
    googleSignOut();
    const accessToken = cookies.get('accessToken');
    dispatch(MainHeaderActions.getCitiesSuccess([]));
    dispatch(MainHeaderActions.getVendorInfoSuccess([]));
    dispatch(MainHeaderActions.setCityId(undefined));
    dispatch(MainHeaderActions.setVendor(undefined));
    dispatch(HeaderActions.logoutRequest(accessToken));
    logout();
  }, [dispatch, googleSignOut]);

  const handleChangeRole = role => {
    const { value, label } = role;
    setItem('currentRole', label);
    setSelectedRole(label);

    const { userId } = userInfo;
    const data = {
      roleId: value,
      userId,
    };

    dispatch(HeaderActions.changeUserRoleRequest(data));
  };

  const convertRoleListValue = () => {
    const currentRole =
      roleList &&
      roleList.find(role => role.roleName === (savedRole || selectedRole));

    return {
      value: currentRole?.roleId,
      label: currentRole?.roleName,
    };
  };

  const menuItems = useMemo(
    () => (
      <S.UserPopoverMenu>
        <S.UserMenuItem
          key="changePassword"
          onClick={() => setIsChangePasswordVisible(true)}
        >
          <KeyOutlined /> {t('header:changePassword')}
        </S.UserMenuItem>
        <S.UserMenuItem
          key="copyBusinessId"
          onClick={() =>
            copyToClipboard(
              vendorId,
              'header:copyBusinessIdSuccess',
              'header:copyBusinessIdWarning',
            )
          }
        >
          <GlobalOutlined />
          {t('header:copyBusinessId')}
        </S.UserMenuItem>
        <S.UserMenuItem key="logout" onClick={userLogout}>
          <LogoutOutlined />
          {t('header:logout')}
        </S.UserMenuItem>
      </S.UserPopoverMenu>
    ),
    [t, userLogout, vendorId],
  );

  const roleListOptions = useMemo(
    () =>
      roleList &&
      roleList.map(role => ({
        value: role.roleId,
        label: role.roleName,
      })),
    [roleList],
  );

  return (
    <S.Header>
      <S.HeaderLeftSide>
        <S.SiteLayoutArrow>
          {React.createElement(collapsed ? MenuOutlined : LeftCircleOutlined, {
            className: 'trigger',
            onClick: toggle,
          })}
        </S.SiteLayoutArrow>
        <Link to={ROUTES.ACTIVE_ORDERS}>
          <img
            src={GetirLogo}
            alt="getirsu"
            title="getirsu"
            width={114}
            height={28}
          />
        </Link>
      </S.HeaderLeftSide>
      <S.HeaderRightSide>
        {!isHeaderMobileView && <MainHeader />}
        {isRoleListVisible && !isHeaderMobileView && (
          <S.RoleListWrapper>
            <S.PrefixIcon>
              <UserSwitchOutlined />
            </S.PrefixIcon>
            <Select
              placeholder={t('header:selectRole')}
              options={roleListOptions}
              onChange={handleChangeRole}
              labelInValue
              getPopupContainer={trigger => trigger.parentNode}
              value={convertRoleListValue()}
            />
          </S.RoleListWrapper>
        )}
        {!isHeaderMobileView && (
          <S.WorkingHoursWrapper>
            <S.StyledImage
              src="https://panel-fe.artisandev.getirapi.com/a6387960d4445743e4c0.svg"
              alt={t('header:workingHours')}
              width="20px"
              height="20px"
              objectFit="contain"
            />
            <S.WorkingHoursArea>
              <div>
                <S.WorkingHoursTitle>
                  {t('header:workingHours')}:{' '}
                </S.WorkingHoursTitle>
                <S.WorkingHoursContent>
                  {typeof workingHours === 'object' &&
                  workingHours !== null &&
                  workingHours.startTime !== null
                    ? `${workingHours.startTime} - ${workingHours.endTime}`
                    : '-'}
                </S.WorkingHoursContent>
              </div>
            </S.WorkingHoursArea>
          </S.WorkingHoursWrapper>
        )}
        <S.HeaderUserWrapper>
          {!isHeaderMobileView && (
            <Popover content={menuItems} placement="bottomLeft" trigger="hover">
              <S.HeaderUserCircle className="ant-dropdown-link" role="button">
                {userInfo.sub && userInfo.sub[0]}
              </S.HeaderUserCircle>
            </Popover>
          )}
          <S.DrawerIcon>
            {isHeaderMobileView && (
              <MobileMenu
                handleUserLogout={userLogout}
                handleChangePassword={() => setIsChangePasswordVisible(true)}
                roleListOptions={roleListOptions}
                handleChangeRole={handleChangeRole}
                isRoleListVisible={isRoleListVisible}
                selectedRole={convertRoleListValue()}
              />
            )}
          </S.DrawerIcon>
        </S.HeaderUserWrapper>
      </S.HeaderRightSide>
      <ChangePasswordModal
        size="445px"
        visible={isChangePasswordVisible}
        onSave={handleAddNewUserSave}
        onClose={handleAddNewUserClose}
      />
    </S.Header>
  );
};

Header.propTypes = {
  collapsed: PropTypes.bool,
  toggle: PropTypes.func,
};

Header.defaultProps = {
  collapsed: false,
  toggle: noop,
};

export default Header;
