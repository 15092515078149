export const UPDATE_WORKING_HOURS_REQUEST = 'UPDATE_WORKING_HOURS_REQUEST';
export const UPDATE_WORKING_HOURS_SUCCESS = 'UPDATE_WORKING_HOURS_SUCCESS';
export const UPDATE_WORKING_HOURS_ERROR = 'UPDATE_WORKING_HOURS_ERROR';

export const UPDATE_AUTO_OPEN_REQUEST = 'UPDATE_AUTO_OPEN_REQUEST';
export const UPDATE_AUTO_OPEN_SUCCESS = 'UPDATE_AUTO_OPEN_SUCCESS';
export const UPDATE_AUTO_OPEN_ERROR = 'UPDATE_AUTO_OPEN_ERROR';

export const GET_AUTO_OPEN_REQUEST = 'GET_AUTO_OPEN_REQUEST';
export const GET_AUTO_OPEN_SUCCESS = 'GET_AUTO_OPEN_SUCCESS';
export const GET_AUTO_OPEN_ERROR = 'GET_AUTO_OPEN_ERROR';
