import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';

import Modal from 'components/v1/Modal';
import SelectBox from 'components/v1/SelectBox';
import {
  DOMAIN_TYPES_ARRAY,
  OWNER_TYPE,
  REPORT_TYPES_ARRAY,
} from 'containers/KuzeydenReports/constants';
import Button from 'components/v1/Button';
import useUserInfo from 'hooks/useUserInfo';

import { REPORT_TYPES } from '../../constants';
import * as KuzeydenReportActions from '../../actions';

import * as S from './styles';

const { RangePicker } = DatePicker;
const CreateReportModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation('reports');
  const {
    formState: { errors },
    handleSubmit,
    control,
    watch,
  } = useForm({
    mode: 'onSubmit',
  });

  const watchReportType = watch('reportType');
  const { userInfo } = useUserInfo();
  const dispatch = useDispatch();
  const onSubmit = async data => {
    if (!userInfo?.userId) {
      return;
    }

    try {
      const { reportType, dateRange, domainType } = data;
      if (reportType === REPORT_TYPES.KUZEYDEN_SALES_REPORT) {
        const dataReportRequestBody = {
          startDateL: dayjs(dateRange[0]).format('YYYY-MM-DD'),
          endDateL: dayjs(dateRange[1]).format('YYYY-MM-DD'),
          isGetir10: false,
          isGetirBuyuk: false,
          isGetirSu: false,
        };

        domainType.forEach(domain => {
          switch (domain) {
            case 1:
              dataReportRequestBody.isGetir10 = true;
              break;
            case 3:
              dataReportRequestBody.isGetirBuyuk = true;
              break;
            case 4:
              dataReportRequestBody.isGetirSu = true;
              break;
            default:
              break;
          }
        });

        const salesReportRequest = {
          ownerType: OWNER_TYPE,
          report: REPORT_TYPES.KUZEYDEN_SALES_REPORT,
          ownerUserId: userInfo?.userId,
          dataReportRequestBody,
        };
        dispatch(KuzeydenReportActions.createReportRequest(salesReportRequest));
      }

      if (reportType === REPORT_TYPES.KUZEYDEN_STOCK_REPORT) {
        const stockReportRequest = {
          ownerType: OWNER_TYPE,
          report: REPORT_TYPES.KUZEYDEN_STOCK_REPORT,
          ownerUserId: userInfo?.userId,
          dataReportRequestBody: {
            domaintypeList: domainType,
          },
        };
        dispatch(KuzeydenReportActions.createReportRequest(stockReportRequest));
      }

      onClose();
    } catch (error) {
      onClose();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      headerOptions={{
        title: t('createNewReport'),
        closeButton: true,
      }}
      footerOptions={{
        cancelText: 'İptal',
        okText: t('createReport'),
        onCancelClick: onClose,
        onOkClick: handleSubmit,
        customFooter: <></>,
      }}
      width={450}
      onClose={onClose}
      destroyOnClose
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <S.Container>
          <S.OptionSection>
            <S.OptionLabel>{t('reportType')}:</S.OptionLabel>
            <Controller
              name="reportType"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <SelectBox
                  options={REPORT_TYPES_ARRAY}
                  size="medium"
                  placeholder={t('reportType')}
                  {...field}
                  status={errors.reportType && 'error'}
                />
              )}
            />
          </S.OptionSection>
          {watchReportType === REPORT_TYPES.KUZEYDEN_SALES_REPORT && (
            <S.OptionSection>
              <S.OptionLabel>{t('dateRange')}</S.OptionLabel>
              <Controller
                name="dateRange"
                control={control}
                rules={{ required: true }}
                status={errors.dateRange && 'error'}
                render={({ field }) => <RangePicker {...field} />}
              />
            </S.OptionSection>
          )}
          <S.OptionSection>
            <S.OptionLabel>{t('domainType')}:</S.OptionLabel>

            <Controller
              name="domainType"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <SelectBox
                  options={DOMAIN_TYPES_ARRAY}
                  size="medium"
                  placeholder={t('domainType')}
                  mode="multiple"
                  status={errors.domainType && 'error'}
                  {...field}
                />
              )}
            />
          </S.OptionSection>
        </S.Container>
        <S.Footer>
          <Button kind="secondary" size="medium" onClick={onClose}>
            {t('cancel')}
          </Button>
          <Button kind="primary" type="submit" size="medium">
            {t('createReport')}
          </Button>
        </S.Footer>
      </form>
    </Modal>
  );
};

CreateReportModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CreateReportModal;
