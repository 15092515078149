import { createAction } from 'utils/createAction';

import * as Types from './types';

export const getAlternativeHoursRequest = createAction(
  Types.ALTERNATIVE_HOURS_GET_REQUEST,
);

export const getAlternativeHoursSuccess = createAction(
  Types.ALTERNATIVE_HOURS_GET_SUCCESS,
);

export const getAlternativeHoursError = createAction(
  Types.ALTERNATIVE_HOURS_GET_ERROR,
);

export const updateAlternativeHoursRequest = createAction(
  Types.ALTERNATIVE_HOURS_UPDATE_REQUEST,
);

export const updateAlternativeHoursSuccess = createAction(
  Types.ALTERNATIVE_HOURS_UPDATE_SUCCESS,
);

export const updateAlternativeHoursError = createAction(
  Types.ALTERNATIVE_HOURS_UPDATE_ERROR,
);
