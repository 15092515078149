import styled from 'styled-components';
import { DatePicker, Select } from 'antd';

import Button from 'components/v1/Button';
import COLORS from 'constants/colors';
import breakpoint from 'constants/breakpoint';

const { RangePicker } = DatePicker;

const HeaderWrapper = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(4, minmax(min-content, 270px)) 1fr 80px;
  grid-column: 1 / 6;

  @media (max-width: ${breakpoint.MOBILE.MAX}px) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

const StyledDatePicker = styled(DatePicker)``;

const PriceContainer = styled.div`
  padding: 1rem 0 1rem 1rem;
  border-left: 4px solid ${COLORS.mainGetirColor};
  background: #f8f8f8;
  margin: 1rem 0;
  font-weight: 600;
  font-size: 18px;
`;

const ActionButton = styled(Button)`
  aspect-ratio: 1;
  width: 36px;
  padding: 8px;
`;

const StyledSelect = styled(Select)`
  .ant-select-selector {
    height: 100%;
    transition: none !important;
    cursor: pointer !important;

    &:hover {
      border: 2px solid ${COLORS.mainGetirColor} !important;
    }
    &:active {
      border-color: ${COLORS.mainGetirColor} !important;
      box-shadow: none;
    }
  }
`;

const FiltersArea = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  /* grid-template-rows: repeat(auto); */
  width: 100%;
  align-items: flex-end;

  .ant-select {
    height: auto !important;
  }

  .ant-space {
    grid-column-end: -1;
  }

  .ant-picker {
    margin-top: 3px;
    width: 100%;
  }
`;

const StyledRangePicker = styled(RangePicker)`
  border: 2px solid #dfdee2 !important;
  border-radius: 4px !important;
  height: 50px;
`;

export {
  StyledDatePicker,
  HeaderWrapper,
  PriceContainer,
  StyledSelect,
  FiltersArea,
  StyledRangePicker,
  ActionButton,
};
