import { takeLatest, select, put, call } from 'redux-saga/effects';

import {
  addUserApi,
  deleteUserApi,
  getUsersByFirmApi,
  getUsersByVendorApi,
  updateUserApi,
  getRolesByRoleGroupApi,
} from 'services/Users/api';
import { createStandardSaga } from 'utils/createStandardSaga';
import * as LoaderActions from 'components/Loader/actions';

import * as Types from './types';
import * as UserActions from './actions';
import { vendor, vendors } from './selectors';

const createUserSaga = createStandardSaga({
  callApi: addUserApi,
  successAction: UserActions.createUserSuccess,
  errorAction: UserActions.createUserError,
  successMessage: 'users:createUserSuccess',
  isSuccessMessageTitleVisible: false,
});

const deleteUserSaga = createStandardSaga({
  callApi: deleteUserApi,
  successAction: UserActions.deleteUserSuccess,
  errorAction: UserActions.deleteUserError,
  successMessage: 'users:deleteUserSuccess',
  isSuccessMessageTitleVisible: false,
});

const updateUserSaga = createStandardSaga({
  callApi: updateUserApi,
  successAction: UserActions.updateUserSuccess,
  errorAction: UserActions.updateUserError,
  successMessage: 'users:updateUserSuccess',
  isSuccessMessageTitleVisible: false,
});

const getUsersByFirmSaga = createStandardSaga({
  callApi: getUsersByFirmApi,
  successAction: UserActions.listUsersByFirmSuccess,
  errorAction: UserActions.listUsersByFirmError,
});

const getUsersByVendorSaga = createStandardSaga({
  callApi: getUsersByVendorApi,
  successAction: UserActions.listUsersByVendorSuccess,
  errorAction: UserActions.listUsersByVendorError,
});

function* getUserList() {
  yield put(LoaderActions.showLoader());
  const selectedVendor = yield select(vendor);
  const vendorList = yield select(vendors);

  const currentVendor = vendorList.find(
    item => item.value === selectedVendor.value,
  );

  try {
    if (currentVendor.isFirm) {
      const result = yield call(getUsersByFirmApi, currentVendor.value);
      if (result.data) {
        yield put(UserActions.listUsersByFirmSuccess(result.data.payload));
      }
    } else {
      const result = yield call(getUsersByVendorApi, {
        vendorId: currentVendor.value,
      });
      if (result.data) {
        yield put(
          UserActions.listUsersByFirmSuccess(result.data.payload.payload),
        );
      }
    }
  } catch (error) {
    yield put(UserActions.listUsersByFirmError(error.message));
  }
  yield put(LoaderActions.hideLoader());
}

const getRolesByRoleGroupSaga = createStandardSaga({
  callApi: getRolesByRoleGroupApi,
  successAction: UserActions.getRolesByRoleGroupSuccess,
  errorAction: UserActions.getRolesByRoleGroupError,
});

export function* rootSaga() {
  yield takeLatest(Types.CREATE_USER_REQUEST, createUserSaga);
  yield takeLatest(Types.GET_USERS_BY_FIRM_REQUEST, getUsersByFirmSaga);
  yield takeLatest(Types.GET_USERS_BY_VENDOR_REQUEST, getUsersByVendorSaga);
  yield takeLatest(Types.DELETE_USER_REQUEST, deleteUserSaga);
  yield takeLatest(Types.UPDATE_USER_REQUEST, updateUserSaga);
  yield takeLatest(Types.DELETE_USER_SUCCESS, getUserList);
  yield takeLatest(Types.CREATE_USER_SUCCESS, getUserList);
  yield takeLatest(Types.UPDATE_USER_SUCCESS, getUserList);
  yield takeLatest(
    Types.GET_ROLES_BY_ROLE_GROUP_REQUEST,
    getRolesByRoleGroupSaga,
  );
}

export default rootSaga;
