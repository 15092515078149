/**
 * @template T
 * @param {{new () => T}} model
 * @returns {(val: any) => T}
 */
// eslint-disable-next-line no-unused-vars
export const getModelInjector = model => val => val;

/**
 * @template T
 * @param {{new () => T}} model
 * @returns {(val: any) => T[]}
 */
// eslint-disable-next-line no-unused-vars
export const getModelListInjector = model => val =>
  val?.map(getModelInjector(model));
