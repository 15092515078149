import React from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import * as CS from 'common/style';
import { getCompoundFunction } from 'utils/function';

import * as S from './style';

const TextareaWrapper = ({
  fieldName,
  control,
  rules,
  placeholder,
  header,
  errors,
  isDisabled,
  onChange,
  style,
  lineCount,
  defaultValue,
  ...props
}) => (
  <CS.FormFieldWrapper style={style}>
    <Controller
      name={fieldName}
      control={control}
      rules={rules}
      render={({ field }) => (
        <S.TextareaField
          name={field.name}
          inputRef={field.ref}
          onChange={getCompoundFunction(onChange, field.onChange)}
          onBlur={field.onBlur}
          defaultValue={field.value ? `${field.value}` : ''}
          value={field.value ? field.value : ''}
          placeholder={placeholder}
          header={header}
          hasError={!!errors[fieldName]}
          disabled={isDisabled}
          rows={lineCount}
          {...props}
        />
      )}
    />
  </CS.FormFieldWrapper>
);

TextareaWrapper.defaultProps = {
  fieldName: '',
  control: {},
  rules: {},
  placeholder: '',
  header: '',
  errors: {},
  isDisabled: false,
  onChange: noop,
  style: {},
  lineCount: 3,
  defaultValue: '',
};

TextareaWrapper.propTypes = {
  fieldName: PropTypes.string,
  control: PropTypes.object,
  rules: PropTypes.object,
  placeholder: PropTypes.string,
  header: PropTypes.string,
  errors: PropTypes.object,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
  style: PropTypes.object,
  lineCount: PropTypes.number,
  defaultValue: PropTypes.string,
};

export default TextareaWrapper;
