import { useEffect, useState } from 'react';

const actionRow = {
  dayOfWeek: 8,
  oldStartDate: null,
  oldEndDate: null,
  newStartDate: null,
  newEndDate: null,
};

const convertWorkingHours = (workingHoursList, startField, endField) =>
  workingHoursList.map(item => ({
    dayOfWeek: item.dayOfWeek,
    [startField]: item.startTime,
    [endField]: item.endTime,
  }));

const useDataSource = workingHoursData => {
  const [workingHours, setWorkingHours] = useState(undefined);

  useEffect(() => {
    if (workingHoursData) {
      const { oldWorkingHours, newWorkingHours } = workingHoursData;

      const updatedOldWorkingHours = convertWorkingHours(
        newWorkingHours,
        'newStartDate',
        'newEndDate',
      );
      const updatedNewWorkingHours = convertWorkingHours(
        oldWorkingHours,
        'oldStartDate',
        'oldEndDate',
      );

      const mapped = updatedNewWorkingHours.reduce(
        (acc, item) => ((acc[item.dayOfWeek] = item), acc), // eslint-disable-line
        {},
      );

      const result = updatedOldWorkingHours.map(o => ({
        ...o,
        ...mapped[o.dayOfWeek],
      }));

      setWorkingHours([...result, actionRow]);
    }
  }, [workingHoursData]);

  return { workingHours };
};

export default useDataSource;
