import styled from 'styled-components';
import { Input } from 'antd';
import { Image } from '@getir/web-components';

const PaymentWarningContainer = styled.div`
  display: flex;
  background: rgb(255, 243, 205);
  padding: 12px 20px;
  border: 1px solid rgb(255, 238, 186);
  border-radius: 0.25rem;
  gap: 16px;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 16px;
  color: rgb(239, 166, 76);
`;

const PaymentWarningText = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: rgb(133, 100, 4);
`;

const PaymentWarningIcon = styled.div`
  display: flex;
  background: rgb(255, 243, 205);
  border: 1px solid rgb(255, 238, 186);
  border-radius: 0.25rem;
  gap: 16px;
  -webkit-box-align: center;
  align-items: center;
  box-sizing: border-box;
`;

const PaymentMethodsContainer = styled.div`
  display: grid;
  grid-template-columns: 44px 40vw 81px 64px;
  height: 72px;
  padding: 12px;
  gap: 44px;
  -webkit-box-align: center;
  align-items: center;
`;

const StyledInput = styled(Input)`
  height: 100%;
`;

const StyledImage = styled(Image)`
  margin: 0px;
`;

export {
  PaymentWarningIcon,
  PaymentWarningContainer,
  PaymentWarningText,
  PaymentMethodsContainer,
  StyledInput,
  StyledImage,
};
