import styled from 'styled-components';
import { Divider, Input } from '@getir/web-components';
export const Container = styled.div`
  .ant-table-title {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    min-width: 100%;
  }
  ${Divider.S.Divider} {
    border: 1px solid rgba(0, 0, 0, 0.06);
    width: 100%;
  }
  ${Input.S.Wrapper} {
    max-width: 270px;
  }
`;
export const CategoryFilter = styled.div`
  display: flex;
  width: 300px;
`;

export { ListTable, StyledToggle } from 'common/style';
