import styled from 'styled-components';

export const ButtonContainer = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
`;

export const ToolTipContainer = styled.div`
  position: absolute;
  top: 24px;
  left: 0;
  background-color: #fff;
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  padding: 8px;
  z-index: 100;
  width: auto;
  display: flex;
  gap: 4px;
  flex-direction: column;
  white-space: nowrap;
`;

export const Input = styled.input`
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #d4d4d4;
  padding: 4px 8px;
`;

export const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #191919;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
