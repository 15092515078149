import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Menu from 'components/Menu';
import Header from 'components/Header';
import useResponsive from 'hooks/useResponsive';
import { MOBILE_MAX_WIDTH } from 'common/constants';
import UndertakingModal from 'components/UndertakingModal';
import { getServerTime } from 'utils/syncronizedMoment';
import { useSocket } from 'containers/ActiveOrders/ActiveList/useSocket';
import VendorStatus from 'components/v1/VendorStatus/VendorStatus';

import * as S from './style';

const Layout = ({ children, isActiveOrderPage }) => {
  const { isMobileView, isMiniTabletView, isTabletView } = useResponsive();

  const [collapsed, setCollapsed] = useState(
    window.innerWidth < MOBILE_MAX_WIDTH,
  );
  const collapsedWidth = isMobileView ? 0 : 80;

  const handleToggle = () => {
    setCollapsed(!collapsed);
  };

  useSocket();

  useEffect(() => {
    getServerTime();
    const intervalTime = setInterval(getServerTime, 30000);

    return () => {
      clearInterval(intervalTime);
    };
  }, []);

  const isUnderTabletView = isMobileView || isMiniTabletView || isTabletView;

  return (
    <S.LayoutWrapper collapsed={collapsed}>
      <Header collapsed={collapsed} toggle={handleToggle} />
      <S.StyledSider
        trigger={null}
        collapsible
        collapsed={collapsed}
        collapsedWidth={collapsedWidth}
        width={285}
        $collapsed={collapsed}
      >
        <Menu />
      </S.StyledSider>
      <S.MainContentWrapper collapsed={collapsed}>
        <S.MainContent>
          {!isActiveOrderPage && isUnderTabletView && <VendorStatus />}
          {children}
        </S.MainContent>
      </S.MainContentWrapper>
      <UndertakingModal />
    </S.LayoutWrapper>
  );
};

Layout.defaultProps = {
  children: <div></div>,
  isActiveOrderPage: false,
};

Layout.propTypes = {
  children: PropTypes.node,
  isActiveOrderPage: PropTypes.bool,
};

export default Layout;
