import styled from 'styled-components';

import breakpoint from 'constants/breakpoint';

const Error404MainContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  position: relative;
  margin-top: 1rem;
`;

const Error404Container = styled.div`
  background-color: #fff;
  min-height: 467px;
  overflow: hidden;
  position: relative;

  box-shadow: 4px 4px 30px rgba(206, 206, 206, 0.25);
`;

const Error404Title = styled.p`
  color: #000;
  margin: 0;
  font-size: 24px;
  font-weight: 600;
`;

const Error404Description = styled.p`
  color: #697488;
  margin: 0;
  font-size: 18px;
`;

const Error404Content = styled.div`
  padding: 50px 0 0 40px;

  p {
    margin-bottom: 15px;
  }

  p:nth-child(2) {
    margin-bottom: 40px;
    max-width: 65%;
  }

  @media (max-width: ${breakpoint.TABLET.MAX}px) {
    padding-left: 30px;
    padding-right: 30px;
  }

  @media (max-width: ${breakpoint.MOBILE.MAX}px) {
    padding: 16px 16px 0;

    p:nth-child(2) {
      margin-bottom: 40px;
      max-width: 100%;
    }
  }
`;

const Error404ButtonContainer = styled.div`
  margin-top: 25px;
`;

const Error404ImageContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: -5px;

  @media (max-width: ${breakpoint.TABLET.MAX}px) {
    position: absolute;
  }

  @media (max-width: ${breakpoint.MOBILE.MAX}px) {
    position: relative;
    float: right;
    bottom: -70px;
  }
`;

const Error404PickerImage = styled.img`
  width: 361px;
  height: 418px;
  margin-right: -20px;

  @media (max-width: ${breakpoint.TABLET.MAX}px) {
    width: 330px;
    height: 360px;
  }

  @media (max-width: ${breakpoint.MOBILE.MAX}px) {
    width: 270px;
    height: 300px;
  }
`;

const Error404Body = styled.div`
  align-items: center;
  height: 100%;
  display: grid;

  @media (max-width: ${breakpoint.MOBILE.MAX}px) {
    height: auto;
  }
`;

export {
  Error404Body,
  Error404PickerImage,
  Error404ImageContainer,
  Error404ButtonContainer,
  Error404Content,
  Error404Description,
  Error404Title,
  Error404Container,
  Error404MainContainer,
};
