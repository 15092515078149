import styled from 'styled-components';
import { Modal } from 'antd';

export const AntdModal = styled(Modal)`
  .ant-modal-content {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    padding: 0px;
  }
  .ant-modal-title {
    width: 100%;
  }
  .ant-modal-header {
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 18px 24px !important;
    border-bottom: 0px !important;
    border-radius: 8px 8px 0px 0px !important;
    ${props => props.$headerStyle && props.$headerStyle}
  }
  .ant-modal-footer {
    border-top: 0px !important;
    width: 100%;
    padding: 12px 24px 24px 24px !important;
  }
  .ant-modal-body {
    padding: 12px 24px !important;
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 16px;
  button {
    padding: 14px 18px !important;
  }
`;

export const FooterInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  svg {
    line-height: 0px;
  }
`;

export const FooterInfoText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  color: #5d3ebc;
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 12px;
  position: relative;

  button {
    position: absolute;
    right: 0;
    background: #f6f6f6;
    width: 36px;
    height: 36px;
  }
`;

export const HeaderText = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #5d3ebc;
  text-align: center;
  flex-grow: 1;
`;
