import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';

import * as S from './styles';
const TooltipWrapper = ({ children, content, ...props }) => (
  <Tooltip
    title={content}
    placement="bottom"
    getPopupContainer={triggerNode => triggerNode}
    {...props}
  >
    {/* dont remove div Antd Tooltip works like that */}
    <S.ToolTipContainer>{children}</S.ToolTipContainer>
  </Tooltip>
);

TooltipWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  content: PropTypes.string,
};

TooltipWrapper.defaultProps = {
  content: '',
};

export default TooltipWrapper;
