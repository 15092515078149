import React from 'react';
import PropTypes from 'prop-types';

const ArrowUpTrayIcon = ({ fill, width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.16667 12.1667C2.16667 13.0871 2.91286 13.8333 3.83333 13.8333L12.1667 13.8333C13.0871 13.8333 13.8333 13.0871 13.8333 12.1667L13.8333 11.3333C13.8333 10.8731 14.2064 10.5 14.6667 10.5C15.1269 10.5 15.5 10.8731 15.5 11.3333L15.5 12.1667C15.5 14.0076 14.0076 15.5 12.1667 15.5L3.83333 15.5C1.99238 15.5 0.5 14.0076 0.5 12.1667L0.5 11.3333C0.5 10.8731 0.873096 10.5 1.33333 10.5C1.79357 10.5 2.16667 10.8731 2.16667 11.3333L2.16667 12.1667ZM4.07741 5.25592C3.75197 4.93049 3.75197 4.40285 4.07741 4.07741L7.41074 0.744077C7.56702 0.587797 7.77899 0.5 8 0.5C8.22101 0.5 8.43297 0.587797 8.58925 0.744077L11.9226 4.07741C12.248 4.40285 12.248 4.93049 11.9226 5.25592C11.5972 5.58136 11.0695 5.58136 10.7441 5.25592L8.83333 3.34518L8.83333 11.3333C8.83333 11.7936 8.46024 12.1667 8 12.1667C7.53976 12.1667 7.16667 11.7936 7.16667 11.3333L7.16667 3.34518L5.25592 5.25592C4.93049 5.58136 4.40285 5.58136 4.07741 5.25592Z"
      fill="inherit"
    />
  </svg>
);

ArrowUpTrayIcon.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

ArrowUpTrayIcon.defaultProps = {
  fill: '#697488',
  width: 16,
  height: 16,
};

export default ArrowUpTrayIcon;
