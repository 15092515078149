import React from 'react';

export default function Bold() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="inherit"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.25 12.4177C15.7397 12.1196 16.1529 11.7112 16.4567 11.225C16.7605 10.7389 16.9466 10.1885 17 9.61774C17.0093 9.10162 16.9168 8.58873 16.7278 8.10837C16.5388 7.628 16.2571 7.18958 15.8986 6.81813C15.5402 6.44667 15.1121 6.14948 14.6387 5.94351C14.1654 5.73755 13.6561 5.62685 13.14 5.61774H6.65002V19.6177H13.65C14.1412 19.6125 14.6265 19.5106 15.0782 19.3178C15.53 19.125 15.9394 18.8451 16.283 18.4941C16.6265 18.1431 16.8976 17.7278 17.0807 17.2721C17.2638 16.8163 17.3553 16.3289 17.35 15.8377V15.7177C17.3504 15.0248 17.1529 14.3463 16.781 13.7617C16.409 13.1771 15.8778 12.7109 15.25 12.4177V12.4177ZM8.65002 7.61774H12.85C13.2762 7.60455 13.6962 7.72202 14.0537 7.95439C14.4112 8.18676 14.6891 8.52291 14.85 8.91774C15.0129 9.44552 14.9602 10.0163 14.7035 10.5054C14.4468 10.9945 14.0069 11.362 13.48 11.5277C13.2754 11.5877 13.0632 11.618 12.85 11.6177H8.65002V7.61774ZM13.25 17.6177H8.65002V13.6177H13.25C13.6762 13.6045 14.0962 13.722 14.4537 13.9544C14.8112 14.1868 15.0891 14.5229 15.25 14.9177C15.4129 15.4455 15.3602 16.0163 15.1035 16.5054C14.8468 16.9945 14.4069 17.362 13.88 17.5277C13.6754 17.5877 13.4632 17.618 13.25 17.6177V17.6177Z"
        fill="inherit"
      />
    </svg>
  );
}
