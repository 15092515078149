import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Button from 'components/v1/Button';
import Layout from 'components/Layout';
import * as S from 'common/style';
import * as ROUTES from 'constants/routes';

import * as BrandActions from './actions';
import List from './List';

const Brands = () => {
  const history = useHistory();
  const { t } = useTranslation(['common', 'brands']);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(BrandActions.brandListRequest());
  }, [dispatch]);

  return (
    <Layout>
      <S.PageHeader>
        <S.PageTitle>{t('brands:title')}</S.PageTitle>
        <Button kind="primary" onClick={() => history.push(ROUTES.BRANDCREATE)}>
          {t('brands:addNew')}
        </Button>
      </S.PageHeader>
      <S.PageBody>
        <List />
      </S.PageBody>
    </Layout>
  );
};

export default Brands;
