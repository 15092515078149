import React, { useCallback, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import { Upload } from 'antd';
import { useTranslation } from 'react-i18next';

import Button from 'components/v1/Button';

import { ArrowUpTrayIcon } from '../Icons';

import * as S from './styles';
import { checkFileSize, checkFileType } from './utils';

const { Dragger } = Upload;

const DragdropUpload = (
  { onError, onChange, uploading, buttonText, validFileTypes, accept },
  ref,
) => {
  const { t } = useTranslation('billingUploadModal');
  const [selectedFile, setSelectedFile] = useState(undefined);
  const [imageBlob, setImageBlob] = useState(undefined);

  const clearFile = useCallback(() => {
    setSelectedFile(undefined);
    setImageBlob(undefined);
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      selectedFile,
      uploading,
      clearFile,
    }),
    [selectedFile, uploading, clearFile],
  );
  const props = {
    name: 'image',
    accept,
    multiple: false,
    onRemove: () => {
      setSelectedFile(undefined);
      setImageBlob(undefined);
      onChange(undefined);
    },
    beforeUpload: async file => {
      const isJpgOrPng = checkFileType(file.type, validFileTypes);
      if (!isJpgOrPng) {
        onError({
          kind: 'alert',
          content: 'dragAndDrop.fileTypeError',
        });

        return false;
      }
      const isLt4M = checkFileSize(file.size, 4);
      if (!isLt4M) {
        onError({
          kind: 'alert',
          content: 'dragAndDrop.sizeError',
        });

        return false;
      }
      onError({
        kind: 'warning',
        content: 'uploadBillingInfo',
      });
      setSelectedFile(file);
      onChange(file);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImageBlob(reader.result);
      };

      return false;
    },
    itemRender: () => undefined,
    withCredentials: true,
  };

  return (
    <S.DragdropContainer>
      {imageBlob ? (
        <S.ImageContainer>
          {uploading && (
            <S.LoadingContainer>
              <S.Loader /> {t('dragAndDrop.uploading')}
            </S.LoadingContainer>
          )}
          <img alt="img" src={imageBlob} />
        </S.ImageContainer>
      ) : (
        <Dragger {...props}>
          <S.InfoContainer>
            <Button kind="secondary">{buttonText}</Button>
            <S.UploadText>
              <ArrowUpTrayIcon />
              {t('dragAndDrop.title')}
            </S.UploadText>
            <S.InfoText>{t('dragAndDrop.info')}</S.InfoText>
          </S.InfoContainer>
        </Dragger>
      )}
    </S.DragdropContainer>
  );
};

DragdropUpload.propTypes = {
  onError: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  uploading: PropTypes.bool.isRequired,
  buttonText: PropTypes.string.isRequired,
  validFileTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  accept: PropTypes.string,
};

DragdropUpload.defaultProps = {
  onChange: () => {},
  accept: undefined,
};

export default React.forwardRef(DragdropUpload);
