export const VENDOR_LIST_REQUEST = 'VENDOR_LIST_REQUEST';
export const VENDOR_LIST_SUCCESS = 'VENDOR_LIST_SUCCESS';
export const VENDOR_LIST_ERROR = 'VENDOR_LIST_ERROR';

export const ADD_VENDOR_REQUEST = 'ADD_VENDOR_REQUEST';
export const ADD_VENDOR_SUCCESS = 'ADD_VENDOR_SUCCESS';
export const ADD_VENDOR_ERROR = 'ADD_VENDOR_ERROR';

export const EDIT_VENDOR_REQUEST = 'EDIT_VENDOR_REQUEST';
export const EDIT_VENDOR_SUCCESS = 'EDIT_VENDOR_SUCCESS';
export const EDIT_VENDOR_ERROR = 'EDIT_VENDOR_ERROR';

export const EDIT_FINANCIAL_INFO_REQUEST = 'EDIT_FINANCIAL_INFO_REQUEST';
export const EDIT_FINANCIAL_INFO_SUCCESS = 'EDIT_FINANCIAL_INFO_SUCCESS';
export const EDIT_FINANCIAL_INFO_ERROR = 'EDIT_FINANCIAL_INFO_ERROR';

export const DELETE_VENDOR_REQUEST = 'DELETE_VENDOR_REQUEST';
export const DELETE_VENDOR_SUCCESS = 'DELETE_VENDOR_SUCCESS';
export const DELETE_VENDOR_ERROR = 'DELETE_VENDOR_ERROR';

export const OPEN_FOR_SALE_REQUEST = 'OPEN_FOR_SALE_REQUEST';
export const OPEN_FOR_SALE_SUCCESS = 'OPEN_FOR_SALE_SUCCESS';
export const OPEN_FOR_SALE_ERROR = 'OPEN_FOR_SALE_ERROR';

export const CLOSE_FOR_SALE_REQUEST = 'CLOSE_FOR_SALE_REQUEST';
export const CLOSE_FOR_SALE_SUCCESS = 'CLOSE_FOR_SALE_SUCCESS';
export const CLOSE_FOR_SALE_ERROR = 'CLOSE_FOR_SALE_ERROR';

export const GET_DISTRICTS_REQUEST = 'GET_DISTRICTS_REQUEST';
export const GET_DISTRICTS_SUCCESS = 'GET_DISTRICTS_SUCCESS';
export const GET_DISTRICTS_ERROR = 'GET_DISTRICTS_ERROR';

export const GET_TOWNS_REQUEST = 'GET_TOWNS_REQUEST';
export const GET_TOWNS_SUCCESS = 'GET_TOWNS_SUCCESS';
export const GET_TOWNS_ERROR = 'GET_TOWNS_ERROR';

export const GET_BRANDS_REQUEST = 'GET_BRANDS_REQUEST';
export const GET_BRANDS_SUCCESS = 'GET_BRANDS_SUCCESS';
export const GET_BRANDS_ERROR = 'GET_BRANDS_ERROR';

export const GET_FIRM_REQUEST = 'GET_FIRM_REQUEST';
export const GET_FIRM_SUCCESS = 'GET_FIRM_SUCCESS';
export const GET_FIRM_ERROR = 'GET_FIRM_ERROR';

export const GET_OPERATIONAL_MANAGER_REQUEST = 'GET_OPERATIONAL_MANAGER';
export const GET_OPERATIONAL_MANAGER_SUCCESS =
  'GET_OPERATIONAL_MANAGER_SUCCESS';
export const GET_OPERATIONAL_MANAGER_ERROR = 'GET_OPERATIONAL_MANAGER_ERROR';

export const GET_REGION_MANAGER_REQUEST = 'GET_REGION_MANAGER_REQUEST';
export const GET_REGION_MANAGER_SUCCESS = 'GET_REGION_MANAGER_SUCCESS';
export const GET_REGION_MANAGER_ERROR = 'GET_REGION_MANAGER_ERROR';

export const SET_VENDOR_ID = 'SET_VENDOR_ID';
