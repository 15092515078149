import React from 'react';

export default function OrderedList() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="inherit"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 16.6177H4.5V17.1177H3.5V18.1177H4.5V18.6177H2.5V19.6177H5.5V15.6177H2.5V16.6177ZM3.5 9.61774H4.5V5.61774H2.5V6.61774H3.5V9.61774ZM2.5 11.6177H4.3L2.5 13.7177V14.6177H5.5V13.6177H3.7L5.5 11.5177V10.6177H2.5V11.6177ZM7.5 6.61774V8.61774H21.5V6.61774H7.5ZM7.5 18.6177H21.5V16.6177H7.5V18.6177ZM7.5 13.6177H21.5V11.6177H7.5V13.6177Z"
        fill="inherit"
      />
    </svg>
  );
}
