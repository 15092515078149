import { createAction } from 'utils/createAction';

import * as Types from './types';

// get active orders
export const getActiveOrdersRequest = createAction(
  Types.GET_ACTIVE_ORDERS_REQUEST,
);
export const getActiveOrdersSuccess = createAction(
  Types.GET_ACTIVE_ORDERS_SUCCESS,
);
export const getActiveOrdersError = createAction(Types.GET_ACTIVE_ORDERS_ERROR);

// get completed orders
export const getCompletedOrdersRequest = createAction(
  Types.GET_COMPLETED_ORDERS_REQUEST,
);
export const getCompletedOrdersSuccess = createAction(
  Types.GET_COMPLETED_ORDERS_SUCCESS,
);
export const getCompletedOrdersError = createAction(
  Types.GET_COMPLETED_ORDERS_ERROR,
);

// get canceled orders
export const getCanceledOrdersRequest = createAction(
  Types.GET_CANCELED_ORDERS_REQUEST,
);
export const getCanceledOrdersSuccess = createAction(
  Types.GET_CANCELED_ORDERS_SUCCESS,
);
export const getCanceledOrdersError = createAction(
  Types.GET_CANCELED_ORDERS_ERROR,
);

// get scheduled orders
export const getScheduledOrdersRequest = createAction(
  Types.GET_SCHEDULED_ORDERS_REQUEST,
);
export const getScheduledOrdersSuccess = createAction(
  Types.GET_SCHEDULED_ORDERS_SUCCESS,
);
export const getScheduledOrdersError = createAction(
  Types.GET_SCHEDULED_ORDERS_ERROR,
);

// change order status
export const changeOrderStatusRequest = createAction(
  Types.CHANGE_ORDER_STATUS_REQUEST,
);

export const changeOrderStatusSuccess = createAction(
  Types.CHANGE_ORDER_STATUS_SUCCESS,
);

export const changeOrderStatusError = createAction(
  Types.CHANGE_ORDER_STATUS_ERROR,
);

// socket orders
export const socketOrderAdd = createAction(Types.SOCKET_ORDER_ADD);

export const changeOrderAffectVendor = createAction(
  Types.CHANGE_ORDER_AFFECT_VENDOR,
);

export const resetDelayedList = createAction(Types.RESET_DELAYED_LIST);

// get user popup
export const getUserPopupRequest = createAction(Types.GET_USER_POPUP_REQUEST);
export const getUserPopupSuccess = createAction(Types.GET_USER_POPUP_SUCCESS);
export const getUserPopupError = createAction(Types.GET_USER_POPUP_ERROR);

// send popup feedback
export const sendPopupFeedbackRequest = createAction(
  Types.SEND_POPUP_FEEDBACK_REQUEST,
);
export const sendPopupFeedbackSuccess = createAction(
  Types.SEND_POPUP_FEEDBACK_SUCCESS,
);
export const sendPopupFeedbackError = createAction(
  Types.SEND_POPUP_FEEDBACK_ERROR,
);

export const addOrderToDelayedList = createAction(
  Types.ADD_ORDERS_TO_DELAYED_LIST,
);

export const changeDelayedOrderList = createAction(
  Types.CHANGE_DELAYED_ORDER_LIST,
);
