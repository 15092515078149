import * as Types from './types';

const initialState = {
  status: false,
};

function LoaderReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.SHOW_LOADER:
      return {
        ...state,
        status: payload,
      };
    case Types.HIDE_LOADER:
      return {
        ...state,
        status: payload,
      };
    default:
      return state;
  }
}

export default LoaderReducer;
