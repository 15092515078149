import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Empty, Space, Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import Button from 'components/v1/Button';
import Layout from 'components/Layout';
import SelectBoxAntd from 'components/Antd/Elements/SelectBox';
import {
  exportExcelFinancials,
  exportExcelDetailedFinancials,
} from 'utils/createExcelUrls';
import * as CS from 'common/style';
import {
  TRANSACTION_TYPES,
  TRANSACTION_TYPE_TRANSLATE,
} from 'constants/transactionTypes';

import * as S from './style';
import * as TransactionsActions from './actions';
import DetailedTransactions from './DetailedTransactions';
import { TABS } from './constants';

const dateFormat = 'DD/MM/YYYY';

const transactionTypeValues = {
  ORDER: 1,
  TRANSFER_TO_VENDOR: 2,
};

const defaultDates = [
  dayjs().subtract(7, 'd').format(dateFormat),
  dayjs().subtract(0, 'd').format(dateFormat),
];

const defaultFilters = {
  partnerId: undefined,
  endDate: dayjs().subtract(0, 'd').endOf('d').toISOString(),
  startDate: dayjs().subtract(7, 'd').startOf('d').toISOString(),
  transactionInfo: true,
  orderInfo: true,
  page: 0,
  size: 10,
  transactionOrderType: TRANSACTION_TYPES.ALL,
};

const FinancialTransactions = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const transactionTypes = [
    {
      value: transactionTypeValues.ORDER,
      label: t('financialTransactions:order'),
    },
    {
      value: transactionTypeValues.TRANSFER_TO_VENDOR,
      label: t('financialTransactions:transfer'),
    },
  ];

  const { data: transactionList, totalCount, currentBalance } = useSelector(
    state => state.financialTransactions.transactions,
  );
  const vendorInfo = useSelector(state => state.mainHeader.vendor);

  const [date, setDate] = useState(defaultDates);
  const [transactionType, setTransactionType] = useState(undefined);

  const [filters, setFilters] = useState(defaultFilters);
  const [pagination, setPagination] = useState({
    current: defaultFilters.page + 1,
    pageSize: defaultFilters.size,
    total: totalCount,
    size: 'small',
    showSizeChanger: true,
  });

  const [selectedTab, setSelectedTab] = useState(TABS.TRANSACTION_LIST);

  useEffect(() => {
    setPagination({
      ...pagination,
      total: totalCount,
    });
  }, [totalCount]); // eslint-disable-line

  useEffect(() => {
    if (vendorInfo && vendorInfo.value && filters.partnerId) {
      dispatch(
        TransactionsActions.getTransactionsRequest({
          ...filters,
          page: pagination.current - 1,
          size: pagination.pageSize,
        }),
      );
    }
  }, [pagination.current, pagination.pageSize, filters.partnerId]); // eslint-disable-line

  const transactionTypeColumns = {
    ORDER: t('financialTransactions:order'),
    TRANSFER_TO_VENDOR: t('financialTransactions:transfer'),
  };

  const TRANSACTION_TYPES_ORDER_OPTIONS = useMemo(
    () =>
      Object.keys(TRANSACTION_TYPES).map(key => ({
        value: TRANSACTION_TYPES[key],
        label: t(TRANSACTION_TYPE_TRANSLATE[key]),
      })),
    [t],
  );

  useEffect(() => {
    if (vendorInfo && vendorInfo.value) {
      setFilters({
        ...filters,
        partnerId: vendorInfo.value,
      });
    }
    // eslint-disable-next-line
  }, [vendorInfo, dispatch]);

  useEffect(() => {
    dispatch(TransactionsActions.resetTransactionList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.partnerId]);

  const handleOrderDetailClick = record => {
    const { id } = record;
    const detailData = {
      id,
      transactionType: TRANSACTION_TYPES.ALL,
    };

    dispatch(TransactionsActions.getTransactionDetailRequest(detailData));
  };

  const columns = [
    {
      title: t('financialTransactions:transactionDate'),
      dataIndex: 'transactionDate',
      render: text => dayjs(text).format('DD.MM.YYYY'),
    },
    {
      title: t('financialTransactions:documentDate'),
      dataIndex: 'documentDate',
      render: text => text && dayjs(text).format('DD.MM.YYYY'),
    },
    {
      title: t('financialTransactions:transactionType'),
      dataIndex: 'transactionType',
      render: text => `${transactionTypeColumns[text]}`,
    },
    {
      title: t('financialTransactions:explanation'),
      dataIndex: 'description',
    },
    {
      title: t('financialTransactions:vendorName'),
      dataIndex: 'vendorName',
    },
    {
      title: t('financialTransactions:progressBilling'),
      dataIndex: 'progressBilling',
      render: text => `₺${text}`,
    },
    {
      title: t('financialTransactions:payment'),
      dataIndex: 'paidFee',
      render: text => `₺${text}`,
    },
    {
      title: t('financialTransactions:balance'),
      dataIndex: 'balance',
      render: text => `₺${text}`,
    },
    {
      title: t('actions'),
      key: 'settings',
      render: (_, record) => (
        <Button kind="secondary" onClick={() => handleOrderDetailClick(record)}>
          {t('financialTransactions:showDetails')}
        </Button>
      ),
      align: 'right',
      width: '10%',
    },
  ];

  const handleFilterClick = () => {
    if (vendorInfo && vendorInfo.value && filters.partnerId) {
      if (selectedTab === TABS.TRANSACTION_LIST) {
        dispatch(
          TransactionsActions.getTransactionsRequest({
            ...filters,
            page: defaultFilters.page,
            size: defaultFilters.size,
          }),
        );
        setPagination({
          ...pagination,
          current: defaultFilters.page + 1,
          pageSize: defaultFilters.size,
        });

        return;
      }
      if (selectedTab === TABS.TRANSACTION_DETAILED_LIST) {
        dispatch(
          TransactionsActions.getDetailedTransactionsRequest({
            ...filters,
            page: defaultFilters.page,
            size: defaultFilters.size,
          }),
        );
      }
    }
  };

  const handleChangeDate = value => {
    setDate([dayjs(value[0], dateFormat), dayjs(value[1], dateFormat)]);

    setFilters({
      ...filters,
      startDate: dayjs(value[0], dateFormat).startOf('d').toISOString(),
      endDate: dayjs(value[1], dateFormat).endOf('d').toISOString(),
    });
  };

  const handleChangeTransactionType = value => {
    if (selectedTab === TABS.TRANSACTION_DETAILED_LIST) {
      setFilters({
        ...filters,
        transactionOrderType: value,
      });

      return;
    }
    setTransactionType(value);
    if (value === transactionTypeValues.ORDER) {
      setFilters({
        ...filters,
        orderInfo: true,
        transactionInfo: false,
      });

      return;
    }

    if (value === transactionTypeValues.TRANSFER_TO_VENDOR) {
      setFilters({
        ...filters,
        orderInfo: false,
        transactionInfo: true,
      });
    }
  };

  const clearFilters = () => {
    setDate(defaultDates);
    setTransactionType(undefined);

    const filterData = {
      ...defaultFilters,
      partnerId: vendorInfo?.value,
    };
    setFilters(filterData);

    if (vendorInfo && vendorInfo.value && filters.partnerId) {
      dispatch(TransactionsActions.getDetailedTransactionsRequest(filterData));
      dispatch(TransactionsActions.getTransactionsRequest(filterData));
    }
  };

  const exportToExcelFinancials = () => {
    if (vendorInfo && vendorInfo.value && filters.partnerId) {
      if (selectedTab === TABS.TRANSACTION_LIST) {
        const url = exportExcelFinancials(filters);

        window.open(url, '_blank');

        return;
      }
      if (selectedTab === TABS.TRANSACTION_DETAILED_LIST) {
        const url = exportExcelDetailedFinancials(filters);

        window.open(url, '_blank');
      }
    }
  };

  const handlePaginationChange = ({ current, pageSize }) => {
    setPagination({ ...pagination, current, pageSize });
  };

  const onChange = key => {
    setSelectedTab(key);
  };

  const items = [
    {
      key: TABS.TRANSACTION_LIST,
      label: t('financialTransactions:pageTitle'),
      children: (
        <S.StyledTable
          dataSource={transactionList || []}
          columns={columns}
          pagination={pagination}
          scroll={{ x: 1000 }}
          locale={{ emptyText: <Empty description={t('table.noData')} /> }}
          onChange={handlePaginationChange}
          rowKey="id"
        />
      ),
    },
    {
      key: TABS.TRANSACTION_DETAILED_LIST,
      label: t('financialTransactions:detailedFinancialTransactions'),
      children: (
        <DetailedTransactions filters={filters} setFilters={setFilters} />
      ),
    },
  ];

  return (
    <Layout>
      <CS.PageHeader>
        <CS.PageTitle>{t('financialTransactions:pageTitle')}</CS.PageTitle>
        <Button onClick={exportToExcelFinancials}>{t('excel')}</Button>
      </CS.PageHeader>
      <CS.PageBody>
        <S.FiltersArea>
          <S.RangePickerWrapper>
            <span>{t('selectDate')}</span>
            <S.StyledRangePicker
              size="middle"
              value={[dayjs(date[0], dateFormat), dayjs(date[1], dateFormat)]}
              format={dateFormat}
              onChange={handleChangeDate}
            />
          </S.RangePickerWrapper>
          <SelectBoxAntd
            placeholder={t('financialTransactions:chooseTransactionType')}
            options={
              selectedTab === TABS.TRANSACTION_LIST
                ? transactionTypes
                : TRANSACTION_TYPES_ORDER_OPTIONS
            }
            onChange={handleChangeTransactionType}
            value={
              selectedTab === TABS.TRANSACTION_LIST
                ? transactionType
                : filters.transactionOrderType
            }
          />
          <Space direction="vertical" align="end">
            <Space size="small">
              <Button kind="secondary" onClick={clearFilters}>
                {t('clear')}
              </Button>
              <Button onClick={handleFilterClick}>{t('filter')}</Button>
            </Space>
          </Space>
        </S.FiltersArea>
        <S.CurrentBalanceArea>
          {t('financialTransactions:currentBalanceText')} : ₺{currentBalance}
        </S.CurrentBalanceArea>

        <Tabs
          defaultActiveKey={TABS.TRANSACTION_LIST}
          items={items}
          onChange={onChange}
        />
      </CS.PageBody>
    </Layout>
  );
};

export default FinancialTransactions;
