export default {
  BASE_URI: '/',
  ENV: process.env.REACT_APP_ENV || 'development',
  REACT_APP_API_PREFIX:
    process.env.REACT_APP_API_PREFIX ||
    'https://water-api-gateway.getirsudev.getirapi.com/v1',
  REACT_APP_SOCKET_ADDRESS:
    process.env.REACT_APP_SOCKET_ADDRESS ||
    'https://water-socket-service.getirsudev.getirapi.com/websocket/',
  REACT_APP_SOCKETIO_ADDRESS:
    process.env.REACT_APP_SOCKETIO_ADDRESS ||
    'wss://water-api-gateway.getirsudev.getirapi.com/w-panel',
  REACT_APP_YANDEX_PLACES_KEY:
    process.env.REACT_APP_YANDEX_PLACES_KEY ||
    'a8f01f30-a7a6-4ebe-95e4-d31ecf93051c',
  REACT_APP_YANDEX_MAP_KEY:
    process.env.REACT_APP_YANDEX_MAP_KEY ||
    'a9942c28-89b1-4347-9faa-f0eb81c265db',
  REACT_APP_RECAPTCHA_SITE_KEY:
    process.env.REACT_APP_RECAPTCHA_SITE_KEY ||
    '6LdpmsYcAAAAABLkfT1bk2ZT42js4DO6Vvq_Iptu',
  REACT_APP_GOOGLE_SSO_CLIENT_ID:
    process.env.REACT_APP_GOOGLE_SSO_CLIENT_ID ||
    '101316913464-c67r3tv9980a8aobrh6vmmlr96nhumml.apps.googleusercontent.com',
  REACT_APP_KUZEYDEN_STOCK_REPORT_ID:
    process.env.REACT_APP_KUZEYDEN_STOCK_REPORT_ID ||
    '65ae61cbb962c64dd71ce582',
  REACT_APP_KUZEYDEN_SALES_REPORT_ID:
    process.env.REACT_APP_KUZEYDEN_SALES_REPORT_ID ||
    '65ae615eb962c64dd71ce581',
  GOOGLE_AUTH: {
    JS_URI: 'https://accounts.google.com/gsi/client',
    DOM_ID: 'google-auth',
    CLIENT_ID:
      process.env.REACT_APP_GOOGLE_SSO_CLIENT_ID ||
      '101316913464-c67r3tv9980a8aobrh6vmmlr96nhumml.apps.googleusercontent.com',
  },
};
