import { getRequest, putRequest } from 'lib/axios';

import api from './apiRoutes';

export const getPermListRootsApi = params =>
  getRequest(api.getPermListRoots(), params);
export const getPermListChildrenApi = params =>
  getRequest(api.getPermListChildren(params));
export const getRoleListApi = () => getRequest(api.getRoleList());
export const updatePermApi = ({ componentId, ...rest }) =>
  putRequest(api.updatePerm(componentId), rest);
