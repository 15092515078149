import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import calendarPlug from 'dayjs/plugin/calendar';

import { DURATION_TYPES } from 'constants/durationTypes';
import * as MapModalActions from 'components/MapModal/actions';
import { DurationCell } from 'components/DurationCell/DurationCell';
import * as OrderDetailActions from 'components/OrderDetailModal/actions';
import {
  ORDER_STATUS_CODES,
  ORDER_STATUS_TRANSLATEKEY,
  ORDER_STATUS,
} from 'constants/orderStatus';
import * as ActiveOrderActions from 'containers/ActiveOrders/actions';
import * as CourierListModalActions from 'components/CourierListModal/actions';

import Badge from '../Badge';
import { ClockIcon, MapPinIcon } from '../Icons';
import Button from '../Button/Button';

import * as S from './style';
import('dayjs/locale/tr');

const MobileOrderCard = ({ order, setSelectedVendor, isCanceledOrders }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isScheduledOrder = order?.status === ORDER_STATUS.SCHEDULED;

  const OrderStatus = useMemo(() => {
    const text = order?.status;
    let type = 'waiting';
    let icon;

    if (text <= 700) {
      type = 'primary';
      icon = <ClockIcon />;
    } else if (text <= 1000) {
      type = 'success';
    } else {
      type = 'failed';
    }

    return (
      <Badge icon={icon} type={type}>
        {t(ORDER_STATUS_TRANSLATEKEY[ORDER_STATUS_CODES[text]])}
      </Badge>
    );
  }, [order?.status, t]);

  const totalTime = useMemo(() => {
    if (order && order?.paymentDate) {
      return (
        <DurationCell
          firstDate={order.deliveryDate || dayjs().toISOString()}
          secondDate={order.paymentDate}
          hideBar
          limitDuration={DURATION_TYPES.DELAYED_ORDER}
          withBadge
          isActive={!order.deliveryDate}
          isMobile
        />
      );
    }

    return null;
  }, [order]);

  const scheduledDateColumn = useMemo(() => {
    if (order && order.scheduledDate && order.scheduledDateEnd) {
      dayjs.extend(calendarPlug);

      const d = new Date(order.scheduledDate);
      const dEnd = new Date(order.scheduledDateEnd);
      const dEndText = dayjs(dEnd).format('HH:mm');
      const startDateText = dayjs(d)
        .locale('tr')
        .calendar(null, {
          sameDay: `[${t('common:today')}] H:mm`,
          nextDay: `[${t('common:tomorrow')}] H:mm`,
          nextWeek: 'dddd H:mm',
          lastDay: `[${t('common:yesterday')}] H:mm`,
          lastWeek: 'dddd H:mm',
          sameElse: 'DD.MM.YYYY H:mm',
        });

      return (
        <Badge
          icon={<ClockIcon />}
          type="success"
          key="scheduled"
        >{`${startDateText} - ${dEndText}`}</Badge>
      );
    }

    return '';
  }, [order, t]);

  const sendDeliverAction = id => {
    dispatch(
      ActiveOrderActions.changeOrderStatusRequest({
        id,
        status: ORDER_STATUS.DELIVERED,
      }),
    );
  };

  const sendOrderAction = id => {
    dispatch(CourierListModalActions.sendSmsModalOpen(id));
  };
  const sendAcceptAction = id => {
    dispatch(
      ActiveOrderActions.changeOrderStatusRequest({
        id,
        status: ORDER_STATUS.PREPARING,
      }),
    );
  };
  const mapModalOpenAction = id => {
    dispatch(MapModalActions.getMapModalRequest(id));
  };

  const modalOpenAction = id => {
    dispatch(OrderDetailActions.getOrderDetailWithActionsRequest(id));
    setSelectedVendor(order);
  };

  const customerName = useMemo(() => {
    if (!order) return '';
    const names = order.client.name.split(' ');

    if (isScheduledOrder) {
      return `${names[0].charAt(0)}*** ${names[1].charAt(0)}.`;
    }

    return `${names[0]} ${names[1].charAt(0)}.`;
  }, [isScheduledOrder, order]);

  if (!order) return null;

  return (
    <S.Container>
      <S.OrderDetailContainer>
        <S.InfoContainer>
          <S.LeftContainer>
            <S.Name>
              {customerName} <span>#{order.confirmationCode}</span>
            </S.Name>
            {!isScheduledOrder && (
              <S.Adress>{order.deliveryAddress?.address}</S.Adress>
            )}
          </S.LeftContainer>
          <S.RightContainer>
            {!isScheduledOrder && (
              <S.MapButton
                onClick={() => {
                  mapModalOpenAction(order.id);
                  setSelectedVendor(order);
                }}
                kind="secondary"
              >
                <MapPinIcon />
              </S.MapButton>
            )}
          </S.RightContainer>
        </S.InfoContainer>
        <S.BadgesContainer>
          {OrderStatus}
          {!isCanceledOrders && !isScheduledOrder && totalTime}
          {isScheduledOrder && scheduledDateColumn}
        </S.BadgesContainer>
      </S.OrderDetailContainer>
      <S.ProductsContainer>
        {order.products?.map(product => (
          <S.ProductContainer key={product.id}>
            <S.ProductName>
              <span>{product?.name}</span>
            </S.ProductName>
            <S.ProductCount>{product?.count} Adet</S.ProductCount>
          </S.ProductContainer>
        ))}
      </S.ProductsContainer>
      <S.ActionContainer>
        <Button
          onClick={() => modalOpenAction(order.id)}
          id="detail"
          kind="secondary"
          size="small"
        >
          {t('common:detail')}
        </Button>

        {order.status === ORDER_STATUS.VERIFYING && (
          <Button
            kind="primary"
            size="small"
            onClick={() => sendAcceptAction(order.id)}
          >
            {t('activeOrders:acceptOrder')}
          </Button>
        )}

        {order.status === ORDER_STATUS.PREPARING && (
          <Button
            kind="primary"
            size="small"
            onClick={() => {
              sendOrderAction(order.id);
            }}
          >
            {t('activeOrders:sendOrder')}
          </Button>
        )}
        {order.status === ORDER_STATUS.ONWAY && (
          <Button
            kind="primary"
            size="small"
            onClick={() => sendDeliverAction(order.id)}
          >
            {t('activeOrders:deliverOrder')}
          </Button>
        )}
      </S.ActionContainer>
    </S.Container>
  );
};

MobileOrderCard.propTypes = {
  order: PropTypes.object,
  setSelectedVendor: PropTypes.func.isRequired,
  isCanceledOrders: PropTypes.bool,
};

MobileOrderCard.defaultProps = {
  order: undefined,
  isCanceledOrders: false,
};

export default MobileOrderCard;
