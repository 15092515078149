import { takeLatest, put, call } from 'redux-saga/effects';

import {
  getRoleListApi,
  getRoleUsersByRoleIdApi,
  createRoleApi,
  updateRoleApi,
  getPermissionsByRoleIdApi,
  deleteRoleApi,
  getRoleGroupNamesApi,
} from 'services/UsersPerms/Roles/api';
import { createStandardSaga } from 'utils/createStandardSaga';
import * as ROUTES from 'constants/routes';
import * as LoaderActions from 'components/Loader/actions';
import { open } from 'utils/notification';
import translate from 'utils/translate';

import * as Types from './types';
import * as RolesActions from './actions';

const getRoleListSaga = createStandardSaga({
  callApi: getRoleListApi,
  successAction: RolesActions.getRoleListSuccess,
  errorAction: RolesActions.getRoleListError,
});

const getRoleUserByRoleIdSaga = createStandardSaga({
  callApi: getRoleUsersByRoleIdApi,
  successAction: RolesActions.getRoleUserByRoleIdSuccess,
  errorAction: RolesActions.getRoleUserByRoleIdError,
});

const createRoleSaga = createStandardSaga({
  callApi: createRoleApi,
  successAction: RolesActions.createRoleSuccess,
  errorAction: RolesActions.createRoleError,
  successPushRoute: ROUTES.AUTH_ROLES,
  successMessage: 'authRolesPage:addNewRolePage.successRoleMessage',
});

const updateRoleSaga = createStandardSaga({
  callApi: updateRoleApi,
  successAction: RolesActions.updateRoleSuccess,
  errorAction: RolesActions.updateRoleError,
  successPushRoute: ROUTES.AUTH_ROLES,
  successMessage: 'authRolesPage:editRolePage.successRoleMessage',
});

const getPermsByRoleIdSaga = createStandardSaga({
  callApi: getPermissionsByRoleIdApi,
  successAction: RolesActions.getPermsByRoleIdSuccess,
  errorAction: RolesActions.getPermsByRoleIdError,
});

function* deleteRoleSaga({ payload }) {
  yield put(LoaderActions.showLoader());
  try {
    const result = yield call(deleteRoleApi, payload);
    if (result.data) {
      yield put(RolesActions.deleteRoleSuccess(payload));
      open(
        'success',
        '',
        translate('authRolesPage:deleteRoleModal.successRoleMessage'),
      );
    } else {
      open('warning', translate('common:notif.warnTitle'), result.message);
    }
  } catch (error) {
    yield put(RolesActions.deleteRoleError(error.message));
  }
  yield put(LoaderActions.hideLoader());
}

const getRoleGroupNamesSaga = createStandardSaga({
  callApi: getRoleGroupNamesApi,
  successAction: RolesActions.getRoleGroupNamesSuccess,
  errorAction: RolesActions.getRoleGroupNamesError,
});

export function* rootSaga() {
  yield takeLatest(Types.GET_ROLE_LIST_REQUEST, getRoleListSaga);
  yield takeLatest(
    Types.GET_ROLE_USERS_BY_ROLE_ID_REQUEST,
    getRoleUserByRoleIdSaga,
  );
  yield takeLatest(Types.CREATE_ROLE_REQUEST, createRoleSaga);
  yield takeLatest(Types.UPDATE_ROLE_REQUEST, updateRoleSaga);
  yield takeLatest(Types.GET_PERMS_BY_ROLE_ID_REQUEST, getPermsByRoleIdSaga);
  yield takeLatest(Types.DELETE_ROLE_REQUEST, deleteRoleSaga);
  yield takeLatest(Types.GET_ROLE_GROUP_NAMES_REQUEST, getRoleGroupNamesSaga);
}

export default rootSaga;
