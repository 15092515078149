import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import * as OrderDetailActions from 'components/OrderDetailModal/actions';
import * as BillingDetailModalActions from 'components/v1/BillingDetailModal/actions';
import {
  MODAL_MODES,
  authorizedComponentIds,
} from 'components/v1/BillingDetailModal/constants';
import {
  ORDER_STATUS,
  ORDER_STATUS_CODES,
  ORDER_STATUS_TRANSLATEKEY,
} from 'constants/orderStatus';
import { DurationCell } from 'components/DurationCell/DurationCell';
import { DURATION_TYPES } from 'constants/durationTypes';
import Badge from 'components/v1/Badge/Badge';
import TooltipWrapper from 'components/v1/TooltipWrapper/TooltipWrapper';
import Button from 'components/v1/Button/Button';
import AuthorizedComponent from 'components/AuthorizedComponent/AuthorizedComponent';

import { invoiceStatus } from './constants';
import { ActionButton } from './style';

const usePreviousOrderColumns = () => {
  const dispatch = useDispatch();
  const modalOpenAction = id => {
    dispatch(OrderDetailActions.getOrderDetailRequest(id));
  };
  const setBillingDetailAction = data => {
    dispatch(
      BillingDetailModalActions.getBillingDetailRequest({
        orderId: data.orderId,
      }),
    );
  };
  const modalOpenBillingAction = data => {
    dispatch(BillingDetailModalActions.openBillingModal(data));
  };
  const { t } = useTranslation();
  const dateColumn = text => {
    if (text && text.length) {
      const d = new Date(text);

      return dayjs(d).format('DD.MM.YYYY, H:mm:ss');
    }

    return '';
  };

  return [
    {
      title: t('activeOrders:columns.confirmationCode'),
      dataIndex: 'confirmationCode',
    },
    {
      title: t('activeOrders:columns.customer'),
      dataIndex: 'client',
      render: text => text.name,
    },
    {
      title: t('activeOrders:columns.orderPrice'),
      dataIndex: 'totalPriceText',
    },
    {
      title: t('activeOrders:columns.orderDate'),
      dataIndex: 'paymentDate',
      render: dateColumn,
    },
    {
      title: t('activeOrders:columns.confirmationDate'),
      dataIndex: 'approvedDate',
      render: dateColumn,
    },
    {
      title: t('activeOrders:columns.cancellationDate'),
      dataIndex: 'cancelDate',
      render: dateColumn,
    },
    {
      title: t('activeOrders:columns.status'),
      dataIndex: 'status',
      render: text => t(ORDER_STATUS_TRANSLATEKEY[ORDER_STATUS_CODES[text]]),
    },
    {
      title: t('activeOrders:columns.confirmationTime'),
      dataIndex: 'confirmationTime',
      render: (text, row) => {
        if (row.paymentDate && row.approvedDate) {
          return (
            <DurationCell
              firstDate={row.approvedDate}
              secondDate={row.paymentDate}
              limitDuration={DURATION_TYPES.APPROVE}
            />
          );
        }

        return '';
      },
    },
    // hazırlanma süresi yazılacak
    {
      title: t('activeOrders:columns.preparationTime'),
      dataIndex: 'preparationTime',
      render: (text, row) => {
        if (row.approvedDate && row.preparedDate) {
          return (
            <DurationCell
              firstDate={row.preparedDate}
              secondDate={row.approvedDate}
              limitDuration={DURATION_TYPES.PREPARE}
            />
          );
        }

        return '';
      },
    },
    {
      title: t('activeOrders:columns.totalTime'),
      dataIndex: 'totalTime',
      render: (text, row) => {
        if (row.paymentDate && row.deliveryDate) {
          return (
            <DurationCell
              firstDate={row.deliveryDate}
              secondDate={row.paymentDate}
              hideBar
            />
          );
        }

        return '';
      },
    },
    {
      title: t('activeOrders:columns.billingStatus'),
      width: 150,
      render: (text, row) => {
        const { invoice } = row;

        if (!invoice || invoice.status === invoiceStatus.DELETED) {
          return <span>{t('activeOrders:billingStatus.noUploadedBill')}</span>;
        }

        if (invoice.status === invoiceStatus.APPROVED) {
          return (
            <Badge type="success">
              {t('activeOrders:billingStatus.success')}
            </Badge>
          );
        }
        if (invoice.status === invoiceStatus.PENDING) {
          return (
            <Badge type="waiting">
              {t('activeOrders:billingStatus.waiting')}
            </Badge>
          );
        }
        if (invoice.status === invoiceStatus.CANCELED) {
          return (
            <Badge type="failed">
              {t('activeOrders:billingStatus.canceled')}
            </Badge>
          );
        }

        return null;
      },
    },
    {
      title: t('activeOrders:columns.billing'),
      width: 150,
      render: (text, row) => {
        const { invoice } = row;

        if (!invoice || invoice.status === invoiceStatus.DELETED) {
          return null;
        }

        return (
          <Button
            kind="secondary"
            onClick={() => {
              setBillingDetailAction({
                orderId: row.id,
              });
              modalOpenBillingAction({
                orderId: row.id,
                mode: MODAL_MODES.DETAIL,
              });
            }}
          >
            {t('activeOrders:billingDetail')}
          </Button>
        );
      },
    },
    {
      title: t('activeOrders:columns.action'),
      width: 100,
      fixed: 'right',
      render: (text, row) => {
        let canBillingUpload =
          row.status === ORDER_STATUS.RATED ||
          row.status === ORDER_STATUS.DELIVERED;

        if (row.invoice) {
          if (
            row.invoice.status === invoiceStatus.APPROVED ||
            row.invoice.status === invoiceStatus.PENDING
          ) {
            canBillingUpload = false;
          }
        }

        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              gap: '6px',
              alignItems: 'center',
            }}
          >
            <AuthorizedComponent
              componentId={authorizedComponentIds.uploadButton}
            >
              <TooltipWrapper
                key={`${row.id}TooltipBilling`}
                content="Fatura Yükle"
                open={canBillingUpload === false ? false : undefined}
              >
                <ActionButton
                  kind="primary"
                  onClick={() =>
                    modalOpenBillingAction({
                      orderId: row.id,
                      mode: MODAL_MODES.UPLOAD,
                    })
                  }
                  disabled={!canBillingUpload}
                >
                  <svg
                    width="14"
                    height="18"
                    viewBox="0 0 14 18"
                    fill="inherit"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M1.6875 0.25H4.5C6.22589 0.25 7.625 1.64911 7.625 3.375V4.9375C7.625 5.80044 8.32455 6.5 9.1875 6.5H10.75C12.4759 6.5 13.875 7.89911 13.875 9.625V16.1875C13.875 17.0504 13.1754 17.75 12.3125 17.75H1.6875C0.824555 17.75 0.125 17.0504 0.125 16.1875V1.8125C0.125 0.949555 0.824555 0.25 1.6875 0.25ZM7.625 9C7.625 8.65482 7.34518 8.375 7 8.375C6.65482 8.375 6.375 8.65482 6.375 9V10.875H4.5C4.15482 10.875 3.875 11.1548 3.875 11.5C3.875 11.8452 4.15482 12.125 4.5 12.125H6.375V14C6.375 14.3452 6.65482 14.625 7 14.625C7.34518 14.625 7.625 14.3452 7.625 14V12.125H9.5C9.84518 12.125 10.125 11.8452 10.125 11.5C10.125 11.1548 9.84518 10.875 9.5 10.875H7.625V9Z"
                      fill="#inherit"
                    />
                    <path
                      d="M8.875 3.375C8.875 2.28079 8.4733 1.2804 7.80933 0.513254C10.6418 1.25315 12.8719 3.48323 13.6117 6.31567C12.8446 5.6517 11.8442 5.25 10.75 5.25H9.1875C9.01491 5.25 8.875 5.11009 8.875 4.9375V3.375Z"
                      fill="#inherit"
                    />
                  </svg>
                </ActionButton>
              </TooltipWrapper>
            </AuthorizedComponent>

            <TooltipWrapper
              key={`${row.id}OrderDetail`}
              content={t('common:detail')}
            >
              <ActionButton
                kind="secondary"
                onClick={() => modalOpenAction(row.id)}
              >
                <svg
                  width="14"
                  height="4"
                  viewBox="0 0 14 4"
                  fill="inherit"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.749919 1.99998C0.749919 1.30962 1.30956 0.74998 1.99992 0.74998C2.69027 0.74998 3.24992 1.30962 3.24992 1.99998C3.24992 2.69034 2.69027 3.24998 1.99992 3.24998C1.30956 3.24998 0.749919 2.69034 0.749919 1.99998ZM5.74992 1.99998C5.74992 1.30962 6.30956 0.74998 6.99992 0.74998C7.69027 0.74998 8.24992 1.30962 8.24992 1.99998C8.24992 2.69034 7.69027 3.24998 6.99992 3.24998C6.30956 3.24998 5.74992 2.69034 5.74992 1.99998ZM10.7499 1.99998C10.7499 1.30962 11.3096 0.74998 11.9999 0.74998C12.6903 0.74998 13.2499 1.30962 13.2499 1.99998C13.2499 2.69034 12.6903 3.24998 11.9999 3.24998C11.3096 3.24998 10.7499 2.69034 10.7499 1.99998Z"
                    fill="#inherit"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M1.99992 2.20831C2.11498 2.20831 2.20825 2.11504 2.20825 1.99998C2.20825 1.88492 2.11498 1.79165 1.99992 1.79165C1.88486 1.79165 1.79159 1.88492 1.79159 1.99998C1.79159 2.11504 1.88486 2.20831 1.99992 2.20831ZM0.541585 1.99998C0.541585 1.19456 1.1945 0.541646 1.99992 0.541646C2.80533 0.541646 3.45825 1.19456 3.45825 1.99998C3.45825 2.8054 2.80533 3.45831 1.99992 3.45831C1.1945 3.45831 0.541585 2.8054 0.541585 1.99998ZM6.99992 2.20831C7.11498 2.20831 7.20825 2.11504 7.20825 1.99998C7.20825 1.88492 7.11498 1.79165 6.99992 1.79165C6.88486 1.79165 6.79158 1.88492 6.79158 1.99998C6.79158 2.11504 6.88486 2.20831 6.99992 2.20831ZM5.54158 1.99998C5.54158 1.19456 6.1945 0.541646 6.99992 0.541646C7.80533 0.541646 8.45825 1.19456 8.45825 1.99998C8.45825 2.8054 7.80533 3.45831 6.99992 3.45831C6.1945 3.45831 5.54158 2.8054 5.54158 1.99998ZM11.9999 2.20831C12.115 2.20831 12.2083 2.11504 12.2083 1.99998C12.2083 1.88492 12.115 1.79165 11.9999 1.79165C11.8849 1.79165 11.7916 1.88492 11.7916 1.99998C11.7916 2.11504 11.8849 2.20831 11.9999 2.20831ZM10.5416 1.99998C10.5416 1.19456 11.1945 0.541646 11.9999 0.541646C12.8053 0.541646 13.4583 1.19456 13.4583 1.99998C13.4583 2.8054 12.8053 3.45831 11.9999 3.45831C11.1945 3.45831 10.5416 2.8054 10.5416 1.99998Z"
                    fill="#inherit"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.333252 1.99998C0.333252 1.07951 1.07944 0.333313 1.99992 0.333313C2.92039 0.333313 3.66659 1.07951 3.66659 1.99998C3.66659 2.92045 2.92039 3.66665 1.99992 3.66665C1.07944 3.66665 0.333252 2.92045 0.333252 1.99998ZM1.99992 0.74998C1.30956 0.74998 0.749919 1.30962 0.749919 1.99998C0.749919 2.69034 1.30956 3.24998 1.99992 3.24998C2.69027 3.24998 3.24992 2.69034 3.24992 1.99998C3.24992 1.30962 2.69027 0.74998 1.99992 0.74998ZM5.33325 1.99998C5.33325 1.07951 6.07944 0.333313 6.99992 0.333313C7.92039 0.333313 8.66658 1.07951 8.66658 1.99998C8.66658 2.92045 7.92039 3.66665 6.99992 3.66665C6.07944 3.66665 5.33325 2.92045 5.33325 1.99998ZM6.99992 0.74998C6.30956 0.74998 5.74992 1.30962 5.74992 1.99998C5.74992 2.69034 6.30956 3.24998 6.99992 3.24998C7.69027 3.24998 8.24992 2.69034 8.24992 1.99998C8.24992 1.30962 7.69027 0.74998 6.99992 0.74998ZM10.3333 1.99998C10.3333 1.07951 11.0794 0.333313 11.9999 0.333313C12.9204 0.333313 13.6666 1.07951 13.6666 1.99998C13.6666 2.92045 12.9204 3.66665 11.9999 3.66665C11.0794 3.66665 10.3333 2.92045 10.3333 1.99998ZM11.9999 0.74998C11.3096 0.74998 10.7499 1.30962 10.7499 1.99998C10.7499 2.69034 11.3096 3.24998 11.9999 3.24998C12.6903 3.24998 13.2499 2.69034 13.2499 1.99998C13.2499 1.30962 12.6903 0.74998 11.9999 0.74998ZM1.58325 1.99998C1.58325 1.76986 1.7698 1.58331 1.99992 1.58331C2.23004 1.58331 2.41659 1.76986 2.41659 1.99998C2.41659 2.2301 2.23004 2.41665 1.99992 2.41665C1.7698 2.41665 1.58325 2.2301 1.58325 1.99998ZM6.58325 1.99998C6.58325 1.76986 6.7698 1.58331 6.99992 1.58331C7.23004 1.58331 7.41658 1.76986 7.41658 1.99998C7.41658 2.2301 7.23004 2.41665 6.99992 2.41665C6.7698 2.41665 6.58325 2.2301 6.58325 1.99998ZM11.5833 1.99998C11.5833 1.76986 11.7698 1.58331 11.9999 1.58331C12.23 1.58331 12.4166 1.76986 12.4166 1.99998C12.4166 2.2301 12.23 2.41665 11.9999 2.41665C11.7698 2.41665 11.5833 2.2301 11.5833 1.99998Z"
                    fill="#inherit"
                  />
                </svg>
              </ActionButton>
            </TooltipWrapper>
          </div>
        );
      },
    },
  ];
};

export { usePreviousOrderColumns };
