import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { generatePath } from 'react-router-dom';

import * as ROUTES from 'constants/routes';
import { ActivateActions } from 'constants/enums/activateActions';

import * as VendorProductActions from '../actions';

const useHandleModal = () => {
  const modalActions = {
    ...ActivateActions,
    pushToEdit: 2,
  };

  const [activateState, setActivateState] = useState({
    currentId: 0,
    action: ActivateActions.ACTIVATE,
    modalVisible: false,
    modalTitleTranslationKey: '',
    modalContentTranslationKey: '',
  });

  const history = useHistory();
  const dispatch = useDispatch();

  const { vendorInfo } = useSelector(state => state.mainHeader);

  const handleModalSave = () => {
    if (activateState.action === modalActions.pushToEdit) {
      history.push({
        pathname: generatePath(ROUTES.VENDORPRODUCTEDIT, {
          id: activateState.currentId,
        }),
        state: { vpGetRequest: false },
      });

      return;
    }
    setStatusAction(activateState.action === modalActions.ACTIVATE);
  };

  const handleModalClose = () =>
    setActivateState(prev => ({ ...prev, modalVisible: false }));

  const handleToggleChange = (id, status) => {
    setActivateState({
      currentId: id,
      action: status ? modalActions.DEACTIVATE : modalActions.ACTIVATE,
      modalVisible: true,
      modalTitleTranslationKey: status
        ? 'vendorproduct:deactivateProductModal.title'
        : 'vendorproduct:activateProductModal.title',
      modalContentTranslationKey: status
        ? 'vendorproduct:deactivateProductModal.description'
        : 'vendorproduct:activateProductModal.description',
    });
  };

  const handlePricelessToggle = id => {
    setActivateState({
      currentId: id,
      action: modalActions.pushToEdit,
      modalVisible: true,
      modalTitleTranslationKey: 'vendorproduct:priceNecessaryModal.title',
      modalContentTranslationKey:
        'vendorproduct:priceNecessaryModal.description',
    });
  };

  const setStatusAction = newStatus => {
    handleModalClose();

    if (newStatus) {
      dispatch(
        VendorProductActions.vendorProductOpenRequest({
          productId: activateState.currentId,
          vendorId: vendorInfo.id,
        }),
      );

      return;
    }

    dispatch(
      VendorProductActions.vendorProductCloseRequest({
        productId: activateState.currentId,
        vendorId: vendorInfo.id,
      }),
    );
  };

  return {
    activateState,
    handleModalSave,
    handleModalClose,
    handleToggleChange,
    handlePricelessToggle,
  };
};

export { useHandleModal };
