import React from 'react';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReloadOutlined } from '@ant-design/icons';

import useResponsive from 'hooks/useResponsive';
import Button from 'components/v1/Button';
import MobileOrders from 'components/v1/MobileOrders/MobileOrders';
import VendorStatus from 'components/v1/VendorStatus';

import * as S from '../style';

import * as CanceledS from './style';
import { useGetCanceledOrderList } from './useGetCanceledOrderList';
import { useCanceledOrdersColumns } from './useCanceledOrdersColumns';

const CanceledOrders = () => {
  const { t } = useTranslation();

  const canceledOrderList = useSelector(
    state => state.activeOrders.canceledList,
  );
  const canceledTotal = useSelector(state => state.activeOrders.canceledTotal);
  const { isMobileView, isMiniTabletView, isTabletView } = useResponsive();
  const isUnderTabletView = isMobileView || isMiniTabletView || isTabletView;
  const canceledOrdersColumns = useCanceledOrdersColumns();

  const { getCanceledOrderList } = useGetCanceledOrderList();

  return isMobileView ? (
    <MobileOrders
      isCanceledOrders
      isActive={false}
      orders={canceledOrderList}
    />
  ) : (
    <>
      {isUnderTabletView && <VendorStatus />}
      <S.PageHeader>
        <Button onClick={getCanceledOrderList}>
          <ReloadOutlined />
          {t('activeOrders:refreshButton')}
        </Button>
      </S.PageHeader>
      <CanceledS.HeaderContainer>
        <CanceledS.TotalAmountSection>
          {t('activeOrders:totalPrice')}:
          <span>₺{canceledTotal?.toFixed(2)}</span>
        </CanceledS.TotalAmountSection>
      </CanceledS.HeaderContainer>
      <S.StyledTable
        columns={canceledOrdersColumns}
        dataSource={canceledOrderList}
        locale={{ emptyText: <Empty description={t('table.noData')} /> }}
        bordered
        pagination={{ size: 'small', position: ['bottomCenter'] }}
        rowKey="id"
        scroll={{ x: 1500 }}
      />
    </>
  );
};

export default CanceledOrders;
