import React from 'react';
import { Empty } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import * as S from './style';

const RoleUsers = ({ users }) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t('authRolesPage:users.name'),
      key: 'userName',
      dataIndex: 'userName',
    },
    {
      title: t('authRolesPage:users.email'),
      key: 'email',
      dataIndex: 'email',
    },
  ];

  return (
    <S.StyledTable
      title={() => t('authRolesPage:users.title')}
      dataSource={users}
      columns={columns}
      bordered
      size="small"
      locale={{ emptyText: <Empty description={t('table.noData')} /> }}
    />
  );
};

RoleUsers.propTypes = {
  users: PropTypes.array,
};

RoleUsers.defaultProps = {
  users: [],
};

export default RoleUsers;
