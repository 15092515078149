export const environments = {
  development: {
    server: 'http://localhost:3000',
  },
  test: {
    server: 'https://water-panel.develop.getirapi.com',
  },
  staging: {
    server: 'https://water-panel.staging.getirapi.com',
  },
  production: {
    server: 'https://panel.getirsu.com',
  },
};
