import React, { useState, useEffect, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import ConfirmModal from 'components/ConfirmModal';
import AuthorizedComponent from 'components/AuthorizedComponent/AuthorizedComponent';
import * as MainHeaderActions from 'components/MainHeader/actions';

import { ClockIcon } from '../Icons';
import Toggle from '../Toggle/Toggle';

import * as S from './style';

const VendorStatus = () => {
  const vendor = useSelector(state => state.mainHeader.vendor);
  const isOpen = useSelector(state => state.mainHeader.isOpen);
  const vendorInfo = useSelector(state => state.mainHeader.vendorInfo);

  const [toggleValue, setToggleValue] = useState(isOpen);
  const [isOpenVendorModalVisible, setIsOpenVendorModalVisible] = useState(
    false,
  );

  const dispatch = useDispatch();

  const { t } = useTranslation();
  const updateVendorStatus = vendor && !vendor.isFirm;

  const handleOpenVendorModalSave = () => {
    setIsOpenVendorModalVisible(false);
    dispatch(
      MainHeaderActions.updateVendorStatusRequest(vendor.value, !toggleValue),
    );
  };

  const handleOpenVendorModalClose = () => {
    setIsOpenVendorModalVisible(false);
  };

  const handleToggleClick = () => {
    if (vendor) {
      setIsOpenVendorModalVisible(true);
    }
  };

  useEffect(() => {
    if (vendorInfo.id && toggleValue !== isOpen) {
      setToggleValue(isOpen);
    }
    // eslint-disable-next-line
  }, [vendorInfo, isOpen]);

  return updateVendorStatus ? (
    <S.Container>
      <S.IconContainer>
        <ClockIcon width={16} height={16} fill="#5D3EBC" />
      </S.IconContainer>
      <S.TextContainer>
        <S.Title>Mağaza Durumu</S.Title>
        <S.VendorName>{vendor.label}</S.VendorName>
      </S.TextContainer>
      <AuthorizedComponent componentId="update_vendor_status">
        <Toggle
          checkedChildren={t('on')}
          checked={toggleValue}
          unCheckedChildren={t('off')}
          size="medium"
          color="#008C20"
          onChange={handleToggleClick}
        />
      </AuthorizedComponent>
      <ConfirmModal
        size="400px"
        visible={isOpenVendorModalVisible}
        onSave={handleOpenVendorModalSave}
        onClose={handleOpenVendorModalClose}
        title={
          toggleValue
            ? t('header:closeVendorModalTitle')
            : t('header:openVendorModalTitle')
        }
        content={
          toggleValue
            ? t('header:closeVendorModalContent')
            : t('header:openVendorModalContent')
        }
        cancelText={t('cancel')}
      />
    </S.Container>
  ) : null;
};

export default memo(VendorStatus);
