import { useCallback, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import L from 'leaflet';

import store from 'store/configureStore';

import { MAP_COLORS } from '../constant';
import { reverseCoordinates } from '../common';
import { createFeatureCollection, createPolygon } from '../utils/polygonUtils';

const useNeighbourhoodPolygon = ({ geomapStateKey, Actions, mapInstance }) => {
  const neighbourhoods = useSelector(
    state => state[geomapStateKey].neighbourhoods,
  );
  const isNeighbourhoodVisible = useSelector(
    state => state[geomapStateKey].isNeighbourhoodVisible,
  );
  const isVendorSelected = !!useSelector(
    state => state[geomapStateKey].selectedVendor,
  );
  const dispatch = useDispatch();

  const handleNeighbourhoodPolygonClick = useCallback(
    polygonData => {
      const { selectedVendor, editedMap } = store.getState()[geomapStateKey];
      if (!selectedVendor) return;

      const createdPolygon = createPolygon(polygonData.polygon, {
        warehouse: selectedVendor,
        neighbourhood: polygonData.id,
        neighbourhoodName: polygonData.name,
      });

      const createdFeature = createFeatureCollection([
        ...(editedMap?.features?.length > 0 ? editedMap.features : []),
        createdPolygon,
      ]);

      dispatch(Actions.setEditedMap(createdFeature));
    },
    [Actions, dispatch, geomapStateKey],
  );

  const neigborhoodPolygons = useRef([]);

  useEffect(() => {
    if (mapInstance) {
      const polygons = [];
      const pane = mapInstance.createPane('neighbourhoodPolygon');
      pane.style.zIndex = isVendorSelected ? 402 : 400;
      neighbourhoods?.forEach(nbhood => {
        if (isNeighbourhoodVisible) {
          const createdPolygon = L.polygon(reverseCoordinates(nbhood.polygon), {
            fillColor: MAP_COLORS.neigborhood,
            color: '#000000',
            fillOpacity: 0.25,
            weight: 1,
            opacity: 1,
            pane: 'neighbourhoodPolygon',
          });

          createdPolygon.on('click', () => {
            handleNeighbourhoodPolygonClick(nbhood);
          });
          createdPolygon.bindPopup(nbhood.name);
          createdPolygon.on('mouseover', () => {
            createdPolygon.openPopup();
          });
          createdPolygon.on('mouseout', () => {
            createdPolygon.closePopup();
          });
          createdPolygon.addTo(mapInstance);
          polygons.push(createdPolygon);
        }
      });

      neigborhoodPolygons.current = [...polygons];
    }

    return () => {
      neigborhoodPolygons.current.forEach(p => {
        p.remove();
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [neighbourhoods, isNeighbourhoodVisible]);
};

export default useNeighbourhoodPolygon;
