import styled from 'styled-components';

const CellProgressWrapper = styled.div`
  width: 100%;
  height: 4px;
  background: #eaeaea;
  border-radius: 2px;
`;

const CellProgressInner = styled.div`
  width: ${props => (props.percent ? `${props.percent}%` : 0)};
  height: 4px;
  background: ${props => (props.percent < 100 ? `#3f8677` : `#ca522f`)};
  border-radius: 2px;
`;

export { CellProgressWrapper, CellProgressInner };
