import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Button from 'components/v1/Button';
import * as S from 'common/style';
import SelectBoxAntd from 'components/Antd/Elements/SelectBox';
import * as ActiveOrderActions from 'containers/ActiveOrders/actions';
import { ORDER_STATUS } from 'constants/orderStatus';
import { rolesWithName } from 'constants/roles';

import * as CourierListModalActions from './actions';

const CourierListModal = () => {
  const { t } = useTranslation();
  const { orderId, showModal } = useSelector(state => state.courierListModal);

  const { data: orderDetail } = useSelector(
    state => state.courierListModal.orderDetail,
  );
  const { data: courierList } = useSelector(
    state => state.courierListModal.courierList,
  );
  const [courierId, setCourierId] = useState(undefined);
  const [orderVendorId, setOrderVendorId] = useState(undefined);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!courierList) {
      const data = {
        userRole: rolesWithName.CARRIER,
        vendorId: orderVendorId,
      };
      if (data.vendorId) {
        dispatch(CourierListModalActions.getCourierListRequest(data));
      }
    }
  }, [dispatch, courierList, orderVendorId]);

  useEffect(() => {
    if (orderDetail) {
      const { vendor } = orderDetail;
      setOrderVendorId(vendor.id);
    }
  }, [orderDetail]);

  useEffect(() => {
    if (!orderDetail && orderId) {
      dispatch(CourierListModalActions.getOrderDetailRequest(orderId));
    }
  }, [dispatch, orderDetail, orderId]);

  useEffect(() => {
    setCourierId(undefined);
  }, [showModal]);

  const closeModal = () => {
    dispatch(CourierListModalActions.sendSmsCourierReset());
    dispatch(CourierListModalActions.resetOrderDetail());
    dispatch(CourierListModalActions.resetCourierList());
  };

  const handleChangeCourierList = value => {
    setCourierId(value);
  };

  const handleClickSendSMSDeclineButton = () => {
    dispatch(
      ActiveOrderActions.changeOrderStatusRequest({
        id: orderId,
        status: ORDER_STATUS.ONWAY,
      }),
    );
    closeModal();
  };

  const handleClickSendSMSConfirmButton = () => {
    const data = {
      orderId,
      courierId,
    };
    dispatch(CourierListModalActions.sendSmsCourierRequest(data));
    dispatch(
      ActiveOrderActions.changeOrderStatusRequest({
        id: orderId,
        status: ORDER_STATUS.ONWAY,
      }),
    );
    closeModal();
  };

  const handleClickCancelButton = () => {
    closeModal();
  };

  return (
    <S.StyledModal
      isOpen={showModal || false}
      width={600}
      footerOptions={{
        customFooter: (
          <S.FooterContainer>
            <Button
              kind="secondary"
              key="back"
              onClick={handleClickCancelButton}
            >
              {t('giveUp')}
            </Button>
            <Button
              type="primary"
              key="declineSms"
              onClick={handleClickSendSMSDeclineButton}
            >
              {t('sendSmsDeclineText')}
            </Button>
            <Button
              key="submit"
              disabled={!courierId}
              type="primary"
              onClick={handleClickSendSMSConfirmButton}
            >
              {t('sendSmsConfirmText')}
            </Button>
          </S.FooterContainer>
        ),
      }}
      onClose={closeModal}
      headerOptions={{
        title: t('activeOrders:courierListModal.title'),
        closeButton: true,
      }}
      centered
    >
      <SelectBoxAntd
        placeholder={t('activeOrders:courierListModal.chooseCourier')}
        showSearch
        options={
          courierList &&
          courierList.map(courier => ({
            value: courier.userId,
            label: courier.username,
          }))
        }
        allowClear
        value={courierId}
        onChange={handleChangeCourierList}
        loading={!courierList}
      />
    </S.StyledModal>
  );
};

export default CourierListModal;
