import * as Types from './types';

const initialState = {
  categorys: undefined,
  error: {},
  addCategory: '',
  categoryInfo: {},
  editCategory: '',
  deleteCategory: '',
};

function CategoryReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        categorys: payload.categories,
      };
    case Types.CATEGORY_LIST_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.GET_CATEGORY_SUCCESS:
      return {
        ...state,
        categoryInfo: payload,
      };
    case Types.GET_CATEGORY_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.ADD_CATEGORY_SUCCESS:
      return {
        ...state,
        addCategory: payload,
      };
    case Types.ADD_CATEGORY_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.EDIT_CATEGORY_SUCCESS:
      return {
        ...state,
        editCategory: payload,
      };
    case Types.EDIT_CATEGORY_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.DELETE_CATEGORY_SUCCESS: {
      const { result } = payload;

      return {
        ...state,
        deleteCategory: result,
      };
    }
    case Types.DELETE_CATEGORY_ERROR:
      return {
        ...state,
        error: payload,
      };
    default:
      return state;
  }
}

export default CategoryReducer;
