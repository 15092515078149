import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { ConfigProvider } from 'antd';

import { InfoIcon, ExclaimationIcon } from '../Icons';

import * as S from './styles';

const Input = ({ size, label, addonAfter, infoText, error, ...props }, ref) => (
  <S.InputContainer>
    <ConfigProvider
      theme={{
        components: {
          Input: {
            activeBorderColor: '#5d3ebc',
            activeShadow: '#f3f0fe',
            hoverBorderColor: '#5d3ebc',
          },
        },
      }}
    >
      <S.Input
        addonAfter={addonAfter}
        $size={size}
        placeholder={label}
        ref={ref}
        {...props}
      />
      {(!!error || !!infoText) && (
        <S.InfoContainer>
          {error ? (
            <ExclaimationIcon width={14} height={14} />
          ) : (
            <InfoIcon width={14} height={14} fill="#697488" />
          )}
          <S.InfoText $error={!!error}>{error || infoText}</S.InfoText>
        </S.InfoContainer>
      )}
    </ConfigProvider>
  </S.InputContainer>
);

Input.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  label: PropTypes.string,
  infoText: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
  addonAfter: PropTypes.node,
};
Input.defaultProps = {
  size: 'medium',
  label: '',
  infoText: undefined,
  error: undefined,
  onChange: () => {},
  addonAfter: undefined,
};

export default forwardRef(Input);
