export const AuthorizationRoleNames = Object.freeze({
  ADMIN: 'ADMIN',
  ADMIN_KEY_ACCOUNT_MANAGER: 'ADMIN_KEY_ACCOUNT_MANAGER',
  ADMIN_REGIONAL_MANAGER: 'ADMIN_REGIONAL_MANAGER',
  ADMIN_OPERATIONAL_MANAGER: 'ADMIN_OPERATIONAL_MANAGER',
  FIRM_MANAGER: 'FIRM_MANAGER',
  VENDOR_MANAGER: 'VENDOR_MANAGER',
  VENDOR_EMPLOYEE: 'VENDOR_EMPLOYEE',
  CARRIER: 'CARRIER',
  GIS_EXPERT: 'GIS_EXPERT',
  NATIONAL_BRAND_VENDOR_MANAGER: 'NATIONAL_BRAND_VENDOR_MANAGER',
});
