import React, { useEffect, useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { noop, get } from 'lodash';
import { Alert } from 'antd';

import SelectBoxAntd from 'components/Antd/FormElements/SelectBox';
import CheckboxWrapper from 'components/CheckboxWrapper/CheckboxWrapper';
import store from 'store/configureStore';
import useBoolean from 'hooks/useBoolean';
import ConfirmModal from 'components/ConfirmModal/ConfirmModal';
import { open } from 'utils/notification';

import useGeoMapTranslation from '../../useGeoMapTranslation';
import {
  addSelectedAreasToVendor,
  createFeatureCollection,
  createPolygon,
  removeSelectedAreasFromVendor,
} from '../../utils/polygonUtils';
import { POLYGON_ERROR_CODES } from '../../constant';

import * as S from './style';

const AreaAndVendorSelect = ({
  geomapStateKey,
  Actions = {
    setSelectedVendor: noop,
    setNeighbourhoodisVisible: noop,
    setEditedMap: noop,
    setShowOnlySelectedVendor: noop,
  },
}) => {
  const { t } = useGeoMapTranslation();
  const newFeatureCollectionRef = useRef();
  const [
    isAddModalVisible,
    // eslint-disable-next-line no-unused-vars
    { setFalse: hideAddmModal, setTrue: showAddModal },
  ] = useBoolean(false);
  const [
    isLowerAreasModalVisible,
    // eslint-disable-next-line no-unused-vars
    {
      setFalse: hideLowerAreasModalVisible,
      setTrue: showLowerAreasModalVisible,
    },
  ] = useBoolean(false);
  const [
    isRemoveModalVisible,
    // eslint-disable-next-line no-unused-vars
    { setFalse: hideRemovemModal, setTrue: showRemoveModal },
  ] = useBoolean(false);

  const neighbourhoods = useSelector(
    state => state[geomapStateKey].neighbourhoods,
  );

  const vendors = useSelector(state => state[geomapStateKey].vendors);

  const districts = useSelector(state => state[geomapStateKey].districts);
  const selectedDistrict = useSelector(
    state => state[geomapStateKey].selectedDistrict,
  );
  const editedMap = useSelector(state => state[geomapStateKey].editedMap);
  const isCityAndBrandSelected = !!useSelector(
    state => state[geomapStateKey].map,
  );
  const selectedVendor = useSelector(
    state => state[geomapStateKey].selectedVendor,
  );

  const dispatch = useDispatch();
  const {
    formState: { errors },
    control,
    setValue,
  } = useForm({
    mode: 'all',
    defaultValues: {
      isNeighbourhoodsVisible: true,
    },
  });

  useEffect(() => {
    /* eslint-disable */
    const newValue =
      editedMap && editedMap.features?.length > 0
        ? editedMap?.features?.map(f => ({
            label: f.properties.neighbourhoodName,
            value: f.properties.neighbourhood,
            key: f.properties.neighbourhood,
        }))
        : [];
    /* eslint-enable */

    setValue('neighbourhoodId', newValue);
  }, [editedMap, setValue]);

  const isNeighbourhoodsDisable =
    !selectedVendor || neighbourhoods.length === 0;

  const vendorsOptions = useMemo(() => {
    if (!vendors) return [];

    return vendors.map(v => ({
      label: v.vendorName,
      value: v.id,
    }));
  }, [vendors]);

  const neighbourhoodOptions = useMemo(() => {
    if (!neighbourhoods) return [];

    return neighbourhoods.map(n => ({
      label: n.name,
      value: n.id,
    }));
  }, [neighbourhoods]);

  const districtOptions = useMemo(() => {
    if (!districts) return [];

    return districts.map(d => ({
      label: d.name,
      value: d.id,
    }));
  }, [districts]);

  const setNeighbourhood = value => {
    if (!value || !neighbourhoods || neighbourhoods.length === 0) return;

    const selectedNeighbourhood = value.map(v => {
      const neighbourhood = neighbourhoods.find(n => n.id === v.value);

      return createPolygon(neighbourhood?.polygon, {
        warehouse: selectedVendor,
        neighbourhood: neighbourhood?.id,
        neighbourhoodName: neighbourhood?.name,
      });
    });

    dispatch(
      Actions.setEditedMap(createFeatureCollection(selectedNeighbourhood)),
    );
  };

  const setVendor = value => {
    dispatch(Actions.setSelectedVendor(value));
  };

  const setDistrict = value => {
    dispatch(Actions.setSelectedDistrict(value));
    dispatch(
      Actions.geomapGetNeighbourhoodRequest({
        districtId: value,
        geojson: true,
      }),
    );
  };
  const setNeighbourhoodisVisible = value => {
    dispatch(Actions.setNeighbourhoodisVisible(value.currentTarget.checked));
  };
  const setShowSelectedVendor = value => {
    dispatch(Actions.setShowOnlySelectedVendor(value.currentTarget.checked));
  };

  const handleSaveWithRemovedAreas = () => {
    const featureCollection = newFeatureCollectionRef.current;
    const { brand, city, interval: currentInterval } = store.getState()[
      geomapStateKey
    ];
    dispatch(
      Actions.geomapSaveRequest({
        brandId: brand.value,
        city: city.value,
        country: city.country,
        intervalType: get(currentInterval, 'value', currentInterval),
        geoJson: featureCollection,
      }),
    );
    dispatch(Actions.setEditedMap(undefined));
    hideLowerAreasModalVisible();
  };

  const handleAddSave = () => {
    const { map, brand, city, interval: currentInterval } = store.getState()[
      geomapStateKey
    ];
    try {
      const {
        featureCollection,
        isSmallAreasRemoved,
      } = addSelectedAreasToVendor(selectedVendor, editedMap, true, map);
      newFeatureCollectionRef.current = featureCollection;

      if (isSmallAreasRemoved) {
        showLowerAreasModalVisible();
        handleCloseModal();

        return;
      }
    } catch (error) {
      if (error.code === POLYGON_ERROR_CODES[10001].code) {
        open('error', 'Error', t('ringPolygonDedected'));
      }
      handleCloseModal();

      return;
    }

    dispatch(
      Actions.geomapSaveRequest({
        brandId: brand.value,
        city: city.value,
        country: city.country,
        intervalType: get(currentInterval, 'value', currentInterval),
        geoJson: newFeatureCollectionRef.current,
      }),
    );
    dispatch(Actions.setEditedMap(undefined));
    handleCloseModal();
  };

  const handleRemoveSave = () => {
    const { map, brand, city, interval: currentInterval } = store.getState()[
      geomapStateKey
    ];

    try {
      const {
        featureCollection,
        isSmallAreasRemoved,
      } = removeSelectedAreasFromVendor(selectedVendor, editedMap, map);
      newFeatureCollectionRef.current = featureCollection;

      if (isSmallAreasRemoved) {
        showLowerAreasModalVisible();
        handleCloseModal();

        return;
      }
    } catch (error) {
      if (error.code === POLYGON_ERROR_CODES[10001].code) {
        open('error', 'Error', t('ringPolygonDedected'));
      }
      handleCloseModal();

      return;
    }

    dispatch(
      Actions.geomapSaveRequest({
        brandId: brand.value,
        city: city.value,
        country: city.country,
        intervalType: get(currentInterval, 'value', currentInterval),
        geoJson: newFeatureCollectionRef.current,
      }),
    );
    dispatch(Actions.setEditedMap(undefined));
    handleCloseModal();
  };

  const handleCloseModal = () => {
    hideAddmModal();
    hideRemovemModal();
  };

  return (
    <S.FormWrapper>
      <S.AlertWrapper>
        <Alert
          closable
          message={t('showNeighbourhoodInfo')}
          type="info"
          showIcon
        />
      </S.AlertWrapper>
      <S.SelectBoxContainer>
        <CheckboxWrapper
          defaultValues
          control={control}
          errors={errors}
          fieldName="isNeighbourhoodsVisible"
          label={t('showNeighbourhood')}
          isDisabled={!isCityAndBrandSelected}
          onChange={setNeighbourhoodisVisible}
        />
        <CheckboxWrapper
          control={control}
          errors={errors}
          fieldName="showSelectedVendor"
          label={t('showSelectedVendor')}
          isDisabled={!isCityAndBrandSelected}
          onChange={setShowSelectedVendor}
        />
      </S.SelectBoxContainer>
      <S.FormContainer>
        <SelectBoxAntd
          fieldName="vendorId"
          errors={errors}
          isDisabled={!isCityAndBrandSelected}
          labelInValue={false}
          onChange={setVendor}
          value={selectedVendor}
          placeholder={t('selectedVendor')}
          options={vendorsOptions}
          showSearch
          allowClear
          control={control}
        />
        <SelectBoxAntd
          fieldName="districtId"
          errors={errors}
          isDisabled={!isCityAndBrandSelected}
          labelInValue={false}
          value={selectedDistrict?.id}
          onChange={setDistrict}
          placeholder={t('selectedDisrict')}
          options={districtOptions}
          showSearch
          allowClear
          control={control}
        />

        <SelectBoxAntd
          fieldName="neighbourhoodId"
          labelInValue
          isDisabled={isNeighbourhoodsDisable}
          errors={errors}
          onChange={setNeighbourhood}
          placeholder={t('selectedNeighbourhoods')}
          options={neighbourhoodOptions}
          selectMode="multiple"
          showSearch
          allowClear
          control={control}
        />

        <S.FullHeihgthButton
          disabled={isNeighbourhoodsDisable}
          onClick={() => showRemoveModal()}
          kind="secondary"
        >
          {t('button.removeSelected')}
        </S.FullHeihgthButton>
        <S.FullHeihgthButton
          disabled={isNeighbourhoodsDisable}
          onClick={() => showAddModal()}
          kind="primary"
        >
          {t('button.addSelected')}
        </S.FullHeihgthButton>
      </S.FormContainer>
      <ConfirmModal
        size="400px"
        visible={isLowerAreasModalVisible}
        onSave={handleSaveWithRemovedAreas}
        onClose={hideLowerAreasModalVisible}
        title={t('smallAreaDedectedTitle')}
        content={t('smallAreaDedectedInfo')}
        cancelText={t('button.cancel')}
      />
      <ConfirmModal
        size="400px"
        visible={isAddModalVisible}
        onSave={handleAddSave}
        onClose={handleCloseModal}
        title={t('addSelectedAreasTitle')}
        content={t('addSelectedAreaInfo')}
        cancelText={t('button.cancel')}
      />
      <ConfirmModal
        size="400px"
        visible={isRemoveModalVisible}
        onSave={handleRemoveSave}
        onClose={handleCloseModal}
        title={t('removeSelectedAreasTitle')}
        content={t('removeSelectedAreaInfo')}
        cancelText={t('button.cancel')}
      />
    </S.FormWrapper>
  );
};

AreaAndVendorSelect.propTypes = {
  geomapStateKey: PropTypes.string.isRequired,
  Actions: PropTypes.shape({
    setSelectedVendor: PropTypes.func,
    setNeighbourhoodisVisible: PropTypes.func,
    setEditedMap: PropTypes.func,
    setShowOnlySelectedVendor: PropTypes.func,
    geomapGetDistrictRequest: PropTypes.func,
    setSelectedDistrict: PropTypes.func,
    geomapGetNeighbourhoodRequest: PropTypes.func,
  }).isRequired,
};

export default AreaAndVendorSelect;
