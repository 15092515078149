export const MISSINGPRODUCT_OPEN = 'MISSINGPRODUCT_OPEN';
export const MISSINGPRODUCT_CLOSE = 'MISSINPRODUCT_CLOSE';

export const MISSINGPRODUCT_SEND_REQUEST = 'MISSINGPRODUCT_SEND_REQUEST';
export const MISSINGPRODUCT_SEND_SUCCESS = 'MISSINGPRODUCT_SEND_SUCCESS';
export const MISSINGPRODUCT_SEND_ERROR = 'MISSINGPRODUCT_SEND_ERROR';

export const MISSINGPRODUCT_ORDER_REQUEST = 'MISSINGPRODUCT_ORDER_REQUEST';
export const MISSINGPRODUCT_ORDER_SUCCESS = 'MISSINGPRODUCT_ORDER_SUCCESS';
export const MISSINGPRODUCT_ORDER_ERROR = 'MISSINGPRODUCT_ORDER_ERROR';
export const MISSINGPRODUCT_ORDER_RESET = 'MISSINGPRODUCT_ORDER_RESET';
