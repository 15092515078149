import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import io from 'socket.io-client';
import { useTranslation } from 'react-i18next';

import * as OrderDetailActions from 'components/OrderDetailModal/actions';
import { ORDER_STATUS } from 'constants/orderStatus';
import cookies from 'utils/cookie';

import { socketOrderAdd } from '../actions';
import { useGetCompletedOrderList } from '../CompletedOrders/useGetCompletedOrderList';
import { useGetCanceledOrderList } from '../CanceledOrders/useGetCanceledOrderList';
import { useAudio } from '../hooks/useAudio';
import { LOCATION_PERMISSION_TYPES } from '../../../constants/locationPermissionTypes';
import { useGetScheduledOrderList } from '../ScheduledOrders/useGetScheduledOrderList';

import useMobileCheck from './useMobileCheck';

const useSocket = () => {
  const dispatch = useDispatch();
  const socketRef = useRef(null);
  const { isMobile } = useMobileCheck();

  const vendorIdList = useSelector(state => state.activeOrders.vendorIdList);
  const vendorInfo = useSelector(state => state.mainHeader.vendor);
  const { getCompletedOrderList } = useGetCompletedOrderList();
  const { getCanceledOrderList } = useGetCanceledOrderList();
  const { getScheduledOrderList } = useGetScheduledOrderList();
  const { playAudio } = useAudio();
  const { t } = useTranslation();

  const activeOrderListener = body => {
    const accessToken = cookies.get('accessToken');

    if (accessToken === undefined) {
      if (socketRef.current) {
        socketRef.current.close();
      }

      return;
    }

    const parsedData = JSON.parse(body);

    if (parsedData && accessToken !== undefined) {
      parsedData.panelOrderList.forEach(item => {
        if (item && item.vendor && vendorIdList.includes(item.vendor.id)) {
          if (item.status === ORDER_STATUS.VERIFYING) {
            if (typeof Notification === 'function' && isMobile === false) {
              Notification.requestPermission().then(result => {
                if (result === LOCATION_PERMISSION_TYPES.GRANTED) {
                  const orderNotification = new Notification(
                    t('common:newOrderNotification'),
                    {
                      body: t('common:newOrderNotification'),
                      tag: item.id || 'new-order',
                      icon: '/favicon.ico',
                    },
                  );
                  orderNotification.addEventListener('click', () => {
                    dispatch(
                      OrderDetailActions.getOrderDetailWithActionsRequest(
                        item.id,
                      ),
                    );
                    window.focus();

                    orderNotification.close();
                  });
                }
              });
            }

            playAudio();
          }
          if (
            [
              ORDER_STATUS.DELIVERED,
              ORDER_STATUS.RATED,
              ORDER_STATUS.CANCELED_ADMIN,
              ORDER_STATUS.CANCELED_VENDOR,
            ].includes(item.status)
          ) {
            getCompletedOrderList();
            getCanceledOrderList();
            getScheduledOrderList();
          }

          dispatch(socketOrderAdd(item));
        }
      });
    }
  };

  const connectErrorListener = () => {
    window.location.reload();
  };

  useEffect(() => {
    if (socketRef.current) {
      socketRef.current.close();
    }

    if (!vendorInfo || !vendorInfo.value || !vendorIdList) {
      return;
    }
    const accessToken = cookies.get('accessToken');

    const query = {
      senderType: 'panel',
      tokenCode: accessToken,
    };

    if (vendorInfo.isFirm) {
      query.firmId = vendorInfo.value;
    } else {
      query.vendorId = vendorIdList;
    }

    const socketClient = io(process.env.REACT_APP_SOCKETIO_ADDRESS, {
      transports: ['websocket'],
      autoConnect: false,
      query,
    });

    socketClient.on('panel-active-order-status', activeOrderListener);
    socketClient.on('connect_error', connectErrorListener);

    socketRef.current = socketClient;
    socketClient.connect();

    /* eslint-disable */
    return () => socketClient.close();
  }, [dispatch, vendorIdList, vendorInfo]);
  /* eslint-enable */
};

export { useSocket };
