import styled from 'styled-components';
import { Switch as AntdSwitch } from 'antd';

const SIZE = {
  small: {
    height: 24,
  },
  medium: {
    height: 30,
  },
  large: {
    height: 36,
  },
};

export const Switch = styled(AntdSwitch)`
  ${({ $size, $color }) => `
   height: ${SIZE[$size].height}px !important;
   &:where(.ant-switch-checked).ant-switch{
        background: ${$color} !important;
    }
    .ant-switch-handle{
    height: ${SIZE[$size].height - 4}px !important;
    width: ${SIZE[$size].height - 4}px !important;
    }
  
  
    &:where(.ant-switch-checked) .ant-switch-handle {
    inset-inline-start: calc(100% - ${SIZE[$size].height - 2}px) !important;   

  }
  `}
  .ant-switch-handle::before {
    border-radius: 100% !important;
  }

  .ant-switch-inner {
    height: 100% !important;
    span {
      height: 100% !important;
      display: inline-flex !important;
      align-items: center !important;
    }
  }
`;
