import * as Types from './types';

const initialState = {
  cityId: undefined,
  vendor: undefined,
  error: {},
  cities: [],
  vendors: [],
  workingHours: '',
  vendorStatus: false,
  vendorInfo: {
    workingHours: undefined,
    id: undefined,
  },
  isPolling: false,
  isOpen: false,
};

function MainHeaderReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.SET_CITY_ID:
      return {
        ...state,
        cityId: payload,
      };
    case Types.SET_VENDOR:
      return {
        ...state,
        vendor: payload,
      };
    case Types.GET_CITIES_SUCCESS:
      return {
        ...state,
        cities: payload,
      };
    case Types.GET_CITIES_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.GET_VENDORS_SUCCESS:
      return {
        ...state,
        vendors: payload,
      };
    case Types.GET_VENDORS_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.UPDATE_VENDOR_STATUS_SUCCESS:
      return {
        ...state,
        vendorStatus: payload,
      };
    case Types.UPDATE_VENDOR_STATUS_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.GET_WORKING_HOURS_INFO_SUCCESS:
      return {
        ...state,
        workingHours: payload,
      };
    case Types.GET_WORKING_HOURS_INFO_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.GET_VENDOR_INFO_SUCCESS:
      return {
        ...state,
        vendorInfo: payload,
        isOpen: payload.isOpen,
      };
    case Types.GET_VENDOR_INFO_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.SET_VENDOR_POLL_ISOPEN_SUCCESS:
      return {
        ...state,
        isOpen: payload,
      };
    case Types.SET_VENDOR_POLL_ISOPEN_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.ISOPEN_POLL_STOP:
      return {
        ...state,
        isPolling: false,
      };
    case Types.ISOPEN_POLL_START:
      return {
        ...state,
        isPolling: true,
      };
    default:
      return state;
  }
}

export default MainHeaderReducer;
