import React from 'react';
import { useTranslation } from 'react-i18next';

import Layout from 'components/Layout';
import * as CS from 'common/style';

import HelpCarousel from './HelpCarousel';

const Help = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <CS.PageHeader>
        <CS.PageTitle>{t('help:pageTitle')}</CS.PageTitle>
      </CS.PageHeader>
      <HelpCarousel />
    </Layout>
  );
};

export default Help;
