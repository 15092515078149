/**
 * @typedef {{
 *  brandId: String,
 *  brandName: String,
 * }} FirmVendorBrandDetailModel
 */

class GetFirmVendorsDetailResponseItem {
  /**
   * @param {String} vendorId
   * @param {String} status
   * @param {String} vendorName
   * @param {String} city
   * @param {String} district
   * @param {String} town
   * @param {String} firmName
   * @param {FirmVendorBrandDetailModel[]} brands
   */
  constructor(
    vendorId,
    status,
    vendorName,
    city,
    district,
    town,
    firmName,
    brands,
  ) {
    this.vendorId = vendorId;
    this.status = status;
    this.vendorName = vendorName;
    this.city = city;
    this.district = district;
    this.town = town;
    this.firmName = firmName;
    this.brands = brands;
  }
}

export default GetFirmVendorsDetailResponseItem;
