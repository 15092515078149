import { createAction } from 'utils/createAction';

import * as Types from './types';

// Upload Geomap Get Actions
export const uploadGeomapGetRequest = createAction(
  Types.UPLOAD_GEOMAP_GET_REQUEST,
);
export const uploadGeomapGetSuccess = createAction(
  Types.UPLOAD_GEOMAP_GET_SUCCESS,
);
export const uploadGeomapGetError = createAction(Types.UPLOAD_GEOMAP_GET_ERROR);

// upload geomap get district request

export const uploadGeomapGetDistrictRequest = createAction(
  Types.UPLOAD_GEOMAP_GET_DISTRICT_REQUEST,
);
export const uploadGeomapGetDistrictSuccess = createAction(
  Types.UPLOAD_GEOMAP_GET_DISTRICT_SUCCESS,
);
export const uploadGeomapGetDistrictError = createAction(
  Types.UPLOAD_GEOMAP_GET_DISTRICT_ERROR,
);
// Upload Geomap Get Vendors Actions
export const uploadGeomapGetVendorsRequest = createAction(
  Types.UPLOAD_GEOMAP_GET_VENDORS_REQUEST,
);
export const uploadGeomapGetVendorsSuccess = createAction(
  Types.UPLOAD_GEOMAP_GET_VENDORS_SUCCESS,
);
export const uploadGeomapGetVendorsError = createAction(
  Types.UPLOAD_GEOMAP_GET_VENDORS_ERROR,
);

// Upload Geomap Set Selected District
export const uploadGeomapSetSelectedDistrict = createAction(
  Types.UPLOAD_GEOMAP_SET_SELECTED_DISTRICT,
);

// Upload Geomap Get Neighbourhood Actions
export const uploadGeomapGetNeighbourhoodRequest = createAction(
  Types.UPLOAD_GEOMAP_GET_NEIGHBOURHOOD_REQUEST,
);

export const uploadGeomapGetNeighbourhoodSuccess = createAction(
  Types.UPLOAD_GEOMAP_GET_NEIGHBOURHOOD_SUCCESS,
);

export const uploadGeomapGetNeighbourhoodError = createAction(
  Types.UPLOAD_GEOMAP_GET_NEIGHBOURHOOD_ERROR,
);

// Upload Geomap Set Neighbourhood is Visible

export const setNeighbourhoodisVisible = createAction(
  Types.SET_NEIGHBOURHOOD_IS_VISIBLE,
);

// Upload Geomap Set Show Only Selected Vendor
export const uploadGeomapSetShowOnlySelectedVendor = createAction(
  Types.UPLOAD_GEOMAP_SET_SHOW_ONLY_SELECTED_VENDOR,
);
// Upload Geomap Set Edited Map
export const uploadGeomapSetEditedMap = createAction(
  Types.UPLOAD_GEOMAP_SET_EDITED_MAP,
);

// Upload Geomap Set City
export const uploadGeomapSetCity = createAction(Types.UPLOAD_GEOMAP_SET_CITY);

// Upload Geomap Set Selected Vendor
export const uploadGeomapSetSelectedVendor = createAction(
  Types.UPLOAD_GEOMAP_SET_SELECTED_VENDOR,
);

// Upload Geomap Set Brand
export const uploadGeomapSetBrand = createAction(Types.UPLOAD_GEOMAP_SET_BRAND);

// Upload Geomap Set Interval
export const uploadGeomapSetInterval = createAction(
  Types.UPLOAD_GEOMAP_SET_INTERVAL,
);

// Upload Geomap Set Center
export const uploadGeomapSetCenter = createAction(
  Types.UPLOAD_GEOMAP_SET_CENTER,
);

// Upload Geomap save request
export const uploadGeomapSaveRequest = createAction(
  Types.UPLOAD_GEOMAP_SAVE_REQUEST,
);
export const uploadGeomapSaveSuccess = createAction(
  Types.UPLOAD_GEOMAP_SAVE_SUCCESS,
);
export const uploadGeomapSaveError = createAction(
  Types.UPLOAD_GEOMAP_SAVE_ERROR,
);

export const uploadGeomapResetState = createAction(
  Types.UPLOAD_GEOMAP_RESET_STATE,
);
