const validations = t => ({
  name: {
    required: t('requiredFieldText'),
  },
  description: {},
  roleIds: {
    required: t('requiredFieldText'),
  },
});

export { validations };
