import { apiGateway } from 'constants/services';

export default {
  uploadFile: orderId =>
    `${apiGateway}/vendor-service/invoice/${orderId}/upload`,
  updateBillingStatus: `${apiGateway}/vendor-service/invoice`,
  getBillingImageApi: orderId =>
    `${apiGateway}/vendor-service/invoice/image/${orderId}`,
  deleteBillingImage: orderId =>
    `${apiGateway}/vendor-service/invoice/${orderId}`,
  getBillingDetail: orderId =>
    `${apiGateway}/vendor-service/invoice/${orderId}`,
};
