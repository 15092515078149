import React from 'react';
import PropTypes from 'prop-types';

const SubMenuIcon = ({ fill, width, height }) => (
  <svg
    width={width}
    height={height}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.7636 6.56359C5.11508 6.21212 5.68492 6.21212 6.0364 6.56359L9 9.5272L11.9636 6.56359C12.3151 6.21212 12.8849 6.21212 13.2364 6.56359C13.5879 6.91506 13.5879 7.48491 13.2364 7.83638L9.6364 11.4364C9.28492 11.7879 8.71508 11.7879 8.3636 11.4364L4.7636 7.83638C4.41213 7.48491 4.41213 6.91506 4.7636 6.56359Z"
      fill="inherit"
    />
  </svg>
);

SubMenuIcon.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

SubMenuIcon.defaultProps = {
  fill: 'white',
  width: 20,
  height: 20,
};

export default SubMenuIcon;
