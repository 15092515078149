import dayjs from 'dayjs';

import translate from 'utils/translate';

import tabTypes from '../constants/tabTypes';

const updateColumns = (newColumns, baseColumns) => {
  const updatedColumns = [...baseColumns];
  updatedColumns.splice(6, 0, ...newColumns);

  return updatedColumns;
};

const createColumns = (selectedTabKey, baseColumns) => {
  if (selectedTabKey === tabTypes.pendingRequests) {
    return baseColumns;
  }

  if (selectedTabKey === tabTypes.confirmedRequests) {
    const approvedColumns = [
      {
        title: translate('requests:approved'),
        key: 'approvalByWho',
        dataIndex: 'approvalByWho',
        render: approvalByWho => approvalByWho?.name,
      },
      {
        title: translate('requests:approvedDate'),
        key: 'approvalDate',
        dataIndex: 'approvalDate',
        render: approvalDate => dayjs(approvalDate).format('DD/MM/YYYY HH:mm'),
        defaultSortOrder: 'descend',
        sorter: (a, b) =>
          dayjs(a.approvalDate).unix() - dayjs(b.approvalDate).unix(),
      },
    ];

    return updateColumns(approvedColumns, baseColumns);
  }
  if (selectedTabKey === tabTypes.declinedRequests) {
    const declinedColumns = [
      {
        title: translate('requests:declined'),
        dataIndex: 'canceledByWho',
        key: 'canceledByWho',
        render: canceledByWho => canceledByWho?.name,
      },
      {
        title: translate('requests:declinedDate'),
        dataIndex: 'canceledDate',
        key: 'canceledDate',
        render: canceledDate => {
          if (canceledDate) {
            return dayjs(canceledDate).format('DD/MM/YYYY HH:mm');
          }

          return '';
        },
        defaultSortOrder: 'descend',
        sorter: (a, b) =>
          dayjs(a.canceledDate).unix() - dayjs(b.canceledDate).unix(),
      },
    ];

    return updateColumns(declinedColumns, baseColumns);
  }

  return baseColumns;
};

export default createColumns;
