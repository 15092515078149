import { apiGateway } from 'constants/services';

export default {
  getTransactions: params => {
    const { page, size } = params;

    return `${apiGateway}/finance-service/vendor/daily-finance-info?page=${page}&size=${size}`;
  },
  getDetailedTransactions: params => {
    const { page, size } = params;

    return `${apiGateway}/finance-service/vendor/daily-finance-info/details?page=${page}&size=${size}`;
  },
  getTransactionDetail: args => {
    const { id, transactionType } = args;

    return `${apiGateway}/finance-service/vendor/daily-finance-info/detail/${id}?orderType=${transactionType}`;
  },
};
