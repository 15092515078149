export const GET_PERM_LIST_REQUEST = 'GET_PERM_LIST_REQUEST';
export const GET_PERM_LIST_SUCCESS = 'GET_PERM_LIST_SUCCESS';
export const GET_PERM_LIST_ERROR = 'GET_PERM_LIST_ERROR';

export const GET_PERM_LIST_CHILDREN_REQUEST = 'GET_PERM_LIST_CHILDREN_REQUEST';
export const GET_PERM_LIST_CHILDREN_SUCCESS = 'GET_PERM_LIST_CHILDREN_SUCCESS';
export const GET_PERM_LIST_CHILDREN_ERROR = 'GET_PERM_LIST_CHILDREN_ERROR';

export const GET_ROLE_LIST_REQUEST = 'GET_ROLE_LIST_PERM_REQUEST';
export const GET_ROLE_LIST_SUCCESS = 'GET_ROLE_LIST_PERM_SUCCESS';
export const GET_ROLE_LIST_ERROR = 'GET_ROLE_LIST_PERM_ERROR';

export const UPDATE_PERM_REQUEST = 'UPDATE_PERM_REQUEST';
export const UPDATE_PERM_SUCCESS = 'UPDATE_PERM_SUCCESS';
export const UPDATE_PERM_ERROR = 'UPDATE_PERM_ERROR';
