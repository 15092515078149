import { useTranslation } from 'react-i18next';

export default function useGeoMapTranslation(
  options = {
    isCommon: true,
  },
) {
  const { t } = useTranslation('geomap');

  let translation = t;

  if (options.isCommon) {
    translation = path => t(`common.${path}`);
  }

  return {
    t: translation,
  };
}
