import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import * as VendorProductActions from '../actions';

const useVendorInfoEffect = (vendorInfo, vendorBrandProducts) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (vendorBrandProducts.length > 0)
      dispatch(VendorProductActions.vendorProductBrandListReset());

    if (
      vendorInfo &&
      vendorInfo.id &&
      vendorInfo.brands &&
      vendorInfo.brands.length > 0
    ) {
      dispatch(VendorProductActions.vendorProductListRequest(vendorInfo.id));

      dispatch(
        VendorProductActions.vendorProductBrandListSuccess(vendorBrandProducts),
      );

      dispatch(VendorProductActions.vendorProductBrandListRequest());
    }
    // eslint-disable-next-line
  }, [dispatch, vendorInfo]);
};

export { useVendorInfoEffect };
