import * as Types from './types';

const initialState = {
  list: [],
  total: undefined,
  totalCount: undefined,
  error: {},
  lastFilter: undefined,
};

function PreviousOrdersReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_FILTERED_ORDERS_REQUEST:
      return {
        ...state,
        lastFilter: payload,
      };
    case Types.GET_FILTERED_ORDERS_SUCCESS:
      return {
        ...state,
        list: payload.orders.content,
        totalCount: payload.orders.totalElements,
        total: payload.totalPrice,
      };
    case Types.GET_FILTERED_ORDERS_ERROR:
      return {
        ...state,
        error: payload,
        total: undefined,
      };
    default:
      return state;
  }
}

export default PreviousOrdersReducer;
