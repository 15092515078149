import React, { useState, useEffect } from 'react';
import { Space, Divider } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { Icon, Button } from '@getir/web-components';

import Layout from 'components/Layout';
import InputWrapper from 'components/InputWrapper';
import SelectBoxWrapper from 'components/SelectBoxWrapper';
import SelectBoxWrapperAntd from 'components/Antd/Elements/SelectBox';
import YandexMap from 'components/Map';
import * as CS from 'common/style';
import * as ROUTES from 'constants/routes';
import { rolesWithName } from 'constants/roles';

import deliveryTimeList from '../deliveryTimeList';
import * as VendorActions from '../actions';

import * as S from './style';
import { validationRules } from './validations';

const timeList = deliveryTimeList(5, 210, 5);

const Add = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const districtList = useSelector(state => state.vendor.districts);
  const townList = useSelector(state => state.vendor.towns);
  const brandList = useSelector(state => state.vendor.brands);
  const firmList = useSelector(state => state.vendor.firms);
  const operationalManagerList = useSelector(
    state => state.vendor.operationalManagers,
  );
  const regionManagerList = useSelector(state => state.vendor.regionManagers);
  const citiesList = useSelector(state => state.mainHeader.cities);

  const exchangeList = [
    {
      label: t('vendor:exchangeFee.off'),
      value: false,
    },
    {
      label: t('vendor:exchangeFee.on'),
      value: true,
    },
  ];

  const [districtValue, setDistrictValue] = useState(undefined);
  const [townValue, setTownValue] = useState(undefined);
  const [, setBrandValue] = useState(undefined);

  const {
    handleSubmit,
    formState: { errors },
    control,
    setError,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      cashOnDelivery: false,
      scheduledDeliveryOption: true,
      district: undefined,
      town: undefined,
      firm: undefined,
      operationalManager: undefined,
      regionManager: undefined,
      city: undefined,
    },
  });

  const selectedCity = useWatch({
    control,
    name: 'city',
  });

  const selectedFirm = useWatch({
    control,
    name: 'firm',
  });

  useEffect(() => {
    dispatch(VendorActions.getBrandsSuccess([]));
    dispatch(VendorActions.getFirmRequest());
    dispatch(
      VendorActions.getOperationalManagerRequest({
        roleName: rolesWithName.ADMIN_OPERATIONAL_MANAGER,
      }),
    );
    dispatch(
      VendorActions.getRegionManagerRequest({
        roleName: rolesWithName.ADMIN_REGIONAL_MANAGER,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (selectedCity) {
      dispatch(VendorActions.getDistrictsRequest(selectedCity.value));
      setDistrictValue(undefined);
      setTownValue(undefined);
    }
  }, [selectedCity, dispatch]);

  useEffect(() => {
    if (selectedFirm) {
      dispatch(VendorActions.getBrandsRequest(selectedFirm.value));
      setBrandValue(undefined);
    }
  }, [selectedFirm, dispatch]);

  const handleDistrictChange = selectedDistrict => {
    setDistrictValue(selectedDistrict);
    dispatch(VendorActions.getTownsRequest(selectedDistrict.value));
    setTownValue(undefined);
  };

  const handleTownChange = selectedTown => {
    setTownValue(selectedTown);
  };

  const onSubmit = data => {
    const {
      vendorName,
      vendorPhoneNumber,
      commissionRate,
      city,
      searchAddress,
      address,
      operationalManager,
      regionManager,
      firm,
      maxDeliveryTime,
      minDeliveryTime,
      minBasketAmount,
      scheduledDeliveryOption,
      cashOnDelivery,
      label,
      brand,
      isExchangeFee,
    } = data;

    if (maxDeliveryTime.value <= minDeliveryTime.value) {
      setError('maxDeliveryTime', {
        type: 'focus',
        message: t('common:shouldBeGreater'),
      });

      return;
    }
    const addressData = {
      city: {
        id: city.value,
        name: city.label,
      },
      district: {
        id: districtValue.value,
        name: districtValue.label,
      },
      town: {
        id: townValue.value,
        name: townValue.label,
      },
      latitude: searchAddress.cordinates[0],
      longitude: searchAddress.cordinates[1],
      searchAddress: searchAddress.name,
      openAddress: address,
    };

    const resultData = {
      address: addressData,
      adminOperationManager: {
        id: operationalManager.value,
        name: operationalManager.label,
      },
      adminRegionalManager: {
        id: regionManager.value,
        name: regionManager.label,
      },
      brands: [brand.value],
      firm: {
        id: firm.value,
        name: firm.label,
      },
      maxDeliveryTime: maxDeliveryTime.value,
      minDeliveryTime: minDeliveryTime.value,
      minShoppingCartAmount: Number(minBasketAmount),
      scheduledDeliveryOption,
      cashOnDelivery,
      vendorName,
      vendorPhoneNumber,
      label,
      commissionRate: Number(commissionRate),
      isExchangeFee: isExchangeFee.value,
    };
    dispatch(VendorActions.addVendorRequest(resultData));
  };

  /* eslint-disable */
  const brandOptions = brandList
    ? brandList.map(item => ({
        value: item.id,
        label: item.brandName,
      }))
    : [];

  const districtOptions = districtList
    ? districtList.map(item => ({
        value: item.id,
        label: item.name,
      }))
    : [];
    const firmOptions = firmList ? firmList.map(item => ({
      value: item.id,
      label: item.firmName,
    })) : []

  const operationalManagerOptions = operationalManagerList.roleUsers ? operationalManagerList.roleUsers.map(item => ({
    value: item.userId,
    label: item.fullName,
  })): [];

  const regionManagerOptions = regionManagerList.roleUsers ? regionManagerList.roleUsers.map(item => ({
    value: item.userId,
    label: item.fullName,
  })): [];

  const cityOptions = citiesList ? citiesList.map(item => ({
    value: item.value,
    label: item.label,
  })) : [];


  const townOptions = townList
    ? townList.map(item => ({
        value: item.id,
        label: item.name,
      }))
    : [];
  /* eslint-enable */

  return (
    <Layout>
      <S.PageHeader>
        <S.PageTitle>
          <Icon
            name="arrow-left"
            onClick={() => history.push(ROUTES.VENDORS)}
            title={t('backToList')}
          />
          {t('vendor:addVendor')}
        </S.PageTitle>
      </S.PageHeader>
      <S.PageContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputWrapper
            fieldName="vendorName"
            control={control}
            rules={validationRules.name}
            placeholder={t('vendor:vendorName')}
            header={t('vendor:vendorName')}
            errors={errors}
          />
          <InputWrapper
            fieldName="vendorPhoneNumber"
            control={control}
            rules={validationRules.phone}
            placeholder={t('vendor:vendorPhone')}
            header={t('vendor:vendorPhone')}
            errors={errors}
            isOnlyNumber
          />
          <InputWrapper
            fieldName="commissionRate"
            control={control}
            rules={validationRules.commissionRate}
            placeholder={t('vendor:commissionRate')}
            header={t('vendor:commissionRate')}
            errors={errors}
            isOnlyNumber
          />
          <SelectBoxWrapper
            fieldName="minDeliveryTime"
            control={control}
            rules={validationRules.minDeliveryTime}
            placeholder={t('vendor:minDeliveryTime')}
            errors={errors}
            options={timeList}
          />

          <SelectBoxWrapper
            fieldName="maxDeliveryTime"
            control={control}
            rules={validationRules.maxDeliveryTime}
            placeholder={t('vendor:maxDeliveryTime')}
            errors={errors}
            options={timeList}
          />

          <InputWrapper
            fieldName="minBasketAmount"
            control={control}
            rules={validationRules.minBasketAmount}
            placeholder={t('vendor:minBasketAmount')}
            header={t('vendor:minBasketAmount')}
            errors={errors}
            isOnlyNumber
          />
          <div>
            <Controller
              name="operationalManager"
              control={control}
              rules={validationRules.operationalManager}
              render={({ field }) => (
                <SelectBoxWrapperAntd
                  placeholder={t('vendor:operationalManager')}
                  onChange={value => {
                    field.onChange(value);
                  }}
                  showSearch
                  hasError={errors.operationalManager}
                  value={field.value}
                  options={operationalManagerOptions}
                  labelInValue
                  allowClear
                />
              )}
            />
            {errors.operationalManager && (
              <CS.ErrorMessage>
                {errors.operationalManager.message}
              </CS.ErrorMessage>
            )}
          </div>
          <div>
            <Controller
              name="regionManager"
              control={control}
              rules={validationRules.regionManager}
              render={({ field }) => (
                <SelectBoxWrapperAntd
                  placeholder={t('vendor:regionManager')}
                  showSearch
                  hasError={errors.regionManager}
                  value={field.value}
                  onChange={field.onChange}
                  options={regionManagerOptions}
                  labelInValue
                  allowClear
                />
              )}
            />
            {errors.regionManager && (
              <CS.ErrorMessage>{errors.regionManager.message}</CS.ErrorMessage>
            )}
          </div>
          <div>
            <Controller
              name="firm"
              control={control}
              rules={validationRules.firm}
              render={({ field }) => (
                <SelectBoxWrapperAntd
                  placeholder={t('vendor:firm')}
                  showSearch
                  hasError={errors.firm}
                  value={field.value}
                  onChange={field.onChange}
                  options={firmOptions}
                  labelInValue
                  allowClear
                />
              )}
            />
            {errors.firm && (
              <CS.ErrorMessage>{errors.firm.message}</CS.ErrorMessage>
            )}
          </div>
          <div>
            <Controller
              name="brand"
              control={control}
              rules={validationRules.brand}
              render={({ field }) => (
                <SelectBoxWrapperAntd
                  placeholder={t('vendor:brand')}
                  showSearch
                  hasError={errors.brand}
                  value={field.value}
                  onChange={field.onChange}
                  options={brandOptions}
                  labelInValue
                  allowClear
                />
              )}
            />
            {errors.brand && (
              <CS.ErrorMessage>{errors.brand.message}</CS.ErrorMessage>
            )}
          </div>
          <div>
            <Controller
              name="city"
              control={control}
              rules={validationRules.city}
              render={({ field }) => (
                <SelectBoxWrapperAntd
                  placeholder={t('vendor:city')}
                  onChange={value => {
                    field.onChange(value);
                  }}
                  showSearch
                  hasError={errors.city}
                  value={field.value}
                  options={cityOptions}
                  labelInValue
                  allowClear
                />
              )}
            />
            {errors.city && (
              <CS.ErrorMessage>{errors.city.message}</CS.ErrorMessage>
            )}
          </div>

          <div>
            <Controller
              name="district"
              control={control}
              rules={validationRules.district}
              render={({ field }) => (
                <SelectBoxWrapperAntd
                  placeholder={t('vendor:district')}
                  onChange={value => {
                    handleDistrictChange(value);
                    field.onChange(value);
                  }}
                  showSearch
                  hasError={errors.district}
                  value={field.value}
                  options={districtOptions}
                  labelInValue
                  allowClear
                />
              )}
            />
            {errors.district && (
              <CS.ErrorMessage>{errors.district.message}</CS.ErrorMessage>
            )}
          </div>
          <div>
            <Controller
              name="town"
              control={control}
              rules={validationRules.town}
              render={({ field }) => (
                <SelectBoxWrapperAntd
                  placeholder={t('vendor:town')}
                  onChange={value => {
                    handleTownChange(value);
                    field.onChange(value);
                  }}
                  showSearch
                  hasError={errors.town}
                  value={field.value}
                  options={townOptions}
                  labelInValue
                  allowClear
                />
              )}
            />
            {errors.town && (
              <CS.ErrorMessage>{errors.town.message}</CS.ErrorMessage>
            )}
          </div>

          <InputWrapper
            fieldName="address"
            control={control}
            rules={validationRules.address}
            placeholder={t('vendor:address')}
            header={t('vendor:address')}
            errors={errors}
          />
          <SelectBoxWrapper
            fieldName="isExchangeFee"
            control={control}
            rules={validationRules.isExchangeFee}
            placeholder={t('vendor:exchangeFee.inputLabel')}
            errors={errors}
            options={exchangeList}
          />
          <CS.FormFieldWrapper>
            <Controller
              name="searchAddress"
              control={control}
              rules={validationRules.searchAddress}
              render={({ field }) => (
                <YandexMap
                  field={field}
                  setFieldValue={field.onChange}
                  errors={errors}
                />
              )}
            />
          </CS.FormFieldWrapper>
          <Divider />
          <Space size="middle">
            <Button
              kind="secondary"
              onClick={() => history.push(ROUTES.VENDORS)}
            >
              {t('giveUp')}
            </Button>
            <Button type="submit">{t('save')}</Button>
          </Space>
        </form>
      </S.PageContent>
    </Layout>
  );
};

export default Add;
