import { ORDERCANCELMODAL_CLOSE, ORDERCANCELMODAL_OPEN } from './types';

const initialState = {
  isModalOpen: false,
  orderId: null,
};

function OrderCancelReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ORDERCANCELMODAL_OPEN:
      return {
        ...state,
        isModalOpen: true,
        orderId: payload.id,
      };
    case ORDERCANCELMODAL_CLOSE:
      return {
        ...state,
        isModalOpen: false,
        orderId: null,
      };
    default:
      return state;
  }
}

export default OrderCancelReducer;
