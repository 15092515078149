import translate from 'utils/translate';

const validationRules = {
  brandId: {
    required: translate('requiredFieldText'),
  },
  cityId: {
    required: translate('requiredFieldText'),
  },
  neighbourhoodId: {},
  interval: {
    required: translate('requiredFieldText'),
  },
};

export { validationRules };
