/* eslint-disable consistent-return */
/* eslint-disable no-continue */
import * as turf from '@turf/turf';

import { MAX_AREA_SIZE_M2, POLYGON_ERROR_CODES } from '../constant';

/**
 * @param {feature} feature - array of coordinates
 * @returns {number} - area m2
 * @description - get area from feature
 * @example
 */
export const getAreaFromFeature = feature => turf.area(feature);

/**
 * @param {Array} featureArray - array of features
 * @param {Object} properties - properties to be added to the unioned feature
 * @returns {Array} - array of unioned features
 * @description - union intersected features
 */
export const unionIntersectedFeatures = ({ featureArray, properties = {} }) => {
  if (!featureArray || featureArray.length < 1) return [];

  let featureArrayLength = featureArray.length;
  const newArray = [...featureArray];

  for (let i = 0; i < featureArrayLength; i += 1) {
    const mainFeature = newArray[i];
    if (!mainFeature) continue;

    // eslint-disable-next-line no-loop-func
    for (let j = 0; j < featureArrayLength; j += 1) {
      const feat = newArray[j];
      if (!feat) continue;

      if (mainFeature === feat) {
        continue;
      }

      const unionedFeature = turf.union(mainFeature, feat, {
        properties: {
          ...properties,
        },
      });

      if (unionedFeature.geometry.type === 'MultiPolygon') {
        continue;
      }

      newArray[i] = undefined;
      newArray[j] = undefined;
      newArray.push(unionedFeature);
      featureArrayLength += 1;
      break;
    }
  }

  return newArray.filter(f => f);
};

/**
 *
 * @param {Polgon} polygon1
 * @param {Polgon} polygon2
 * @returns {Array} - array of polygons
 * @description - remove intersection from polygon2
 */
export const removeIntersectedPolygons = (polygon1, polygon2) => {
  const areaWithoutIntersection = turf.difference(polygon2, polygon1);
  const isMultiPolygon =
    areaWithoutIntersection &&
    areaWithoutIntersection.geometry.type === 'MultiPolygon';

  if (isMultiPolygon) {
    const polygons = areaWithoutIntersection.geometry.coordinates.map(p =>
      turf.polygon(p, {
        ...polygon2.properties,
      }),
    );

    return polygons;
  }

  if (!areaWithoutIntersection) return [];

  return [areaWithoutIntersection];
};

/**
 *
 * @param {coordinates} coordinates
 * @param {Objecr} properties
 * @returns  {Polygon} - polygon
 * @description - create polygon
 */
export const createPolygon = (coordinates, properties) =>
  turf.polygon(coordinates, properties);

/**
 *
 * @param {Array} polygonArray
 * @returns featureCollection
 * @description - create feature collection
 */
export const createFeatureCollection = polygonArray =>
  turf.featureCollection(polygonArray);

/**
 *
 * @param {Array} selectedVendorFeatures
 * @param {Object} selectedAreaFeatureCollections
 * @returns   {Array} - array of polygons
 * @description - remove selectedVendorFeatures polygons from selectedAreaFeatureCollections
 */
export const iterateAndRemovePolygons = (
  selectedVendorFeatures,
  selectedAreaFeatureCollections,
) => {
  const selectedVendorFeaturesLength = selectedVendorFeatures.length;
  const newVendorFeatures = [];

  for (let i = 0; i < selectedVendorFeaturesLength; i += 1) {
    const features = [{ ...selectedVendorFeatures[i] }];
    let featuresLength = features.length;

    for (let j = 0; j < featuresLength; j += 1) {
      // eslint-disable-next-line no-loop-func
      selectedAreaFeatureCollections?.features?.forEach(selectedAreaFeature => {
        if (!features[j]) return;

        const removedArea = removeIntersectedPolygons(
          selectedAreaFeature,
          features[j],
        );

        if (removedArea.length > 1) {
          features.push(...removedArea);
          featuresLength += removedArea.length;
          features[j] = undefined;
        } else {
          // eslint-disable-next-line prefer-destructuring
          features[j] = removedArea[0];
        }
      });
    }
    features.filter(f => f).forEach(f => newVendorFeatures.push(f));
  }

  return newVendorFeatures;
};

/**
 *
 * @param {string} selectedVendor
 * @param {FeatureCollection} selectedAreaFeatureCollections
 * @param {FeatureCollection} firmFeatureCollections
 * @returns {FeatureCollection} - featureCollection
 * @description - remove selected areas from vendor
 */
export const removeSelectedAreasFromVendor = (
  selectedVendor,
  selectedAreaFeatureCollections,
  firmFeatureCollections,
) => {
  const unSelectedVendors = [];

  const selectedVendorFeatures = firmFeatureCollections.features.filter(
    feature => {
      if (feature.properties.warehouse !== selectedVendor) {
        unSelectedVendors.push(feature);

        return false;
      }

      return feature.properties.warehouse === selectedVendor;
    },
  );

  const newVendorFeatures = iterateAndRemovePolygons(
    selectedVendorFeatures,
    selectedAreaFeatureCollections,
  );

  let isSmallAreasRemoved = false;

  const mappedNewVendorFeatures = newVendorFeatures.flatMap(f => {
    if (f.geometry.coordinates.length > 1) {
      const err = new Error(POLYGON_ERROR_CODES[10001].message);
      err.code = POLYGON_ERROR_CODES[10001].code;
      throw err;
    }

    const areaOfFeature = getAreaFromFeature(f);

    if (areaOfFeature < MAX_AREA_SIZE_M2) {
      isSmallAreasRemoved = true;

      return [];
    }

    return [f];
  });

  const mappedUnSelectedVendors = unSelectedVendors.flatMap(f => {
    const areaOfFeature = getAreaFromFeature(f);

    if (areaOfFeature < MAX_AREA_SIZE_M2) {
      isSmallAreasRemoved = true;

      return [];
    }

    return [f];
  });

  const newFeatures = [...mappedNewVendorFeatures, ...mappedUnSelectedVendors];

  return {
    isSmallAreasRemoved,
    featureCollection: createFeatureCollection(newFeatures),
  };
};

/**
 *
 * @param {string} selectedVendor
 * @param {FeatureCollection} selectedAreaFeatureCollections
 * @param {boolean} isActive
 * @param {FeatureCollection} firmFeatureCollections
 * @returns {FeatureCollection} - featureCollection
 * @returns {boolean} - isLowerAreasRemoved
 * @description - add selected areas to vendor
 */

export const addSelectedAreasToVendor = (
  selectedVendor,
  selectedAreaFeatureCollections,
  isActive,
  firmFeatureCollections,
) => {
  if (!firmFeatureCollections || firmFeatureCollections.features.length < 1) {
    const selectedAreaFeatures = unionIntersectedFeatures({
      featureArray: selectedAreaFeatureCollections?.features?.map(f =>
        turf.feature(f.geometry, {
          warehouse: selectedVendor,
          isActive,
        }),
      ),
      properties: {
        warehouse: selectedVendor,
        isActive,
      },
    });

    selectedAreaFeatures.forEach(feature => {
      if (feature.geometry.coordinates.length > 1) {
        const err = new Error(POLYGON_ERROR_CODES[10001].message);
        err.code = POLYGON_ERROR_CODES[10001].code;
        throw err;
      }
    });

    return {
      isSmallAreasRemoved: false,
      featureCollection: createFeatureCollection(selectedAreaFeatures),
    };
  }
  const unSelectedVendorsFeatures = [];
  const selectedVendorFeatures = firmFeatureCollections.features.filter(
    feature => {
      if (feature.properties.warehouse !== selectedVendor) {
        unSelectedVendorsFeatures.push(feature);

        return false;
      }

      return feature.properties.warehouse === selectedVendor;
    },
  );
  const selectedAreaFeatures = selectedAreaFeatureCollections?.features?.map(
    f =>
      turf.feature(f.geometry, {
        warehouse: selectedVendor,
        isActive,
      }),
  );

  const mappedSelectedAreaFeatureCollections = createFeatureCollection([
    ...selectedAreaFeatures,
  ]);

  const newUnselectedVendors = iterateAndRemovePolygons(
    unSelectedVendorsFeatures,
    mappedSelectedAreaFeatureCollections,
    {
      warehouse: selectedVendor,
      isActive: true,
    },
  );

  const unionedSelectedAreasAndSelectedVendorFeatures = unionIntersectedFeatures(
    {
      featureArray: [...selectedVendorFeatures, ...selectedAreaFeatures],
      properties: {
        warehouse: selectedVendor,
        isActive,
      },
    },
  );

  let isSmallAreasRemoved = false;

  const mappedUnionedSelectedAreasAndSelectedVendorFeatures = unionedSelectedAreasAndSelectedVendorFeatures.flatMap(
    f => {
      const areaOfFeature = getAreaFromFeature(f);

      if (areaOfFeature < MAX_AREA_SIZE_M2) {
        isSmallAreasRemoved = true;

        return [];
      }

      return [f];
    },
  );

  const mappedNewUnselectedVendors = newUnselectedVendors.flatMap(f => {
    const areaOfFeature = getAreaFromFeature(f);

    if (areaOfFeature < MAX_AREA_SIZE_M2) {
      isSmallAreasRemoved = true;

      return [];
    }

    return [f];
  });

  const newFeatures = [
    ...mappedNewUnselectedVendors,
    ...mappedUnionedSelectedAreasAndSelectedVendorFeatures,
  ];

  newFeatures.forEach(feature => {
    if (feature.geometry.coordinates.length > 1) {
      const err = new Error(POLYGON_ERROR_CODES[10001].message);
      err.code = POLYGON_ERROR_CODES[10001].code;
      throw err;
    }
  });

  return {
    isSmallAreasRemoved,
    featureCollection: createFeatureCollection(newFeatures),
  };
};

export const getCenterFromFeatureCollection = featureCollection => {
  if (!featureCollection || featureCollection.features.length < 1) return null;

  const center = turf.centerOfMass(featureCollection);

  return center;
};
