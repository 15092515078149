import styled from 'styled-components';

export const EditorContainer = styled.div`
  border: 1px solid #d4d4d4;
  border-radius: 8px;
  .ProseMirror {
    padding: 12px;
    &:focus-visible {
      outline: none;
    }
  }
`;

export const Seperator = styled.div`
  height: 1px;
  background-color: #d4d4d4;
`;
