import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useVirtualizer } from '@tanstack/react-virtual';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import Modal from 'components/v1/Modal';
import Button from 'components/v1/Button';
import { CloseIcon, ExclaimationIcon } from 'components/v1/Icons';
import RichTextEditor from 'components/v1/RichTextEditor';
import useUserInfo from 'hooks/useUserInfo';
import { rolesWithName } from 'constants/roles';

import * as vendorCommunicationActions from '../actions';

import * as S from './styles';

const MessageDetail = () => {
  const { t } = useTranslation('vendorCommunication');
  const dispatch = useDispatch();
  const { isModalOpen, threadId } = useSelector(
    state => state.vendorCommunication.modalOptions,
  );

  const { list: threadDetailMessages, isLocked, toUserName } = useSelector(
    state => state.vendorCommunication.threadDetail,
  );

  const [messageError, setMessageError] = useState(undefined);
  const richTextEditorRef = useRef(null);
  const { userInfo } = useUserInfo();

  const isAdmin = userInfo?.role?.includes(rolesWithName.ADMIN) || false;

  useEffect(
    () => () => dispatch(vendorCommunicationActions.clearThreadDetail()),
    [dispatch],
  );
  useEffect(() => {
    if (threadId) {
      dispatch(vendorCommunicationActions.changeReadStatusRequest(threadId));
    }
  }, [dispatch, threadId]);

  useEffect(() => {
    if (threadId) {
      dispatch(vendorCommunicationActions.getThreadDetailRequest(threadId));
    }
  }, [dispatch, threadId]);

  const closeModal = useCallback(() => {
    dispatch(vendorCommunicationActions.closeMessageModal());
  }, [dispatch]);

  const parentRef = useRef();

  const messageCount = threadDetailMessages.length || 0;

  const rowVirtualizer = useVirtualizer({
    count: messageCount,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 45,
  });

  useEffect(() => {
    if (messageCount > 0) {
      rowVirtualizer.scrollToIndex(messageCount - 1);
    }
  }, [messageCount, rowVirtualizer]);

  const sendMessageHandler = useCallback(() => {
    const text = richTextEditorRef.current?.getText().trim();
    if (!text) {
      setMessageError(t('vendorCommunication:pleaseEnterAMessage'));

      return;
    }

    if (messageError) {
      setMessageError(undefined);
    }

    const message = richTextEditorRef.current?.getHTML();
    const requestBody = {
      content: message,
      toId:
        threadDetailMessages[0]?.toId === userInfo.userId
          ? threadDetailMessages[0]?.fromId
          : threadDetailMessages[0]?.toId,
      threadId,
    };
    dispatch(vendorCommunicationActions.sendMessageRequest(requestBody));
    richTextEditorRef.current?.clearData();
  }, [
    dispatch,
    messageError,
    threadDetailMessages,
    threadId,
    userInfo.userId,
    t,
  ]);

  const Footer = useMemo(
    () =>
      isLocked ? (
        t('threadIsClosed')
      ) : (
        <S.Footer>
          <Button onClick={sendMessageHandler} kind="primary">
            {t('vendorCommunication:send')}
          </Button>
        </S.Footer>
      ),
    [isLocked, sendMessageHandler, t],
  );

  const closeThreadHandler = useCallback(() => {
    dispatch(
      vendorCommunicationActions.closeThreadRequest({ threadId, isAdmin }),
    );
  }, [dispatch, threadId, isAdmin]);

  const Header = useMemo(
    () => (
      <S.Header>
        <S.HeaderLeft>{toUserName}</S.HeaderLeft>
        <S.MessageHeaderRight>
          {isAdmin && !isLocked && (
            <Button
              onClick={closeThreadHandler}
              style={{
                color: '#FF3939',
                border: '1px solid #FF3939',
                backgroundColor: '#FFEFEF',
                fontSize: '12px',
                fontWeight: '700',
              }}
            >
              {t('vendorCommunication:endConversation')}
            </Button>
          )}
          <Button kind="secondary" onClick={closeModal}>
            <CloseIcon fill="#191919" />
          </Button>
        </S.MessageHeaderRight>
      </S.Header>
    ),
    [closeModal, closeThreadHandler, isAdmin, toUserName, t, isLocked],
  );
  const items = rowVirtualizer.getVirtualItems();

  return (
    <Modal
      footerOptions={{
        customFooter: Footer,
      }}
      key="MessageModal"
      headerOptions={{
        customHeader: Header,
        headerStyle: {
          padding: '12px 24px !important',
          'background-color': '#f0f1f3',
        },
      }}
      isOpen={isModalOpen}
      onClose={closeModal}
    >
      <S.ModalContainer>
        <div
          style={{
            height: `${rowVirtualizer.getTotalSize()}px`,
            maxHeight: '400px',
            width: '100%',
            overflowY: 'auto',
            contain: 'strict',
          }}
          ref={parentRef}
        >
          <div
            style={{
              height: rowVirtualizer.getTotalSize(),
              width: '100%',
              position: 'relative',
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                transform: `translateY(${items[0]?.start}px)`,
              }}
            >
              {items.map(virtualRow => {
                const data = threadDetailMessages[virtualRow.index];
                const isMe = data.fromId === userInfo.userId;

                return (
                  <div
                    key={virtualRow.key}
                    data-index={virtualRow.index}
                    ref={rowVirtualizer.measureElement}
                    className={
                      virtualRow.index % 2 ? 'ListItemOdd' : 'ListItemEven'
                    }
                  >
                    <S.MessageWrapper
                      $isMe={isMe}
                      style={{ padding: '10px 0' }}
                    >
                      <S.MessageContainer $isMe={isMe}>
                        <S.MessageDateContainer $isMe={isMe}>
                          {dayjs(data?.createdAt).format('DD.MM.YYYY HH:mm')}
                        </S.MessageDateContainer>
                        <S.MessageContent
                          dangerouslySetInnerHTML={{
                            __html:
                              threadDetailMessages[virtualRow.index]?.content,
                          }}
                        ></S.MessageContent>
                      </S.MessageContainer>
                    </S.MessageWrapper>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {!isLocked && <RichTextEditor ref={richTextEditorRef} />}
        {messageError && (
          <S.ErrorMessage>
            <ExclaimationIcon height={16} width={16} fill="inherit" />
            {messageError}
          </S.ErrorMessage>
        )}
      </S.ModalContainer>
    </Modal>
  );
};

export default MessageDetail;
