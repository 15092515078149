import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@getir/web-components';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import locale from 'antd/lib/locale/tr_TR';

import store from 'store/configureStore';

import Sentry from './sentry';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './utils/i18n';
import 'dayjs/locale/tr';

import 'index.css';

Sentry.init();

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider>
      <ConfigProvider locale={locale}>
        <Sentry.CustomErrorBoundary>
          <App />
        </Sentry.CustomErrorBoundary>
      </ConfigProvider>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
