import { takeLatest, call } from '@redux-saga/core/effects';
import { put } from 'redux-saga/effects';

import * as LoaderActions from 'components/Loader/actions';
import {
  getMapApi,
  saveMapApi,
  getVendorsApi,
  getDistrictsApi,
  getNeighbourhoodsApi,
} from 'services/GeoMap/api';
import { createStandardSaga } from 'utils/createStandardSaga';
import { open } from 'utils/notification';
import translate from 'utils/translate';

import * as Types from './types';
import * as UploadGeomapActions from './actions';

const geomapSaveSaga = createStandardSaga({
  callApi: saveMapApi,
  successAction: UploadGeomapActions.uploadGeomapSaveSuccess,
  errorAction: UploadGeomapActions.uploadGeomapSaveError,
  successMessage: 'geomap:common.messages.savesuccess',
});

const geomapGetVendorsSaga = createStandardSaga({
  callApi: getVendorsApi,
  successAction: UploadGeomapActions.uploadGeomapGetVendorsSuccess,
  errorAction: UploadGeomapActions.uploadGeomapGetVendorsError,
  successMessage: 'geomap:common.messages.getVendorsSuccess',
});

function* geomapGetSaga({ payload }) {
  yield put(LoaderActions.showLoader());
  try {
    const result = yield call(getMapApi, payload);
    if (result.data) {
      yield put(
        UploadGeomapActions.uploadGeomapGetSuccess(result.data.payload),
      );

      open(
        'success',
        translate('common:notif.successTitle'),
        translate('geomap:common.messages.getsuccess'),
      );
    }

    if (result.message) {
      open('warning', translate('common:notif.warnTitle'), result.message);
    }
  } catch (error) {
    yield put(UploadGeomapActions.uploadGeomapGetError(error.message));
  }
  yield put(LoaderActions.hideLoader());
}

function* geomapGetNeighbourhoodSaga({ payload }) {
  yield put(LoaderActions.showLoader());
  if (!payload || !payload.districtId) {
    yield put(UploadGeomapActions.uploadGeomapGetNeighbourhoodSuccess([]));
    yield put(UploadGeomapActions.uploadGeomapSetEditedMap([]));
    yield put(LoaderActions.hideLoader());

    return;
  }
  try {
    const result = yield call(getNeighbourhoodsApi, payload);

    if (result.data) {
      const neighbourhood = result.data.payload.features.map(feature => ({
        id: feature.properties.id,
        name: feature.properties.nameTr,
        polygon: feature.geometry.coordinates,
      }));

      yield put(
        UploadGeomapActions.uploadGeomapGetNeighbourhoodSuccess(neighbourhood),
      );

      open(
        'success',
        translate('common:notif.successTitle'),
        translate('geomap:common.messages.neighbourhoodsGetSuccess'),
      );
    }

    if (result.message) {
      open('warning', translate('common:notif.warnTitle'), result.message);
    }
  } catch (error) {
    yield put(
      UploadGeomapActions.uploadGeomapGetNeighbourhoodError(error.message),
    );
  }
  yield put(LoaderActions.hideLoader());
}

function* geomapGetDistrictSaga({ payload }) {
  yield put(LoaderActions.showLoader());

  try {
    const result = yield call(getDistrictsApi, payload);
    if (result.data) {
      const districts = result.data.payload.features.map(feature => ({
        id: feature.properties.id,
        name: feature.properties.nameTr,
        polygon: feature.geometry.coordinates,
      }));
      yield put(UploadGeomapActions.uploadGeomapSetEditedMap(undefined));
      yield put(UploadGeomapActions.uploadGeomapGetDistrictSuccess(districts));

      open(
        'success',
        translate('common:notif.successTitle'),
        translate('geomap:common.messages.districtsGetSuccess'),
      );
    }

    if (result.message) {
      open('warning', translate('common:notif.warnTitle'), result.message);
    }
  } catch (error) {
    yield put(UploadGeomapActions.uploadGeomapGetDistrictError(error.message));
  }
  yield put(LoaderActions.hideLoader());
}

export function* rootSaga() {
  yield takeLatest(Types.UPLOAD_GEOMAP_GET_REQUEST, geomapGetSaga);
  yield takeLatest(Types.UPLOAD_GEOMAP_SAVE_REQUEST, geomapSaveSaga);
  yield takeLatest(
    Types.UPLOAD_GEOMAP_GET_NEIGHBOURHOOD_REQUEST,
    geomapGetNeighbourhoodSaga,
  );
  yield takeLatest(
    Types.UPLOAD_GEOMAP_GET_DISTRICT_REQUEST,
    geomapGetDistrictSaga,
  );
  yield takeLatest(
    Types.UPLOAD_GEOMAP_GET_VENDORS_REQUEST,
    geomapGetVendorsSaga,
  );
}

export default rootSaga;
