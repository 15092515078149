import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from 'components/v1/Button';
import Layout from 'components/Layout';
import * as ROUTES from 'constants/routes';

import List from './List';
import * as FirmActions from './actions';
import * as S from './style';

const Firm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(FirmActions.firmListRequest());
  }, [dispatch]);
  const canCreateFirm = false;

  return (
    <Layout>
      <S.PageHeader>
        <S.PageTitle>{t('firm:title')}</S.PageTitle>
        <Button
          kind="primary"
          onClick={() => history.push(ROUTES.ADD_NEW_FIRM)}
          disabled={!canCreateFirm}
        >
          {t('firm:addNewFirm')}
        </Button>
      </S.PageHeader>
      <List />
    </Layout>
  );
};

export default Firm;
