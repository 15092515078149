import { get, isNull, isArray } from 'lodash';

import { BanGeoMapInterval } from './BanGeoMap/constants';
import { GeoMapStateKey } from './Common/common';
import { UploadGeoMapInterval } from './UploadGeoMap/constants';

export const getSelectList = (data, labelProp, valueProp) =>
  data?.map(item => ({
    label: item[labelProp],
    value: item[valueProp],
  }));

export const getGeoMapIntervalBasedOnStateKey = stateKey => {
  switch (stateKey) {
    case GeoMapStateKey.UPLOAD_GEO_MAP:
      return UploadGeoMapInterval;
    case GeoMapStateKey.BAN_GEO_MAP:
      return BanGeoMapInterval;
    default:
      return {};
  }
};

export const convertToJSON = (
  data,
  options = {
    beautify: true,
  },
) => JSON.stringify(data, null, options.beautify ? 2 : 0);

export const getPolygonGeometry = polygons => {
  const features = get(polygons, 'features', []);
  const geometryList = [];
  let initialWarehouse = null;
  features.forEach(feature => {
    if (isNull(initialWarehouse)) {
      initialWarehouse = feature.properties.warehouse;
    }
    if (initialWarehouse === feature.properties.warehouse) {
      geometryList.push({ coordinates: feature.geometry.coordinates });
    }
  });

  return geometryList;
};

export const combineGeoFragments = (geometryList, vendorSelectList) => {
  if (!isArray(geometryList))
    return { type: 'FeatureCollection', features: [] };
  const features = [];
  geometryList.forEach(geometryCoords => {
    vendorSelectList.forEach(vendorSelect => {
      features.push({
        type: 'Feature',
        geometry: {
          ...geometryCoords,
          type: 'Polygon',
        },
        properties: {
          warehouse: vendorSelect.value,
          isActive: true,
        },
      });
    });
  });

  return {
    type: 'FeatureCollection',
    features,
  };
};
