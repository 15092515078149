import { createAction } from 'utils/createAction';

import * as Types from './types';

// get UndertakingDoc
export const getUndertakingDocRequest = createAction(
  Types.GET_UNDERTAKING_DOC_REQUEST,
);
export const getUndertakingDocSuccess = createAction(
  Types.GET_UNDERTAKING_DOC_SUCCESS,
);
export const getUndertakingDocError = createAction(
  Types.GET_UNDERTAKING_DOC_ERROR,
);

// approve UndertakingDoc

export const approveUndertakingDocRequest = createAction(
  Types.APPROVE_UNDERTAKING_DOC_REQUEST,
);
export const approveUndertakingDocSuccess = createAction(
  Types.APPROVE_UNDERTAKING_DOC_SUCCESS,
);
export const approveUndertakingDocError = createAction(
  Types.APPROVE_UNDERTAKING_DOC_ERROR,
);
