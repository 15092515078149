import styled from 'styled-components';
import { Upload, Form } from 'antd';

const { Item } = Form;

const StyledUpload = styled(Upload)`
  .upload-list-inline .ant-upload-list-item {
    float: left;
    width: 200px;
    margin-right: 8px;
  }

  .upload-list-inline [class*='-upload-list-rtl'] .ant-upload-list-item {
    float: right;
  }
`;

const StyledFormItem = styled(Item)`
  border: ${props => (props.error ? `2px solid #DB471E` : 'none')};
  border-radius: 4px;
`;

export { StyledUpload, StyledFormItem };
