export const GET_FIRM_REQUEST = 'GET_FIRM_REQUEST';
export const GET_FIRM_SUCCESS = 'GET_FIRM_SUCCESS';
export const GET_FIRM_ERROR = 'GET_FIRM_ERROR';

export const GET_OPERATIONAL_MANAGER_REQUEST = 'GET_OPERATIONAL_MANAGER';
export const GET_OPERATIONAL_MANAGER_SUCCESS =
  'GET_OPERATIONAL_MANAGER_SUCCESS';
export const GET_OPERATIONAL_MANAGER_ERROR = 'GET_OPERATIONAL_MANAGER_ERROR';

export const GET_REGION_MANAGER_REQUEST = 'GET_REGION_MANAGER_REQUEST';
export const GET_REGION_MANAGER_SUCCESS = 'GET_REGION_MANAGER_SUCCESS';
export const GET_REGION_MANAGER_ERROR = 'GET_REGION_MANAGER_ERROR';
