function range(size, startAt = 0) {
  return [...Array(size).keys()].map(i => i + startAt);
}
const selectboxOptionsHours = range(19, 5);
const selectboxOptionsMinutes = ['00', '15', '30', '45'];

const selectboxOptions = [];
selectboxOptionsHours.forEach(hour => {
  selectboxOptionsMinutes.forEach(minute => {
    selectboxOptions.push({
      label: `${hour.toString().padStart(2, '0')}:${minute}`,
      value: `${hour.toString().padStart(2, '0')}:${minute}`,
    });
  });
});

export { selectboxOptions };
