import { apiGateway } from 'constants/services';

export default {
  listBrands: `${apiGateway}/brand-service/get/all`,
  addBrand: `${apiGateway}/brand-service/create`,
  updateBrand: `${apiGateway}/brand-service/update`,
  saveLogo: `${apiGateway}/brand-service/save-logo`,
  updateBrandAvailableHours: brandId =>
    `${apiGateway}/vendor-service/brands/alternative-intervals/${brandId}`,
  deleteBrand: brandId => `${apiGateway}/brand-service/delete/${brandId}`,
  getBrandInfo: brandId => `${apiGateway}/brand-service/get/all/${brandId}`,
  getBrandProducts: brandId =>
    `${apiGateway}/product-service/get-product-with-brand-id/${brandId}`,
  saveOrderBrandProducts: `${apiGateway}/product-service/order`,
  getBrandAvailableHours: brandId =>
    `${apiGateway}/vendor-service/brands/alternative-intervals/${brandId}`,
};
