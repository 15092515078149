import styled from 'styled-components';
import { DatePicker } from 'antd';

const { RangePicker } = DatePicker;

export const StyledRangePicker = styled(RangePicker)`
  height: 50px;
  border: 2px solid #dfdee2 !important;
  border-radius: 4px !important;
  width: 100%;
  margin-top: 3px;
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const InputArea = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  grid-row-gap: 16px;
  grid-column-gap: 8px;
  align-items: flex-end;
`;

export const ActionsArea = styled.div`
  align-self: flex-end;
`;
