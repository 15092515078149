import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { email } from '@sideway/address';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';

import { MAX_PASSWORD_LENGTH, MIN_NEW_PASSWORD_LENGTH } from 'common/constants';
import { environments } from 'constants/environments';
import Input from 'components/v1/Input';
import Button from 'components/v1/Button';

import GoogleSSOLogin from './GoogleSSOLogin';
import * as LoginActions from './actions';
import * as S from './style';

const defaultValues = {
  email: '',
  password: '',
};

const LoginStep = ({ onForgotPasswordClick }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [captchaValue, setCapcthaValue] = useState(undefined);
  const recaptchaRef = useRef();

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm({
    mode: 'onBlur',
    defaultValues,
  });

  const isGetirEmail = watch('email')?.endsWith('@getir.com');

  const validationRules = {
    email: {
      required: t('requiredFieldText'),
      validate: value => {
        const isValidEmail = email.isValid(value);

        return isValidEmail || t('emailValid');
      },
    },
    password: {
      required: t('requiredFieldText'),
      validate: value => {
        if (value.length < MIN_NEW_PASSWORD_LENGTH) {
          const minPasswordLength = MIN_NEW_PASSWORD_LENGTH;

          return t('minPasswordValid', { minPasswordLength });
        }
        if (value.length > MAX_PASSWORD_LENGTH) {
          const maxPasswordLength = MAX_PASSWORD_LENGTH;

          return t('maxPasswordValid', { maxPasswordLength });
        }

        return true;
      },
    },
  };

  const onChangeReCAPTCHA = value => {
    setCapcthaValue(value);
  };

  const onSubmit = data => {
    const resultData = {
      ...data,
      captchaToken: captchaValue,
    };
    dispatch(LoginActions.loginRequest(resultData));
    recaptchaRef.current.reset();
    setCapcthaValue(undefined);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleLoginButtonDisabled = () =>
    window.location.origin === environments.production.server ||
    window.location.origin === environments.staging.server;

  return (
    <S.LoginForm onSubmit={handleSubmit(onSubmit)}>
      <S.FormHeader>
        <S.FormTitle>{t('auth:login.title')}</S.FormTitle>
      </S.FormHeader>
      <S.FormBody>
        <div>
          <S.Label htmlFor="email">{t('mail')}</S.Label>
          <Controller
            name="email"
            control={control}
            rules={validationRules.email}
            render={({ field }) => (
              <Input
                ref={field.ref}
                onChange={field.onChange}
                onBlur={field.onBlur}
                name={field.name}
                defaultValue={field.value}
                placeholder={t('mail')}
                header={t('mail')}
                error={errors.email?.message}
                size="large"
              />
            )}
          />
        </div>
        {!isGetirEmail && (
          <div>
            <S.Label htmlFor="password">{t('password')}</S.Label>
            <Controller
              name="password"
              control={control}
              rules={validationRules.password}
              render={({ field }) => (
                <Input
                  inputRef={field.ref}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  name={field.name}
                  defaultValue={field.value}
                  placeholder={t('password')}
                  header={t('password')}
                  size="large"
                  error={errors.password?.message}
                  type={showPassword ? 'text' : 'password'}
                  addonAfter={
                    <S.IconProvider
                      name={showPassword ? 'eye' : 'eye-off'}
                      size={15}
                      color="#a1a1a1"
                      format="small"
                      onClick={handleClickShowPassword}
                    />
                  }
                />
              )}
            />
          </div>
        )}
        {!isGetirEmail && (
          <S.RecaptchaWrapper>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={onChangeReCAPTCHA}
              ref={recaptchaRef}
            />
          </S.RecaptchaWrapper>
        )}
      </S.FormBody>
      <S.FormFooter>
        {!isGetirEmail && (
          <>
            <Button
              type="submit"
              disabled={handleLoginButtonDisabled() && !captchaValue}
            >
              {t('auth:login.btnLogin')}
            </Button>
            <S.ForgotPasswordText onClick={onForgotPasswordClick}>
              {t('auth:login.btnForgotPassword')}
            </S.ForgotPasswordText>
          </>
        )}
        <GoogleSSOLogin />
        {/* <GoogleLogin /> */}
      </S.FormFooter>
    </S.LoginForm>
  );
};

LoginStep.defaultProps = {
  onForgotPasswordClick: () => {},
};

LoginStep.propTypes = {
  onForgotPasswordClick: PropTypes.func,
};

export default LoginStep;
