import React from 'react';
import PropTypes from 'prop-types';

const XCircleIcon = ({ fill, width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 14"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 0.5C3.41015 0.5 0.5 3.41015 0.5 7C0.5 10.5899 3.41015 13.5 7 13.5C10.5899 13.5 13.5 10.5899 13.5 7C13.5 3.41015 10.5899 0.5 7 0.5ZM5.85355 5.14645C5.65829 4.95118 5.34171 4.95118 5.14645 5.14645C4.95118 5.34171 4.95118 5.65829 5.14645 5.85355L6.29289 7L5.14645 8.14645C4.95118 8.34171 4.95118 8.65829 5.14645 8.85355C5.34171 9.04882 5.65829 9.04882 5.85355 8.85355L7 7.70711L8.14645 8.85355C8.34171 9.04882 8.65829 9.04882 8.85355 8.85355C9.04882 8.65829 9.04882 8.34171 8.85355 8.14645L7.70711 7L8.85355 5.85355C9.04882 5.65829 9.04882 5.34171 8.85355 5.14645C8.65829 4.95118 8.34171 4.95118 8.14645 5.14645L7 6.29289L5.85355 5.14645Z"
      fill="inherit"
    />
  </svg>
);

XCircleIcon.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

XCircleIcon.defaultProps = {
  fill: '#D13333',
  width: 14,
  height: 14,
};

export default XCircleIcon;
