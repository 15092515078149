import styled from 'styled-components';

export const MenuContainer = styled.div`
  padding: 20px 20px 12px 20px;
  display: flex;
  align-items: center;
  gap: 48px;
  overflow-x: hidden;
  overflow-y: visible;
  padding-bottom: 262px;
  margin-bottom: -250px;
  svg {
    fill: #a1a1a1;
    .is-active & {
      fill: #191919;
    }
  }
  #div:hover {
    background-color: #b5b5b5;
    border-radius: 4px;
  }
`;

export const MenuSection = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const MenuButton = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;

  svg {
    ${props => (props.$isActive ? 'fill: #191919;' : 'fill: #a1a1a1;')}
  }
`;
