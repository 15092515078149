import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

const ToggleVisibilityButton = ({
  firstDate,
  secondDate,
  timeDuration,
  children,
}) => {
  const [diffSeconds, setDiffSeconds] = useState(0);

  const [firstDateState, setFirstDateState] = useState(
    firstDate || dayjs().toISOString(),
  );

  useEffect(() => {
    const calculatedTime = dayjs(firstDateState).diff(secondDate, 'seconds');

    setDiffSeconds(calculatedTime);
  }, [firstDateState, secondDate]);

  useEffect(() => {
    const interval = setInterval(() => {
      setFirstDateState(dayjs().toISOString());
    }, 1000);

    // eslint-disable-next-line
    return () => {
      clearInterval(interval);
    };
  }, []);

  if (Number(diffSeconds) >= timeDuration) return '';

  return children;
};

ToggleVisibilityButton.propTypes = {
  firstDate: PropTypes.string,
  secondDate: PropTypes.string,
  timeDuration: PropTypes.number,
  children: PropTypes.node,
};

ToggleVisibilityButton.defaultProps = {
  firstDate: '',
  secondDate: '',
  timeDuration: 120,
  children: <div></div>,
};

export default ToggleVisibilityButton;
