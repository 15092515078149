import { apiGateway } from 'constants/services';

export default {
  listVendorProducts: vendorId =>
    `${apiGateway}/vendor-service/products/get/${vendorId}`,
  openVendorProduct: `${apiGateway}/vendor-service/products/open`,
  editVendorProduct: `${apiGateway}/vendor-service/products/send-change-product-price-for-approval`,
  closeVendorProduct: `${apiGateway}/vendor-service/products/close`,
  getVendorProduct: ({ vendorId, productId }) =>
    `${apiGateway}/vendor-service/products/get/${vendorId}/${productId}`,
  getProductInfo: ({ productId }) =>
    `${apiGateway}/product-service/get-product-by-product-id/${productId}`,
  getCategories: `${apiGateway}/vendor-service/categories`,
};
