import styled from 'styled-components';
import {
  Input,
  Divider as StyledDivider,
  Button as StyledButton,
  Modal as ModalComponent,
  Image,
} from '@getir/web-components';
import { Drawer } from 'antd';

import COLORS from 'constants/colors';
import breakpoint from 'constants/breakpoint';

const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: ${COLORS.mainGetirColor};
  padding: 12px 24px;
  grid-area: header;

  .site-layout .site-layout-background {
    background: #fff;
  }
`;

const HeaderLeftSide = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  a {
    height: 32px;
  }
`;

const SiteLayoutArrow = styled.div`
  color: #fff;
  font-size: 20px;
  padding: 0;
  margin-right: 10px;
`;

const HeaderRightSide = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  gap: 12px;
`;

const HeaderUserCircle = styled.span`
  border-radius: 100%;
  display: flex;
  width: 36px;
  height: 36px;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #422c85;
  text-transform: uppercase;
`;

const WorkingHoursWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${COLORS.menuHoverColor};
  padding: 8px 12px;
  border-radius: 4px;
  height: 36px;
  gap: 12px;

  > span {
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
  }
  figure {
    margin: 0;
  }
`;

const WorkingHoursArea = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

const WorkingHoursTitle = styled.span`
  font-size: 10px;
`;

const WorkingHoursContent = styled.span`
  margin-left: 3px;
  font-size: 10px;
`;

const HeaderUserWrapper = styled.div`
  cursor: pointer;
`;

const ChangePasswordForm = styled.form`
  display: grid;
  grid-gap: 12px;

  ${StyledDivider.S.Divider} {
    border: 1px solid rgba(0, 0, 0, 0.06);
  }
`;

const FormBody = styled.div`
  display: grid;
  gap: 16px;

  ${Input.S.Input} {
    border: none;
  }
`;

const FormFooter = styled.div`
  display: grid;
  gap: 16px;

  ${StyledButton.S.Wrapper} {
    border-radius: 0;
  }
`;

// User Drawer

const StyledUserDrawer = styled(Drawer)`
  top: 70px !important;

  .ant-drawer-body {
    background: ${COLORS.mainGetirColor};
    color: #fff;
    padding: 0 !important;
  }

  a {
    color: inherit;
  }
`;

const UserDrawerTitle = styled.div`
  border-top: 1px solid ${COLORS.menuHoverColor};
  border-bottom: 1px solid ${COLORS.menuHoverColor};
  padding: 1rem;
  position: relative;

  img {
    width: 40px;
  }

  .online {
    height: 11px;
    width: 11px;
    background: #188977;
    position: absolute;
    top: 12px;
    border-radius: 50%;
    left: 48px;
  }
`;

const DrawerMenuContent = styled.div`
  padding: 1rem 0 8px 1rem;
  img {
    width: 14px;
  }
`;

const NoficationCount = styled.div`
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #ffd300;
  font-weight: 600;
  color: ${COLORS.mainGetirColor};
  float: left;
`;

const UserDrawerContentTitle = styled.span`
  margin-left: 10px;
`;

const DrawerIcon = styled.div`
  font-size: 20px;
`;

const StyledImage = styled(Image)`
  margin-bottom: 0;
  margin-right: 10px;
`;

const StyledModal = styled(ModalComponent)`
  ${ModalComponent.S.ModalContent} {
    overflow: auto;
  }

  ${ModalComponent.S.Modal} {
    @media (max-width: ${breakpoint.MOBILE.MAX}px) {
      width: 100vw;
    }
  }
`;

const PrefixIcon = styled.div`
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 8px;
`;

const UserPopoverMenu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
`;

const UserMenuItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: #00000020 !important;
  }
`;
const RoleListWrapper = styled.div`
  position: relative;
  width: 164px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;

  .ant-select .ant-select-selector {
    padding-left: 30px;
    height: 36px !important;
  }

  .ant-select {
    width: 100%;
  }
  .ant-select-selector {
    border-radius: 4px !important;
    background-color: #7849f7 !important;
    border: none !important;
    color: #fff !important;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 400 !important;
    fill: #fff !important;
    display: flex;
    padding: 8px 7px;
    align-items: center;
    gap: 8px;
  }
  .ant-select-selection-item {
    color: #fff !important;
  }
  .ant-select-item {
    padding: 4px 8px !important;
    line-height: normal !important;
  }
  .ant-select-item-option {
    height: 32px !important;
    display: flex;
    align-items: center;
    justify-items: center;
    &:hover {
      background-color: #00000030 !important;
    }
  }
  .ant-select-item-option-selected {
    background-color: #00000030 !important;
    color: #fff !important;
    border-radius: 0px !important;
  }
  .ant-select-item-option-content {
    font-size: 12px !important;
    font-style: normal;
    font-weight: 400 !important;
    color: #fff !important;
    height: 32px !important;

    &:hover {
      color: #fff !important;
    }
  }
  .ant-select-arrow {
    color: #fff !important;
  }
  .ant-select-dropdown {
    background-color: #7849f7 !important;
    border: none !important;
  }
`;

export {
  Header,
  HeaderRightSide,
  SiteLayoutArrow,
  HeaderLeftSide,
  WorkingHoursWrapper,
  WorkingHoursArea,
  WorkingHoursTitle,
  WorkingHoursContent,
  HeaderUserWrapper,
  FormBody,
  FormFooter,
  ChangePasswordForm,
  StyledUserDrawer,
  UserDrawerContentTitle,
  UserDrawerTitle,
  DrawerMenuContent,
  NoficationCount,
  DrawerIcon,
  StyledImage,
  StyledModal,
  RoleListWrapper,
  HeaderUserCircle,
  PrefixIcon,
  UserPopoverMenu,
  UserMenuItem,
};
