import styled from 'styled-components';

import COLORS from 'constants/colors';

export const CompletedOrdersTableHeader = styled.div`
  font-size: 18px;
  color: rgba(0, 0, 0, 0.85);

  span {
    color: ${COLORS.mainGetirColor};
    margin-left: 8px;
    font-weight: 600;
  }
`;

export const StyledCell = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  width: 100%;

  button {
    padding: 8px;
    width: 36px;
    height: 36px;
  }
`;
