import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/v1/Modal';
import Button from 'components/v1/Button';
import { InfoIcon } from 'components/v1/Icons';
import { getItem, setItem } from 'utils/localStorage';

import * as S from './style';

const BillingInformationModal = () => {
  const [isModalVisible, setIsModalVisible] = useState(
    !getItem('isBillingInformationAccepted'),
  );
  const { t } = useTranslation();

  const handleOkClick = () => {
    setItem('isBillingInformationAccepted', true);
    setIsModalVisible(false);
  };

  return (
    <Modal
      headerOptions={{
        title: t('billingInformationModal:title'),
        closeButton: false,
      }}
      footerOptions={{
        customFooter: (
          <S.ModalFooter>
            <S.FooterInfoContainer>
              <InfoIcon width={14} height={14} fill="#5D3EBC" />
              <S.FooterInfoText>
                {t('billingInformationModal:infoText')}
              </S.FooterInfoText>
            </S.FooterInfoContainer>
            <Button onClick={handleOkClick} kind="primary">
              {t('ok')}
            </Button>
          </S.ModalFooter>
        ),
      }}
      isOpen={isModalVisible}
      maskClosable={false}
    >
      <S.ModalContent>
        <S.BoldText>
          <p>Değerli GetirSu İş Ortağımız,</p> T.C. Hazine ve Maliye Bakanlığı
          Vergi Denetim Kurulu Başkanlığı tarafından platformumuz aracılığı ile
          gerçekleştirdiğiniz işlemlerde fiş/fatura düzenleme yükümlülüğüne
          uymanız ve GetirSu paneli üzerinde geçmiş siparişler ekranında sizlere
          sağladığımız alana düzenlediğiniz fiş/faturayı yüklemeniz gerektiği
          konusunda uyarılmış bulunmaktasınız. Bu konuda hassasiyetinizi rica
          ederiz.
        </S.BoldText>
        <S.BoldText>
          Sayın Mükellef, bu uyarı T.C. Hazine ve Maliye Bakanlığı Vergi Denetim
          Kurulu Başkanlığı adına yapılmaktadır;
        </S.BoldText>
        <p>
          Bilindiği üzere, 24/12/2015 tarihli ve 29572 sayılı Resmi Gazetede
          yayımlanan 464 sıra numaralı Vergi Usul Kanunu Genel Tebliği gereği,
          aracı hizmet sağlayıcı e-ticaret platformları aracılığını yaptıkları
          satışlara ilişkin bilgileri her ay T.C. Hazine ve Maliye Bakanlığı’na
          bildirmekle yükümlüdürler.
        </p>
        <p>
          Bu kapsamda, şu ana kadar aktarılan bilgilerden hareketle Vergi
          Denetim Kurulu Başkanlığı’nca analizler yapılarak 6 binin üzerinde
          mükellef vergi incelemesine sevk edilmiş ve 3 milyar TL’ye yakın
          cezalı vergi tarhiyatı önerilmiştir. Verilerin analizi çalışmalarına
          sürekli gözetim kapsamında devam edilmektedir.
        </p>
        <p>
          Bununla birlikte, Elektronik Ticaretin Düzenlenmesi Hakkında Kanun’un
          Ek-2’nci maddesinin 1’inci fıkrasının (b) bendi gereği, Elektronik
          Ticaret Aracı Hizmet Sağlayıcı, Elektronik ticaret hizmet sağlayıcının
          213 sayılı Vergi Usul Kanunu kapsamında düzenlemesi gereken
          belgelerdeki bilgilere, satışın yapıldığı elektronik ticaret pazar
          yerinde yer verebilmesine imkân sağlar. Hükmü yer almaktadır.
        </p>
        <p>
          Yukarıda yapılan açıklamalar çerçevesinde cezalı vergi tarhiyatı ile
          muhatap olmamanız açısından, e-ticaret platformu aracılığı ile
          gerçekleştirdiğiniz işlemlerde fiş/fatura düzenleme yükümlülüğüne
          uymanız ve platformda açılan alana düzenlediğiniz fiş/faturayı
          yüklemeniz önem arz etmektedir.
        </p>
      </S.ModalContent>
    </Modal>
  );
};

export default BillingInformationModal;
