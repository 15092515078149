import React from 'react';
import PropTypes from 'prop-types';

const ElipsisIcon = ({ fill, width, height }) => (
  <svg
    width={width}
    height={height}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.75016 10C3.75016 9.30968 4.30981 8.75004 5.00016 8.75004C5.69052 8.75004 6.25016 9.30968 6.25016 10C6.25016 10.6904 5.69052 11.25 5.00016 11.25C4.30981 11.25 3.75016 10.6904 3.75016 10ZM8.75016 10C8.75016 9.30968 9.30981 8.75004 10.0002 8.75004C10.6905 8.75004 11.2502 9.30968 11.2502 10C11.2502 10.6904 10.6905 11.25 10.0002 11.25C9.30981 11.25 8.75016 10.6904 8.75016 10ZM13.7502 10C13.7502 9.30968 14.3098 8.75004 15.0002 8.75004C15.6905 8.75004 16.2502 9.30968 16.2502 10C16.2502 10.6904 15.6905 11.25 15.0002 11.25C14.3098 11.25 13.7502 10.6904 13.7502 10Z"
      fill="inherit"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.00016 10.2084C5.11522 10.2084 5.2085 10.1151 5.2085 10C5.2085 9.88498 5.11522 9.79171 5.00016 9.79171C4.8851 9.79171 4.79183 9.88498 4.79183 10C4.79183 10.1151 4.8851 10.2084 5.00016 10.2084ZM3.54183 10C3.54183 9.19463 4.19475 8.54171 5.00016 8.54171C5.80558 8.54171 6.4585 9.19463 6.4585 10C6.4585 10.8055 5.80558 11.4584 5.00016 11.4584C4.19475 11.4584 3.54183 10.8055 3.54183 10ZM10.0002 10.2084C10.1152 10.2084 10.2085 10.1151 10.2085 10C10.2085 9.88498 10.1152 9.79171 10.0002 9.79171C9.8851 9.79171 9.79183 9.88498 9.79183 10C9.79183 10.1151 9.8851 10.2084 10.0002 10.2084ZM8.54183 10C8.54183 9.19463 9.19475 8.54171 10.0002 8.54171C10.8056 8.54171 11.4585 9.19463 11.4585 10C11.4585 10.8055 10.8056 11.4584 10.0002 11.4584C9.19475 11.4584 8.54183 10.8055 8.54183 10ZM15.0002 10.2084C15.1152 10.2084 15.2085 10.1151 15.2085 10C15.2085 9.88498 15.1152 9.79171 15.0002 9.79171C14.8851 9.79171 14.7918 9.88498 14.7918 10C14.7918 10.1151 14.8851 10.2084 15.0002 10.2084ZM13.5418 10C13.5418 9.19463 14.1947 8.54171 15.0002 8.54171C15.8056 8.54171 16.4585 9.19463 16.4585 10C16.4585 10.8055 15.8056 11.4584 15.0002 11.4584C14.1947 11.4584 13.5418 10.8055 13.5418 10Z"
      fill="inherit"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.3335 10C3.3335 9.07957 4.07969 8.33337 5.00016 8.33337C5.92064 8.33337 6.66683 9.07957 6.66683 10C6.66683 10.9205 5.92064 11.6667 5.00016 11.6667C4.07969 11.6667 3.3335 10.9205 3.3335 10ZM5.00016 8.75004C4.30981 8.75004 3.75016 9.30968 3.75016 10C3.75016 10.6904 4.30981 11.25 5.00016 11.25C5.69052 11.25 6.25016 10.6904 6.25016 10C6.25016 9.30968 5.69052 8.75004 5.00016 8.75004ZM8.3335 10C8.3335 9.07957 9.07969 8.33337 10.0002 8.33337C10.9206 8.33337 11.6668 9.07957 11.6668 10C11.6668 10.9205 10.9206 11.6667 10.0002 11.6667C9.07969 11.6667 8.3335 10.9205 8.3335 10ZM10.0002 8.75004C9.30981 8.75004 8.75016 9.30968 8.75016 10C8.75016 10.6904 9.30981 11.25 10.0002 11.25C10.6905 11.25 11.2502 10.6904 11.2502 10C11.2502 9.30968 10.6905 8.75004 10.0002 8.75004ZM13.3335 10C13.3335 9.07957 14.0797 8.33337 15.0002 8.33337C15.9206 8.33337 16.6668 9.07957 16.6668 10C16.6668 10.9205 15.9206 11.6667 15.0002 11.6667C14.0797 11.6667 13.3335 10.9205 13.3335 10ZM15.0002 8.75004C14.3098 8.75004 13.7502 9.30968 13.7502 10C13.7502 10.6904 14.3098 11.25 15.0002 11.25C15.6905 11.25 16.2502 10.6904 16.2502 10C16.2502 9.30968 15.6905 8.75004 15.0002 8.75004ZM4.5835 10C4.5835 9.76992 4.77004 9.58337 5.00016 9.58337C5.23028 9.58337 5.41683 9.76992 5.41683 10C5.41683 10.2302 5.23028 10.4167 5.00016 10.4167C4.77004 10.4167 4.5835 10.2302 4.5835 10ZM9.5835 10C9.5835 9.76992 9.77004 9.58337 10.0002 9.58337C10.2303 9.58337 10.4168 9.76992 10.4168 10C10.4168 10.2302 10.2303 10.4167 10.0002 10.4167C9.77004 10.4167 9.5835 10.2302 9.5835 10ZM14.5835 10C14.5835 9.76992 14.77 9.58337 15.0002 9.58337C15.2303 9.58337 15.4168 9.76992 15.4168 10C15.4168 10.2302 15.2303 10.4167 15.0002 10.4167C14.77 10.4167 14.5835 10.2302 14.5835 10Z"
      fill="inherit"
    />
  </svg>
);

ElipsisIcon.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

ElipsisIcon.defaultProps = {
  fill: '#5D3EBC',
  width: 14,
  height: 14,
};

export default ElipsisIcon;
