import React from 'react';
import { Space } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import Button from 'components/v1/Button';

const useCatalogProductColumns = (
  pushToProductEdit,
  setIsDeleteCatalogModalVisible,
) => {
  const { t } = useTranslation();

  return [
    {
      title: t('catalogs:tableColumns.categoryName'),
      dataIndex: 'categoryName',
      width: '20%',
    },
    {
      title: t('catalogs:tableColumns.brandName'),
      dataIndex: 'brandName',
      width: '20%',
    },
    {
      title: t('catalogs:tableColumns.productName'),
      dataIndex: 'productName',
      width: '20%',
    },
    {
      title: t('catalogs:tableColumns.productImageUrl'),
      dataIndex: 'productImageUrl',
      width: '20%',
      render: text => {
        if (text) return <img src={text} alt="productImage" width={60} />;

        return '';
      },
    },
    {
      title: t('actions'),
      key: 'settings',
      render: (text, row) => (
        <Space size="middle">
          <Button
            kind="secondary"
            onClick={() => pushToProductEdit({ id: row.id })}
          >
            {t('table.edit')}
          </Button>
          <Button
            kind="secondary"
            style={{
              backgroundColor: 'transparent',
              padding: 0,
              dispaly: 'flex',
            }}
            onClick={() =>
              setIsDeleteCatalogModalVisible({
                visible: true,
                id: row.id,
              })
            }
          >
            <DeleteOutlined />
          </Button>
        </Space>
      ),
      align: 'center',
      width: 150,
      fixed: 'right',
    },
  ];
};

export { useCatalogProductColumns };
