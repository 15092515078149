import styled from 'styled-components';
import { InputNumber } from 'antd';

const InputField = styled(InputNumber)`
  border: none;
  width: 100%;
`;

const Container = styled.div`
  .ant-input-number {
    width: 100% !important;
    position: relative;
    color: #191919;
    line-height: 24px !important;
    letter-spacing: 0.15px;
    caret-color: #7849f7;
    height: 100%;
    padding: 0;
    border-radius: 4px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid #dfdee2;
    border-radius: 4px;
  }
  .ant-input-number-handler-wrap {
    display: none;
    visibility: hidden;
  }
  .ant-input-number:focus-within {
    border-color: #5d3ebc;
    outline: none;
    border-right-width: 2px !important;
  }
  .ant-input-number:hover {
    border-color: #7849f7;
    border-right-width: 2px !important;
  }
  .ant-input-number-input-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
  }
  .ant-input-number-input {
    padding: 0px 14px;
    outline: none;
    width: 100%;
    height: 100%;
  }
  .ant-input-number-input::placeholder {
    color: rgb(117, 117, 117);
  }
  width: 100%;
`;

const Header = styled.span`
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 3px;
`;

export { Header, InputField, Container };
