import * as Types from './types';

const initialState = {
  orderId: undefined,
  sendSMSCourier: undefined,
  error: {},
  showModal: false,
  orderDetail: {
    data: undefined,
    error: undefined,
  },
  courierList: {
    data: undefined,
    error: undefined,
  },
};

function ListCouriersReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.SEND_SMS_COURIER_SUCCESS:
      return {
        ...state,
        sendSMSCourier: payload,
        showModal: false,
      };
    case Types.SEND_SMS_COURIER_ERROR:
      return {
        ...state,
        error: payload,
        showModal: false,
      };
    case Types.SEND_SMS_COURIER_RESET:
      return {
        ...state,
        sendSMSCourier: undefined,
        showModal: false,
      };
    case Types.SEND_SMS_MODAL_OPEN:
      return {
        ...state,
        orderId: payload,
        showModal: true,
      };
    case Types.SEND_SMS_MODAL_CLOSE:
      return {
        ...state,
        orderId: undefined,
        showModal: false,
      };
    case Types.ORDER_DETAIL_SUCCESS:
      return {
        ...state,
        orderDetail: {
          data: payload,
        },
      };
    case Types.ORDER_DETAIL_ERROR:
      return {
        ...state,
        orderDetail: {
          error: payload,
        },
      };
    case Types.ORDER_DETAIL_RESET:
      return {
        ...state,
        orderDetail: {
          data: undefined,
          error: undefined,
        },
      };
    case Types.GET_COURIER_LIST_SUCCESS:
      return {
        ...state,
        courierList: {
          data: payload.payload,
        },
      };
    case Types.GET_COURIER_LIST_ERROR:
      return {
        ...state,
        courierList: {
          error: payload.payload,
        },
      };
    case Types.GET_COURIER_LIST_RESET:
      return {
        ...state,
        courierList: {
          data: undefined,
          error: undefined,
        },
      };
    default:
      return state;
  }
}

export default ListCouriersReducer;
