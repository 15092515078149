import styled from 'styled-components';
import { Input, Divider as StyledDivider, Button } from '@getir/web-components';

export const FormBody = styled.div`
  display: grid;
  gap: 16px;

  ${Input.S.Input} {
    border: none;
  }
`;

export const FormFooter = styled.div`
  display: grid;
  gap: 16px;
  justify-content: flex-end;

  ${Button.S.Wrapper} {
    border-radius: 0;
  }
`;

export const NewRoleForm = styled.form`
  display: grid;
  grid-gap: 12px;

  ${StyledDivider.S.Divider} {
    border: 1px solid rgba(0, 0, 0, 0.06);
  }

  .ant-select-selection-item {
    padding-top: 0 !important;
  }

  .ant-select-selector {
    height: auto !important;
  }

  .ant-select {
    height: auto !important;
  }
`;
