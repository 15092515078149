import React from 'react';
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined } from '@ant-design/icons';

import Button from 'components/v1/Button';

const useColumns = (pushToEdit, setModalState) => {
  const { t } = useTranslation();
  const columns = [
    {
      title: 'Kategori Adı',
      dataIndex: 'name',
    },
    {
      title: t('actions'),
      key: 'settings',
      render: (text, row) => (
        <Space size="middle">
          <Button kind="secondary" onClick={() => pushToEdit({ id: row.id })}>
            {t('common:edit')}
          </Button>
          <Button
            kind="secondary"
            style={{
              backgroundColor: 'transparent',
              padding: 0,
              dispaly: 'flex',
            }}
            onClick={() => setModalState({ visible: true, id: row.id })}
          >
            <DeleteOutlined />
          </Button>
        </Space>
      ),
      align: 'center',
      width: '200px',
    },
  ];

  return columns;
};

export { useColumns };
