import React, { useMemo, useState } from 'react';
import { Empty } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SearchOutlined } from '@ant-design/icons';

import { pushToScreenBy } from 'utils/pushToScreenBy';
import * as ROUTES from 'constants/routes';
import ConfirmModal from 'components/ConfirmModal';
import Table from 'components/v1/Table';
import useDebounce from 'hooks/useDebounce';
import Input from 'components/v1/Input';

import * as BrandActions from '../actions';

import { useBrandsColumns } from './useBrandsColumns';
import * as S from './style';

const List = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const brands = useSelector(state => state.brand.brands);

  const pushToBrandEdit = pushToScreenBy(history, ROUTES.BRANDEDIT);
  const pushToBrandProductOrder = pushToScreenBy(history, ROUTES.BRANDPRODUCT);
  const [deleteState, setDeleteState] = useState({
    currentId: 0,
    modalVisible: false,
  });
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 200);
  const closeModal = () =>
    setDeleteState(prev => ({ ...prev, modalVisible: false }));

  const brandsFromFirms = useMemo(
    () =>
      brands?.map(item => ({
        key: item.id,
        brandname: item.brandName,
        firm: item.firm.name,
      })),
    [brands],
  );
  const filteredBrands = useMemo(
    () =>
      brandsFromFirms?.filter(brand =>
        brand.brandname
          .toLocaleLowerCase('tr')
          .includes(debouncedSearchTerm.toLocaleLowerCase('tr')),
      ),
    [brandsFromFirms, debouncedSearchTerm],
  );

  const deleteBrand = () => {
    dispatch(BrandActions.deleteBrandRequest(deleteState.currentId));
    closeModal();
  };

  const columns = useBrandsColumns(
    pushToBrandEdit,
    pushToBrandProductOrder,
    id => setDeleteState({ modalVisible: true, currentId: id }),
  );

  return (
    <S.Wrapper>
      <Input
        placeholder={t('brands:searchBrand')}
        value={searchTerm}
        onChange={evt => setSearchTerm(evt.target.value)}
        size="large"
        style={{ width: '300px' }}
        prefix={<SearchOutlined width={20} height={20} />}
        allowClear
      />
      <Table
        data={filteredBrands}
        columns={columns}
        pagination={{ size: 'small' }}
        scroll={{ x: 'max-content' }}
        locale={{ emptyText: <Empty description={t('table.noData')} /> }}
        rowKey="key"
      />
      <ConfirmModal
        size="400px"
        visible={deleteState.modalVisible}
        onSave={deleteBrand}
        onClose={() => closeModal()}
        title={t('brands:deleteBrandModalTitle')}
        content={t('brands:deleteBrandModalText')}
        cancelText={t('cancel')}
      />
    </S.Wrapper>
  );
};

export default List;
