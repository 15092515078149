import styled from 'styled-components';
import { Table, TimePicker } from 'antd';

const AlternativeMapHoursTable = styled(Table)`
  width: 100%;
  tr {
    width: 100%;
  }
  .ant-table-cell {
    background: none;

    &::before {
      display: none;
    }
  }
`;

const InputRow = styled.div`
  width: 320px;
`;

const CustomTimePicker = styled(TimePicker)`
  &:hover {
    border-color: #d9d9d9;
  }
`;

export { AlternativeMapHoursTable, InputRow, CustomTimePicker };
