import { ORDER_STATUS } from 'constants/orderStatus';
import { PAYMENT_TYPE_TRANSLATEKEY, PAYMENT_TYPE } from 'constants/paymentType';

const standardStatusList = [
  ORDER_STATUS.DELIVERED,
  ORDER_STATUS.RATED,
  ORDER_STATUS.CANCELED_ADMIN,
  ORDER_STATUS.CANCELED_VENDOR,
];

const standardPaymentList = [
  PAYMENT_TYPE.ONLINE_CREDIT_CARD,
  PAYMENT_TYPE.ONLINE_BKM,
  PAYMENT_TYPE.CREDIT_OR_DEBIT_CARD_AT_DELIVERY,
  PAYMENT_TYPE.CASH_AT_DELIVERY,
];

const orderStateSelectItems = [
  {
    labelKey: 'common:delivered',
    values: [ORDER_STATUS.DELIVERED, ORDER_STATUS.RATED],
  },
  {
    labelKey: 'common:canceledbyadmin',
    values: [ORDER_STATUS.CANCELED_ADMIN],
  },
  {
    labelKey: 'common:canceledbyvendor',
    values: [ORDER_STATUS.CANCELED_VENDOR],
  },
];

const orderPaymentSelectItems = [
  {
    labelKey: PAYMENT_TYPE_TRANSLATEKEY.ONLINE_PAYMENT,
    values: [PAYMENT_TYPE.ONLINE_CREDIT_CARD, PAYMENT_TYPE.ONLINE_BKM],
  },
  {
    labelKey: PAYMENT_TYPE_TRANSLATEKEY.CREDIT_OR_DEBIT_CARD_AT_DELIVERY,
    values: [PAYMENT_TYPE.CREDIT_OR_DEBIT_CARD_AT_DELIVERY],
  },
  {
    labelKey: PAYMENT_TYPE_TRANSLATEKEY.CASH_AT_DELIVERY,
    values: [PAYMENT_TYPE.CASH_AT_DELIVERY],
  },
];

const invoiceStatus = Object.freeze({
  PENDING: 1,
  APPROVED: 2,
  CANCELED: 3,
  DELETED: 4,
});

const invoiceStatusList = Object.freeze([
  {
    labelKey: 'common:invoice.pending',
    value: invoiceStatus.PENDING,
  },
  {
    labelKey: 'common:invoice.approved',
    value: invoiceStatus.APPROVED,
  },
  {
    labelKey: 'common:invoice.canceled',
    value: invoiceStatus.CANCELED,
  },
  {
    labelKey: 'common:invoice.deleted',
    value: invoiceStatus.DELETED,
  },
]);

export {
  standardStatusList,
  orderStateSelectItems,
  orderPaymentSelectItems,
  standardPaymentList,
  invoiceStatusList,
  invoiceStatus,
};
