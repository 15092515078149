import * as Types from './types';

const initialState = {
  reportList: [],
  reportTypes: [],
  filters: {
    selectedReportType: null,
    selectedDateRange: [null, null],
    paginationOptions: {
      current: 1,
      defaultCurrent: 1,
      pageSize: 10,
      defaultPageSize: 10,
      total: 0,
    },
  },
  error: {},
  typesLoading: false,
  reportsLoading: false,
  createReportLoading: false,
};

function ReportsReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case Types.CHANGE_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...payload,
        },
      };
    case Types.GET_REPORTS_REQUEST:
      return {
        ...state,
        reportsLoading: true,
      };
    case Types.GET_REPORTS_SUCCESS:
      return {
        ...state,
        reportList: payload.data,
        filters: {
          ...state.filters,
          paginationOptions: {
            ...state.filters.paginationOptions,
            total: payload.totalCount,
          },
        },
        reportsLoading: false,
      };
    case Types.GET_REPORTS_ERROR:
      return {
        ...state,
        error: payload,
        reportsLoading: false,
      };
    case Types.CREATE_REPORT_REQUEST:
      return {
        ...state,
        createReportLoading: true,
      };
    case Types.CREATE_REPORT_SUCCESS:
      return {
        ...state,
        createReportLoading: false,
      };
    case Types.CREATE_REPORT_ERROR:
      return {
        ...state,
        error: payload,
        createReportLoading: false,
      };
    default:
      return state;
  }
}

export default ReportsReducer;
