import { createAction } from 'utils/createAction';

import * as Types from './types';

// create user
export const createUserRequest = createAction(Types.CREATE_USER_REQUEST);
export const createUserSuccess = createAction(Types.CREATE_USER_SUCCESS);
export const createUserError = createAction(Types.CREATE_USER_ERROR);

// update user
export const updateUserRequest = createAction(Types.UPDATE_USER_REQUEST);
export const updateUserSuccess = createAction(Types.UPDATE_USER_SUCCESS);
export const updateUserError = createAction(Types.UPDATE_USER_ERROR);

// delete user
export const deleteUserRequest = createAction(Types.DELETE_USER_REQUEST);
export const deleteUserSuccess = createAction(Types.DELETE_USER_SUCCESS);
export const deleteUserError = createAction(Types.DELETE_USER_ERROR);

// get users bu firmid
export const listUsersByFirmRequest = createAction(
  Types.GET_USERS_BY_FIRM_REQUEST,
);
export const listUsersByFirmSuccess = createAction(
  Types.GET_USERS_BY_FIRM_SUCCESS,
);
export const listUsersByFirmError = createAction(Types.GET_USERS_BY_FIRM_ERROR);

// get users bu vendorid
export const listUsersByVendorRequest = createAction(
  Types.GET_USERS_BY_VENDOR_REQUEST,
);
export const listUsersByVendorSuccess = createAction(
  Types.GET_USERS_BY_VENDOR_SUCCESS,
);
export const listUsersByVendorError = createAction(
  Types.GET_USERS_BY_VENDOR_ERROR,
);

// get roles by role gorup
export const getRolesByRoleGroupRequest = createAction(
  Types.GET_ROLES_BY_ROLE_GROUP_REQUEST,
);
export const getRolesByRoleGroupSuccess = createAction(
  Types.GET_ROLES_BY_ROLE_GROUP_SUCCESS,
);
export const getRolesByRoleGroupError = createAction(
  Types.GET_ROLES_BY_ROLE_GROUP_ERROR,
);

// set user list

export const setUserList = createAction(Types.SET_USER_LIST);
