import React from 'react';

export default function AlignLeft() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="inherit"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 18.6177H9V16.6177H3V18.6177ZM3 11.6177V13.6177H15V11.6177H3ZM3 6.61774V8.61774H21V6.61774H3Z"
        fill="inherit"
      />
    </svg>
  );
}
