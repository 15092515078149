import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/v1/Button';
import Layout from 'components/Layout';
import * as CS from 'common/style';

import AddNewUserModal from './components/AddNewUserModal';
import EditUserModal from './components/EditUserModal';
import List from './List';

const Users = () => {
  const { t } = useTranslation();
  const addNewUserModalRef = useRef();
  const editUserModalRef = useRef();

  const [isAddNewUserModalVisible, setIsAddNewUserModalVisible] = useState(
    false,
  );
  const [isEditUserModalVisible, setIsEditUserModalVisible] = useState(false);
  const [currentUser, setCurrentUser] = useState(undefined);

  const getCurrentUser = user => {
    setCurrentUser(user);
  };

  const openEditUserModal = value => {
    setIsEditUserModalVisible(value);
  };

  const toggleAddNewUserVisibility = value => {
    setIsAddNewUserModalVisible(value);
  };

  const handleAddNewUserModalClose = () => {
    setIsAddNewUserModalVisible(false);
  };

  const handleEditUserModalClose = () => {
    setIsEditUserModalVisible(false);
  };

  return (
    <Layout>
      <CS.PageHeader>
        <CS.PageTitle>{t('authUsersPage:pageTitle')}</CS.PageTitle>
        <Button type="primary" onClick={() => toggleAddNewUserVisibility(true)}>
          {t('authUsersPage:addNewUser')}
        </Button>
      </CS.PageHeader>
      <CS.PageBody>
        <List getEditUserClick={openEditUserModal} getUser={getCurrentUser} />
      </CS.PageBody>
      <AddNewUserModal
        modalRef={addNewUserModalRef}
        size="800px"
        visible={isAddNewUserModalVisible}
        onClose={handleAddNewUserModalClose}
      />
      <EditUserModal
        modalRef={editUserModalRef}
        size="800px"
        visible={isEditUserModalVisible}
        onClose={handleEditUserModalClose}
        user={currentUser}
      />
    </Layout>
  );
};

export default Users;
