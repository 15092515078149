const VENDOR_SALE_STATUS = Object.freeze({
  CLOSE: 'CLOSED_FOR_SALE',
  OPEN: 'OPEN_FOR_SALE',
  WAIT: 'WAITING_FOR_FINANCIAL_INFO_AND_WORKING_HOURS',
});

const VENDOR_SALE_STATUS_TRANSLATIONKEY = Object.freeze({
  [VENDOR_SALE_STATUS.CLOSE]: 'vendor:vendorStatus.closedToSale',
  [VENDOR_SALE_STATUS.OPEN]: 'vendor:vendorStatus.openToSale',
  [VENDOR_SALE_STATUS.WAIT]: 'vendor:vendorStatus.waitForFinancialInfo',
});

export { VENDOR_SALE_STATUS, VENDOR_SALE_STATUS_TRANSLATIONKEY };
