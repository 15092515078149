import styled from 'styled-components';
import { DatePicker } from 'antd';

const DATEPICKER_TYPES = {
  large: {
    height: '56px',
  },
  medium: {
    height: '48px',
  },
  small: {
    height: '36px',
  },
};

export const StyledDatePicker = styled(DatePicker)`
  .ant-picker-input {
    flex-direction: row-reverse !important;
    gap: 8px !important;
    span {
      margin: 0;
    }
  }

  ${({ $size }) =>
    DATEPICKER_TYPES[$size] && `height: ${DATEPICKER_TYPES[$size].height}`}
`;
