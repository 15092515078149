import translate from 'utils/translate';
import { open } from 'utils/notification';

const copyToClipboard = (text, successMessage, warningMessage) => {
  if (text) {
    const textField = document.createElement('textarea');
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();

    open(
      'success',
      translate('common:notif.successTitle'),
      translate(successMessage),
    );
  } else {
    open(
      'warning',
      translate('common:notif.warnTitle'),
      translate(warningMessage),
    );
  }
};

export default copyToClipboard;
