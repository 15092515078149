import React from 'react';
import { Space } from 'antd';
import { Input } from '@getir/web-components';
import i18next from 'i18next';

import allowOnlyNumber from 'utils/allowOnlyNumber';
import * as CS from 'common/style';

import { requestStatuses } from '../constants/requestStatuses';

const useColumns = (
  setPriceData,
  currentRequestStatus,
  handleCancelButtonClick,
  handleApproveButtonClick,
  { brands },
) => {
  const columns = [
    {
      title: i18next.t('requests:brandName'),
      dataIndex: `brandId`,
      width: '20%',
      render: brandId => {
        const currentBrand = brands?.find(brand => brand.id === brandId);

        return currentBrand?.brandName;
      },
    },
    {
      title: i18next.t('requests:categoryName'),
      dataIndex: 'categoryName',
      width: '20%',
    },
    {
      title: i18next.t('requests:productName'),
      dataIndex: 'productName',
      width: '20%',
    },
    // {
    //   title: i18next.t('requests:oldPrice'),
    //   key: 'oldPrice',
    //   render: text => {
    //     if (text.oldPrice !== null) {
    //       return (
    //         <Input
    //           size="small"
    //           placeholder={i18next.t('requests:oldPrice')}
    //           addonBefore="₺"
    //           disabled
    //           value={text.oldPrice}
    //         />
    //       );
    //     }

    //     return '';
    //   },
    //   width: '20%',
    // },
    {
      title: i18next.t('requests:newPrice'),
      key: 'newPrice',
      render: (text, row, index) => {
        if (text.newPrice !== null) {
          return (
            <Input
              size="small"
              placeholder={i18next.t('requests:newPrice')}
              addonBefore="₺"
              onKeyPress={allowOnlyNumber}
              onChange={e => setPriceData(index, 'newPrice', e.target.value)}
              value={text?.newPrice === 0 ? '' : text.newPrice}
              disabled={
                currentRequestStatus === requestStatuses.APPROVED ||
                currentRequestStatus === requestStatuses.DECLINED
              }
            />
          );
        }

        if (currentRequestStatus === requestStatuses.WAITING) {
          return {
            children: (
              <Space size="small">
                <CS.FullWidthButton
                  kind="secondary"
                  onClick={handleCancelButtonClick}
                >
                  {i18next.t('requests:workingHours.cancelButton')}
                </CS.FullWidthButton>
                <CS.FullWidthButton onClick={handleApproveButtonClick}>
                  {i18next.t('requests:workingHours.successButton')}
                </CS.FullWidthButton>
              </Space>
            ),
            props: {
              colSpan: 2,
            },
          };
        }

        return '';
      },
      width: '20%',
    },
  ];

  return columns;
};

export { useColumns };
