import * as Types from './types';

const initialState = {
  products: undefined,
  error: {},
  addProduct: undefined,
  editProduct: undefined,
  deleteProduct: undefined,
  catalogBrands: [],
};

function CatalogProductReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.CATALOGPRODUCT_LIST_SUCCESS:
      return {
        ...state,
        products: payload,
      };
    case Types.CATALOGPRODUCT_LIST_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.ADD_CATALOGPRODUCT_SUCCESS:
      return {
        ...state,
      };
    case Types.ADD_CATALOGPRODUCT_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.EDIT_CATALOGPRODUCT_SUCCESS:
      return {
        ...state,
        editProduct: payload,
      };
    case Types.EDIT_CATALOGPRODUCT_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.DELETE_CATALOGPRODUCT_SUCCESS:
      return {
        ...state,
        deleteProduct: payload,
      };
    case Types.DELETE_CATALOGPRODUCT_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.CATALOGPRODUCT_BRAND_SUCCESS:
      return {
        ...state,
        catalogBrands: payload,
      };
    case Types.CATALOGPRODUCT_BRAND_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.CATALOGPRODUCT_BRAND_RESET:
      return {
        ...state,
        catalogBrands: [],
      };
    default:
      return state;
  }
}

export default CatalogProductReducer;
