import React from 'react';
import PropTypes from 'prop-types';
import { Button, utilities } from '@getir/web-components';

import * as S from './style';

const { noop } = utilities;

const LoginResult = ({ text, onOkClick, status }) => (
  <S.LoginForm>
    <S.FormHeader>
      <S.IconProvider
        name={status === 200 ? 'check' : 'alert-circle'}
        size={40}
        color={status === 200 ? '#6CC375' : '#faad14'}
        format="small"
      />
    </S.FormHeader>
    <S.FormBody>
      <S.LoginResultText>{text}</S.LoginResultText>
    </S.FormBody>
    <S.FormFooter>
      <Button onClick={onOkClick}>Tamam</Button>
    </S.FormFooter>
  </S.LoginForm>
);

LoginResult.defaultProps = {
  text: '',
  onOkClick: noop,
  status: 200,
};

LoginResult.propTypes = {
  text: PropTypes.string,
  onOkClick: PropTypes.func,
  status: PropTypes.number,
};

export default LoginResult;
