import * as Types from './types';

const initialState = {
  referenceInfo: {
    data: undefined,
    error: undefined,
  },
  bankCodes: {
    data: undefined,
    error: undefined,
  },
};

function FinancialInformationReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_REFERENCE_VENDOR_SUCCESS:
      return {
        ...state,
        referenceInfo: {
          data: payload,
        },
      };
    case Types.GET_REFERENCE_VENDOR_ERROR:
      return {
        ...state,
        referenceInfo: {
          error: payload,
        },
      };
    case Types.GET_REFERENCE_FIRM_SUCCESS:
      return {
        ...state,
        referenceInfo: {
          data: payload,
        },
      };
    case Types.GET_REFERENCE_FIRM_ERROR:
      return {
        ...state,
        referenceInfo: {
          error: payload,
        },
      };
    case Types.GET_BANK_CODES_SUCCESS:
      return {
        ...state,
        bankCodes: {
          data: payload,
        },
      };
    case Types.GET_BANK_CODES_ERROR:
      return {
        ...state,
        bankCodes: {
          error: payload,
        },
      };
    default:
      return state;
  }
}

export default FinancialInformationReducer;
