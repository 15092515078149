import { apiGateway } from 'constants/services';

export default {
  login: `${apiGateway}/user-service/auth/login`,
  forgotPassword: `${apiGateway}/user-service/auth/forgot-password`,
  newPassword: `${apiGateway}/user-service/auth/forgot-password/reset`,
  googleLogin: `${apiGateway}/user-service/auth/login/google`,
  getUserPerms: args => {
    const { userId, roleId } = args;

    return `${apiGateway}/user-service/users/user/role/ui-permissions?userId=${userId}&roleName=${roleId}`;
  },
  getPermList: args => {
    const { userId, roleId } = args;

    return `${apiGateway}/user-service/users/user/${userId}/role/${roleId}/ui-permissions`;
  },
};
