import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import durationExtender from 'dayjs/plugin/duration';

import Badge from 'components/v1/Badge';

import { CellProgressWrapper, CellProgressInner } from './style';
dayjs.extend(durationExtender);

const DurationCell = ({
  firstDate,
  secondDate,
  limitDuration,
  hideBar,
  isActive,
  withBadge,

  isMobile,
}) => {
  const [state, setState] = useState({
    diffSeconds: 0,
    diffString: '',
  });

  const [firstDateState, setFirstDateState] = useState(firstDate);

  useEffect(() => {
    const diffSeconds = dayjs(firstDateState)
      .diff(dayjs(secondDate), 'seconds')
      .toFixed();

    const totalMinutes = parseInt(diffSeconds / 60, 10);
    const totalSeconds = parseInt(diffSeconds % 60, 10);

    const diffString = `${totalMinutes} dk ${totalSeconds} sn`;

    setState({
      diffSeconds,
      diffString,
    });
  }, [firstDateState, secondDate]);

  useEffect(() => {
    if (!isActive) return;

    const interval = setInterval(() => {
      const now = dayjs.now().toISOString();
      setFirstDateState(now);
    }, 1000);

    // eslint-disable-next-line
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  if (state.diffSeconds < 0) return <div></div>;
  const totalMinutes = parseInt(state.diffSeconds / 60, 10);
  const totalSeconds = parseInt(state.diffSeconds % 60, 10);

  return (
    <div>
      {withBadge ? (
        <Badge type={state.diffSeconds <= limitDuration ? 'success' : 'failed'}>
          {
            /* eslint-disable */
          isMobile
            ? `Toplam süre: ${totalMinutes<10 ? '0':''}${totalMinutes}:${totalSeconds<10 ? '0':''}${totalSeconds}`
            : `${totalMinutes} dakika`}
        </Badge>
      ) : (
        /* eslint-enable */
        <span>{state.diffString}</span>
      )}
      {!hideBar && (
        <CellProgressWrapper>
          <CellProgressInner
            percent={Math.min((state.diffSeconds / limitDuration) * 100, 100)}
          />
        </CellProgressWrapper>
      )}
    </div>
  );
};

DurationCell.propTypes = {
  firstDate: PropTypes.string,
  secondDate: PropTypes.string,
  limitDuration: PropTypes.number,
  hideBar: PropTypes.bool,
  isActive: PropTypes.bool,
  withBadge: PropTypes.bool,
  isMobile: PropTypes.bool,
};

DurationCell.defaultProps = {
  firstDate: '',
  secondDate: '',
  limitDuration: 0,
  hideBar: false,
  isActive: false,
  withBadge: false,
  isMobile: false,
};

export { DurationCell };
