import styled from 'styled-components';

import Button from 'components/v1/Button';
import breakpoint from 'constants/breakpoint';
import Table from 'components/v1/Table';
import Modal from 'components/v1/Modal';

export const OrderDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const RowContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;

  @media (max-width: ${breakpoint.MOBILE.MAX}px) {
    flex-direction: column;
  }
`;

export const AddressInfoCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 40%;
  @media (max-width: ${breakpoint.MOBILE.MAX}px) {
    width: 100%;
  }
`;

export const OrderInfoContainer = styled.div`
  display: flex;
  padding: 16px;
  align-items: flex-start;
  gap: 24px;
  border-radius: 8px;
  border: 1px solid #d4d4d4;
  width: 100%;
`;

export const OrderInfoCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  width: 60%;
  flex-grow: 1;
  @media (max-width: ${breakpoint.MOBILE.MAX}px) {
    width: 100%;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  height: 24px;
  div {
    height: 24px;
    gap: 2px;
  }
  h2 {
    margin: 0;
    color: #191919;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  @media (max-width: ${breakpoint.MOBILE.MAX}px) {
    justify-content: space-between;
  }
`;

export const AdressTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 24px;
  h2 {
    margin: 0;
    color: #191919;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
`;
export const PrintButton = styled(Button)`
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: #7849f7;
  padding: 8px 16px;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
`;
export const ShowOnMap = styled.a`
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 6px;
  height: 24px;
  border-radius: 6px;
  background: #f0f1f3;
  color: #191919;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  &:hover {
    color: #191919;
  }
`;

export const AdressInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #d4d4d4;
  border-radius: 8px;
`;
export const AdressMapContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  height: 160px;
  overflow: hidden;
`;
export const AdressInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 12px;
`;

export const Info = styled.div`
  color: #697488;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
`;
export const InfoValue = styled.span`
  color: #191919;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
`;

export const PhoneNumber = styled.a`
  color: #5d3ebc;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
`;

export const ProductTable = styled(Table)`
  width: 100%;
  .ant-table-footer {
    background-color: rgba(26, 57, 96, 0.1);
    text-align: right;
    box-shadow: inset 0px -1px 0px rgba(0, 58, 102, 0.06);
    color: #1b2b41b0;
    border: 1px solid #d4d4d4;
    border-top: 0px;
  }
  .ant-table-content {
    border-radius: 8px 8px 0px 0px !important;
    border-bottom: 0px;
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
`;

export const OrderActionButton = styled(Button)`
  font-size: 14px;
  font-weight: 700;
`;

export const ModalWrapper = styled(Modal)`
  .ant-modal-title {
    text-align: center;
  }

  .ant-modal-content {
    @media (max-width: ${breakpoint.MOBILE.MAX}px) {
      overflow: initial;
    }
  }

  .ant-modal-body {
    padding: 16px;
  }

  .ant-modal-footer {
    justify-content: flex-end;
    @media (max-width: ${breakpoint.MOBILE.MAX}px) {
      position: sticky;
      bottom: 0;
      background: #fff;
      justify-content: space-between;

      & > *:only-child {
        width: 100%;
      }
    }
  }

  .ant-divider-horizontal {
    margin: 12px 0;
  }

  h3 {
    margin: 0 !important;
  }
`;

export const ProductNameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const ProductImage = styled.div`
  width: 64px;
  aspect-ratio: 1/1;
`;

export const ProductNameLabel = styled.span`
  color: #191919;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
`;

export const ProductCol = styled.span`
  color: #191919;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
`;

export const OrderNoteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  background: #f3f0fe;
  border-radius: 4px;
  padding: 10px 0 10px 18px;
  border-left: 4px solid #332267;
  img {
    color: #332267;
    margin-right: 10px;
  }

  .icon-wrapper {
    color: #332267;
    font-weight: bold;
    display: flex;
    align-items: center;

    .anticon-info-circle {
      margin-right: 10px;
    }
  }

  .content {
    color: #332267;
  }
`;
