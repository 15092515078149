import { useState, useEffect } from 'react';

import cookies from 'utils/cookie';

function useIsAuthenticated() {
  const isAuthenticated = !!cookies.get('accessToken');

  const [isLoggedIn, setLoggedIn] = useState(isAuthenticated);

  useEffect(() => {
    setLoggedIn(isAuthenticated);
  }, [isAuthenticated, setLoggedIn]);

  return { isLoggedIn };
}

export default useIsAuthenticated;
