import * as Types from './types';

const initialState = {
  orderDetail: undefined,
  isModalOpen: false,
  orderId: undefined,
  cancelNote: undefined,
  error: {},
};

function MissingProductReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.MISSINGPRODUCT_OPEN:
      return {
        ...state,
        isModalOpen: true,
        orderId: payload.id,
        cancelNote: payload.cancelNote,
      };
    case Types.MISSINGPRODUCT_SEND_SUCCESS:
    case Types.MISSINGPRODUCT_CLOSE:
      return {
        ...state,
        isModalOpen: false,
        orderId: undefined,
        orderDetail: undefined,
      };
    case Types.MISSINGPRODUCT_SEND_ERROR:
      return {
        ...state,
        isModalOpen: false,
        error: payload.error,
      };
    case Types.MISSINGPRODUCT_ORDER_SUCCESS:
      return {
        ...state,
        orderDetail: payload,
      };
    case Types.MISSINGPRODUCT_ORDER_ERROR:
      return {
        ...state,
        orderDetail: undefined,
        error: payload,
      };
    default:
      return state;
  }
}

export default MissingProductReducer;
