import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Empty } from 'antd';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SearchOutlined } from '@ant-design/icons';

import * as ROUTES from 'constants/routes';
import { BUSINESS_TYPES } from 'common/constants';
import ConfirmModal from 'components/ConfirmModal';
import * as MainHeaderActions from 'components/MainHeader/actions';
import { getModelListInjector } from 'utils/getTypeInjector';
import GetFirmVendorsDetailResponseItem from 'models/GetFirmVendorsDetailResponseItem';
import useDebounce from 'hooks/useDebounce';
import Table from 'components/v1/Table';
import Input from 'components/v1/Input';

import * as VendorActions from '../actions';

import * as S from './style';
import { useVendorColumns } from './useVendorColumns';

const List = () => {
  const deleteVendorRef = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 200);
  const cityId = useSelector(state => state.mainHeader.cityId);
  const vendor = useSelector(state => state.mainHeader.vendor);
  const vendorList = getModelListInjector(GetFirmVendorsDetailResponseItem)(
    useSelector(state => state.vendor.vendorList),
  );
  const vendorId = useSelector(state => state.vendor.vendorId);

  const filteredVendorList = useMemo(
    () =>
      vendorList?.filter(v =>
        v.vendorName
          .toLocaleLowerCase('tr')
          .includes(debouncedSearchTerm.toLocaleLowerCase('tr')),
      ),
    [debouncedSearchTerm, vendorList],
  );

  useEffect(() => {
    if (vendor) {
      dispatch(
        VendorActions.vendorListRequest({
          cityId,
          firmId: vendor.value,
        }),
      );
    }
  }, [cityId, vendor, dispatch]);

  const handleConfirmSave = () => {
    dispatch(VendorActions.deleteVendorRequest(vendorId));
    setIsConfirmModalVisible(false);
  };

  const handleConfirmClose = () => {
    setIsConfirmModalVisible(false);
  };

  const handleEditVendor = selectedVendor => {
    const mutatedVendor = {
      isFirm: false,
      value: selectedVendor.vendorId,
      label: selectedVendor.vendorName,
    };
    dispatch(MainHeaderActions.setVendor(mutatedVendor));
    dispatch(VendorActions.setVendorId(selectedVendor.vendorId));
    dispatch(MainHeaderActions.getVendorInfoRequest(selectedVendor.vendorId));

    history.push({
      pathname: ROUTES.GENERAL_INFORMATION,
      state: { type: BUSINESS_TYPES.VENDOR },
      search: `?vendorId=${selectedVendor.vendorId}`,
    });
  };

  const handleDeleteVendor = selectedVendor => {
    dispatch(VendorActions.setVendorId(selectedVendor.vendorId));
    setIsConfirmModalVisible(true);
  };

  const columns = useVendorColumns(handleEditVendor, handleDeleteVendor);

  return (
    <S.Wrapper>
      <Input
        placeholder={t('vendor:searchVendor')}
        value={searchTerm}
        onChange={evt => setSearchTerm(evt.target.value)}
        size="large"
        style={{ width: '300px' }}
        prefix={<SearchOutlined width={20} height={20} />}
        allowClear
      />
      <Table
        columns={columns}
        dataSource={filteredVendorList}
        pagination={{ size: 'small', position: ['bottomCenter'] }}
        locale={{ emptyText: <Empty description={t('table.noData')} /> }}
        rowKey="id"
        scroll={{ x: 1500 }}
      />
      <ConfirmModal
        modalRef={deleteVendorRef}
        size="400px"
        visible={isConfirmModalVisible}
        onSave={handleConfirmSave}
        onClose={handleConfirmClose}
        title={t('vendor:deleteFirmModalTitle')}
        content={t('vendor:deleteFirmModalContent')}
      />
    </S.Wrapper>
  );
};

export default List;
