import { postRequest } from 'lib/axios';

import api from './apiRoutes';

export const addFinancialTransaction = args =>
  postRequest(api.addFinancialTransaction, args);
export const uploadPremiumFileApi = args =>
  postRequest(api.uploadPremiumFile, args);
export const savePremiumTransactionApi = args =>
  postRequest(api.savePremiumTransaction(args));
