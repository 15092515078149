import { put, call, takeLatest, select } from 'redux-saga/effects';

// import * as LoaderActions from 'components/Loader/actions';
import translate from 'utils/translate';
import { createStandardSaga } from 'utils/createStandardSaga';
import {
  getOrdersApi,
  getCompletedOrdersApi,
  changeOrderStatusApi,
  getUserPopupApi,
  sendPopupFeedbackApi,
} from 'services/ActiveOrders/api';
import { open } from 'utils/notification';
import * as LoaderActions from 'components/Loader/actions';
import { ORDER_STATUS } from 'constants/orderStatus';
import { ORDERCANCEL_REASON } from 'constants/orderCancelReasons';

import * as Types from './types';
import * as ActiveOrdersActions from './actions';
import { vendorId } from './sagaselectors';

const getActiveOrdersSaga = createStandardSaga({
  callApi: getOrdersApi,
  successAction: ActiveOrdersActions.getActiveOrdersSuccess,
  errorAction: ActiveOrdersActions.getActiveOrdersError,
});

const getCompletedOrdersSaga = createStandardSaga({
  callApi: getCompletedOrdersApi,
  successAction: ActiveOrdersActions.getCompletedOrdersSuccess,
  errorAction: ActiveOrdersActions.getCompletedOrdersError,
});

const getCanceledOrdersSaga = createStandardSaga({
  callApi: getCompletedOrdersApi,
  successAction: ActiveOrdersActions.getCanceledOrdersSuccess,
  errorAction: ActiveOrdersActions.getCanceledOrdersError,
});

const getScheduledOrdersSaga = createStandardSaga({
  callApi: getCompletedOrdersApi,
  successAction: ActiveOrdersActions.getScheduledOrdersSuccess,
  errorAction: ActiveOrdersActions.getScheduledOrdersError,
});

const getUserPopupSaga = createStandardSaga({
  callApi: getUserPopupApi,
  successAction: ActiveOrdersActions.getUserPopupSuccess,
  errorAction: ActiveOrdersActions.getUserPopupError,
});

const sendPopupFeedbackSaga = createStandardSaga({
  callApi: sendPopupFeedbackApi,
  successAction: ActiveOrdersActions.sendPopupFeedbackSuccess,
  errorAction: ActiveOrdersActions.sendPopupFeedbackError,
});

function* changeOrderStatusSaga({ payload }) {
  yield put(LoaderActions.showLoader());
  try {
    const result = yield call(changeOrderStatusApi, payload);
    if (result.data) {
      yield put(
        ActiveOrdersActions.changeOrderStatusSuccess({
          payload: result.data.payload,
          id: payload.id,
          status: payload.status,
        }),
      );

      if (
        payload.status === ORDER_STATUS.CANCELED_VENDOR &&
        [
          ORDERCANCEL_REASON.CARRIER_NOT_AVAILABLE,
          ORDERCANCEL_REASON.VENDOR_CLOSED,
          ORDERCANCEL_REASON.VENDOR_BUSY,
        ].includes(payload.cancelReason)
      ) {
        const vendor = yield select(vendorId);
        yield put(ActiveOrdersActions.changeOrderAffectVendor(vendor));
      }
      open(
        'success',
        '',
        translate(`activeOrders:statusChange.${payload.status}`),
      );
    } else {
      open('warning', translate('common:notif.warnTitle'), result.message);
    }
  } catch (error) {
    yield put(ActiveOrdersActions.changeOrderStatusError(error.message));
  }
  yield put(LoaderActions.hideLoader());
}

export function* rootSaga() {
  yield takeLatest(Types.GET_ACTIVE_ORDERS_REQUEST, getActiveOrdersSaga);
  yield takeLatest(Types.GET_COMPLETED_ORDERS_REQUEST, getCompletedOrdersSaga);
  yield takeLatest(Types.GET_CANCELED_ORDERS_REQUEST, getCanceledOrdersSaga);
  yield takeLatest(Types.CHANGE_ORDER_STATUS_REQUEST, changeOrderStatusSaga);
  yield takeLatest(Types.GET_USER_POPUP_REQUEST, getUserPopupSaga);
  yield takeLatest(Types.SEND_POPUP_FEEDBACK_REQUEST, sendPopupFeedbackSaga);
  yield takeLatest(Types.GET_SCHEDULED_ORDERS_REQUEST, getScheduledOrdersSaga);
}

export default rootSaga;
