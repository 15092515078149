import { createAction } from 'utils/createAction';

import * as Types from './types';

// list products
export const getProductsRequest = createAction(Types.GET_PRODUCTS_REQUEST);
export const getProductsSuccess = createAction(Types.GET_PRODUCTS_SUCCESS);
export const getProductsError = createAction(Types.GET_PRODUCTS_ERROR);

// update price
export const updateProductRequest = createAction(Types.UPDATE_PRICE_REQUEST);
export const updateProductSuccess = createAction(Types.UPDATE_PRICE_SUCCESS);
export const updateProductError = createAction(Types.UPDATE_PRICE_ERROR);
export const cannotUpdatePrice = createAction(Types.CANNOT_UPDATE_PRICE);
export const cannotUpdatePriceClear = createAction(
  Types.CANNOT_UPDATE_PRICE_CLEAR,
);
