import * as Types from './types';

const initialState = {
  firms: [],
  operationalManagers: {},
  error: {},
  regionManagers: {},
};

function StoreDetails(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_FIRM_SUCCESS:
      return {
        ...state,
        firms: payload,
      };
    case Types.GET_FIRM_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.GET_OPERATIONAL_MANAGER_SUCCESS:
      return {
        ...state,
        operationalManagers: payload,
      };
    case Types.GET_OPERATIONAL_MANAGER_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.GET_REGION_MANAGER_SUCCESS:
      return {
        ...state,
        regionManagers: payload,
      };
    case Types.GET_REGION_MANAGER_ERROR:
      return {
        ...state,
        error: payload,
      };
    default:
      return state;
  }
}

export default StoreDetails;
