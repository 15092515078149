import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Modal from 'components/v1/Modal';
import RichTextEditor from 'components/v1/RichTextEditor';
import Input from 'components/v1/Input';
import Button from 'components/v1/Button/Button';
import useUserInfo from 'hooks/useUserInfo';
import { ExclaimationIcon } from 'components/v1/Icons';

import * as vendorCommunicationActions from '../actions';

import * as S from './styles';

const NewMessage = () => {
  const { t } = useTranslation('vendorCommunication');
  const [subjectError, setSubjectError] = useState(true);
  const [messageError, setMessageError] = useState(undefined);
  const dispatch = useDispatch();
  const subjectRef = useRef(null);
  const messageRef = useRef(null);
  const { userInfo } = useUserInfo();
  const vendorId = useSelector(state => state.mainHeader.vendor.value);

  const { isModalOpen } = useSelector(
    state => state.vendorCommunication.modalOptions,
  );

  const closeModal = useCallback(() => {
    dispatch(vendorCommunicationActions.closeMessageModal());
  }, [dispatch]);

  const onSubjectChange = useCallback(
    e => {
      if (e.target.value.length < 3 && !subjectError) {
        setSubjectError(true);

        return;
      }

      if (e.target.value.length >= 3 && subjectError) {
        setSubjectError(false);
      }
    },
    [subjectError],
  );

  const sendMessage = useCallback(() => {
    const subject = subjectRef.current?.input.value;

    if (!messageRef.current?.getText().trim()) {
      setMessageError(t('vendorCommunication:pleaseEnterAMessage'));

      return;
    }
    if (messageError) {
      setMessageError(undefined);
    }
    const message = messageRef.current?.getHTML();

    dispatch(
      vendorCommunicationActions.createNewThreadRequest({
        fromUser: userInfo.userId,
        subject,
        vendorId,
        vendorMessageContent: {
          content: message,
          fromId: userInfo.userId,
        },
      }),
    );
  }, [dispatch, messageError, userInfo.userId, vendorId, t]);

  const Footer = useMemo(
    () => (
      <S.Footer>
        <Button disabled={subjectError} onClick={sendMessage} kind="primary">
          {t('vendorCommunication:send')}
        </Button>
      </S.Footer>
    ),
    [sendMessage, subjectError, t],
  );

  return (
    <Modal
      footerOptions={{
        customFooter: Footer,
      }}
      key="MessageModal"
      headerOptions={{
        title: t('vendorCommunication:newMessage'),
        closeButton: true,
      }}
      isOpen={isModalOpen}
      onClose={closeModal}
    >
      <S.ModalContainer>
        <Input
          size="medium"
          placeholder={t('vendorCommunication:subjectPlaceholder')}
          ref={subjectRef}
          onChange={onSubjectChange}
        />
        <RichTextEditor ref={messageRef} />
        {messageError && (
          <S.ErrorMessage>
            <ExclaimationIcon height={16} width={16} fill="inherit" />
            {messageError}
          </S.ErrorMessage>
        )}
      </S.ModalContainer>
    </Modal>
  );
};

export default NewMessage;
