import {
  deleteRequest,
  getImageRequest,
  getRequest,
  postRequest,
  putRequest,
} from 'lib/axios';

import api from './apiRoutes';

export const uploadFileApi = args => {
  const { file, orderId } = args;

  return postRequest(api.uploadFile(orderId), file);
};
export const getBillingImageApi = orderId =>
  getImageRequest(api.getBillingImageApi(orderId));

export const updateBillingStatusApi = args =>
  putRequest(api.updateBillingStatus, args);

export const deleteBillingImageApi = orderId =>
  deleteRequest(api.deleteBillingImage(orderId));

export const getBillingDetailApi = orderId =>
  getRequest(api.getBillingDetail(orderId));
