function getRandomString(length) {
  const characters =
    ';,:.!|?=)(/&%+^"_ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  return Array(length)
    .fill(null, 0, length)
    .map(() => {
      const randomMultiplier = Math.random() * 79;

      return characters.charAt(Math.floor(randomMultiplier));
    })
    .join('');
}

export default getRandomString;
