import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { Space, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import Button from 'components/v1/Button';
import * as CS from 'common/style';
import InputWrapper from 'components/InputWrapper';
import SelectBoxWrapper from 'components/SelectBoxWrapper';
import SelectBoxAntd from 'components/Antd/Elements/SelectBox';
import YandexMap from 'components/Map';
import deliveryTimeList from 'containers/Stores/Vendor/deliveryTimeList';
import * as VendorActions from 'containers/Stores/Vendor/actions';
import * as MainHeaderActions from 'components/MainHeader/actions';
import { rolesWithName } from 'constants/roles';

import * as VendorFormActions from '../../actions';

import { validationRules } from './validations';
import { useVendorOnSubmit } from './useVendorOnSubmit';
const timeList = deliveryTimeList(5, 210, 5);

const VendorForm = ({ isFieldsEditable, getFieldsEditable }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const vendorInfo = useSelector(state => state.mainHeader.vendorInfo);

  const firmList = useSelector(state => state.formInputs.firms);
  const operationalManagerList = useSelector(
    state => state.formInputs.operationalManagers,
  );
  const regionManagerList = useSelector(
    state => state.formInputs.regionManagers,
  );
  const citiesList = useSelector(state => state.mainHeader.cities);

  const districtList = useSelector(state => state.vendor.districts);
  const townList = useSelector(state => state.vendor.towns);
  const brandList = useSelector(state => state.vendor.brands);
  const isExchangeFee = useSelector(
    state => state.mainHeader.vendorInfo?.isExchangeFee,
  );
  const isSAPAreasEditable = !useSelector(
    state => state.mainHeader.vendorInfo?.isPanelRestricted,
  );

  const exchangeList = [
    {
      label: t('vendor:exchangeFee.off'),
      value: false,
    },
    {
      label: t('vendor:exchangeFee.on'),
      value: true,
    },
  ];

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    setValue,
  } = useForm({
    mode: 'onChange',
  });

  const selectedCity = useWatch({
    control,
    name: 'city',
  });

  const selectedFirm = useWatch({
    control,
    name: 'firm',
  });

  useEffect(() => {
    if (vendorInfo.id) {
      setValue('vendorName', vendorInfo.vendorName, { shouldValidate: true });
      setValue('vendorPhoneNumber', vendorInfo.vendorPhoneNumber, {
        shouldValidate: true,
      });

      setValue('commissionRate', String(vendorInfo.commissionRate ?? ''), {
        shouldValidate: true,
      });
      setValue('label', vendorInfo.label, { shouldValidate: true });
      setValue(
        'referenceCode',
        vendorInfo.referenceCode || vendorInfo.sapcardCode,
        {
          shouldValidate: true,
        },
      );
      setValue('payerReferenceCode', vendorInfo.payerReferenceCode ?? '', {
        shouldValidate: true,
      });
      setValue('minDeliveryTime', vendorInfo.minDeliveryTime, {
        shouldValidate: true,
      });
      setValue('mersisNumber', vendorInfo.mersisNumber, {
        shouldValidate: true,
      });
      setValue('kepAddress', vendorInfo.kepAddress, {
        shouldValidate: true,
      });
      setValue('maxDeliveryTime', vendorInfo.maxDeliveryTime, {
        shouldValidate: true,
      });
      setValue('minBasketAmount', vendorInfo.minShoppingCartAmount, {
        shouldValidate: true,
      });
      setValue(
        'operationalManager',
        {
          value: vendorInfo.adminOperationManager.id,
          label: vendorInfo.adminOperationManager.name,
        },
        {
          shouldValidate: true,
        },
      );
      setValue(
        'regionManager',
        {
          value: vendorInfo.adminRegionalManager.id,
          label: vendorInfo.adminRegionalManager.name,
        },
        {
          shouldValidate: true,
        },
      );
      setValue(
        'firm',
        {
          value: vendorInfo.firm.id,
          label: vendorInfo.firm.name,
        },
        {
          shouldValidate: true,
        },
      );
      setValue(
        'brand',
        Array.isArray(vendorInfo.brands)
          ? vendorInfo.brands.map(item => ({ value: item }))
          : vendorInfo.brands.value,
        {
          shouldValidate: true,
        },
      );
      setValue(
        'city',
        {
          value: vendorInfo.address.city.id,
          label: vendorInfo.address.city.name,
        },
        {
          shouldValidate: true,
        },
      );
      setValue(
        'district',
        {
          label: vendorInfo.address.district.name,
          value: vendorInfo.address.district.id,
        },
        {
          shouldValidate: true,
        },
      );
      dispatch(VendorActions.getTownsRequest(vendorInfo.address.district.id));
      setValue(
        'town',
        {
          label: vendorInfo.address.town.name,
          value: vendorInfo.address.town.id,
        },
        {
          shouldValidate: true,
        },
      );

      setValue('address', vendorInfo.address.openAddress, {
        shouldValidate: true,
      });
      setValue('searchAddress', {
        lat: vendorInfo.address.latitude,
        long: vendorInfo.address.longitude,
        name: vendorInfo.address.searchAddress,
      });
      setValue('isExchangeFee', isExchangeFee, {
        shouldValidate: true,
      });
    }
    // eslint-disable-next-line
  }, [vendorInfo]);

  useEffect(() => {
    if (selectedCity && (selectedCity.id || selectedCity.value)) {
      dispatch(
        VendorActions.getDistrictsRequest(
          selectedCity.id ? selectedCity.id : selectedCity.value,
        ),
      );
    }
  }, [selectedCity, dispatch]);

  useEffect(() => {
    if (selectedFirm) {
      const firmValue = selectedFirm.id || selectedFirm.value;
      dispatch(VendorActions.getBrandsRequest(firmValue));
      if (firmValue !== vendorInfo.firm.id) {
        setValue('brand', [], {
          shouldValidate: true,
        });
      }
    }
    // eslint-disable-next-line
  }, [selectedFirm, dispatch]);

  useEffect(() => {
    if (vendorInfo.id && !selectedFirm) {
      dispatch(VendorActions.getBrandsRequest(vendorInfo.firm.id));
    }
    // eslint-disable-next-line
  }, [vendorInfo]);

  useEffect(() => {
    dispatch(VendorFormActions.getFirmRequest());
    dispatch(
      VendorFormActions.getOperationalManagersRequest({
        roleName: rolesWithName.ADMIN_OPERATIONAL_MANAGER,
      }),
    );
    dispatch(
      VendorFormActions.getRegionManagersRequest({
        roleName: rolesWithName.ADMIN_REGIONAL_MANAGER,
      }),
    );
  }, [dispatch]);

  const handleDistrictChange = selectedDistrict => {
    if (selectedDistrict && selectedDistrict.value)
      dispatch(VendorActions.getTownsRequest(selectedDistrict.value));

    setValue('town', undefined);
  };

  const editUserSelectBoxFormData = useCallback(data => {
    if (data.userId) {
      return {
        id: data.userId,
        name: data.fullName,
      };
    }

    return {
      id: data.value,
      name: data.label,
    };
  }, []);

  const editSelectBoxFormData = useCallback(data => {
    if (data) {
      if (data.id) {
        return data;
      }

      return {
        id: data.value,
        name: data.label,
      };
    }

    return undefined;
  }, []);

  /* eslint-disable */
  const brandOptions = brandList
    ? brandList.map(item => ({
        value: item.id,
        label: item.brandName,
      }))
    : [];

  const districtOptions = districtList
    ? districtList.map(item => ({
        value: item.id,
        label: item.name,
      }))
    : [];

  const townOptions = townList
    ? townList.map(item => ({
        value: item.id,
        label: item.name,
      }))
    : [];
    const firmOptions = firmList.firms ? firmList.firms.map(item => ({
      value: item.id,
      label: item.firmName,
    })) : []

  const operationalManagerOptions = operationalManagerList.roleUsers ? operationalManagerList.roleUsers.map(item => ({
    value: item.userId,
    label: item.fullName,
  })): [];

  const regionManagerOptions = regionManagerList.roleUsers ? regionManagerList.roleUsers.map(item => ({
    value: item.userId,
    label: item.fullName,
  })): [];

  const cityOptions = citiesList ? citiesList.map(item => ({
    value: item.value,
    label: item.label,
  })) : [];

  /* eslint-enable */

  const vendorOnSubmit = useVendorOnSubmit({
    editSelectBoxFormData,
    editUserSelectBoxFormData,
    getFieldsEditable,
  });

  const cityChangeHandler = () => {
    setValue('town', undefined);
    setValue('district', undefined);
  };

  const handleCancelClick = () => {
    dispatch(MainHeaderActions.getVendorInfoRequest(vendorInfo.id));
    getFieldsEditable(false);
  };

  return (
    <form onSubmit={handleSubmit(vendorOnSubmit)}>
      <InputWrapper
        fieldName="vendorName"
        control={control}
        rules={validationRules.name}
        placeholder={t('vendor:vendorName')}
        header={t('vendor:vendorName')}
        errors={errors}
        isDisabled={!isFieldsEditable || !isSAPAreasEditable}
      />
      <InputWrapper
        fieldName="vendorPhoneNumber"
        control={control}
        rules={validationRules.phone}
        placeholder={t('vendor:vendorPhone')}
        header={t('vendor:vendorPhone')}
        errors={errors}
        isOnlyNumber
        isDisabled={!isFieldsEditable || !isSAPAreasEditable}
      />
      <InputWrapper
        fieldName="referenceCode"
        control={control}
        rules={validationRules.referenceCode}
        placeholder={t('vendor:referenceCode')}
        header={t('vendor:referenceCode')}
        errors={errors}
        isDisabled
      />
      <InputWrapper
        fieldName="payerReferenceCode"
        control={control}
        rules={validationRules.payerReferenceCode}
        placeholder={t('vendor:payerReferenceCode')}
        header={t('vendor:payerReferenceCode')}
        errors={errors}
        disabled
      />
      <InputWrapper
        fieldName="mersisNumber"
        control={control}
        rules={validationRules.mersisNo}
        placeholder={t('firm:mersisNumber')}
        header={t('firm:mersisNumber')}
        errors={errors}
        isDisabled={!isFieldsEditable}
      />
      <InputWrapper
        fieldName="kepAddress"
        control={control}
        rules={validationRules.kepAddress}
        placeholder={t('firm:kepAddress')}
        header={t('firm:kepAddress')}
        errors={errors}
        isDisabled={!isFieldsEditable}
      />

      <InputWrapper
        fieldName="commissionRate"
        control={control}
        rules={validationRules.commissionRate}
        placeholder={t('vendor:commissionRate')}
        header={t('vendor:commissionRate')}
        errors={errors}
        isOnlyNumber
        isDisabled={!isFieldsEditable}
      />

      <SelectBoxWrapper
        fieldName="minDeliveryTime"
        control={control}
        rules={validationRules.minDeliveryTime}
        placeholder={t('vendor:minDeliveryTime')}
        errors={errors}
        options={timeList}
        isDisabled={!isFieldsEditable}
      />

      <SelectBoxWrapper
        fieldName="maxDeliveryTime"
        control={control}
        rules={validationRules.maxDeliveryTime}
        placeholder={t('vendor:maxDeliveryTime')}
        errors={errors}
        options={timeList}
        isDisabled={!isFieldsEditable}
      />

      <InputWrapper
        fieldName="minBasketAmount"
        control={control}
        rules={validationRules.minBasketAmount}
        placeholder={t('vendor:minBasketAmount')}
        header={t('vendor:minBasketAmount')}
        errors={errors}
        isOnlyNumber
        isDisabled={!isFieldsEditable}
      />
      <div>
        <Controller
          name="operationalManager"
          control={control}
          rules={validationRules.operationalManager}
          render={({ field }) => (
            <SelectBoxAntd
              placeholder={t('vendor:operationalManager')}
              showSearch
              hasError={errors.operationalManager}
              value={field.value}
              onChange={field.onChange}
              options={operationalManagerOptions}
              labelInValue
              allowClear
              disabled={!isFieldsEditable}
            />
          )}
        />
        {errors.operationalManager && (
          <CS.ErrorMessage>{errors.operationalManager.message}</CS.ErrorMessage>
        )}
      </div>
      <div>
        <Controller
          name="regionManager"
          control={control}
          rules={validationRules.regionManager}
          render={({ field }) => (
            <SelectBoxAntd
              placeholder={t('vendor:regionManager')}
              showSearch
              hasError={errors.regionManager}
              value={field.value}
              onChange={field.onChange}
              options={regionManagerOptions}
              labelInValue
              allowClear
              disabled={!isFieldsEditable}
            />
          )}
        />
        {errors.regionManager && (
          <CS.ErrorMessage>{errors.regionManager.message}</CS.ErrorMessage>
        )}
      </div>
      <div>
        <Controller
          name="firm"
          control={control}
          rules={validationRules.firm}
          render={({ field }) => (
            <SelectBoxAntd
              placeholder={t('vendor:firm')}
              showSearch
              hasError={errors.firm}
              value={field.value}
              onChange={field.onChange}
              options={firmOptions}
              labelInValue
              allowClear
              disabled={!isFieldsEditable || !isSAPAreasEditable}
            />
          )}
        />
        {errors.firm && (
          <CS.ErrorMessage>{errors.firm.message}</CS.ErrorMessage>
        )}
      </div>
      <div>
        <Controller
          name="brand"
          control={control}
          rules={validationRules.brand}
          render={({ field }) => (
            <SelectBoxAntd
              placeholder={t('vendor:brand')}
              showSearch
              hasError={errors.brand}
              value={field.value}
              onChange={field.onChange}
              options={brandOptions}
              labelInValue
              allowClear
              disabled={!isFieldsEditable}
            />
          )}
        />
        {errors.brand && (
          <CS.ErrorMessage>{errors.brand.message}</CS.ErrorMessage>
        )}
      </div>
      <div>
        <Controller
          name="city"
          control={control}
          rules={validationRules.city}
          render={({ field }) => (
            <SelectBoxAntd
              placeholder={t('vendor:city')}
              showSearch
              hasError={errors.city}
              value={field.value}
              onChange={value => {
                field.onChange(value);
                cityChangeHandler();
              }}
              options={cityOptions}
              labelInValue
              allowClear
              disabled={!isFieldsEditable || !isSAPAreasEditable}
            />
          )}
        />
        {errors.city && (
          <CS.ErrorMessage>{errors.city.message}</CS.ErrorMessage>
        )}
      </div>
      <div>
        <Controller
          name="district"
          control={control}
          rules={validationRules.district}
          render={({ field }) => (
            <SelectBoxAntd
              placeholder={t('vendor:district')}
              onChange={value => {
                handleDistrictChange(value);
                field.onChange(value);
              }}
              showSearch
              hasError={errors.district}
              value={field.value}
              options={districtOptions}
              labelInValue
              allowClear
              disabled={!isFieldsEditable}
            />
          )}
        />
        {errors.district && (
          <CS.ErrorMessage>{errors.district.message}</CS.ErrorMessage>
        )}
      </div>
      <div>
        <Controller
          name="town"
          control={control}
          rules={validationRules.town}
          render={({ field }) => (
            <SelectBoxAntd
              placeholder={t('vendor:town')}
              onChange={field.onChange}
              showSearch
              hasError={errors.town}
              value={field.value}
              options={townOptions}
              labelInValue
              allowClear
              disabled={!isFieldsEditable}
            />
          )}
        />
        {errors.town && (
          <CS.ErrorMessage>{errors.town.message}</CS.ErrorMessage>
        )}
      </div>
      <InputWrapper
        fieldName="address"
        control={control}
        rules={validationRules.address}
        placeholder={t('vendor:address')}
        header={t('vendor:address')}
        errors={errors}
        isDisabled={!isFieldsEditable || !isSAPAreasEditable}
      />
      <SelectBoxWrapper
        fieldName="isExchangeFee"
        control={control}
        rules={validationRules.isExchangeFee}
        placeholder={t('vendor:exchangeFee.inputLabel')}
        errors={errors}
        options={exchangeList}
        isDisabled={!isFieldsEditable}
      />
      <CS.FormFieldWrapper>
        <Controller
          name="searchAddress"
          control={control}
          rules={validationRules.searchAddress}
          render={({ field }) => (
            <YandexMap
              field={field}
              setFieldValue={field.onChange}
              errors={errors}
            />
          )}
        />
      </CS.FormFieldWrapper>
      <Divider />
      {isFieldsEditable && (
        <Space size="middle">
          <Button
            style={{
              width: '100%',
            }}
            kind="secondary"
            onClick={handleCancelClick}
          >
            {t('giveUp')}
          </Button>
          <Button
            style={{
              width: '100%',
            }}
            type="submit"
            disabled={!isValid}
          >
            {t('save')}
          </Button>
        </Space>
      )}
    </form>
  );
};

VendorForm.propTypes = {
  isFieldsEditable: PropTypes.bool,
  getFieldsEditable: PropTypes.func,
};

VendorForm.defaultProps = {
  isFieldsEditable: false,
  getFieldsEditable: () => {},
};

export default VendorForm;
