import styled from 'styled-components';

export const ToolTipContainer = styled.div`
  .ant-tooltip-inner {
    background: #191919;
    box-shadow: 0px 3px 12px -1px rgba(28, 52, 84, 0.26),
      0px 2px 4px -1px rgba(28, 55, 90, 0.16);
    border-radius: 6px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    padding: 8px;
  }
  .ant-tooltip-arrow-content {
    background-color: #191919;
  }
`;
