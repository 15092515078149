import { useState, useEffect } from 'react';

import { playableAudio as audio } from '../constants';

const useAudio = () => {
  const [playing, setPlaying] = useState(false);

  const playAudio = () => setPlaying(!playing);
  /* eslint-disable */
  useEffect(() => {
    if(playing){
      const playPromise = audio.play();
      if (playPromise !== undefined) {
        playPromise
          .then(_ => {
        
          })
          .catch(error => {
          
          });
      }
      return
    }
     audio.pause();
  }, [playing]);


  useEffect(() => {
    audio.autoplay = true;
    audio.addEventListener('ended', () => setPlaying(false));

    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
    // eslint-disable-next-line
  }, []);

  return { playing, playAudio };
};

export { useAudio };
