import { apiGateway } from 'constants/services';

export default {
  getPermListRoots: () => `${apiGateway}/user-service/ui-components/roots`,
  getPermListChildren: rootId =>
    `${apiGateway}/user-service/ui-component/${rootId}/sub-components`,
  getRoleList: () => `${apiGateway}/user-service/roles`,
  updatePerm: componentId =>
    `${apiGateway}/user-service/ui-components/ui-component/${componentId}/ui-permission`,
};
