import styled, { keyframes } from 'styled-components';

export const DragdropContainer = styled.div`
  .ant-upload-drag {
    border: 1px dashed #d4d4d4 !important;
    border-radius: 8px !important;
    background: #ffffff !important;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  span {
    width: 100%;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
  img {
    width: 100%;
  }
`;

export const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;
export const Loader = styled.div`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);

  border-top: 2px solid grey;
  border-right: 2px solid grey;
  border-bottom: 2px solid grey;
  border-left: 4px solid black;
  background: transparent;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 8px;
`;

export const LoadingContainer = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InfoText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #697488;
`;

export const UploadText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #697488;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-right: 6px;
    line-height: 0px;
  }
`;

export const InfoContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
  padding: 24px;

  button {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    padding: 8px 16px;
  }
`;
