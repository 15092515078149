import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as FirmActions from 'containers/Stores/Firm/actions';
import * as CategoryActions from 'containers/CategoryManagement/Category/actions';

import * as CatalogActions from '../actions';

const useBrandsFirmsCategories = () => {
  const dispatch = useDispatch();

  const brands = useSelector(state => state.catalogProduct.catalogBrands);
  const firms = useSelector(state => state.firm.firms.firms);
  const categories = useSelector(state => state.category.categorys);

  useEffect(() => {
    if (brands !== undefined) {
      dispatch(CatalogActions.catalogBrandReset());
    }
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    if (firms === undefined) dispatch(FirmActions.firmListRequest());
  }, [dispatch, firms]);

  useEffect(() => {
    if (categories === undefined)
      dispatch(CategoryActions.categoryListRequest());
  }, [dispatch, categories]);

  return { brands, firms, categories };
};

export { useBrandsFirmsCategories };
