import React from 'react';

export default function AlignCenter() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="inherit"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 18.6177H15V16.6177H9V18.6177ZM6 11.6177V13.6177H18V11.6177H6ZM3 6.61774V8.61774H21V6.61774H3Z"
        fill="inherit"
      />
    </svg>
  );
}
