import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Space, Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { SearchOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'components/v1/Button';
import Table from 'components/v1/Table';
import { roleGroupNames } from 'constants/roleGroupNames';
import ConfirmModal from 'components/ConfirmModal';
import useDebounce from 'hooks/useDebounce';
import Input from 'components/v1/Input';

import * as UserActions from '../actions';

import * as S from './style';

const List = ({ getEditUserClick, getUser }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const deleteUserModalRef = useRef();
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 200);
  const { data: userList } = useSelector(state => state.authUsers.userList);
  const { data: updateUser } = useSelector(state => state.authUsers.updateUser);
  const { data: createUser } = useSelector(state => state.authUsers.createUser);
  const { data: deleteUser } = useSelector(state => state.authUsers.deleteUser);

  const [isDeleteUserModalVisible, setIsDeleteUserModalVisible] = useState(
    false,
  );
  const [selectedUser, setSelectedUser] = useState(undefined);

  /* eslint-disable */
  const filteredUsers = useMemo(
    () =>
      userList
        ? userList.users?.filter(user =>
            user.userName?.toLocaleLowerCase('tr')
              .includes(debouncedSearchTerm.toLocaleLowerCase('tr')),
          )
        : [],
    [userList, debouncedSearchTerm],
  );
  /* eslint-enable */

  useEffect(() => {
    dispatch(
      UserActions.getUserListRequest({
        roleGroupName: roleGroupNames.STATIC_GETIR_GROUP,
      }),
    );
  }, [dispatch, updateUser, createUser, deleteUser]);

  const handleEditUserClick = record => {
    getEditUserClick(true);
    getUser(record);
  };

  const handleDeleteUserClick = record => {
    setSelectedUser(record);
    setIsDeleteUserModalVisible(true);
  };

  const handleDeleteUserModalSave = () => {
    const { userId } = selectedUser;
    dispatch(UserActions.deleteUserRequest(userId));
    setIsDeleteUserModalVisible(false);
  };
  const handleDeleteUserModalClose = () => {
    setIsDeleteUserModalVisible(false);
  };

  const columns = [
    {
      title: t('authUsersPage:tableColumns.name'),
      dataIndex: 'userName',
      key: 'userName',
      align: 'center',
    },
    {
      title: t('authUsersPage:tableColumns.email'),
      dataIndex: 'email',
      key: 'email',
      align: 'center',
      sorter: (a, b) => {
        if (Object.keys(a).length > 0) {
          return a.email.localeCompare(b.email);
        }

        return '';
      },
      defaultSortOrder: 'ascend',
    },
    {
      title: t('actions'),
      key: 'settings',
      render: (_, record) => (
        <Space size="small">
          <Button kind="secondary" onClick={() => handleEditUserClick(record)}>
            {t('edit')}
          </Button>
          <Button
            kind="secondary"
            onClick={() => handleDeleteUserClick(record)}
          >
            {t('delete')}
          </Button>
        </Space>
      ),
      align: 'center',
      width: '20%',
    },
  ];

  return (
    <S.Wrapper>
      <Input
        placeholder={t('authUsersPage:searchUser')}
        value={searchTerm}
        onChange={evt => setSearchTerm(evt.target.value)}
        size="large"
        style={{ width: '300px' }}
        prefix={<SearchOutlined width={20} height={20} />}
        allowClear
      />
      <Table
        data={filteredUsers}
        columns={columns}
        pagination={{ size: 'small' }}
        scroll={{ x: 'max-content' }}
        bordered
        locale={{ emptyText: <Empty description={t('table.noData')} /> }}
        rowKey={record => record.userId}
      />
      <ConfirmModal
        modalRef={deleteUserModalRef}
        size="400px"
        visible={isDeleteUserModalVisible}
        onSave={handleDeleteUserModalSave}
        onClose={handleDeleteUserModalClose}
        title={t('authUsersPage:deleteUserModal.title')}
        content={t('authUsersPage:deleteUserModal.text')}
        cancelText={t('giveUp')}
        okText={t('yes')}
      />
    </S.Wrapper>
  );
};

List.defaultProps = {
  getEditUserClick: () => {},
  getUser: () => {},
};

List.propTypes = {
  getEditUserClick: PropTypes.func,
  getUser: PropTypes.func,
};

export default List;
