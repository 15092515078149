import React from 'react';
import PropTypes from 'prop-types';

const MapPinIcon = ({ fill, width, height }) => (
  <svg
    width={width}
    height={height}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.11844 3.68863C7.81445 0.992624 12.1855 0.992624 14.8815 3.68863C17.5776 6.38464 17.5776 10.7557 14.8815 13.4517L10 18.3333L5.11844 13.4517C2.42243 10.7557 2.42243 6.38464 5.11844 3.68863ZM10 10.5426C11.0893 10.5426 11.9724 9.65954 11.9724 8.57019C11.9724 7.48083 11.0893 6.59774 10 6.59774C8.91064 6.59774 8.02755 7.48083 8.02755 8.57019C8.02755 9.65954 8.91064 10.5426 10 10.5426Z"
      fill="inherit"
    />
  </svg>
);

MapPinIcon.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

MapPinIcon.defaultProps = {
  fill: '#5D3EBC',
  width: 14,
  height: 14,
};

export default MapPinIcon;
