export const BAN_GEOMAP_GET_REQUEST = 'BAN_GEOMAP_GET_REQUEST';
export const BAN_GEOMAP_GET_SUCCESS = 'BAN_GEOMAP_GET_SUCCESS';
export const BAN_GEOMAP_GET_ERROR = 'BAN_GEOMAP_GET_ERROR';

export const BAN_GEOMAP_SET_CITY = 'BAN_GEOMAP_SET_CITY';
export const BAN_GEOMAP_SET_BRAND = 'BAN_GEOMAP_SET_BRAND';
export const BAN_GEOMAP_SET_INTERVAL = 'BAN_GEOMAP_SET_INTERVAL';
export const BAN_GEOMAP_SET_CENTER = 'BAN_GEOMAP_SET_CENTER';
export const BAN_GEOMAP_RESET_STATE = 'BAN_GEOMAP_RESET_STATE';

export const BAN_GEOMAP_SAVE_REQUEST = 'BAN_GEOMAP_SAVE_REQUEST';
export const BAN_GEOMAP_SAVE_SUCCESS = 'BAN_GEOMAP_SAVE_SUCCESS';
export const BAN_GEOMAP_SAVE_ERROR = 'BAN_GEOMAP_SAVE_ERROR';

export const BAN_GEOMAP_GET_BANNED_VENDORS_REQUEST =
  'BAN_GEOMAP_GET_BANNED_VENDORS_REQUEST';
export const BAN_GEOMAP_GET_BANNED_VENDORS_SUCCESS =
  'BAN_GEOMAP_GET_BANNED_VENDORS_SUCCESS';
export const BAN_GEOMAP_GET_BANNED_VENDORS_ERROR =
  'BAN_GEOMAP_GET_BANNED_VENDORS_ERROR';

export const BAN_GEOMAP_GET_NEIGHBOURHOOD_REQUEST =
  'BAN_GEOMAP_GET_NEIGHBOURHOOD_REQUEST';
export const BAN_GEOMAP_GET_NEIGHBOURHOOD_SUCCESS =
  'BAN_GEOMAP_GET_NEIGHBOURHOOD_SUCCESS';
export const BAN_GEOMAP_GET_NEIGHBOURHOOD_ERROR =
  'BAN_GEOMAP_GET_NEIGHBOURHOOD_ERROR';

export const BAN_GEOMAP_GET_DISTRICT_REQUEST =
  'BAN_GEOMAP_GET_DISTRICT_REQUEST';
export const BAN_GEOMAP_GET_DISTRICT_SUCCESS =
  'BAN_GEOMAP_GET_DISTRICT_SUCCESS';
export const BAN_GEOMAP_GET_DISTRICT_ERROR = 'BAN_GEOMAP_GET_DISTRICT_ERROR';

export const BAN_GEOMAP_GET_VENDORS_REQUEST = 'BAN_GEOMAP_GET_VENDORS_REQUEST';
export const BAN_GEOMAP_GET_VENDORS_SUCCESS = 'BAN_GEOMAP_GET_VENDORS_SUCCESS';
export const BAN_GEOMAP_GET_VENDORS_ERROR = 'BAN_GEOMAP_GET_VENDORS_ERROR';

export const SET_NEIGHBOURHOOD_IS_VISIBLE = 'SET_NEIGHBOURHOOD_IS_VISIBLE';

export const BAN_GEOMAP_SET_SELECTED_DISTRICT =
  'BAN_GEOMAP_SET_SELECTED_DISTRICT';

export const BAN_GEOMAP_SET_EDITED_MAP = 'BAN_GEOMAP_SET_EDITED_MAP';

export const BAN_GEOMAP_SET_SHOW_ONLY_SELECTED_VENDOR =
  'BAN_GEOMAP_SET_SHOW_ONLY_SELECTED_VENDOR';

export const BAN_GEOMAP_SET_SELECTED_VENDOR = 'BAN_GEOMAP_SET_SELECTED_VENDOR';
