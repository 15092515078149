import * as Types from './types';

const initialState = {
  transactions: {
    data: undefined,
    currentBalance: undefined,
    totalCount: undefined,
  },
  detailedTransactions: {
    data: undefined,
    totalCount: undefined,
  },
  transactionDetail: {
    data: undefined,
    dayEndCharge: undefined,
  },
  error: {},
};

function FinancialTransactionsReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactions: {
          data: payload.payload.content,
          currentBalance: payload.currentBalance,
          totalCount: payload.payload.totalElements,
        },
      };
    case Types.GET_TRANSACTIONS_ERROR:
    case Types.GET_TRANSACTION_DETAIL_ERROR:
    case Types.GET_DETAILED_TRANSACTIONS_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.GET_DETAILED_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        detailedTransactions: {
          data: payload.payload.content,
          totalCount: payload.payload.totalElements,
        },
      };
    }
    case Types.GET_TRANSACTION_DETAIL_SUCCESS:
      return {
        ...state,
        transactionDetail: {
          data: payload.payload,
          dayEndCharge: payload.dayEndCharge,
        },
      };
    case Types.RESET_TRANSACTIONS_LIST:
      return {
        ...state,
        transactions: {
          data: undefined,
          currentBalance: undefined,
        },
        detailedTransactions: {
          data: undefined,
          totalCount: undefined,
        },
      };
    default:
      return state;
  }
}

export default FinancialTransactionsReducer;
