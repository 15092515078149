import { takeLatest } from 'redux-saga/effects';

import { sendSMSCourier, getCourierListApi } from 'services/ActiveOrders/api';
import { createStandardSaga } from 'utils/createStandardSaga';
import { getOrderApi } from 'services/OrderDetail/api';

import * as CourierListModalActions from './actions';
import * as Types from './types';

const sendSMSCourierSaga = createStandardSaga({
  callApi: sendSMSCourier,
  successAction: CourierListModalActions.sendSmsCourierSuccess,
  errorAction: CourierListModalActions.sendSmsCourierError,
  successMessage: 'activeOrders:courierListModal.successMessage',
});

const getOrderDetailSaga = createStandardSaga({
  callApi: getOrderApi,
  successAction: CourierListModalActions.getOrderDetailSuccess,
  errorAction: CourierListModalActions.getOrderDetailError,
});

const getCourierListSaga = createStandardSaga({
  callApi: getCourierListApi,
  successAction: CourierListModalActions.getCourierListSuccess,
  errorAction: CourierListModalActions.getCourierListError,
});

export function* rootSaga() {
  yield takeLatest(Types.SEND_SMS_COURIER_REQUEST, sendSMSCourierSaga);
  yield takeLatest(Types.ORDER_DETAIL_REQUEST, getOrderDetailSaga);
  yield takeLatest(Types.GET_COURIER_LIST_REQUEST, getCourierListSaga);
}

export default rootSaga;
