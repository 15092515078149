import React from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Modal, Button, Divider, utilities } from '@getir/web-components';
import { useTranslation } from 'react-i18next';

import { validateNewPassword } from 'utils/validations';
import InputWrapper from 'components/InputWrapper';
import { PASSWORD_PATTERN, MIN_PASSWORD_LENGTH } from 'common/constants';

import * as HeaderActions from './actions';
import * as S from './style';

const { noop } = utilities;

const defaultValues = {
  oldPassword: '',
  newPassword: '',
  newPasswordConfirm: '',
};

const ChangePasswordModal = ({ onSave, onClose, ...props }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
  } = useForm({
    mode: 'onBlur',
    defaultValues,
  });

  const validationRules = {
    oldPassword: {
      required: t('requiredFieldText'),
    },
    newPassword: {
      required: t('requiredFieldText'),
      validate: value => {
        const minPasswordLength = MIN_PASSWORD_LENGTH;
        const pattern = new RegExp(PASSWORD_PATTERN);
        const result = pattern.test(value);

        if (!result) {
          return t('passwordValid', { minPasswordLength });
        }

        return true;
      },
    },
    newPasswordConfirm: {
      required: t('requiredFieldText'),
      validate: value => {
        if (validateNewPassword(value)) {
          if (value !== watch('newPassword')) {
            return t('matchPasswordValid');
          }
        }

        return true;
      },
    },
  };

  const onSubmit = data => {
    dispatch(HeaderActions.changePasswordRequest(data));
    onSave();
    reset();
  };

  const handleOnClose = () => {
    onClose();
    reset();
  };

  return (
    <S.StyledModal
      name="HEADER_CHANGE_PASSWORD"
      onCancel={handleOnClose}
      {...props}
    >
      <Modal.Header>{t('header:changePasswordTitle')}</Modal.Header>
      <Modal.Body>
        <S.FormBody>
          <S.ChangePasswordForm onSubmit={handleSubmit(onSubmit)}>
            <InputWrapper
              fieldName="oldPassword"
              control={control}
              rules={validationRules.oldPassword}
              placeholder={t('header:oldPassword')}
              header={t('header:oldPassword')}
              errors={errors}
              type="password"
            />
            <Divider />
            <InputWrapper
              fieldName="newPassword"
              control={control}
              rules={validationRules.newPassword}
              placeholder={t('header:newPassword')}
              header={t('header:newPassword')}
              errors={errors}
              type="password"
            />
            <InputWrapper
              fieldName="newPasswordConfirm"
              control={control}
              rules={validationRules.newPasswordConfirm}
              placeholder={t('header:newPasswordConfirm')}
              header={t('header:newPasswordConfirm')}
              errors={errors}
              type="password"
            />
            <S.FormFooter>
              <Button type="submit">{t('save')}</Button>
            </S.FormFooter>
          </S.ChangePasswordForm>
        </S.FormBody>
      </Modal.Body>
    </S.StyledModal>
  );
};

ChangePasswordModal.propTypes = {
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

ChangePasswordModal.defaultProps = {
  onSave: noop,
  onClose: noop,
};

export default ChangePasswordModal;
