import React from 'react';
import PropTypes from 'prop-types';

const Exclaimation = ({ fill, width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 14"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.6666 7.00004C13.6666 10.6819 10.6818 13.6667 6.99992 13.6667C3.31802 13.6667 0.333252 10.6819 0.333252 7.00004C0.333252 3.31814 3.31802 0.333374 6.99992 0.333374C10.6818 0.333374 13.6666 3.31814 13.6666 7.00004ZM7.83325 10.3334C7.83325 10.7936 7.46016 11.1667 6.99992 11.1667C6.53968 11.1667 6.16659 10.7936 6.16659 10.3334C6.16659 9.87314 6.53968 9.50004 6.99992 9.50004C7.46016 9.50004 7.83325 9.87314 7.83325 10.3334ZM6.99992 2.83337C6.53968 2.83337 6.16659 3.20647 6.16659 3.66671V7.00004C6.16659 7.46028 6.53968 7.83337 6.99992 7.83337C7.46016 7.83337 7.83325 7.46028 7.83325 7.00004V3.66671C7.83325 3.20647 7.46016 2.83337 6.99992 2.83337Z"
      fill="#D13333"
    />
  </svg>
);

Exclaimation.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

Exclaimation.defaultProps = {
  fill: '#D13333',
  width: 10,
  height: 10,
};

export default Exclaimation;
