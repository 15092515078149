import { takeLatest, call, put } from 'redux-saga/effects';

import {
  getTransactionsApi,
  getTransactionsDetailApi,
  getDetailedTransactionsApi,
} from 'services/FinancialInformation/api';
import history from 'utils/history';
import * as LoaderActions from 'components/Loader/actions';
import * as ROUTES from 'constants/routes';

import * as Types from './types';
import * as TransactionsActions from './actions';

function* getTransactionsSaga({ payload }) {
  yield put(LoaderActions.showLoader());
  try {
    const result = yield call(getTransactionsApi, payload);
    if (result.data) {
      yield put(TransactionsActions.getTransactionsSuccess(result.data));
    }
  } catch (error) {
    yield put(TransactionsActions.getTransactionsError(error.message));
  }
  yield put(LoaderActions.hideLoader());
}

function* getDetailedTransactionsSaga({ payload }) {
  yield put(LoaderActions.showLoader());
  try {
    const result = yield call(getDetailedTransactionsApi, payload);
    if (result.data) {
      yield put(
        TransactionsActions.getDetailedTransactionsSuccess(result.data),
      );
    }
  } catch (error) {
    yield put(TransactionsActions.getDetailedTransactionsError(error.message));
  }
  yield put(LoaderActions.hideLoader());
}

function* getTransactionDetailSaga({ payload }) {
  yield put(LoaderActions.showLoader());
  try {
    const result = yield call(getTransactionsDetailApi, payload);
    if (result.data) {
      yield put(TransactionsActions.getTransactionDetailSuccess(result.data));
      history.push({
        state: {
          id: payload.id,
        },
        pathname: ROUTES.ORDER_DETAIL,
      });
    }
  } catch (error) {
    yield put(TransactionsActions.getTransactionDetailError(error.message));
  }
  yield put(LoaderActions.hideLoader());
}

export function* rootSaga() {
  yield takeLatest(Types.GET_TRANSACTIONS_REQUEST, getTransactionsSaga);
  yield takeLatest(
    Types.GET_TRANSACTION_DETAIL_REQUEST,
    getTransactionDetailSaga,
  );
  yield takeLatest(
    Types.GET_DETAILED_TRANSACTIONS_REQUEST,
    getDetailedTransactionsSaga,
  );
}

export default rootSaga;
