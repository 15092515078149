import { createAction } from 'utils/createAction';

import * as Types from './types';

export const getReferenceVendorRequest = createAction(
  Types.GET_REFERENCE_VENDOR_REQUEST,
);
export const getReferenceVendorSuccess = createAction(
  Types.GET_REFERENCE_VENDOR_SUCCESS,
);
export const getReferenceVendorError = createAction(
  Types.GET_REFERENCE_VENDOR_ERROR,
);

export const getReferenceFirmRequest = createAction(
  Types.GET_REFERENCE_FIRM_REQUEST,
);
export const getReferenceFirmSuccess = createAction(
  Types.GET_REFERENCE_FIRM_SUCCESS,
);
export const getReferenceFirmError = createAction(
  Types.GET_REFERENCE_FIRM_ERROR,
);

export const getBankCodesRequest = createAction(Types.GET_BANK_CODES_REQUEST);
export const getBankCodesSuccess = createAction(Types.GET_BANK_CODES_SUCCESS);
export const getBankCodesError = createAction(Types.GET_BANK_CODES_ERROR);
