export const COMPANY_TYPES = [
  'COMPANY_TYPE.PERSONAL',
  'COMPANY_TYPE.CORPORATE',
];

export const financialInfoTypesValues = {
  NEW: 'NEW',
  FIRM: 'FIRM',
  VENDOR: 'VENDOR',
};

export const financialInfoTypes = [
  {
    value: financialInfoTypesValues.NEW,
    label: 'financialInfoValues.new',
  },
  {
    value: financialInfoTypesValues.FIRM,
    label: 'financialInfoValues.firm',
  },
  {
    value: financialInfoTypesValues.VENDOR,
    label: 'financialInfoValues.vendor',
  },
];
