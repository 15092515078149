import { takeLatest, put, call, select } from 'redux-saga/effects';

import {
  updateWorkingHoursApi,
  getAutoOpenApi,
  updateAutoOpenApi,
} from 'services/WorkingHours/api';
import { getWorkingHoursInfoApi } from 'services/MainHeader/api';
import * as MainHeaderActions from 'components/MainHeader/actions';
import * as LoaderActions from 'components/Loader/actions';
import { open } from 'utils/notification';
import translate from 'utils/translate';

import * as Types from './types';
import * as WorkingHoursActions from './actions';
import { vendorId } from './selectors';

function* updateWorkingHoursSaga({ payload }) {
  yield put(LoaderActions.showLoader());
  try {
    const result = yield call(updateWorkingHoursApi, payload);
    if (result.data) {
      const vendor = yield select(vendorId);
      yield put(WorkingHoursActions.updateWorkingHoursSuccess(vendor));

      open(
        'success',
        translate('common:notif.successTitle'),
        translate('common:notif.successContent'),
      );
    }
  } catch (error) {
    yield put(WorkingHoursActions.updateWorkingHoursError(error.message));
  }
  yield put(LoaderActions.hideLoader());
}

function* getWorkingHoursSaga() {
  yield put(LoaderActions.showLoader());
  try {
    const vendor = yield select(vendorId);
    const result = yield call(getWorkingHoursInfoApi, vendor);
    if (result.data) {
      yield put(
        MainHeaderActions.getWorkingHoursInfoSuccess(result.data.payload),
      );
    }
  } catch (error) {
    yield put(MainHeaderActions.getWorkingHoursInfoError(error.message));
  }
  yield put(LoaderActions.hideLoader());
}

function* getAutoOpenSaga() {
  try {
    const vendor = yield select(vendorId);
    const result = yield call(getAutoOpenApi, vendor);
    if (result.data) {
      yield put(WorkingHoursActions.getAutoOpenSuccess(result.data.payload));
    }
  } catch (error) {
    open('error', translate('common:notif.errorTitle'), error.message);
    yield put(WorkingHoursActions.getAutoOpenError(error.message));
  }
}

function* updateAutoOpenSaga({ payload }) {
  try {
    const vendor = yield select(vendorId);
    const reqData = {
      vendorId: vendor,
      data: { isAutoOpenEnabled: payload.isOpen },
    };
    const result = yield call(updateAutoOpenApi, reqData);
    if (result.data) {
      yield put(WorkingHoursActions.updateAutoOpenSuccess(payload));
      open(
        'success',
        translate('common:notif.successTitle'),
        translate('common:notif.successContent'),
      );
    }
  } catch (error) {
    open('error', translate('common:notif.errorTitle'), error.message);
    yield put(WorkingHoursActions.updateAutoOpenError(error.message));
  }
}

export function* rootSaga() {
  yield takeLatest(Types.UPDATE_WORKING_HOURS_REQUEST, updateWorkingHoursSaga);
  yield takeLatest(Types.UPDATE_WORKING_HOURS_SUCCESS, getWorkingHoursSaga);
  yield takeLatest(Types.GET_AUTO_OPEN_REQUEST, getAutoOpenSaga);
  yield takeLatest(Types.UPDATE_AUTO_OPEN_REQUEST, updateAutoOpenSaga);
}

export default rootSaga;
