import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from 'components/v1/Button';
import Layout from 'components/Layout';
import * as ROUTES from 'constants/routes';

import * as S from './style';
import List from './List';

const CatalogProduct = () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Layout>
      <S.PageHeader>
        <S.PageTitle>{t('catalogs:title')}</S.PageTitle>
        <Button type="primary" onClick={() => history.push(ROUTES.ADD_CATALOG)}>
          {t('catalogs:addCatalogProduct')}
        </Button>
      </S.PageHeader>
      <List />
    </Layout>
  );
};

export default CatalogProduct;
