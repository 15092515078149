import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'components/v1/Button';
import { open } from 'utils/notification';
import Layout from 'components/Layout';
import * as UsersActions from 'containers/StoreDetails/Users/actions';

import List from './List';
import * as S from './style';
import * as UserActions from './actions';
import { findUserRoleGroupName } from './utils/findUserRoleGroupName';
import AddNewUserModal from './AddNewUserModal';

const Users = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const addUserModalRef = useRef();
  const vendor = useSelector(state => state.mainHeader.vendor);

  const [isAddUserModalVisible, setIsAddUserModalVisible] = useState(false);

  useEffect(() => {
    if (vendor) {
      if (vendor.isFirm) {
        dispatch(UsersActions.listUsersByFirmRequest(vendor.value));

        return;
      }
      dispatch(
        UsersActions.listUsersByVendorRequest({
          vendorId: vendor.value,
        }),
      );
    }
  }, [vendor, dispatch]);

  useEffect(() => {
    const currentRoleGroupName = findUserRoleGroupName(vendor);
    if (vendor && currentRoleGroupName) {
      dispatch(UserActions.getRolesByRoleGroupRequest(currentRoleGroupName));
    }
  }, [vendor, dispatch]);

  const handleAddNewUserSave = () => {
    setIsAddUserModalVisible(false);
  };

  const handleAddNewUserClose = () => {
    setIsAddUserModalVisible(false);
  };

  const openAddNewUserModal = () => {
    if (vendor) {
      setIsAddUserModalVisible(true);
    } else {
      open('warning', t('notif.warnTitle'), t('users:selectVendorInfo'));
    }
  };

  return (
    <Layout>
      <S.PageHeader>
        <S.PageTitle>{t('users:users')}</S.PageTitle>
        <Button type="primary" onClick={openAddNewUserModal}>
          {t('users:addNew')}
        </Button>
      </S.PageHeader>
      <S.UsersBody>
        <List />
      </S.UsersBody>
      <AddNewUserModal
        modalRef={addUserModalRef}
        size="800px"
        visible={isAddUserModalVisible}
        onSave={handleAddNewUserSave}
        onClose={handleAddNewUserClose}
      />
    </Layout>
  );
};

export default Users;
