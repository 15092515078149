export const BOTTLE_RETURN_WITH_SAME_BRAND = 'BOTTLE_RETURN_WITH_SAME_BRAND';

export const BOTTLE_RETURN_WITH_DIFFERENT_BRAND =
  'BOTTLE_RETURN_WITH_DIFFERENT_BRAND';

export const NO_DEPOSIT_REQUIRED = 'NO_DEPOSIT_REQUIRED';

export const WITHOUT_BOTTLE_RETURN = 'WITHOUT_BOTTLE_RETURN';

export const ONLY_PRICE_MANUAL = 'ONLY_PRICE_MANUAL';
export const MAX_PRICE_VALUE = 9999.99;
