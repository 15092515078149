export const createAction = type => data => {
  if (data !== undefined) {
    return {
      type,
      payload: data,
    };
  }

  return {
    type,
  };
};
