import styled from 'styled-components';

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px;
  gap: 24px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 2px 4px -1px rgba(28, 55, 90, 0.08),
    0px 3px 12px -1px rgba(28, 52, 84, 0.13);
`;
