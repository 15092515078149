import { deleteRequest, getRequest, postRequest, putRequest } from 'lib/axios';

import api from './apiRoutes';

export const listBrandsApi = args => getRequest(api.listBrands, args);
export const addBrandApi = args => postRequest(api.addBrand, args);
export const updateBrandApi = args => putRequest(api.updateBrand, args);
export const getBrandProductApi = args =>
  getRequest(api.getBrandProducts(args));
export const saveOrderBrandProductApi = args =>
  postRequest(api.saveOrderBrandProducts, args);
export const deleteBrandApi = args => deleteRequest(api.deleteBrand(args));
export const getBrandAvailableHoursApi = args =>
  getRequest(api.getBrandAvailableHours(args));
export const updateBrandAvailableHoursApi = ({ id, payload }) =>
  putRequest(api.updateBrandAvailableHours(id), payload);
