import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from '@redux-devtools/extension';
import * as Sentry from '@sentry/react';

import appReducer from './reducers';
import rootSaga from './sagas';
import { CLEAR_ALL_DATA } from './rootTypes';

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];
const sentryReduxEnhancer = Sentry.createReduxEnhancer();

const rootReducer = (state, action) => {
  if (action.type === CLEAR_ALL_DATA) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

const store = createStore(
  rootReducer,
  {},
  composeWithDevTools(applyMiddleware(...middlewares), sentryReduxEnhancer),
);

sagaMiddleware.run(rootSaga);

export default store;
