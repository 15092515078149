import styled from 'styled-components';
import {
  Input,
  Modal as ModalComponent,
  Divider as StyledDivider,
  Button,
} from '@getir/web-components';

import breakpoint from 'constants/breakpoint';

const StyledModal = styled(ModalComponent)`
  ${ModalComponent.S.ModalContent} {
    overflow: auto;
  }

  ${ModalComponent.S.Modal} {
    @media (max-width: ${breakpoint.MOBILE.MAX}px) {
      width: 100vw;
    }
  }
`;

const PageHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0;

  ${Button.S.Wrapper} {
    border-radius: 0;
  }
`;

const PageTitle = styled.p`
  margin-bottom: 0;
  font-size: 24px;
`;

const UsersBody = styled.div`
  background: rgb(255, 255, 255);
  box-shadow: rgb(206 206 206 / 25%) 4px 4px 30px;
  padding: 24px;
  width: 100%;

  ${Button.S.Wrapper} {
    border-radius: 0;
  }
`;

const NewUserModalForm = styled.form`
  display: grid;
  grid-gap: 12px;

  ${StyledDivider.S.Divider} {
    border: 1px solid rgba(0, 0, 0, 0.06);
  }
`;

const FormBody = styled.div`
  display: grid;
  gap: 16px;

  ${Input.S.Input} {
    border: none;
  }
`;

const FormFooter = styled.div`
  display: grid;
  gap: 16px;
  justify-content: flex-end;

  ${Button.S.Wrapper} {
    border-radius: 0;
  }
`;

const ErrorMessage = styled.span`
  display: inline-block;
  color: rgb(219, 71, 30);
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.16px;
  margin-top: 8px;
`;

export {
  StyledModal,
  PageHeader,
  UsersBody,
  FormBody,
  ErrorMessage,
  NewUserModalForm,
  FormFooter,
  PageTitle,
};
