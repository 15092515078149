import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { Space, Divider } from 'antd';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from 'components/v1/Button';
import InputWrapper from 'components/InputWrapper';
import SelectBoxWrapper from 'components/SelectBoxWrapper';
import translate from 'utils/translate';

import * as S from './style';

const validationRules = {
  note: {},
  reason: {
    required: translate('requiredFieldText'),
  },
};

const defaultValues = {
  note: '',
  reason: '',
};

const SaleModal = ({ onClose, getData }) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const reasons = [
    {
      label: i18next.t('vendor:saleModal.reasonWorkingHours'),
      value: i18next.t('vendor:saleModal.reasonWorkingHours'),
    },
    {
      label: i18next.t('vendor:saleModal.reasonLowPerformance'),
      value: i18next.t('vendor:saleModal.reasonLowPerformance'),
    },
    {
      label: i18next.t('vendor:saleModal.reasonDicipline'),
      value: i18next.t('vendor:saleModal.reasonDicipline'),
    },
    {
      label: i18next.t('vendor:saleModal.reasonUnavailable'),
      value: i18next.t('vendor:saleModal.reasonUnavailable'),
    },
    {
      label: i18next.t('vendor:saleModal.reasonVendorReq'),
      value: i18next.t('vendor:saleModal.reasonVendorReq'),
    },
    {
      label: i18next.t('vendor:saleModal.reasonFirmReq'),
      value: i18next.t('vendor:saleModal.reasonFirmReq'),
    },
    {
      label: i18next.t('vendor:saleModal.reasonOther'),
      value: i18next.t('vendor:saleModal.reasonOther'),
    },
  ];

  const onSubmit = data => {
    getData(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <S.FormBodyWrapper>
        <InputWrapper
          fieldName="note"
          control={control}
          rules={validationRules.note}
          placeholder={t('vendor:saleModal.note')}
          header={t('vendor:saleModal.note')}
          errors={errors}
        />

        <SelectBoxWrapper
          fieldName="reason"
          control={control}
          rules={validationRules.reason}
          placeholder={t('vendor:saleModal.reason')}
          errors={errors}
          options={reasons}
        />
      </S.FormBodyWrapper>

      <Divider />
      <S.FormFooterWrapper>
        <Space size="middle">
          <Button style={{ width: '100%' }} kind="secondary" onClick={onClose}>
            {t('giveUp')}
          </Button>
          <Button style={{ width: '100%' }} type="submit">
            {t('save')}
          </Button>
        </Space>
      </S.FormFooterWrapper>
    </form>
  );
};

SaleModal.defaultProps = {
  onClose: () => {},
  getData: () => {},
};

SaleModal.propTypes = {
  onClose: PropTypes.func,
  getData: PropTypes.func,
};

export default SaleModal;
