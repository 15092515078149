import React, { useEffect, useRef, useState } from 'react';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'components/v1/Button';
import Table from 'components/v1/Table';

import EditPermModal from '../components/EditPermModal';
import * as PermsActions from '../actions';

const addChildrenFieldIntoArray = list =>
  list.map(item => ({
    ...item,
    key: item.id,
    children: [],
  }));

const updateWrappedPermListWithPerms = (allList, matchedPerm, childPerms) =>
  allList.map(perm => {
    if (perm.id === matchedPerm.id) {
      return {
        ...perm,
        children: addChildrenFieldIntoArray(childPerms),
      };
    }

    return {
      ...perm,
      children: updateWrappedPermListWithPerms(
        perm.children,
        matchedPerm,
        childPerms,
      ),
    };
  });

const List = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedPerm, setSelectedPerm] = useState(undefined);
  const [isEditPermModalVisible, setIsEditPermModalVisible] = useState(false);
  const [wrappedPermList, setWrappedPermList] = useState([]);

  const { data: permList } = useSelector(state => state.authPerms.permList);
  const { data: updatePerm } = useSelector(state => state.authPerms.updatePerm);
  const { data: permChildren } = useSelector(
    state => state.authPerms.permChildren,
  );
  const editPermModalRef = useRef();
  const [clickedPerm, setClickedPerm] = useState(undefined);

  useEffect(() => {
    dispatch(PermsActions.getPermListRequest());
  }, [dispatch, updatePerm]);

  useEffect(() => {
    if (permList) {
      setWrappedPermList(addChildrenFieldIntoArray(permList));
    }
  }, [permList]);

  useEffect(() => {
    if (permChildren) {
      const updatedList = updateWrappedPermListWithPerms(
        wrappedPermList,
        clickedPerm,
        permChildren,
      );
      setWrappedPermList(updatedList);
    }
  }, [permChildren]); // eslint-disable-line

  const columns = [
    {
      title: t('authPermsPage:tableColumns.name'),
      dataIndex: 'title',
      key: 'title',
      sorter: (a, b) => a.title.localeCompare(b.title),
      defaultSortOrder: 'ascend',
    },
    {
      title: t('authPermsPage:tableColumns.desc'),
      dataIndex: 'componentDesc',
      key: 'componentDesc',
    },
    {
      title: t('actions'),
      dataIndex: '',
      key: 'x',
      render: (_, record) => (
        <Button kind="secondary" onClick={() => handleEditClick(record)}>
          {t('edit')}
        </Button>
      ),
      align: 'center',
      width: 50,
      fixed: 'right',
    },
  ];

  const handleEditPermModalClose = () => {
    setIsEditPermModalVisible(false);
  };

  const handleEditClick = record => {
    setIsEditPermModalVisible(true);
    setSelectedPerm(record);
  };

  const handleOnExpandClick = (expanded, record) => {
    setClickedPerm(record);
    const { id, children } = record;
    if (expanded && children.length === 0) {
      dispatch(PermsActions.getPermListChildrenRequest(id));
    }
  };

  return (
    <>
      <Table
        dataSource={wrappedPermList}
        columns={columns}
        pagination={{ size: 'small' }}
        scroll={{ x: 'max-content' }}
        bordered
        locale={{ emptyText: <Empty description={t('table.noData')} /> }}
        rowKey="id"
        expandable={{
          onExpand: handleOnExpandClick,
        }}
      />
      <EditPermModal
        modalRef={editPermModalRef}
        size="800px"
        visible={isEditPermModalVisible}
        onClose={handleEditPermModalClose}
        perm={selectedPerm}
      />
    </>
  );
};

export default List;
