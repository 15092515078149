import React from 'react';
import PropTypes from 'prop-types';

const ClockIcon = ({ fill, width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 14"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.99992 0.333344C3.31802 0.333344 0.333252 3.31811 0.333252 7.00001C0.333252 10.6819 3.31802 13.6667 6.99992 13.6667C10.6818 13.6667 13.6666 10.6819 13.6666 7.00001C13.6666 3.31811 10.6818 0.333344 6.99992 0.333344ZM7.51274 2.89745C7.51274 2.61422 7.28314 2.38463 6.99992 2.38463C6.7167 2.38463 6.4871 2.61422 6.4871 2.89745V7.00001C6.4871 7.28323 6.7167 7.51283 6.99992 7.51283H10.0768C10.3601 7.51283 10.5897 7.28323 10.5897 7.00001C10.5897 6.71679 10.3601 6.48719 10.0768 6.48719H7.51274V2.89745Z"
      fill="inherit"
    />
  </svg>
);

ClockIcon.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

ClockIcon.defaultProps = {
  fill: '#97670F',
  width: 16,
  height: 16,
};

export default ClockIcon;
