import styled from 'styled-components';

import breakpoint from 'constants/breakpoint';

const MainHeaderWrapper = styled.div`
  height: auto;
  padding: 16px 0px;
  display: flex;
  gap: 12px;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;

  @media (max-width: ${breakpoint.MOBILE.MAX}px) {
    background-color: #fff;
    grid-template-columns: 1fr;
    row-gap: 16px;
    -webkit-box-pack: center;
    justify-content: center;
  }
`;

const PrefixIcon = styled.div`
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 8px;
`;

const MainHeaderArea = styled.div`
  width: 200px;
  position: relative;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  .ant-select-clear {
    background: #7849f7 !important;
    &:hover {
      svg {
        fill: #fff !important;
      }
    }
    svg {
      fill: #ffffff60 !important;
    }
  }

  .ant-select .ant-select-selector {
    padding-left: 30px;
    height: 36px !important;
  }
  .ant-select-selection-search {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 19px;
  }
  .ant-select-selection-placeholder {
    color: #fff !important;
  }

  .ant-select {
    width: 100%;
    height: 36px !important;
  }
  .ant-select-selector {
    border-radius: 4px !important;
    background-color: #7849f7 !important;
    border: none !important;
    color: #fff !important;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 400 !important;
    fill: #fff !important;
    display: flex;
    padding: 8px 7px;
    align-items: center;
    gap: 8px;
    &:hover {
      border: none !important;
    }
  }
  .ant-select-selection-search {
  }
  .ant-select-selection-item {
    color: #fff !important;
  }
  .ant-select-item {
    padding: 4px 8px !important;
    line-height: normal !important;
  }
  .ant-select-item-option {
    height: 32px !important;
    display: flex;
    align-items: center;
    justify-items: center;
    &:hover {
      background-color: #00000030 !important;
    }
  }
  .ant-select-item-option-selected {
    background-color: #00000030 !important;
    color: #fff !important;
    border-radius: 0px !important;
  }
  .ant-select-item-option-content {
    font-size: 12px !important;
    font-style: normal;
    font-weight: 400 !important;
    color: #fff !important;
    height: 32px !important;

    &:hover {
      color: #fff !important;
    }
  }
  .ant-select-arrow {
    color: #fff !important;
  }
  .ant-select-dropdown {
    background-color: #7849f7 !important;
    border: none !important;
  }
`;

const MainHeaderToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  place-self: center end;
`;

export {
  MainHeaderWrapper,
  MainHeaderArea,
  MainHeaderToggleWrapper,
  PrefixIcon,
};
