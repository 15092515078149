const ORDER_STATUS = Object.freeze({
  INCOMPLETE: 100,
  ABORTED: 200,
  BROWSING: 300,
  SCHEDULED_VERIFYING: 325,
  SCHEDULED: 350,
  VERIFYING: 400,
  PREPARING: 500,
  PREPARED: 550,
  HANDOVER: 600,
  ONWAY: 700,
  REACHED: 800,
  DELIVERED: 900,
  RATED: 1000,
  CANCELED_ADMIN: 1500,
  CANCELED_VENDOR: 1600,
});

const ORDER_STATUS_TRANSLATEKEY = Object.freeze({
  INCOMPLETE: 'common:orderStatuses.incomplete',
  ABORTED: 'common:orderStatuses.aborted',
  BROWSING: 'common:orderStatuses.browsing',
  SCHEDULED_VERIFYING: 'common:orderStatuses.scheduledverifying',
  SCHEDULED: 'common:orderStatuses.scheduled',
  VERIFYING: 'common:orderStatuses.verifying',
  PREPARING: 'common:orderStatuses.preparing',
  PREPARED: 'common:orderStatuses.prepared',
  HANDOVER: 'common:orderStatuses.handover',
  ONWAY: 'common:orderStatuses.onway',
  REACHED: 'common:orderStatuses.reached',
  DELIVERED: 'common:orderStatuses.delivered',
  RATED: 'common:orderStatuses.rated',
  CANCELED_ADMIN: 'common:orderStatuses.canceledadmin',
  CANCELED_VENDOR: 'common:orderStatuses.canceledvendor',
});

const ORDER_STATUS_CODES = Object.freeze({
  100: 'INCOMPLETE',
  200: 'ABORTED',
  300: 'BROWSING',
  325: 'SCHEDULED_VERIFYING',
  350: 'SCHEDULED',
  400: 'VERIFYING',
  500: 'PREPARING',
  550: 'PREPARED',
  600: 'HANDOVER',
  700: 'ONWAY',
  800: 'REACHED',
  900: 'DELIVERED',
  1000: 'RATED',
  1500: 'CANCELED_ADMIN',
  1600: 'CANCELED_VENDOR',
});

export { ORDER_STATUS, ORDER_STATUS_CODES, ORDER_STATUS_TRANSLATEKEY };
