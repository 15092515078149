import env from 'config/env';

export const REPORT_TYPES = Object.freeze({
  KUZEYDEN_SALES_REPORT: env.REACT_APP_KUZEYDEN_SALES_REPORT_ID,
  KUZEYDEN_STOCK_REPORT: env.REACT_APP_KUZEYDEN_STOCK_REPORT_ID,
});

export const REPORT_TYPES_ARRAY = Object.freeze([
  {
    value: env.REACT_APP_KUZEYDEN_SALES_REPORT_ID,
    label: 'Ürün Satış Raporu',
  },
  { value: env.REACT_APP_KUZEYDEN_STOCK_REPORT_ID, label: 'Stok Raporu' },
]);

export const DOMAIN_TYPES = Object.freeze({
  GETIR10: 1,
  MARKET: 3,
  WATER: 4,
});

export const DOMAIN_TYPES_ARRAY = Object.freeze([
  { value: 1, label: 'Getir10' },
  { value: 3, label: 'GetirBüyük' },
  { value: 4, label: 'GetirSu' },
]);

export const REQUEST_STATUSES = Object.freeze({
  FAILURE: 0,
  SUCCESS: 1,
  IN_PROG: 2,
});

export const OWNER_TYPE = 'water';
