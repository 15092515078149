import { createAction } from 'utils/createAction';

import * as Types from './types';

// send sms courier
export const sendSmsCourierRequest = createAction(
  Types.SEND_SMS_COURIER_REQUEST,
);
export const sendSmsCourierSuccess = createAction(
  Types.SEND_SMS_COURIER_SUCCESS,
);
export const sendSmsCourierError = createAction(Types.SEND_SMS_COURIER_ERROR);
export const sendSmsCourierReset = createAction(Types.SEND_SMS_COURIER_RESET);

export const sendSmsModalOpen = createAction(Types.SEND_SMS_MODAL_OPEN);
export const sendSmsModalClose = createAction(Types.SEND_SMS_MODAL_CLOSE);

// get order detail
export const getOrderDetailRequest = createAction(Types.ORDER_DETAIL_REQUEST);
export const getOrderDetailSuccess = createAction(Types.ORDER_DETAIL_SUCCESS);
export const getOrderDetailError = createAction(Types.ORDER_DETAIL_ERROR);
export const resetOrderDetail = createAction(Types.ORDER_DETAIL_RESET);

// get courier list
export const getCourierListRequest = createAction(
  Types.GET_COURIER_LIST_REQUEST,
);
export const getCourierListSuccess = createAction(
  Types.GET_COURIER_LIST_SUCCESS,
);
export const getCourierListError = createAction(Types.GET_COURIER_LIST_ERROR);
export const resetCourierList = createAction(Types.GET_COURIER_LIST_RESET);
