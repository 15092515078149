import React from 'react';
import { noop, range } from 'lodash';
import dayjs from 'dayjs';

import * as CS from 'common/style';

import * as S from './style';
import {
  Day,
  defaultNumberOfIntervals,
  NumberWord,
  TableMode,
  defaultTimeFormat,
  TimeType,
  fullTimeFormat,
} from './constants';
import { findIsIntervalMixed } from './utils/common';

const hasAnyData = alternativeMapIntervals =>
  alternativeMapIntervals.length > 0;
const isViewMode = tableMode => tableMode === TableMode.VIEW;

const createCustomTimePicker = (
  options = {
    t: (key = '') => key,
    time: '',
    customTimeFormat: defaultTimeFormat,
    handleTimePicker: noop,
  },
) => (
  <S.CustomTimePicker
    placeholder={options.t('geomap:alternativeMapHour.datePickerPlaceholder')}
    showNow={false}
    format={options.customTimeFormat || defaultTimeFormat}
    value={options.time && dayjs(options.time, defaultTimeFormat)}
    onSelect={options.handleTimePicker}
    onChange={options.handleTimePicker}
  />
);

const getTimePickerHandler = (
  timePickerIndex,
  intervalIndex,
  dayIndex,
  setAlternativeMapIntervals,
) => value => {
  setAlternativeMapIntervals(prev => {
    const clonedPrev = [...prev];
    clonedPrev[dayIndex].intervals[intervalIndex][TimeType[timePickerIndex]] =
      value && value.format(fullTimeFormat);

    return clonedPrev;
  });
};

const createIntervalColumnsWithTitleAndButtons = options => {
  const {
    t,
    alternativeMapIntervals,
    tableMode,
    setAlternativeMapIntervals,
  } = options;
  const titleColumn = hasAnyData(alternativeMapIntervals) && {
    title: t('geomap:alternativeMapHour.days'),
    width: 150,
    dataIndex: 'dayOfWeek',
    key: 'dayOfWeek',
    render: text => `${text ? t(Day[text]) : ''}`,
  };

  const intervalColumns = range(
    0,
    hasAnyData(alternativeMapIntervals) && options.numberOfIntervals,
  ).map(index => {
    const intervalVal = `interval${NumberWord[index + 1]}`;

    return {
      title: () => {
        const titleText = t(`geomap:alternativeMapHour.${intervalVal}`);
        const [chunk1, chunk2] = titleText.split(/\//);

        return (
          <>
            <div>{chunk1}</div>
            <div>{chunk2}</div>
          </>
        );
      },
      width: 200,
      dataIndex: intervalVal,
      key: intervalVal,
      render: (text, _, dayIndex) => {
        if (isViewMode(tableMode)) {
          return <div>{text}</div>;
        }
        const timePickerTuple =
          alternativeMapIntervals[dayIndex].intervals[index];
        const { startTime, endTime } = timePickerTuple;
        const shouldShowErrorMessage =
          findIsIntervalMixed({ startTime, endTime }) &&
          options.shouldShowTimePickerErrors;
        const getTimePickerHandlerWithIndex = timePickerIndex =>
          getTimePickerHandler(
            timePickerIndex,
            index,
            dayIndex,
            setAlternativeMapIntervals,
          );

        return (
          <>
            {createCustomTimePicker({
              t,
              time: startTime,
              handleTimePicker: getTimePickerHandlerWithIndex(0),
            })}
            {createCustomTimePicker({
              t,
              time: endTime,
              handleTimePicker: getTimePickerHandlerWithIndex(1),
            })}
            {shouldShowErrorMessage && (
              <CS.ErrorMessage>
                {t('geomap:alternativeMapHour.mixedTimeError')}
              </CS.ErrorMessage>
            )}
          </>
        );
      },
    };
  });

  const hasAnyDataAndIsViewMode =
    hasAnyData(alternativeMapIntervals) && isViewMode(tableMode);
  const editButton = hasAnyDataAndIsViewMode && {
    title: () => (
      <CS.FullWidthButton
        onClick={() => options.setTableMode(TableMode.EDIT)}
        kind="secondary"
      >
        {t('edit')}
      </CS.FullWidthButton>
    ),
    width: 100,
    dataIndex: 'edit',
    key: 'edit',
  };

  return [titleColumn, ...intervalColumns, editButton].filter(Boolean);
};

const getColumns = (
  options = {
    t: (key = '') => key,
    numberOfIntervals: defaultNumberOfIntervals,
    tableMode: TableMode.VIEW,
    setTableMode: noop,
    shouldShowTimePickerErrors: false,
    alternativeMapIntervals: [],
    setAlternativeMapIntervals: noop,
  },
) => [...createIntervalColumnsWithTitleAndButtons({ ...options })];

export default getColumns;
