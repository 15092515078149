import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const OpeningContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 16px 8px;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  background: #fff;
`;

export const IconContainer = styled.div`
  display: flex;
  padding: 8px;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  gap: 8px;
  background: #f8f8f8;
  flex-shrink: 0;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const Title = styled.span`
  color: #191919;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
`;

export const InfoText = styled.span`
  color: #697488;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
`;
