import * as Types from './types';

const initialState = {
  regionManagers: {},
  operationalManagers: {},
  firms: [],
  vendorList: [],
  error: {},
  addVendor: '',
  editVendor: undefined,
  deleteVendor: '',
  vendorId: undefined,
  openSale: '',
  closeSale: '',
  districts: undefined,
  towns: undefined,
  brands: undefined,
  updateFinancialInfo: undefined,
};

function VendorReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.VENDOR_LIST_SUCCESS:
      return {
        ...state,
        vendorList: payload.firmVendors,
      };
    case Types.VENDOR_LIST_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.ADD_VENDOR_SUCCESS:
      return {
        ...state,
        addVendor: payload,
      };
    case Types.ADD_VENDOR_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.EDIT_VENDOR_SUCCESS:
      return {
        ...state,
        editVendor: payload,
      };
    case Types.EDIT_VENDOR_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.EDIT_FINANCIAL_INFO_SUCCESS:
      return {
        ...state,
        updateFinancialInfo: payload,
      };
    case Types.EDIT_FINANCIAL_INFO_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.DELETE_VENDOR_SUCCESS: {
      return {
        ...state,
        deleteVendor: payload,
      };
    }
    case Types.DELETE_VENDOR_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.CLOSE_FOR_SALE_SUCCESS: {
      return {
        ...state,
        closeSale: payload,
      };
    }
    case Types.CLOSE_FOR_SALE_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.OPEN_FOR_SALE_SUCCESS: {
      return {
        ...state,
        openSale: payload,
      };
    }
    case Types.OPEN_FOR_SALE_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.SET_VENDOR_ID:
      return {
        ...state,
        vendorId: payload,
      };
    case Types.GET_DISTRICTS_SUCCESS:
      return {
        ...state,
        districts: payload,
      };
    case Types.GET_DISTRICTS_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.GET_TOWNS_SUCCESS:
      return {
        ...state,
        towns: payload,
      };
    case Types.GET_TOWNS_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.GET_BRANDS_SUCCESS:
      return {
        ...state,
        brands: payload,
      };
    case Types.GET_BRANDS_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.GET_FIRM_SUCCESS:
      return {
        ...state,
        firms: payload.firms,
      };
    case Types.GET_FIRM_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.GET_OPERATIONAL_MANAGER_SUCCESS:
      return {
        ...state,
        operationalManagers: payload,
      };
    case Types.GET_OPERATIONAL_MANAGER_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.GET_REGION_MANAGER_SUCCESS:
      return {
        ...state,
        regionManagers: payload,
      };
    case Types.GET_REGION_MANAGER_ERROR:
      return {
        ...state,
        error: payload,
      };
    default:
      return state;
  }
}

export default VendorReducer;
