import { createAction } from 'utils/createAction';

import * as Types from './types';

// list vendors
export const vendorListRequest = createAction(Types.VENDOR_LIST_REQUEST);
export const vendorListSuccess = createAction(Types.VENDOR_LIST_SUCCESS);
export const vendorListError = createAction(Types.VENDOR_LIST_SUCCESS);

// add new vendor
export const addVendorRequest = createAction(Types.ADD_VENDOR_REQUEST);
export const addVendorSuccess = createAction(Types.ADD_VENDOR_SUCCESS);
export const addVendorError = createAction(Types.ADD_VENDOR_ERROR);

// update vendor
export const updateVendorRequest = createAction(Types.EDIT_VENDOR_REQUEST);
export const updateVendorSuccess = createAction(Types.EDIT_VENDOR_SUCCESS);
export const updateVendorError = createAction(Types.EDIT_VENDOR_SUCCESS);

// update financial info

export const updateFinancialInfoRequest = createAction(
  Types.EDIT_FINANCIAL_INFO_REQUEST,
);
export const updateFinancialInfoSuccess = createAction(
  Types.EDIT_FINANCIAL_INFO_SUCCESS,
);
export const updateFinancialInfoError = createAction(
  Types.EDIT_FINANCIAL_INFO_ERROR,
);

// delete vendor
export const deleteVendorRequest = createAction(Types.DELETE_VENDOR_REQUEST);
export const deleteVendorSuccess = createAction(Types.DELETE_VENDOR_SUCCESS);
export const deleteVendorError = createAction(Types.DELETE_VENDOR_ERROR);

// close for sale
export const closeForSaleRequest = createAction(Types.CLOSE_FOR_SALE_REQUEST);
export const closeForSaleSuccess = createAction(Types.CLOSE_FOR_SALE_SUCCESS);
export const closeForSaleError = createAction(Types.CLOSE_FOR_SALE_ERROR);

// open for sale
export const openForSaleRequest = createAction(Types.OPEN_FOR_SALE_REQUEST);
export const openForSaleSuccess = createAction(Types.OPEN_FOR_SALE_SUCCESS);
export const openForSaleError = createAction(Types.OPEN_FOR_SALE_ERROR);

// get districts
export const getDistrictsRequest = createAction(Types.GET_DISTRICTS_REQUEST);
export const getDistrictsSuccess = createAction(Types.GET_DISTRICTS_SUCCESS);
export const getDistrictsError = createAction(Types.GET_DISTRICTS_ERROR);

// get towns
export const getTownsRequest = createAction(Types.GET_TOWNS_REQUEST);
export const getTownsSuccess = createAction(Types.GET_TOWNS_SUCCESS);
export const getTownsError = createAction(Types.GET_TOWNS_ERROR);

// get brands
export const getBrandsRequest = createAction(Types.GET_BRANDS_REQUEST);
export const getBrandsSuccess = createAction(Types.GET_BRANDS_SUCCESS);
export const getBrandsError = createAction(Types.GET_BRANDS_ERROR);
// get firms
export const getFirmRequest = createAction(Types.GET_FIRM_REQUEST);
export const getFirmSuccess = createAction(Types.GET_FIRM_SUCCESS);
export const getFirmError = createAction(Types.GET_FIRM_ERROR);

// get OperationalManagers
export const getOperationalManagerRequest = createAction(
  Types.GET_OPERATIONAL_MANAGER_REQUEST,
);
export const getOperationalManagersSuccess = createAction(
  Types.GET_OPERATIONAL_MANAGER_SUCCESS,
);
export const getOperationalManagersError = createAction(
  Types.GET_OPERATIONAL_MANAGER_ERROR,
);

// get Region Managers
export const getRegionManagerRequest = createAction(
  Types.GET_REGION_MANAGER_REQUEST,
);
export const getRegionManagerSuccess = createAction(
  Types.GET_REGION_MANAGER_SUCCESS,
);
export const getRegionManagerError = createAction(
  Types.GET_REGION_MANAGER_ERROR,
);

// set vendor id
export const setVendorId = createAction(Types.SET_VENDOR_ID);
