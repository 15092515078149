import { createAction } from 'utils/createAction';

import * as Types from './types';

export const updatePaymentMethodsRequest = createAction(
  Types.UPDATE_PAYMENTMETHODS_REQUEST,
);
export const updatePaymentMethodsSuccess = createAction(
  Types.UPDATE_PAYMENTMETHODS_SUCCESS,
);
export const updatePaymentMethodsError = createAction(
  Types.UPDATE_PAYMENTMETHODS_ERROR,
);
