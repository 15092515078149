import cookies from 'js-cookie';

const cookie = {};

function remove(cookieName) {
  cookies.remove(cookieName);
}

function removeCookieList(cookieList) {
  cookieList.forEach(cookieName => {
    cookies.remove(cookieName);
  });
}

function get(cookieName) {
  return cookies.get(cookieName);
}

function set(name, value, options) {
  cookies.set(name, value, {
    path: '/',
    expires: getExpireDate(),
    ...options,
  });
}

cookie.get = get;
cookie.set = set;
cookie.remove = remove;
cookie.removeCookieList = removeCookieList;

export default cookie;
export function getExpireDate(dateCount = 30 * 12) {
  return 60 * 60 * 24 * dateCount;
}
