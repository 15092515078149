import styled from 'styled-components';

import { PageHeader, PageTitle, PageContent } from 'common/style';

const AlertWrapper = styled.div`
  margin-bottom: 24px;
`;

const StyledStrikethroughPriceWrapper = styled.div`
  border: 2px solid #dfdee2;
  border-radius: 4px;
  padding: 1px 14px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f5f5f5;
`;

const StyledSpacer = styled.div`
  width: 16px;
  display: inline-block;
`;

export {
  PageHeader,
  PageTitle,
  PageContent,
  AlertWrapper,
  StyledStrikethroughPriceWrapper,
  StyledSpacer,
};
export { StyledToggle } from 'common/style';
