import { email } from '@sideway/address';

const validations = t => ({
  fullName: {
    required: t('requiredFieldText'),
  },
  email: {
    required: t('requiredFieldText'),
    validate: value => {
      const isValidEmail = email.isValid(value);

      return isValidEmail || t('emailValid');
    },
  },
  role: {},
});

export { validations };
