import React, { useEffect, useMemo, useState } from 'react';
import { Empty } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Table from 'components/v1/Table';
import Layout from 'components/Layout';
import * as CS from 'common/style';
import ConfirmModal from 'components/ConfirmModal';
import * as MainHeaderActions from 'components/MainHeader/actions';
import Info from 'components/v1/Info';
import Toggle from 'components/v1/Toggle';
import { ClockIcon } from 'components/v1/Icons';

import * as WorkingHoursActions from './actions';
import { getColumns } from './getColumns';
import { getDataSource } from './getDataSource';
import * as S from './styles';

const WorkingHours = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['common', 'workinghours']);
  const vendorWorkingHours = useSelector(
    state => state.mainHeader.vendorInfo.workingHours,
  );

  const isAutoOpenEnabled = useSelector(
    state => state.workingHours.isAutoOpenEnabled,
  );
  const isAutoOpenLoading = useSelector(
    state => state.workingHours.isAutoOpenLoading,
  );
  const userPerms = useSelector(state => state.login.userPerms);

  const { canEdit } = useMemo(
    () =>
      userPerms.data.find(
        perms => perms.componentId === 'vendor_auto_opening_switch',
      ) || {},
    [userPerms],
  );

  const vendorId = useSelector(state => state.mainHeader.vendorInfo.id);
  const [editActive, setEditActive] = useState(false);
  const [closeVendorModalActive, setCloseVendorModalActive] = useState(false);
  const [data, setData] = useState([]);
  const [currentDayIndex, setCurrentDayIndex] = useState(0);

  useEffect(() => {
    if (vendorId) {
      dispatch(MainHeaderActions.getVendorInfoRequest(vendorId));
      dispatch(WorkingHoursActions.getAutoOpenRequest(vendorId));
    }
  }, [dispatch, vendorId]);

  useEffect(() => {
    if (vendorWorkingHours && vendorWorkingHours.length === 0) {
      setData(getDataSource());

      return;
    }
    setData(vendorWorkingHours);
  }, [vendorWorkingHours]);

  useEffect(() => {
    if (editActive) {
      const actionsRow = {
        dayOfWeek: 8,
        startTime: '',
        endTime: '',
      };

      if (vendorWorkingHours.length === 0) {
        const updatedVendorWorkingHours = [...getDataSource()];
        updatedVendorWorkingHours.push(actionsRow);
        setData(updatedVendorWorkingHours);

        return;
      }

      const updatedVendorWorkingHours = [...vendorWorkingHours];
      updatedVendorWorkingHours.push(actionsRow);
      setData(updatedVendorWorkingHours);
    } else {
      setData(vendorWorkingHours);
    }
    // eslint-disable-next-line
  }, [editActive]);

  const setDayData = (dayIndex, key, val) => {
    const tmpData = [...data];
    tmpData[dayIndex][key] = val.value;
    setData(tmpData);
  };

  const setToAllDays = () => {
    const tmpData = [...data];
    setData(
      tmpData.map(item => {
        const tmpItem = { ...item };
        if (tmpItem.dayOfWeek < 8) {
          tmpItem.startTime = tmpData[0].startTime;
          tmpItem.endTime = tmpData[0].endTime;
        }

        return tmpItem;
      }),
    );
  };

  const closeDay = dayIndex => {
    setCurrentDayIndex(dayIndex);
    setCloseVendorModalActive(true);
  };

  const handleCloseVendorModalSave = () => {
    const tmpData = [...data];
    tmpData[currentDayIndex].startTime = null;
    tmpData[currentDayIndex].endTime = null;
    setData(tmpData);
    setCloseVendorModalActive(false);
  };

  const saveWorkingHours = () => {
    const workingHour = data
      .map(item => {
        if (item.startTime !== '') {
          return {
            dayOfWeek: item.dayOfWeek,
            startTime: item.startTime,
            endTime: item.endTime,
          };
        }

        return null;
      })
      .filter(item => item !== null);
    const formData = {
      vendorId,
      workingHour,
    };

    dispatch(WorkingHoursActions.updateWorkingHoursRequest(formData));
    setEditActive(false);
  };

  const cancelEvent = () => {
    dispatch(MainHeaderActions.getVendorInfoRequest(vendorId));
    setEditActive(false);
  };

  const handleAutoOpenChange = value => {
    if (!canEdit) return;
    dispatch(WorkingHoursActions.updateAutoOpenRequest({ isOpen: value }));
  };

  return (
    <Layout>
      <CS.PageHeader>
        <CS.PageTitle>{t('workinghours:pageTitle')}</CS.PageTitle>
      </CS.PageHeader>
      <S.Container>
        <Info kind="warning" isCloseable>
          {t('workinghours:vendorWarningMessage')}
        </Info>
        <S.OpeningContainer>
          <S.IconContainer>
            <ClockIcon width={16} height={16} fill="#5D3EBC" />
          </S.IconContainer>
          <S.TextContainer>
            <S.Title>{t('workinghours:autoOpenTitle')}</S.Title>
            <S.InfoText>{t('workinghours:autoOpenDesc')}</S.InfoText>
          </S.TextContainer>
          <Toggle
            checkedChildren={t('on')}
            unCheckedChildren={t('off')}
            checked={isAutoOpenEnabled}
            size="medium"
            color="#008C20"
            onChange={handleAutoOpenChange}
            loading={isAutoOpenLoading}
            disabled={isAutoOpenLoading || !canEdit}
          />
        </S.OpeningContainer>
        <Table
          dataSource={data}
          columns={getColumns(
            editActive,
            setEditActive,
            setDayData,
            setToAllDays,
            closeDay,
            saveWorkingHours,
            cancelEvent,
            data,
          )}
          pagination={false}
          scroll={{ x: 'max-content' }}
          locale={{ emptyText: <Empty description="Veri Yok" /> }}
          rowKey="dayOfWeek"
        />
        <ConfirmModal
          size="400px"
          visible={closeVendorModalActive}
          onSave={handleCloseVendorModalSave}
          onClose={() => setCloseVendorModalActive(false)}
          title={t('workinghours:closeVendorTitle')}
          content={t('workinghours:closeVendorDescription')}
          cancelText={t('cancel')}
        />
      </S.Container>
    </Layout>
  );
};

export { WorkingHours };
