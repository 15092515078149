import styled from 'styled-components';
import { DatePicker, Table } from 'antd';

import COLORS from 'constants/colors';
const { RangePicker } = DatePicker;

export const FiltersArea = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  width: 100%;
  align-items: flex-end;

  .ant-space {
    grid-column-end: -1;
  }

  .ant-picker {
    margin-top: 3px;
  }
`;

export const CurrentBalanceArea = styled.div`
  padding: 1rem 0 1rem 1rem;
  border-left: 4px solid ${COLORS.mainGetirColor};
  background: #f8f8f8;
  margin: 1rem 0;
  font-weight: 600;
  font-size: 18px;
`;

export const StyledRangePicker = styled(RangePicker)`
  height: 50px;
  border: 2px solid #dfdee2 !important;
  border-radius: 4px !important;
`;

export const StyledTable = styled(Table)`
  .ant-table-thead {
    tr {
      th {
        background: #eaeaea !important;
      }
    }
  }
`;

export const RangePickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
