const validations = t => ({
  roleName: {
    required: t('requiredFieldText'),
  },
  desc: {},
  roleType: {
    required: t('requiredFieldText'),
  },
});

export { validations };
