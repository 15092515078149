import { takeLatest, put, call, select } from 'redux-saga/effects';

import * as api from 'services/VendorCommunication/api';
import translate from 'utils/translate';
import { open } from 'utils/notification';
// import * as ROUTES from 'constants/routes';
import { createStandardSaga } from 'utils/createStandardSaga';
import * as LoaderActions from 'components/Loader/actions';

import * as Types from './types';
import * as VendorCommunicationActions from './actions';

const getVendorThreadsSaga = createStandardSaga({
  callApi: api.getVendorThreads,
  successAction: VendorCommunicationActions.getThreadListSuccess,
  errorAction: VendorCommunicationActions.getThreadListError,
  // successMessage: 'vendorCommunication:threadsGetSuccess',
});

const getThreadDetailSaga = createStandardSaga({
  callApi: api.getMessagesFromThreads,
  successAction: VendorCommunicationActions.getThreadDetailSuccess,
  errorAction: VendorCommunicationActions.getThreadDetailError,
  // successMessage: 'vendorCommunication:threadDetailSuccess',
});

const sendMessageToThread = createStandardSaga({
  callApi: api.sendNewMessageToThread,
  successAction: VendorCommunicationActions.sendMessageSuccess,
  errorAction: VendorCommunicationActions.sendMessageError,
  successMessage: 'vendorCommunication:messageSendSuccess',
});

function* closeThreadSaga({ payload }) {
  yield put(LoaderActions.showLoader());
  const { threadId, isAdmin } = payload;
  try {
    const result = yield call(api.closeThread, threadId);
    if (result.data) {
      yield put(
        VendorCommunicationActions.closeThreadSuccess(result.data.payload),
      );
      const vendorInfo = yield select(state => state.mainHeader.vendor);

      yield put(VendorCommunicationActions.closeMessageModal());

      yield call(getVendorThreadsSaga, {
        payload: {
          vendorId: isAdmin ? undefined : vendorInfo?.value,
          page: 0,
          size: 10,
        },
      });

      return;
    }

    if (result.message) {
      open('warning', translate('common:notif.warnTitle'), result.message);
    }
  } catch (error) {
    yield put(VendorCommunicationActions.closeThreadError(error.message));
  }
  yield put(LoaderActions.hideLoader());
}

function* changeReadStatusSaga({ payload }) {
  try {
    const result = yield call(api.changeReadStatusThread, payload);
    if (result.data) {
      yield put(VendorCommunicationActions.changeReadStatusSuccess(payload));

      return;
    }
  } catch (e) {
    yield put(VendorCommunicationActions.changeReadStatusError(e.message));
  }
}

function* createNewThreadSaga({ payload }) {
  yield put(LoaderActions.showLoader());
  try {
    const result = yield call(api.createNewThread, payload);
    if (result.data) {
      yield put(
        VendorCommunicationActions.createNewThreadSuccess(result.data.payload),
      );
      open('success', '', translate('vendorCommunication:messageSendSuccess'));
      const vendorInfo = yield select(state => state.mainHeader.vendor);

      yield put(VendorCommunicationActions.closeMessageModal());

      yield call(getVendorThreadsSaga, {
        payload: {
          vendorId: vendorInfo?.value ?? undefined,
          page: 0,
          size: 10,
        },
      });

      return;
    }

    if (result.message) {
      open('warning', translate('common:notif.warnTitle'), result.message);
    }
  } catch (error) {
    yield put(VendorCommunicationActions.createNewThreadError(error.message));
  }
  yield put(LoaderActions.hideLoader());
}

export function* rootSaga() {
  yield takeLatest(Types.GET_THREAD_LIST_REQUEST, getVendorThreadsSaga);
  yield takeLatest(Types.CREATE_NEW_THREAD_REQUEST, createNewThreadSaga);
  yield takeLatest(Types.GET_THREAD_DETAIL_REQUEST, getThreadDetailSaga);
  yield takeLatest(Types.SEND_MESSAGE_REQUEST, sendMessageToThread);
  yield takeLatest(Types.CLOSE_THREAD_REQUEST, closeThreadSaga);
  yield takeLatest(Types.CHANGE_READ_STATUS_REQUEST, changeReadStatusSaga);
}

export default rootSaga;
