import { postRequest, putRequest, getRequest } from 'lib/axios';

import api from './apiRoutes';

export const getMapApi = args => postRequest(api.getMap, args);
export const saveMapApi = args => putRequest(api.saveMap, args);
export const getBannedVendorsApi = args =>
  postRequest(api.getBannedVendors, args);
export const getVendorsApi = args => getRequest(api.getVendors, args);
export const getDistrictsApi = args => postRequest(api.getDistricts, args);
export const getNeighbourhoodsApi = args =>
  postRequest(api.getNeighbourhoods, args);
