import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Empty } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Divider } from '@getir/web-components';

import Table from 'components/v1/Table';
import ConfirmModal from 'components/ConfirmModal';
import SelectBoxAntd from 'components/Antd/Elements/SelectBox';
import * as ROUTES from 'constants/routes';
import { pushToScreenBy } from 'utils/pushToScreenBy';

import * as VendorProductActions from '../actions';

import { getColumns } from './getColumns';
import * as S from './style';
import SearchArea from './SearchArea';
import { extractDataFrom } from './extractDataFrom';
import { useVendorInfoEffect } from './useVendorInfoEffect';
import { useHandleModal } from './useHandleModal';
import { FILTER_VALUES } from './constants';

const List = () => {
  const { t } = useTranslation(['common', 'vendorproduct']);
  const history = useHistory();
  const location = useLocation();

  const modalRef = useRef();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(FILTER_VALUES.ALL);

  const pushToEdit = pushToScreenBy(history, ROUTES.VENDORPRODUCTEDIT);
  // selectors
  const { vendor, vendorInfo } = useSelector(state => state.mainHeader);
  const {
    vendorBrandProducts,
    vendorProducts,
    openSuccessId,
    closeSuccessId,
    editSuccessId,
    categories,
  } = useSelector(state => state.vendorproduct);

  const dispatch = useDispatch();
  const optionForAll = { label: t('common:all'), value: FILTER_VALUES.ALL };

  /* eslint-disable */
  const categoriesOptions = useMemo(() => {
    return categories !== undefined
      ? categories.map(item => ({
          label: item.categoryNameTR,
          value: item.id,
        }))
      : [];
  }, [categories]);
  /* eslint-enable */

  const {
    activateState,
    handleModalSave,
    handleModalClose,
    handleToggleChange,
    handlePricelessToggle,
  } = useHandleModal();

  useEffect(() => {
    dispatch(VendorProductActions.vendorProductBrandListReset());
    dispatch(VendorProductActions.vendorProductCategoriesRequest());
  }, [dispatch]);

  useVendorInfoEffect(vendorInfo, vendorBrandProducts);
  useEffect(() => {
    if (openSuccessId || closeSuccessId || editSuccessId)
      dispatch(VendorProductActions.vendorProductListRequest(vendorInfo.id));
  }, [openSuccessId, closeSuccessId, editSuccessId, dispatch, vendorInfo.id]);

  const getData = () => {
    if (vendorBrandProducts.length === 0 || vendorProducts.length === 0) {
      return [];
    }

    const filteredData = extractDataFrom(
      vendorBrandProducts,
      vendorProducts,
    ).filter(item => {
      if (selectedCategory === FILTER_VALUES.ALL) {
        return (
          item.title.price.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
        );
      }

      return (
        item.category === selectedCategory &&
        item.title.price.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
      );
    });

    const openProducts = filteredData.filter(item => item.open);
    const closedProducts = filteredData.filter(item => !item.open);

    const alphabeticalOpenProducts = openProducts.sort(
      (a, b) => a.title.price.localeCompare(b.title.price),
      'tr',
    );

    const alphabeticalClosedProducts = closedProducts.sort(
      (a, b) => a.title.price.localeCompare(b.title.price),
      'tr',
    );

    return [...alphabeticalOpenProducts, ...alphabeticalClosedProducts];
  };

  return (
    <S.Container>
      <Table
        columns={getColumns(
          handleToggleChange,
          handlePricelessToggle,
          pushToEdit,
          history,
          location,
        )}
        rowKey="id"
        dataSource={vendor?.value ? getData() : []}
        pagination={{ size: 'small', position: ['bottomCenter'] }}
        locale={{ emptyText: <Empty description={t('table.noData')} /> }}
        scroll={{ x: 800 }}
        title={() => (
          <>
            <SearchArea searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
            <Divider />
            <S.CategoryFilter>
              <SelectBoxAntd
                className="category"
                placeholder={t('tableFilters.selectCategory')}
                options={[optionForAll, ...categoriesOptions]}
                onChange={value => setSelectedCategory(value)}
                value={selectedCategory}
                onClear={() => setSelectedCategory(FILTER_VALUES.ALL)}
              />
            </S.CategoryFilter>
          </>
        )}
      />
      <ConfirmModal
        modalRef={modalRef}
        size="400px"
        visible={activateState.modalVisible}
        onSave={handleModalSave}
        onClose={handleModalClose}
        title={t(activateState.modalTitleTranslationKey)}
        content={t(activateState.modalContentTranslationKey)}
      />
    </S.Container>
  );
};

export default List;
