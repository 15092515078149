const SET_CITY_ID = 'SET_CITY_ID';
const SET_VENDOR = 'SET_VENDOR';

const GET_CITIES_SUCCESS = 'GET_CITIES_SUCCESS';
const GET_CITIES_ERROR = 'GET_CITIES_ERROR';
const GET_CITIES_REQUEST = 'GET_CITIES_REQUEST';

const GET_VENDORS_SUCCESS = 'GET_VENDORS_SUCCESS';
const GET_VENDORS_ERROR = 'GET_VENDORS_ERROR';
const GET_VENDORS_REQUEST = 'GET_VENDORS_REQUEST';

const UPDATE_VENDOR_STATUS_SUCCESS = 'UPDATE_VENDOR_STATUS_SUCCESS';
const UPDATE_VENDOR_STATUS_ERROR = 'UPDATE_VENDOR_STATUS_ERROR';
const UPDATE_VENDOR_STATUS_REQUEST = 'UPDATE_VENDOR_STATUS_REQUEST';

const GET_WORKING_HOURS_INFO_SUCCESS = 'GET_WORKING_HOURS_INFO_SUCCESS';
const GET_WORKING_HOURS_INFO_ERROR = 'GET_WORKING_HOURS_INFO_ERROR';
const GET_WORKING_HOURS_INFO_REQUEST = 'GET_WORKING_HOURS_INFO_REQUEST';

const GET_VENDOR_INFO_SUCCESS = 'GET_VENDOR_INFO_SUCCESS';
const GET_VENDOR_INFO_ERROR = 'GET_VENDOR_INFO_ERROR';
const GET_VENDOR_INFO_REQUEST = 'GET_VENDOR_INFO_REQUEST';

const SET_VENDOR_POLL_ISOPEN_SUCCESS = 'SET_VENDOR_INFO_ISOPEN_SUCCESS';
const SET_VENDOR_POLL_ISOPEN_ERROR = 'SET_VENDOR_INFO_ISOPEN_ERROR';
const ISOPEN_POLL_START = 'ISOPEN_POLL_START';
const ISOPEN_POLL_STOP = 'ISOPEN_POLL_STOP';

export {
  SET_CITY_ID,
  SET_VENDOR,
  GET_CITIES_SUCCESS,
  GET_CITIES_ERROR,
  GET_CITIES_REQUEST,
  GET_VENDORS_SUCCESS,
  GET_VENDORS_ERROR,
  GET_VENDORS_REQUEST,
  UPDATE_VENDOR_STATUS_SUCCESS,
  UPDATE_VENDOR_STATUS_ERROR,
  UPDATE_VENDOR_STATUS_REQUEST,
  GET_WORKING_HOURS_INFO_SUCCESS,
  GET_WORKING_HOURS_INFO_ERROR,
  GET_WORKING_HOURS_INFO_REQUEST,
  GET_VENDOR_INFO_SUCCESS,
  GET_VENDOR_INFO_ERROR,
  GET_VENDOR_INFO_REQUEST,
  SET_VENDOR_POLL_ISOPEN_SUCCESS,
  SET_VENDOR_POLL_ISOPEN_ERROR,
  ISOPEN_POLL_START,
  ISOPEN_POLL_STOP,
};
