import { apiGateway } from 'constants/services';

export default {
  getCategory: categoryId => `${apiGateway}/category-service/get/${categoryId}`,
  listCategorys: `${apiGateway}/category-service/getAll`,
  addCategory: `${apiGateway}/category-service/create`,
  updateCategory: `${apiGateway}/category-service/update`,
  deleteCategory: categoryId =>
    `${apiGateway}/category-service/delete/${categoryId}`,
};
