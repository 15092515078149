import * as Types from './types';

const initialState = {
  roleList: {
    data: undefined,
    error: undefined,
  },
  roleUsers: {
    data: undefined,
    error: undefined,
  },
  createRole: {
    data: undefined,
    error: undefined,
  },
  updateRole: {
    data: undefined,
    error: undefined,
  },
  permList: {
    data: undefined,
    error: undefined,
  },
  deleteRole: {
    data: undefined,
    error: undefined,
  },
  roleGroupNames: {
    data: undefined,
    error: undefined,
  },
};

function RoleReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_ROLE_LIST_SUCCESS:
      return {
        ...state,
        roleList: {
          data: payload,
        },
      };
    case Types.GET_ROLE_LIST_ERROR:
      return {
        ...state,
        roleList: {
          error: payload,
        },
      };
    case Types.GET_ROLE_USERS_BY_ROLE_ID_SUCCESS:
      return {
        ...state,
        roleUsers: {
          data: payload,
        },
      };
    case Types.GET_ROLE_USERS_BY_ROLE_ID_ERROR:
      return {
        ...state,
        roleUsers: {
          error: payload,
        },
      };
    case Types.CREATE_ROLE_SUCCESS:
      return {
        ...state,
        createRole: {
          data: payload,
        },
      };
    case Types.CREATE_ROLE_ERROR:
      return {
        ...state,
        createRole: {
          error: payload,
        },
      };
    case Types.UPDATE_ROLE_SUCCESS:
      return {
        ...state,
        updateRole: {
          data: payload,
        },
      };
    case Types.UPDATE_ROLE_ERROR:
      return {
        ...state,
        updateRole: {
          error: payload,
        },
      };
    case Types.GET_PERMS_BY_ROLE_ID_SUCCESS:
      return {
        ...state,
        permList: {
          data: payload,
        },
      };
    case Types.GET_PERMS_BY_ROLE_ID_ERROR:
      return {
        ...state,
        permList: {
          error: payload,
        },
      };
    case Types.DELETE_ROLE_SUCCESS:
      return {
        ...state,
        deleteRole: {
          data: payload,
        },
      };
    case Types.DELETE_ROLE_ERROR:
      return {
        ...state,
        deleteRole: {
          error: payload,
        },
      };
    case Types.GET_ROLE_GROUP_NAMES_SUCCESS:
      return {
        ...state,
        roleGroupNames: {
          data: payload,
        },
      };
    case Types.GET_ROLE_GROUP_NAMES_ERROR:
      return {
        ...state,
        roleGroupNames: {
          error: payload,
        },
      };
    default:
      return state;
  }
}

export default RoleReducer;
