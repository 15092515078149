/* eslint-disable indent */
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import useUserInfo from 'hooks/useUserInfo';
import { rolesWithName } from 'constants/roles';

import * as vendorCommunicationActions from '../actions';
import { MODAL_TYPES } from '../constants';

import * as S from './styles';

const useTableColumn = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('vendorCommunication');
  const { userInfo } = useUserInfo();

  const isUserAdmin = userInfo?.role?.includes(rolesWithName.ADMIN) || false;

  const openMessageModal = useCallback(
    thread => {
      dispatch(
        vendorCommunicationActions.openMessageModal({
          isModalOpen: true,
          modalType: MODAL_TYPES.MESSAGE_DETAILS,
          threadId: thread.messageId,
        }),
      );

      dispatch(
        vendorCommunicationActions.setThreadDetail({
          toUserName: thread.toUsername,
          isLocked: thread.isLocked,
        }),
      );
    },
    [dispatch],
  );

  const columns = [
    {
      title: t('subject'),
      dataIndex: 'subject',
      key: 'subject',
      render: (text, row) => (
        <S.ContentContainer>
          {row.unreadMessageCount !== 0 && (
            <S.UnreadedText>{row.unreadMessageCount}</S.UnreadedText>
          )}
          <S.Text
            onClick={() => openMessageModal(row)}
            $isReaded={row.unreadMessageCount}
          >
            {text}
          </S.Text>
        </S.ContentContainer>
      ),
    },
    {
      title: isUserAdmin ? t('sender') : t('operationManager'),
      dataIndex: 'toUsername',
      key: 'toUsername',
      width: 170,
      render: (text, row) => (
        <S.ContentContainer>
          <S.Text $isReaded={row.unreadMessageCount}>{text}</S.Text>
        </S.ContentContainer>
      ),
    },
    {
      title: t('firstMessageDate'),
      dataIndex: 'firstMessageDate',
      key: 'firstMessageDate',
      width: 140,
      render: (text, row) => (
        <S.ContentContainer>
          <S.Text $isReaded={row.unreadMessageCount}>
            {dayjs(text).format('DD.MM.YYYY HH:mm')}
          </S.Text>
        </S.ContentContainer>
      ),
    },
  ];

  if (isUserAdmin) {
    columns.splice(1, 0, {
      title: t('senderVendor'),
      dataIndex: 'vendorName',
      key: 'vendorName',
      width: 170,
      render: (text, row) => (
        <S.ContentContainer>
          <S.Text $isReaded={row.unreadMessageCount}>{text}</S.Text>
        </S.ContentContainer>
      ),
    });
  }

  return columns;
};

export default useTableColumn;
