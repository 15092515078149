import getRandomString from 'utils/getRandomString';

import * as Types from './types';

const initialState = {
  vendorProducts: [],
  vendorBrandProducts: [],
  error: {},
  selectedProduct: undefined,
  productInfo: undefined,
  openSuccessId: undefined,
  closeSuccessId: undefined,
  editSuccessId: undefined,
  categories: [],
};

function VendorProductReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.VENDORPRODUCT_BRAND_LIST_SUCCESS:
      return {
        ...state,
        vendorBrandProducts: payload,
      };
    case Types.VENDORPRODUCT_BRAND_LIST_RESET:
      return {
        ...state,
        vendorBrandProducts: [],
      };
    case Types.VENDORPRODUCT_LIST_SUCCESS:
      return {
        ...state,
        vendorProducts: payload,
      };
    case Types.VENDORPRODUCT_LIST_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.VENDORPRODUCT_OPEN_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.VENDORPRODUCT_OPEN_SUCCESS:
      return {
        ...state,
        openSuccessId: getRandomString(7),
      };
    case Types.VENDORPRODUCT_CLOSE_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.VENDORPRODUCT_CLOSE_SUCCESS:
      return {
        ...state,
        closeSuccessId: getRandomString(7),
      };
    case Types.VENDORPRODUCT_EDIT_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.VENDORPRODUCT_EDIT_SUCCESS:
      return {
        ...state,
        editSuccessId: getRandomString(7),
      };
    case Types.VENDORPRODUCT_GET_SUCCESS:
      return {
        ...state,
        selectedProduct: payload,
      };
    case Types.VENDORPRODUCT_GET_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.VENDORPRODUCT_PRODUCTINFO_SUCCESS:
      return {
        ...state,
        productInfo: payload,
      };
    case Types.VENDORPRODUCT_PRODUCTINFO_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.VENDORPRODUCT_PRODUCTINFO_RESET:
      return {
        ...state,
        productInfo: undefined,
      };
    case Types.VENDORPRODUCT_GET_RESET:
      return {
        ...state,
        selectedProduct: undefined,
      };
    case Types.VENDORPRODUCT_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: payload.categories,
      };
    case Types.VENDORPRODUCT_CATEGORIES_ERROR:
      return {
        ...state,
        error: payload,
      };
    default:
      return state;
  }
}

export default VendorProductReducer;
