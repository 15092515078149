/* eslint-disable indent */
import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Input } from 'antd';
import dayjs from 'dayjs';
import { StarFilled, PrinterFilled } from '@ant-design/icons';
import { isEmpty, isNull } from 'lodash';
import { useHistory } from 'react-router-dom';

import {
  PAYMENT_TYPE_TRANSLATEKEY,
  PAYMENT_TYPE_REVERSE,
} from 'constants/paymentType';
import * as ActiveOrderActions from 'containers/ActiveOrders/actions';
import * as CourierListModalActions from 'components/CourierListModal/actions';
import * as OrderCancelModalActions from 'components/OrderCancelModal/actions';
import * as BillingDetailModalActions from 'components/v1/BillingDetailModal/actions';
import { MODAL_MODES } from 'components/v1/BillingDetailModal/constants';
import { ORDER_STATUS } from 'constants/orderStatus';
import { PREVIOUS_ORDERS } from 'constants/routes';
import useResponsive from 'hooks/useResponsive';
import MapViewer from 'components/MapViewer';
import ViewOnMapImage from 'common/svg/map.svg';
import InfoIconBg from 'common/svg/infoIconBg.svg';
import ToggleVisibilityButton from 'containers/ActiveOrders/toggleVisibilityButton';
import useQuery from 'hooks/useQuery';
import { invoiceStatus } from 'containers/PreviousOrders/constants';
import Badge from 'components/v1/Badge';
import Info from 'components/v1/Info';
import Button from 'components/v1/Button';

import * as S from './style';
import { useOrderDetailColumns } from './OrderDetailModalColumns';
import * as OrderDetailActions from './actions';

const CANCEL_BUTTON_COLOR = '#FFEFEF';
const CANCEL_BUTTON_TEXT_COLOR = '#D13333';

const OrderDetailModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['orderdetailmodal', 'activeOrders']);
  const { orderDetail, showActions, showModal } = useSelector(
    state => state.orderDetail,
  );

  const { data: savedCustomerNote } = useSelector(
    state => state.orderDetail.getAddressNote,
  );

  const columns = useOrderDetailColumns();
  const { isDesktopView } = useResponsive();
  const history = useHistory();
  const params = useQuery();
  const [customerNote, setCustomerNote] = useState(undefined);
  const [currentUserLocation, setCurrentUserLocation] = useState(undefined);
  const isScheduledOrder = orderDetail?.status === ORDER_STATUS.SCHEDULED;
  const MapViewMemo = useMemo(
    () => (
      <MapViewer
        vendorAddress={[
          orderDetail?.vendor?.vendorLat,
          orderDetail?.vendor?.vendorLon,
        ]}
        deliveryAddress={[
          orderDetail?.deliveryAddress.lat,
          orderDetail?.deliveryAddress.lon,
        ]}
        isScheduled={orderDetail?.status === ORDER_STATUS.SCHEDULED}
      />
    ),
    [orderDetail],
  );

  useEffect(() => {
    const orderId = params.get('orderId');
    const orderDateQuery = params.get('date');

    if (isNull(orderId)) return;
    if (isNull(orderDateQuery)) return;

    const now = new Date();
    const queryDate = new Date(+orderDateQuery);

    const today = {
      day: now.getDate(),
      month: now.getMonth(),
      year: now.getFullYear(),
    };

    const orderDateInfo = {
      day: queryDate.getDate(),
      month: queryDate.getMonth(),
      year: queryDate.getFullYear(),
    };

    if (
      today.day === orderDateInfo.day &&
      today.month === orderDateInfo.month &&
      today.year === orderDateInfo.year
    ) {
      dispatch(OrderDetailActions.getOrderDetailWithActionsRequest(orderId));

      return;
    }
    const searchParam = new URLSearchParams(window.location.search);
    history.push(`${PREVIOUS_ORDERS}?${searchParam.toString()}`);
    dispatch(OrderDetailActions.getOrderDetailWithActionsRequest(orderId));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEmpty(orderDetail)) {
      addQueryParametrs('orderId', orderDetail.id);
      const orderDate = new Date(orderDetail.createdDate);
      addQueryParametrs('date', orderDate.getTime());
    }

    return () => {
      clearQueryParametrs('orderId');
      clearQueryParametrs('date');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  useEffect(() => {
    if (orderDetail) {
      const { vendor, deliveryAddress } = orderDetail;
      const data = {
        vendorId: vendor?.id,
        addressId: deliveryAddress?._id, // eslint-disable-line
      };
      dispatch(OrderDetailActions.getAddressNoteRequest(data));
    }
  }, [orderDetail]); // eslint-disable-line

  useEffect(() => {
    if (savedCustomerNote) {
      setCustomerNote(savedCustomerNote.addressNote);
    }
  }, [savedCustomerNote]);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(position => {
      const { latitude, longitude } = position.coords;
      setCurrentUserLocation([latitude, longitude]);
    });
  }, []);

  function addQueryParametrs(name, value) {
    const searchParam = new URLSearchParams(window.location.search);
    searchParam.set(name, value);

    history.push(`?${searchParam.toString()}`);
  }

  function clearQueryParametrs(value) {
    const searchParam = new URLSearchParams(window.location.search);
    searchParam.delete(value);

    history.push(`?${searchParam.toString()}`);
  }

  const closeModal = () => {
    dispatch(OrderDetailActions.resetOrderDetail());
  };

  const cancelOrder = () => {
    const { id } = orderDetail;
    dispatch(
      OrderCancelModalActions.openOrderCancelModal({
        id,
      }),
    );
    dispatch(OrderDetailActions.resetOrderDetail());
  };

  const changeOrderStatus = orderStatus => {
    dispatch(
      ActiveOrderActions.changeOrderStatusRequest({
        id: orderDetail.id,
        status: orderStatus,
      }),
    );
    dispatch(OrderDetailActions.resetOrderDetail());
  };

  const sendOrderAction = () => {
    const { id } = orderDetail;

    dispatch(CourierListModalActions.sendSmsModalOpen(id));
    dispatch(OrderDetailActions.resetOrderDetail());
  };

  const footerActions = [
    (orderDetail?.status === ORDER_STATUS.VERIFYING ||
      orderDetail?.status === ORDER_STATUS.PREPARING ||
      isScheduledOrder) && (
      <ToggleVisibilityButton secondDate={orderDetail?.approvedDate}>
        <S.OrderActionButton
          style={{
            backgroundColor: CANCEL_BUTTON_COLOR,
            color: CANCEL_BUTTON_TEXT_COLOR,
          }}
          onClick={cancelOrder}
          kind="primary"
        >
          {t('orderdetailmodal:cancelOrder')}
        </S.OrderActionButton>
      </ToggleVisibilityButton>
    ),
    orderDetail?.status === ORDER_STATUS.VERIFYING && (
      <S.OrderActionButton
        onClick={() => changeOrderStatus(ORDER_STATUS.PREPARING)}
        kind="primary"
      >
        {t('orderdetailmodal:acceptOrder')}
      </S.OrderActionButton>
    ),
    orderDetail?.status === ORDER_STATUS.PREPARING && (
      <S.OrderActionButton onClick={sendOrderAction} kind="primary">
        {t('orderdetailmodal:sendOrder')}
      </S.OrderActionButton>
    ),
    orderDetail?.status === ORDER_STATUS.ONWAY && (
      <S.OrderActionButton
        onClick={() => changeOrderStatus(ORDER_STATUS.DELIVERED)}
        kind="primary"
      >
        {t('orderdetailmodal:deliverOrder')}
      </S.OrderActionButton>
    ),
  ];

  const handleCustomerNoteClearClick = () => {
    const { vendor, deliveryAddress } = orderDetail;
    setCustomerNote(undefined);
    const deleteData = {
      vendorId: vendor?.id,
      addressId: deliveryAddress?._id, // eslint-disable-line
    };
    dispatch(OrderDetailActions.deleteAddressNoteRequest(deleteData));
  };

  const handleCustomerNoteChange = e => {
    if (!e.target.value && savedCustomerNote.addressNote.length > 0) {
      handleCustomerNoteClearClick();
    }

    setCustomerNote(e.target.value);
  };

  const billingDetailClickHandler = () => {
    const orderId = params.get('orderId');
    dispatch(
      BillingDetailModalActions.getBillingDetailRequest({
        orderId,
      }),
    );

    dispatch(
      BillingDetailModalActions.openBillingModal({
        orderId,
        mode: MODAL_MODES.DETAIL,
      }),
    );
  };

  const handleCustomerNoteBlur = () => {
    const { client, vendor, deliveryAddress } = orderDetail;

    if (
      savedCustomerNote.addressNote.length === 0 &&
      customerNote &&
      customerNote.trim().length > 0
    ) {
      const createData = {
        addressId: deliveryAddress._id, // eslint-disable-line
        addressNote: customerNote,
        clientId: client.id,
        vendorId: vendor.id,
      };
      dispatch(OrderDetailActions.createAddressNoteRequest(createData));

      return;
    }

    if (savedCustomerNote.addressNote.length > 0) {
      const updateData = {
        vendorId: vendor?.id,
        addressId: deliveryAddress?._id, // eslint-disable-line
        addressNote: customerNote,
      };
      dispatch(OrderDetailActions.updateAddressNoteRequest(updateData));
    }
  };

  const createMapUrl = () =>
    currentUserLocation
      ? `https://www.google.com/maps/dir/?api=1&
    origin=${currentUserLocation[0]},${currentUserLocation[1]}&
    destination=${orderDetail.deliveryAddress.lat},${orderDetail.deliveryAddress.lon}&
    travelmode=driving`.replace(/\s/g, '')
      : `https://www.google.com/maps/search/?api=1&
    query=${orderDetail.deliveryAddress.lat},${orderDetail.deliveryAddress.lon}&
    travelmode=driving`.replace(/\s/g, '');
  const customerName = useMemo(() => {
    if (!orderDetail) return '';
    const names = orderDetail.client.name.split(' ');

    if (isScheduledOrder) {
      return `${names[0].charAt(0)}*** ${names[1].charAt(0)}.`;
    }

    return `${names[0]} ${names[1].charAt(0)}.`;
  }, [isScheduledOrder, orderDetail]);

  return (
    <S.ModalWrapper
      isOpen={showModal}
      width={isDesktopView ? 1200 : '100vw'}
      footerOptions={{
        customFooter: (
          <S.FooterContainer>
            {orderDetail?.invoice &&
              orderDetail.invoice.status !== invoiceStatus.DELETED && (
                <Button
                  kind="secondary"
                  style={{ marginRight: 8 }}
                  onClick={billingDetailClickHandler}
                >
                  {t('activeOrders:billingDetail')}
                </Button>
              )}
            {showActions ? footerActions : null}
          </S.FooterContainer>
        ),
      }}
      headerOptions={{
        title: t('orderdetailmodal:title'),
        closeButton: true,
      }}
      onClose={closeModal}
    >
      {orderDetail !== undefined && (
        <S.OrderDetailContainer>
          <S.RowContainer>
            <S.OrderInfoCol>
              <S.TitleWrapper>
                <h2>{t('orderdetailmodal:title')} </h2>
                {orderDetail?.newCustomer && (
                  <Badge type="primary" icon={<StarFilled />}>
                    {t('orderdetailmodal:newCustomer')}
                  </Badge>
                )}
              </S.TitleWrapper>
              <S.OrderInfoContainer>
                <S.OrderInfoCol>
                  <S.Info>
                    {t('orderdetailmodal:clientName')} :{' '}
                    <S.InfoValue>{customerName}</S.InfoValue>
                  </S.Info>
                  {!isScheduledOrder && (
                    <S.Info>
                      {t('orderdetailmodal:courierCallNumber')} :{' '}
                      <S.PhoneNumber
                        href={`tel:${orderDetail.clientCallNumber},${orderDetail.clientCallPin}`}
                      >
                        {orderDetail.clientCallNumber}/{' '}
                        {orderDetail.clientCallPin}
                      </S.PhoneNumber>
                    </S.Info>
                  )}
                  <Divider
                    style={{
                      margin: '0',
                    }}
                  />
                  <S.Info>
                    {t('activeOrders:columns.orderDate')} :{' '}
                    <S.InfoValue>
                      {dayjs(orderDetail.paymentDate).format(
                        'DD/MM/YYYY - HH:mm',
                      )}
                    </S.InfoValue>
                  </S.Info>
                  {orderDetail?.scheduledOrder && (
                    <S.Info>
                      {t('activeOrders:columns.scheduledDeliveryTime')} :{' '}
                      <S.InfoValue>
                        {dayjs(orderDetail.scheduledDate).format(
                          'DD/MM/YYYY - HH:mm',
                        )}
                        -{dayjs(orderDetail.scheduledDateEnd).format('HH:mm')}
                      </S.InfoValue>
                    </S.Info>
                  )}
                  <S.Info>
                    {t('activeOrders:columns.paymentMethod')} :{' '}
                    <S.InfoValue>
                      {t(
                        PAYMENT_TYPE_TRANSLATEKEY[
                          PAYMENT_TYPE_REVERSE[orderDetail.paymentType]
                        ],
                      )}
                    </S.InfoValue>
                  </S.Info>
                  <S.Info>
                    {t('activeOrders:columns.confirmationCode')} :{' '}
                    <S.InfoValue>{orderDetail.confirmationCode}</S.InfoValue>
                  </S.Info>
                </S.OrderInfoCol>
                <S.PrintButton onClick={() => window.print()} kind="secondary">
                  <PrinterFilled />
                  {t('common:print')}
                </S.PrintButton>
              </S.OrderInfoContainer>
              {orderDetail?.note && (
                <S.OrderNoteWrapper>
                  <div className="icon-wrapper">
                    <img src={InfoIconBg} alt="info" width={16} height={16} />
                    {t('orderdetailmodal:orderNote')}
                  </div>
                  <div className="content">{orderDetail?.note}</div>
                </S.OrderNoteWrapper>
              )}
              <S.ProductTable
                columns={columns}
                dataSource={orderDetail.products}
                rowKey="id"
                pagination={false}
                scroll={{ x: 400 }}
                footer={() =>
                  `${t('common:total')}: ${orderDetail.totalChargedAmountText}`
                }
              />
              {/* {!orderDetail.doNotKnock && (
                <Info kind="primary">
                  {t('orderdetailmodal:dropOffAtDoor')}
                </Info>
              )} */}
              {orderDetail.doNotKnock && (
                <Info kind="primary">{t('orderdetailmodal:doNotKnock')}</Info>
              )}
            </S.OrderInfoCol>
            <S.AddressInfoCol>
              <S.AdressTitleWrapper>
                <h2>{t('orderdetailmodal:addressInfo')}</h2>
                {!isScheduledOrder && (
                  <S.ShowOnMap href={createMapUrl()} target="_blank">
                    <img
                      src={ViewOnMapImage}
                      alt="map"
                      width={16}
                      height={16}
                    />
                    {t('orderdetailmodal:viewOnMap')}
                  </S.ShowOnMap>
                )}
              </S.AdressTitleWrapper>
              <S.AdressInfoContainer>
                <S.AdressMapContainer>{MapViewMemo}</S.AdressMapContainer>
                {!isScheduledOrder && (
                  <S.AdressInfo>
                    <S.Info>
                      {t('orderdetailmodal:address')} :{' '}
                      <S.InfoValue>
                        {orderDetail.deliveryAddress.address}
                      </S.InfoValue>
                    </S.Info>
                    <S.Info>
                      {t('orderdetailmodal:aptNo')} :{' '}
                      <S.InfoValue>
                        {orderDetail.deliveryAddress.aptNo}
                      </S.InfoValue>
                    </S.Info>
                    <S.Info>
                      {t('orderdetailmodal:floor')} :{' '}
                      <S.InfoValue>
                        {orderDetail.deliveryAddress.floor}
                      </S.InfoValue>
                    </S.Info>
                    <S.Info>
                      {t('orderdetailmodal:flat')} :{' '}
                      <S.InfoValue>
                        {orderDetail.deliveryAddress.doorNo}
                      </S.InfoValue>
                    </S.Info>
                    <S.Info>
                      {t('orderdetailmodal:description')} :{' '}
                      <S.InfoValue>
                        {orderDetail.deliveryAddress.description}
                      </S.InfoValue>
                    </S.Info>
                  </S.AdressInfo>
                )}
              </S.AdressInfoContainer>
            </S.AddressInfoCol>
          </S.RowContainer>
          <S.RowContainer>
            <Input.TextArea
              maxLength={200}
              rows={4}
              placeholder={t('orderdetailmodal:notePlaceholder')}
              onChange={handleCustomerNoteChange}
              onBlur={handleCustomerNoteBlur}
              value={customerNote}
              allowClear
              showCount
            />
          </S.RowContainer>
        </S.OrderDetailContainer>
      )}
    </S.ModalWrapper>
  );
};

export default OrderDetailModal;
