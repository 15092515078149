import styled from 'styled-components';

import Button from '../Button';

export const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 8px;
  background: #fff;
`;

export const OrderDetailContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
  flex-direction: column;
`;

export const Name = styled.span`
  color: #191919;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  span {
    color: #697488;
    font-weight: 400;
    margin-left: 4px;
  }
`;

export const GhostBadge = styled.div`
  display: none;
`;
export const Adress = styled.span`
  color: #697488;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  width: 100%;
`;
export const MapButton = styled(Button)`
  width: 36px;
  height: 36px;
  padding: 0;

  svg {
    width: 20px;
    height: 20px;
  }
`;
export const InfoContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
  justify-content: space-between;
`;
export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 100%;
`;
export const RightContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
export const BadgesContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
  flex-wrap: wrap;
  div {
    height: 24px;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    svg {
      height: 14px;
      width: 14px;
    }
  }
`;

export const ProductsContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  border-radius: 8px;
  background: #f8f8f8;
`;

export const ProductContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const ProductName = styled.div`
  overflow: hidden;
  color: #191919;
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  display: flex;
  align-items: center;
  align-items: center;
  white-space: nowrap;
  flex-grow: 1;
  min-width: 50px;
  vertical-align: middle;
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-grow: 1;
  }

  &::before {
    content: '';
    margin-right: 4px;
    width: 4px;
    height: 4px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #191919;
  }
`;

export const ProductCount = styled.span`
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 2px;
  border-radius: 6px;
  background: #f0f1f3;
  color: #191919;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  white-space: nowrap;
  flex-shrink: 0;
`;

export const ActionContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
  button {
    flex-grow: 6;
  }
  #detail {
    flex-grow: 4;
  }
`;
