import { apiGateway } from 'constants/services';

export default {
  getOrder: orderId => `${apiGateway}/order-service/get/${orderId}`,
  getAddressNote: (vendorId, addressId) =>
    `${apiGateway}/vendor-service/vendors/vendor/address-notes?vendorId=${vendorId}&addressId=${addressId}`,
  updateAddressNote: (vendorId, addressId) =>
    `${apiGateway}/vendor-service/vendors/vendor/address-notes/${vendorId}/${addressId}`,
  createAddressNote: () =>
    `${apiGateway}/vendor-service/vendors/vendor/address-notes`,
  deleteAddressNote: (vendorId, addressId) =>
    `${apiGateway}/vendor-service/vendors/vendor/address-notes/${vendorId}/${addressId}`,
};
