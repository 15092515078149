import i18n from 'i18next';

export const defaultLang = 'en';

export const changeLanguage = selectedLanguage => {
  i18n.changeLanguage(selectedLanguage);
};

export const getLangKey = () => {
  if (i18n.language) {
    return i18n.language.split('-')[0];
  }

  return defaultLang;
};

export const getFullLangKey = () => {
  const rawLanguage = i18n.language;
  if (rawLanguage === 'en') {
    return 'en_US';
  }
  if (rawLanguage === 'tr') {
    return 'tr_TR';
  }

  return rawLanguage;
};
