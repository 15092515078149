import { createAction } from 'utils/createAction';

import * as Types from './types';

// Open and Close Message Modal
export const openMessageModal = createAction(Types.OPEN_MESSAGE_MODAL);
export const closeMessageModal = createAction(Types.CLOSE_MESSAGE_MODAL);

// get Thread list
export const getThreadListRequest = createAction(Types.GET_THREAD_LIST_REQUEST);
export const getThreadListSuccess = createAction(Types.GET_THREAD_LIST_SUCCESS);
export const getThreadListError = createAction(Types.GET_THREAD_LIST_ERROR);

// get thread detail
export const getThreadDetailRequest = createAction(
  Types.GET_THREAD_DETAIL_REQUEST,
);
export const getThreadDetailSuccess = createAction(
  Types.GET_THREAD_DETAIL_SUCCESS,
);
export const getThreadDetailError = createAction(Types.GET_THREAD_DETAIL_ERROR);

// send message
export const sendMessageRequest = createAction(Types.SEND_MESSAGE_REQUEST);
export const sendMessageSuccess = createAction(Types.SEND_MESSAGE_SUCCESS);
export const sendMessageError = createAction(Types.SEND_MESSAGE_ERROR);

// create new thread
export const createNewThreadRequest = createAction(
  Types.CREATE_NEW_THREAD_REQUEST,
);
export const createNewThreadSuccess = createAction(
  Types.CREATE_NEW_THREAD_SUCCESS,
);
export const createNewThreadError = createAction(Types.CREATE_NEW_THREAD_ERROR);

// close thread
export const closeThreadRequest = createAction(Types.CLOSE_THREAD_REQUEST);
export const closeThreadSuccess = createAction(Types.CLOSE_THREAD_SUCCESS);
export const closeThreadError = createAction(Types.CLOSE_THREAD_ERROR);

// change read status
export const changeReadStatusRequest = createAction(
  Types.CHANGE_READ_STATUS_REQUEST,
);
export const changeReadStatusSuccess = createAction(
  Types.CHANGE_READ_STATUS_SUCCESS,
);
export const changeReadStatusError = createAction(
  Types.CHANGE_READ_STATUS_ERROR,
);

// reset all states
export const resetAllStates = createAction(Types.RESET_ALL_STATES);
export const clearThreadDetail = createAction(Types.CLEAR_THREAD_DETAIL);

//
export const setThreadDetail = createAction(Types.SET_THREAD_DETAIL);
