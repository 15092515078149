import styled from 'styled-components';
import { Table } from 'antd';
import {
  Button as StyledButton,
  Icon as StyledIcon,
} from '@getir/web-components';

const PageHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0;

  ${StyledButton.S.Wrapper} {
    border-radius: 0;
  }
`;

const PageTitle = styled.p`
  margin-bottom: 0;
  font-size: 24px;
`;

const FirmListTable = styled(Table)`
  ${StyledButton.S.Wrapper} {
    border-radius: 0;
  }

  ${StyledIcon.S.Wrapper} {
    color: #db471e;
    cursor: pointer;
  }
`;

export { PageHeader, PageTitle, FirmListTable };
