import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { noop } from 'lodash';

import * as CS from 'common/style';

import * as S from './style';

const PriceInput = ({
  fieldName,
  control,
  rules,
  placeholder,
  header,
  errors,
  isOnlyNumber,
  isDisabled,
  style,
  customOnChange,
  shouldHideErrorMessage,
  maxPrice,
  customOnFocus,
  ...props
}) => {
  const currencyFormatter = useCallback(
    val =>
      new Intl.NumberFormat('tr-TR', {
        style: 'currency',
        currency: 'TRY',
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
      }).format(val),
    [],
  );

  const currencyParser = useCallback(val => {
    try {
      // for when the input gets clears
      let newVal = val;
      if (typeof val === 'string' && !val.length) {
        newVal = '0.0';
      }
      // check is negative number
      const isNegative = newVal.includes('-');

      if (isNegative) {
        newVal = '0.0';
      }

      // detecting and parsing between comma and dot
      const group = new Intl.NumberFormat('tr-TR')
        .format(1111)
        .replace(/1/g, ''); // . or ,

      const decimal = new Intl.NumberFormat('tr-TR')
        .format(1.1)
        .replace(/1/g, ''); // . or ,

      let reversedVal = newVal.replace(new RegExp(`\\${group}`, 'g'), '');

      reversedVal = reversedVal.replace(new RegExp(`\\${decimal}`, 'g'), '.');
      //  => ₺1232.21

      // removing everything except the digits and dot
      reversedVal = reversedVal.replace(/[^0-9.]/g, '');
      //  => 1232.21

      // appending digits properly
      const digitsAfterDecimalCount = (reversedVal.split('.')[1] || []).length;

      const needsDigitsAppended = digitsAfterDecimalCount > 2;

      if (needsDigitsAppended) {
        reversedVal *= 10 ** (digitsAfterDecimalCount - 2);
      }

      return Number.isNaN(reversedVal) ? 0 : reversedVal;
    } catch (error) {
      return null;
    }
  }, []);

  return (
    <CS.FormFieldWrapper style={style}>
      <S.Container>
        {header ? <S.Header>{header}</S.Header> : null}
        <Controller
          name={fieldName}
          control={control}
          rules={rules}
          render={({ field }) => (
            <S.InputField
              name={field.name}
              id={field.name}
              decimalSeparator=","
              keyboard
              ref={field.ref}
              max={maxPrice}
              min={0}
              onChange={val => {
                field.onChange(val);
                customOnChange(val);
              }}
              onBlur={field.onBlur}
              onFocus={() => {
                if (field.value === undefined) field.onChange(0);
                customOnFocus();
              }}
              value={field.value ? field.value : ''}
              placeholder={placeholder}
              disabled={isDisabled}
              formatter={currencyFormatter}
              parser={currencyParser}
              {...props}
            />
          )}
        />
      </S.Container>
      {!shouldHideErrorMessage && errors[fieldName] && (
        <CS.ErrorMessage>{errors[fieldName].message}</CS.ErrorMessage>
      )}
    </CS.FormFieldWrapper>
  );
};

PriceInput.defaultProps = {
  fieldName: '',
  control: {},
  rules: {},
  placeholder: '',
  header: '',
  errors: {},
  isOnlyNumber: false,
  isDisabled: false,
  style: {},
  customOnChange: noop,
  customOnFocus: noop,
  shouldHideErrorMessage: false,
  maxPrice: Number.MAX_VALUE,
};

PriceInput.propTypes = {
  fieldName: PropTypes.string,
  control: PropTypes.object,
  rules: PropTypes.object,
  placeholder: PropTypes.string,
  header: PropTypes.string,
  errors: PropTypes.object,
  style: PropTypes.object,
  isOnlyNumber: PropTypes.bool,
  isDisabled: PropTypes.bool,
  customOnChange: PropTypes.func,
  customOnFocus: PropTypes.func,
  shouldHideErrorMessage: PropTypes.bool,
  maxPrice: PropTypes.number,
};

export default PriceInput;
