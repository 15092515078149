import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import breakpoint from 'constants/breakpoint';

import useIsomorphicLayoutEffect from './useIsomorphicLayoutEffect';

function useResponsive() {
  const [isClient, setIsClient] = useState(false);

  const isMobile = useMediaQuery({
    maxWidth: breakpoint.MOBILE.MAX,
  });

  const isMiniTablet = useMediaQuery({
    minWidth: breakpoint.TABLET_MINI.MIN,
    maxWidth: breakpoint.TABLET_MINI.MAX,
  });

  const isTablet = useMediaQuery({
    minWidth: breakpoint.TABLET.MIN,
    maxWidth: breakpoint.TABLET.MAX,
  });

  const isDesktop = useMediaQuery({
    minWidth: breakpoint.DESKTOP.MIN,
  });

  useIsomorphicLayoutEffect(() => {
    setIsClient(true);
  }, []);

  return {
    isDesktopView: isClient ? isDesktop : true,
    isTabletView: isClient ? isTablet : false,
    isMiniTabletView: isClient ? isMiniTablet : false,
    isMobileView: isClient ? isMobile : false,
  };
}

export default useResponsive;
