import styled from 'styled-components';

export const UnreadedText = styled.span`
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 2px;
  border-radius: 6px;
  background: #f3f0fe;
  color: #5d3ebc;
  user-select: none;
  font-size: 12px;
  font-weight: 600;
  cursor: default;
`;

export const ContentContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const Text = styled.span`
  overflow: hidden;
  color: #191919;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  cursor: pointer;
  ${props =>
    !props.$isReaded &&
    `color: #7F7F7F;
     span{
         cursor: default;
     }
  `}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;
