import React from 'react';
import PropTypes from 'prop-types';

const BackIcon = ({ fill, width, height }) => (
  <svg width={width} height={height} fill={fill}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7803 3.21967C12.0732 3.51256 12.0732 3.98744 11.7803 4.28033L7.06066 9L11.7803 13.7197C12.0732 14.0126 12.0732 14.4874 11.7803 14.7803C11.4874 15.0732 11.0126 15.0732 10.7197 14.7803L5.46967 9.53033C5.17678 9.23744 5.17678 8.76256 5.46967 8.46967L10.7197 3.21967C11.0126 2.92678 11.4874 2.92678 11.7803 3.21967Z"
      fill="inherit"
    />
  </svg>
);

BackIcon.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

BackIcon.defaultProps = {
  fill: '#97670F',
  width: 16,
  height: 16,
};

export default BackIcon;
