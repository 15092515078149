import { takeLatest, select, call, put } from 'redux-saga/effects';

import {
  listVendorsApi,
  addVendorApi,
  updateVendorApi,
  deleteVendorApi,
  closeForSaleApi,
  openForSalesApi,
  getDistrictsApi,
  getTownsApi,
  getBrandsApi,
  getFirmsApi,
  getOperationalManagersApi,
  getRegionManagersApi,
} from 'services/Vendor/api';
import history from 'utils/history';
import translate from 'utils/translate';
import { open } from 'utils/notification';
import * as ROUTES from 'constants/routes';
import { createStandardSaga } from 'utils/createStandardSaga';
import * as LoaderActions from 'components/Loader/actions';

import * as Types from './types';
import { firmId, cityId, vendorId } from './selectors';
import * as VendorActions from './actions';

const listVendorsSaga = createStandardSaga({
  callApi: listVendorsApi,
  successAction: VendorActions.vendorListSuccess,
  errorAction: VendorActions.vendorListError,
});

function* addVendorSaga({ payload }) {
  yield put(LoaderActions.showLoader());
  try {
    const result = yield call(addVendorApi, payload);
    if (result.data) {
      const formData = {};
      if (firmId) {
        const firm = yield select(firmId);
        formData.firmId = firm;
      }
      if (cityId) {
        const city = yield select(cityId);
        formData.cityId = city;
      }
      yield put(VendorActions.addVendorSuccess(formData));
      open(
        'success',
        translate('common:notif.successTitle'),
        translate('vendor:createVendorSuccess'),
      );
      history.push(ROUTES.VENDORS);
    }
  } catch (error) {
    yield put(VendorActions.addVendorError(error.message));
  }
  yield put(LoaderActions.hideLoader());
}

function* updateVendorSaga({ payload }) {
  yield put(LoaderActions.showLoader());
  try {
    const result = yield call(updateVendorApi, payload);
    if (result.data) {
      const vendor = yield select(vendorId);
      yield put(VendorActions.updateVendorSuccess(vendor));
      open(
        'success',
        translate('common:notif.successTitle'),
        translate('vendor:updateVendorSuccess'),
      );
    } else {
      open('warning', translate('common:notif.warnTitle'), result.message);
    }
  } catch (error) {
    yield put(VendorActions.updateVendorError(error.message));
  }
  yield put(LoaderActions.hideLoader());
}

function* updateFinancialInfoSaga({ payload }) {
  yield put(LoaderActions.showLoader());
  try {
    const result = yield call(updateVendorApi, payload);
    if (result.data) {
      const vendor = yield select(vendorId);
      yield put(VendorActions.updateFinancialInfoSuccess(vendor));
      open(
        'success',
        translate('common:notif.successTitle'),
        translate('vendor:updateVendorSuccess'),
      );
    } else {
      open('warning', translate('common:notif.warnTitle'), result.message);
    }
  } catch (error) {
    yield put(VendorActions.updateFinancialInfoError(error.message));
  }
  yield put(LoaderActions.hideLoader());
}

const getDistrictsSaga = createStandardSaga({
  callApi: getDistrictsApi,
  successAction: VendorActions.getDistrictsSuccess,
  errorAction: VendorActions.getDistrictsError,
});

const getTownsSaga = createStandardSaga({
  callApi: getTownsApi,
  successAction: VendorActions.getTownsSuccess,
  errorAction: VendorActions.getTownsError,
});

function* deleteVendorSaga({ payload }) {
  yield put(LoaderActions.showLoader());
  try {
    const result = yield call(deleteVendorApi, payload);
    if (result.data) {
      const firm = yield select(firmId);
      const city = yield select(cityId);
      yield put(
        VendorActions.deleteVendorSuccess({ firmId: firm, cityId: city }),
      );
      open(
        'success',
        translate('common:notif.successTitle'),
        translate('vendor:deleteVendorSuccess'),
      );
      yield put(history.push(ROUTES.VENDORS));
    }
  } catch (error) {
    yield put(VendorActions.deleteVendorError(error.message));
  }
  yield put(LoaderActions.hideLoader());
}

function* openForSaleSaga({ payload }) {
  yield put(LoaderActions.showLoader());
  try {
    const result = yield call(openForSalesApi, payload);
    if (result.data) {
      const vendor = yield select(vendorId);
      yield put(VendorActions.openForSaleSuccess(vendor));
      open(
        'success',
        translate('common:notif.successTitle'),
        translate('vendor:openForSaleSuccess'),
      );
    } else {
      open('warning', translate('common:notif.warnTitle'), result.message);
    }
  } catch (error) {
    yield put(VendorActions.openForSaleError(error.message));
  }
  yield put(LoaderActions.hideLoader());
}

function* closeForSaleSaga({ payload }) {
  yield put(LoaderActions.showLoader());
  try {
    const result = yield call(closeForSaleApi, payload);
    if (result.data) {
      const vendor = yield select(vendorId);
      yield put(VendorActions.closeForSaleSuccess(vendor));
      open(
        'success',
        translate('common:notif.successTitle'),
        translate('vendor:closeForSaleSuccess'),
      );
    }
  } catch (error) {
    yield put(VendorActions.closeForSaleError(error.message));
  }
  yield put(LoaderActions.hideLoader());
}

const getBrandsSaga = createStandardSaga({
  callApi: getBrandsApi,
  successAction: VendorActions.getBrandsSuccess,
  errorAction: VendorActions.getBrandsError,
});

const getFirmsSaga = createStandardSaga({
  callApi: getFirmsApi,
  successAction: VendorActions.getFirmSuccess,
  errorAction: VendorActions.getFirmError,
});

const getOperationalManagersSaga = createStandardSaga({
  callApi: getOperationalManagersApi,
  successAction: VendorActions.getOperationalManagersSuccess,
  errorAction: VendorActions.getOperationalManagersError,
});

const getRegionManagersSaga = createStandardSaga({
  callApi: getRegionManagersApi,
  successAction: VendorActions.getRegionManagerSuccess,
  errorAction: VendorActions.getRegionManagerError,
});

export function* rootSaga() {
  yield takeLatest(
    [
      Types.VENDOR_LIST_REQUEST,
      Types.DELETE_VENDOR_SUCCESS,
      Types.ADD_VENDOR_SUCCESS,
    ],
    listVendorsSaga,
  );
  yield takeLatest(Types.ADD_VENDOR_REQUEST, addVendorSaga);
  yield takeLatest(Types.EDIT_VENDOR_REQUEST, updateVendorSaga);
  yield takeLatest(Types.DELETE_VENDOR_REQUEST, deleteVendorSaga);
  yield takeLatest(Types.CLOSE_FOR_SALE_REQUEST, closeForSaleSaga);
  yield takeLatest(Types.OPEN_FOR_SALE_REQUEST, openForSaleSaga);
  yield takeLatest(Types.GET_DISTRICTS_REQUEST, getDistrictsSaga);
  yield takeLatest(Types.GET_TOWNS_REQUEST, getTownsSaga);
  yield takeLatest(Types.GET_BRANDS_REQUEST, getBrandsSaga);
  yield takeLatest(Types.GET_FIRM_REQUEST, getFirmsSaga);
  yield takeLatest(
    Types.GET_OPERATIONAL_MANAGER_REQUEST,
    getOperationalManagersSaga,
  );
  yield takeLatest(Types.GET_REGION_MANAGER_REQUEST, getRegionManagersSaga);
  yield takeLatest(Types.EDIT_FINANCIAL_INFO_REQUEST, updateFinancialInfoSaga);
}

export default rootSaga;
