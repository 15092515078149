import styled, { css } from 'styled-components';
import { Select } from 'antd';

import COLORS from 'constants/colors';

// prettier-ignore
const SelectContainer = styled(Select)`
  width: 100%;
  border: none;
  outline: none;
  height: 50px;
  ${
  props =>
    props.mode === 'multiple' &&
        css`
      
          height: auto !important;
          `
}

  .ant-select-selector {
    height: 50px !important;

    ${
  props =>
    props.mode === 'multiple' &&
        css`
          height: auto !important;
          min-height: 50px !important;
          `
}

    border: 2px solid #dfdee2 !important;
    border-radius: 4px !important;

    ${props =>
    // eslint-disable-next-line
      props.errors[props.name] &&
      css`
        border: 2px solid #db4713 !important;
      `}

    &:hover {
      border: 2px solid ${COLORS.mainGetirColor} !important;

      ${props =>
  // eslint-disable-next-line
        props.errors[props.name] &&
        css`
          border: 2px solid #db4713 !important;
        `}
    }
  }

  .ant-select-selection-placeholder {
    line-height: 44px !important;
    color: #838287 !important;
  }

  .ant-select-selection-item,
  .ant-select-selection-search {
    color: #191919 !important;
    padding-top: 8px !important;

  } 
  
  .ant-select-selection-item {
    ${
  props =>
    props.mode === 'multiple' &&
        css`
             padding-top: 0px !important;

          `
}
  }
`;

const ErrorWrapper = styled.div`
  position: absolute;
  right: 5px;
  pointer-events: none;
  top: -4px;
`;

export const SelectBoxPlaceholder = styled.span`
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 3px;
`;

export { SelectContainer, ErrorWrapper };
