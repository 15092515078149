export const BRAND_LIST_REQUEST = 'BRAND_LIST_REQUEST';
export const BRAND_LIST_SUCCESS = 'BRAND_LIST_SUCCESS';
export const BRAND_LIST_ERROR = 'BRAND_LIST_ERROR';

export const ADD_BRAND_REQUEST = 'ADD_BRAND_REQUEST';
export const ADD_BRAND_SUCCESS = 'ADD_BRAND_SUCCESS';
export const ADD_BRAND_ERROR = 'ADD_BRAND_ERROR';

export const EDIT_BRAND_REQUEST = 'EDIT_BRAND_REQUEST';
export const EDIT_BRAND_SUCCESS = 'EDIT_BRAND_SUCCESS';
export const EDIT_BRAND_ERROR = 'EDIT_BRAND_ERROR';

export const DELETE_BRAND_REQUEST = 'DELETE_BRAND_REQUEST';
export const DELETE_BRAND_SUCCESS = 'DELETE_BRAND_SUCCESS';
export const DELETE_BRAND_ERROR = 'DELETE_BRAND_ERROR';

// Bunu Sil
export const SET_BRAND_ID = 'SET_BRAND_ID';

export const BRAND_PRODUCT_LIST_REQUEST = 'BRAND_PRODUCT_LIST_REQUEST';
export const BRAND_PRODUCT_LIST_SUCCESS = 'BRAND_PRODUCT_LIST_SUCCESS';
export const BRAND_PRODUCT_LIST_ERROR = 'BRAND_PRODUCT_LIST_ERROR';

export const BRAND_PRODUCT_LIST_SAVE = 'BRAND_PRODUCT_LIST_SAVE';
export const BRAND_PRODUCT_LIST_SAVE_ERROR = 'BRAND_PRODUCT_LIST_SAVE_ERROR';
export const BRAND_PRODUCT_LIST_SAVE_SUCCESS =
  'BRAND_PRODUCT_LIST_SAVE_SUCCESS';
