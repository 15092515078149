import React from 'react';
import { useSelector } from 'react-redux';
import { Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';

import Info from 'components/v1/Info';

import * as S from '../styles';
import { invoiceStatus } from '../constants';

const ModalDetail = () => {
  const { billingDetail } = useSelector(state => state.billingDetailModal);

  const { t } = useTranslation('billingUploadModal');

  return (
    <div>
      {billingDetail.status === invoiceStatus.CANCELED && (
        <Info key="cancelReason" kind="alert">
          <S.CancelReason>{t('cancelReason')}:</S.CancelReason>
          {billingDetail.cancelReason || null}
        </Info>
      )}
      <S.ImageContainer>
        {billingDetail?.imageUrl ? (
          <img src={billingDetail?.imageUrl} alt="fatura" />
        ) : (
          <Skeleton.Image
            key="skleton"
            active
            style={{
              width: '100%',
            }}
          />
        )}
      </S.ImageContainer>
    </div>
  );
};

export default ModalDetail;
