import { useCallback, useEffect, useRef, useState } from 'react';
import L from 'leaflet';
import { useDispatch, useSelector } from 'react-redux';

import { MAP_COLORS } from '../constant';
import { reverseCoordinates } from '../common';

const useDistrictPolygon = ({ geomapStateKey, Actions, mapInstance }) => {
  const districts = useSelector(state => state[geomapStateKey].districts);

  const selectedDistrict = useSelector(
    state => state[geomapStateKey].selectedDistrict,
  );
  const selectedVendor = useSelector(
    state => state[geomapStateKey].selectedVendor,
  );
  const [isSelected, setIsSelected] = useState(false);
  useEffect(() => {
    if (!selectedDistrict) {
      setIsSelected(false);
    } else {
      setIsSelected(true);
    }
  }, [selectedDistrict]);

  const dispatch = useDispatch();

  const handleDistrictPolygonClick = useCallback(
    polygonData => {
      dispatch(Actions.setSelectedDistrict(polygonData));
      dispatch(
        Actions.geomapGetNeighbourhoodRequest({
          districtId: polygonData.id,
          geojson: true,
        }),
      );
      setIsSelected(true);
    },
    [Actions, dispatch],
  );

  const districtPolygonsRef = useRef([]);

  useEffect(() => {
    if (mapInstance) {
      const polygons = [];
      const pane = mapInstance.createPane('districtPolygon');
      pane.style.zIndex = selectedVendor ? 402 : 400;

      districts.forEach(district => {
        if (isSelected) {
          return;
        }
        const createdPolygon = L.polygon(
          reverseCoordinates(district.polygon[0]),
          {
            fillColor: MAP_COLORS.neigborhood,
            color: '#000000',
            fillOpacity: 0.25,
            weight: 1,
            opacity: 1,
            pane: 'districtPolygon',
          },
        );

        createdPolygon.on('click', () => {
          handleDistrictPolygonClick(district);
        });
        createdPolygon.addTo(mapInstance);
        polygons.push(createdPolygon);
      });
      districtPolygonsRef.current = [...polygons];
    }

    return () => {
      districtPolygonsRef.current.forEach(p => {
        p.remove();
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [districts, isSelected]);
};

export default useDistrictPolygon;
