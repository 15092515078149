import useStateWithProp from './useStateWithProp';

/**
 * A custom hook for handling boolean state.
 * If no default value given then it will be false
 * @param {Boolean} defaultValue Initial value for the hook
 * @returns {[Boolean, { toggle: () => void, setTrue: () => void, setFalse: () => void, setValue: (newValue: Boolean) => void }]}
 */
export default function useBoolean(defaultValue = false) {
  const [value, setValue] = useStateWithProp(Boolean(defaultValue));
  const setValueWhenItsDifferent = newValue => {
    if (value !== newValue) {
      setValue(newValue);
    }
  };
  const updateValue = {
    toggle: () => setValueWhenItsDifferent(!value),
    setTrue: () => setValueWhenItsDifferent(true),
    setFalse: () => setValueWhenItsDifferent(false),
    setValue: setValueWhenItsDifferent,
  };

  return [value, updateValue];
}
