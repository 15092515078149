import { put, takeLatest, call } from 'redux-saga/effects';

import history from 'utils/history';
import { open } from 'utils/notification';
import * as LoaderActions from 'components/Loader/actions';
import {
  listFirmsApi,
  addFirmsApi,
  updateFirmsApi,
  deleteFirmsApi,
  getFirmInfoApi,
} from 'services/Firm/api';
import translate from 'utils/translate';
import * as ROUTES from 'constants/routes';

import * as Types from './types';
import * as FirmActions from './actions';

function* listFirmsSaga() {
  yield put(LoaderActions.showLoader());
  try {
    const result = yield call(listFirmsApi);
    if (result.data) {
      yield put(FirmActions.firmListSuccess(result.data.payload));
    }
  } catch (error) {
    yield put(FirmActions.firmListError(error.message));
  }
  yield put(LoaderActions.hideLoader());
}

function* addFirmSaga({ payload }) {
  yield put(LoaderActions.showLoader());
  try {
    const reqBody = { ...payload };
    const result = yield call(addFirmsApi, reqBody);
    if (result.data) {
      yield put(FirmActions.addFirmSuccess(result.data.payload));
      open(
        'success',
        translate('common:notif.successTitle'),
        translate('firm:createFirmSuccess'),
      );
      history.push(ROUTES.FIRMS);
    } else {
      yield put(open('warning', translate('notif.warnTitle'), result.message));
    }
  } catch (error) {
    yield put(FirmActions.addFirmError(error.message));
  }
  yield put(LoaderActions.hideLoader());
}

function* updateFirmSaga({ payload }) {
  yield put(LoaderActions.showLoader());
  try {
    const reqBody = { ...payload };
    const result = yield call(updateFirmsApi, reqBody);
    if (result.data) {
      yield put(FirmActions.updateFirmSuccess(result.data.payload));
      open(
        'success',
        translate('common:notif.successTitle'),
        translate('firm:updateFirmSuccess'),
      );
    } else {
      yield put(open('warning', translate('notif.warnTitle'), result.message));
    }
  } catch (error) {
    yield put(FirmActions.updateFirmError(error.message));
  }
  yield put(LoaderActions.hideLoader());
}

function* deleteFirmSaga({ payload }) {
  yield put(LoaderActions.showLoader());
  try {
    const result = yield call(deleteFirmsApi, payload);
    if (result.data) {
      yield put(FirmActions.deleteFirmSuccess(result.data.payload));
      yield put(history.push(ROUTES.FIRMS));
    } else {
      yield put(open('warning', translate('notif.warnTitle'), result.message));
    }
  } catch (error) {
    yield put(FirmActions.deleteFirmError(error.message));
  }
  yield put(LoaderActions.hideLoader());
}

function* getFirmInfoSaga({ payload }) {
  yield put(LoaderActions.showLoader());
  try {
    const result = yield call(getFirmInfoApi, payload);
    if (result.data) {
      yield put(FirmActions.getFirmInfoSuccess(result.data.payload));
    }
  } catch (error) {
    yield put(FirmActions.getFirmInfoError(error.message));
  }
  yield put(LoaderActions.hideLoader());
}

export function* rootSaga() {
  yield takeLatest(Types.FIRM_LIST_REQUEST, listFirmsSaga);
  yield takeLatest(Types.ADD_FIRM_REQUEST, addFirmSaga);
  yield takeLatest(Types.EDIT_FIRM_REQUEST, updateFirmSaga);
  yield takeLatest(Types.DELETE_FIRM_REQUEST, deleteFirmSaga);
  yield takeLatest(Types.GET_FIRM_INFO_REQUEST, getFirmInfoSaga);
  yield takeLatest(Types.DELETE_FIRM_SUCCESS, listFirmsSaga);
}

export default rootSaga;
