import { MODAL_MODES } from './constants';
import * as Types from './types';

const initialState = {
  orderId: undefined,
  error: {},
  modalDetail: {
    isVisible: false,
    mode: '',
  },
  isSaveButtonDisabled: true,
  isCancelButtonDisabled: false,
  isSuccessful: false,
  isUploading: false,
  isImageDeleteSuccessful: false,
  billingDetail: {},
};

function BillingDetailReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.BILLING_DETAIL_DELETE_IMAGE_SUCCESS:
      return {
        ...state,
        isImageDeleteSuccessful: payload,
        modalDetail: {
          isVisible: true,
          mode: MODAL_MODES.UPLOAD,
        },
        isSuccessful: false,
      };
    case Types.BILLING_DETAIL_GET_BILLING_IMAGE_SUCCESS:
      return {
        ...state,
        billingDetail: {
          imageUrl: payload,
        },
      };
    case Types.BILLING_DETAIL_BILLING_DETAIL_SUCCESS:
      return {
        ...state,
        billingDetail: {
          ...state.billingDetail,
          ...payload,
        },
      };
    case Types.BILLING_DETAIL_OPEN:
      return {
        ...state,
        orderId: payload.orderId,
        modalDetail: {
          isVisible: true,
          mode: payload.mode,
        },
      };
    case Types.BILLING_DETAIL_CLOSE:
      return {
        ...state,
        showModal: false,
        orderId: undefined,
        modalDetail: {
          isVisible: false,
          mode: '',
        },
      };
    case Types.BILLING_DETAIL_SET_MODAL_MODE:
      return {
        ...state,
        modalDetail: {
          ...state.modalDetail,
          mode: payload,
        },
      };
    case Types.BILLING_DETAIL_RESET_STATES:
      return {
        ...initialState,
      };
    case Types.BILLING_DETAIL_UPLOAD_ERROR:
      return {
        ...state,
        error: { ...payload },
      };
    case Types.BILLING_DETAIL_SAVE_BUTTON_DISABLED:
      return {
        ...state,
        isSaveButtonDisabled: payload,
      };
    case Types.BILLING_DETAIL_CANCEL_BUTTON_DISABLED:
      return {
        ...state,
        isCancelButtonDisabled: payload,
      };
    case Types.BILLING_DETAIL_IS_UPLOADING:
      return {
        ...state,
        isUploading: payload,
      };
    case Types.BILLING_DETAIL_IS_SUCCESSFUL:
      return {
        ...state,
        isSuccessful: payload,
      };
    default:
      return state;
  }
}

export default BillingDetailReducer;
