import { getOrdersByType } from 'constants/getOrdersByType';

export const getOrderType = business => {
  if (business) {
    if (business.isFirm) {
      return getOrdersByType.FIRM;
    }
    if (!business.isFirm) {
      return getOrdersByType.VENDOR;
    }
  }

  return undefined;
};
