import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Footer from 'components/Footer';
import useIsAuthenticated from 'hooks/useIsAuthenticated';
import * as ROUTES from 'constants/routes';
import LoginWaterImage from 'common/svg/loginWater.svg';
import { CLEAR_ALL_DATA } from 'store/rootTypes';
import { openStatusPollStop } from 'components/MainHeader/actions';
import {
  OLD_GETIRWATER_PANEL_URLS,
  CURRENT_GETIRWATER_PANEL_URL,
} from 'common/constants';

import LoginStep from './LoginStep';
import ForgotPassword from './ForgotPassword';
import LoginResult from './LoginResult';
import NewPassword from './NewPassword';
import * as LoginActions from './actions';
import * as S from './style';
import { loginSteps } from './constants';

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isLoggedIn } = useIsAuthenticated();
  const forgotPasswordStatus = useSelector(
    state => state.login.forgotPassword.status,
  );
  const newPasswordStatus = useSelector(
    state => state.login.newPassword.status,
  );
  const step = useSelector(state => state.login.step);
  const forgotPasswordResult = useSelector(
    state => state.login.forgotPassword.payload,
  );

  useEffect(() => {
    const { hostname } = window.location;
    if (OLD_GETIRWATER_PANEL_URLS.includes(hostname)) {
      window.location.href = `https://${CURRENT_GETIRWATER_PANEL_URL}`;
    }
  }, []);

  useEffect(() => {
    dispatch(openStatusPollStop());
    dispatch({
      type: CLEAR_ALL_DATA,
    });
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    if (isLoggedIn) {
      history.push(ROUTES.HOME);
    }
  }, [history, isLoggedIn]);

  const handleForgotPasswordClick = () => {
    dispatch(LoginActions.updateStep(loginSteps.ForgotPassword));
  };

  const handleBackClick = () => {
    dispatch(LoginActions.updateStep(loginSteps.Login));
  };

  const handleLoginResultOkClick = () => {
    dispatch(LoginActions.updateStep(loginSteps.Login));
    history.push(ROUTES.LOGIN);
  };

  const steps = [
    {
      stepCode: loginSteps.Login,
      component: (
        <LoginStep onForgotPasswordClick={handleForgotPasswordClick} />
      ),
    },
    {
      stepCode: loginSteps.ForgotPassword,
      component: <ForgotPassword onBackClick={handleBackClick} />,
    },
    {
      stepCode: loginSteps.ForgotPasswordResult,
      component: (
        <LoginResult
          status={forgotPasswordStatus}
          text={forgotPasswordResult}
          onOkClick={handleLoginResultOkClick}
        />
      ),
    },
    {
      stepCode: loginSteps.NewPassword,
      component: <NewPassword />,
    },
    {
      stepCode: loginSteps.NewPasswordResult,
      component: (
        <LoginResult
          status={newPasswordStatus}
          text="Yeni şifren başarıyla oluşturuldu."
          onOkClick={handleLoginResultOkClick}
        />
      ),
    },
  ];

  return (
    <S.MainWrapper>
      <S.LoginLeftContainer>
        <S.LoginWelcomeImage
          src={LoginWaterImage}
          alt="getirsu"
          title="getirsu"
        />
      </S.LoginLeftContainer>
      <S.LoginRightContainer>{steps[step].component}</S.LoginRightContainer>
      <Footer />
    </S.MainWrapper>
  );
};

export default Login;
