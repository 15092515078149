import React from 'react';
import { Button } from '@getir/web-components';
import { useHistory } from 'react-router-dom';

import * as ROUTES from 'constants/routes';
import Layout from 'components/Layout';

import * as S from './style';

const NotFound = () => {
  const history = useHistory();

  return (
    <Layout>
      <S.Error404Body className="not-found-component">
        <S.Error404MainContainer>
          <S.Error404Container>
            <S.Error404Content>
              <S.Error404Title>Aradığınız Sayfa Bulunamadı.</S.Error404Title>
              <S.Error404Description>
                Böyle bir sayfa yok. Ana Sayfa’ya dönüp başka bir şey
                arayabilirsiniz.
              </S.Error404Description>
              <Button onClick={() => history.push(ROUTES.HOME)} size="medium">
                Ana Sayfa&apos;ya Dön
              </Button>
            </S.Error404Content>
            <S.Error404ImageContainer>
              <S.Error404PickerImage
                src="https://panel-fe.artisandev.getirapi.com/2d4986a649301195451c.svg"
                alt="notfound"
                title="notfound"
              />
            </S.Error404ImageContainer>
          </S.Error404Container>
        </S.Error404MainContainer>
      </S.Error404Body>
    </Layout>
  );
};

export default NotFound;
