import { fork } from 'redux-saga/effects';

import billingDetailModalSagas from 'components/v1/BillingDetailModal/saga';
import AlternativeMapHoursSaga from 'containers/GeoMap/AlternativeMapHours/saga';
import loginSaga from 'containers/Auth/Login/saga';
import firmSaga from 'containers/Stores/Firm/saga';
import vendorSaga from 'containers/Stores/Vendor/saga';
import usersSaga from 'containers/StoreDetails/Users/saga';
import headerSaga from 'components/Header/saga';
import brandSaga from 'containers/Stores/Brands/saga';
import catalogProductSaga from 'containers/CategoryManagement/CatalogProduct/saga';
import mainHeaderSaga from 'components/MainHeader/saga';
import vendorproductSaga from 'containers/VendorProduct/saga';
import paymentMethodsSaga from 'containers/PaymentMethods/saga';
import workingHoursSaga from 'containers/WorkingHours/saga';
import categorySaga from 'containers/CategoryManagement/Category/saga';
import activeOrdersSaga from 'containers/ActiveOrders/saga';
import previousOrdersSaga from 'containers/PreviousOrders/saga';
import orderdetailmodalSaga from 'components/OrderDetailModal/saga';
import financialTransactionsSaga from 'containers/Payments/FinancialTransactions/saga';
import mapmodalSaga from 'components/MapModal/saga';
import missingproductSaga from 'components/MissingProductModal/saga';
import requestSaga from 'containers/Requests/saga';
import bulkChangeSaga from 'containers/BulkChange/saga';
import uploadGeoMapSaga from 'containers/GeoMap/UploadGeoMap/saga';
import BanGeoMapSaga from 'containers/GeoMap/BanGeoMap/saga';
import courierListModalSaga from 'components/CourierListModal/saga';
import undertakingModalSaga from 'components/UndertakingModal/saga';
import financialInformationSaga from 'containers/StoreDetails/FinancialInformation/saga';
import addFinancialTransactionSaga from 'containers/Payments/AddFinancialTransaction/saga';
import authRolesSaga from 'containers/UsersPerms/Roles/saga';
import authUsersSaga from 'containers/UsersPerms/Users/saga';
import authPermsSaga from 'containers/UsersPerms/Perms/saga';
import vendorCommunicationSaga from 'containers/VendorCommunication/saga';
import kuzeydenReportSaga from 'containers/KuzeydenReports/saga';

function* rootSaga() {
  yield fork(loginSaga);
  yield fork(headerSaga);
  yield fork(brandSaga);
  yield fork(firmSaga);
  yield fork(catalogProductSaga);
  yield fork(mainHeaderSaga);
  yield fork(usersSaga);
  yield fork(vendorproductSaga);
  yield fork(paymentMethodsSaga);
  yield fork(workingHoursSaga);
  yield fork(categorySaga);
  yield fork(vendorSaga);
  yield fork(activeOrdersSaga);
  yield fork(previousOrdersSaga);
  yield fork(orderdetailmodalSaga);
  yield fork(financialTransactionsSaga);
  yield fork(mapmodalSaga);
  yield fork(missingproductSaga);
  yield fork(requestSaga);
  yield fork(bulkChangeSaga);
  yield fork(uploadGeoMapSaga);
  yield fork(AlternativeMapHoursSaga);
  yield fork(BanGeoMapSaga);
  yield fork(courierListModalSaga);
  yield fork(undertakingModalSaga);
  yield fork(financialInformationSaga);
  yield fork(addFinancialTransactionSaga);
  yield fork(authRolesSaga);
  yield fork(authUsersSaga);
  yield fork(authPermsSaga);
  yield fork(billingDetailModalSagas);
  yield fork(vendorCommunicationSaga);
  yield fork(kuzeydenReportSaga);
}

export default rootSaga;
