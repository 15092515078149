import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/v1/Button';

import * as S from './style';

const ConfirmModal = ({
  onSave,
  onClose,
  title,
  content,
  cancelText,
  okText,
  visibleFooter,
  visible,
  ...props
}) => (
  <S.StyledModal
    isOpen={visible}
    width={500}
    headerOptions={{
      title,
    }}
    footerOptions={{
      visible: visibleFooter,
      customFooter: visibleFooter ? (
        <S.FooterContainer>
          <Button kind="secondary" onClick={onClose}>
            {cancelText}
          </Button>
          <Button onClick={onSave}>{okText}</Button>
        </S.FooterContainer>
      ) : undefined,
    }}
    onCancel={onClose}
    {...props}
  >
    {content}
  </S.StyledModal>
);

ConfirmModal.propTypes = {
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  title: PropTypes.string,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.bool,
  ]),
  cancelText: PropTypes.string,
  okText: PropTypes.string,
  visibleFooter: PropTypes.bool,
  visible: PropTypes.bool.isRequired,
};

ConfirmModal.defaultProps = {
  onSave: () => {},
  onClose: () => {},
  title: '',
  content: '' || undefined,
  cancelText: 'Vazgeç',
  okText: 'Onayla',
  visibleFooter: true,
};

export default ConfirmModal;
