export const SEND_SMS_COURIER_REQUEST = 'SEND_SMS_COURIER_REQUEST';
export const SEND_SMS_COURIER_SUCCESS = 'SEND_SMS_COURIER_SUCCESS';
export const SEND_SMS_COURIER_ERROR = 'SEND_SMS_COURIER_ERROR';
export const SEND_SMS_COURIER_RESET = 'SEND_SMS_COURIER_RESET';

export const SEND_SMS_MODAL_OPEN = 'SEND_SMS_MODAL_OPEN';
export const SEND_SMS_MODAL_CLOSE = 'SEND_SMS_MODAL_CLOSE';

export const ORDER_DETAIL_REQUEST = 'COURIER_LIST_ORDERDETAIL_REQUEST';
export const ORDER_DETAIL_SUCCESS = 'COURIER_LIST_ORDERDETAIL_SUCCESS';
export const ORDER_DETAIL_ERROR = 'COURIER_LIST_ORDERDETAIL_ERROR';
export const ORDER_DETAIL_RESET = 'COURIER_LIST_ORDERDETAIL_RESET';

export const GET_COURIER_LIST_REQUEST = 'GET_COURIER_LIST_REQUEST';
export const GET_COURIER_LIST_SUCCESS = 'GET_COURIER_LIST_SUCCESS';
export const GET_COURIER_LIST_ERROR = 'GET_COURIER_LIST_ERROR';
export const GET_COURIER_LIST_RESET = 'GET_COURIER_LIST_RESET';
