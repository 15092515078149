import styled from 'styled-components';

import COLORS from 'constants/colors';

const LinkWrapper = styled.p`
  margin: 0px;
  color: rgb(93, 62, 188);
  letter-spacing: 0.1px;
  text-align: left;
  font-size: 12px;
`;

const Link = styled.a`
  color: ${COLORS.mainGetirColor};
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
`;

export { LinkWrapper, Link };
