import React, { useEffect, useImperativeHandle, useState } from 'react';
import { useSelector } from 'react-redux';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { Table } from 'antd';

import { arrayMove } from 'utils/arrayMove';

import { useOrderListBrandsColumns } from './useOrderListBrandsColumns';
import './orderList.css';

const OrderList = (properties, ref) => {
  const columns = useOrderListBrandsColumns();
  const products = useSelector(state => state.brand.products);
  const [productsState, setProductsState] = useState([]);

  const SortableItem = SortableElement(props => <tr {...props} />);
  const SortableItemContainer = SortableContainer(props => (
    <tbody {...props} />
  ));

  useImperativeHandle(ref, () => productsState, [productsState]);

  useEffect(() => {
    if (products)
      setProductsState(products.map(item => ({ ...item, key: item.id })));
  }, [products]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const tmpproducts = arrayMove(
        [].concat(productsState),
        oldIndex,
        newIndex,
      ).filter(el => !!el);

      setProductsState(tmpproducts);
    }
  };

  const DraggableContainer = props => (
    <SortableItemContainer
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ ...restProps }) => {
    const index = productsState?.findIndex(
      x => x.id === restProps['data-row-key'],
    );

    return <SortableItem index={index} {...restProps} />;
  };

  return (
    <Table
      pagination={false}
      dataSource={productsState || []}
      columns={columns}
      rowKey="id"
      components={{
        body: {
          wrapper: DraggableContainer,
          row: DraggableBodyRow,
        },
      }}
    />
  );
};

export default React.forwardRef(OrderList);
