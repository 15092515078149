import { useTranslation } from 'react-i18next';

export const useValidations = () => {
  const { t } = useTranslation();
  const validationRules = {
    categoryNameTR: {
      required: t('requiredFieldText'),
    },
    categoryNameEN: {
      required: t('requiredFieldText'),
    },
    vatRate: {
      required: t('requiredFieldText'),
    },
  };

  return validationRules;
};
