import { takeLatest } from 'redux-saga/effects';

import { createStandardSaga } from 'utils/createStandardSaga';
import {
  addProductApi,
  deleteProductApi,
  getCatalogBrandByFirmId,
  listProductsApi,
  updateProductApi,
} from 'services/CatalogProduct/api';
import * as ROUTES from 'constants/routes';

import * as Types from './types';
import * as CatalogProductActions from './actions';

const listProductsSaga = createStandardSaga({
  callApi: listProductsApi,
  successAction: CatalogProductActions.catalogProductListSuccess,
  errorAction: CatalogProductActions.catalogProductListError,
});

const addProductSaga = createStandardSaga({
  callApi: addProductApi,
  successAction: CatalogProductActions.addCatalogProductSuccess,
  errorAction: CatalogProductActions.addCatalogProductError,
  successPushRoute: ROUTES.LIST_CATALOG,
  successMessage: 'catalogs:addProductSuccess',
});

const updateProductSaga = createStandardSaga({
  callApi: updateProductApi,
  successAction: CatalogProductActions.updateCatalogProductSuccess,
  errorAction: CatalogProductActions.updateCatalogProductError,
  successPushRoute: ROUTES.LIST_CATALOG,
  successMessage: 'catalogs:updateProductSuccess',
});

const deleteProductSaga = createStandardSaga({
  callApi: deleteProductApi,
  successAction: CatalogProductActions.deleteCatalogProductSuccess,
  errorAction: CatalogProductActions.deleteCatalogProductError,
  successMessage: 'catalogs:deleteProductSuccess',
});

const catalogBrandSaga = createStandardSaga({
  callApi: getCatalogBrandByFirmId,
  successAction: CatalogProductActions.catalogBrandSuccess,
  errorAction: CatalogProductActions.catalogBrandError,
});

export function* rootSaga() {
  yield takeLatest(Types.CATALOGPRODUCT_LIST_REQUEST, listProductsSaga);
  yield takeLatest(Types.ADD_CATALOGPRODUCT_REQUEST, addProductSaga);
  yield takeLatest(Types.EDIT_CATALOGPRODUCT_REQUEST, updateProductSaga);
  yield takeLatest(Types.DELETE_CATALOGPRODUCT_REQUEST, deleteProductSaga);
  yield takeLatest(Types.CATALOGPRODUCT_BRAND_REQUEST, catalogBrandSaga);
}

export default rootSaga;
