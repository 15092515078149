export const MODAL_MODES = Object.freeze({
  UPLOAD: 'UPLOAD',
  DELETE: 'DELETE',
  ADMIN_REFUSE: 'ADMIN_REFUSE',
  DETAIL: 'DETAIL',
});

export const invoiceStatus = Object.freeze({
  PENDING: 1,
  APPROVED: 2,
  CANCELED: 3,
  DELETED: 4,
});

export const authorizedComponentIds = Object.freeze({
  deleteButton: 'invoice_delete',
  uploadButton: 'invoice_upload',
  invoice_update: 'invoice_update',
});
