import React, { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Layout from 'components/Layout';
import OrderDetailModal from 'components/OrderDetailModal';
import useUserInfo from 'hooks/useUserInfo';
import { rolesWithName } from 'constants/roles';
import BillingInformationModal from 'components/BillingInformationModal';

import * as S from './style';
import ActiveList from './ActiveList/ActiveList';
import CompletedOrders from './CompletedOrders';
import CanceledOrders from './CanceledOrders';
import SatisfactionPopup from './SatisfactionPopup';
import DelayedOrders from './DelayedOrders';
import { useGetCompletedOrderList } from './CompletedOrders/useGetCompletedOrderList';
import { useGetCanceledOrderList } from './CanceledOrders/useGetCanceledOrderList';
import { useGetScheduledOrderList } from './ScheduledOrders/useGetScheduledOrderList';
import ScheduledOrders from './ScheduledOrders';

const ActiveOrders = () => {
  const { t } = useTranslation();
  const activeOrdersList = useSelector(state => state.activeOrders.list);
  const delayedOrderListCount = useSelector(
    state => state.activeOrders.delayedList,
  ).length;

  const { userInfo } = useUserInfo();
  const { completedList: completedOrdersList } = useSelector(
    state => state.activeOrders,
  );
  const canceledOrderList = useSelector(
    state => state.activeOrders.canceledList,
  );
  const scheduledOrdersList = useSelector(
    state => state.activeOrders.scheduledOrderList,
  );

  const orderListCount = useMemo(() => {
    if (userInfo && userInfo.role && userInfo.role === rolesWithName.CARRIER) {
      const { userId } = userInfo;

      const filteredOrderList = activeOrdersList.filter(
        order => order.courierId && order.courierId === userId,
      );

      return filteredOrderList.length;
    }

    return activeOrdersList.length;
  }, [activeOrdersList, userInfo]);

  const { getCompletedOrderListCallback } = useGetCompletedOrderList();
  const { getCanceledOrderListCallback } = useGetCanceledOrderList();
  const { getScheduledOrderListCallback } = useGetScheduledOrderList();

  useEffect(() => {
    getScheduledOrderListCallback();
  }, [getScheduledOrderListCallback]);

  useEffect(() => {
    getCompletedOrderListCallback();
  }, [getCompletedOrderListCallback]);

  useEffect(() => {
    getCanceledOrderListCallback();
  }, [getCanceledOrderListCallback]);

  const scheduledOrderListCount = useMemo(
    () =>
      scheduledOrdersList && scheduledOrdersList.length
        ? scheduledOrdersList.length
        : 0,
    [scheduledOrdersList],
  );

  const completedListCount = useMemo(
    () =>
      completedOrdersList && completedOrdersList.length
        ? completedOrdersList.length
        : 0,
    [completedOrdersList],
  );

  const canceledOrderListCount = useMemo(
    () =>
      canceledOrderList && canceledOrderList.length
        ? canceledOrderList.length
        : 0,
    [canceledOrderList],
  );
  const items = useMemo(
    () => [
      {
        key: 'activeOrders',
        label: (
          <S.TabContainer>
            {t('activeOrders:pageTitle')}
            <S.CountContainer>{orderListCount}</S.CountContainer>
          </S.TabContainer>
        ),
        children: <ActiveList />,
      },
      {
        key: 'scheduledOrders',
        label: (
          <S.TabContainer>
            {t('activeOrders:scheduledOrders')}
            <S.CountContainer>{scheduledOrderListCount}</S.CountContainer>
          </S.TabContainer>
        ),
        children: <ScheduledOrders />,
      },
      {
        key: 'delayedOrders',
        label: (
          <S.TabContainer>
            {t('activeOrders:delayedOrders')}
            <S.CountContainer id="delayedOrders">
              {delayedOrderListCount}
            </S.CountContainer>
          </S.TabContainer>
        ),
        children: <DelayedOrders />,
      },
      {
        key: 'completedOrders',
        label: (
          <S.TabContainer>
            {t('activeOrders:completedOrders')}
            <S.CountContainer>{completedListCount}</S.CountContainer>
          </S.TabContainer>
        ),
        children: <CompletedOrders />,
      },
      {
        key: 'canceledOrders',
        label: (
          <S.TabContainer>
            {t('activeOrders:canceledOrders')}
            <S.CountContainer>{canceledOrderListCount}</S.CountContainer>
          </S.TabContainer>
        ),
        children: <CanceledOrders />,
      },
    ],
    [
      canceledOrderListCount,
      completedListCount,
      delayedOrderListCount,
      orderListCount,
      scheduledOrderListCount,
      t,
    ],
  );

  return (
    <Layout isActiveOrderPage>
      <BillingInformationModal />
      <SatisfactionPopup />
      <S.ActiveOrderContent>
        <S.Tabs moreIcon={null} defaultActiveKey="activeOrders" items={items} />
        <OrderDetailModal />
      </S.ActiveOrderContent>
    </Layout>
  );
};

export default ActiveOrders;
