const FIRM_LIST_REQUEST = 'FIRM_LIST_REQUEST';
const FIRM_LIST_SUCCESS = 'FIRM_LIST_SUCCESS';
const FIRM_LIST_ERROR = 'FIRM_LIST_ERROR';

const ADD_FIRM_REQUEST = 'ADD_FIRM_REQUEST';
const ADD_FIRM_SUCCESS = 'ADD_FIRM_SUCCESS';
const ADD_FIRM_ERROR = 'ADD_FIRM_ERROR';

const EDIT_FIRM_REQUEST = 'EDIT_FIRM_REQUEST';
const EDIT_FIRM_SUCCESS = 'EDIT_FIRM_SUCCESS';
const EDIT_FIRM_ERROR = 'EDIT_FIRM_ERROR';

const DELETE_FIRM_REQUEST = 'DELETE_FIRM_REQUEST';
const DELETE_FIRM_SUCCESS = 'DELETE_FIRM_SUCCESS';
const DELETE_FIRM_ERROR = 'DELETE_FIRM_ERROR';

const SET_FIRM_ID = 'SET_FIRM_ID';

const GET_FIRM_INFO_REQUEST = 'GET_FIRM_INFO_REQUEST';
const GET_FIRM_INFO_SUCCESS = 'GET_FIRM_INFO_SUCCESS';
const GET_FIRM_INFO_ERROR = 'GET_FIRM_INFO_ERROR';

export {
  FIRM_LIST_REQUEST,
  FIRM_LIST_SUCCESS,
  FIRM_LIST_ERROR,
  ADD_FIRM_REQUEST,
  ADD_FIRM_SUCCESS,
  ADD_FIRM_ERROR,
  EDIT_FIRM_REQUEST,
  EDIT_FIRM_SUCCESS,
  EDIT_FIRM_ERROR,
  DELETE_FIRM_SUCCESS,
  DELETE_FIRM_REQUEST,
  DELETE_FIRM_ERROR,
  SET_FIRM_ID,
  GET_FIRM_INFO_REQUEST,
  GET_FIRM_INFO_SUCCESS,
  GET_FIRM_INFO_ERROR,
};
