import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const OptionSection = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  .ant-picker {
    width: 100%;
    height: 48px;
  }
`;

export const OptionLabel = styled.label`
  font-size: 14px;
  font-weight: 400;
  color: #000;
  width: 30%;
  max-width: 30%;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 16px;
  margin-bottom: -32px;
`;
