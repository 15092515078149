import React from 'react';
import { useTranslation } from 'react-i18next';

import Layout from 'components/Layout';
import * as CS from 'common/style';

import List from './List';

const Perms = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <CS.PageHeader>
        <CS.PageTitle>{t('authPermsPage:pageTitle')}</CS.PageTitle>
      </CS.PageHeader>
      <CS.PageBody>
        <List />
      </CS.PageBody>
    </Layout>
  );
};

export default Perms;
