import styled from 'styled-components';

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

export const FooterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  align-items: center;
  width: 100%;
  button {
    padding: 14px 18px;
  }

  #delete-button {
    background-color: #d13333;
    color: white;
  }
  #accept-button {
    background-color: #ebf6ed;
    color: #008c20;
    font-weight: 700;
  }
  #denide-button {
    background-color: #ffefef;
    color: #d13333;
    font-weight: 700;
  }
`;

export const AdminController = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 16px;
  align-items: center;
  flex-grow: 1;
`;

export const ImageContainer = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
  img {
    width: 100%;
  }
`;

export const CancelReason = styled.span`
  font-weight: 700;
  margin-right: 6px;
`;
