import { takeLatest } from 'redux-saga/effects';

import { createStandardSaga } from 'utils/createStandardSaga';
import {
  getBrandAvailableHoursApi,
  updateBrandAvailableHoursApi,
} from 'services/Brand/api';

import * as Types from './types';
import * as AlternativeHoursActions from './actions';

const alternativeMapGetSaga = createStandardSaga({
  callApi: getBrandAvailableHoursApi,
  successAction: AlternativeHoursActions.getAlternativeHoursSuccess,
  errorAction: AlternativeHoursActions.getAlternativeHoursError,
});

const alternativeMapUpdateSaga = createStandardSaga({
  callApi: updateBrandAvailableHoursApi,
  successAction: AlternativeHoursActions.updateAlternativeHoursSuccess,
  errorAction: AlternativeHoursActions.updateAlternativeHoursError,
  successMessage: 'geomap:updateAlternativeMapHoursSuccessMessage',
});

export function* rootSaga() {
  yield takeLatest(Types.ALTERNATIVE_HOURS_GET_REQUEST, alternativeMapGetSaga);
  yield takeLatest(
    Types.ALTERNATIVE_HOURS_UPDATE_REQUEST,
    alternativeMapUpdateSaga,
  );
}

export default rootSaga;
