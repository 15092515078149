import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Document, Page, pdfjs } from 'react-pdf';
import { Pagination, Typography } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import * as CS from 'common/style';

import * as S from './style';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const { Text } = Typography;

const HelpCarousel = () => {
  const { t } = useTranslation();
  const [numPageCount, setNumPageCount] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPageCount(numPages);
  };

  const changePage = toPage => {
    setPageNumber(toPage);
  };

  const itemRender = (_, type, originalElement) => {
    if (type === 'prev') {
      return (
        <S.PaginationLeftArrow>
          <LeftOutlined />
          <Text>{t('prev')}</Text>
        </S.PaginationLeftArrow>
      );
    }

    if (type === 'next') {
      return (
        <S.PaginationRightArrow>
          <Text>{t('next')}</Text>
          <RightOutlined />
        </S.PaginationRightArrow>
      );
    }

    return originalElement;
  };

  return (
    <CS.PageBody>
      <S.PdfContent>
        <Document
          file="https://cdn.getir.com/getir-water-images/GetirSu_Isletme_Yonetim_Paneli.pdf"
          onLoadSuccess={onDocumentLoadSuccess}
          options={{
            cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
            cMapPacked: true,
          }}
          loading={t('help:pleaseWait')}
        >
          <Page pageNumber={pageNumber} />
        </Document>
      </S.PdfContent>
      <S.PaginationArea>
        {numPageCount && (
          <Pagination
            defaultCurrent={pageNumber}
            defaultPageSize={1}
            onChange={changePage}
            showSizeChanger={false}
            total={numPageCount}
            itemRender={itemRender}
          />
        )}
      </S.PaginationArea>
    </CS.PageBody>
  );
};

export default HelpCarousel;
