import { createAction } from 'utils/createAction';

import * as Types from './types';

// get Order Detail
export const getOrderDetailRequest = createAction(Types.ORDERDETAIL_REQUEST);
export const getOrderDetailSuccess = createAction(Types.ORDERDETAIL_SUCCESS);
export const getOrderDetailError = createAction(Types.ORDERDETAIL_ERROR);
export const resetOrderDetail = createAction(Types.ORDERDETAIL_RESET);

// get Order Detail With Actions
export const getOrderDetailWithActionsRequest = createAction(
  Types.ORDERDETAILWITHACTIONS_REQUEST,
);
export const getOrderDetailWithActionsSuccess = createAction(
  Types.ORDERDETAILWITHACTIONS_SUCCESS,
);
export const getOrderDetailWithActionsError = createAction(
  Types.ORDERDETAILWITHACTIONS_ERROR,
);

// get address note
export const getAddressNoteRequest = createAction(
  Types.GET_ADDRESS_NOTE_REQUEST,
);
export const getAddressNoteSuccess = createAction(
  Types.GET_ADDRESS_NOTE_SUCCESS,
);
export const getAddressNoteError = createAction(Types.GET_ADDRESS_NOTE_ERROR);

// update address note
export const updateAddressNoteRequest = createAction(
  Types.UPDATE_ADDRESS_NOTE_REQUEST,
);
export const updateAddressNoteSuccess = createAction(
  Types.UPDATE_ADDRESS_NOTE_SUCCESS,
);
export const updateAddressNoteError = createAction(
  Types.UPDATE_ADDRESS_NOTE_ERROR,
);

// create address note
export const createAddressNoteRequest = createAction(
  Types.CREATE_ADDRESS_NOTE_REQUEST,
);
export const createAddressNoteSuccess = createAction(
  Types.CREATE_ADDRESS_NOTE_SUCCESS,
);
export const createAddressNoteError = createAction(
  Types.CREATE_ADDRESS_NOTE_ERROR,
);

// delete address note
export const deleteAddressNoteRequest = createAction(
  Types.DELETE_ADDRESS_NOTE_REQUEST,
);
export const deleteAddressNoteSuccess = createAction(
  Types.DELETE_ADDRESS_NOTE_SUCCESS,
);
export const deleteAddressNoteError = createAction(
  Types.DELETE_ADDRESS_NOTE_ERROR,
);
