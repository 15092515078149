import styled from 'styled-components';
import { Input } from '@getir/web-components';

import { StyledModal } from 'containers/Requests/style';

const ContentRow = styled.div`
  display: grid;
  grid-template-columns: 90px 1fr;
  gap: 12px;
`;

const ContentRowPrice = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 16px;
  margin-bottom: initial;
`;

const ContentRowName = styled.div`
  color: rgb(62, 62, 62);
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
`;

const ContentRowValue = styled.div`
  color: rgb(62, 62, 62);
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
`;

const ContentRowValuePrice = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-top: initial;
  margin-bottom: initial;

  ${Input.S.Input} {
    border: none;
  }
`;
export {
  StyledModal,
  ContentRow,
  ContentRowPrice,
  ContentRowName,
  ContentRowValue,
  ContentRowValuePrice,
};
