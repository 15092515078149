import styled from 'styled-components';
import { Table } from 'antd';
import { Icon } from '@getir/web-components';

import breakpoint from 'constants/breakpoint';

export const FiltersArea = styled.div`
  gap: 16px;
  display: grid;
  grid-template-columns: 270px 1fr;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;

  @media (max-width: ${breakpoint.MOBILE.MAX}px) {
    grid-template-columns: 1fr;
  }

  .ant-space {
    justify-self: end;
  }
`;

export const StyledTable = styled(Table)`
  .ant-table-thead {
    tr {
      th {
        background: #eaeaea !important;
      }
    }
  }

  .ant-table-summary {
    font-weight: 600;
    background: #eaeaea;
  }
`;

export const StyledIcon = styled(Icon)`
  margin-right: 5px;
  cursor: pointer;
`;
