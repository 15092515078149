import styled from 'styled-components';

import Button from 'components/v1/Button';

export const FullHeihgthButton = styled(Button)`
  height: 50px;
`;

export const FormWrapper = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const FormContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
  align-items: end;
  width: 100%;
  gap: 6px;
`;

export const SelectBoxContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
`;

export const FormTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  margin: 0;
`;

export const AlertWrapper = styled.div`
  width: 100%;
  margin-bottom: 8px 0px;
`;
