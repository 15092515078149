import { findIsIntervalMixed } from './common';

export const findIfThereIsAnyMixedTimeError = alternativeMapIntervals => {
  for (let i = 0; i < alternativeMapIntervals.length; i += 1) {
    const { intervals } = alternativeMapIntervals[i];
    for (let j = 0; j < intervals.length; j += 1) {
      const { startTime, endTime } = intervals[j];
      if (findIsIntervalMixed({ startTime, endTime })) {
        return true;
      }
    }
  }

  return false;
};
