import styled from 'styled-components';

import COLORS from 'constants/colors';

const YandexMapWrapper = styled.div`
  width: 100%;
`;
/* eslint-disable */
const SearchFieldWrapper = styled.div`
  .ant-select {
    margin-bottom: 18px;
    width: 100%;
    height: 56px !important;

    .ant-select-selector {
      height: 56px !important;
      display: flex;
      align-items: center;
      border: ${props => {
        if (props.haserror) return '2px solid #db471e !important';

        return '2px solid #dfdee2 !important';
      }};
      border-radius: 4px !important;
      &:hover {
        border: 2px solid ${COLORS.mainGetirColor} !important;
      }
      &:focus {
        border: 2px solid ${COLORS.mainGetirColor} !important;
      }
    }
  }

  .ant-select-selection-placeholder {
    color: #828287;
  }

  .ant-select-selection-search {
    input {
      height: 100% !important;
    }
  }

  .ant-select-arrow {
    color: ${COLORS.mainGetirColor} !important;
  }
`;
/* eslint-enable */

const ErrorWrapper = styled.div`
  margin-top: -18px;
  margin-bottom: 10px;
`;

export { YandexMapWrapper, SearchFieldWrapper, ErrorWrapper };
