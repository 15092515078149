import { getRequest, postRequest, putRequest, deleteRequest } from 'lib/axios';

import api from './apiRoutes';

export const listUsersApi = args => getRequest(api.listUsers, args);

export const addUserApi = args => postRequest(api.addUser, args);

export const updateUserApi = args => {
  const { id, ...rest } = args;

  return putRequest(api.updateUser(id), rest);
};

export const deleteUserApi = args => deleteRequest(api.deleteUser(args));

export const getUsersByFirmApi = args => getRequest(api.getUsersByFirm(args));

export const getUsersByVendorApi = args =>
  postRequest(api.getUsersByVendor, args);

export const getRolesByRoleGroupApi = args =>
  getRequest(api.getRolesByRoleGroup(args));
