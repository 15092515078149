import { put, takeLatest, call } from 'redux-saga/effects';

import { open } from 'utils/notification';
import * as LoaderActions from 'components/Loader/actions';
import { updatePaymentMethodsApi } from 'services/PaymentMethods/api';
import translate from 'utils/translate';

import * as Types from './types';
import * as PaymentMethodsActions from './actions';

function* updatePaymentMethods({ payload }) {
  yield put(LoaderActions.showLoader());
  try {
    const reqBody = { ...payload };
    const result = yield call(updatePaymentMethodsApi, reqBody);
    if (result.data) {
      yield put(
        PaymentMethodsActions.updatePaymentMethodsSuccess(result.data.payload),
      );
    } else {
      yield put(open('warning', translate('notif:warnTitle'), result.message));
    }
  } catch (error) {
    yield put(PaymentMethodsActions.updatePaymentMethodsError(error.message));
  }
  yield put(LoaderActions.hideLoader());
}

export function* rootSaga() {
  yield takeLatest(Types.UPDATE_PAYMENTMETHODS_REQUEST, updatePaymentMethods);
}

export default rootSaga;
