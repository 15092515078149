const MIN_PASSWORD_LENGTH = 8;
const MIN_NEW_PASSWORD_LENGTH = 6;
const MAX_PASSWORD_LENGTH = 16;
const MOBILE_MAX_WIDTH = 768;
const PASSWORD_PATTERN =
  "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@!'#$%^&-+=()])(?=\\S+$).{8,20}$";

const LANGUAGE_NAMESPACES = [
  'common',
  'catalogs',
  'users',
  'workinghours',
  'paymentmethods',
  'auth',
  'header',
  'firm',
  'previousorders',
  'vendor',
  'generalInformation',
  'financialInformation',
  'activeOrders',
  'financialTransactions',
  'requests',
  'bulkChange',
  'geomap',
  'help',
  'addFinancialTransaction',
  'authUsersPage',
  'authPermsPage',
  'authRolesPage',
  'billingInformationModal',
];

const BUSINESS_TYPES = {
  FIRM: 1,
  VENDOR: 2,
};

const PAYMENT_METHODS = {
  ALL: 0,
  ONLINE_CREDIT_CARD: 1,
  ONLINE_BKM: 2,
  CREDIT_OR_DEBIT_CARD_AT_DELIVERY: 3,
  CASH_AT_DELIVERY: 4,
};

const { REACT_APP_GOOGLE_SSO_CLIENT_ID } = process.env;

const OLD_GETIRWATER_PANEL_URLS = ['getirsu.com', 'www.getirsu.com'];

const CURRENT_GETIRWATER_PANEL_URL = 'panel.getirsu.com';

const OrderCancelSource = {
  VENDOR_PANEL: 4,
};

const GOOGLE_AUTH_REQUEST_TYPES = {
  SIGN_IN: 1,
  SIGN_OUT: 2,
};

export {
  MIN_PASSWORD_LENGTH,
  MIN_NEW_PASSWORD_LENGTH,
  MAX_PASSWORD_LENGTH,
  LANGUAGE_NAMESPACES,
  BUSINESS_TYPES,
  PAYMENT_METHODS,
  MOBILE_MAX_WIDTH,
  PASSWORD_PATTERN,
  REACT_APP_GOOGLE_SSO_CLIENT_ID,
  OLD_GETIRWATER_PANEL_URLS,
  CURRENT_GETIRWATER_PANEL_URL,
  OrderCancelSource,
  GOOGLE_AUTH_REQUEST_TYPES,
};
