import React from 'react';

export default function Italic() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="inherit"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.7601 9.61774H13.7601L11.5601 19.6177H9.56006L11.7601 9.61774ZM13.4401 5.61774C13.2423 5.61774 13.0489 5.67639 12.8845 5.78627C12.72 5.89615 12.5919 6.05233 12.5162 6.23505C12.4405 6.41778 12.4207 6.61885 12.4593 6.81283C12.4979 7.00681 12.5931 7.18499 12.733 7.32484C12.8728 7.4647 13.051 7.55994 13.245 7.59852C13.4389 7.63711 13.64 7.6173 13.8227 7.54162C14.0055 7.46593 14.1616 7.33776 14.2715 7.17331C14.3814 7.00886 14.4401 6.81552 14.4401 6.61774C14.4401 6.35252 14.3347 6.09817 14.1472 5.91063C13.9596 5.72309 13.7053 5.61774 13.4401 5.61774Z"
        fill="inherit"
      />
    </svg>
  );
}
