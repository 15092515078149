import React, { useCallback, useEffect, useMemo, useRef, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Modal from 'components/v1/Modal';

import * as S from './styles';
import * as BillingDetailModalActions from './actions';
import {
  MODAL_MODES,
  authorizedComponentIds,
  invoiceStatus,
} from './constants';
import Footer from './Footer';
import ModalDetail from './Detail';
import AdminRefuse from './AdminRefuse';
import Upload from './Upload';

const BillingDetailModal = () => {
  const {
    orderId,
    isSuccessful,
    modalDetail: { mode: modalMode, isVisible },
    billingDetail,
  } = useSelector(state => state.billingDetailModal);
  const { data: allPerms } = useSelector(state => state.login.allPerms);

  const { t } = useTranslation('billingUploadModal');
  const dispatch = useDispatch();

  const adminRefuseRef = useRef(null);
  const uploadRef = useRef(null);

  const isAdmin = useMemo(
    () =>
      allPerms.some(
        perm => perm.componentId === authorizedComponentIds.invoice_update,
      ),
    [allPerms],
  );

  useEffect(() => {
    if (modalMode === MODAL_MODES.DELETE) {
      dispatch(BillingDetailModalActions.saveButtonDisabled(false));
    }
  }, [dispatch, modalMode]);

  useEffect(() => {
    if (
      modalMode === MODAL_MODES.DETAIL &&
      billingDetail &&
      billingDetail.status === invoiceStatus.DELETED
    ) {
      dispatch(BillingDetailModalActions.setModalMode(MODAL_MODES.UPLOAD));
    }
  }, [billingDetail, dispatch, modalMode]);

  useEffect(() => {
    if (!isVisible) {
      dispatch(BillingDetailModalActions.resetStates());
    }
  }, [dispatch, isVisible]);

  const onOkButtonClickHandler = useCallback(() => {
    if (modalMode === MODAL_MODES.UPLOAD) {
      if (isSuccessful) {
        dispatch(BillingDetailModalActions.closeBillingModal());

        return;
      }

      uploadRef.current.uploadFile();

      return;
    }

    if (modalMode === MODAL_MODES.DELETE) {
      dispatch(BillingDetailModalActions.deleteImageRequest(orderId));
    }

    if (modalMode === MODAL_MODES.ADMIN_REFUSE) {
      const cancelReason = adminRefuseRef.current.value;
      const cancelReasonError = adminRefuseRef.current.error;
      const setCancelReasonError = adminRefuseRef.current.setError;
      if (!cancelReason || cancelReasonError) {
        setCancelReasonError(t('billingInputError.minLengtThree'));

        return;
      }

      dispatch(
        BillingDetailModalActions.adminRefuseRequest({
          orderId,
          status: invoiceStatus.CANCELED,
          cancelReason,
        }),
      );
    }

    if (modalMode === MODAL_MODES.DETAIL) {
      dispatch(BillingDetailModalActions.closeBillingModal());
    }
  }, [dispatch, isSuccessful, modalMode, orderId, t]);

  const approveBillingHandler = useCallback(() => {
    if (!isAdmin) return;
    if (!orderId) return;

    dispatch(
      BillingDetailModalActions.adminApproveRequest({
        orderId,
        status: invoiceStatus.APPROVED,
      }),
    );
  }, [dispatch, isAdmin, orderId]);

  const denideBillingHandler = useCallback(() => {
    if (!isAdmin) return;
    if (!orderId) return;

    dispatch(BillingDetailModalActions.setModalMode(MODAL_MODES.ADMIN_REFUSE));
  }, [dispatch, isAdmin, orderId]);

  const onCancelButtonClickHandler = useCallback(() => {
    if (
      modalMode === MODAL_MODES.DETAIL ||
      (modalMode === MODAL_MODES.UPLOAD && isSuccessful)
    ) {
      dispatch(BillingDetailModalActions.setModalMode(MODAL_MODES.DELETE));

      return;
    }
    if (modalMode === MODAL_MODES.UPLOAD && !isSuccessful) {
      dispatch(BillingDetailModalActions.closeBillingModal());
    }
    dispatch(BillingDetailModalActions.setModalMode(MODAL_MODES.DETAIL));
  }, [dispatch, isSuccessful, modalMode]);

  const customFooter = useMemo(() => {
    if (
      modalMode === MODAL_MODES.DETAIL &&
      billingDetail.status === undefined
    ) {
      return <div></div>;
    }

    return (
      <Footer
        isAdmin={isAdmin && modalMode === MODAL_MODES.DETAIL}
        approveBillingHandler={approveBillingHandler}
        denideBillingHandler={denideBillingHandler}
        onCancelButtonClickHandler={onCancelButtonClickHandler}
        onOkButtonClickHandler={onOkButtonClickHandler}
      />
    );
  }, [
    approveBillingHandler,
    billingDetail,
    denideBillingHandler,
    isAdmin,
    modalMode,
    onCancelButtonClickHandler,
    onOkButtonClickHandler,
  ]);

  const modalContent = {
    [MODAL_MODES.UPLOAD]: <Upload ref={uploadRef} />,
    [MODAL_MODES.ADMIN_REFUSE]: <AdminRefuse ref={adminRefuseRef} />,
    [MODAL_MODES.DELETE]: <div>{t('deleteBillingInfo')}</div>,
    [MODAL_MODES.DETAIL]: <ModalDetail />,
  };

  return (
    <Modal
      headerOptions={{
        title: 'Fatura Yükle',
        closeButton: false,
      }}
      footerOptions={{
        customFooter,
      }}
      closable={
        modalMode !== MODAL_MODES.DELETE &&
        modalMode !== MODAL_MODES.ADMIN_REFUSE
      }
      width="600px"
      isOpen={isVisible}
      onClose={() => dispatch(BillingDetailModalActions.closeBillingModal())}
    >
      <S.ContentContainer>{modalContent[modalMode]}</S.ContentContainer>
    </Modal>
  );
};

export default memo(BillingDetailModal);
