import history from 'utils/history';
import * as ROUTES from 'constants/routes';
import cookies from 'utils/cookie';
import { removeItem } from 'utils/localStorage';

const logout = () => {
  history.push(ROUTES.LOGIN);
  cookies.remove('accessToken');
  removeItem('user');
  removeItem('currentRole');
};

export { logout };
