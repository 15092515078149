import axios from 'axios';
import * as Sentry from '@sentry/react';

import cookies from 'utils/cookie';
import { logout } from 'utils/auth';
import history from 'utils/history';
import translate from 'utils/translate';
import i18n from 'utils/i18n';
import { open } from 'utils/notification';
import * as ROUTES from 'constants/routes';

const headers = {};

headers['Content-Type'] = 'application/json';
axios.defaults.withCredentials = true;

const axiosInstance = axios.create({
  headers,
  withCredentials: true,
});

axiosInstance.interceptors.response.use(
  res => res,
  err => {
    if (err.response.status === 401) {
      logout();
    }
    if (err.response.status === 403) {
      open('warning', '', translate('unauthorizedMessage'));
      history.push(ROUTES.ACTIVE_ORDERS);
    }
    Sentry.captureException(err);
    throw new Error(err.response.data.message);
  },
);

axiosInstance.interceptors.request.use(
  config => {
    const accessToken = cookies.get('accessToken');
    const { language } = i18n;
    if (accessToken) {
      return {
        ...config,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Accept-Language': language,
        },
      };
    }

    return config;
  },
  err => Promise.reject(err),
);

export const postRequest = async (url, data, args) => {
  const result = await axiosInstance
    .post(url, data, args)
    .then(response => response)
    .catch(error => error);

  return result;
};

export const patchRequest = async (url, data, args) => {
  const result = await axiosInstance
    .patch(url, data, args)
    .then(response => response)
    .catch(error => error);

  return result;
};

export const getRequest = async (url, args) => {
  const result = await axiosInstance
    .get(url, {
      params: args,
    })
    .then(response => response)
    .catch(error => error);

  return result;
};

export const getImageRequest = async (url, args) => {
  const result = await axiosInstance
    .get(url, {
      params: args,
      responseType: 'arraybuffer',
    })
    .then(response => response)
    .catch(error => error);

  return result;
};

export const putRequest = async (url, data, args) => {
  const result = await axiosInstance
    .put(url, data, args)
    .then(response => response)
    .catch(error => error);

  return result;
};

export const deleteRequest = async (url, data, args) => {
  const result = await axiosInstance
    .delete(url, data, args)
    .then(response => response)
    .catch(error => error);

  return result;
};

export default axiosInstance;
