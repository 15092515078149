import React, { useEffect, useState } from 'react';
import { Space, Row, Col, Divider } from 'antd';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Icon, Button } from '@getir/web-components';
import { useDispatch } from 'react-redux';

import Layout from 'components/Layout';
import InputWrapper from 'components/InputWrapper';
import SelectBoxWrapper from 'components/SelectBoxWrapper';
import * as ROUTES from 'constants/routes';
import apiRoutes from 'services/CatalogProduct/apiRoutes';
import Uploader from 'components/Uploader';
import * as CS from 'common/style';
import SelectBoxAntd from 'components/Antd/Elements/SelectBox';
import TextareaWrapper from 'components/TextareaWrapper';

import { extractBrandOptionsFrom } from '../helpers/extractBrandOptionsFrom';
import { extractFirmOptionsFrom } from '../helpers/extractFirmOptionsFrom';
import { useBrandsFirmsCategories } from '../helpers/useBrandsFirmsCategories';
import * as CatalogActions from '../actions';
import { extractProductItemFrom } from '../helpers/extractProductItemFrom';
import useValidations from '../helpers/useValidations';
import { useSetRemoveImage } from '../helpers/useSetRemoveImage';
import { extractCategoryOptionsFrom } from '../helpers/extractCategoryOptionsFrom';
import { useDepositStatus } from '../helpers/useDepositStatus';
import { extractDepositOptionsFrom } from '../helpers/extractDepositOptions';
import { extractRecommendedOptionsFrom } from '../helpers/extractRecommended';
import { showAsRecomendedProduct } from '../constants';

import * as S from './style';

const Add = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { validationRules } = useValidations();
  const { brands, firms, categories } = useBrandsFirmsCategories();
  const { depositStatus } = useDepositStatus();
  const dispatch = useDispatch();
  const [firmId, setFirmId] = useState();

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    mode: 'onBlur',
  });

  useEffect(() => {
    setValue('brandId', undefined);
    if (firmId) dispatch(CatalogActions.catalogBrandRequest({ firmId }));
  }, [firmId, setValue, dispatch]);

  const { setImage, removeImage } = useSetRemoveImage(
    setValue,
    'productImageUrl',
  );
  const {
    setImage: setWideImage,
    removeImage: removeWideImage,
  } = useSetRemoveImage(setValue, 'productWideImageUrl');

  const onSubmit = formdata => {
    const extractedData = extractProductItemFrom(formdata);
    dispatch(CatalogActions.addCatalogProductRequest(extractedData));
  };

  return (
    <Layout>
      <S.PageHeader>
        <Space>
          <Icon
            name="arrow-left"
            onClick={() => history.push(ROUTES.LIST_CATALOG)}
            title={t('backToList')}
          />
          <S.PageTitle>{t('catalogs:addCatalogProduct')}</S.PageTitle>
        </Space>
      </S.PageHeader>
      <S.PageContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row gutter={16} align="bottom">
            <Col span={12}>
              <SelectBoxWrapper
                fieldName="isRecommended"
                control={control}
                rules={validationRules.isRecommended}
                placeholder={t('catalogs:showAsRecomendedProduct')}
                errors={errors}
                options={extractRecommendedOptionsFrom(showAsRecomendedProduct)}
              />
            </Col>
          </Row>
          <Row gutter={16} align="bottom">
            <Col span={12}>
              <SelectBoxWrapper
                fieldName="categoryId"
                control={control}
                rules={validationRules.categoryName}
                placeholder={t('catalogs:categoryName')}
                errors={errors}
                options={extractCategoryOptionsFrom(categories)}
              />
            </Col>
            <Col span={12}>
              <InputWrapper
                fieldName="productName"
                control={control}
                rules={validationRules.productName}
                placeholder={t('catalogs:productName')}
                header={t('catalogs:productName')}
                errors={errors}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <InputWrapper
                fieldName="shortProductName_TR"
                control={control}
                rules={validationRules.shortProductName_TR}
                placeholder={t('catalogs:shortProductName_TR')}
                header={t('catalogs:shortProductName_TR')}
                errors={errors}
              />
            </Col>
            <Col span={12}>
              <InputWrapper
                fieldName="shortProductName_EN"
                control={control}
                rules={validationRules.shortProductName_EN}
                placeholder={t('catalogs:shortProductName_EN')}
                header={t('catalogs:shortProductName_EN')}
                errors={errors}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <InputWrapper
                fieldName="longProductName_TR"
                control={control}
                rules={validationRules.longProductName_TR}
                placeholder={t('catalogs:longProductName_TR')}
                header={t('catalogs:longProductName_TR')}
                errors={errors}
              />
            </Col>
            <Col span={12}>
              <InputWrapper
                fieldName="longProductName_EN"
                control={control}
                rules={validationRules.longProductName_EN}
                placeholder={t('catalogs:longProductName_EN')}
                header={t('catalogs:longProductName_EN')}
                errors={errors}
              />
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <InputWrapper
                fieldName="shortDescription_TR"
                control={control}
                rules={validationRules.shortDescription_TR}
                placeholder={t('catalogs:shortDescription_TR')}
                header={t('catalogs:shortDescription_TR')}
                errors={errors}
              />
            </Col>
            <Col span={12}>
              <InputWrapper
                fieldName="shortDescription_EN"
                control={control}
                rules={validationRules.shortDescription_EN}
                placeholder={t('catalogs:shortDescription_EN')}
                header={t('catalogs:shortDescription_EN')}
                errors={errors}
              />
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <TextareaWrapper
                fieldName="longDescription_TR"
                control={control}
                rules={validationRules.longDescription_TR}
                placeholder={t('catalogs:longDescription_TR')}
                header={t('catalogs:longDescription_TR')}
                errors={errors}
              />
            </Col>
            <Col span={12}>
              <TextareaWrapper
                fieldName="longDescription_EN"
                control={control}
                rules={validationRules.longDescription_EN}
                placeholder={t('catalogs:longDescription_EN')}
                header={t('catalogs:longDescription_EN')}
                errors={errors}
              />
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Controller
                name="firmId"
                control={control}
                rules={validationRules.firmId}
                render={({ field }) => (
                  <SelectBoxAntd
                    placeholder={t('catalogs:firm')}
                    onChange={value => {
                      setFirmId(value);
                      field.onChange(value);
                    }}
                    showSearch
                    hasError={errors.firmId}
                    value={field.value}
                    options={extractFirmOptionsFrom(firms)}
                    allowClear
                  />
                )}
              />
              {errors.firmId && (
                <CS.ErrorMessage>{errors.firmId.message}</CS.ErrorMessage>
              )}
            </Col>
            <Col span={12}>
              <Controller
                name="brandId"
                control={control}
                rules={validationRules.firmId}
                render={({ field }) => (
                  <SelectBoxAntd
                    placeholder={t('catalogs:brand')}
                    onChange={value => {
                      field.onChange(value);
                    }}
                    showSearch
                    hasError={errors.brandId}
                    value={field.value}
                    options={extractBrandOptionsFrom(brands)}
                    allowClear
                  />
                )}
              />
              {errors.brandId && (
                <CS.ErrorMessage>{errors.brandId.message}</CS.ErrorMessage>
              )}
            </Col>
          </Row>

          <Row gutter={16} align="bottom">
            <Col span={12}>
              <InputWrapper
                fieldName="maxOrderCount"
                control={control}
                rules={validationRules.maxOrderCount}
                placeholder={t('catalogs:maxOrderCount')}
                header={t('catalogs:maxOrderCount')}
                errors={errors}
                isOnlyNumber
              />
            </Col>
            <Col span={12}>
              <Controller
                name="depositCarboy"
                control={control}
                render={({ field }) => (
                  <SelectBoxAntd
                    placeholder={t('catalogs:depositStatus')}
                    onChange={value => {
                      field.onChange(value);
                    }}
                    showSearch
                    value={field.value}
                    options={extractDepositOptionsFrom(depositStatus)}
                    allowClear
                  />
                )}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Controller
                name="productWideImageUrl"
                control={control}
                rules={validationRules.productWideImageUrl}
                render={() => (
                  <Uploader
                    name="file"
                    uploadUrl={apiRoutes.uploadImage}
                    multiple={false}
                    onSuccess={setWideImage}
                    onRemove={removeWideImage}
                    title={t('catalogs:productWideImageUrl')}
                    imageWidth={1400}
                    imageHeight={700}
                  />
                )}
              />
              {errors.productWideImageUrl && (
                <CS.ErrorMessage>
                  {errors.productWideImageUrl.message}
                </CS.ErrorMessage>
              )}
            </Col>
            <Col span={12}>
              <Controller
                name="productImageUrl"
                control={control}
                rules={validationRules.productImageUrl}
                render={({ field }) => (
                  <Uploader
                    name="file"
                    uploadUrl={apiRoutes.uploadImage}
                    multiple={false}
                    onSuccess={setImage}
                    onRemove={removeImage}
                    title={t('catalogs:productImageUrl')}
                    imageWidth={700}
                    imageHeight={700}
                    inputRef={field.ref}
                    errors={errors}
                    fieldName="productImageUrl"
                    style={{ marginBottom: 0, padding: '2px' }}
                  />
                )}
              />
              {errors.productImageUrl && (
                <CS.ErrorMessage>
                  {errors.productImageUrl.message}
                </CS.ErrorMessage>
              )}
            </Col>
          </Row>
          <Divider />
          <Row gutter={16}>
            <Col span={24}>
              <Space size="middle">
                <Button
                  kind="secondary"
                  onClick={() => history.push(ROUTES.LIST_CATALOG)}
                >
                  {t('giveUp')}
                </Button>
                <Button type="submit">{t('save')}</Button>
              </Space>
            </Col>
          </Row>
        </form>
      </S.PageContent>
    </Layout>
  );
};

export default Add;
