import React from 'react';
import { Icon } from '@getir/web-components';

import iconWithImage from 'utils/iconWithImage';
import * as ROUTES from 'constants/routes';
import * as SvgList from 'common/svg/menuicons';

const menuItems = [
  {
    id: 1,
    order: 1,
    name: 'menu:businesses',
    route: '',
    icon: iconWithImage(SvgList.businessSvg, 'isletmeler', 'isletmeler'),
    componentId: 'business',
    childs: [
      {
        id: 11,
        name: 'menu:firms',
        route: '/isletmeler/firmalar',
        componentId: 'firm_page',
      },
      {
        id: 12,
        name: 'menu:brands',
        route: '/isletmeler/markalar',
        componentId: 'brand_list',
      },
      {
        id: 13,
        name: 'menu:vendors',
        route: '/isletmeler/bayiler',
        componentId: 'vendor_page',
      },
    ],
  },
  {
    id: 22,
    order: 2,
    name: 'menu:catalog',
    route: '',
    componentId: 'catalog_management',
    icon: iconWithImage(
      SvgList.catalogSvg,
      'katalog-yonetimi',
      'katalog-yonetimi',
    ),
    childs: [
      {
        id: 211,
        name: 'menu:categoryList',
        route: ROUTES.LIST_CATEGORY,
        componentId: 'category_list',
      },
      {
        id: 212,
        name: 'catalogs:title',
        route: ROUTES.LIST_CATALOG,
        componentId: 'catalog_list',
      },
    ],
  },
  {
    id: 4,
    order: 3,
    name: 'menu:businessInformation',
    icon: iconWithImage(SvgList.businessinformationSvg, 'destek', 'destek'),
    route: '',
    componentId: 'business_info',
    childs: [
      {
        id: 401,
        name: 'menu:generalInformation',
        route: '/isletme-bilgileri/genel-bilgiler',
        componentId: 'general_info',
      },
      {
        id: 402,
        name: 'menu:financialInformation',
        route: ROUTES.FINANCIAL_INFORMATION,
        componentId: 'financial_info',
      },
      {
        id: 403,
        name: 'menu:users',
        route: '/isletme-bilgileri/kullanicilar',
        componentId: 'user_list',
      },
    ],
  },
  {
    id: 16,
    order: 4,
    name: 'menu:geomap',
    icon: iconWithImage(SvgList.geomapSvg, 'getirsu', 'getirsu'),
    componentId: 'map',
    childs: [
      {
        id: 161,
        name: 'menu:uploadGeomap',
        route: ROUTES.UPLOAD_GEOMAP,
        componentId: 'map_upload',
      },
      {
        id: 163,
        name: 'menu:banGeomap',
        route: ROUTES.BAN_GEOMAP,
        componentId: 'map_ban',
      },
      {
        id: 162,
        name: 'menu:alternativeMapHours',
        route: ROUTES.ALTERNATIVE_MAP_HOURS,
        componentId: 'alternative_map',
      },
    ],
  },
  {
    id: 5,
    order: 5,
    name: 'menu:activeOrders',
    icon: iconWithImage(SvgList.activeordersSvg, 'getirsu', 'getirsu'),
    route: ROUTES.ACTIVE_ORDERS,
    childs: [],
    componentId: 'active_orders',
  },
  {
    id: 6,
    order: 6,
    name: 'menu:products',
    icon: iconWithImage(SvgList.productsSvg, 'getirsu', 'getirsu'),
    route: ROUTES.VENDORPRODUCT,
    childs: [],
    componentId: 'product_list',
  },
  {
    id: 7,
    order: 7,
    name: 'menu:workingHours',
    icon: iconWithImage(SvgList.workinghoursSvg, 'getirsu', 'getirsu'),
    route: ROUTES.WORKING_HOURS,
    childs: [],
    componentId: 'working_hours',
  },
  {
    id: 8,
    order: 8,
    name: 'menu:paymentMethods',
    icon: iconWithImage(SvgList.paymentmethodsSvg, 'getirsu', 'getirsu'),
    route: ROUTES.PAYMENT_METHODS,
    childs: [],
    componentId: 'payment_methods',
  },
  {
    id: 18,
    order: 9,
    name: 'menu:bulkChange',
    icon: <Icon name="edit" size={16} />,
    route: ROUTES.BULK_CHANGE,
    childs: [],
    componentId: 'bulk_change',
  },
  {
    id: 10,
    order: 10,
    name: 'menu:payments',
    icon: iconWithImage(SvgList.paymentsSvg, 'getirsu', 'getirsu'),
    route: '',
    componentId: 'payments',
    childs: [
      {
        id: 101,
        name: 'menu:financialTransactions',
        route: ROUTES.FINANCIAL_TRANSACTIONS,
        componentId: 'financial_transactions',
      },
      {
        id: 102,
        name: 'menu:previousOrders',
        route: ROUTES.PREVIOUS_ORDERS,
        componentId: 'previous_orders',
      },
      {
        id: 103,
        name: 'menu:addFinancialTransaction',
        route: ROUTES.ADD_FINANCIAL_TRANSACTION,
        componentId: 'financial_transactions_add',
      },
    ],
  },
  {
    id: 15,
    order: 11,
    name: 'menu:requests',
    icon: iconWithImage(SvgList.requestsSvg, 'getirsu', 'getirsu'),
    route: ROUTES.REQUESTS,
    childs: [],
    componentId: 'requests',
  },
  {
    id: 9,
    name: 'menu:payments',
    icon: iconWithImage(SvgList.paymentsyellowSvg, 'getirsu', 'getirsu'),
    route: '',
    childs: [
      {
        id: 91,
        name: 'menu:previousOrders',
        route: '/orders/past',
      },
    ],
  },
  {
    id: 19,
    name: 'menu:vendorCommunication',
    icon: iconWithImage(SvgList.productsSvg, 'getirsu', 'getirsu'),
    route: ROUTES.VENDOR_COMMUNICATION,
    childs: [],
    componentId: 'vendor_communication',
  },
  {
    id: 20,
    name: 'menu:kuzeydenReport',
    icon: iconWithImage(
      SvgList.productsSvg,
      'Getir Su Raporlar',
      'Getir Su Raporlar',
    ),
    route: ROUTES.KUZEYDEN_REPORT,
    childs: [],
    componentId: 'kuzeyden_reports',
  },
  {
    id: 14,
    order: 12,
    name: 'menu:authorizationPage',
    icon: iconWithImage(SvgList.productsSvg, 'getirsu', 'getirsu'),
    componentId: 'auth_page',
    route: '',
    childs: [
      {
        id: 141,
        name: 'menu:authUserPage',
        route: ROUTES.AUTH_USERS,
        componentId: 'auth_users',
      },
      {
        id: 142,
        name: 'menu:authPermPage',
        route: ROUTES.AUTH_PERMS,
        componentId: 'auth_perms',
      },
      {
        id: 143,
        name: 'menu:authRolePage',
        route: ROUTES.AUTH_ROLES,
        componentId: 'auth_roles',
      },
    ],
  },
  {
    id: 26,
    name: 'menu:help',
    icon: iconWithImage(SvgList.supportSvg, 'getirsu', 'getirsu'),
    route: ROUTES.HELP,
    childs: [],
  },
  {
    id: 100,
    name: '0850 214 04 54',
    icon: iconWithImage(SvgList.phoneSvg, 'getirsu', 'getirsu'),
    route: '',
    childs: [],
    showAlways: true,
  },
];

export default menuItems;
