import { createAction } from 'utils/createAction';

import * as Types from './types';

// add transaction
export const addTransactionsRequest = createAction(
  Types.ADD_TRANSACTION_REQUEST,
);
export const addTransactionsSuccess = createAction(
  Types.ADD_TRANSACTION_SUCCESS,
);
export const addTransactionsError = createAction(Types.ADD_TRANSACTION_ERROR);

// save premium transaction
export const savePremiumTransactionsRequest = createAction(
  Types.SAVE_PREMIUM_TRANSACTION_REQUEST,
);
export const savePremiumTransactionsSuccess = createAction(
  Types.SAVE_PREMIUM_TRANSACTION_SUCCESS,
);
export const savePremiumTransactionsError = createAction(
  Types.SAVE_PREMIUM_TRANSACTION_ERROR,
);
