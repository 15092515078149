import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@getir/web-components';
import { useDispatch, useSelector } from 'react-redux';
import { Document, pdfjs, Page } from 'react-pdf';
import { Pagination, Typography } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import * as CS from 'common/style';
import { logout } from 'utils/auth';
import cookies from 'utils/cookie';
import * as MainHeaderActions from 'components/MainHeader/actions';
import * as HeaderActions from 'components/Header/actions';

import * as UndertakingActions from './actions';
import * as S from './style';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const { Text } = Typography;

const UndertakingModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const vendorInfo = useSelector(state => state.mainHeader.vendorInfo);
  const approveUndertakingData = useSelector(
    state => state.undertakingModal.approveUndertaking.data,
  );
  const approveUndertakingError = useSelector(
    state => state.undertakingModal.approveUndertaking.error,
  );

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [undertakingInfo, setUndertakingInfo] = useState(undefined);
  const [numPageCount, setNumPageCount] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    if (vendorInfo && vendorInfo.letterOfUndertaking) {
      setUndertakingInfo(vendorInfo.letterOfUndertaking);
      setIsModalVisible(!vendorInfo.letterOfUndertaking.isApprovedLU);
    }
  }, [vendorInfo]);

  useEffect(() => {
    if (approveUndertakingData) {
      setIsModalVisible(false);
    }
    if (approveUndertakingError) {
      logoutUser();
    }
    // eslint-disable-next-line
  }, [approveUndertakingData, approveUndertakingError]);

  const logoutUser = () => {
    const accessToken = cookies.get('accessToken');
    dispatch(MainHeaderActions.getCitiesSuccess([]));
    dispatch(MainHeaderActions.getVendorInfoSuccess([]));
    dispatch(MainHeaderActions.setCityId(undefined));
    dispatch(MainHeaderActions.setVendor(undefined));
    dispatch(HeaderActions.logoutRequest(accessToken));
    logout();
  };

  const closeModal = () => {
    logoutUser();
  };

  const acceptUndertaking = () => {
    dispatch(
      UndertakingActions.approveUndertakingDocRequest({
        letterUndertakingDocURL: undertakingInfo.letterUndertakingDocURL,
        userId: undertakingInfo.userId,
        vendorId: undertakingInfo.vendorId,
      }),
    );
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPageCount(numPages);
  };

  const changePage = toPage => {
    setPageNumber(toPage);
  };

  const footerActions = [
    <>
      <Button kind="secondary" onClick={closeModal}>
        {t('common:prev')}
      </Button>
      <Button kind="primary" onClick={acceptUndertaking}>
        {t('common:approveUndertakingText')}
      </Button>
    </>,
  ];

  const itemRender = (_, type, originalElement) => {
    if (type === 'prev') {
      return (
        <S.PaginationLeftArrow>
          <LeftOutlined />
          <Text>{t('prev')}</Text>
        </S.PaginationLeftArrow>
      );
    }

    if (type === 'next') {
      return (
        <S.PaginationRightArrow>
          <Text>{t('next')}</Text>
          <RightOutlined />
        </S.PaginationRightArrow>
      );
    }

    return originalElement;
  };

  return (
    <CS.StyledModal
      isOpen={isModalVisible}
      width={800}
      onClose={closeModal}
      closable={false}
      maskClosable={false}
      footerOptions={{
        customFooter: footerActions,
      }}
      maskStyle={{ backdropFilter: 'blur(8px)' }}
    >
      <S.ModalContentWrapper>
        <S.PdfContent>
          <Document
            file={vendorInfo?.letterOfUndertaking?.letterUndertakingDocURL}
            options={{
              cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
              cMapPacked: true,
            }}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page pageNumber={pageNumber} scale={1.2} />
          </Document>
        </S.PdfContent>
        <S.PaginationArea>
          {numPageCount && numPageCount > 1 && (
            <Pagination
              defaultCurrent={pageNumber}
              defaultPageSize={1}
              onChange={changePage}
              showSizeChanger={false}
              total={numPageCount}
              itemRender={itemRender}
            />
          )}
        </S.PaginationArea>
      </S.ModalContentWrapper>
    </CS.StyledModal>
  );
};

export default UndertakingModal;
