import React from 'react';
import { useSelector } from 'react-redux';

import MessageDetail from '../MessageDetail';
import NewMessage from '../NewMessage';
import { MODAL_TYPES } from '../constants';

const MessageModal = () => {
  const modalType = useSelector(
    state => state.vendorCommunication.modalOptions.modalType,
  );

  if (!modalType) return null;

  return modalType === MODAL_TYPES.NEW_MESSAGE ? (
    <NewMessage />
  ) : (
    <MessageDetail />
  );
};

export default MessageModal;
