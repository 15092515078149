import { createAction } from 'utils/createAction';

import * as Types from './types';

// list requests
export const requestsListRequest = createAction(Types.REQUESTS_LIST_REQUEST);
export const requestsListSuccess = createAction(Types.REQUESTS_LIST_SUCCESS);
export const requestsListError = createAction(Types.REQUESTS_LIST_ERROR);

// approve requests
export const approveRequestRequest = createAction(
  Types.APPROVE_REQUEST_REQUEST,
);
export const approveRequestSuccess = createAction(
  Types.APPROVE_REQUEST_SUCCESS,
);
export const approveRequestError = createAction(Types.APPROVE_REQUEST_ERROR);

// set request type

export const setRequestType = createAction(Types.SET_REQUEST_TYPE);
