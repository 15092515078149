import { createAction } from 'utils/createAction';

import * as Types from './types';

// set city id

export function setCityId(data) {
  return {
    type: Types.SET_CITY_ID,
    payload: data,
  };
}

// set vendor id

export function setVendor(data) {
  return {
    type: Types.SET_VENDOR,
    payload: data,
  };
}

// get cities

export function getCitiesRequest() {
  return {
    type: Types.GET_CITIES_REQUEST,
  };
}

export function getCitiesSuccess(data) {
  return {
    type: Types.GET_CITIES_SUCCESS,
    payload: data.map(item => ({
      value: item.id,
      label: item.name,
      country: item.country,
      center: item.center,
    })),
  };
}

export function getCitiesError(data) {
  return {
    type: Types.GET_CITIES_ERROR,
    payload: data,
  };
}

// get vendors by city

export function getVendorsRequest(data) {
  return {
    type: Types.GET_VENDORS_REQUEST,
    payload: data,
  };
}

export function getVendorsSuccess(data) {
  return {
    type: Types.GET_VENDORS_SUCCESS,
    payload: data.map(item => ({
      value: item.id,
      label: item.name,
      isFirm: item.firm,
    })),
  };
}

export function getVendorsError(data) {
  return {
    type: Types.GET_VENDORS_ERROR,
    payload: data,
  };
}

// update vendor status

export function updateVendorStatusRequest(vendorId, isOpen) {
  return {
    type: Types.UPDATE_VENDOR_STATUS_REQUEST,
    payload: {
      vendorId,
      isOpen,
    },
  };
}

export function updateVendorStatusSuccess(data) {
  return {
    type: Types.UPDATE_VENDOR_STATUS_SUCCESS,
    payload: data,
  };
}

export function updateVendorStatusError(data) {
  return {
    type: Types.UPDATE_VENDOR_STATUS_ERROR,
    payload: data,
  };
}

// get working hours info

export function getWorkingHoursInfoRequest(data) {
  return {
    type: Types.GET_WORKING_HOURS_INFO_REQUEST,
    payload: data,
  };
}

export function getWorkingHoursInfoSuccess(data) {
  return {
    type: Types.GET_WORKING_HOURS_INFO_SUCCESS,
    payload: data,
  };
}

export function getWorkingHoursInfoError(data) {
  return {
    type: Types.GET_WORKING_HOURS_INFO_ERROR,
    payload: data,
  };
}

// get vendor info

export function getVendorInfoRequest(data) {
  return {
    type: Types.GET_VENDOR_INFO_REQUEST,
    payload: data,
  };
}

export function getVendorInfoSuccess(data) {
  return {
    type: Types.GET_VENDOR_INFO_SUCCESS,
    payload: data,
  };
}

export function getVendorInfoError(data) {
  return {
    type: Types.GET_VENDOR_INFO_ERROR,
    payload: data,
  };
}

// vendor in
export const setVendorPollIsOpenSuccess = createAction(
  Types.SET_VENDOR_POLL_ISOPEN_SUCCESS,
);

export const setVendorPollIsOpenError = createAction(
  Types.SET_VENDOR_POLL_ISOPEN_ERROR,
);

export const openStatusPollStart = createAction(Types.ISOPEN_POLL_START);
export const openStatusPollStop = createAction(Types.ISOPEN_POLL_STOP);
