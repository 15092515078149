import CookieName from 'constants/cookieName';
import { apiGateway } from 'constants/services';
import cookies from 'utils/cookie';

const getAccessToken = () => cookies.get(CookieName.ACCESS_TOKEN);

const exportExcelFinancials = args => {
  const { partnerId, startDate, endDate, orderInfo, transactionInfo } = args;
  const accessToken = getAccessToken();

  return `${apiGateway}/finance-service/export/daily-finance-info?partnerId=${partnerId}&startDate=${startDate}&endDate=${endDate}&orderInfo=${orderInfo}&transactionInfo=${transactionInfo}&token=${accessToken}`;
};

const exportExcelDetailedFinancials = args => {
  const { partnerId, startDate, endDate, transactionOrderType } = args;
  const accessToken = getAccessToken();

  return `${apiGateway}/finance-service/export/daily-finance-info/details?partnerId=${partnerId}&startDate=${startDate}&endDate=${endDate}&orderType=${transactionOrderType}&token=${accessToken}`;
};

const exportExcelDetail = args => {
  const { id, transactionType } = args;
  const accessToken = getAccessToken();

  return `${apiGateway}/finance-service/export/daily-finance-info/detail/${id}?orderType=${transactionType}&token=${accessToken}`;
};

export {
  exportExcelFinancials,
  exportExcelDetail,
  exportExcelDetailedFinancials,
};
