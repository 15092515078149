import { takeLatest, put, call } from 'redux-saga/effects';

import * as LoaderActions from 'components/Loader/actions';
import {
  uploadFileApi,
  updateBillingStatusApi,
  getBillingImageApi,
  deleteBillingImageApi,
  getBillingDetailApi,
} from 'services/BillingDetail/api';
import { open } from 'utils/notification';
import translate from 'utils/translate';
import * as PreviousOrderAction from 'containers/PreviousOrders/actions';

import * as BillingDetailModalActions from './actions';
import * as Types from './types';

function* uploadBillingFileSaga({ payload }) {
  const { formData, orderId } = payload;

  try {
    yield put(BillingDetailModalActions.isUploading(true));
    const result = yield call(uploadFileApi, { file: formData, orderId });
    if (result.data) {
      open(
        'success',
        translate('common:notif.successTitle'),
        translate('billingUploadModal:billingUploaded'),
      );
      yield put(PreviousOrderAction.revalidateFilteredOrders());
      yield put(BillingDetailModalActions.isSuccessful(true));
    } else {
      open('warning', translate('common:notif.warnTitle'), result.message);
      yield put(BillingDetailModalActions.isSuccessful(false));
    }
  } catch (error) {
    open('warning', translate('common:notif.warnTitle'), error.message);
    yield put(BillingDetailModalActions.isSuccessful(false));
    yield put(BillingDetailModalActions.uploadFileError(error));
  } finally {
    yield put(BillingDetailModalActions.isUploading(false));
  }
}

function* deleteBillingFileSaga({ payload }) {
  try {
    if (payload) {
      const result = yield call(deleteBillingImageApi, payload);
      if (result.data) {
        yield put(PreviousOrderAction.revalidateFilteredOrders());
        yield put(BillingDetailModalActions.deleteImageSuccess(true));
        open(
          'success',
          translate('common:notif.successTitle'),
          translate('billingUploadModal:billingDeleted'),
        );
      }
      if (result.message) {
        open('warning', translate('common:notif.warnTitle'), result.message);
      }
    }
  } catch (error) {
    yield put(BillingDetailModalActions.deleteImageError(error));
    open('warning', translate('common:notif.warnTitle'), error.message);
  }
}

function* getBillingDetailSaga({ payload }) {
  const { orderId } = payload;
  try {
    yield put(LoaderActions.showLoader());
    const imageResult = yield call(getBillingImageApi, orderId);
    const detailResult = yield call(getBillingDetailApi, orderId);
    if (imageResult.data) {
      const blob = new Blob([imageResult.data]);
      const imageURL = URL.createObjectURL(blob);
      yield put(BillingDetailModalActions.getBillingImageSuccess(imageURL));
    } else {
      open('warning', translate('common:notif.warnTitle'), imageResult.message);
    }
    if (detailResult.data && detailResult.data.payload) {
      yield put(
        BillingDetailModalActions.getBillingDetailSuccess(
          detailResult.data.payload,
        ),
      );
    } else {
      open(
        'warning',
        translate('common:notif.warnTitle'),
        detailResult.message,
      );
    }
  } catch (error) {
    yield put(BillingDetailModalActions.getBillingDetailError(error));
    open('warning', translate('common:notif.warnTitle'), error.message);
  } finally {
    yield put(LoaderActions.hideLoader());
  }
}

function* approveBillingSaga({ payload }) {
  const { orderId, status } = payload;

  try {
    const result = yield call(updateBillingStatusApi, { status, orderId });
    if (result.data) {
      yield put(PreviousOrderAction.revalidateFilteredOrders());
      open(
        'success',
        translate('common:notif.successTitle'),
        translate('billingUploadModal:billingApproved'),
      );
      yield put(BillingDetailModalActions.closeBillingModal());
    }
    if (result.message) {
      open('warning', translate('common:notif.warnTitle'), result.message);
    }
  } catch (error) {
    open('warning', translate('common:notif.warnTitle'), error.message);
  }
}

function* refuseBillingSaga({ payload }) {
  const { orderId, cancelReason, status } = payload;
  try {
    const result = yield call(updateBillingStatusApi, {
      status,
      orderId,
      cancelReason,
    });

    if (result.data) {
      yield put(PreviousOrderAction.revalidateFilteredOrders());
      open(
        'success',
        translate('common:notif.successTitle'),
        translate('billingUploadModal:billingRejected'),
      );
      yield put(BillingDetailModalActions.closeBillingModal());
    }
    if (result.message) {
      open('warning', translate('common:notif.warnTitle'), result.message);
    }
  } catch (error) {
    open('warning', translate('common:notif.warnTitle'), error.message);
  }
}

export function* rootSaga() {
  yield takeLatest(
    Types.BILLING_DETAIL_BILLING_DETAIL_REQUEST,
    getBillingDetailSaga,
  );
  yield takeLatest(
    Types.BILLING_DETAIL_ADMIN_REFUSE_REQUEST,
    refuseBillingSaga,
  );
  yield takeLatest(
    Types.BILLING_DETAIL_ADMIN_APPROVE_REQUEST,
    approveBillingSaga,
  );
  yield takeLatest(Types.BILLING_DETAIL_UPLOAD_REQUEST, uploadBillingFileSaga);
  yield takeLatest(
    Types.BILLING_DETAIL_DELETE_IMAGE_REQUEST,
    deleteBillingFileSaga,
  );
}

export default rootSaga;
