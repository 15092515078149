import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Space } from 'antd';
import { Button, Icon } from '@getir/web-components';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import * as ROUTES from 'constants/routes';
import Layout from 'components/Layout';
import * as CS from 'common/style';
import ConfirmModal from 'components/ConfirmModal';

import * as BrandActions from './actions';
import OrderList from './OrderList/OrderList';

const BrandProductList = () => {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation(['common', 'brands']);
  const orderListRef = useRef(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    dispatch(BrandActions.brandProductListRequest(params.id));
  }, [params.id, dispatch]);

  const saveOrder = () => {
    const productsState = orderListRef.current;

    dispatch(
      BrandActions.brandProductListSave({
        brandId: params.id,
        orderOfProducts: productsState.map((item, index) => ({
          productId: item.id,
          productOrder: index,
        })),
      }),
    );
    setIsModalVisible(false);
  };

  const showSaveOrderModal = () => setIsModalVisible(true);

  return (
    <Layout>
      <CS.PageHeader>
        <Space>
          <Icon name="arrow-left" onClick={() => history.push(ROUTES.BRANDS)} />
          <CS.PageTitle>{t('brands:productListScreenTitle')}</CS.PageTitle>
        </Space>
        <Space>
          <Button kind="secondary" onClick={() => history.push(ROUTES.BRANDS)}>
            {t('cancel')}
          </Button>
          <Button onClick={showSaveOrderModal}>{t('save')}</Button>
        </Space>
      </CS.PageHeader>
      <OrderList ref={orderListRef} />
      <ConfirmModal
        size="400px"
        visible={isModalVisible}
        onSave={saveOrder}
        onClose={() => setIsModalVisible(false)}
        title={t('brands:orderBrandProductListTitle')}
        content={t('brands:orderBrandProductListText')}
        cancelText={t('cancel')}
      />
    </Layout>
  );
};
export { BrandProductList };
