import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Radio, Space, Input } from 'antd';

import Button from 'components/v1/Button';
import * as S from 'common/style';
import GetirLogo from 'common/svg/logo.svg';
import {
  ORDERCANCEL_REASON,
  ORDERCANCEL_REASON_TRANSLATEKEYS,
} from 'constants/orderCancelReasons';
import * as ActiveOrderActions from 'containers/ActiveOrders/actions';
import * as MissinProductActions from 'components/MissingProductModal/actions';
import { ORDER_STATUS } from 'constants/orderStatus';
import { OrderCancelSource } from 'common/constants';

import * as OrderCancelModalActions from './actions';

const OrderCancelModal = () => {
  const { t } = useTranslation(['ordercancelmodal']);
  const [radioState, setRadioState] = useState(null);
  const [noteState, setNoteState] = useState('');
  const { isModalOpen, orderId } = useSelector(state => state.orderCancel);

  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(OrderCancelModalActions.closeOrderCancelModal());
    setNoteState('');
    setRadioState(null);
  };

  const sendOrderCancelRequest = () => {
    if (radioState === ORDERCANCEL_REASON.MISSING_PRODUCT) {
      dispatch(
        MissinProductActions.openMissingProduct({
          id: orderId,
          cancelNote: noteState,
        }),
      );
      closeModal();

      return;
    }
    dispatch(
      ActiveOrderActions.changeOrderStatusRequest({
        cancelReason: radioState,
        id: orderId,
        status: ORDER_STATUS.CANCELED_VENDOR,
        cancelNote: noteState,
        cancelSource: OrderCancelSource.VENDOR_PANEL,
      }),
    );
    closeModal();
  };

  return (
    <S.StyledModal
      isOpen={isModalOpen}
      width={400}
      footer={[
        <Button key="buttonClose" kind="secondary" onClick={closeModal}>
          {t('common:giveUp')}
        </Button>,
        <Button
          kind="primary"
          key="buttonCancel"
          disabled={radioState === null}
          onClick={sendOrderCancelRequest}
        >
          {t('ordercancelmodal:cancelOrder')}
        </Button>,
      ]}
      footerOptions={{
        customFooter: (
          <S.FooterContainer>
            <Button key="buttonClose" kind="secondary" onClick={closeModal}>
              {t('common:giveUp')}
            </Button>
            <Button
              kind="primary"
              key="buttonCancel"
              disabled={radioState === null}
              onClick={sendOrderCancelRequest}
            >
              {t('ordercancelmodal:cancelOrder')}
            </Button>
          </S.FooterContainer>
        ),
      }}
      title={<img src={GetirLogo} alt="GetirWaterLogo" />}
      onClose={closeModal}
    >
      <Radio.Group
        name="radioinput"
        value={radioState}
        onChange={e => setRadioState(e.target.value)}
      >
        <Space direction="vertical">
          {Object.entries(ORDERCANCEL_REASON).map(([key]) => (
            <Radio key={`radio${key}`} value={ORDERCANCEL_REASON[key]}>
              {t(ORDERCANCEL_REASON_TRANSLATEKEYS[key])}
            </Radio>
          ))}
          <Input.TextArea onChange={e => setNoteState(e.target.value)} />
        </Space>
      </Radio.Group>
    </S.StyledModal>
  );
};

export default OrderCancelModal;
