import React from 'react';
import PropTypes from 'prop-types';

const SolidCarIcon = ({ fill, width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill={fill}
  >
    <path
      d="M13.3332 15.8333H6.6665V17.0833C6.6665 17.4148 6.53481 17.7328 6.30039
       17.9672C6.06597 18.2016 5.74802 18.3333 5.4165 18.3333H4.58317C4.25165 18.3333
        3.93371 18.2016 3.69929 17.9672C3.46487 17.7328 3.33317 17.4148 3.33317 
        17.0833V15.6916C2.84561 15.5191 2.42352 15.1997 2.12504 14.7773C1.82655 
        14.355 1.66635 13.8505 1.6665 13.3333V10.8333C1.6665 10.3166 1.82317 9.83663
         2.0915 9.43829L2.9965 3.77163C3.09049 3.18428 3.39081 2.64969 3.84352 
         2.26387C4.29624 1.87805 4.87169 1.66629 5.4665 1.66663H14.5332C15.1275 
         1.66712 15.7023 1.87935 16.1543 2.26527C16.6064 2.65118 16.9061 3.18553 
         16.9998 3.77246L17.9023 9.43079C18.174 9.83079 18.3323 10.3141 18.3323 
         10.8333V13.3333C18.3325 13.8505 18.1723 14.355 17.8738 14.7773C17.5753 15.1997 17.1532 
         15.5191 16.6657 15.6916V17.0833C16.6657 17.4148 16.534 17.7328 16.2996 17.9672C16.0651 
         18.2016 15.7472 18.3333 15.4157 18.3333H14.5823C14.2508 18.3333 13.9329 18.2016 13.6985 
         17.9672C13.464 17.7328 13.3323 17.4148 13.3323 17.0833V15.8333H13.3332ZM16.0415 8.34163L15.3548
          4.03496C15.3236 3.83946 15.2237 3.66149 15.0731 3.53291C14.9226 3.40434 14.7312 3.33358 14.5332
           3.33329H5.46567C5.2674 3.33318 5.07558 3.40377 4.92468 3.53237C4.77377 3.66098 4.67366 3.83918
            4.64234 4.03496L3.95484 8.34246C4.02523 8.33645 4.09585 8.3334 4.1665 8.33329H15.8332C15.9032 8.33329
             15.9732 8.33579 16.0415 8.34163ZM14.5832 13.3333C14.7473 13.3333 14.9099 13.301 15.0615 13.2381C15.2132
              13.1753 15.351 13.0832 15.4671 12.9672C15.5831 12.8511 15.6752 12.7133 15.738 12.5616C15.8008 12.41 15.8332
               12.2474 15.8332 12.0833C15.8332 11.9191 15.8008 11.7566 15.738 11.6049C15.6752 11.4533 15.5831 11.3155
                15.4671 11.1994C15.351 11.0833 15.2132 10.9913 15.0615 10.9284C14.9099 10.8656 14.7473 10.8333 14.5832
                 10.8333C14.2517 10.8333 13.9337 10.965 13.6993 11.1994C13.4649 11.4338 13.3332 11.7518 13.3332
                  12.0833C13.3332 12.4148 13.4649 12.7328 13.6993 12.9672C13.9337 13.2016 14.2517 13.3333 14.5832 
                  13.3333ZM5.4165 13.3333C5.58066 13.3333 5.7432 13.301 5.89486 13.2381C6.04652 13.1753 6.18431 13.0832
                   6.30039 12.9672C6.41646 12.8511 6.50854 12.7133 6.57135 12.5616C6.63417 12.41 6.6665 12.2474 6.6665
                    12.0833C6.6665 11.9191 6.63417 11.7566 6.57135 11.6049C6.50854 11.4533 6.41646 11.3155 6.30039 11.1994C6.18431 
                    11.0833 6.04652 10.9913 5.89486 10.9284C5.7432 10.8656 5.58066 10.8333 5.4165 10.8333C5.08498 10.8333 4.76704
                     10.965 4.53262 11.1994C4.2982 11.4338 4.1665 11.7518 4.1665 12.0833C4.1665 12.4148 4.2982 12.7328 4.53262
                      12.9672C4.76704 13.2016 5.08498 13.3333 5.4165 13.3333ZM6.00317 4.24496C6.22418 4.24496 6.43615 4.33276 6.59243
                       4.48904C6.74871 4.64532 6.8365 4.85728 6.8365 5.07829V6.74496C6.8365 6.96597 6.74871 7.17794 6.59243
                        7.33422C6.43615 7.4905 6.22418 7.57829 6.00317 7.57829C5.78216 7.57829 5.5702 7.4905 5.41392 7.33422C5.25763
                         7.17794 5.16984 6.96597 5.16984 6.74496V5.07829C5.16984 4.85728 5.25763 4.64532 5.41392 4.48904C5.5702
                          4.33276 5.78216 4.24496 6.00317 4.24496Z"
      fill="inherit"
    />
  </svg>
);

SolidCarIcon.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

SolidCarIcon.defaultProps = {
  fill: '#5D3EBC',
  width: 14,
  height: 14,
};

export default SolidCarIcon;
