import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Col, Input, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { get, isNil, isNull, noop } from 'lodash';

import { FullWidthButton } from 'common/style';
import { open } from 'utils/notification';
import copyToClipboard from 'utils/copyToClipboard';
import { convertToJSON } from 'containers/GeoMap/common';

import useGeoMapTranslation from '../../useGeoMapTranslation';

const { TextArea } = Input;

const AddArea = ({
  geomapStateKey,
  Actions = {
    geomapGetRequest: noop,
    geomapSaveRequest: noop,
  },
}) => {
  const { t } = useGeoMapTranslation();
  const dispatch = useDispatch();

  const {
    map: polygons,
    city: currentCity,
    brand: currentBrand,
    interval: currentInterval,
    center,
    saveSuccessId,
  } = useSelector(state => state[geomapStateKey]);

  const [disabled, setDisabled] = useState(true);
  const isMapObtained = useCallback(() => !!center, [center]);
  const getEligibleJSONData = useCallback(
    () => convertToJSON(isMapObtained() ? polygons : undefined),
    [isMapObtained, polygons],
  );
  const [geoJSONString, setGeoJSONString] = useState(getEligibleJSONData());

  useEffect(() => {
    setGeoJSONString(getEligibleJSONData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [polygons]);

  useEffect(() => {
    if (!saveSuccessId) return;

    dispatch(
      Actions.geomapGetRequest({
        brandId: currentBrand.value,
        city: currentCity.value,
        country: currentCity.country,
        intervalType: get(currentInterval, 'value', currentInterval),
      }),
    );
    setDisabled(true);

    // eslint-disable-next-line
  }, [saveSuccessId]);

  const parseGeoJSONString = () => {
    let parsedGeoJSON;
    let isThereAnyParseError = false;
    try {
      parsedGeoJSON = JSON.parse(geoJSONString);
    } catch {
      isThereAnyParseError = true;
    }
    const isGeoJSONValid = !isThereAnyParseError && !isNil(parsedGeoJSON);

    if (!isGeoJSONValid) {
      open('warn', t('notif.warnTitle'), t('messages.invalidJson'));

      return null;
    }

    return parsedGeoJSON;
  };

  const copyGeoJSON = () => {
    const modifiedGeoJSONString = convertToJSON(parseGeoJSONString(), {
      beautify: false,
    });
    copyToClipboard(
      modifiedGeoJSONString,
      'geomap:common.copy.success',
      'geomap:common.copy.warning',
    );
  };

  const sendGeoJSON = () => {
    const parsedGeoJSON = parseGeoJSONString();

    dispatch(
      Actions.geomapSaveRequest({
        brandId: currentBrand.value,
        city: currentCity.value,
        country: currentCity.country,
        intervalType: get(currentInterval, 'value', currentInterval),
        geoJson: isNull(parsedGeoJSON) ? polygons : parsedGeoJSON,
      }),
    );
  };

  return (
    <Col span={24}>
      <Space direction="vertical" style={{ width: '100%' }}>
        {disabled && (
          <FullWidthButton
            disabled={!isMapObtained()}
            type="primary"
            onClick={() => setDisabled(false)}
          >
            {t('button.add')}
          </FullWidthButton>
        )}
        <FullWidthButton
          type="primary"
          disabled={!isMapObtained()}
          onClick={copyGeoJSON}
        >
          {t('button.copy')}
        </FullWidthButton>
        {!disabled && (
          <FullWidthButton type="primary" onClick={sendGeoJSON}>
            {t('button.save')}
          </FullWidthButton>
        )}
        {!disabled && (
          <FullWidthButton type="primary" onClick={() => setDisabled(true)}>
            {t('button.cancel')}
          </FullWidthButton>
        )}
        <TextArea
          disabled={disabled}
          value={geoJSONString}
          onChange={e => {
            setGeoJSONString(e.target.value);
          }}
          style={{ height: 290, overflow: 'scroll' }}
        ></TextArea>
        <Alert
          message={t('firmPolygonChangeInfo')}
          type="warning"
          closable
          showIcon
        />
      </Space>
    </Col>
  );
};

AddArea.propTypes = {
  geomapStateKey: PropTypes.string.isRequired,
  Actions: PropTypes.shape({
    geomapGetRequest: PropTypes.func.isRequired,
    geomapSaveRequest: PropTypes.func.isRequired,
  }).isRequired,
};

export default AddArea;
