import { apiGateway } from 'constants/services';

export default {
  getUserList: () => `${apiGateway}/user-service/users/filter`,
  createUser: `${apiGateway}/user-service/admin/internal/users/user`,
  deleteUser: userId => `${apiGateway}/user-service/admin/users/user/${userId}`,
  updateUser: userId =>
    `${apiGateway}/user-service/admin/internal/users/user/${userId}`,
  getRolesByRoleGroup: roleGroupName =>
    `${apiGateway}/user-service/roles/?roleGroupName=${roleGroupName}`,
  getRolesByUserId: userId =>
    `${apiGateway}/user-service/users/user/${userId}/roles`,
};
