import { apiGateway } from 'constants/services';

export default {
  getMap: `${apiGateway}/vendor-service/gisexpert/vendor-polygons`,
  saveMap: `${apiGateway}/vendor-service/gisexpert/vendor-polygons`,
  getBannedVendors: `${apiGateway}/vendor-service/gisexpert/banned-brand-vendors`,
  getVendors: `${apiGateway}/vendor-service/vendors/by-brand-id-and-city-id`,
  getDistricts: `${apiGateway}/vendor-service/gis/districts`,
  getNeighbourhoods: `${apiGateway}/vendor-service/gis/neighbourhoods`,
};
