import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import _ from 'lodash';
import { Tooltip } from 'antd';

import * as OrderDetailActions from 'components/OrderDetailModal//actions';
import {
  ORDER_STATUS_CODES,
  ORDER_STATUS_TRANSLATEKEY,
} from 'constants/orderStatus';
import { DurationCell } from 'components/DurationCell/DurationCell';
import { ClockIcon } from 'components/v1/Icons';
import Badge from 'components/v1/Badge';
import ElipsisIcon from 'components/v1/Icons/ElipsisIcon';
import Button from 'components/v1/Button';
import { DURATION_TYPES } from 'constants/durationTypes';

import * as S from './style';

const useCanceledOrdersColumns = () => {
  const dispatch = useDispatch();
  const modalOpenAction = id => {
    dispatch(OrderDetailActions.getOrderDetailRequest(id));
  };
  const { t } = useTranslation();
  const dateColumn = text => {
    if (text && text.length) {
      const d = new Date(text);

      return dayjs(d).format('DD.MM.YYYY, H:mm:ss');
    }

    return '';
  };

  return [
    {
      title: t('activeOrders:columns.confirmationCode'),
      dataIndex: 'confirmationCode',
    },
    {
      title: t('activeOrders:columns.vendor'),
      dataIndex: 'vendor',
      render: vendor => _.get(vendor, 'name', ''),
    },
    {
      title: t('activeOrders:columns.customer'),
      dataIndex: 'client',
      render: text => text.name,
    },
    {
      title: t('activeOrders:columns.orderPrice'),
      dataIndex: 'totalPriceText',
    },
    {
      title: t('activeOrders:columns.orderDate'),
      dataIndex: 'paymentDate',
      render: dateColumn,
      defaultSortOrder: 'descend',
      sorter: (
        { paymentDate: firstPaymentDate },
        { paymentDate: secondPaymentDate },
      ) => {
        const firstTime = Date.parse(firstPaymentDate);
        const secondTime = Date.parse(secondPaymentDate);

        return firstTime - secondTime;
      },
    },
    {
      title: t('activeOrders:columns.cancellationDate'),
      dataIndex: 'cancelDate',
      render: dateColumn,
    },

    {
      title: t('activeOrders:columns.status'),
      dataIndex: 'status',
      render: text => {
        let type = 'waiting';
        let icon;

        if (text <= 700) {
          type = 'primary';
          icon = <ClockIcon />;
        } else if (text <= 1000) {
          type = 'success';
        } else {
          type = 'failed';
        }

        return (
          <Badge icon={icon} type={type}>
            {t(ORDER_STATUS_TRANSLATEKEY[ORDER_STATUS_CODES[text]])}
          </Badge>
        );
      },
    },
    {
      title: t('activeOrders:columns.totalTime'),
      dataIndex: 'totalTime',
      render: (text, row) => {
        if (row.paymentDate && row.cancelDate) {
          return (
            <DurationCell
              firstDate={row.cancelDate}
              limitDuration={DURATION_TYPES.DELAYED_ORDER}
              secondDate={row.paymentDate}
              hideBar
              withBadge
            />
          );
        }

        return '';
      },
    },
    {
      title: t('activeOrders:columns.action'),
      fixed: 'right',
      width: 100,
      align: 'center',
      render: (text, row) => (
        <S.StyledCell>
          <Tooltip title={t('common:detail')}>
            <Button kind="secondary" onClick={() => modalOpenAction(row.id)}>
              <ElipsisIcon width={20} height={20} />
            </Button>
          </Tooltip>
        </S.StyledCell>
      ),
    },
  ];
};

export { useCanceledOrdersColumns };
