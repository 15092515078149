import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import UnderlineExt from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import ImageExt from '@tiptap/extension-image';
import Link from '@tiptap/extension-link';
import React, { forwardRef, useImperativeHandle, memo } from 'react';
import PropTypes from 'prop-types';

import * as S from './styles';
import MenuBar from './MenuBar';

const RichTextEditor = ({ onUpdate, initialContent }, ref) => {
  const editor = useEditor({
    onUpdate: ({ editor: e }) => {
      if (!!onUpdate && typeof onUpdate === 'function') {
        onUpdate(e);
      }
    },
    extensions: [
      StarterKit,
      ImageExt,
      Link.configure({
        validate: href => /^https?:\/\//.test(href),
      }),
      UnderlineExt,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
    ],
    content: initialContent,
  });

  useImperativeHandle(ref, () => ({
    getHTML: () => editor.getHTML(),
    getJSON: () => editor.getJSON(),
    getText: () => editor.getText(),
    clearData: () => editor.commands.clearContent(),
  }));

  if (!editor) return null;

  return (
    <S.EditorContainer>
      <MenuBar editor={editor} />
      <S.Seperator />
      <EditorContent editor={editor} />
    </S.EditorContainer>
  );
};

RichTextEditor.propTypes = {
  onUpdate: PropTypes.func,
  initialContent: PropTypes.string,
};

RichTextEditor.defaultProps = {
  onUpdate: () => {},
  initialContent: undefined,
};

export default memo(forwardRef(RichTextEditor));
