import { createAction } from 'utils/createAction';

import * as Types from './types';

export const getReportsRequest = createAction(Types.GET_REPORTS_REQUEST);
export const getReportsSuccess = createAction(Types.GET_REPORTS_SUCCESS);
export const getReportsError = createAction(Types.GET_REPORTS_ERROR);

export const createReportRequest = createAction(Types.CREATE_REPORT_REQUEST);
export const createReportSuccess = createAction(Types.CREATE_REPORT_SUCCESS);
export const createReportError = createAction(Types.CREATE_REPORT_ERROR);

export const changeFilters = createAction(Types.CHANGE_FILTERS);
export const getDownloadUrl = createAction(Types.GET_DOWNLOAD_URL);
