import React from 'react';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReloadOutlined } from '@ant-design/icons';

import useResponsive from 'hooks/useResponsive';
import Button from 'components/v1/Button/Button';
import Table from 'components/v1/Table';
import MobileOrders from 'components/v1/MobileOrders/MobileOrders';
import VendorStatus from 'components/v1/VendorStatus';

import * as S from '../style';

import { useGetCompletedOrderList } from './useGetCompletedOrderList';
import { useCompletedOrdersColumns } from './useCompletedOrdersColumns';

const CompletedOrders = () => {
  const { t } = useTranslation();
  const { completedList: completedOrdersList, completedTotal } = useSelector(
    state => state.activeOrders,
  );
  const { isMobileView, isMiniTabletView, isTabletView } = useResponsive();
  const isUnderTabletView = isMobileView || isMiniTabletView || isTabletView;

  const completedOrdersColumns = useCompletedOrdersColumns();

  const { getCompletedOrderList } = useGetCompletedOrderList();

  return isMobileView ? (
    <MobileOrders isActive={false} orders={completedOrdersList} />
  ) : (
    <>
      {isUnderTabletView && <VendorStatus />}
      <S.PageHeader>
        <Button kind="primary" onClick={getCompletedOrderList}>
          <ReloadOutlined />
          {t('activeOrders:refreshButton')}
        </Button>
      </S.PageHeader>
      <S.HeaderContainer>
        <S.TotalAmountSection>
          {t('activeOrders:totalPrice')}:
          <span>₺{completedTotal?.toFixed(2)}</span>
        </S.TotalAmountSection>
      </S.HeaderContainer>
      <Table
        columns={completedOrdersColumns}
        dataSource={completedOrdersList}
        locale={{ emptyText: <Empty description={t('table.noData')} /> }}
        bordered
        pagination={{ size: 'small', position: ['bottomCenter'] }}
        rowKey="id"
        scroll={{ x: 1500 }}
      />
    </>
  );
};

export default CompletedOrders;
