import React, { useEffect, useImperativeHandle, useRef } from 'react';
import { Divider, Space } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SearchOutlined } from '@ant-design/icons';

import Input from 'components/v1/Input';
import Button from 'components/v1/Button';
import SelectBoxAntd from 'components/Antd/FormElements/SelectBox';
import * as CategoryActions from 'containers/CategoryManagement/Category/actions';
import * as BrandActions from 'containers/Stores/Brands/actions';

import * as S from './style';

const CatalogProductListHeader = (
  { handleSubmit, control, onReset, errors, searchText, setSearchText },
  ref,
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const brands = useSelector(state => state.brand.brands);
  const categories = useSelector(state => state.category.categorys);

  useEffect(() => {
    if (categories === undefined)
      dispatch(CategoryActions.categoryListRequest());
  }, [dispatch, categories]);

  useEffect(() => {
    if (brands === undefined) dispatch(BrandActions.brandListRequest());
  }, [dispatch, brands]);

  const formRef = useRef();
  const validationRules = {
    categoryName: {},
    brandName: {},
  };

  const optionForAll = { label: 'Tümü', value: 0 };

  /* eslint-disable */
    const brandsOptions =
      brands !== undefined
        ? brands.map(item => ({
            label: item.brandName,
            value: item.id,
          }))
        : [];
    const categoriesOptions =
      categories !== undefined
        ? categories.map(item => ({
            label: item.categoryNameTR,
            value: item.id,
          }))
        : [];
    /* eslint-enable */

  useImperativeHandle(ref, () => ({ formRef }), [formRef]);

  return (
    <S.TableHeaderWrapper>
      <Input
        value={searchText}
        onChange={evt => setSearchText(evt.target.value)}
        placeholder={t('catalogs:filter.productName')}
        size="medium"
        style={{ width: '300px' }}
        prefix={<SearchOutlined />}
      />
      <Divider />
      <form onSubmit={handleSubmit} ref={formRef}>
        <S.TableInputFilterWrapper>
          <SelectBoxAntd
            control={control}
            fieldName="categoryName"
            placeholder={t('tableFilters.selectCategory')}
            showSearch
            labelInValue
            options={[...categoriesOptions, optionForAll]}
            rules={validationRules.categoryName}
            errors={errors}
          />
          <SelectBoxAntd
            control={control}
            fieldName="brandName"
            placeholder={t('tableFilters.selectBrand')}
            showSearch
            labelInValue
            options={[...brandsOptions, optionForAll]}
            rules={validationRules.brandName}
            errors={errors}
          />
        </S.TableInputFilterWrapper>

        <Space size="small">
          <Button type="button" kind="secondary" onClick={() => onReset()}>
            {t('clear')}
          </Button>
          <Button type="submit">{t('filter')}</Button>
        </Space>
      </form>
    </S.TableHeaderWrapper>
  );
};

CatalogProductListHeader.defaultProps = {
  handleSubmit: Function,
  control: Object,
  onReset: Function,
  errors: Object,
  searchText: '',
  setSearchText: Function,
};

CatalogProductListHeader.propTypes = {
  handleSubmit: PropTypes.func,
  control: PropTypes.object,
  onReset: PropTypes.func,
  errors: PropTypes.object,
  searchText: PropTypes.string,
  setSearchText: PropTypes.func,
};

export default React.forwardRef(CatalogProductListHeader);
