import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import * as S from './style';

const Toggle = (
  {
    size,
    checked,
    disabled,
    onChange,
    color,
    checkedChildren,
    unCheckedChildren,
    loading,
  },
  ref,
) => (
  <S.Switch
    onChange={onChange}
    disabled={disabled}
    checked={checked}
    loading={loading}
    checkedChildren={checkedChildren}
    unCheckedChildren={unCheckedChildren}
    ref={ref}
    $size={size}
    $color={color}
  ></S.Switch>
);

Toggle.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  color: PropTypes.string,
  checkedChildren: PropTypes.node,
  unCheckedChildren: PropTypes.node,
  loading: PropTypes.bool,
};

Toggle.defaultProps = {
  size: 'medium',
  checked: false,
  disabled: false,
  onChange: () => {},
  color: '#5d3ebc',
  checkedChildren: null,
  unCheckedChildren: null,
  loading: false,
};

export default forwardRef(Toggle);
