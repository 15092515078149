import React, { useRef } from 'react';
import { SearchOutlined, FilterOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Input from 'components/v1/Input';
import Button from 'components/v1/Button';

import * as S from './styles';

const Filter = ({ onFilterClick, onClearFilterClick }) => {
  const searchInputRef = useRef(null);
  const { t } = useTranslation('vendorCommunication');

  const onFilterClickHandler = () => {
    const searchValue = searchInputRef.current.value;
    onFilterClick(searchValue);
  };

  const clearFilterClickHandler = () => {
    searchInputRef.current.value = '';
    onClearFilterClick();
  };

  return (
    <S.Container>
      <Input
        placeholder={t('searchToThread')}
        prefix={<SearchOutlined width={20} color="#5D3EBC" height={20} />}
        size="large"
        ref={searchInputRef}
      />
      <Button onClick={onFilterClickHandler} kind="primary">
        <FilterOutlined width={20} height={20} color="#fff" /> {t('filter')}
      </Button>
      <Button onClick={clearFilterClickHandler} kind="secondary">
        {t('clear')}
      </Button>
    </S.Container>
  );
};

Filter.propTypes = {
  onFilterClick: PropTypes.func.isRequired,
  onClearFilterClick: PropTypes.func.isRequired,
};

export default Filter;
