import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Empty, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import locale from 'antd/lib/date-picker/locale/tr_TR';
import dayjs from 'dayjs';
import { isNull } from 'lodash';

import Button from 'components/v1/Button';
import Layout from 'components/Layout';
import * as CS from 'common/style';
import useQuery from 'hooks/useQuery';
import OrderDetailModal from 'components/OrderDetailModal/OrderDetailModal';
import SelectBoxAntd from 'components/Antd/Elements/SelectBox';
import Table from 'components/v1/Table/Table';
import BillingDetailModal from 'components/v1/BillingDetailModal/BillingDetailModal';

import {
  orderPaymentSelectItems,
  orderStateSelectItems,
  standardPaymentList,
  standardStatusList,
  invoiceStatusList,
  invoiceStatus,
} from './constants';
import * as S from './style';
import { usePreviousOrderActions } from './usePreviousOrderActions';
import { usePreviousOrderColumns } from './usePreviousOrdersColumns';
import * as PreviousOrdersActions from './actions';

const defaultFilters = {
  startDate: dayjs().subtract(7, 'd').startOf('d').toISOString(),
  endDate: dayjs().subtract(0, 'd').endOf('d').toISOString(),
  statusList: standardStatusList,
  paymentType: standardPaymentList,
  page: 0,
  size: 10,
  invoiceStatusList: [1, 2, 3, 4],
};

const PreviousOrders = () => {
  const { t } = useTranslation(['common', 'paymentmethods']);
  const dispatch = useDispatch();
  const vendorInfo = useSelector(state => state.mainHeader.vendorInfo);

  const { list, total, totalCount } = useSelector(
    state => state.previousOrders,
  );
  const params = useQuery();

  const [paymentItems, setPaymentItems] = useState([]);
  const [statusItems, setSelectedStatusItems] = useState([]);
  const [invoiceStatusItems, setInvoiceStatusItems] = useState([]);

  const [dateRangeValues, setDateRangeValues] = useState({
    startDate: dayjs().subtract(7, 'd').startOf('d').toISOString(),
    endDate: dayjs().subtract(0, 'd').endOf('d').toISOString(),
  });
  const [pagination, setPagination] = useState({
    current: defaultFilters.page + 1,
    pageSize: defaultFilters.size,
    total: totalCount,
    size: 'small',
    showSizeChanger: true,
  });

  usePreviousOrderActions(
    statusItems,
    paymentItems,
    dateRangeValues,
    pagination,
  );

  const previousOrderColumns = usePreviousOrderColumns();

  useEffect(() => {
    const paramVendorId = params.get('vendorId');
    const paramOrderDate = params.get('date');

    if (isNull(paramVendorId) || isNull(paramOrderDate)) return;

    const dateRange = {
      startDate: dayjs(+paramOrderDate)
        .subtract(7, 'd')
        .toISOString(),
      endDate: dayjs(+paramOrderDate).toISOString(),
    };

    setDateRangeValues({ ...dateRange });
    const data = {
      statusList: standardStatusList,
      vendorId: paramVendorId,
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
      paymentType: standardPaymentList,
      page: defaultFilters.page,
      size: defaultFilters.size,
      invoiceStatusList: defaultFilters.invoiceStatusList,
    };

    setPagination({
      ...pagination,
      current: defaultFilters.page + 1,
      pageSize: defaultFilters.size,
    });

    dispatch(PreviousOrdersActions.getFilteredOrdersRequest(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPagination({
      ...pagination,
      total: totalCount,
    });
  }, [totalCount]); // eslint-disable-line

  const rangeOnChange = values => {
    setDateRangeValues({
      startDate: values ? values[0].startOf('d').toISOString() : null,
      endDate: values ? values[1].endOf('d').toISOString() : null,
    });
  };

  const resetFilters = () => {
    setPaymentItems([]);
    setSelectedStatusItems([]);
    setInvoiceStatusItems([]);
    setDateRangeValues({
      startDate: null,
      endDate: null,
    });
  };

  const handlePaginationChange = ({ current, pageSize }) => {
    setPagination({ ...pagination, current, pageSize });
  };

  const handleFilterClick = () => {
    const selectedPaymentItems = paymentItems
      .map(
        item => orderPaymentSelectItems.find(i => i.labelKey === item).values,
      )
      .flat();
    const selectedStatusItems = statusItems
      .map(item => orderStateSelectItems.find(i => i.labelKey === item).values)
      .flat();
    const data = {
      statusList:
        selectedStatusItems.length > 0
          ? selectedStatusItems
          : standardStatusList,
      vendorId: vendorInfo.id,
      startDate: dateRangeValues.startDate
        ? dateRangeValues.startDate
        : dayjs(new Date(2021, 1, 1)).toISOString(),
      endDate: dateRangeValues.endDate
        ? dateRangeValues.endDate
        : dayjs().subtract(1, 'd').endOf('day').toISOString(),
      paymentType:
        selectedPaymentItems.length > 0
          ? selectedPaymentItems
          : standardPaymentList,
      page: defaultFilters.page,
      size: defaultFilters.size,
      invoiceStatusList:
        invoiceStatusItems.length > 0
          ? invoiceStatusItems.flat()
          : Object.values(invoiceStatus),
    };

    setPagination({
      ...pagination,
      current: defaultFilters.page + 1,
      pageSize: defaultFilters.size,
    });

    dispatch(PreviousOrdersActions.getFilteredOrdersRequest(data));
  };

  return (
    <Layout>
      <CS.PageHeader>
        <CS.PageTitle>{t('previousorders:title')}</CS.PageTitle>
      </CS.PageHeader>
      <CS.PageBody>
        <S.FiltersArea>
          <div>
            <span>{t('selectDate')}</span>
            <S.StyledRangePicker
              locale={locale}
              format="DD/MM/YYYY"
              onChange={rangeOnChange}
              value={[
                dateRangeValues.startDate !== null
                  ? dayjs(dateRangeValues.startDate)
                  : undefined,
                dateRangeValues.endDate !== null
                  ? dayjs(dateRangeValues.endDate)
                  : undefined,
              ]}
            />
          </div>
          <SelectBoxAntd
            mode="multiple"
            placeholder={t('previousorders:selectPaymentMethod')}
            value={paymentItems}
            onChange={setPaymentItems}
            showSearch={false}
            options={orderPaymentSelectItems.map(item => ({
              label: t(item.labelKey),
              value: item.labelKey,
            }))}
            name="paymentType"
          />
          <SelectBoxAntd
            mode="multiple"
            data-testid="statusSelect"
            placeholder={t('previousorders:selectOrderType')}
            value={statusItems}
            onChange={setSelectedStatusItems}
            showSearch={false}
            options={orderStateSelectItems.map(item => ({
              label: t(item.labelKey),
              value: item.labelKey,
            }))}
            name="orderType"
          />
          <SelectBoxAntd
            data-testid="invoiceStatusSelect"
            mode="multiple"
            placeholder="Fatura Durumu"
            value={invoiceStatusItems}
            onChange={setInvoiceStatusItems}
            showSearch={false}
            options={invoiceStatusList.map(item => ({
              label: t(item.labelKey),
              value: item.value,
            }))}
          />
          <Space direction="vertical" align="end">
            <Space size="small">
              <Button
                kind="secondary"
                data-testid="clearFiltersButton"
                onClick={resetFilters}
                disabled={!vendorInfo.id}
              >
                {t('clear')}
              </Button>
              <Button
                data-testid="filterButton"
                onClick={handleFilterClick}
                disabled={!vendorInfo.id}
              >
                {t('filter')}
              </Button>
            </Space>
          </Space>
        </S.FiltersArea>
        <S.PriceContainer>{`${t('common:total')} : ${
          total !== undefined ? `₺${total.toFixed(2)}` : ''
        }`}</S.PriceContainer>
        <Table
          columns={previousOrderColumns}
          data={list}
          locale={{
            emptyText: <Empty description={t('common:table.noData')} />,
          }}
          pagination={pagination}
          scroll={{ x: 1500 }}
          size="small"
          rowKey="id"
          handlePaginationChange={handlePaginationChange}
        />
      </CS.PageBody>
      <BillingDetailModal />
      <OrderDetailModal />
    </Layout>
  );
};
export { PreviousOrders };
