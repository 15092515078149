export const extractDataFrom = (bulk, openProducts) =>
  openProducts
    .map(item => {
      const vendorProduct = bulk.find(product => product.id === item.productId);

      if (!vendorProduct) return undefined;

      const itemPrice = `${vendorProduct.productName} ${
        item.price ? `- ₺${item.price.toFixed(2)}` : ''
      }`;

      const itemCrossedOutPrice =
        item.price && item.showCrossedOutPrice && item.crossedOutPrice
          ? `₺${item.crossedOutPrice.toFixed(2)}`
          : '';

      const title = {
        price: itemPrice,
        crossedOutPrice: itemCrossedOutPrice,
      };

      return {
        id: vendorProduct.id,
        image: vendorProduct.productImageUrl,
        category: vendorProduct.category.id,
        title,
        open: item.isOpen,
        priceLess: !item.price,
      };
    })
    .filter(item => item !== undefined);
