import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import * as S from './style';

const ArrowBackButton = ({ route, size }) => {
  const history = useHistory();

  return (
    <S.IconProvider
      name="arrow-left"
      size={size}
      format="small"
      onClick={() => history.push(route)}
    />
  );
};

ArrowBackButton.propTypes = {
  route: PropTypes.string.isRequired,
  size: PropTypes.number,
};

ArrowBackButton.defaultProps = {
  size: 18,
};

export default ArrowBackButton;
