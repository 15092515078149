import styled from 'styled-components';

const YandexMapWrapper = styled.div`
  height: 160px;

  ${({ isScheduled }) =>
    isScheduled &&
    `  [class$='gotoymaps'] {
    display: none;
  }`}
`;

export { YandexMapWrapper };
