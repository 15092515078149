import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Space, Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SearchOutlined } from '@ant-design/icons';

import Button from 'components/v1/Button';
import * as ROUTES from 'constants/routes';
import ConfirmModal from 'components/ConfirmModal';
import Table from 'components/v1/Table';
import Input from 'components/v1/Input';
import useDebounce from 'hooks/useDebounce';

import * as RolesActions from '../actions';

import * as S from './style';

const List = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { data: roleList } = useSelector(state => state.authRoles.roleList);
  const { data: deleteRole } = useSelector(state => state.authRoles.deleteRole);
  const [selectedRole, setSelectedRole] = useState(undefined);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 200);
  const deleteRoleModalRef = useRef();
  const [isDeleteRoleModalVisible, setIsDeleteRoleModalVisible] = useState(
    false,
  );

  const handleDeleteRoleModalSave = () => {
    const { roleId } = selectedRole;
    dispatch(RolesActions.deleteRoleRequest(roleId));
    setIsDeleteRoleModalVisible(false);
  };
  const handleDeleteRoleModalClose = () => {
    setIsDeleteRoleModalVisible(false);
  };

  useEffect(() => {
    dispatch(RolesActions.getRoleListRequest());
  }, [dispatch, deleteRole]);

  const handleEditRoleClick = record => {
    const { roleId } = record;
    dispatch(RolesActions.getRoleUserByRoleIdRequest(roleId));
    history.push({
      pathname: generatePath(ROUTES.EDIT_ROLE, { id: roleId }),
      state: {
        role: record,
      },
    });
  };

  const filteredRole = useMemo(
    () =>
      roleList?.filter(role =>
        role.roleName
          .toLocaleLowerCase('tr')
          .includes(debouncedSearchTerm.toLocaleLowerCase('tr')),
      ),
    [roleList, debouncedSearchTerm],
  );

  const handleDeleteRoleClick = record => {
    setSelectedRole(record);
    setIsDeleteRoleModalVisible(true);
  };

  const columns = [
    {
      title: t('authRolesPage:tableColumns.roleName'),
      dataIndex: 'roleName',
      key: 'roleName',
      align: 'center',
      width: 150,
    },
    {
      title: t('authRolesPage:tableColumns.desc'),
      dataIndex: 'roleDesc',
      key: 'roleDesc',
      align: 'center',
      width: 150,
    },
    {
      title: t('authRolesPage:tableColumns.roleType'),
      dataIndex: 'roleGroupName',
      key: 'roleGroupName',
      align: 'center',
      render: roleGroupNames => roleGroupNames.join(', '),
      width: 150,
    },
    {
      title: t('actions'),
      key: 'settings',
      render: (_, record) => (
        <Space size="small">
          <Button kind="secondary" onClick={() => handleEditRoleClick(record)}>
            {t('edit')}
          </Button>
          <Button
            kind="secondary"
            onClick={() => handleDeleteRoleClick(record)}
          >
            {t('delete')}
          </Button>
        </Space>
      ),
      align: 'center',
      width: 50,
      fixed: 'right',
    },
  ];

  return (
    <S.Wrapper>
      <Input
        placeholder={t('authRolesPage:searchRole')}
        value={searchTerm}
        onChange={evt => setSearchTerm(evt.target.value)}
        size="large"
        style={{ width: '300px' }}
        prefix={<SearchOutlined width={20} height={20} />}
        allowClear
      />
      <Table
        data={filteredRole}
        columns={columns}
        pagination={{ size: 'small' }}
        scroll={{ x: 'max-content' }}
        bordered
        locale={{ emptyText: <Empty description={t('table.noData')} /> }}
        rowKey="roleName"
      />
      <ConfirmModal
        modalRef={deleteRoleModalRef}
        size="400px"
        visible={isDeleteRoleModalVisible}
        onSave={handleDeleteRoleModalSave}
        onClose={handleDeleteRoleModalClose}
        title={t('authRolesPage:deleteRoleModal.title')}
        content={t('authRolesPage:deleteRoleModal.text')}
        cancelText={t('giveUp')}
        okText={t('yes')}
      />
    </S.Wrapper>
  );
};

export default List;
