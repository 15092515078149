import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from '@getir/web-components';
import { Router, Switch, Redirect, Route } from 'react-router-dom';

import history from 'utils/history';
import getParams from 'utils/getParams';
import Login from 'containers/Auth/Login';
import * as ROUTES from 'constants/routes';
import Help from 'containers/Help';
import Users from 'containers/StoreDetails/Users';
import ActiveOrders from 'containers/ActiveOrders';
import NotFound from 'containers/NotFound';
import Brands, { BrandDetail } from 'containers/Stores/Brands';
import useIsAuthenticated from 'hooks/useIsAuthenticated';
import AddNewFirm from 'containers/Stores/Firm/Add';
import Vendor from 'containers/Stores/Vendor';
import AddNewVendor from 'containers/Stores/Vendor/Add';
import GeneralInformation from 'containers/StoreDetails/GeneralInformation';
import FinancialInformation from 'containers/StoreDetails/FinancialInformation';
import { WorkingHours } from 'containers/WorkingHours';
import Category from 'containers/CategoryManagement/Category';
import AddCategory from 'containers/CategoryManagement/Category/Add';
import EditCategory from 'containers/CategoryManagement/Category/Edit';
import CatalogProduct from 'containers/CategoryManagement/CatalogProduct';
import AddCatalogProduct from 'containers/CategoryManagement/CatalogProduct/Add';
import EditCatalogProduct from 'containers/CategoryManagement/CatalogProduct/Edit';
import VendorProduct from 'containers/VendorProduct';
import VendorProductDetail from 'containers/VendorProduct/Edit';
import { PaymentMethods } from 'containers/PaymentMethods';
import AuthorizedRoute from 'components/AuthorizedRoute/AuthorizedRoute';
import Firm from 'containers/Stores/Firm';
import { BrandProductList } from 'containers/Stores/Brands/BrandProductList';
import Requests from 'containers/Requests';
import KuzeydenReport from 'containers/KuzeydenReports';
import WorkingHoursRequest from 'containers/Requests/WorkingHours';
import BulkChangeRequest from 'containers/Requests/BulkChange';
import VendorCommunication from 'containers/VendorCommunication';
import { PreviousOrders } from 'containers/PreviousOrders';
import FinancialTransactions from 'containers/Payments/FinancialTransactions';
import DetailOrder from 'containers/Payments/FinancialTransactions/DetailOrder';
import BulkChange from 'containers/BulkChange';
import UploadGeoMap from 'containers/GeoMap/UploadGeoMap';
import AddFinancialTransaction from 'containers/Payments/AddFinancialTransaction';
import AlternativeMapHours from 'containers/GeoMap/AlternativeMapHours';
import AuthUsers from 'containers/UsersPerms/Users';
import AuthPerms from 'containers/UsersPerms/Perms';
import AuthRoles from 'containers/UsersPerms/Roles';
import AddNewRole from 'containers/UsersPerms/Roles/Add';
import EditRole from 'containers/UsersPerms/Roles/Edit';
import { getItem } from 'utils/localStorage';
import * as LoginActions from 'containers/Auth/Login/actions';
import BanGeoMap from 'containers/GeoMap/BanGeoMap';
import useUserInfo from 'hooks/useUserInfo';
import googleTagManager from 'utils/googleTagManager';
import menuItems from 'constants/menuItems';

function App() {
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.loader.status);
  const { data: roleList } = useSelector(state => state.header.roleListByUser);
  const { isLoggedIn } = useIsAuthenticated();
  const { userInfo } = useUserInfo(true);
  const { data: permList } = useSelector(state => state.login.userPerms);

  const token = getParams('token');
  const currentRole = getItem('currentRole');

  useEffect(() => {
    const googleTagManagerID = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;
    const tagManagerArg = { gtmId: googleTagManagerID };
    googleTagManager.initialize(tagManagerArg);
  }, []);

  useEffect(() => {
    if (Object.keys(userInfo).length > 0 && currentRole) {
      const { userId } = userInfo;

      dispatch(
        LoginActions.getUserPermsRequest({
          userId,
          roleId: currentRole,
        }),
      );
    }
  }, [currentRole, userInfo]); // eslint-disable-line

  useEffect(() => {
    if (Object.keys(userInfo).length > 0 && roleList && currentRole) {
      const { userId } = userInfo;
      const selectedRole = roleList.find(role => role.roleName === currentRole);
      const { roleId } = selectedRole;

      dispatch(
        LoginActions.getPermListRequest({
          userId,
          roleId,
        }),
      );
    }
  }, [roleList, currentRole, userInfo]); // eslint-disable-line

  const hasPermList = permList && permList.length > 0;

  const checkPermListAndGetRoute = () => {
    if (!hasPermList) {
      return '';
    }

    const activeOrderComponentId = menuItems.find(
      item => item.route === ROUTES.ACTIVE_ORDERS,
    ).componentId;

    const isUserHasActiveOrders =
      permList.findIndex(item => item.componentId === activeOrderComponentId) >
      -1;

    if (!isUserHasActiveOrders) {
      const { componentId } = permList[0];

      const menuItem = menuItems.find(item => item.componentId === componentId);

      if (menuItem) {
        if (menuItem.route) {
          return menuItem.route;
        }

        const hasChilds =
          !menuItem.route && menuItem.childs && menuItem.childs.length > 0;

        if (hasChilds) {
          const firstChildRoute = menuItem.childs.find(child => !!child.route);

          return firstChildRoute.route || '';
        }

        return '';
      }
    }

    return ROUTES.ACTIVE_ORDERS;
  };

  const mainRoute = checkPermListAndGetRoute();

  return (
    <Spin spinning={isLoading}>
      <Router history={history}>
        <Switch>
          <Redirect from={ROUTES.HOME} to={mainRoute} exact />
          <Route path={ROUTES.HELP} component={Help} exact />
          <Route path={ROUTES.LOGIN} component={Login} exact />
          <Route path={ROUTES.RESET_PASSWORD} component={Login} exact />
          <Route path={ROUTES.CREATE_PASSWORD} component={Login} exact />
          <AuthorizedRoute path={ROUTES.USERS} component={Users} exact />
          <AuthorizedRoute path={ROUTES.BRANDS} component={Brands} exact />
          <AuthorizedRoute
            path={ROUTES.UPLOAD_GEOMAP}
            component={UploadGeoMap}
            exact
          />
          <AuthorizedRoute
            path={ROUTES.BAN_GEOMAP}
            component={BanGeoMap}
            exact
          />
          <AuthorizedRoute
            path={ROUTES.ALTERNATIVE_MAP_HOURS}
            component={AlternativeMapHours}
            exact
          />
          <AuthorizedRoute
            path={ROUTES.BRANDEDIT}
            component={BrandDetail}
            exact
          />
          <AuthorizedRoute
            path={ROUTES.BRANDCREATE}
            component={BrandDetail}
            exact
          />
          <AuthorizedRoute
            path={ROUTES.ACTIVE_ORDERS}
            component={ActiveOrders}
            exact
          />
          <AuthorizedRoute
            path={ROUTES.VENDOR_COMMUNICATION}
            component={VendorCommunication}
            exact
          />
          <AuthorizedRoute path={ROUTES.FIRMS} component={Firm} exact />
          <AuthorizedRoute
            path={ROUTES.ADD_NEW_FIRM}
            component={AddNewFirm}
            exact
          />
          <AuthorizedRoute path={ROUTES.VENDORS} component={Vendor} exact />
          <AuthorizedRoute
            path={ROUTES.ADD_NEW_VENDOR}
            component={AddNewVendor}
            exact
          />
          <AuthorizedRoute
            path={ROUTES.GENERAL_INFORMATION}
            component={GeneralInformation}
            exact
          />
          <AuthorizedRoute
            path={ROUTES.FINANCIAL_INFORMATION}
            component={FinancialInformation}
            exact
          />
          <AuthorizedRoute
            path={ROUTES.WORKING_HOURS}
            component={WorkingHours}
            exact
          />
          <AuthorizedRoute
            path={ROUTES.LIST_CATEGORY}
            component={Category}
            exact
          />
          <AuthorizedRoute
            path={ROUTES.ADD_CATEGORY}
            component={AddCategory}
            exact
          />
          <AuthorizedRoute
            path={ROUTES.EDIT_CATEGORY}
            component={EditCategory}
            exact
          />
          <AuthorizedRoute
            path={ROUTES.LIST_CATALOG}
            component={CatalogProduct}
            exact
          />
          <AuthorizedRoute
            path={ROUTES.ADD_CATALOG}
            component={AddCatalogProduct}
            exact
          />
          <AuthorizedRoute
            path={ROUTES.EDIT_CATALOG}
            component={EditCatalogProduct}
            exact
          />
          <AuthorizedRoute
            path={ROUTES.VENDORPRODUCT}
            component={VendorProduct}
            exact
          />
          <AuthorizedRoute
            path={ROUTES.VENDORPRODUCTEDIT}
            component={VendorProductDetail}
          />
          <AuthorizedRoute
            path={ROUTES.PAYMENT_METHODS}
            component={PaymentMethods}
            exact
          />
          <AuthorizedRoute
            path={ROUTES.BRANDPRODUCT}
            component={BrandProductList}
            exact
          />
          <AuthorizedRoute
            path={ROUTES.PREVIOUS_ORDERS}
            component={PreviousOrders}
            exact
          />
          <AuthorizedRoute
            path={ROUTES.FINANCIAL_TRANSACTIONS}
            component={FinancialTransactions}
            exact
          />
          <AuthorizedRoute
            path={ROUTES.ADD_FINANCIAL_TRANSACTION}
            component={AddFinancialTransaction}
            exact
          />
          <AuthorizedRoute
            path={ROUTES.ORDER_DETAIL}
            component={DetailOrder}
            exact
          />
          <AuthorizedRoute path={ROUTES.REQUESTS} component={Requests} exact />
          <AuthorizedRoute
            path={ROUTES.REQUESTS_WORKING_HOURS}
            component={WorkingHoursRequest}
            exact
          />
          <AuthorizedRoute
            path={ROUTES.REQUESTS_BULK_CHANGE}
            component={BulkChangeRequest}
            exact
          />
          <AuthorizedRoute
            path={ROUTES.KUZEYDEN_REPORT}
            component={KuzeydenReport}
            exact
          />
          <AuthorizedRoute
            path={ROUTES.BULK_CHANGE}
            component={BulkChange}
            exact
          />
          <AuthorizedRoute
            path={ROUTES.AUTH_USERS}
            component={AuthUsers}
            exact
          />
          <AuthorizedRoute
            path={ROUTES.AUTH_PERMS}
            component={AuthPerms}
            exact
          />
          <AuthorizedRoute
            path={ROUTES.AUTH_ROLES}
            component={AuthRoles}
            exact
          />
          <AuthorizedRoute
            path={ROUTES.ADD_NEW_ROLE}
            component={AddNewRole}
            exact
          />
          <AuthorizedRoute path={ROUTES.EDIT_ROLE} component={EditRole} exact />
          <AuthorizedRoute
            path={ROUTES.REDIRECT_ACTIVE_ORDERS} // this route should be above the notfound component. do not change its place
            component={ActiveOrders}
            exact
          />
          <AuthorizedRoute path="/*" component={NotFound} />
        </Switch>
        {!isLoggedIn && !token && <Redirect to={ROUTES.LOGIN} />}
      </Router>
    </Spin>
  );
}
export default App;
