import {
  MAX_PASSWORD_LENGTH,
  MIN_PASSWORD_LENGTH,
  MIN_NEW_PASSWORD_LENGTH,
} from '../containers/Auth/Login/constants';

const checkMinimumPasswordLength = value =>
  value.length < MIN_NEW_PASSWORD_LENGTH;

const checkMaximumPasswordLength = value => value.length > MAX_PASSWORD_LENGTH;

const checkMinimumNumbersAndLetters = value => {
  const numbersCount = value.match(/[0-9]/g)?.length ?? 0;
  const lettersCount = value.match(/[a-zA-Z]/g)?.length ?? 0;

  if (numbersCount < 1 || lettersCount < 1) return false;

  return true;
};

const validateNewPassword = value => {
  if (checkMinimumPasswordLength(value)) {
    const minPasswordLength = MIN_PASSWORD_LENGTH;

    return `Lütfen minimum ${minPasswordLength} karakterli şifre giriniz.`;
  }

  if (!checkMinimumNumbersAndLetters(value)) {
    return 'Yeni Şifre en az 1 harf ve 1 rakam içermelidir.';
  }

  if (checkMaximumPasswordLength(value)) {
    const maxPasswordLength = MAX_PASSWORD_LENGTH;

    return `Lütfen maksimum ${maxPasswordLength} karakterli şifre giriniz.`;
  }

  return true;
};

const validatePhone = value => {
  const patt = new RegExp('^[0-9]{10}$');

  return patt.test(value);
};

const validateIBAN = value => {
  const ibanPattern = /^TR[A-Z0-9]{24}$/;

  return ibanPattern.test(value);
};

const validateMersisNo = value => {
  const mersisNoPatt = new RegExp('^[0-9]{16}$');

  return mersisNoPatt.test(value);
};

const validateKepAddress = value => {
  const kepAddressPatt = new RegExp(
    '^[a-zA-Z0-9._]+@([a-zA-Z0-9.-]+.)?kep.tr$',
  );

  return kepAddressPatt.test(value);
};

export {
  validateKepAddress,
  validateMersisNo,
  validateNewPassword,
  validatePhone,
  validateIBAN,
};
