export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_ERROR = 'CREATE_USER_ERROR';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR';

export const GET_USERS_BY_FIRM_REQUEST = 'GET_USERS_BY_FIRM_REQUEST';
export const GET_USERS_BY_FIRM_SUCCESS = 'GET_USERS_BY_FIRM_SUCCESS';
export const GET_USERS_BY_FIRM_ERROR = 'GET_USERS_BY_FIRM_ERROR';

export const GET_USERS_BY_VENDOR_REQUEST = 'GET_USERS_BY_VENDOR_REQUEST';
export const GET_USERS_BY_VENDOR_SUCCESS = 'GET_USERS_BY_VENDOR_SUCCESS';
export const GET_USERS_BY_VENDOR_ERROR = 'GET_USERS_BY_VENDOR_ERROR';

export const GET_ROLES_BY_ROLE_GROUP_REQUEST =
  'GET_ROLES_BY_ROLE_GROUP_REQUEST';
export const GET_ROLES_BY_ROLE_GROUP_SUCCESS =
  'GET_ROLES_BY_ROLE_GROUP_SUCCESS';
export const GET_ROLES_BY_ROLE_GROUP_ERROR = 'GET_ROLES_BY_ROLE_GROUP_ERROR';

export const SET_USER_LIST = 'SET_USER_LIST';
