import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ConfigProvider } from 'antd';

import menuItems from 'constants/menuItems';
import * as ROUTES from 'constants/routes';
import iconWithImage from 'utils/iconWithImage';
import * as SvgList from 'common/svg/menuicons';
import useUserInfo from 'hooks/useUserInfo';
import { rolesWithName } from 'constants/roles';

import * as S from './style';

const rootSubmenuKeys = [];

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

function compareMenuItems(a, b) {
  if (a.order < b.order) {
    return -1;
  }
  if (a.order > b.order) {
    return 1;
  }

  return 0;
}

const help = {
  id: 26,
  name: 'menu:help',
  icon: iconWithImage(SvgList.supportSvg, 'getirsu', 'getirsu'),
  route: ROUTES.HELP,
  childs: [],
};

const helpNumber = {
  id: 100,
  name: '0850 214 04 54',
  icon: iconWithImage(SvgList.phoneSvg, 'getirsu', 'getirsu'),
};

const Menu = () => {
  const history = useHistory();
  const activeOrdersList = useSelector(state => state.activeOrders.list);
  const { data: permList } = useSelector(state => state.login.userPerms);
  const { userInfo } = useUserInfo();

  const { t } = useTranslation(['common', 'catalogs', 'menu']);
  const [openKeys, setOpenKeys] = useState(['']);
  const [sortedPermList, setSortedPermList] = useState([]);

  useEffect(() => {
    if (permList) {
      for (let i = 0; i < menuItems.length; i += 1) {
        for (let j = 0; j < permList.length; j += 1) {
          if (menuItems[i].componentId === permList[j].componentId) {
            permList[j].order = menuItems[i].order;
          }
          setSortedPermList(permList);
        }
      }
    }
  }, [permList]);

  const onOpenChange = keys => {
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const isAuthorizedForAnyChild = useCallback(
    (parentChilds, childs) =>
      parentChilds.filter(parentChild =>
        childs?.some(child => child.componentId === parentChild.componentId),
      ),
    [],
  );

  const routeMenuItem = useCallback(
    route => {
      history.push(route);
    },
    [history],
  );

  // const isSelectedMenuItem = useCallback(
  //   childs => childs.some(child => child?.route === history.location.pathname),
  //   [history.location.pathname],
  // );

  const selectedMenuItem = useMemo(() => {
    const path = history.location.pathname;

    return menuItems.flatMap(item => {
      const isParent = item.route === path;

      if (isParent) {
        return [`${item.id}`];
      }

      const subItem = item.childs.find(child => child.route === path);

      if (subItem) {
        return [`${subItem.id}`];
      }

      return [];
    });
  }, [history.location.pathname]);

  const orderListCount = useMemo(() => {
    if (userInfo && userInfo.role && userInfo.role === rolesWithName.CARRIER) {
      const { userId } = userInfo;

      const filteredOrderList = activeOrdersList.filter(
        order => order.courierId && order.courierId === userId,
      );

      return filteredOrderList.length;
    }

    return activeOrdersList.length;
  }, [activeOrdersList, userInfo]);

  const createMenuItem = useCallback(
    menuItem => {
      const { route, name } = menuItem;

      const getItemComp = getItem(
        <S.MenuItemWrapper>
          {t(name)}
          {route === ROUTES.ACTIVE_ORDERS ? (
            <S.ActiveOrderCount>
              <span>{activeOrdersList && orderListCount}</span>
            </S.ActiveOrderCount>
          ) : null}
        </S.MenuItemWrapper>,
        menuItem.id,
        menuItem.icon,
      );

      return getItemComp;
    },
    [activeOrdersList, orderListCount, t],
  );

  const createSubMenuItem = useCallback(
    (menuItem, perm = undefined) => {
      const childs = isAuthorizedForAnyChild(
        menuItem.childs,
        perm?.subComponents,
      ).map(child => getItem(t(child.name), child.id));

      return getItem(t(menuItem.name), menuItem.id, menuItem.icon, childs);
    },
    [isAuthorizedForAnyChild, t],
  );

  const menuItemsList = useMemo(() => {
    const menuItemsFromList = sortedPermList
      ?.sort(compareMenuItems)
      ?.map(perm =>
        menuItems
          .map(
            menuItem =>
              menuItem.componentId === perm.componentId &&
              (perm.subComponents.length === 0
                ? createMenuItem(menuItem)
                : createSubMenuItem(menuItem, perm)),
          )
          .filter(Boolean),
      )
      .filter(item => item.length > 0)
      .map(item => item[0]);

    menuItemsFromList.push(createMenuItem(help));
    menuItemsFromList.push(createMenuItem(helpNumber));

    return menuItemsFromList;
  }, [createMenuItem, createSubMenuItem, sortedPermList]);

  return (
    <S.MenuWrapper>
      <ConfigProvider
        theme={{
          token: {
            motion: false,
          },
        }}
      >
        <S.MenuAntD
          mode="inline"
          openKeys={openKeys}
          selectedKeys={selectedMenuItem}
          onOpenChange={onOpenChange}
          onSelect={e => {
            const routeId = +e.key;
            let isSubMenuItem;
            const menuItem = menuItems.find(item => {
              const isParent = item.id === routeId;

              if (isParent) {
                return true;
              }

              isSubMenuItem = item.childs.find(child => child.id === routeId);

              if (isSubMenuItem) {
                return true;
              }

              return false;
            });

            if (isSubMenuItem) {
              routeMenuItem(`${isSubMenuItem.route}${history.location.search}`);

              return;
            }
            routeMenuItem(`${menuItem.route}${history.location.search}`);
          }}
          items={menuItemsList}
        />
      </ConfigProvider>
    </S.MenuWrapper>
  );
};

export default Menu;
