export const TableMode = {
  EDIT: 'edit',
  VIEW: 'view',
};

export const defaultTimeFormat = 'HH:mm';
export const fullTimeFormat = 'HH:mm:ss';

export const NumberWord = {
  1: 'One',
  2: 'Two',
  3: 'Three',
  4: 'Four',
  5: 'Five',
};

export const Day = {
  1: 'monday',
  2: 'tuesday',
  3: 'wednesday',
  4: 'thursday',
  5: 'friday',
  6: 'saturday',
  7: 'sunday',
};

export const TimeType = {
  0: 'startTime',
  1: 'endTime',
};

export const defaultNumberOfIntervals = 2;

export const defaultIntervalNames = (() =>
  [...Array(defaultNumberOfIntervals).keys()].map(
    intervalValue => `interval${NumberWord[intervalValue + 1]}`,
  ))();

export const defaultMapIntervalsComparator = (mapIntervals1, mapIntervals2) => {
  if (mapIntervals1?.length !== mapIntervals2?.length) return false;

  for (let i = 0; i < mapIntervals1.length; i += 1) {
    const interval1 = mapIntervals1[i];
    const interval2 = mapIntervals2[i];

    if (
      interval1.startTime !== interval2.startTime ||
      interval1.endTime !== interval2.endTime
    ) {
      return false;
    }
  }

  return true;
};
