import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DEPOSIT_OPTIONS, DEPOSIT_TYPE } from '../constants';

const useDepositStatus = isKuzeyden => {
  const { t } = useTranslation();
  const depositStatus = useMemo(
    () =>
      DEPOSIT_OPTIONS.flatMap(item => {
        if (!isKuzeyden && item.value === DEPOSIT_TYPE.WITH_BOTTLE_RETURN) {
          return [];
        }

        return [
          {
            label: t(item.label),
            value: item.value,
          },
        ];
      }),

    [isKuzeyden, t],
  );

  return { depositStatus };
};

export { useDepositStatus };
