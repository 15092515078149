import { createAction } from 'utils/createAction';

import * as Types from './types';

// get transactions
export const getTransactionsRequest = createAction(
  Types.GET_TRANSACTIONS_REQUEST,
);
export const getTransactionsSuccess = createAction(
  Types.GET_TRANSACTIONS_SUCCESS,
);
export const getTransactionsError = createAction(Types.GET_TRANSACTIONS_ERROR);

// get detailed transactions
export const getDetailedTransactionsRequest = createAction(
  Types.GET_DETAILED_TRANSACTIONS_REQUEST,
);
export const getDetailedTransactionsSuccess = createAction(
  Types.GET_DETAILED_TRANSACTIONS_SUCCESS,
);
export const getDetailedTransactionsError = createAction(
  Types.GET_DETAILED_TRANSACTIONS_ERROR,
);

// get transactions Detail
export const getTransactionDetailRequest = createAction(
  Types.GET_TRANSACTION_DETAIL_REQUEST,
);
export const getTransactionDetailSuccess = createAction(
  Types.GET_TRANSACTION_DETAIL_SUCCESS,
);
export const getTransactionDetailError = createAction(
  Types.GET_TRANSACTION_DETAIL_ERROR,
);

export const resetTransactionList = createAction(Types.RESET_TRANSACTIONS_LIST);
