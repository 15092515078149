import styled from 'styled-components';
import { Table } from 'antd';
import { Input } from '@getir/web-components';

const StyledTable = styled(Table)`
  width: 100%;
  tr {
    width: 100%;
  }
  .ant-table-cell {
    background: none;

    &::before {
      display: none;
    }
  }

  ${Input.S.Input} {
    border: none;
  }

  .ant-space,
  .ant-space-item {
    width: 100%;
  }
`;

export { StyledTable };
