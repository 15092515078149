import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Divider, utilities } from '@getir/web-components';
import { useDispatch, useSelector } from 'react-redux';

import InputWrapper from 'components/InputWrapper';
import SelectBoxAntd from 'components/Antd/FormElements/SelectBox';
import { AUTH_COMPONENT_TYPES } from 'constants/authComponentTypes';

import * as PermActions from '../../actions';

import { validations } from './validations';
import * as S from './style';

const { noop } = utilities;

const EditPermModal = ({ onClose, perm, ...props }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const validationRules = validations(t);

  const { data: roleList } = useSelector(state => state.authPerms.roleList);

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
  } = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    dispatch(PermActions.getRoleListRequest());
  }, []); // eslint-disable-line

  useEffect(() => {
    if (perm) {
      const { title, componentDesc, roles } = perm;
      setValue('name', title, { shouldValidate: true });
      setValue('description', componentDesc, { shouldValidate: true });
      setValue(
        'roleIds',
        roles?.map(role => role.roleId),
        { shouldValidate: true },
      );
    }
  }, [perm, setValue]);

  const onSubmit = formData => {
    const { name, description, roleIds } = formData;
    const { componentId } = perm;
    const data = {
      componentId,
      componentTitle: name,
      componentDesc: description,
      componentType: AUTH_COMPONENT_TYPES.PAGE,
      isVisible: true,
      isEnable: true,
      canEdit: true,
      hasAllPermission: 'true',
      roleIds,
    };

    dispatch(PermActions.updatePermRequest(data));
    handleOnClose();
  };

  const handleOnClose = () => {
    onClose();
    reset();
  };

  return (
    <S.StyledModal name="EDIT_PERM" onCancel={handleOnClose} {...props}>
      <Modal.Header>{t('authPermsPage:editPermModal.title')}</Modal.Header>
      <Modal.Body>
        <S.FormBody>
          <S.EditPermModalForm onSubmit={handleSubmit(onSubmit)}>
            <InputWrapper
              fieldName="name"
              control={control}
              rules={validationRules.fullName}
              placeholder={t(`authPermsPage:editPermModal.name`)}
              header={t(`authPermsPage:editPermModal.name`)}
              errors={errors}
              isDisabled
            />
            <InputWrapper
              fieldName="description"
              control={control}
              rules={validationRules.email}
              placeholder={t(`authPermsPage:editPermModal.desc`)}
              header={t(`authPermsPage:editPermModal.desc`)}
              errors={errors}
            />
            <SelectBoxAntd
              fieldName="roleIds"
              control={control}
              rules={validationRules.roleIds}
              placeholder={t(`authPermsPage:editPermModal.role`)}
              header={t(`authPermsPage:editPermModal.role`)}
              errors={errors}
              options={
                roleList &&
                roleList.map(role => ({
                  value: role.roleId,
                  label: role.roleName,
                }))
              }
              mode="multiple"
            />
            <Divider />
            <S.FormFooter>
              <Button type="submit">{t('save')}</Button>
            </S.FormFooter>
          </S.EditPermModalForm>
        </S.FormBody>
      </Modal.Body>
    </S.StyledModal>
  );
};

EditPermModal.propTypes = {
  onClose: PropTypes.func,
  perm: PropTypes.object,
};

EditPermModal.defaultProps = {
  onClose: noop,
  perm: undefined,
};

export default EditPermModal;
