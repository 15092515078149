import {
  BOTTLE_RETURN_WITH_DIFFERENT_BRAND,
  BOTTLE_RETURN_WITH_SAME_BRAND,
  DEPOSIT_COLOR,
  WITHOUT_BOTTLE_RETURN,
  WITH_BOTTLE_RETURN,
} from '../constants';

export const extractDepositOptionsFrom = bulk => {
  if (bulk) {
    return bulk.map(item => ({
      label: item.label,
      value: item.value,
    }));
  }

  return [];
};

export const extractDepositOptionsTo = bulk => {
  switch (bulk) {
    case BOTTLE_RETURN_WITH_SAME_BRAND:
      return 1;
    case BOTTLE_RETURN_WITH_DIFFERENT_BRAND:
      return 2;
    case WITHOUT_BOTTLE_RETURN:
      return 3;
    case WITH_BOTTLE_RETURN:
      return 5;
    default:
      return null;
  }
};

export const extractDepositTypeFromValue = item => {
  switch (item) {
    case 1:
      return DEPOSIT_COLOR.GREEEN;
    case 2:
      return DEPOSIT_COLOR.BLUE;
    case 3:
      return DEPOSIT_COLOR.GRAY;
    case 5:
      return DEPOSIT_COLOR.GREEEN;
    default:
      return null;
  }
};
