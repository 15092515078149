const rolesWithId = {
  ADMIN: 0,
  ADMIN_KEY_ACCOUNT_MANAGER: 10,
  ADMIN_REGIONAL_MANAGER: 11,
  ADMIN_OPERATIONAL_MANAGER: 12,
  FIRM_MANAGER: 20,
  VENDOR_MANAGER: 21,
  VENDOR_EMPLOYEE: 22,
  CARRIER: 23,
  GIS_EXPERT: 26,
};

const rolesWithName = {
  ADMIN: 'ADMIN',
  ADMIN_KEY_ACCOUNT_MANAGER: 'ADMIN_KEY_ACCOUNT_MANAGER',
  ADMIN_REGIONAL_MANAGER: 'ADMIN_REGIONAL_MANAGER',
  ADMIN_OPERATIONAL_MANAGER: 'ADMIN_OPERATIONAL_MANAGER',
  FIRM_MANAGER: 'FIRM_MANAGER',
  VENDOR_MANAGER: 'VENDOR_MANAGER',
  VENDOR_EMPLOYEE: 'VENDOR_EMPLOYEE',
  CARRIER: 'CARRIER',
  GIS_EXPERT: 'GIS_EXPERT',
};

const rolesWithTranslate = {
  ADMIN: {
    tr: 'Yönetici',
    en: 'Admin',
  },
  ADMIN_KEY_ACCOUNT_MANAGER: {
    tr: 'Key Account Manager',
    en: 'Key Account Manager',
  },
  ADMIN_REGIONAL_MANAGER: {
    tr: 'Bölge Yöneticisi',
    en: 'Regional Manager',
  },
  ADMIN_OPERATIONAL_MANAGER: {
    tr: 'Operasyon Yöneticisi',
    en: 'Operational Manager',
  },
  FIRM_MANAGER: {
    tr: 'Firma Yöneticisi',
    en: 'Firm Manager',
  },
  VENDOR_MANAGER: {
    tr: 'Bayi Yöneticisi',
    en: 'Vendor Manager',
  },
  VENDOR_EMPLOYEE: {
    tr: 'Bayi Çalışanı',
    en: 'Vendor Employee',
  },
  CARRIER: {
    tr: 'Kurye',
    en: 'Carrier',
  },
  GIS_EXPERT: {
    tr: 'GIS Uzmanı',
    en: 'GIS Expert',
  },
  EXTERNAL_API: {
    tr: 'External API',
    en: 'Esternal API',
  },
};

export { rolesWithId, rolesWithName, rolesWithTranslate };
