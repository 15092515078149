import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import useUserInfo from 'hooks/useUserInfo';
import { ORDER_STATUS } from 'constants/orderStatus';
import { PAYMENT_TYPE } from 'constants/paymentType';

import { getOrderType } from '../utils/getOrderType';
import * as ActiveOrdersActions from '../actions';

const useGetCompletedOrderList = () => {
  const dispatch = useDispatch();
  const vendorInfo = useSelector(state => state.mainHeader.vendorInfo);
  const selectedBusiness = useSelector(state => state.mainHeader.vendor);
  const { userInfo } = useUserInfo();

  const getCompletedOrderList = () => {
    if (!selectedBusiness || !selectedBusiness.value) return;

    if (
      vendorInfo?.letterOfUndertaking?.isApprovedLU ||
      (!vendorInfo?.letterOfUndertaking && Object.keys(userInfo).length > 0)
    ) {
      const data = {
        statusList: [ORDER_STATUS.DELIVERED, ORDER_STATUS.RATED],
        paymentType: [
          PAYMENT_TYPE.ONLINE_CREDIT_CARD,
          PAYMENT_TYPE.ONLINE_BKM,
          PAYMENT_TYPE.CREDIT_OR_DEBIT_CARD_AT_DELIVERY,
          PAYMENT_TYPE.CASH_AT_DELIVERY,
        ],
        id: selectedBusiness && selectedBusiness.value,
        startDate: dayjs().startOf('day').toISOString(),
        endDate: dayjs().toISOString(),
        type: getOrderType(selectedBusiness),
      };
      dispatch(ActiveOrdersActions.getCompletedOrdersRequest(data));
    }
  };

  const getCompletedOrderListCallback = useCallback(() => {
    getCompletedOrderList();
    // eslint-disable-next-line
  }, [vendorInfo, dispatch,userInfo]);

  return { getCompletedOrderListCallback, getCompletedOrderList };
};

export { useGetCompletedOrderList };
