import React from 'react';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import useResponsive from 'hooks/useResponsive';
import MobileOrdersPage from 'components/v1/MobileOrders';
import VendorStatus from 'components/v1/VendorStatus';
import Info from 'components/v1/Info';

import * as S from '../style';

import { useScheduledOrdersColumns } from './useScheduledOrdersColumns';

const ScheduledOrders = () => {
  const { t } = useTranslation();

  const { isMobileView, isMiniTabletView, isTabletView } = useResponsive();
  const isUnderTabletView = isMobileView || isMiniTabletView || isTabletView;
  const scheduledOrdersList = useSelector(
    state => state.activeOrders.scheduledOrderList,
  );

  const { columns } = useScheduledOrdersColumns();

  return isMobileView ? (
    <>
      <MobileOrdersPage
        isScheduledOrderPage
        isActive={false}
        orders={scheduledOrdersList}
      />
    </>
  ) : (
    <S.Container>
      {isUnderTabletView && <VendorStatus />}
      <Info kind="warning">
        <S.InfoBox>
          <p>{t('activeOrders:scheduledInfoFirst')}</p>
          <p>{t('activeOrders:scheduledInfoSecond')}</p>
        </S.InfoBox>
      </Info>
      <S.StyledTable
        columns={columns}
        dataSource={scheduledOrdersList}
        locale={{ emptyText: <Empty description={t('table.noData')} /> }}
        bordered
        pagination={{
          size: 'small',
          position: ['bottomCenter'],
          defaultPageSize: 20,
          showSizeChanger: true,
        }}
        rowKey="id"
        scroll={{ x: 800 }}
      />
    </S.Container>
  );
};

export default ScheduledOrders;
