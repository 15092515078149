import { createAction } from 'utils/createAction';

import * as Types from './types';

// list categorys
export const categoryListRequest = createAction(Types.CATEGORY_LIST_REQUEST);
export const categoryListSuccess = createAction(Types.CATEGORY_LIST_SUCCESS);
export const categoryListError = createAction(Types.CATEGORY_LIST_SUCCESS);

// add new category
export const addCategoryRequest = createAction(Types.ADD_CATEGORY_REQUEST);
export const addCategorySuccess = createAction(Types.ADD_CATEGORY_SUCCESS);
export const addCategoryError = createAction(Types.ADD_CATEGORY_ERROR);

// update category
export const updateCategoryRequest = createAction(Types.EDIT_CATEGORY_REQUEST);
export const updateCategorySuccess = createAction(Types.EDIT_CATEGORY_SUCCESS);
export const updateCategoryError = createAction(Types.EDIT_CATEGORY_SUCCESS);

// delete category
export const deleteCategoryRequest = createAction(
  Types.DELETE_CATEGORY_REQUEST,
);
export const deleteCategorySuccess = createAction(
  Types.DELETE_CATEGORY_SUCCESS,
);
export const deleteCategoryError = createAction(Types.DELETE_CATEGORY_ERROR);

// get category
export const getCategoryRequest = createAction(Types.GET_CATEGORY_REQUEST);
export const getCategorySuccess = createAction(Types.GET_CATEGORY_SUCCESS);
export const getCategoryError = createAction(Types.GET_CATEGORY_SUCCESS);
