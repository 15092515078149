import { useEffect, useState } from 'react';

const useSetRemoveImage = (setValue, key, defaultImageUrl) => {
  const [imageUrl, setImageUrl] = useState(defaultImageUrl);

  useEffect(() => {
    if (imageUrl !== defaultImageUrl) setImageUrl(defaultImageUrl);
    // eslint-disable-next-line
  }, [defaultImageUrl]);

  const setImage = url => {
    setImageUrl(url);
    setValue(key, url, {
      shouldValidate: true,
    });
  };

  const removeImage = () => {
    setImageUrl(undefined);
    setValue(key, undefined, {
      shouldValidate: true,
    });
  };

  return { setImage, removeImage, imageUrl };
};

export { useSetRemoveImage };
