import React, { useState, useCallback, useEffect } from 'react';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { utilities, Spin, Icon } from '@getir/web-components';
import { Map, Placemark, YMaps, ZoomControl } from 'react-yandex-maps';
import { useTranslation } from 'react-i18next';

import markerIcon from 'public/images/icons/marker.png';
import * as CS from 'common/style';

import * as S from './style';

const { noop } = utilities;

const YandexMap = ({ field, setFieldValue, errors }) => {
  const { t } = useTranslation();
  const [center, setCenter] = useState([41.077382, 29.031074]);
  const [isLoading, setIsLoading] = useState(false);
  const [locations, setLocations] = useState([]);

  const onDragEnd = ({
    originalEvent: {
      target: {
        geometry: { _coordinates },
      },
    },
  }) => {
    setCenter([_coordinates?.[0], _coordinates?.[1]]);
    setFieldValue({
      cordinates: [_coordinates?.[0], _coordinates?.[1]],
    });
  };

  const onMapClick = e => {
    const [lat, lon] = e.get('coords');

    setCenter([lat, lon]);
    setFieldValue({ cordinates: [lat, lon] });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getPlacesByDebounce = useCallback(
    debounce(val => getPlaces(val), 500),
    [],
  );

  const setSearchLocations = selectedOption => {
    const [result] = locations.filter(data =>
      [
        data?.properties?.GeocoderMetaData?.text,
        data?.properties?.CompanyMetaData?.address,
      ].includes(selectedOption),
    );

    setCenter(result?.geometry.coordinates.reverse());
    setFieldValue({
      cordinates: result?.geometry.coordinates,
      name:
        result?.properties?.GeocoderMetaData?.text ??
        result?.properties?.CompanyMetaData?.address,
    });
  };

  useEffect(() => {
    if (field.value) {
      setCenter([field.value.lat, field.value.long]);
    }
  }, [field.value]);

  // prettier-ignore
  const getPlaces = async value => {
    setIsLoading(true);
    setLocations(null);

    const searchArea =
      'bbox=26.0433512713,35.8215347357~44.7939896991,42.1414848903';

    if (value !== '') {
      fetch(
        `https://search-maps.yandex.ru/v1/?apikey=${
          process.env.REACT_APP_YANDEX_PLACES_KEY
        }&text=${value}&lang=${'tr_TR'}&${searchArea}&rspn=1`,
      )
        .then(response => response.json())
        .then(response => {
          const { features } = response;
          setLocations(
            features.map((e, i) => ({
              ...e,
              key: i,
              label: `${
                e.properties?.GeocoderMetaData?.text ??
                e.properties?.CompanyMetaData.address
              }`,
              value: `${
                e.properties?.GeocoderMetaData?.text ??
                e.properties?.CompanyMetaData.address
              }`,
            })),
          );
        })
        .then(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);

      setLocations([]);
    }
  };

  return (
    <S.YandexMapWrapper>
      <S.SearchFieldWrapper haserror={errors[field.name]}>
        <Select
          defaultValue={field?.value?.name}
          filterOption={false}
          notFoundContent={isLoading ? <Spin size="small" /> : t(`noRecord`)}
          onChange={setSearchLocations}
          onSearch={getPlacesByDebounce}
          options={locations}
          placeholder={
            field.value ? field?.value?.name : t('mapSearchAddressPlaceholder')
          }
          value={
            field.value ? field?.value?.name : t('mapSearchAddressPlaceholder')
          }
          showSearch
          suffixIcon={<Icon name="chevron-down" size={12} />}
        />
      </S.SearchFieldWrapper>
      <S.ErrorWrapper>
        {errors[field.name] && (
          <CS.ErrorMessage>{errors[field.name].message}</CS.ErrorMessage>
        )}
      </S.ErrorWrapper>
      <YMaps
        query={{
          lang: 'tr_TR',
          apikey: process.env.REACT_APP_YANDEX_MAP_KEY,
        }}
        version="2.1"
      >
        <Map
          height="300px"
          onClick={onMapClick}
          state={{
            center: [
              field?.value?.cordinates?.[0] ?? field?.value?.lat ?? center[0],
              field?.value?.cordinates?.[1] ?? field?.value?.lon ?? center[1],
            ],
            zoom: 13,
          }}
          width="100%"
        >
          <ZoomControl
            options={{
              size: 'small',
              zoomDuration: 500,
            }}
          />
          <Placemark
            geometry={[
              field?.value?.cordinates?.[0] ?? field?.value?.lat ?? center[0],
              field?.value?.cordinates?.[1] ?? field?.value?.lon ?? center[1],
            ]}
            onDragend={onDragEnd}
            options={{
              iconLayout: 'default#image',
              iconImageHref: `${markerIcon}`,
              iconImageSize: [71, 74],
              iconImageOffset: [-29, -58],
              draggable: true,
            }}
          />
        </Map>
      </YMaps>
    </S.YandexMapWrapper>
  );
};

YandexMap.propTypes = {
  field: PropTypes.object,
  setFieldValue: PropTypes.func,
  errors: PropTypes.object,
};

YandexMap.defaultProps = {
  field: {},
  setFieldValue: noop,
  errors: {},
};

export default YandexMap;
