import { createAction } from 'utils/createAction';

import * as Types from './types';

export function loginRequest(data) {
  return {
    type: Types.LOGIN_REQUEST,
    payload: data,
  };
}

export function loginSuccess(data) {
  return {
    type: Types.LOGIN_SUCCESS,
    payload: data,
  };
}

export function loginError(err) {
  return {
    type: Types.LOGIN_ERROR,
    payload: err,
  };
}

export function forgotPasswordRequest(data) {
  return {
    type: Types.FORGOT_PASSWORD_REQUEST,
    payload: data,
  };
}

export function forgotPasswordSuccess(data) {
  return {
    type: Types.FORGOT_PASSWORD_SUCCESS,
    payload: data,
  };
}

export function forgotPasswordError(err) {
  return {
    type: Types.FORGOT_PASSWORD_ERROR,
    payload: err,
  };
}

export function newPasswordRequest(data) {
  return {
    type: Types.NEW_PASSWORD_REQUEST,
    payload: data,
  };
}

export function newPasswordSuccess(data) {
  return {
    type: Types.NEW_PASSWORD_SUCCESS,
    payload: data,
  };
}

export function newPasswordError(err) {
  return {
    type: Types.NEW_PASSWORD_ERROR,
    payload: err,
  };
}

export function updateStep(stepCount) {
  return {
    type: Types.UPDATE_STEP,
    payload: stepCount,
  };
}

export const googleLoginRequest = createAction(Types.GOOGLE_LOGIN_REQUEST);

// get user perms
export const getUserPermsRequest = createAction(Types.GET_USER_PERMS_REQUEST);
export const getUserPermsSuccess = createAction(Types.GET_USER_PERMS_SUCCESS);
export const getUserPermsError = createAction(Types.GET_USER_PERMS_ERROR);

// get all perms
export const getPermListRequest = createAction(Types.GET_PERM_LIST_REQUEST);
export const getPermListSuccess = createAction(Types.GET_PERM_LIST_SUCCESS);
export const getPermListError = createAction(Types.GET_PERM_LIST_ERROR);
