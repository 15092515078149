import styled from 'styled-components';
import { Button as StyledButton } from '@getir/web-components';

const PageHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0;

  ${StyledButton.S.Wrapper} {
    border-radius: 0;
  }
`;

const PageTitle = styled.p`
  margin-bottom: 0;
  font-size: 24px;
`;

export { PageHeader, PageTitle };
