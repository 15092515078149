import { NumberWord } from '../constants';

import { findIfIntervalIsEmpty } from './common';

const getHourMinuteTime = time => `${time.split(':')[0]}:${time.split(':')[1]}`;

export default function convertAlternativeMapIntervalsToDataSource(
  alternativeMapIntervals,
) {
  const dataSource = [];
  alternativeMapIntervals.forEach(mapInterval => {
    const { dayOfWeek, intervals } = mapInterval;
    let intervalObject = {};
    intervals.forEach((interval, index) => {
      const intervalKey = `interval${NumberWord[index + 1]}`;
      const { startTime, endTime } = interval;
      intervalObject = {
        ...intervalObject,
        [intervalKey]: !findIfIntervalIsEmpty({ startTime, endTime })
          ? `${getHourMinuteTime(startTime)}-${getHourMinuteTime(endTime)}`
          : '',
      };
    });
    dataSource.push({
      dayOfWeek,
      ...intervalObject,
    });
  });

  return dataSource;
}
