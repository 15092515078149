import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DatePicker, Empty } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import Layout from 'components/Layout';
import Table from 'components/v1/Table';
import * as CommonStyle from 'common/style';
import SelectBox from 'components/v1/SelectBox';
import Button from 'components/v1/Button';
import useUserInfo from 'hooks/useUserInfo';

import * as S from './styles';
import useTableColumns from './useTableColumns';
import CreateReportModal from './components/CreateReportModal';
import * as KuzeydenReportsActions from './actions';
import { OWNER_TYPE, REPORT_TYPES_ARRAY } from './constants';
const { RangePicker } = DatePicker;

const DEFAULT_SELECTED_TYPES = REPORT_TYPES_ARRAY.map(({ value }) => value);

const KuzeydenReports = () => {
  const { t } = useTranslation('reports');
  const { columns } = useTableColumns();
  const {
    selectedDateRange,
    selectedReportType,
    paginationOptions,
  } = useSelector(state => state.kuzeydenReports.filters);
  const [isCreateReportModalOpen, setIsCreateReportModalOpen] = useState(false);
  const dispatch = useDispatch();
  const handleOpenCreateReportModal = () => setIsCreateReportModalOpen(true);
  const { userInfo } = useUserInfo();
  const filtersRef = useRef({
    selectedReportType: null,
    selectedDateRange: [null, null],
  });

  const kuzeydenReportList = useSelector(
    state => state.kuzeydenReports.reportList,
  );
  const reportsLoading = useSelector(
    state => state.kuzeydenReports.reportsLoading,
  );
  const handleCloseCreateReportModal = () => setIsCreateReportModalOpen(false);

  useEffect(() => {
    dispatch(
      KuzeydenReportsActions.getReportsRequest({
        query: {
          limit: paginationOptions.pageSize,
          offset: paginationOptions.pageSize * (paginationOptions.current - 1),
        },
        body: {
          report: DEFAULT_SELECTED_TYPES,
          ownerType: OWNER_TYPE,
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleFilterReport = () => {
    if (!userInfo) {
      return;
    }

    dispatch(
      KuzeydenReportsActions.changeFilters({
        paginationOptions: {
          ...paginationOptions,
          current: 1,
        },
        selectedDateRange: filtersRef.current.selectedDateRange,
        selectedReportType: filtersRef.current.selectedReportType,
      }),
    );

    dispatch(
      KuzeydenReportsActions.getReportsRequest({
        query: {
          limit: 10,
          offset: 0,
        },
        body: {
          report: filtersRef.current.selectedReportType
            ? [filtersRef.current.selectedReportType]
            : DEFAULT_SELECTED_TYPES,
          ownerType: OWNER_TYPE,
          startDate: filtersRef.current.selectedDateRange[0] ?? undefined,
          endDate: filtersRef.current.selectedDateRange[1] ?? undefined,
          ownerUserId: userInfo?.userId,
        },
      }),
    );
  };

  const dateOnChange = date => {
    if (!date) {
      filtersRef.current.selectedDateRange = [null, null];

      return;
    }

    filtersRef.current.selectedDateRange = date;
  };

  const handleReportTypeChange = value => {
    filtersRef.current.selectedReportType = value;
  };
  const handlePaginationChange = page => {
    dispatch(
      KuzeydenReportsActions.getReportsRequest({
        query: {
          limit: page.pageSize,
          offset: page.pageSize * (page.current - 1),
        },
        body: {
          report: selectedReportType
            ? [selectedReportType]
            : DEFAULT_SELECTED_TYPES,
          ownerType: OWNER_TYPE,
          startDate: selectedDateRange[0] ?? undefined,
          endDate: selectedDateRange[1] ?? undefined,
          ownerUserId: userInfo?.userId,
        },
      }),
    );

    dispatch(
      KuzeydenReportsActions.changeFilters({
        paginationOptions: {
          ...paginationOptions,
          current: page.current,
          pageSize: page.pageSize,
        },
      }),
    );
  };

  return (
    <Layout>
      <CommonStyle.PageHeader>
        <CommonStyle.PageTitle>{t('title')}</CommonStyle.PageTitle>
        <Button
          onClick={handleOpenCreateReportModal}
          kind="primary"
          size="small"
          data-testid="createReportButton"
        >
          {t('createReport')}
        </Button>
      </CommonStyle.PageHeader>
      <CommonStyle.PageBody>
        <S.SettingsContainer>
          <S.FilterLabel>{t('filterReport')}</S.FilterLabel>
          <S.FilterContainer>
            <RangePicker onChange={dateOnChange} />
            <SelectBox
              options={REPORT_TYPES_ARRAY}
              size="small"
              placeholder={t('reportType')}
              onChange={handleReportTypeChange}
              allowClear
            />
            <Button kind="primary" size="small" onClick={handleFilterReport}>
              {t('filterReport')}
            </Button>
          </S.FilterContainer>
        </S.SettingsContainer>
        <Table
          columns={columns}
          scroll={{ x: 800 }}
          bordered
          locale={{ emptyText: <Empty description={t('noData')} /> }}
          data={kuzeydenReportList}
          pagination={paginationOptions}
          rowKey="_id"
          loading={reportsLoading}
          handlePaginationChange={handlePaginationChange}
          showSizeChanger
        />
        {isCreateReportModalOpen && (
          <CreateReportModal
            isOpen={isCreateReportModalOpen}
            onClose={handleCloseCreateReportModal}
          />
        )}
      </CommonStyle.PageBody>
    </Layout>
  );
};

export default KuzeydenReports;
