import React from 'react';

import * as S from './style';

const { useTranslation } = require('react-i18next');

const useOrderDetailColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('orderdetailmodal:columns.product'),
      dataIndex: 'name',
      width: '50%',
      render: (_, record) => {
        const { name, productImageUrl } = record;

        return (
          <S.ProductNameContainer>
            <S.ProductImage>
              <img
                width={64}
                height={64}
                src={productImageUrl}
                alt={name}
                title={name}
              />
            </S.ProductImage>

            <S.ProductNameLabel>{name}</S.ProductNameLabel>
          </S.ProductNameContainer>
        );
      },
    },
    {
      title: t('orderdetailmodal:columns.price'),
      dataIndex: 'priceText',
      align: 'right',
      render: text => (text ? <S.ProductCol>{text}</S.ProductCol> : ''),
    },
    {
      title: t('orderdetailmodal:columns.count'),
      dataIndex: 'count',
      align: 'right',
      render: text => (text ? <S.ProductCol>{text}</S.ProductCol> : ''),
    },
    {
      title: t('orderdetailmodal:columns.totalRow'),
      dataIndex: 'totalPriceOfAllItems',
      align: 'right',
      render: text =>
        text ? <S.ProductCol>₺{text.toFixed(2)}</S.ProductCol> : '',
    },
  ];
};

export { useOrderDetailColumns };
