import styled from 'styled-components';
import { Pagination } from 'antd';

export const PaginationContainer = styled(Pagination)`
  .ant-pagination-disabled {
  }
  display: flex;
  li {
    height: 36px !important;
  }
  color: #5d3ebc !important ;
  svg {
    color: #5d3ebc !important ;
    fill: #5d3ebc !important ;
  }
  .ant-pagination-options {
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      color: #5d3ebc;
      font-size: 14px;
      font-weight: 600;
    }
  }
  .ant-pagination-item-active {
    a {
      color: #5d3ebc;
    }
    color: #5d3ebc;
    background-color: #f3f0fe;
  }

  .ant-select {
    display: flex;
    height: 36px;
    padding: 0px 12px;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #f3f0fe;
    background: #f3f0fe;
    .ant-select-selector {
      border: none !important;
      background: transparent !important;
    }
    .ant-select-focused {
      border: none !important;
      outline: none !important;
      box-shadow: none;
    }
  }

  .ant-pagination-item {
    border-radius: 8px;
    display: flex;
    width: 36px;
    height: 36px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border: 0px;
    &:hover {
      a {
        color: #5d3ebc;
      }
    }
  }

  .ant-pagination-prev {
  }
`;
