import React from 'react';
import i18next from 'i18next';
import { Space } from 'antd';
import { Input, SelectBox } from '@getir/web-components';

import Button from 'components/v1/Button';

import { requestStatuses } from '../constants/requestStatuses';

import { selectboxOptions } from './selectBoxOptions';

const days = {
  1: 'monday',
  2: 'tuesday',
  3: 'wednesday',
  4: 'thursday',
  5: 'friday',
  6: 'saturday',
  7: 'sunday',
};

const getColumns = (
  handleCancelButtonClick,
  handleApproveButtonClick,
  setDayData,
  currentRequestStatus,
) => [
  {
    title: i18next.t('requests:workingHours.days'),
    width: 100,
    dataIndex: 'dayOfWeek',
    key: 'dayOfWeek',
    render: text => `${text ? i18next.t(days[text]) : ''}`,
  },
  {
    title: i18next.t('requests:workingHours.oldStartTime'),
    width: 200,
    dataIndex: 'oldStartDate',
    key: 'oldStartDate',
    render: (text, _, index) => {
      if (index < 7) {
        return <Input disabled value={text} />;
      }

      return <div></div>;
    },
  },
  {
    title: i18next.t('requests:workingHours.oldEndTime'),
    width: 200,
    dataIndex: 'oldEndDate',
    key: 'oldEndDate',
    render: (text, _, index) => {
      if (index < 7) {
        return <Input disabled value={text} />;
      }

      return <div></div>;
    },
  },
  {
    title: i18next.t('requests:workingHours.newStartTime'),
    width: 200,
    dataIndex: 'newStartDate',
    key: 'newStartDate',
    render: (text, _, index) => {
      if (index < 7) {
        return (
          <SelectBox
            options={selectboxOptions}
            onChange={val => setDayData(index, 'newStartDate', val)}
            value={selectboxOptions.find(item => item.value === text)}
            isDisabled={
              currentRequestStatus === requestStatuses.APPROVED ||
              currentRequestStatus === requestStatuses.DECLINED
            }
          ></SelectBox>
        );
      }

      return <div></div>;
    },
  },
  {
    title: i18next.t('requests:workingHours.newEndTime'),
    width: 200,
    dataIndex: 'newEndDate',
    key: 'newEndDate',
    render: (text, _, index) => {
      if (index < 7) {
        return (
          <SelectBox
            options={selectboxOptions}
            onChange={val => setDayData(index, 'newEndDate', val)}
            value={selectboxOptions.find(item => item.value === text)}
            isDisabled={
              currentRequestStatus === requestStatuses.APPROVED ||
              currentRequestStatus === requestStatuses.DECLINED
            }
          ></SelectBox>
        );
      }

      if (index === 7 && currentRequestStatus === requestStatuses.WAITING) {
        return {
          children: (
            <Space size="small">
              <Button kind="secondary" onClick={handleCancelButtonClick}>
                {i18next.t('requests:workingHours.cancelButton')}
              </Button>
              <Button onClick={handleApproveButtonClick}>
                {i18next.t('requests:workingHours.successButton')}
              </Button>
            </Space>
          ),
          props: {
            colSpan: 2,
          },
        };
      }

      return <div></div>;
    },
  },
];

export default getColumns;
