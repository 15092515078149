export const loadScript = (target, src, id, onSuccess, onError) => {
  const currentScriptEl = document.getElementById(id);
  const hasValidScript =
    currentScriptEl && currentScriptEl instanceof HTMLScriptElement;
  if (hasValidScript) {
    return;
  }

  const firstScriptEl = document[target].getElementsByTagName('script')[0];
  const scriptEl = document.createElement('script');
  scriptEl.id = id;
  scriptEl.src = src;
  scriptEl.defer = true;
  scriptEl.async = true;

  if (firstScriptEl && firstScriptEl.parentNode) {
    firstScriptEl.parentNode.insertBefore(scriptEl, firstScriptEl);
  } else {
    document[target].appendChild(scriptEl);
  }

  scriptEl.onerror = onError;
  scriptEl.onload = onSuccess;
};
