import { combineReducers } from 'redux';

import billingDefatilModalReducer from 'components/v1/BillingDetailModal/reducers';
import generalFormReducer from 'containers/StoreDetails/GeneralInformation/reducer';
import uploadGeoMapReducer from 'containers/GeoMap/UploadGeoMap/reducers';
import loginReducer from 'containers/Auth/Login/reducers';
import firmReducer from 'containers/Stores/Firm/reducers';
import vendorReducer from 'containers/Stores/Vendor/reducers';
import usersReducer from 'containers/StoreDetails/Users/reducers';
import loaderReducer from 'components/Loader/reducers';
import brandReducer from 'containers/Stores/Brands/reducers';
import catalogProductReducer from 'containers/CategoryManagement/CatalogProduct/reducers';
import headerReducer from 'components/Header/reducers';
import mainHeaderReducer from 'components/MainHeader/reducers';
import vendorproductReducer from 'containers/VendorProduct/reducers';
import paymentMethodsReducer from 'containers/PaymentMethods/reducer';
import workingHoursReducer from 'containers/WorkingHours/reducers';
import categoryReducer from 'containers/CategoryManagement/Category/reducers';
import activeOrdersReducer from 'containers/ActiveOrders/reducers';
import previousOrdersReducer from 'containers/PreviousOrders/reducers';
import orderDetailReducer from 'components/OrderDetailModal/reducers';
import financialTransactionsReducer from 'containers/Payments/FinancialTransactions/reducers';
import mapmodalReducer from 'components/MapModal/reducers';
import orderCancelReducer from 'components/OrderCancelModal/reducers';
import missingProductReducer from 'components/MissingProductModal/reducers';
import requestReducer from 'containers/Requests/reducers';
import bulkChangeReducer from 'containers/BulkChange/reducers';
import courierListModalReducer from 'components/CourierListModal/reducers';
import undertakingModalReducer from 'components/UndertakingModal/reducers';
import financialInformationReducer from 'containers/StoreDetails/FinancialInformation/reducer';
import addFinancialTransactionReducer from 'containers/Payments/AddFinancialTransaction/reducers';
import AlternativeMapHoursReducer from 'containers/GeoMap/AlternativeMapHours/reducers';
import authRolesReducer from 'containers/UsersPerms/Roles/reducers';
import authUsersReducer from 'containers/UsersPerms/Users/reducers';
import authPermsReducer from 'containers/UsersPerms/Perms/reducers';
import BanGeomapReducer from 'containers/GeoMap/BanGeoMap/reducers';
import VendorCommunicationReducer from 'containers/VendorCommunication/reducers';
import KuzeydenReportsReducer from 'containers/KuzeydenReports/reducer';

const appReducer = combineReducers({
  login: loginReducer,
  loader: loaderReducer,
  firm: firmReducer,
  brand: brandReducer,
  catalogProduct: catalogProductReducer,
  header: headerReducer,
  mainHeader: mainHeaderReducer,
  users: usersReducer,
  vendorproduct: vendorproductReducer,
  paymentMethods: paymentMethodsReducer,
  workingHours: workingHoursReducer,
  category: categoryReducer,
  vendor: vendorReducer,
  activeOrders: activeOrdersReducer,
  previousOrders: previousOrdersReducer,
  orderDetail: orderDetailReducer,
  financialTransactions: financialTransactionsReducer,
  mapModal: mapmodalReducer,
  orderCancel: orderCancelReducer,
  missingProduct: missingProductReducer,
  requests: requestReducer,
  bulkChange: bulkChangeReducer,
  uploadGeoMap: uploadGeoMapReducer,
  banGeoMap: BanGeomapReducer,
  alternativeHours: AlternativeMapHoursReducer,
  courierListModal: courierListModalReducer,
  undertakingModal: undertakingModalReducer,
  financialInformation: financialInformationReducer,
  addFinancialTransaction: addFinancialTransactionReducer,
  authRoles: authRolesReducer,
  authUsers: authUsersReducer,
  authPerms: authPermsReducer,
  formInputs: generalFormReducer,
  billingDetailModal: billingDefatilModalReducer,
  vendorCommunication: VendorCommunicationReducer,
  kuzeydenReports: KuzeydenReportsReducer,
});

export default appReducer;
