import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { Input, Button } from '@getir/web-components';

import { validateNewPassword } from 'utils/validations';
import getParams from 'utils/getParams';
import { PASSWORD_PATTERN, MIN_PASSWORD_LENGTH } from 'common/constants';

import * as LoginActions from './actions';
import * as S from './style';

const defaultValues = {
  newPassword: '',
  newPasswordConfirm: '',
};

const NewPassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm({
    mode: 'onBlur',
    defaultValues,
  });

  const validationRules = {
    newPassword: {
      required: t('requiredFieldText'),
      validate: value => {
        const minPasswordLength = MIN_PASSWORD_LENGTH;
        const pattern = new RegExp(PASSWORD_PATTERN);
        const result = pattern.test(value);

        if (!result) {
          return t('passwordValid', { minPasswordLength });
        }

        return true;
      },
    },
    newPasswordConfirm: {
      required: t('requiredFieldText'),
      validate: value => {
        if (validateNewPassword(value)) {
          if (value !== watch('newPassword')) {
            return t('auth:newPassword.validations.matchPassword');
          }
        }

        return true;
      },
    },
  };

  const onSubmit = data => {
    const token = getParams('token');
    const updatedData = { ...data, token };
    dispatch(LoginActions.newPasswordRequest(updatedData));
  };

  return (
    <S.LoginForm onSubmit={handleSubmit(onSubmit)}>
      <S.FormHeader>
        <S.FormTitle>{t('auth:newPassword.title')}</S.FormTitle>
      </S.FormHeader>
      <S.FormBody>
        <div>
          <Controller
            name="newPassword"
            control={control}
            rules={validationRules.newPassword}
            render={({ field }) => (
              <Input
                inputRef={field.ref}
                onChange={field.onChange}
                onBlur={field.onBlur}
                name={field.name}
                defaultValue={field.value}
                placeholder={t('auth:newPassword.newPasswordText')}
                header={t('auth:newPassword.newPasswordText')}
                type="password"
                hasError={!!errors.newPassword}
              />
            )}
          />
          {errors.newPassword && (
            <S.ErrorMessage>{errors.newPassword.message}</S.ErrorMessage>
          )}
        </div>
        <div>
          <Controller
            name="newPasswordConfirm"
            control={control}
            rules={validationRules.newPasswordConfirm}
            render={({ field }) => (
              <Input
                inputRef={field.ref}
                onChange={field.onChange}
                onBlur={field.onBlur}
                name={field.name}
                defaultValue={field.value}
                placeholder={t('auth:newPassword.newPasswordConfirmText')}
                header={t('auth:newPassword.newPasswordConfirmText')}
                type="password"
                hasError={!!errors.newPasswordConfirm}
              />
            )}
          />
          {errors.newPasswordConfirm && (
            <S.ErrorMessage>{errors.newPasswordConfirm.message}</S.ErrorMessage>
          )}
        </div>
      </S.FormBody>
      <S.FormFooter>
        <Button type="submit">{t('approve')}</Button>
      </S.FormFooter>
    </S.LoginForm>
  );
};

export default NewPassword;
