import styled from 'styled-components';
import { Button as StyledButton, Input, Icon } from '@getir/web-components';

const PageHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0;

  ${StyledButton.S.Wrapper} {
    border-radius: 0;
  }

  ${Icon.S.Wrapper} {
    color: #697488;
    margin-right: 14px;
    cursor: pointer;
  }
`;

const PageTitle = styled.p`
  margin: 0;
  font-size: 24px;
`;

const PageContent = styled.div`
  padding: 20px 2rem;
  background-color: #fff;
  box-shadow: 4px 4px 30px rgba(206, 206, 206, 0.25);
  border-radius: 2px;

  form {
    width: 50%;
    button {
      width: 100%;
    }
    > div {
      margin-bottom: 18px;
    }

    .ant-space,
    .ant-space-item {
      width: 100%;
    }
  }

  ${StyledButton.S.Wrapper} {
    border-radius: 0;
    width: 100%;
  }

  ${Input.S.Input} {
    border: none;
  }
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export { PageHeader, PageTitle, PageContent, IconContainer };
