import styled from 'styled-components';
import { Select } from 'antd';

export const SelectBoxWrapper = styled.div`
  display: flex;
  width: 100%;
  .ant-select-focused {
    .ant-select-selector {
      border-color: #5d3ebc !important;
      outline: none !important;
      box-shadow: 0px 0px 0px 3px #f3f0fe !important;
    }
  }
`;
export const SelectBox = styled(Select)`
  width: 100%;
  &:hover {
    .ant-select-selector {
      border-color: #5d3ebc !important;
    }
  }
  &:has(.ant-select-focused) {
    .ant-select-selector {
      border-color: #5d3ebc !important;
      outline: none !important;
      box-shadow: 0px 0px 0px 3px #f3f0fe !important;
    }
  }
  .ant-select-focused .ant-select-selector,
  .ant-select,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    border-color: #5d3ebc !important;
    outline: none !important;
    box-shadow: 0px 0px 0px 3px #f3f0fe !important;
  }

  ${({ $size, mode }) => {
    switch ($size) {
      case 'small':
        if (mode === 'multiple') {
          return `
            min-height: 36px;
            height: auto;
            font-size: 14px;
          `;
        }

        return `
          height: 36px;
          font-size: 14px;
        `;
      case 'large':
        if (mode === 'multiple') {
          return `
            min-height: 56px;
            height: auto;
            font-size: 14px;
          `;
        }

        return `
          height: 56px;
          font-size: 14px;
        `;
      default:
        if (mode === 'multiple') {
          return `
            min-height: 48px;
            height: auto;
            font-size: 14px;
          `;
        }

        return `
          height: 48px;
          font-size: 14px;
        `;
    }
  }}
`;
