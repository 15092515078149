import { getRequest, postRequest, putRequest } from 'lib/axios';

import api from './apiRoutes';

export const getVendorThreads = args => getRequest(api.getVendorThreads, args);

// export const getFilteredOrdersApi = params => {
//   const { page, size, vendorId, ...rest } = params;

//   return postRequest(api.getVendorThreads({ page, size }), rest);
// };

export const getMessagesFromThreads = (threadId, args) =>
  getRequest(api.getMessagesFromThread(threadId), args);

export const createNewThread = args => postRequest(api.createNewThread, args);

export const sendNewMessageToThread = args => {
  const { threadId, ...rest } = args;

  return putRequest(api.sendNewMessageToThread(threadId), rest);
};

export const closeThread = threadId => putRequest(api.closeThread(threadId));

export const changeReadStatusThread = threadId =>
  putRequest(api.changeReadStatusThread(threadId));
