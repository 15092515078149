import { Icon } from '@getir/web-components';
import { Alert, Divider, Space } from 'antd';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import Button from 'components/v1/Button';
import * as CS from 'common/style';
import ConfirmModal from 'components/ConfirmModal';
import InputWrapper from 'components/InputWrapper';
import PriceInput from 'components/PriceInput';
import Layout from 'components/Layout';
import SelectBoxWrapper from 'components/SelectBoxWrapper';
import * as ROUTES from 'constants/routes';
import * as CategoryActions from 'containers/CategoryManagement/Category/actions';
import useBoolean from 'hooks/useBoolean';

import * as VendorProductActions from '../actions';
import {
  ONLY_PRICE_MANUAL,
  BOTTLE_RETURN_WITH_SAME_BRAND,
  NO_DEPOSIT_REQUIRED,
  WITHOUT_BOTTLE_RETURN,
  MAX_PRICE_VALUE,
} from '../constants';

import * as S from './style';
import {
  isCrossedOutPriceZeroOrUndefined,
  obtainValidationRules,
} from './validationRules';

const Edit = () => {
  const {
    handleSubmit,
    formState: { errors, touchedFields },
    control,
    setValue,
    getValues,
    setError,
    clearErrors,
  } = useForm({
    mode: 'onSubmit',
  });
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation(['common', 'vendorproduct', 'catalogs']);
  const dispatch = useDispatch();
  const { id } = useParams();
  const userPerms = useSelector(state => state.login.userPerms);
  const isEditorUser = userPerms.data.find(
    perms => perms.componentId === 'product_list',
  ).canEdit;

  // selectors
  const { vendorInfo } = useSelector(state => state.mainHeader);
  const { selectedProduct, productInfo } = useSelector(
    state => state.vendorproduct,
  );
  const categoryList = useSelector(state => state.category.categorys);
  const [
    showCrossedOutPrice,
    { toggle: toggleCrossedOutPriceShown },
  ] = useBoolean(selectedProduct?.showCrossedOutPrice);
  const [
    isConfirmModalVisible,
    // eslint-disable-next-line no-unused-vars
    { setFalse: hideConfirmModal, setTrue: showConfirmModal },
  ] = useBoolean(false);
  const [isToggledFirst, { setTrue: setToggleFirstTrue }] = useBoolean(false);
  const [isSomeFieldDirty, { setTrue: setSomeFieldDirtyTrue }] = useBoolean(
    false,
  );
  const [isHandlerLocked, { setValue: setHandlerLocked }] = useBoolean(false);
  const isDepositableProduct =
    productInfo?.depositCarboyBadgeName !== BOTTLE_RETURN_WITH_SAME_BRAND &&
    productInfo?.depositCarboyBadgeName !== NO_DEPOSIT_REQUIRED;

  const crossedOutPrice = selectedProduct?.crossedOutPrice;
  const depositPrice = Number(getValues('depositPrice'));
  const productPrice = Number(getValues('price'));
  const validationRules = obtainValidationRules(
    t,
    crossedOutPrice,
    showCrossedOutPrice,
  );

  const handleToggleCrossedOutPrice = () => {
    let shouldLockToggle = true;
    if (!isCrossedOutPriceZeroOrUndefined(crossedOutPrice)) {
      const price = Number(getValues('price'));
      const isCrossedOutPriceLessThanOrEqualToPriceAndCrossedOutPriceIsNotShown =
        crossedOutPrice <= price && !showCrossedOutPrice;
      if (isCrossedOutPriceLessThanOrEqualToPriceAndCrossedOutPriceIsNotShown) {
        setError('price', {
          type: 'manual',
          message: t('strikethroughPriceIsLessThanPriceError'),
        });
      } else {
        clearErrors('price');
      }
      if (
        !isCrossedOutPriceLessThanOrEqualToPriceAndCrossedOutPriceIsNotShown
      ) {
        shouldLockToggle = false;
        toggleCrossedOutPriceShown();
      }
    }

    setSomeFieldDirtyTrue(!shouldLockToggle);
    setHandlerLocked(shouldLockToggle);

    if (!isToggledFirst) {
      setToggleFirstTrue();
    }
  };

  const vendorId = new URLSearchParams(location.search).get('vendorId');

  useEffect(() => {
    if (vendorId) {
      dispatch(
        VendorProductActions.vendorProductInfoRequest({
          productId: id,
        }),
      );
      if (location.state?.vpGetInfo !== false) {
        dispatch(
          VendorProductActions.vendorProductGetRequest({
            vendorId,
            productId: id,
          }),
        );
      }
    }

    return () => {
      dispatch(VendorProductActions.vendorProductInfoReset());
      dispatch(VendorProductActions.vendorProductGetReset());
    };

    // eslint-disable-next-line
  }, [dispatch, vendorId]);

  useEffect(() => {
    if (categoryList === undefined)
      dispatch(CategoryActions.categoryListRequest());
  }, [dispatch, categoryList]);

  useEffect(() => {
    if (productInfo) {
      setValue('productName', productInfo.productName);
      setValue('category', productInfo.category.id);
    }
  }, [productInfo, setValue]);

  useEffect(() => {
    setValue(
      'price',
      _.get(selectedProduct, 'price') ? selectedProduct?.price.toFixed(2) : '',
    );
    setValue(
      'depositPrice',
      _.get(selectedProduct, 'depositPrice') !== undefined
        ? selectedProduct?.depositPrice.toFixed(2)
        : undefined,
    );
    setValue(
      'crossedOutPrice',
      _.get(selectedProduct, 'crossedOutPrice')
        ? selectedProduct?.crossedOutPrice.toFixed(2)
        : '',
    );
  }, [selectedProduct, setValue]);
  /* eslint-disable */
  const categoryOptions = categoryList
    ? categoryList.map(item => ({
        label: item.categoryNameTR,
        value: item.id,
      }))
    : [];
  /* eslint-enable */

  const dispatchVendorEditRequestThenCloseModal = ({
    shouldHideCrossedOutPrice = false,
  } = {}) => {
    const data = {
      productId: productInfo.id,
      vendorId: vendorInfo.id,
      depositPrice: Number(getValues('depositPrice')),
      crossedOutPrice,
      showCrossedOutPrice: shouldHideCrossedOutPrice
        ? false
        : showCrossedOutPrice,
    };
    if (touchedFields.price) {
      data.price = Number(getValues('price'));
    }
    dispatch(VendorProductActions.vendorProductEditRequest(data));
    hideConfirmModal();
  };
  const handleConfirmSave = ({ shouldHideCrossedOutPrice = true } = {}) => {
    dispatchVendorEditRequestThenCloseModal({
      shouldHideCrossedOutPrice,
    });
  };

  const handleConfirmClose = () => {
    hideConfirmModal();
  };

  const onSubmit = () => {
    const modifiedPrice = Number(getValues('price'));
    if (
      showCrossedOutPrice &&
      selectedProduct.showCrossedOutPrice &&
      selectedProduct.price !== modifiedPrice
    ) {
      showConfirmModal();
    } else {
      handleConfirmSave({
        shouldHideCrossedOutPrice: false,
      });
    }
  };
  const depositPriceOnChange = depositValue => {
    setSomeFieldDirtyTrue();
    if (depositValue >= productPrice) {
      setError('depositPrice', {
        type: ONLY_PRICE_MANUAL,
        message:
          productInfo?.depositCarboyBadgeName === WITHOUT_BOTTLE_RETURN
            ? t('vendorproduct:depositPriceHasToBeLessThanPrice')
            : t('vendorproduct:changePriceHasToBeLessThanPrice'),
      });

      return;
    }
    if (depositValue > MAX_PRICE_VALUE) {
      setError('price', {
        type: ONLY_PRICE_MANUAL,
        message: t('vendorproduct:fieldCannotBeBiggerThanTenThousand'),
      });

      return;
    }
    clearErrors('depositPrice');
    clearErrors('price');
  };
  const priceOnChange = priceValue => {
    setSomeFieldDirtyTrue();
    if (priceValue <= depositPrice) {
      setError('price', {
        type: ONLY_PRICE_MANUAL,
        message:
          productInfo?.depositCarboyBadgeName === WITHOUT_BOTTLE_RETURN
            ? t('vendorproduct:priceHasToBeBiggerThanDepositPrice')
            : t('vendorproduct:priceHasToBeBiggerThanChangePrice'),
      });

      return;
    }
    if (priceValue > MAX_PRICE_VALUE) {
      setError('price', {
        type: ONLY_PRICE_MANUAL,
        message: t('vendorproduct:fieldCannotBeBiggerThanTenThousand'),
      });

      return;
    }
    clearErrors('price');
    clearErrors('depositPrice');
  };

  const depositPriceCustomOnFocus = () => {
    if (Number.isNaN(depositPrice)) setSomeFieldDirtyTrue();
  };

  return (
    <Layout>
      <S.PageHeader>
        <Space>
          <Icon
            name="arrow-left"
            onClick={() => history.push(ROUTES.VENDORPRODUCT)}
            title={t('backToList')}
          />
          <S.PageTitle>{t('vendorproduct:productdetail')}</S.PageTitle>
        </Space>
      </S.PageHeader>
      <S.PageContent>
        <S.AlertWrapper>
          <Alert
            message={t('common:priceChangeAlertText')}
            type="warning"
            showIcon
          />
        </S.AlertWrapper>
        <S.AlertWrapper>
          <Alert
            message={t('common:strikethroughPriceChangeAlertText')}
            type="warning"
            showIcon
          />
        </S.AlertWrapper>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputWrapper
            fieldName="productName"
            control={control}
            rules={validationRules.name}
            placeholder={t('catalogs:productName')}
            header={t('catalogs:productName')}
            errors={errors}
            isDisabled
          />
          <SelectBoxWrapper
            fieldName="category"
            control={control}
            rules={validationRules.categoryName}
            placeholder={t('catalogs:categoryName')}
            errors={errors}
            options={categoryOptions}
            isDisabled
          />
          <PriceInput
            fieldName="price"
            control={control}
            rules={validationRules.price}
            placeholder={t('vendorproduct:productPrice')}
            isDisabled={!isEditorUser}
            errors={errors}
            customOnChange={priceOnChange}
            header={t('vendorproduct:productPrice')}
          />
          {
            /* eslint-disable */
            isDepositableProduct ? (
              <PriceInput
                fieldName="depositPrice"
                control={control}
                rules={validationRules.depositPrice}
                isDisabled={!isEditorUser}
                placeholder={
                  productInfo?.depositCarboyBadgeName === WITHOUT_BOTTLE_RETURN
                    ? t('vendorproduct:depositPrice')
                    : t('vendorproduct:changePrice')
                }
                errors={errors}
                customOnChange={depositPriceOnChange}
                customOnFocus={depositPriceCustomOnFocus}
                header={
                  productInfo?.depositCarboyBadgeName === WITHOUT_BOTTLE_RETURN
                    ? t('vendorproduct:depositPrice')
                    : t('vendorproduct:changePrice')
                }
              />
            ) : null
            /* eslint-enable */
          }
          <S.StyledStrikethroughPriceWrapper>
            <div>
              {t('showStrikethroughPriceText')}:
              <S.StyledSpacer />
              {crossedOutPrice} TL
            </div>
            <S.StyledToggle
              checked={showCrossedOutPrice}
              checkedChildren={t('on')}
              uncheckedChildren={t('off')}
              onClick={handleToggleCrossedOutPrice}
              disabled={!isEditorUser}
            />
            <ConfirmModal
              size="400px"
              visible={isConfirmModalVisible}
              onSave={handleConfirmSave}
              onClose={handleConfirmClose}
              title={t('strikethroughPriceChangeConfirmModalTitle')}
              content={t('strikethroughPriceChangeConfirmModalContent')}
              cancelText={t('strikethroughPriceChangeCancelText')}
            />
          </S.StyledStrikethroughPriceWrapper>
          {errors.price && errors.price.type !== ONLY_PRICE_MANUAL && (
            <CS.ErrorMessage>{errors.price.message}</CS.ErrorMessage>
          )}
          <Divider />
          <Space size="middle">
            <Button
              style={{
                width: '100%',
              }}
              kind="secondary"
              onClick={() => history.push(ROUTES.VENDORPRODUCT)}
            >
              {t('cancel')}
            </Button>
            <Button
              style={{
                width: '100%',
              }}
              type="submit"
              disabled={
                !isEditorUser ||
                isHandlerLocked ||
                (isDepositableProduct && Number.isNaN(depositPrice)) ||
                !isSomeFieldDirty ||
                (!isSomeFieldDirty && !isToggledFirst) ||
                (errors.price && errors.price.type === ONLY_PRICE_MANUAL) ||
                errors.depositPrice?.type === ONLY_PRICE_MANUAL
              }
            >
              {t('save')}
            </Button>
          </Space>
        </form>
      </S.PageContent>
    </Layout>
  );
};

export default Edit;
