import { postRequest, getRequest } from 'lib/axios';

import api from './apiRoutes';

export const changePasswordApi = args => postRequest(api.changePassword, args);
export const logoutApi = args => postRequest(api.logout, args);
export const changeUserRoleApi = params =>
  getRequest(api.changeUserRole(), params);
export const getRolesByUserIdApi = params =>
  getRequest(api.getRolesByUserId(params));
