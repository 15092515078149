import React from 'react';
import PropTypes from 'prop-types';

import * as S from './style';

const DatePicker = ({ size, ...props }) => (
  <S.StyledDatePicker $size={size} {...props} />
);

DatePicker.propTypes = {
  size: PropTypes.oneOf(['large', 'medium', 'small']),
};

DatePicker.defaultProps = {
  size: 'medium',
};

export default DatePicker;
