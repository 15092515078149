import React, { useState, useEffect } from 'react';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
  TRANSACTION_TYPE_REVERSE,
  TRANSACTION_TYPE_TRANSLATE,
} from 'constants/transactionTypes';
import {
  PAYMENT_STATUS_TRANSLATEKEY,
  PAYMENT_STATUS,
} from 'constants/paymentType';

import * as TransactionsActions from './actions';
import * as S from './style';

const DetailedTransactions = ({ filters, setFilters }) => {
  const { data: detailedTransactionList, totalCount } = useSelector(
    state => state.financialTransactions.detailedTransactions,
  );
  const vendorInfo = useSelector(state => state.mainHeader.vendor);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    size: 'small',
    showSizeChanger: true,
    showTotal: (total, range) => `${range[0]}-${range[1]} / ${total} kayıt`,
  });
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handlePaginationChange = ({ current, pageSize }) => {
    setPagination({ ...pagination, current, pageSize });
  };

  useEffect(() => {
    setPagination(oldPagination => ({
      ...oldPagination,
      total: totalCount,
    }));
  }, [totalCount]);

  useEffect(() => {
    if (vendorInfo?.value && filters.partnerId) {
      setFilters({
        ...filters,
        partnerId: vendorInfo.value,
      });

      dispatch(
        TransactionsActions.getDetailedTransactionsRequest({
          ...filters,
          page: pagination.current - 1,
          size: pagination.pageSize,
        }),
      );
    }
  }, [pagination.current, pagination.pageSize , filters.partnerId]); // eslint-disable-line

  useEffect(() => {
    if (!filters.partnerId && vendorInfo && vendorInfo.value) {
      setFilters({
        ...filters,
        partnerId: vendorInfo.value,
      });
    }
    // eslint-disable-next-line
  }, [vendorInfo, dispatch]);

  useEffect(
    () => () => {
      dispatch(TransactionsActions.resetTransactionList());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters.partnerId],
  );

  const columns = [
    {
      title: t('financialTransactions:transactionDate'),
      dataIndex: 'date',
      render: text => {
        const d = new Date(text);

        return dayjs(d).format('DD.MM.YYYY');
      },
    },
    {
      title: t('financialTransactions:documentDate'),
      dataIndex: 'documentDate',
      render: (text, data) =>
        text
          ? dayjs(text).format('DD.MM.YYYY')
          : dayjs(data.date).format('DD.MM.YYYY'),
    },
    {
      title: t('financialTransactions:vendorName'),
      dataIndex: 'vendorName',
    },
    {
      title: t('financialTransactions:transactionType'),
      dataIndex: 'type',
      render: status => {
        const transactionType = TRANSACTION_TYPE_REVERSE[status];

        return transactionType
          ? t(TRANSACTION_TYPE_TRANSLATE[transactionType])
          : '';
      },
    },
    {
      title: t('financialTransactions:orderCode'),
      dataIndex: 'confirmationCode',
    },
    {
      title: t('financialTransactions:orderId'),
      dataIndex: 'orderId',
    },
    {
      title: t('financialTransactions:paymentStatus'),
      dataIndex: 'paymentStatusType',
      render: status => {
        const paymentStatusType = PAYMENT_STATUS[status];

        return paymentStatusType
          ? t(PAYMENT_STATUS_TRANSLATEKEY[paymentStatusType])
          : '';
      },
    },

    {
      title: t('financialTransactions:totalOrderAmount'),
      dataIndex: 'orderAmount',
      render: text => `₺${text.toFixed(2)}`,
    },
    {
      title: t('financialTransactions:totalOrderAmountWCampaign'),
      dataIndex: 'orderAmountWithCampaign',
      render: text => `₺${text.toFixed(2)}`,
    },
    {
      title: t('financialTransactions:commissionAmount'),
      dataIndex: 'commissionAmount',
      render: text => `₺${text.toFixed(2)}`,
    },
    {
      title: t('financialTransactions:vendorReceivable'),
      dataIndex: 'vendorReceivable',
      render: text => `₺${text.toFixed(2)}`,
    },
    {
      title: t('financialTransactions:note'),
      dataIndex: 'note',
    },
  ];

  return (
    <S.StyledTable
      dataSource={detailedTransactionList || []}
      columns={columns}
      pagination={pagination}
      showTotal
      scroll={{ x: 1000 }}
      locale={{ emptyText: <Empty description={t('table.noData')} /> }}
      onChange={handlePaginationChange}
      rowKey={record => `${record?.id}${record?.date}${record?.type}`}
    />
  );
};

DetailedTransactions.propTypes = {
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
};

export default DetailedTransactions;
