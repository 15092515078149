import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Divider, utilities } from '@getir/web-components';

import InputWrapper from 'components/InputWrapper';
import SelectBoxWrapper from 'components/Antd/FormElements/SelectBox';
import { createProgramData } from 'utils/createProgramData';

import * as S from './style';
import * as UserActions from './actions';
import { validationRules } from './validationrules';

const { noop } = utilities;

const defaultValues = {
  fullName: '',
  mobileNumber: '',
  email: '',
  role: '',
};

const AddNewUserModal = ({ onSave, onClose, ...props }) => {
  const { t } = useTranslation(['common', 'users']);
  const dispatch = useDispatch();
  const vendor = useSelector(state => state.mainHeader.vendor);
  const roleList = useSelector(state => state.users.roleList.data);

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    mode: 'onBlur',
    defaultValues,
  });

  const onSubmit = data => {
    const { fullName, email, mobileNumber, role } = data;
    const userData = {
      email,
      fullName,
      mobileNumber,
      roleIds: [`${role}`],
      programs: [createProgramData(vendor)],
      username: fullName,
    };

    dispatch(UserActions.createUserRequest(userData));
    reset();
    onSave();
  };

  const handleOnClose = () => {
    onClose();
    reset();
  };

  return (
    <S.StyledModal name="ADD_NEW_USER" onCancel={handleOnClose} {...props}>
      <Modal.Header>{t('users:addNew')}</Modal.Header>
      <Modal.Body>
        <S.FormBody>
          <S.NewUserModalForm onSubmit={handleSubmit(onSubmit)}>
            <InputWrapper
              fieldName="fullName"
              control={control}
              rules={validationRules.fullName}
              placeholder={t(`users:name`)}
              header={t(`users:name`)}
              errors={errors}
            />
            <InputWrapper
              fieldName="email"
              control={control}
              rules={validationRules.email}
              placeholder={t(`users:email`)}
              header={t(`users:email`)}
              errors={errors}
            />
            <InputWrapper
              fieldName="mobileNumber"
              control={control}
              rules={validationRules.mobileNumber}
              placeholder={t(`users:phone`)}
              header={t(`users:phone`)}
              errors={errors}
              isOnlyNumber
            />
            <SelectBoxWrapper
              fieldName="role"
              control={control}
              rules={validationRules.role}
              placeholder={t(`users:role`)}
              errors={errors}
              options={
                roleList &&
                roleList.map(role => ({
                  value: role.roleId,
                  label: role.roleName,
                }))
              }
            />
            <Divider />
            <S.FormFooter>
              <Button type="submit">{t('save')}</Button>
            </S.FormFooter>
          </S.NewUserModalForm>
        </S.FormBody>
      </Modal.Body>
    </S.StyledModal>
  );
};

AddNewUserModal.propTypes = {
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

AddNewUserModal.defaultProps = {
  onSave: noop,
  onClose: noop,
};

export default AddNewUserModal;
