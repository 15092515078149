import { takeLatest } from 'redux-saga/effects';

import {
  listCategorysApi,
  addCategoryApi,
  updateCategoryApi,
  deleteCategoryApi,
  getCategoryApi,
} from 'services/Category/api';
import * as ROUTES from 'constants/routes';
import { createStandardSaga } from 'utils/createStandardSaga';

import * as Types from './types';
import * as CategoryActions from './actions';

const listCategorysSaga = createStandardSaga({
  callApi: listCategorysApi,
  successAction: CategoryActions.categoryListSuccess,
  errorAction: CategoryActions.categoryListError,
});

const addCategorySaga = createStandardSaga({
  callApi: addCategoryApi,
  successAction: CategoryActions.addCategorySuccess,
  errorAction: CategoryActions.addCategoryError,
  successPushRoute: ROUTES.LIST_CATEGORY,
  successMessage: 'categories:createCategorySuccessMessage',
});

const updateCategorySaga = createStandardSaga({
  callApi: updateCategoryApi,
  successAction: CategoryActions.updateCategorySuccess,
  errorAction: CategoryActions.updateCategoryError,
  successPushRoute: ROUTES.LIST_CATEGORY,
  successMessage: 'categories:updateCategorySuccessMessage',
});

const deleteCategorySaga = createStandardSaga({
  callApi: deleteCategoryApi,
  successAction: CategoryActions.deleteCategorySuccess,
  errorAction: CategoryActions.deleteCategoryError,
});

const getCategorySaga = createStandardSaga({
  callApi: getCategoryApi,
  successAction: CategoryActions.getCategorySuccess,
  errorAction: CategoryActions.getCategoryError,
});

export function* rootSaga() {
  yield takeLatest(Types.CATEGORY_LIST_REQUEST, listCategorysSaga);
  yield takeLatest(Types.ADD_CATEGORY_REQUEST, addCategorySaga);
  yield takeLatest(Types.EDIT_CATEGORY_REQUEST, updateCategorySaga);
  yield takeLatest(Types.DELETE_CATEGORY_REQUEST, deleteCategorySaga);
  yield takeLatest(Types.GET_CATEGORY_REQUEST, getCategorySaga);
  yield takeLatest(Types.DELETE_CATEGORY_SUCCESS, listCategorysSaga);
  yield takeLatest(Types.ADD_CATEGORY_SUCCESS, listCategorysSaga);
  yield takeLatest(Types.EDIT_CATEGORY_SUCCESS, listCategorysSaga);
}

export default rootSaga;
