export const ORDERDETAIL_REQUEST = 'ORDERDETAIL_REQUEST';
export const ORDERDETAIL_SUCCESS = 'ORDERDETAIL_SUCCESS';
export const ORDERDETAIL_ERROR = 'ORDERDETAIL_ERROR';
export const ORDERDETAIL_RESET = 'ORDERDETAIL_RESET';

export const ORDERDETAILWITHACTIONS_REQUEST = 'ORDERDETAILWITHACTIONS_REQUEST';
export const ORDERDETAILWITHACTIONS_SUCCESS = 'ORDERDETAILWITHACTIONS_SUCCESS';
export const ORDERDETAILWITHACTIONS_ERROR = 'ORDERDETAILWITHACTIONS_ERROR';

export const GET_ADDRESS_NOTE_REQUEST = 'GET_ADDRESS_NOTE_REQUEST';
export const GET_ADDRESS_NOTE_SUCCESS = 'GET_ADDRESS_NOTE_SUCCESS';
export const GET_ADDRESS_NOTE_ERROR = 'GET_ADDRESS_NOTE_ERROR';

export const UPDATE_ADDRESS_NOTE_REQUEST = 'UPDATE_ADDRESS_NOTE_REQUEST';
export const UPDATE_ADDRESS_NOTE_SUCCESS = 'UPDATE_ADDRESS_NOTE_SUCCESS';
export const UPDATE_ADDRESS_NOTE_ERROR = 'UPDATE_ADDRESS_NOTE_ERROR';

export const CREATE_ADDRESS_NOTE_REQUEST = 'CREATE_ADDRESS_NOTE_REQUEST';
export const CREATE_ADDRESS_NOTE_SUCCESS = 'CREATE_ADDRESS_NOTE_SUCCESS';
export const CREATE_ADDRESS_NOTE_ERROR = 'CREATE_ADDRESS_NOTE_ERROR';

export const DELETE_ADDRESS_NOTE_REQUEST = 'DELETE_ADDRESS_NOTE_REQUEST';
export const DELETE_ADDRESS_NOTE_SUCCESS = 'DELETE_ADDRESS_NOTE_SUCCESS';
export const DELETE_ADDRESS_NOTE_ERROR = 'DELETE_ADDRESS_NOTE_ERROR';
