import React, { useEffect, useState } from 'react';
import { Space, Divider } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Icon, Button } from '@getir/web-components';

import Layout from 'components/Layout';
import * as ROUTES from 'constants/routes';
import InputWrapper from 'components/InputWrapper';
import SelectBoxWrapper from 'components/SelectBoxWrapper';
import SelectBoxAntd from 'components/Antd/FormElements/SelectBox';
import { rolesWithName } from 'constants/roles';
import pickMap from 'utils/pickMap';

import * as FirmActions from '../actions';
import * as FinancialInformationActions from '../../../StoreDetails/FinancialInformation/actions';

import { validations } from './validations';
import * as S from './style';

const firmTypesEnum = {
  1: 'INDIVIDUAL',
  2: 'CORPORATE',
};

const payoutTypeEnum = {
  1: 'TO_VENDOR',
  2: 'TO_FIRM',
};

const Add = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [isIndividual, setIsIndividual] = useState(false);

  const cities = useSelector(state => state.mainHeader.cities);
  const bankCodes =
    useSelector(state => state.financialInformation.bankCodes.data) || [];
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm({
    mode: 'onChange',
  });

  const firmTypes = [
    { label: t('firm:individual'), value: firmTypesEnum[1] },
    { label: t('firm:corporate'), value: firmTypesEnum[2] },
  ];

  const payoutTypes = [
    { label: t('firm:toVendor'), value: payoutTypeEnum[1] },
    { label: t('firm:toFirm'), value: payoutTypeEnum[2] },
  ];

  const selectedFirmType = watch('firmType');
  const validationRules = validations(t, isIndividual);

  useEffect(() => {
    dispatch(FinancialInformationActions.getBankCodesRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedFirmType) {
      if (
        selectedFirmType.value === firmTypesEnum[1] ||
        selectedFirmType === firmTypesEnum[1]
      ) {
        setIsIndividual(true);
        control.unregister('taxNumber');

        return;
      }
      setIsIndividual(false);
      control.unregister('identityNumber');
    }
  }, [selectedFirmType, control]);

  const onSubmit = formData => {
    const {
      keyAccountManager,
      firmName,
      commissionRate,
      address,
      manager,
      managerTelNumber,
      bankAccountName,
      email,
      firmManagerEmail,
      firmTitle,
      firmType,
      iban,
      identityNumber,
      taxNumber,
      mersisNumber,
      kepAddress,
      taxOffice,
      bank,
      cityId,
      payoutType,
    } = formData;

    const keyAccountManagerData = {
      id: keyAccountManager.value,
      fullName: keyAccountManager.label,
    };

    const selectedBank = bank.value
      ? { id: String(bank.value), name: bank.label }
      : bankCodes.find(code => code.value === bank);

    const data = {
      financialInfo: {
        bank: selectedBank.id
          ? selectedBank
          : { id: String(selectedBank.value), name: selectedBank.label },
        bankAccountName,
        email,
        firmManagerEmail,
        firmTitle,
        firmType: firmType.value ? firmType.value : firmType,
        iban,
        taxOffice,
        payoutType: payoutType.value ? payoutType.value : payoutType,
      },
      firmName,
      commissionRate: Number(commissionRate),
      address,
      mersisNumber,
      kepAddress,
      manager,
      managerTelNumber,
      cityId,
      ...{
        users: [],
        keyAccountManager: keyAccountManagerData,
      },
    };

    if (isIndividual) {
      data.financialInfo.identityNumber = identityNumber;
    } else {
      data.financialInfo.taxNumber = taxNumber;
    }

    dispatch(FirmActions.addFirmRequest(data));
  };

  return (
    <Layout>
      <S.PageHeader>
        <S.PageTitle>
          <Icon
            name="arrow-left"
            onClick={() => history.push(ROUTES.FIRMS)}
            title={t('backToList')}
          />
          {t('firm:addNewFirm')}
        </S.PageTitle>
      </S.PageHeader>
      <S.PageContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputWrapper
            fieldName="firmName"
            control={control}
            rules={validationRules.firmName}
            placeholder={t('firm:firmName')}
            header={t('firm:firmName')}
            errors={errors}
          />
          <InputWrapper
            fieldName="commissionRate"
            control={control}
            rules={validationRules.commissionRate}
            placeholder={t('vendor:commissionRate')}
            header={t('vendor:commissionRate')}
            errors={errors}
            isOnlyNumber
          />
          <InputWrapper
            fieldName="manager"
            control={control}
            rules={validationRules.manager}
            placeholder={t('firm:manager')}
            header={t('firm:manager')}
            errors={errors}
          />
          <InputWrapper
            fieldName="managerTelNumber"
            control={control}
            rules={validationRules.managerTelNumber}
            placeholder={t('firm:managerTelNumber')}
            header={t('firm:managerTelNumber')}
            errors={errors}
            isOnlyNumber
          />
          <InputWrapper
            fieldName="firmManagerEmail"
            control={control}
            rules={validationRules.firmManagerMail}
            placeholder={t('firm:firmManagerMail')}
            header={t('firm:firmManagerMail')}
            errors={errors}
          />
          <SelectBoxAntd
            showSearch
            labelInValue
            fieldName="keyAccountManager"
            control={control}
            rules={validationRules.keyAccountManager}
            placeholder={t('firm:keyAccountManager')}
            errors={errors}
            data={{ roleName: rolesWithName.ADMIN_KEY_ACCOUNT_MANAGER }}
            url="user-service/roles/role/users"
            valueKey="userId"
            labelKey="fullName"
            reqType="get"
            payloadKey="roleUsers"
          />

          <InputWrapper
            fieldName="firmTitle"
            control={control}
            rules={validationRules.title}
            placeholder={t('firm:firmTitle')}
            header={t('firm:firmTitle')}
            errors={errors}
          />

          <SelectBoxWrapper
            fieldName="firmType"
            control={control}
            rules={validationRules.type}
            placeholder={t('firm:firmType')}
            errors={errors}
            options={firmTypes}
            labelInValue
          />

          <InputWrapper
            fieldName="taxOffice"
            control={control}
            rules={validationRules.taxOffice}
            placeholder={t('firm:taxOffice')}
            header={t('firm:taxOffice')}
            errors={errors}
          />
          <InputWrapper
            fieldName="identityNumber"
            control={control}
            rules={validationRules?.idNumber}
            placeholder={t('firm:identityNumber')}
            header={t('firm:identityNumber')}
            errors={errors}
            isOnlyNumber
            style={{ display: isIndividual ? 'block' : 'none' }}
          />
          <InputWrapper
            fieldName="taxNumber"
            control={control}
            rules={validationRules?.taxNumber}
            placeholder={t('firm:taxNumber')}
            header={t('firm:taxNumber')}
            errors={errors}
            isOnlyNumber
            style={{ display: isIndividual ? 'none' : 'block' }}
          />
          <InputWrapper
            fieldName="mersisNumber"
            control={control}
            rules={validationRules.mersisNo}
            placeholder={t('firm:mersisNumber')}
            header={t('firm:mersisNumber')}
            errors={errors}
          />
          <InputWrapper
            fieldName="kepAddress"
            control={control}
            rules={validationRules.kepAddress}
            placeholder={t('firm:kepAddress')}
            header={t('firm:kepAddress')}
            errors={errors}
          />
          <SelectBoxWrapper
            fieldName="payoutType"
            control={control}
            rules={validationRules.payoutTypes}
            errors={errors}
            options={payoutTypes}
            placeholder={t('firm:payoutType')}
            labelInValue
          />
          <SelectBoxAntd
            showSearch
            fieldName="bank"
            control={control}
            rules={validationRules.bank}
            placeholder={t('firm:bank')}
            errors={errors}
            options={pickMap(bankCodes, 'label', 'value')}
            labelInValue
          />

          <InputWrapper
            fieldName="iban"
            control={control}
            rules={validationRules.iban}
            placeholder={t('firm:iban')}
            header={t('firm:iban')}
            errors={errors}
          />

          <InputWrapper
            fieldName="bankAccountName"
            control={control}
            rules={validationRules.bankAccountName}
            placeholder={t('firm:bankAccountName')}
            header={t('firm:bankAccountName')}
            errors={errors}
          />

          <InputWrapper
            fieldName="email"
            control={control}
            rules={validationRules.email}
            placeholder={t('mail')}
            header={t('mail')}
            errors={errors}
          />

          <SelectBoxAntd
            fieldName="cityId"
            control={control}
            rules={validationRules.cityId}
            placeholder={t('firm:city')}
            errors={errors}
            options={cities}
            showSearch
          />

          <InputWrapper
            fieldName="address"
            control={control}
            rules={validationRules.address}
            placeholder={t('firm:address')}
            header={t('firm:address')}
            errors={errors}
          />
          <Divider />
          <Space size="middle">
            <Button kind="secondary" onClick={() => history.push(ROUTES.FIRMS)}>
              {t('giveUp')}
            </Button>
            <Button type="submit">{t('save')}</Button>
          </Space>
        </form>
      </S.PageContent>
    </Layout>
  );
};

export default Add;
