import { getRequest, postRequest, putRequest } from 'lib/axios';

import api from './apiRoutes';

export const listVendorsApi = args => getRequest(api.listVendors, args);

export const addVendorApi = args => postRequest(api.addVendor, args);

export const updateVendorApi = args => putRequest(api.updateVendor, args);

export const deleteVendorApi = args => putRequest(api.deleteVendor(args));

export const closeForSaleApi = args => putRequest(api.closeForSales, args);

export const openForSalesApi = args => putRequest(api.openForSale(args));

export const getDistrictsApi = args => getRequest(api.getDistricts(args));

export const getTownsApi = args => getRequest(api.getTowns(args));

export const getBrandsApi = args => getRequest(api.getBrands(args));

export const getFirmsApi = args => getRequest(api.getFirms, args);

export const getOperationalManagersApi = args =>
  getRequest(api.getOperationalManagers, args);

export const getRegionManagersApi = args =>
  getRequest(api.getRegionManagers, args);
