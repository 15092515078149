import React, { useState, useRef, useMemo } from 'react';
import { Empty, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SearchOutlined, DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import Button from 'components/v1/Button';
import ConfirmModal from 'components/ConfirmModal';
import * as ROUTES from 'constants/routes';
import { BUSINESS_TYPES } from 'common/constants';
import * as MainHeaderActions from 'components/MainHeader/actions';
import Table from 'components/v1/Table';
import useDebounce from 'hooks/useDebounce';
import Input from 'components/v1/Input';

import * as FirmActions from '../actions';

import * as S from './style';

const List = () => {
  const dispatch = useDispatch();
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [selectedFirm, setSelectedFirm] = useState({});
  const history = useHistory();
  const { t } = useTranslation();

  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 200);

  const firms = useSelector(state => state.firm.firms.firms);
  const vendors = useSelector(state => state.mainHeader.vendors);

  const filteredFirms = useMemo(
    () =>
      firms?.filter(firm =>
        firm.firmName
          .toLocaleLowerCase('tr')
          .includes(debouncedSearchTerm.toLocaleLowerCase('tr')),
      ),
    [debouncedSearchTerm, firms],
  );

  const modalRef = useRef();

  const handleConfirmSave = () => {
    setIsConfirmModalVisible(false);
    dispatch(FirmActions.deleteFirmRequest(selectedFirm.id));
  };

  const handleConfirmClose = () => {
    setIsConfirmModalVisible(false);
  };

  const handleEditFirm = record => {
    history.push({
      pathname: ROUTES.GENERAL_INFORMATION,
      state: { type: BUSINESS_TYPES.FIRM },
    });
    dispatch(FirmActions.setFirmId(record.id));
    dispatch(FirmActions.getFirmInfoRequest(record.id));
    const currentVendor = vendors.find(item => item.value === record.id);
    dispatch(MainHeaderActions.setVendor(currentVendor));
  };
  const sapAreaEditable = false;
  const handleDeleteFirm = record => {
    setSelectedFirm(record);
    setIsConfirmModalVisible(true);
  };

  const columns = [
    {
      title: t('firm:firmName'),
      dataIndex: 'firmName',
      width: '20%',
    },
    {
      title: t('firm:manager'),
      dataIndex: 'manager',
      width: '20%',
    },
    {
      title: t('firm:managerTelNumber'),
      dataIndex: 'managerTelNumber',
    },
    {
      title: t('firm:keyAccountManager'),
      dataIndex: 'keyAccountManager',
      render: text => `${text.fullName ?? ''}`,
    },
    {
      title: 'Aksiyon',
      key: 'settings',
      render: (_, record) => (
        <Space size="middle">
          <Button kind="secondary" onClick={() => handleEditFirm(record)}>
            {t('edit')}
          </Button>
          <Button
            kind="secondary"
            style={{
              backgroundColor: 'transparent',
              padding: 0,
              dispaly: 'flex',
            }}
            disabled={!sapAreaEditable}
            onClick={() => handleDeleteFirm(record)}
          >
            <DeleteOutlined />
          </Button>
        </Space>
      ),
      align: 'center',
      width: 150,
      fixed: 'right',
    },
  ];

  return (
    <S.Wrapper>
      <Input
        placeholder={t('firm:searchFirm')}
        value={searchTerm}
        onChange={evt => setSearchTerm(evt.target.value)}
        size="large"
        style={{ width: '300px' }}
        prefix={<SearchOutlined width={20} height={20} />}
        allowClear
      />
      <Table
        columns={columns}
        dataSource={filteredFirms}
        pagination={{ size: 'small', position: ['bottomCenter'] }}
        locale={{ emptyText: <Empty description={t('table.noData')} /> }}
        scroll={{ x: 800 }}
        rowKey="id"
        bordered
      />
      <ConfirmModal
        modalRef={modalRef}
        size="400px"
        visible={isConfirmModalVisible}
        onSave={handleConfirmSave}
        onClose={handleConfirmClose}
        title={t('firm:deleteFirmModalTitle')}
        content={t('firm:deleteFirmModalContent')}
        cancelText={t('cancel')}
      />
    </S.Wrapper>
  );
};

export default List;
