import styled from 'styled-components';

const colors = {
  success: '#008C20',
  alert: '#FF3939',
  warning: '#F8AA1C',
  info: '#697488',
  primary: '#5D3EBC',
};

export const InfoIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 18px;
`;

export const InfoContainer = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  border-radius: 8px;

  overflow: hidden;

  ${({ $kind }) => `
    border: 1px solid ${colors[$kind]};
    background: ${colors[$kind]};
    & ${InfoIconContainer} {
        background: ${colors[$kind]};
    }
    `}
`;

export const ContentContainer = styled.div`
  padding: 16px;
  gap: 16px;
  display: flex;
  min-height: 100%;
  align-items: center;
  background-color: #fff;
  justify-content: flex-start;
  flex-grow: 1;
  button {
    width: 36px !important;
    height: 36px !important;
    background: #f6f6f6 !important;
    border-radius: 8px !important;
    align-self: flex-end;
    justify-self: flex-end !important;
    padding: 0 !important;
  }
`;

export const ContentText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  flex-grow: 1;
  color: #191919;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
`;
