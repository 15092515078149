import * as Types from './types';

const initialState = {
  vendor: '',
  error: {},
  vendorId: '',
  paymentData: undefined,
};

function PaymentMethodsReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.UPDATE_PAYMENTMETHODS_SUCCESS:
      return {
        ...state,
        paymentData: payload,
      };
    case Types.UPDATE_PAYMENTMETHODS_ERROR:
      return {
        ...state,
        error: payload,
      };
    default:
      return state;
  }
}

export default PaymentMethodsReducer;
