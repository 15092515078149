import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Space, Divider } from 'antd';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'components/v1/Button';
import * as FirmActions from 'containers/Stores/Firm/actions';
import InputWrapper from 'components/InputWrapper';
import SelectBoxWrapper from 'components/SelectBoxWrapper';
import SelectBoxAntd from 'components/Antd/FormElements/SelectBox';
import { rolesWithName } from 'constants/roles';
import pickMap from 'utils/pickMap';

import * as FinancialInformationActions from '../../../FinancialInformation/actions';

import { validations } from './validations';

const firmTypesEnum = {
  1: 'INDIVIDUAL',
  2: 'CORPORATE',
};

const payoutTypeEnum = {
  1: 'TO_VENDOR',
  2: 'TO_FIRM',
};

const FirmForm = ({ isFieldsEditable, getFieldsEditable }) => {
  const { t } = useTranslation();
  const [isIndividual, setIsIndividual] = useState(false);

  const dispatch = useDispatch();
  const vendor = useSelector(state => state.mainHeader.vendor);
  const cities = useSelector(state => state.mainHeader.cities);
  const firmInfo = useSelector(state => state.firm.firmInfo);
  const isSAPAreasEditable = !useSelector(
    state => state.firm.firmInfo.isPanelRestricted,
  );
  const bankCodes =
    useSelector(state => state.financialInformation.bankCodes.data) || [];

  const validationRules = validations(t, isIndividual);

  const firmTypes = [
    { label: t('firm:individual'), value: firmTypesEnum[1] },
    { label: t('firm:corporate'), value: firmTypesEnum[2] },
  ];

  const payoutTypes = [
    { label: t('firm:toVendor'), value: payoutTypeEnum[1] },
    { label: t('firm:toFirm'), value: payoutTypeEnum[2] },
  ];

  const {
    handleSubmit,
    formState: { errors, isDirty, isValid },
    control,
    setValue,
    watch,
  } = useForm({
    mode: 'onChange',
  });

  const selectedFirmType = watch('firmType');

  useEffect(() => {
    dispatch(FinancialInformationActions.getBankCodesRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedFirmType) {
      if (
        selectedFirmType.value === firmTypesEnum[1] ||
        selectedFirmType === firmTypesEnum[1]
      ) {
        setIsIndividual(true);
        control.unregister('taxNumber');

        return;
      }
      setIsIndividual(false);
      control.unregister('identityNumber');
    }
  }, [selectedFirmType, control]);

  useEffect(() => {
    if (vendor?.isFirm && !isFieldsEditable) {
      dispatch(FirmActions.getFirmInfoRequest(vendor.value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, vendor]);

  useEffect(() => {
    if (Object.keys(firmInfo).length > 0) {
      const { financialInfo } = firmInfo;
      setValue('firmName', firmInfo.firmName, { shouldValidate: true });
      setValue('commissionRate', String(firmInfo.commissionRate ?? ''), {
        shouldValidate: true,
      });

      setValue('manager', firmInfo.manager, { shouldValidate: true });
      setValue('managerTelNumber', firmInfo.managerTelNumber, {
        shouldValidate: true,
      });
      setValue(
        'referenceCode',
        firmInfo.referenceCode || firmInfo.sapcardCode,
        {
          shouldValidate: true,
        },
      );
      setValue(
        'keyAccountManager',
        {
          value: firmInfo.keyAccountManager.id,
          label: firmInfo.keyAccountManager.fullName,
        },
        {
          shouldValidate: true,
        },
      );
      setValue('address', firmInfo.address, { shouldValidate: true });
      setValue('cityId', firmInfo.cityId, { shouldValidate: true });
      setValue('mersisNumber', firmInfo.mersisNumber, {
        shouldValidate: true,
      });
      setValue('kepAddress', firmInfo.kepAddress, {
        shouldValidate: true,
      });
      if (financialInfo) {
        setValue('firmTitle', financialInfo.firmTitle, {
          shouldValidate: true,
        });
        setValue('payoutType', financialInfo.payoutType, {
          shouldValidate: true,
        });
        setValue('firmType', financialInfo.firmType, { shouldValidate: true });
        setValue('taxOffice', financialInfo.taxOffice, {
          shouldValidate: true,
        });

        setValue('firmManagerEmail', financialInfo.firmManagerEmail, {
          shouldValidate: true,
        });
        setValue('email', financialInfo.email, { shouldValidate: true });
        setValue('openAddress', financialInfo.openAddress, {
          shouldValidate: true,
        });
        setValue(
          'bank',
          {
            value: Number(financialInfo.bank.id),
            label: financialInfo.bank.name,
          },
          {
            shouldValidate: true,
          },
        );
        setValue('iban', financialInfo.iban, { shouldValidate: true });
        setValue('bankAccountName', financialInfo.bankAccountName, {
          shouldValidate: true,
        });
        setValue('sapcardCode', firmInfo.sapcardCode);

        return;
      }

      setValue('firmTitle', undefined);
      setValue('firmType', undefined);
      setValue('taxOffice', undefined);
      setValue('firmManagerEmail', undefined);
      setValue('email', undefined);
      setValue('openAddress', undefined);
      setValue('bank', undefined);
      setValue('iban', undefined);
      setValue('bankAccountName', undefined);
    }
  }, [firmInfo, setValue]);

  useEffect(() => {
    if (Object.keys(firmInfo).length > 0) {
      const { financialInfo } = firmInfo;
      if (financialInfo) {
        if (isIndividual) {
          setValue('identityNumber', financialInfo.identityNumber, {
            shouldValidate: true,
          });

          return;
        }

        setValue('taxNumber', financialInfo.taxNumber, {
          shouldValidate: true,
        });

        return;
      }
      setValue('identityNumber', undefined);
      setValue('taxNumber', undefined);
    }
  }, [isIndividual, setValue, firmInfo]);

  const onSubmit = formData => {
    const {
      keyAccountManager,
      commissionRate,
      firmName,
      address,
      manager,
      managerTelNumber,
      bankAccountName,
      email,
      firmManagerEmail,
      firmTitle,
      firmType,
      iban,
      identityNumber,
      taxNumber,
      taxOffice,
      bank,
      mersisNumber,
      kepAddress,
      cityId,
      payoutType,
    } = formData;

    const selectedBank = bank.value
      ? { id: String(bank.value), name: bank.label }
      : bankCodes.find(code => code.value === bank);

    const data = {
      id: firmInfo.id,
      financialInfo: {
        bank: selectedBank.id
          ? selectedBank
          : { id: String(selectedBank.value), name: selectedBank.label },
        bankAccountName,
        email,
        firmManagerEmail,
        firmTitle,
        firmType: firmType.value ? firmType.value : firmType,
        iban,
        taxOffice,
        payoutType: payoutType.value ? payoutType.value : payoutType,
      },
      firmName,
      mersisNumber,
      kepAddress,
      address,
      manager,
      managerTelNumber,
      cityId,
      users: firmInfo.users || [],
      commissionRate: Number(commissionRate),
    };

    if (isIndividual) {
      data.financialInfo.identityNumber = identityNumber;
    } else {
      data.financialInfo.taxNumber = taxNumber;
    }

    if (Object.prototype.hasOwnProperty.call(keyAccountManager, 'userId')) {
      data.keyAccountManager = {
        id: keyAccountManager.userId,
        fullName: keyAccountManager.fullName,
      };
    } else {
      data.keyAccountManager = {
        id: keyAccountManager.value,
        fullName: keyAccountManager.label,
      };
    }

    dispatch(FirmActions.updateFirmRequest(data));
    getFieldsEditable(false);
  };

  const handleCancelClick = () => {
    getFieldsEditable(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InputWrapper
        fieldName="firmName"
        control={control}
        rules={validationRules.firmName}
        placeholder="Firma Adı"
        header="Firma Adı"
        errors={errors}
        isDisabled={!isFieldsEditable || !isSAPAreasEditable}
      />
      <InputWrapper
        fieldName="commissionRate"
        control={control}
        rules={validationRules.commissionRate}
        placeholder={t('vendor:commissionRate')}
        header={t('vendor:commissionRate')}
        errors={errors}
        isOnlyNumber
        isDisabled={!isFieldsEditable}
      />
      <InputWrapper
        fieldName="manager"
        control={control}
        rules={validationRules.manager}
        placeholder="Firma Yöneticisi"
        header="Firma Yöneticisi"
        errors={errors}
        isDisabled={!isFieldsEditable}
      />
      <InputWrapper
        fieldName="managerTelNumber"
        control={control}
        rules={validationRules.managerTelNumber}
        placeholder="Firma Yönetici Telefon No"
        header="Firma Yönetici Telefon No"
        errors={errors}
        isDisabled={!isFieldsEditable}
        isOnlyNumber
      />

      <InputWrapper
        fieldName="firmManagerEmail"
        control={control}
        rules={validationRules.firmManagerMail}
        placeholder={t('firm:firmManagerMail')}
        header={t('firm:firmManagerMail')}
        errors={errors}
        isDisabled={!isFieldsEditable}
      />

      <SelectBoxAntd
        showSearch
        labelInValue
        fieldName="keyAccountManager"
        control={control}
        rules={validationRules.keyAccountManager}
        placeholder="Key Account Manager"
        errors={errors}
        isDisabled={!isFieldsEditable}
        data={{ roleName: rolesWithName.ADMIN_KEY_ACCOUNT_MANAGER }}
        url="user-service/roles/role/users"
        valueKey="userId"
        labelKey="fullName"
        reqType="get"
        payloadKey="roleUsers"
      />

      <InputWrapper
        fieldName="firmTitle"
        control={control}
        rules={validationRules.title}
        placeholder={t('firm:firmTitle')}
        header={t('firm:firmTitle')}
        errors={errors}
        isDisabled={!isFieldsEditable || !isSAPAreasEditable}
      />

      <SelectBoxWrapper
        fieldName="firmType"
        control={control}
        rules={validationRules.type}
        placeholder={t('firm:firmType')}
        errors={errors}
        options={firmTypes}
        labelInValue
        isDisabled={!isFieldsEditable}
      />

      <InputWrapper
        fieldName="taxOffice"
        control={control}
        rules={validationRules.taxOffice}
        placeholder={t('firm:taxOffice')}
        header={t('firm:taxOffice')}
        errors={errors}
        isDisabled={!isFieldsEditable || !isSAPAreasEditable}
      />
      <InputWrapper
        fieldName="identityNumber"
        control={control}
        rules={validationRules?.idNumber}
        placeholder={t('firm:identityNumber')}
        header={t('firm:identityNumber')}
        errors={errors}
        isOnlyNumber
        style={{ display: isIndividual ? 'block' : 'none' }}
        isDisabled={!isFieldsEditable || !isSAPAreasEditable}
      />
      <InputWrapper
        fieldName="taxNumber"
        control={control}
        rules={validationRules?.taxNumber}
        placeholder={t('firm:taxNumber')}
        header={t('firm:taxNumber')}
        errors={errors}
        isOnlyNumber
        style={{ display: isIndividual ? 'none' : 'block' }}
        isDisabled={!isFieldsEditable || !isSAPAreasEditable}
      />

      <InputWrapper
        fieldName="referenceCode"
        control={control}
        rules={validationRules.referenceCode}
        placeholder={t('firm:referenceCode')}
        header={t('firm:referenceCode')}
        errors={errors}
        isDisabled
      />
      <InputWrapper
        fieldName="mersisNumber"
        control={control}
        rules={validationRules.mersisNo}
        placeholder={t('firm:mersisNumber')}
        header={t('firm:mersisNumber')}
        errors={errors}
        isDisabled={!isFieldsEditable}
      />
      <InputWrapper
        fieldName="kepAddress"
        control={control}
        rules={validationRules.kepAddress}
        placeholder={t('firm:kepAddress')}
        header={t('firm:kepAddress')}
        errors={errors}
        isDisabled={!isFieldsEditable}
      />
      <SelectBoxWrapper
        fieldName="payoutType"
        control={control}
        rules={validationRules.payoutTypes}
        errors={errors}
        options={payoutTypes}
        placeholder={t('firm:payoutType')}
        labelInValue
        isDisabled={!isFieldsEditable}
      />
      <SelectBoxAntd
        showSearch
        fieldName="bank"
        control={control}
        rules={validationRules.bank}
        placeholder={t('firm:bank')}
        errors={errors}
        options={pickMap(bankCodes, 'label', 'value')}
        labelInValue
        isDisabled={!isFieldsEditable || !isSAPAreasEditable}
      />

      <InputWrapper
        fieldName="iban"
        control={control}
        rules={validationRules.iban}
        placeholder={t('firm:iban')}
        header={t('firm:iban')}
        errors={errors}
        isDisabled={!isFieldsEditable || !isSAPAreasEditable}
      />

      <InputWrapper
        fieldName="bankAccountName"
        control={control}
        rules={validationRules.bankAccountName}
        placeholder={t('firm:bankAccountName')}
        header={t('firm:bankAccountName')}
        errors={errors}
        isDisabled={!isFieldsEditable}
      />

      <InputWrapper
        fieldName="email"
        control={control}
        rules={validationRules.email}
        placeholder={t('mail')}
        header={t('mail')}
        errors={errors}
        isDisabled={!isFieldsEditable || !isSAPAreasEditable}
      />

      <SelectBoxAntd
        fieldName="cityId"
        control={control}
        rules={validationRules.cityId}
        placeholder={t('firm:city')}
        errors={errors}
        options={cities}
        showSearch
        isDisabled={!isFieldsEditable || !isSAPAreasEditable}
      />

      <InputWrapper
        fieldName="address"
        control={control}
        rules={validationRules.address}
        placeholder={t('firm:address')}
        header={t('firm:address')}
        errors={errors}
        isDisabled={!isFieldsEditable}
      />

      <InputWrapper
        fieldName="sapcardCode"
        control={control}
        placeholder={t('firm:sapcardCode')}
        header={t('firm:sapcardCode')}
        errors={errors}
        isDisabled={!isFieldsEditable || !isSAPAreasEditable}
      />

      <Divider />
      {isFieldsEditable && (
        <Space size="middle">
          <Button
            style={{
              width: '100%',
            }}
            kind="secondary"
            onClick={handleCancelClick}
          >
            {t('giveUp')}
          </Button>
          <Button
            style={{
              width: '100%',
            }}
            type="submit"
            disabled={!isDirty || !isValid}
          >
            {t('save')}
          </Button>
        </Space>
      )}
    </form>
  );
};

FirmForm.propTypes = {
  isFieldsEditable: PropTypes.bool,
  getFieldsEditable: PropTypes.func,
};

FirmForm.defaultProps = {
  isFieldsEditable: false,
  getFieldsEditable: () => {},
};

export default FirmForm;
