export const GET_TRANSACTIONS_REQUEST = 'GET_TRANSACTIONS_REQUEST';
export const GET_TRANSACTIONS_SUCCESS = 'GET_TRANSACTIONS_SUCCESS';
export const GET_TRANSACTIONS_ERROR = 'GET_TRANSACTIONS_ERROR';

export const GET_DETAILED_TRANSACTIONS_REQUEST =
  'GET_DETAILED_TRANSACTIONS_REQUEST';
export const GET_DETAILED_TRANSACTIONS_SUCCESS =
  'GET_DETAILED_TRANSACTIONS_SUCCESS';
export const GET_DETAILED_TRANSACTIONS_ERROR =
  'GET_DETAILED_TRANSACTIONS_ERROR';

export const GET_TRANSACTION_DETAIL_REQUEST = 'GET_TRANSACTION_DETAIL_REQUEST';
export const GET_TRANSACTION_DETAIL_SUCCESS = 'GET_TRANSACTION_DETAIL_SUCCESS';
export const GET_TRANSACTION_DETAIL_ERROR = 'GET_TRANSACTION_DETAIL_ERROR';

export const RESET_TRANSACTIONS_LIST = 'RESET_TRANSACTIONS_LIST';
