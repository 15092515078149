import React from 'react';
import { Input } from '@getir/web-components';
import { useTranslation } from 'react-i18next';

import allowOnlyNumber from 'utils/allowOnlyNumber';
import getFieldNameById from 'utils/getFieldNameById';
import sortFieldNamesById from 'utils/sortFieldNamesById';

const useColumns = (
  isEditActive,
  setPriceData,
  { categories, brands, productList },
) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t('bulkChange:brandName'),
      dataIndex: `brand`,
      width: '20%',
      sorter: (a, b) => sortFieldNamesById(a, b, brands, 'brandName', 'brand'),
      render: text => getFieldNameById(brands, 'brandName', text),
    },
    {
      title: t('bulkChange:categoryName'),
      dataIndex: 'category',
      width: '20%',
      render: text => getFieldNameById(categories, 'categoryNameTR', text),
      sorter: (a, b) =>
        sortFieldNamesById(a, b, categories, 'categoryNameTR', 'category'),
    },
    {
      title: t('bulkChange:productName'),
      dataIndex: 'productName',
      width: '20%',
      sorter: (a, b) => a.productName.localeCompare(b.productName),
    },
    {
      title: isEditActive ? t('bulkChange:oldPrice') : '',
      key: 'price',
      render: text => {
        if (isEditActive) {
          return (
            <Input
              size="small"
              placeholder={t('bulkChange:oldPrice')}
              addonBefore="₺"
              disabled
              value={text.price ? text.price : 0}
            />
          );
        }

        return '';
      },
      width: '20%',
    },
    {
      title: isEditActive ? t('bulkChange:newPrice') : '',
      key: 'newPrice',
      render: (text, row) => {
        const currentIndex = productList.findIndex(
          product => product.uniqueId === row.uniqueId,
        );
        if (isEditActive) {
          return (
            <Input
              size="small"
              placeholder={t('bulkChange:newPrice')}
              addonBefore="₺"
              onKeyPress={allowOnlyNumber}
              onChange={e =>
                setPriceData(currentIndex, 'newPrice', e.target.value)
              }
              value={text?.newPrice === 0 ? '' : text.newPrice}
            />
          );
        }

        return '';
      },
      width: '20%',
    },
  ];

  return columns;
};

export { useColumns };
