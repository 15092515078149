import { createAction } from 'utils/createAction';

import * as Types from './types';

// update working hours
export const updateWorkingHoursRequest = createAction(
  Types.UPDATE_WORKING_HOURS_REQUEST,
);
export const updateWorkingHoursSuccess = createAction(
  Types.UPDATE_WORKING_HOURS_SUCCESS,
);
export const updateWorkingHoursError = createAction(
  Types.UPDATE_WORKING_HOURS_ERROR,
);

// update auto open
export const updateAutoOpenRequest = createAction(
  Types.UPDATE_AUTO_OPEN_REQUEST,
);
export const updateAutoOpenSuccess = createAction(
  Types.UPDATE_AUTO_OPEN_SUCCESS,
);
export const updateAutoOpenError = createAction(Types.UPDATE_AUTO_OPEN_ERROR);

// get auto open
export const getAutoOpenRequest = createAction(Types.GET_AUTO_OPEN_REQUEST);
export const getAutoOpenSuccess = createAction(Types.GET_AUTO_OPEN_SUCCESS);
export const getAutoOpenError = createAction(Types.GET_AUTO_OPEN_ERROR);
