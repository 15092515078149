import * as Types from './types';

const initialState = {
  brands: undefined,
  error: {},
  addBrand: '',
  brandId: '',
  brandInfo: {},
  editBrand: '',
  deleteBrand: '',
};

function BrandReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.BRAND_LIST_SUCCESS:
      return {
        ...state,
        brands: payload,
      };
    case Types.BRAND_LIST_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.ADD_BRAND_SUCCESS:
      return {
        ...state,
        addBrand: payload,
      };
    case Types.ADD_BRAND_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.SET_BRAND_ID:
      return {
        ...state,
        brandId: payload,
      };
    case Types.EDIT_BRAND_SUCCESS:
      return {
        ...state,
        editBrand: payload,
      };
    case Types.EDIT_BRAND_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.DELETE_BRAND_SUCCESS: {
      const { result } = payload;

      return {
        ...state,
        deleteBrand: result,
      };
    }
    case Types.DELETE_BRAND_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.BRAND_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        products: payload,
      };
    case Types.BRAND_PRODUCT_LIST_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.BRAND_PRODUCT_LIST_SAVE_SUCCESS:
      return {
        ...state,
        saveOrder: payload,
      };
    case Types.BRAND_PRODUCT_LIST_SAVE_ERROR:
      return {
        ...state,
        error: payload,
      };
    default:
      return state;
  }
}

export default BrandReducer;
