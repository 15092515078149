import dayjs from 'dayjs';

import beepSound from 'common/shipbell.mp3';
import translate from 'utils/translate';
import { ORDER_STATUS_CODES } from 'constants/orderStatus';

const activeOrdersColumns = [
  {
    title: translate('activeOrders:columns.confirmationCode'),
    dataIndex: 'confirmationCode',
    key: 'confirmationCode',
  },
  {
    title: translate('activeOrders:columns.customer'),
    dataIndex: 'customer',
  },
  {
    title: translate('activeOrders:columns.orderPrice'),
    dataIndex: 'totalPriceText',
  },
  {
    title: translate('activeOrders:columns.orderDate'),
    dataIndex: 'paymentDate',
    render: text => {
      const d = new Date(text);

      return dayjs(d).format('DD.MM.YYYY, H:mm:ss');
    },
  },
  {
    title: translate('activeOrders:columns.paymentMethod'),
    dataIndex: 'paymentMethodText',
  },
  {
    title: translate('activeOrders:columns.status'),
    dataIndex: 'status',
  },
  {
    title: translate('activeOrders:columns.confirmationTime'),
    dataIndex: 'confirmationTime',
  },
  {
    title: translate('activeOrders:columns.totalTime'),
    dataIndex: 'totalTime',
  },
];

const completedOrdersColumns = [
  {
    title: translate('activeOrders:columns.confirmationCode'),
    dataIndex: 'confirmationCode',
  },
  {
    title: translate('activeOrders:columns.customer'),
    dataIndex: 'customer',
  },
  {
    title: translate('activeOrders:columns.orderPrice'),
    dataIndex: 'totalPriceText',
  },
  {
    title: translate('activeOrders:columns.orderDate'),
    dataIndex: 'paymentDate',
    render: text => {
      const d = new Date(text);

      return dayjs(d).format('DD.MM.YYYY, H:mm:ss');
    },
  },
  {
    title: translate('activeOrders:columns.confirmationDate'),
    dataIndex: 'confirmationDate',
  },
  {
    title: translate('activeOrders:columns.cancellationDate'),
    dataIndex: 'cancellationDate',
  },
  {
    title: translate('activeOrders:columns.status'),
    dataIndex: 'status',
    render: text => ORDER_STATUS_CODES[text],
  },
  {
    title: translate('activeOrders:columns.confirmationTime'),
    dataIndex: 'confirmationTime',
  },
  {
    title: translate('activeOrders:columns.totalTime'),
    dataIndex: 'totalTime',
  },
];

const playableAudio = new Audio(beepSound);

export { activeOrdersColumns, completedOrdersColumns, playableAudio };
