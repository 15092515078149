import { email } from '@sideway/address';

import translate from 'utils/translate';
import { validateIBAN, validatePhone } from 'utils/validations';

const validations = isIndividual => ({
  title: {
    required: translate('requiredFieldText'),
  },
  type: {
    required: translate('requiredFieldText'),
  },
  taxOffice: {
    required: translate('requiredFieldText'),
  },
  ...(!isIndividual && {
    taxNumber: {
      required: translate('requiredFieldText'),
      validate: value => {
        const isValidTaxNumber = value && value.length === 10;

        return isValidTaxNumber || translate('financialInformation:validTaxNo');
      },
    },
  }),
  ...(isIndividual && {
    idNumber: {
      required: translate('requiredFieldText'),
      validate: value => {
        const isValidIdNumber = value && value.length === 11;

        return (
          isValidIdNumber || translate('financialInformation:validIdNumber')
        );
      },
    },
  }),
  firmManager: {
    required: translate('requiredFieldText'),
  },
  managerPhoneNumber: {
    required: translate('requiredFieldText'),
    validate: value => {
      if (value.length > 0) {
        const isValidPhone = validatePhone(value);

        return isValidPhone || translate('phoneValid');
      }

      return true;
    },
  },
  email: {
    required: translate('requiredFieldText'),
    validate: value => {
      const isValidEmail = email.isValid(value);

      return isValidEmail || translate('emailValid');
    },
  },
  address: {
    required: translate('requiredFieldText'),
  },
  bank: {
    required: translate('requiredFieldText'),
  },
  iban: {
    required: translate('requiredFieldText'),
    validate: value => {
      const isValidIBAN = validateIBAN(value);

      return isValidIBAN || translate('financialInformation:validIBAN');
    },
  },
  bankAccountName: {
    required: translate('requiredFieldText'),
  },
  financialInfoType: {
    required: translate('requiredFieldText'),
  },
  vendorPhoneNumber: {
    required: translate('requiredFieldText'),
    validate: value => {
      if (value.length > 0) {
        const isValidPhone = validatePhone(value);

        return isValidPhone || translate('phoneValid');
      }

      return true;
    },
  },
  city: {
    required: translate('requiredFieldText'),
  },
  district: {
    required: translate('requiredFieldText'),
  },
});

export { validations };
