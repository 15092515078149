import { takeLatest, select, call } from 'redux-saga/effects';

import { createStandardSaga } from 'utils/createStandardSaga';
import { getFilteredOrdersApi } from 'services/PreviousOrders/api';

import * as Types from './types';
import * as PreviousOrdersActions from './actions';

const getFilteredOrdersSaga = createStandardSaga({
  callApi: getFilteredOrdersApi,
  successAction: PreviousOrdersActions.getFilteredOrdersSuccess,
  errorAction: PreviousOrdersActions.getFilteredOrdersError,
});

function* revalidatePreviousOrdersSaga() {
  const lastFilter = yield select(state => state.previousOrders.lastFilter);
  yield call(getFilteredOrdersSaga, { payload: lastFilter });
}

export function* rootSaga() {
  yield takeLatest(Types.GET_FILTERED_ORDERS_REQUEST, getFilteredOrdersSaga);
  yield takeLatest(
    Types.REVALIDATE_PREVIOUS_ORDER,
    revalidatePreviousOrdersSaga,
  );
}

export default rootSaga;
