const turkishCharacterRegex = keyword =>
  keyword
    .replace(/[ıİiI]/g, '[ıİiI]')
    .replace(/[şŞsS]/g, '[şŞsS]')
    .replace(/[çÇcC]/g, '[çÇcC]')
    .replace(/[ğĞgG]/g, '[ğĞgG]')
    .replace(/[öÖoO]/g, '[öÖoO]')
    .replace(/[üÜuU]/g, '[üÜuU]');

const customFilter = (input, option) => {
  const label = option.label || option.title || option.value;

  return turkishCharacterRegex(label)
    .toLowerCase()
    .includes(turkishCharacterRegex(input).toLowerCase());
};

export default customFilter;
