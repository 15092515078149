import { createAction } from 'utils/createAction';

import * as Types from './types';

// get role list
export const getRoleListRequest = createAction(Types.GET_ROLE_LIST_REQUEST);
export const getRoleListSuccess = createAction(Types.GET_ROLE_LIST_SUCCESS);
export const getRoleListError = createAction(Types.GET_ROLE_LIST_ERROR);

// get role list
export const getRoleUserByRoleIdRequest = createAction(
  Types.GET_ROLE_USERS_BY_ROLE_ID_REQUEST,
);
export const getRoleUserByRoleIdSuccess = createAction(
  Types.GET_ROLE_USERS_BY_ROLE_ID_SUCCESS,
);
export const getRoleUserByRoleIdError = createAction(
  Types.GET_ROLE_USERS_BY_ROLE_ID_ERROR,
);

// create role
export const createRoleRequest = createAction(Types.CREATE_ROLE_REQUEST);
export const createRoleSuccess = createAction(Types.CREATE_ROLE_SUCCESS);
export const createRoleError = createAction(Types.CREATE_ROLE_ERROR);

// update role
export const updateRoleRequest = createAction(Types.UPDATE_ROLE_REQUEST);
export const updateRoleSuccess = createAction(Types.UPDATE_ROLE_SUCCESS);
export const updateRoleError = createAction(Types.UPDATE_ROLE_ERROR);

// get perms by role id
export const getPermsByRoleIdRequest = createAction(
  Types.GET_PERMS_BY_ROLE_ID_REQUEST,
);
export const getPermsByRoleIdSuccess = createAction(
  Types.GET_PERMS_BY_ROLE_ID_SUCCESS,
);
export const getPermsByRoleIdError = createAction(
  Types.GET_PERMS_BY_ROLE_ID_ERROR,
);

// delete role
export const deleteRoleRequest = createAction(Types.DELETE_ROLE_REQUEST);
export const deleteRoleSuccess = createAction(Types.DELETE_ROLE_SUCCESS);
export const deleteRoleError = createAction(Types.DELETE_ROLE_ERROR);

// get role group names
export const getRoleGroupNamesRequest = createAction(
  Types.GET_ROLE_GROUP_NAMES_REQUEST,
);
export const getRoleGroupNamesSuccess = createAction(
  Types.GET_ROLE_GROUP_NAMES_SUCCESS,
);
export const getRoleGroupNamesError = createAction(
  Types.GET_ROLE_GROUP_NAMES_ERROR,
);
