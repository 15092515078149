import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styles';

const Button = ({ kind, children, size, ...props }) => (
  <S.ButtonContainer $kind={kind} $size={size} {...props}>
    {children}
  </S.ButtonContainer>
);

Button.propTypes = {
  kind: PropTypes.oneOf(['primary', 'secondary']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  children: PropTypes.node.isRequired,
};

Button.defaultProps = {
  kind: 'primary',
  size: 'medium',
};

export default Button;
