import { postRequest, getRequest } from 'lib/axios';

import api from './apiRoutes';

export const loginApi = args => postRequest(api.login, args);
export const forgotPasswordApi = args => postRequest(api.forgotPassword, args);
export const newPasswordApi = args => postRequest(api.newPassword, args);
export const googleLoginApi = args => postRequest(api.googleLogin, args);
export const getUserPermsApi = args => getRequest(api.getUserPerms(args));
export const getPermListApi = args => getRequest(api.getPermList(args));
