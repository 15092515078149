const ORDERCANCEL_REASON = Object.freeze({
  OUT_OF_SERVICE: 1,
  CARRIER_NOT_AVAILABLE: 2,
  VENDOR_CLOSED: 3,
  VENDOR_BUSY: 4,
  CARRIER_CRASHED: 5,
  MISSING_PRODUCT: 6,
  // WEATHER: 7,
});

const ORDERCANCEL_REASON_TRANSLATEKEYS = Object.freeze({
  OUT_OF_SERVICE: 'ordercancelmodal:OUT_OF_SERVICE',
  CARRIER_NOT_AVAILABLE: 'ordercancelmodal:CARRIER_NOT_AVAILABLE',
  VENDOR_CLOSED: 'ordercancelmodal:VENDOR_CLOSED',
  VENDOR_BUSY: 'ordercancelmodal:VENDOR_BUSY',
  CARRIER_CRASHED: 'ordercancelmodal:CARRIER_CRASHED',
  MISSING_PRODUCT: 'ordercancelmodal:MISSING_PRODUCT',
  // WEATHER: 'ordercancelmodal:WEATHER',
});

export { ORDERCANCEL_REASON, ORDERCANCEL_REASON_TRANSLATEKEYS };
