import styled from 'styled-components';
import { Input, Button } from '@getir/web-components';

const PageHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0;
`;

const PageTitle = styled.div`
  margin-bottom: 0;
  font-size: 24px;
`;

const PageContent = styled.div`
  padding: 20px 2rem;
  background-color: #fff;
  box-shadow: 4px 4px 30px rgba(206, 206, 206, 0.25);
  border-radius: 2px;

  form {
    width: 70%;

    > div {
      margin-bottom: 18px;
    }

    .ant-space,
    .ant-space-item {
      width: 100%;
    }
  }

  ${Button.S.Wrapper} {
    border-radius: 0;
    width: 100%;
  }

  ${Input.S.Input} {
    border: none;
  }
`;

const PageTitleActions = styled.div`
  ${Button.S.Wrapper} {
    border-radius: 0;
  }
`;

const AlertWrapper = styled.div`
  margin-bottom: 20px;
`;

export { PageHeader, PageTitle, PageContent, PageTitleActions, AlertWrapper };
