import { takeLatest, call, put } from 'redux-saga/effects';

import {
  addFinancialTransaction,
  savePremiumTransactionApi,
} from 'services/AddFinancialTransaction/api';
import * as LoaderActions from 'components/Loader/actions';
import { open } from 'utils/notification';
import translate from 'utils/translate';
import { createStandardSaga } from 'utils/createStandardSaga';

import * as Types from './types';
import * as AddTransactionsActions from './actions';

function* addTransactionSaga({ payload }) {
  yield put(LoaderActions.showLoader());
  try {
    const result = yield call(addFinancialTransaction, payload);
    if (result.data) {
      yield put(AddTransactionsActions.addTransactionsSuccess(result.data));
      open(
        'success',
        '',
        translate('addFinancialTransaction:transactionSuccessMessage'),
      );
    } else {
      open(
        'warn',
        '',
        translate('addFinancialTransaction:transactionWarningMessage'),
      );
    }
  } catch (error) {
    yield put(AddTransactionsActions.addTransactionsError(error.message));
  }
  yield put(LoaderActions.hideLoader());
}

const savePremiumTransactionSaga = createStandardSaga({
  callApi: savePremiumTransactionApi,
  successAction: AddTransactionsActions.savePremiumTransactionsSuccess,
  errorAction: AddTransactionsActions.savePremiumTransactionsError,
  successMessage: 'addFinancialTransaction:transactionSuccessMessage',
});

export function* rootSaga() {
  yield takeLatest(Types.ADD_TRANSACTION_REQUEST, addTransactionSaga);
  yield takeLatest(
    Types.SAVE_PREMIUM_TRANSACTION_REQUEST,
    savePremiumTransactionSaga,
  );
}

export default rootSaga;
