import { validatePhone } from 'utils/validations';
import translate from 'utils/translate';

const validationRules = {
  name: {
    required: translate('requiredFieldText'),
  },
  phone: {
    required: translate('requiredFieldText'),
    validate: value => {
      const isValidPhone = validatePhone(value);

      return isValidPhone || translate('phoneValid');
    },
  },
  commissionRate: {
    required: translate('requiredFieldText'),
  },
  label: {},
  minDeliveryTime: {
    required: translate('requiredFieldText'),
  },
  maxDeliveryTime: {
    required: translate('requiredFieldText'),
  },
  minBasketAmount: {},
  cashOnDelivery: {},
  scheduledDeliveryOption: {},
  operationalManager: {
    required: translate('requiredFieldText'),
  },
  regionManager: {
    required: translate('requiredFieldText'),
  },
  firm: {
    required: translate('requiredFieldText'),
  },
  brand: {
    required: translate('requiredFieldText'),
  },
  city: {
    required: translate('requiredFieldText'),
  },
  district: {
    required: translate('requiredFieldText'),
  },
  town: {
    required: translate('requiredFieldText'),
  },
  address: {
    required: translate('requiredFieldText'),
    validate: value => {
      const isValidAddress = value.length >= 11;

      return isValidAddress || translate('vendor:validAddress');
    },
  },
  searchAddress: {
    required: translate('requiredFieldText'),
  },
  isExchangeFee: {
    required: translate('requiredFieldText'),
  },
};

export { validationRules };
