import { createAction } from 'utils/createAction';

import * as Types from './types';

// list brands
export const brandListRequest = createAction(Types.BRAND_LIST_REQUEST);
export const brandListSuccess = createAction(Types.BRAND_LIST_SUCCESS);
export const brandListError = createAction(Types.BRAND_LIST_SUCCESS);

// add new brand
export const addBrandRequest = createAction(Types.ADD_BRAND_REQUEST);
export const addBrandSuccess = createAction(Types.ADD_BRAND_SUCCESS);
export const addBrandError = createAction(Types.ADD_BRAND_ERROR);

// update brand
export const updateBrandRequest = createAction(Types.EDIT_BRAND_REQUEST);
export const updateBrandSuccess = createAction(Types.EDIT_BRAND_SUCCESS);
export const updateBrandError = createAction(Types.EDIT_BRAND_SUCCESS);

// delete brand
export const deleteBrandRequest = createAction(Types.DELETE_BRAND_REQUEST);
export const deleteBrandSuccess = createAction(Types.DELETE_BRAND_SUCCESS);
export const deleteBrandError = createAction(Types.DELETE_BRAND_ERROR);

// set brand id
// Bunu Sil
export const setBrandId = createAction(Types.SET_BRAND_ID);

// get brand product list

export const brandProductListRequest = createAction(
  Types.BRAND_PRODUCT_LIST_REQUEST,
);
export const brandProductListSuccess = createAction(
  Types.BRAND_PRODUCT_LIST_SUCCESS,
);
export const brandProductListError = createAction(
  Types.BRAND_PRODUCT_LIST_ERROR,
);

export const brandProductListSave = createAction(Types.BRAND_PRODUCT_LIST_SAVE);
export const brandProductListSaveSuccess = createAction(
  Types.BRAND_PRODUCT_LIST_SAVE_SUCCESS,
);
export const brandProductListSaveError = createAction(
  Types.BRAND_PRODUCT_LIST_SAVE_ERROR,
);
