import styled from 'styled-components';
import { Input } from '@getir/web-components';
import { Table } from 'antd';

export const StyledTable = styled(Table)`
  .ant-table-title {
    display: flex;
    justify-content: flex-end;
    padding: 0 0 24px 0;
  }

  .ant-table-thead {
    tr {
      th {
        background: #eaeaea !important;
      }
    }
  }

  .ant-space,
  .ant-space-item {
    width: 100%;
  }

  ${Input.S.Input} {
    border: none;
  }
`;
