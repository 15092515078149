import _ from 'lodash';

export const findIfIntervalsAreEmptyForDay = intervals => {
  const numberOfIntervals = intervals.length;
  for (let i = 0; i < numberOfIntervals; i += 1) {
    const interval = intervals[i];
    if (!findIfIntervalIsEmpty(interval)) {
      return false;
    }
  }

  return true;
};

export const findIfIntervalIsEmpty = interval => {
  const { startTime, endTime } = interval;

  return _.isNull(startTime) || _.isNull(endTime);
};

export const findIsIntervalMixed = interval =>
  !interval.startTime !== !interval.endTime;

export const getEmptyInterval = () => ({
  startTime: null,
  endTime: null,
});
