import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/v1/Button';

import { CloseIcon, InfoIcon } from '../Icons';

import * as S from './styles';

const Modal = ({
  children,
  headerOptions,
  footerOptions,
  onClose,
  isOpen,
  closable,
  width,
  maskClosable,
}) => {
  const header = useMemo(
    () =>
      headerOptions.customHeader || (
        <S.ModalHeader>
          <S.HeaderText>{headerOptions.title}</S.HeaderText>
          {headerOptions.closeButton && (
            <Button
              kind="secondary"
              onClick={() => {
                onClose();
              }}
              style={{ padding: 0 }}
            >
              <CloseIcon fill="#191919" />
            </Button>
          )}
        </S.ModalHeader>
      ),
    [headerOptions, onClose],
  );

  const footer = useMemo(() => {
    if (footerOptions.customFooter) {
      return footerOptions.customFooter;
    }
    if (!footerOptions.visible) {
      return null;
    }

    return (
      <S.ModalFooter>
        {!!footerOptions.infoText && (
          <S.FooterInfoContainer>
            <InfoIcon width={14} height={14} fill="#5D3EBC" />
            <S.FooterInfoText>{footerOptions.infoText}</S.FooterInfoText>
          </S.FooterInfoContainer>
        )}
        <Button
          key="cancelButtonModal"
          onClick={footerOptions.onCancelClick || onClose}
          kind="secondary"
        >
          {footerOptions.cancelText}
        </Button>
        <Button
          key="okayButtonModal"
          kind="primary"
          onClick={footerOptions.onOkClick}
        >
          {footerOptions.okText}
        </Button>
      </S.ModalFooter>
    );
  }, [footerOptions, onClose]);

  return (
    <S.AntdModal
      destroyOnClose
      $headerStyle={headerOptions.headerStyle}
      footer={footer}
      maskClosable={maskClosable}
      closable={closable}
      onCancel={() => {
        onClose();
      }}
      title={header}
      style={{
        mask: {
          backgroundColor: 'rgba(46, 38, 68, 0.85)',
          backdropFilter: 'blur(1px)',
        },
      }}
      open={isOpen}
      width={width}
    >
      {children}
    </S.AntdModal>
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  headerOptions: PropTypes.shape({
    title: PropTypes.string,
    closeButton: PropTypes.bool,
    customHeader: PropTypes.node,
    headerStyle: PropTypes.object,
  }),
  footerOptions: PropTypes.shape({
    customFooter: PropTypes.node,
    okText: PropTypes.string,
    cancelText: PropTypes.string,
    infoText: PropTypes.string,
    onOkClick: PropTypes.func,
    onCancelClick: PropTypes.func,
    visible: PropTypes.bool,
  }),
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  closable: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maskClosable: PropTypes.bool,
};

Modal.defaultProps = {
  headerOptions: {
    title: '',
    closeButton: true,
    customHeader: null,
    headerStyle: null,
  },
  width: '80%',
  closable: false,
  maskClosable: true,
  footerOptions: {
    customFooter: null,
    okText: 'Save',
    cancelText: 'Cancel',
    infoText: '',
    onOkClick: () => {},
    onCancelClick: () => {},
    visible: true,
  },
  onClose: () => {},
};

export default Modal;
