const validations = t => ({
  financialType: {
    required: t('requiredFieldText'),
  },
  amount: {
    required: t('requiredFieldText'),
  },
  note: {},
  premiumFile: {
    required: t('requiredFieldText'),
  },
  documentDate: {
    required: t('requiredFieldText'),
  },
});

export { validations };
