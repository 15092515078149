import { apiGateway } from 'constants/services';

export default {
  getCities: `${apiGateway}/header-service/get-all-cities`,
  getVendors: `${apiGateway}/header-service/get-all-firms-and-vendors-by-city-id`,
  updateVendorStatus: (vendorId, isOpen) =>
    `${apiGateway}/header-service/switch-on-off-vendor/${vendorId}/${isOpen}`,
  getWorkingHoursInfo: vendorId =>
    `${apiGateway}/header-service/working-hours/${vendorId}`,
  getVendorInfo: vendorId =>
    `${apiGateway}/vendor-service/user/get/${vendorId}`,
  getVendorOpenStatus: vendorId =>
    `${apiGateway}/vendor-service/user/get-vendor-is-open/${vendorId}`,
};
