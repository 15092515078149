import styled from 'styled-components';

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 16px;
  width: 100%;
`;

export const HeaderLeft = styled.div``;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f0f1f3;
  border-bottom: 1px solid #f0f1f3;
  width: 100%;
  height: 100%;
`;

export const MessageDateContainer = styled.div`
  color: #191919;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  position: absolute;
  top: 16px;
  ${({ $isMe }) => ($isMe ? 'left: 16px;' : 'right: 16px;')}
`;

export const MessageContent = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  padding: 0px;
  margin: 0px;
`;

export const ErrorMessage = styled.div`
  color: #d13333;
  fill: #d13333;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 4px;
`;

export const MessageHeaderRight = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const MessageContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-width: 30%;
  padding: 24px;
  padding-top: 40px;
  ${({ $isMe }) =>
    $isMe
      ? `border-radius: 8px 8px 0px 8px;
         background: #F3F0FE;
         aling-items: flex-start;

         
         `
      : `border-radius: 8px 8px 8px 0px; 
         background:  #F0F1F3;
         aling-items: flex-end;
         `}
`;

export const MessageWrapper = styled.div`
  width: 100%;
  display: flex;
  ${({ $isMe }) => ($isMe ? 'justify-content: flex-end;' : '')}
  align-items: center;
`;
