import { useReducer } from 'react';

const SetReducerActionType = {
  ADD: 'ADD',
  SET: 'SET',
  REMOVE: 'REMOVE',
};

const setReducer = (state, action) => {
  switch (action.type) {
    case SetReducerActionType.ADD: {
      state.add(action.value);

      return new Set(state);
    }
    case SetReducerActionType.SET: {
      return new Set(action.value);
    }
    case SetReducerActionType.REMOVE: {
      state.delete(action.value);

      return new Set(state);
    }
    default: {
      return state;
    }
  }
};

export default function useSet(initialValue) {
  const [setValue, dispatch] = useReducer(setReducer, new Set(initialValue));

  const add = value => dispatch({ type: SetReducerActionType.ADD, value });
  const remove = value =>
    dispatch({ type: SetReducerActionType.REMOVE, value });
  const has = value => setValue.has(value);
  const list = () => Array.from(setValue);
  const set = value => dispatch({ type: SetReducerActionType.SET, value });

  return { add, remove, has, list, set };
}
