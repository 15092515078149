import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { SearchOutlined } from '@ant-design/icons';

import Input from 'components/v1/Input';

const SearchArea = ({ searchTerm, setSearchTerm }) => {
  const { t } = useTranslation(['vendorproduct']);

  return (
    <Input
      placeholder={t('vendorproduct:productsearch')}
      size="large"
      prefix={<SearchOutlined />}
      value={searchTerm}
      onChange={evt => setSearchTerm(evt.target.value)}
      style={{
        width: '300px',
      }}
    />
  );
};

SearchArea.defaultProps = {
  searchTerm: '',
  setSearchTerm: () => {},
};

SearchArea.propTypes = {
  searchTerm: PropTypes.string,
  setSearchTerm: PropTypes.func,
};

export default SearchArea;
