import { apiGateway } from 'constants/services';

export default {
  listVendors: `${apiGateway}/vendor-service/vendors/firm-vendors`,
  addVendor: `${apiGateway}/vendor-service/admin/createVendor`,
  updateVendor: `${apiGateway}/vendor-service/admin/updateVendor`,
  deleteVendor: vendorId =>
    `${apiGateway}/vendor-service/admin/deleteVendor/${vendorId}`,
  closeForSales: `${apiGateway}/vendor-service/admin/closeForSales`,
  openForSale: vendorId =>
    `${apiGateway}/vendor-service/admin/openForSales/${vendorId}`,
  getDistricts: cityId =>
    `${apiGateway}/header-service/get-all-towns-by-cityId/${cityId}`,
  getTowns: townId =>
    `${apiGateway}/header-service/get-all-districts-by-townId/${townId}`,
  getBrands: firmId => `${apiGateway}/brand-service/get/all/${firmId}`,
  getFirms: `${apiGateway}/firm-service/admin/get-all`,
  getOperationalManagers: `${apiGateway}/user-service/roles/role/users`,
  getRegionManagers: `${apiGateway}/user-service/roles/role/users`,
};
