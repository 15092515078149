import React from 'react';
import i18next from 'i18next';
import { Space } from 'antd';

import SelectBox from 'components/v1/SelectBox';
import Button from 'components/v1/Button';

import {
  defaultStartTime,
  defaultEndTime,
} from './constants/defaultWorkingHours';
import { selectboxOptions } from './selectboxOptions';

const days = {
  1: 'monday',
  2: 'tuesday',
  3: 'wednesday',
  4: 'thursday',
  5: 'friday',
  6: 'saturday',
  7: 'sunday',
};

const getColumns = (
  editActive,
  setEditActive,
  setDayData,
  setToAllDays,
  closeDay,
  saveWorkingHours,
  cancelEvent,
  data,
) => [
  {
    title: ' ',
    width: 200,
    dataIndex: 'dayOfWeek',
    key: 'dayOfWeek',
    render: text => `${text ? i18next.t(days[text]) : ''}`,
  },
  {
    title: i18next.t('workinghours:startHour'),
    width: 200,
    dataIndex: 'startTime',
    key: 'startTime',
    render: (text, row, index) => {
      if (text === null && !editActive) {
        return i18next.t('workinghours:todayIsClose');
      }

      if (text === null && editActive) {
        return {
          children: (
            <Space style={{ justifyContent: 'center', width: '100%' }}>
              {i18next.t('workinghours:closedAllDay')}
            </Space>
          ),
          props: {
            colSpan: 2,
          },
        };
      }

      if (index < 7 && editActive) {
        return (
          <SelectBox
            options={selectboxOptions}
            size="medium"
            labelInValue
            onChange={val => setDayData(index, 'startTime', val)}
            value={selectboxOptions.find(item => item.value === text)}
          ></SelectBox>
        );
      }
      if (index === 7 && editActive) {
        return {
          children: (
            <Button
              style={{
                width: '100%',
              }}
              kind="primary"
              onClick={saveWorkingHours}
            >
              {i18next.t('common:save')}
            </Button>
          ),
          props: {
            colSpan: 2,
          },
        };
      }

      return <div>{text}</div>;
    },
  },
  {
    title: i18next.t('workinghours:endHour'),
    width: 200,
    dataIndex: 'endTime',
    key: 'endTime',
    render: (text, row, index) => {
      if (text === null && !editActive) {
        return i18next.t('workinghours:todayIsClose');
      }

      if (text === null && editActive) {
        return {
          props: {
            colSpan: 0,
          },
        };
      }

      if (editActive && index < 7) {
        return (
          <SelectBox
            options={selectboxOptions}
            size="medium"
            onChange={val => setDayData(index, 'endTime', val)}
            labelInValue
            value={selectboxOptions.find(item => item.value === text)}
          ></SelectBox>
        );
      }

      if (index === 7 && editActive)
        return (
          <Button kind="secondary" onClick={cancelEvent} style={{ width: 138 }}>
            {i18next.t('common:cancel')}
          </Button>
        );

      return <div>{text}</div>;
    },
  },
  {
    title: ' ',
    dataIndex: 'rowAction',
    key: 'rowAction',
    render: (text, row, index) => {
      if (row.startTime === null && editActive) {
        return (
          <Button
            style={{ width: 138 }}
            onClick={() => {
              setDayData(index, 'startTime', { value: defaultStartTime });
              setDayData(index, 'endTime', { value: defaultEndTime });
            }}
          >
            {i18next.t('common:on')}
          </Button>
        );
      }

      if (index < 7 && editActive) {
        return (
          <Button onClick={() => closeDay(index)} kind="secondary">
            {i18next.t('workinghours:closeAllDay')}
          </Button>
        );
      }

      return <div></div>;
    },
  },
  {
    title: ' ',
    dataIndex: 'setAllAction',
    key: 'setAllAction',
    width: 220,
    render: (text, row, index) => {
      if (row.startTime === null) return <div></div>;

      if (editActive && index === 0) {
        return (
          <Button
            kind="secondary"
            onClick={() => {
              setToAllDays();
            }}
          >
            {i18next.t('workinghours:applyToAllDays')}
          </Button>
        );
      }

      return <div></div>;
    },
  },
  {
    title: () => {
      if (!editActive && data && data.length > 0) {
        return (
          <Button
            style={{ float: 'right' }}
            kind="primary"
            onClick={() => setEditActive(!editActive)}
          >
            {i18next.t('common:edit')}
          </Button>
        );
      }

      return <div></div>;
    },
  },
];

export { getColumns };
