import { findIfIntervalIsEmpty } from './common';

const findIsDayEmpty = dayIntervals =>
  dayIntervals.every(findIfIntervalIsEmpty);

export default function getAlternativeMapIntervalsRequestBody(
  alternativeMapIntervals,
) {
  const processedAlternativeMapIntervals = alternativeMapIntervals
    .filter(mapInterval => !findIsDayEmpty(mapInterval.intervals))
    .map(mapInterval => {
      const { dayOfWeek, intervals } = mapInterval;
      const modifiedIntervals = [];
      let lastIntervalIndex;
      for (let i = intervals.length - 1; i >= 0; i -= 1) {
        if (!findIfIntervalIsEmpty(intervals[i])) {
          lastIntervalIndex = i;
          break;
        }
      }
      for (let i = 0; i <= lastIntervalIndex; i += 1) {
        let interval = intervals[i];
        if (findIfIntervalIsEmpty(interval)) {
          interval = null;
        }
        modifiedIntervals.push(interval);
      }

      return {
        dayOfWeek,
        intervals: modifiedIntervals,
      };
    });

  const requestBody = {
    alternativeMapIntervals: processedAlternativeMapIntervals,
  };

  return requestBody;
}
