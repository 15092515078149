import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 48px;
  margin-bottom: 24px;

  button {
    padding: 8px 16px;
    font-size: 12px;
    height: 100%;
    font-weight: 700;
  }
`;

export const Title = styled.h1`
  font-size: 22px;
  color: #5d3ebc;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  margin: 0;
`;
