import styled from 'styled-components';

import breakpoint from 'constants/breakpoint';

export const StyledForm = styled.form`
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 50%;

  @media (max-width: ${breakpoint.TABLET.MAX}px) {
    width: 100%;
  }

  > div {
    width: 100%;
  }
  label {
    width: 100%;
  }
  > button {
    width: 50%;
  }
`;
