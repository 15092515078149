import { postRequest, getRequest } from 'lib/axios';

import api from './apiRoutes';

export const getTransactionsApi = params => {
  const { page, size, ...rest } = params;

  return postRequest(api.getTransactions({ page, size }), rest);
};

export const getDetailedTransactionsApi = params => {
  const { page, size, transactionOrderType: orderType, ...rest } = params;

  return postRequest(
    api.getDetailedTransactions({
      page,
      size,
    }),
    {
      orderType,
      ...rest,
    },
  );
};

export const getTransactionsDetailApi = args =>
  getRequest(api.getTransactionDetail(args));

export const exportExcelDetailApi = args =>
  getRequest(api.exportExcelDetail(args));

export const exportExcelFinancials = args =>
  postRequest(api.exportExcelFinancials, args);
