import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Col, Divider, Space } from 'antd';
import { get, noop } from 'lodash';

import SelectBoxWrapper from 'components/SelectBoxWrapper';
import SelectBoxAntd from 'components/Antd/FormElements/SelectBox';
import * as BrandActions from 'containers/Stores/Brands/actions';
import { FullWidthButton } from 'common/style';
import { getGeoMapIntervalBasedOnStateKey } from 'containers/GeoMap/common';

import useGeoMapTranslation from '../../useGeoMapTranslation';
import { getGeoMapIntervalOptions } from '../../common';

import { validationRules } from './validationRules';
import { extractBrandOptionsFrom } from './extractBrandOptionsFrom';

function Filter({
  geomapStateKey,
  Actions = {
    setGeomapInterval: noop,
    setGeomapBrand: noop,
    setGeomapCity: noop,
    setGeomapNeighbourhood: noop,
    setGeomapCenter: noop,
    geomapGetRequest: noop,
    geomapBannedVendorsRequest: noop,
    geomapGetNeighbourhoodRequest: noop,
    setSelectedNeighbourhood: noop,
    geomapGetDistrictRequest: noop,
    geomapGetVendors: noop,
    setEditedMap: noop,
    setSelectedVendor: noop,
    setSelectedDistrict: noop,
    geomapGetNeighbourhoodSuccess: noop,
  },
}) {
  const { t } = useGeoMapTranslation();
  const dispatch = useDispatch();
  const cities = useSelector(state => state.mainHeader.cities);
  const brands = useSelector(state => state.brand.brands);
  const GeoMapInterval = getGeoMapIntervalBasedOnStateKey(geomapStateKey);
  const intervals = getGeoMapIntervalOptions(t, GeoMapInterval);

  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
  } = useForm({
    mode: 'all',
    defaultValues: {
      interval: GeoMapInterval.MAIN_HOURS,
    },
  });

  const setGeomapBrand = useCallback(
    value => dispatch(Actions.setGeomapBrand(value)),
    [dispatch, Actions],
  );
  const setGeomapCity = useCallback(
    value => {
      const cityWithCountry = {
        ...value,
        country: cities.find(city => city.value === value.value).country,
      };
      dispatch(Actions.setGeomapCity(cityWithCountry));
    },
    [dispatch, cities, Actions],
  );
  const setGeomapInterval = useCallback(
    ({ value }) => dispatch(Actions.setGeomapInterval(value)),
    [dispatch, Actions],
  );

  useEffect(() => {
    if (!brands) dispatch(BrandActions.brandListRequest());
  }, [dispatch, brands]);

  useEffect(() => {
    setGeomapInterval({ value: getValues('interval') });
  }, [getValues, setGeomapInterval]);

  const onSubmit = formData => {
    const { country, center } = cities.find(
      city => city.value === formData.cityId.value,
    );
    const commonRequestPayload = {
      brandId: formData.brandId.value,
      city: formData.cityId.value,
      country,
      intervalType: get(formData, 'interval.value', formData.interval),
    };

    const getVendorsReqData = {
      brandId: formData.brandId.value,
      cityId: formData.cityId.value,
    };
    dispatch(Actions.geomapGetVendors(getVendorsReqData));
    dispatch(Actions.geomapGetRequest(commonRequestPayload));
    dispatch(Actions.setGeomapCenter(center));
    dispatch(
      Actions.geomapGetDistrictRequest({
        cityId: formData.cityId.value,
        geojson: true,
      }),
    );
    dispatch(Actions.setEditedMap(undefined));
    dispatch(Actions.setSelectedVendor(undefined));
    dispatch(Actions.setSelectedDistrict(undefined));
    dispatch(Actions.geomapGetNeighbourhoodSuccess([]));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Col span={24}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <SelectBoxAntd
            fieldName="brandId"
            errors={errors}
            labelInValue
            onChange={setGeomapBrand}
            rules={validationRules.brandId}
            placeholder={t('brands')}
            options={extractBrandOptionsFrom(brands)}
            control={control}
            showSearch
          />
          <SelectBoxAntd
            fieldName="cityId"
            errors={errors}
            labelInValue
            onChange={setGeomapCity}
            rules={validationRules.cityId}
            placeholder={t('cities')}
            options={cities}
            control={control}
            showSearch
          />
          <SelectBoxWrapper
            fieldName="interval"
            control={control}
            onChange={setGeomapInterval}
            rules={validationRules.interval}
            placeholder={t('interval')}
            errors={errors}
            options={intervals}
          />
          <FullWidthButton type="primary">{t('button.get')}</FullWidthButton>
        </Space>
        <Divider />
      </Col>
    </form>
  );
}

Filter.propTypes = {
  geomapStateKey: PropTypes.string.isRequired,
  Actions: PropTypes.shape({
    setGeoMapInterval: PropTypes.func,
    setGeoMapBrand: PropTypes.func,
    setGeoMapCity: PropTypes.func,
    setGeomapCenter: PropTypes.func,
    geoMapGetRequest: PropTypes.func,
    geomapGetDistrictRequest: PropTypes.func,
    geomapGetVendors: PropTypes.func,
    setEditedMap: PropTypes.func,
    setSelectedVendor: PropTypes.func,
    setSelectedDistrict: PropTypes.func,
    geomapGetNeighbourhoodSuccess: PropTypes.func,
  }).isRequired,
};

export default Filter;
