const PAYMENT_TYPE_TRANSLATEKEY = Object.freeze({
  ONLINE_PAYMENT: 'common:onlinepayment',
  ONLINE_CREDIT_CARD: 'common:onlinecreditcard',
  ONLINE_BKM: 'common:onlinebkm',
  CREDIT_OR_DEBIT_CARD_AT_DELIVERY: 'common:creditatdelivery',
  CASH_AT_DELIVERY: 'common:cashatdelivery',
});

const PAYMENT_TYPE = Object.freeze({
  ONLINE_CREDIT_CARD: 1,
  ONLINE_BKM: 2,
  CREDIT_OR_DEBIT_CARD_AT_DELIVERY: 3,
  CASH_AT_DELIVERY: 4,
});

const PAYMENT_TYPE_REVERSE = Object.freeze({
  1: 'ONLINE_CREDIT_CARD',
  2: 'ONLINE_BKM',
  3: 'CREDIT_OR_DEBIT_CARD_AT_DELIVERY',
  4: 'CASH_AT_DELIVERY',
});

const PAYMENT_STATUS = Object.freeze({
  1: 'PENDING',
  2: 'PAID',
  3: 'FAILED',
});

const PAYMENT_STATUS_TRANSLATEKEY = Object.freeze({
  PENDING: 'common:pending',
  PAID: 'common:paid',
  FAILED: 'common:failed',
});

export {
  PAYMENT_TYPE,
  PAYMENT_TYPE_TRANSLATEKEY,
  PAYMENT_TYPE_REVERSE,
  PAYMENT_STATUS_TRANSLATEKEY,
  PAYMENT_STATUS,
};
