// Active Orders Types
export const GET_ACTIVE_ORDERS_REQUEST = 'GET_ACTIVE_ORDERS_REQUEST';
export const GET_ACTIVE_ORDERS_SUCCESS = 'GET_ACTIVE_ORDERS_SUCCESS';
export const GET_ACTIVE_ORDERS_ERROR = 'GET_ACTIVE_ORDERS_ERROR';

// Completed Orders Types
export const GET_COMPLETED_ORDERS_REQUEST = 'GET_COMPLETED_ORDERS_REQUEST';
export const GET_COMPLETED_ORDERS_SUCCESS = 'GET_COMPLETED_ORDERS_SUCCESS';
export const GET_COMPLETED_ORDERS_ERROR = 'GET_COMPLETED_ORDERS_ERROR';

// Canceled Orders Types
export const GET_CANCELED_ORDERS_REQUEST = 'GET_CANCELED_ORDERS_REQUEST';
export const GET_CANCELED_ORDERS_SUCCESS = 'GET_CANCELED_ORDERS_SUCCESS';
export const GET_CANCELED_ORDERS_ERROR = 'GET_CANCELED_ORDERS_ERROR';

// Scheduled Orders Types
export const GET_SCHEDULED_ORDERS_REQUEST = 'GET_SCHEDULED_ORDERS_REQUEST';
export const GET_SCHEDULED_ORDERS_SUCCESS = 'GET_SCHEDULED_ORDERS_SUCCESS';
export const GET_SCHEDULED_ORDERS_ERROR = 'GET_SCHEDULED_ORDERS_ERROR';

// Change Order Types
export const CHANGE_ORDER_STATUS_REQUEST = 'CHANGE_ORDER_STATUS_REQUEST';
export const CHANGE_ORDER_STATUS_SUCCESS = 'CHANGE_ORDER_STATUS_SUCCESS';
export const CHANGE_ORDER_STATUS_ERROR = 'CHANGE_ORDER_STATUS_ERROR';

// Get User Popup
export const GET_USER_POPUP_REQUEST = 'GET_USER_POPUP_REQUEST';
export const GET_USER_POPUP_SUCCESS = 'GET_USER_POPUP_SUCCESS';
export const GET_USER_POPUP_ERROR = 'GET_USER_POPUP_ERROR';

// Send Popup Feedback
export const SEND_POPUP_FEEDBACK_REQUEST = 'SEND_POPUP_FEEDBACK_REQUEST';
export const SEND_POPUP_FEEDBACK_SUCCESS = 'SEND_POPUP_FEEDBACK_SUCCESS';
export const SEND_POPUP_FEEDBACK_ERROR = 'SEND_POPUP_FEEDBACK_ERROR';

export const CHANGE_ORDER_AFFECT_VENDOR = 'CHANGE_ORDER_AFFECT_VENDOR';

export const CHANGE_DELAYED_ORDER_LIST = 'CHANGE_DELAYED_ORDER_LIST';
export const ADD_ORDERS_TO_DELAYED_LIST = 'ADD_ORDERS_TO_DELAYED_LIST';
export const RESET_DELAYED_LIST = 'RESET_DELAYED_LIST';
export const REMOVE_ORDERS_FROM_DELAYED_LIST =
  'REMOVE_ORDERS_FROM_DELAYED_LIST';

export const SOCKET_ORDER_ADD = 'SOCKET_ORDER_ADD';
