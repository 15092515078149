import { createAction } from 'utils/createAction';

import * as Types from './types';

export const closeBillingModal = createAction(Types.BILLING_DETAIL_CLOSE);
export const openBillingModal = createAction(Types.BILLING_DETAIL_OPEN);

export const uploadFileRequest = createAction(
  Types.BILLING_DETAIL_UPLOAD_REQUEST,
);
export const uploadFileSuccess = createAction(
  Types.BILLING_DETAIL_UPLOAD_SUCCESS,
);
export const uploadFileError = createAction(Types.BILLING_DETAIL_UPLOAD_ERROR);
export const saveButtonDisabled = createAction(
  Types.BILLING_DETAIL_SAVE_BUTTON_DISABLED,
);
export const cancelButtonDisabled = createAction(
  Types.BILLING_DETAIL_CANCEL_BUTTON_DISABLED,
);

export const isUploading = createAction(Types.BILLING_DETAIL_IS_UPLOADING);
export const isSuccessful = createAction(Types.BILLING_DETAIL_IS_SUCCESSFUL);

// Delete Image Actions
export const deleteImageRequest = createAction(
  Types.BILLING_DETAIL_DELETE_IMAGE_REQUEST,
);
export const deleteImageSuccess = createAction(
  Types.BILLING_DETAIL_DELETE_IMAGE_SUCCESS,
);
export const deleteImageError = createAction(
  Types.BILLING_DETAIL_DELETE_IMAGE_ERROR,
);

// Reset All states
export const resetStates = createAction(Types.BILLING_DETAIL_RESET_STATES);

export const adminApproveRequest = createAction(
  Types.BILLING_DETAIL_ADMIN_APPROVE_REQUEST,
);

export const setModalMode = createAction(Types.BILLING_DETAIL_SET_MODAL_MODE);

// Admin refuse
export const adminRefuseRequest = createAction(
  Types.BILLING_DETAIL_ADMIN_REFUSE_REQUEST,
);
export const adminRefuseSuccess = createAction(
  Types.BILLING_DETAIL_ADMIN_REFUSE_SUCCESS,
);
export const adminRefuseError = createAction(
  Types.BILLING_DETAIL_ADMIN_REFUSE_ERROR,
);

// Get Billing Image
export const getBillingImageRequest = createAction(
  Types.BILLING_DETAIL_GET_BILLING_IMAGE_REQUEST,
);
export const getBillingImageSuccess = createAction(
  Types.BILLING_DETAIL_GET_BILLING_IMAGE_SUCCESS,
);
export const getBillingImageError = createAction(
  Types.BILLING_DETAIL_GET_BILLING_IMAGE_ERROR,
);

// Get Billing Detail
export const getBillingDetailRequest = createAction(
  Types.BILLING_DETAIL_BILLING_DETAIL_REQUEST,
);
export const getBillingDetailSuccess = createAction(
  Types.BILLING_DETAIL_BILLING_DETAIL_SUCCESS,
);
export const getBillingDetailError = createAction(
  Types.BILLING_DETAIL_BILLING_DETAIL_ERROR,
);
