import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Divider } from '@getir/web-components';
import { useDispatch, useSelector } from 'react-redux';
import { Space } from 'antd';

import * as CS from 'common/style';
import * as ROUTES from 'constants/routes';
import Layout from 'components/Layout';
import InputWrapper from 'components/InputWrapper';
import ArrowBackButton from 'components/ArrowBackButton';
import SelectBoxWrapper from 'components/Antd/FormElements/SelectBox';

import * as RolesActions from '../actions';

import * as S from './style';
import { validations } from './validations';

const defaultValues = {
  roleName: '',
  roleDesc: '',
  roleType: [],
};

const Add = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { data: roleGroupNames } = useSelector(
    state => state.authRoles.roleGroupNames,
  );

  const validationRules = validations(t);

  useEffect(() => {
    dispatch(RolesActions.getRoleGroupNamesRequest());
  }, [dispatch]);

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    mode: 'onBlur',
    defaultValues,
  });

  const onSubmit = formData => {
    const { roleName, roleDesc, roleGroupId } = formData;
    const data = {
      roleName,
      roleDesc,
      roleGroupNames: roleGroupId,
    };
    dispatch(RolesActions.createRoleRequest(data));
  };

  const handleOnClose = () => {
    reset();
  };

  return (
    <Layout>
      <CS.PageHeader>
        <Space>
          <ArrowBackButton route={ROUTES.AUTH_ROLES} size={14} />
          <CS.PageTitle>{t('authRolesPage:addNewRolePage.title')}</CS.PageTitle>
        </Space>
      </CS.PageHeader>
      <CS.PageBody>
        <S.FormBody>
          <S.NewRoleForm onSubmit={handleSubmit(onSubmit)}>
            <InputWrapper
              fieldName="roleName"
              control={control}
              rules={validationRules.roleName}
              placeholder={t(`authRolesPage:addNewRolePage.roleName`)}
              header={t(`authRolesPage:addNewRolePage.roleName`)}
              errors={errors}
            />
            <InputWrapper
              fieldName="roleDesc"
              control={control}
              rules={validationRules.desc}
              placeholder={t(`authRolesPage:addNewRolePage.desc`)}
              header={t(`authRolesPage:addNewRolePage.desc`)}
              errors={errors}
            />
            <SelectBoxWrapper
              fieldName="roleGroupId"
              control={control}
              rules={validationRules.roleType}
              placeholder={t(`authRolesPage:addNewRolePage.roleType`)}
              header={t(`authRolesPage:addNewRolePage.roleType`)}
              errors={errors}
              options={
                roleGroupNames &&
                roleGroupNames.map(role => ({
                  value: role.roleGroupName,
                  label: role.roleGroupName,
                }))
              }
              mode="multiple"
            />
            <Divider />
            <S.FormFooter>
              <Space size="small">
                <Button kind="secondary" onClick={handleOnClose}>
                  {t('giveUp')}
                </Button>
                <Button type="submit">{t('save')}</Button>
              </Space>
            </S.FormFooter>
          </S.NewRoleForm>
        </S.FormBody>
      </CS.PageBody>
    </Layout>
  );
};

export default Add;
