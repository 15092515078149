import React, { useEffect, useMemo } from 'react';
import { Empty } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReloadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { ORDER_STATUS } from 'constants/orderStatus';
import { PAYMENT_TYPE } from 'constants/paymentType';
import { MapModal } from 'components/MapModal/MapModal';
import { MissingProductModal } from 'components/MissingProductModal/MissingProductModal';
import CourierListModal from 'components/CourierListModal';
import OrderCancelModal from 'components/OrderCancelModal/OrderCancelModal';
import useUserInfo from 'hooks/useUserInfo';
import { rolesWithName } from 'constants/roles';
import Button from 'components/v1/Button';
import useResponsive from 'hooks/useResponsive';
import MobileOrdersPage from 'components/v1/MobileOrders';
import VendorStatus from 'components/v1/VendorStatus';
import { DURATION_TYPES } from 'constants/durationTypes';

import * as ActiveOrdersActions from '../actions';
import * as S from '../style';
import { getOrderType } from '../utils/getOrderType';

import { useActiveOrdersColumns } from './useActiveOrdersColumns';

const ActiveList = () => {
  const { t } = useTranslation();

  const activeOrdersList = useSelector(state => state.activeOrders.list);
  const vendorInfo = useSelector(state => state.mainHeader.vendorInfo);
  const selectedBusiness = useSelector(state => state.mainHeader.vendor);
  const { userInfo } = useUserInfo();

  const { isMobileView, isMiniTabletView, isTabletView } = useResponsive();
  const isUnderTabletView = isMobileView || isMiniTabletView || isTabletView;

  const { selectedVendor, columns } = useActiveOrdersColumns();

  const dispatch = useDispatch();

  const orderList = useMemo(() => {
    if (userInfo && userInfo.role === rolesWithName.CARRIER) {
      const { userId } = userInfo;
      const filteredOrderList = activeOrdersList.filter(
        order => order.courierId && order.courierId === userId,
      );

      return filteredOrderList;
    }

    return activeOrdersList;
  }, [activeOrdersList, userInfo]);

  useEffect(() => {
    const initialDelayedList = [];
    const timeoutList = [];

    orderList.forEach(order => {
      const now = dayjs.now().toISOString();
      const diffSeconds = dayjs(now).diff(
        dayjs(order.paymentDate),
        'seconds',
        DURATION_TYPES.DELAYED_ORDER,
      );
      const isDelayedOrder = diffSeconds >= DURATION_TYPES.DELAYED_ORDER;

      if (isDelayedOrder) {
        initialDelayedList.push(order);
      } else {
        const howMuchSeconds = DURATION_TYPES.DELAYED_ORDER - diffSeconds;

        const timeout = setTimeout(() => {
          const isOrderStillInActiveOrder = orderList.find(
            o => o.id === order.id,
          );
          if (isOrderStillInActiveOrder) {
            dispatch(ActiveOrdersActions.addOrderToDelayedList(order.id));
          }
        }, howMuchSeconds * 1000);

        timeoutList.push(timeout);
      }
    });
    dispatch(ActiveOrdersActions.changeDelayedOrderList(initialDelayedList));

    return () => {
      timeoutList.forEach(timeout => clearTimeout(timeout));
    };
  }, [dispatch, orderList]);

  const fetchData = () => {
    if (!selectedBusiness || !selectedBusiness.value) return;

    if (
      vendorInfo?.letterOfUndertaking?.isApprovedLU ||
      (!vendorInfo?.letterOfUndertaking && Object.keys(userInfo).length > 0)
    ) {
      const data = {
        statusList: [
          ORDER_STATUS.VERIFYING,
          ORDER_STATUS.PREPARING,
          ORDER_STATUS.ONWAY,
        ],
        paymentType: [
          PAYMENT_TYPE.ONLINE_CREDIT_CARD,
          PAYMENT_TYPE.ONLINE_BKM,
          PAYMENT_TYPE.CREDIT_OR_DEBIT_CARD_AT_DELIVERY,
          PAYMENT_TYPE.CASH_AT_DELIVERY,
        ],
        id: selectedBusiness && selectedBusiness.value,
        type: getOrderType(selectedBusiness),
      };

      dispatch(ActiveOrdersActions.getActiveOrdersRequest(data));
      dispatch(ActiveOrdersActions.resetDelayedList());
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [vendorInfo, , dispatch, userInfo]);

  return (
    <>
      {!isMobileView && (
        <>
          {isUnderTabletView && <VendorStatus />}
          <S.PageHeader>
            <Button kind="primary" onClick={fetchData}>
              <ReloadOutlined />
              {t('activeOrders:refreshButton')}
            </Button>
          </S.PageHeader>
        </>
      )}
      {isMobileView ? (
        <MobileOrdersPage isActive orders={orderList} />
      ) : (
        <>
          <S.StyledTable
            columns={columns}
            dataSource={orderList}
            scroll={{ x: 1500 }}
            bordered
            locale={{ emptyText: <Empty description={t('table.noData')} /> }}
            pagination={{
              size: 'small',
              position: ['bottomCenter'],
              defaultPageSize: 20,
              showSizeChanger: true,
            }}
            rowKey="id"
          />
          <MapModal vendor={selectedVendor} />
          <MissingProductModal vendor={selectedVendor} />
        </>
      )}
      <CourierListModal />
      <OrderCancelModal />
    </>
  );
};

export default ActiveList;
