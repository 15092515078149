export const CATALOGPRODUCT_LIST_REQUEST = 'CATALOGPRODUCT_LIST_REQUEST';
export const CATALOGPRODUCT_LIST_SUCCESS = 'CATALOGPRODUCT_LIST_SUCCESS';
export const CATALOGPRODUCT_LIST_ERROR = 'CATALOGPRODUCT_LIST_ERROR';

export const ADD_CATALOGPRODUCT_REQUEST = 'ADD_CATALOGPRODUCT_REQUEST';
export const ADD_CATALOGPRODUCT_SUCCESS = 'ADD_CATALOGPRODUCT_SUCCESS';
export const ADD_CATALOGPRODUCT_ERROR = 'ADD_CATALOGPRODUCT_ERROR';

export const EDIT_CATALOGPRODUCT_REQUEST = 'EDIT_CATALOGPRODUCT_REQUEST';
export const EDIT_CATALOGPRODUCT_SUCCESS = 'EDIT_CATALOGPRODUCT_SUCCESS';
export const EDIT_CATALOGPRODUCT_ERROR = 'EDIT_CATALOGPRODUCT_ERROR';

export const DELETE_CATALOGPRODUCT_REQUEST = 'DELETE_CATALOGPRODUCT_REQUEST';
export const DELETE_CATALOGPRODUCT_SUCCESS = 'DELETE_CATALOGPRODUCT_SUCCESS';
export const DELETE_CATALOGPRODUCT_ERROR = 'DELETE_CATALOGPRODUCT_ERROR';

export const CATALOGPRODUCT_BRAND_REQUEST = 'CATALOGPRODUCT_BRAND_REQUEST';
export const CATALOGPRODUCT_BRAND_SUCCESS = 'CATALOGPRODUCT_BRAND_SUCCESS';
export const CATALOGPRODUCT_BRAND_ERROR = 'CATALOGPRODUCT_BRAND_ERROR';
export const CATALOGPRODUCT_BRAND_RESET = 'CATALOGPRODUCT_BRAND_RESET';
