import getParams from 'utils/getParams';

import * as Types from './types';
import { loginSteps } from './constants';

const token = getParams('token');

const initialState = {
  user: {},
  forgotPassword: {
    status: undefined,
    payload: undefined,
  },
  newPassword: {
    status: undefined,
    payload: undefined,
  },
  error: {},
  step: !token ? loginSteps.Login : loginSteps.NewPassword,
  userPerms: {
    data: undefined,
    error: undefined,
  },
  allPerms: {
    data: undefined,
    error: undefined,
  },
};

function LoginReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.LOGIN_SUCCESS:
      return {
        ...state,
        user: payload,
      };
    case Types.LOGIN_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPassword: payload,
      };
    case Types.FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        newPassword: payload,
      };
    case Types.NEW_PASSWORD_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.UPDATE_STEP:
      return {
        ...state,
        step: payload,
      };
    case Types.GET_USER_PERMS_SUCCESS:
      return {
        ...state,
        userPerms: {
          data: payload,
        },
      };
    case Types.GET_USER_PERMS_ERROR:
      return {
        ...state,
        userPerms: {
          error: payload,
        },
      };
    case Types.GET_PERM_LIST_SUCCESS:
      return {
        ...state,
        allPerms: {
          data: payload,
        },
      };
    case Types.GET_PERM_LIST_ERROR:
      return {
        ...state,
        allPerms: {
          error: payload,
        },
      };
    default:
      return state;
  }
}

export default LoginReducer;
