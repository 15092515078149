import { Select } from 'antd';
import styled from 'styled-components';

export const SelectBoxPlaceholder = styled.span`
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 3px;
`;

export const SelectBox = styled(Select)`
  width: 100%;
  height: 52px;
`;
