import { apiGateway } from 'constants/services';

export default {
  getVendorThreads: `${apiGateway}/vendor-service/vendor-messages`,
  createNewThread: `${apiGateway}/vendor-service/vendor-messages`,
  getMessagesFromThread: threadId =>
    `${apiGateway}/vendor-service/vendor-messages/${threadId}`,
  sendNewMessageToThread: threadId =>
    `${apiGateway}/vendor-service/vendor-messages/${threadId}`,
  closeThread: threadId =>
    `${apiGateway}/vendor-service/vendor-messages/${threadId}/lock`,
  changeReadStatusThread: threadId =>
    `${apiGateway}/vendor-service/vendor-messages/${threadId}/status`,
};
