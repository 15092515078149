import { useTranslation } from 'react-i18next';

const useOrderListBrandsColumns = () => {
  const { t } = useTranslation(['catalogs']);

  return [
    {
      title: t('catalogs:productName'),
      dataIndex: 'productName',
      key: 'productName',
    },
  ];
};
export { useOrderListBrandsColumns };
