import { put, call } from 'redux-saga/effects';

import * as LoaderActions from 'components/Loader/actions';

import { open } from './notification';
import history from './history';
import translate from './translate';

export const createStandardSaga = ({
  callApi,
  successAction,
  errorAction,
  successPushRoute,
  successMessage,
  isSuccessMessageTitleVisible = true,
  isReloadThePage = false,
}) =>
  // eslint-disable-next-line
  function* ({ payload }) {
    yield put(LoaderActions.showLoader());
    try {
      const result = yield call(callApi, payload);
      if (result.data) {
        yield put(successAction(result.data.payload));

        if (successMessage)
          open(
            'success',
            isSuccessMessageTitleVisible
              ? translate('common:notif.successTitle')
              : '',
            translate(successMessage),
          );

        if (successPushRoute) history.push(successPushRoute);
        if (isReloadThePage) window.location.reload();
      }

      if (result.message) {
        open('warning', translate('common:notif.warnTitle'), result.message);
      }
    } catch (error) {
      yield put(errorAction(error.message));
    }
    yield put(LoaderActions.hideLoader());
  };
