import styled from 'styled-components';
import { Input, Divider } from '@getir/web-components';

import Table from 'components/v1/Table';

const TableHeaderWrapper = styled.div`
  display: grid;
  grid-gap: 10px;

  ${Input.S.Wrapper} {
    max-width: 270px;
  }

  ${Divider.S.Divider} {
    border: 1px solid rgba(0, 0, 0, 0.06);
  }

  form {
    display: grid;
    grid-gap: 10px;
  }

  .ant-space {
    justify-content: flex-end;
  }
`;

const TableInputFilterWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  grid-gap: 16px;
  align-items: center;
`;

const CatalogProductistTable = styled(Table)``;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export {
  TableHeaderWrapper,
  ListContainer,
  TableInputFilterWrapper,
  CatalogProductistTable,
};
