import React from 'react';
import PropTypes from 'prop-types';

import {
  AlignCenter,
  AlignLeft,
  Bold,
  Italic,
  OrderedList,
  Redo,
  Underline,
  Undo,
} from './MenuIcons';
import * as S from './styles';
import ImageExt from './ImageExt/ImageExt';
import LinkExt from './LinkExt/LinkExt';

const MenuBar = ({ editor }) => {
  if (!editor) return null;

  return (
    <S.MenuContainer>
      <S.MenuSection>
        <S.MenuButton
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={!editor.can().chain().focus().toggleBold().run()}
          $isActive={editor.isActive('bold')}
        >
          <Bold />
        </S.MenuButton>
        <S.MenuButton
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
          $isActive={editor.isActive('italic')}
        >
          <Italic />
        </S.MenuButton>
        <S.MenuButton
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          disabled={!editor.can().chain().focus().toggleUnderline().run()}
          $isActive={editor.isActive('underline')}
        >
          <Underline />
        </S.MenuButton>
      </S.MenuSection>
      <S.MenuSection>
        <S.MenuButton
          onClick={() => editor.chain().focus().setTextAlign('left').run()}
          disabled={!editor.can().chain().focus().setTextAlign('left').run()}
          $isActive={editor.isActive({ textAlign: 'left' })}
        >
          <AlignLeft />
        </S.MenuButton>
        <S.MenuButton
          onClick={() => editor.chain().focus().setTextAlign('center').run()}
          disabled={!editor.can().chain().focus().setTextAlign('center').run()}
          $isActive={editor.isActive({ textAlign: 'center' })}
        >
          <AlignCenter />
        </S.MenuButton>
        <S.MenuButton
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          disabled={!editor.can().chain().focus().toggleOrderedList().run()}
          $isActive={editor.isActive('orderedList')}
        >
          <OrderedList />
        </S.MenuButton>
      </S.MenuSection>
      <S.MenuSection>
        <ImageExt editor={editor} />
        <LinkExt editor={editor} />
      </S.MenuSection>
      <S.MenuSection>
        <S.MenuButton
          onClick={() => editor.chain().focus().undo().run()}
          disabled={!editor.can().chain().focus().undo().run()}
          $isActive={editor.can().chain().focus().undo().run()}
        >
          <Undo />
        </S.MenuButton>
        <S.MenuButton
          onClick={() => editor.chain().focus().redo().run()}
          disabled={!editor.can().chain().focus().redo().run()}
          $isActive={editor.can().chain().focus().redo().run()}
        >
          <Redo />
        </S.MenuButton>
      </S.MenuSection>
    </S.MenuContainer>
  );
};

MenuBar.propTypes = {
  editor: PropTypes.object.isRequired,
};

export default MenuBar;
