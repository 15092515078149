import { email } from '@sideway/address';

import translate from 'utils/translate';
import { validatePhone } from 'utils/validations';

const validationRules = {
  fullName: {
    required: translate('requiredFieldText'),
  },
  mobileNumber: {
    required: translate('requiredFieldText'),
    validate: value => {
      const isValidPhone = validatePhone(value);

      return isValidPhone || translate('phoneValid');
    },
  },
  email: {
    required: translate('requiredFieldText'),
    validate: value => {
      const isValidEmail = email.isValid(value);

      return isValidEmail || translate('emailValid');
    },
  },
  role: {
    required: translate('requiredFieldText'),
  },
};
export { validationRules };
