import * as Types from './types';

export function showLoader() {
  return {
    type: Types.SHOW_LOADER,
    payload: true,
  };
}

export function hideLoader() {
  return {
    type: Types.HIDE_LOADER,
    payload: false,
  };
}
