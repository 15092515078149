const MIN_PASSWORD_LENGTH = 4;
const MIN_NEW_PASSWORD_LENGTH = 7;
const MAX_PASSWORD_LENGTH = 16;

const loginSteps = {
  Login: 0,
  ForgotPassword: 1,
  ForgotPasswordResult: 2,
  NewPassword: 3,
  NewPasswordResult: 4,
};

export {
  MIN_PASSWORD_LENGTH,
  MIN_NEW_PASSWORD_LENGTH,
  MAX_PASSWORD_LENGTH,
  loginSteps,
};
