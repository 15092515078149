import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import { pick } from 'lodash';
import { useDispatch } from 'react-redux';

import Layout from 'components/Layout';
import * as CS from 'common/style';
import useUnmount from 'hooks/useUnmount';

import { CommonActionMethodNames, defaultGeomapActions } from './common';
import MapWrapper from './components/MapWrapper';
import AddArea from './components/AddArea';
import Filter from './components/Filter';
import useGeoMapTranslation from './useGeoMapTranslation';
import AreaAndVendorSelect from './components/AreaAndVendorSelect';
import VendorInfo from './components/VendorInfo';

const GeoMap = ({ geomapStateKey, GeomapActions = defaultGeomapActions }) => {
  const { t } = useGeoMapTranslation({
    isCommon: false,
  });
  const dispatch = useDispatch();

  useUnmount(() => {
    dispatch(GeomapActions.resetGeomapState());
  });

  return (
    <Layout>
      <CS.PageTitle>{t(`${geomapStateKey}.title`)}</CS.PageTitle>
      <CS.PageBody style={{ height: '100%' }}>
        <Row gutter={16}>
          <Col span={18}>
            <MapWrapper
              Actions={pick(GeomapActions, [
                CommonActionMethodNames.setSelectedVendor,
                CommonActionMethodNames.setEditedMap,
                CommonActionMethodNames.geomapGetNeighbourhoodRequest,
                CommonActionMethodNames.setSelectedDistrict,
              ])}
              geomapStateKey={geomapStateKey}
            />
            <AreaAndVendorSelect
              Actions={pick(GeomapActions, [
                CommonActionMethodNames.setSelectedVendor,
                CommonActionMethodNames.setNeighbourhoodisVisible,
                CommonActionMethodNames.setEditedMap,
                CommonActionMethodNames.setShowOnlySelectedVendor,
                CommonActionMethodNames.geomapGetDistrictRequest,
                CommonActionMethodNames.setSelectedDistrict,
                CommonActionMethodNames.geomapGetNeighbourhoodRequest,
                CommonActionMethodNames.geomapSaveRequest,
              ])}
              geomapStateKey={geomapStateKey}
            />
            <VendorInfo geomapStateKey={geomapStateKey} />
          </Col>
          <Col span={6}>
            <Filter
              geomapStateKey={geomapStateKey}
              Actions={pick(GeomapActions, [
                CommonActionMethodNames.setGeomapInterval,
                CommonActionMethodNames.setGeomapBrand,
                CommonActionMethodNames.setGeomapCity,
                CommonActionMethodNames.setGeomapCenter,
                CommonActionMethodNames.geomapGetRequest,
                CommonActionMethodNames.geomapBannedVendorsRequest,
                CommonActionMethodNames.geomapGetNeighbourhoodRequest,
                CommonActionMethodNames.setSelectedVendor,
                CommonActionMethodNames.geomapGetDistrictRequest,
                CommonActionMethodNames.geomapGetVendors,
                CommonActionMethodNames.setSelectedDistrict,
                CommonActionMethodNames.setSelectedVendor,
                CommonActionMethodNames.setEditedMap,
                CommonActionMethodNames.geomapGetNeighbourhoodSuccess,
              ])}
            />
            <AddArea
              geomapStateKey={geomapStateKey}
              Actions={pick(GeomapActions, [
                CommonActionMethodNames.geomapGetRequest,
                CommonActionMethodNames.geomapSaveRequest,
              ])}
            />
          </Col>
        </Row>
      </CS.PageBody>
    </Layout>
  );
};

GeoMap.propTypes = {
  geomapStateKey: PropTypes.string.isRequired,
  GeomapActions: PropTypes.shape({
    geomapGetRequest: PropTypes.func.isRequired,
    geomapSaveRequest: PropTypes.func.isRequired,
    setGeomapInterval: PropTypes.func.isRequired,
    setGeomapBrand: PropTypes.func.isRequired,
    setGeomapCity: PropTypes.func.isRequired,
    setGeomapCenter: PropTypes.func.isRequired,
    setSelectedVendor: PropTypes.func.isRequired,
    geomapGetNeighbourhoodRequest: PropTypes.func.isRequired,
  }).isRequired,
};

export default GeoMap;
