import React from 'react';
import { useTranslation } from 'react-i18next';

import Layout from 'components/Layout';

import * as S from './style';
import List from './List';

const VendorProduct = () => {
  const { t } = useTranslation(['common', 'vendorproduct']);

  return (
    <Layout>
      <S.PageHeader>
        <S.PageTitle>{t('vendorproduct:products')}</S.PageTitle>
      </S.PageHeader>
      <List />
    </Layout>
  );
};

export default VendorProduct;
