import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Space } from 'antd';
import { Icon } from '@getir/web-components';
import { useTranslation } from 'react-i18next';

import * as ROUTES from 'constants/routes';
import Layout from 'components/Layout';
import * as CS from 'common/style';

import BrandForm from './components/BrandForm';

const BrandDetail = () => {
  const history = useHistory();
  const params = useParams();
  const { t } = useTranslation(['brands']);

  return (
    <Layout>
      <CS.PageHeader>
        <Space align="center">
          <Icon name="arrow-left" onClick={() => history.push(ROUTES.BRANDS)} />
          <CS.PageTitle>
            {t(params.id ? 'brands:editScreenTitle' : 'brands:addScreenTitle')}
          </CS.PageTitle>
        </Space>
      </CS.PageHeader>
      <BrandForm id={params.id} />
    </Layout>
  );
};
export { BrandDetail };
