import React, {
  useState,
  useCallback,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Info from 'components/v1/Info';
import DragdropUpload from 'components/v1/DragdropUpload';

import * as BillingDetailModalActions from '../actions';

const Upload = (props, ref) => {
  const { isUploading, orderId, isSuccessful } = useSelector(
    state => state.billingDetailModal,
  );
  const { t } = useTranslation('billingUploadModal');

  const [message, setMessage] = useState({
    kind: 'warning',
    content: 'uploadBillingInfo',
  });
  const uploadRef = useRef(null);
  const dispatch = useDispatch();

  const uploadFile = useCallback(() => {
    const uploadData = uploadRef.current;

    const formData = new FormData();
    formData.append('file', uploadData.selectedFile);
    dispatch(
      BillingDetailModalActions.uploadFileRequest({
        formData,
        orderId,
      }),
    );
  }, [dispatch, orderId]);

  const onFileChange = useCallback(
    e => {
      const isDisabled = !e;
      dispatch(BillingDetailModalActions.saveButtonDisabled(isDisabled));
    },
    [dispatch],
  );

  const onError = useCallback(content => {
    setMessage(content);
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      uploadFile,
    }),
    [uploadFile],
  );

  return (
    <>
      {isSuccessful && <Info kind="info">{t('uploadedBillingInfo')}</Info>}
      <DragdropUpload
        ref={uploadRef}
        onChange={onFileChange}
        onError={onError}
        uploading={isUploading}
        buttonText={t('dragAndDrop.buttonText')}
        accept=".jpg, .jpeg, .png"
        validFileTypes={['image/jpeg', 'image/png']}
      />
      {!isSuccessful && (
        <Info kind={message.kind} isCloseable>
          {t(message.content)}
        </Info>
      )}
    </>
  );
};

export default forwardRef(Upload);
