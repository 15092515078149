import React from 'react';
import { Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined } from '@ant-design/icons';

import Button from 'components/v1/Button';
import AuthorizedComponent from 'components/AuthorizedComponent';

const useBrandsColumns = (
  pushToBrandEdit,
  pushToBrandProductOrder,
  deleteBrand,
) => {
  const { t } = useTranslation(['common', 'brands']);

  return [
    {
      title: t('brands:brandname'),
      dataIndex: 'brandname',
      key: 'brandname',
      sorter: (a, b) => a.brandname.localeCompare(b.brandname),
    },
    {
      title: t('brands:firm'),
      dataIndex: 'firm',
      key: 'firm',
      sorter: (a, b) => a.firm.localeCompare(b.firm),
    },
    {
      title: t('brands:action'),
      key: 'actions',
      render: (text, row) => (
        <Space size="middle" direction="horizontal" align="end">
          <Button
            kind="secondary"
            size="small"
            onClick={() => pushToBrandProductOrder({ id: row.key })}
          >
            {t('brands:orderProducts')}
          </Button>
          <Button
            kind="secondary"
            size="small"
            onClick={() => pushToBrandEdit({ id: row.key })}
          >
            {t('common:edit')}
          </Button>
          <AuthorizedComponent componentId="brand_delete">
            <Button
              onClick={() => deleteBrand(row.key)}
              kind="secondary"
              size="small"
              style={{
                backgroundColor: 'transparent',
                padding: 0,
              }}
            >
              <DeleteOutlined style={{ fontSize: '18px' }} />
            </Button>
          </AuthorizedComponent>
        </Space>
      ),
      width: 300,
      align: 'right',
    },
  ];
};
export { useBrandsColumns };
