import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Button from 'components/v1/Button';
import Layout from 'components/Layout';
import * as ROUTES from 'constants/routes';

import List from './List';
import * as S from './style';
import * as VendorActions from './actions';

const Vendor = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const addNewVendor = () => {
    history.push(ROUTES.ADD_NEW_VENDOR);
    dispatch(VendorActions.getDistrictsSuccess(undefined));
    dispatch(VendorActions.getTownsSuccess(undefined));
  };

  const canCreateVendor = false;

  return (
    <Layout>
      <S.PageHeader>
        <S.PageTitle>{t('vendor:title')}</S.PageTitle>
        <Button
          disabled={!canCreateVendor}
          type="primary"
          onClick={addNewVendor}
        >
          {t('vendor:addVendor')}
        </Button>
      </S.PageHeader>
      <List />
    </Layout>
  );
};

export default Vendor;
