import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { flow, get, noop } from 'lodash';

import allowOnlyNumber from 'utils/allowOnlyNumber';
import * as CS from 'common/style';
import { getCompoundFunction } from 'utils/function';

import * as S from './style';

const InputWrapper = ({
  fieldName,
  control,
  rules,
  placeholder,
  header,
  errors,
  isOnlyNumber,
  isDisabled,
  style,
  customOnChange,
  shouldHideErrorMessage,
  ...props
}) => (
  <CS.FormFieldWrapper style={style}>
    <Controller
      name={fieldName}
      control={control}
      rules={rules}
      render={({ field }) => (
        <S.InputField
          name={field.name}
          inputRef={field.ref}
          onChange={getCompoundFunction(
            flow(evt => get(evt, 'target.value'), customOnChange),
            field.onChange,
          )}
          onBlur={field.onBlur}
          value={field.value ?? ''}
          placeholder={placeholder}
          header={header}
          hasError={!!errors[fieldName]}
          onKeyPress={isOnlyNumber ? allowOnlyNumber : undefined}
          disabled={isDisabled}
          {...props}
        />
      )}
    />
    {!shouldHideErrorMessage && errors[fieldName] && (
      <CS.ErrorMessage>{errors[fieldName].message}</CS.ErrorMessage>
    )}
  </CS.FormFieldWrapper>
);

InputWrapper.defaultProps = {
  fieldName: '',
  control: {},
  rules: {},
  placeholder: '',
  header: '',
  errors: {},
  isOnlyNumber: false,
  isDisabled: false,
  style: {},
  customOnChange: noop,
  shouldHideErrorMessage: false,
};

InputWrapper.propTypes = {
  fieldName: PropTypes.string,
  control: PropTypes.object,
  rules: PropTypes.object,
  placeholder: PropTypes.string,
  header: PropTypes.string,
  errors: PropTypes.object,
  style: PropTypes.object,
  isOnlyNumber: PropTypes.bool,
  isDisabled: PropTypes.bool,
  customOnChange: PropTypes.func,
  shouldHideErrorMessage: PropTypes.bool,
};

export default InputWrapper;
