import { Table } from 'antd';
import styled from 'styled-components';
import { Tabs, Modal, Button } from '@getir/web-components';

import COLORS from 'constants/colors';

export const StyledTab = styled(Tabs)`
  padding: 0;
  margin: 0;

  ${Tabs.S.Pane} {
    padding-top: 0;
  }

  .ant-table-title {
    padding: 8px 0;
  }
`;

export const StyledTable = styled(Table)`
  .ant-table-thead {
    tr {
      th {
        background: #eaeaea !important;
      }
    }
  }
`;

export const StyledModal = styled(Modal)`
  ${Modal.S.ModalWrapper} {
    ${Modal.S.Modal} {
      > div {
        &:first-child {
          top: 12px;
          right: 12px;
        }
      }
    }
  }

  ${Modal.S.ModalContent} {
    overflow: auto;
  }

  ${Modal.S.Header} {
    padding: 16px 24px;
    color: ${COLORS.mainGetirColor};
    background: rgb(245, 245, 245);
    border-bottom: 1px solid rgb(240, 240, 240);
    border-radius: 16px 16px 0px 0px;
    font-size: 16px;
  }

  ${Modal.S.Content} {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: initial;
    margin-bottom: initial;
  }

  ${Modal.S.Footer} {
    padding: 10px 32px;

    .ant-space,
    .ant-space-item {
      width: 100%;
    }

    ${Button.S.Wrapper} {
      border-radius: 4px;
      width: 100%;
    }
  }
`;
