import React from 'react';
import i18next from 'i18next';
import { Space } from 'antd';
import { generatePath } from 'react-router-dom';

import Button from 'components/v1/Button';
import * as ROUTES from 'constants/routes';
import { MOBILE_MAX_WIDTH } from 'common/constants';

import * as S from './style';

const getColumns = (
  handleToggleChange,
  handlePricelessToggle,
  pushEditScreen,
  history,
  location,
) => [
  {
    title: i18next.t('common:image'),
    width: 100,
    dataIndex: 'image',
    key: 'image',
    render: (text, row) => (
      <img src={text} alt={row.info} style={{ maxWidth: '100%' }} />
    ),
  },
  {
    title: i18next.t('vendorproduct:product'),
    dataIndex: 'title',
    key: 'title',
    render: item => (
      <div
        style={{
          display: 'flex',
        }}
      >
        <p>{item.price}</p>
        {item.crossedOutPrice ? ' / ' : ''}
        {item.crossedOutPrice ? <del>{item.crossedOutPrice}</del> : ''}
      </div>
    ),
  },
  {
    title: i18next.t('actions'),
    width: window.innerWidth < MOBILE_MAX_WIDTH ? 150 : 250,
    fixed: 'right',
    dataIndex: 'actions',
    key: 'actions',
    render: (text, row) => (
      <Space
        direction={
          window.innerWidth < MOBILE_MAX_WIDTH ? 'vertical' : 'horizontal'
        }
        size="middle"
      >
        <S.StyledToggle
          checked={row.open}
          checkedChildren={i18next.t('on')}
          uncheckedChildren={i18next.t('off')}
          onClick={() => {
            if (row.priceLess) {
              handlePricelessToggle(row.id);

              return;
            }
            handleToggleChange(row.id, row.open);
          }}
        />
        <Button
          onClick={() => {
            if (row.priceLess === false) {
              pushEditScreen({ id: row.id });

              return;
            }

            history.push({
              pathname: generatePath(ROUTES.VENDORPRODUCTEDIT, {
                id: row.id,
              }),
              state: { vpGetRequest: false },
              search: `?${new URLSearchParams(location.search).toString()}`,
            });
          }}
          kind="secondary"
        >
          {i18next.t('edit')}
        </Button>
      </Space>
    ),
  },
];

export { getColumns };
