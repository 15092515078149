import React, { useEffect, useState } from 'react';
import { Empty, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Icon } from '@getir/web-components';
import { useDispatch } from 'react-redux';

import Layout from 'components/Layout';
import * as CS from 'common/style';
import * as ROUTES from 'constants/routes';

import * as RequestActions from '../actions';
import useDataSource from '../hooks/useDataSource';
import requestActionsData from '../constants/requestActions';

import getColumns from './getColumns';
import * as S from './style';

const createNewWorkingHours = workingHours =>
  workingHours.map(item => ({
    dayOfWeek: item.dayOfWeek,
    startTime: item.newStartDate,
    endTime: item.newEndDate,
  }));

const WorkingHoursRequest = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const [workingHoursData, setWorkingHoursData] = useState([]);
  const [actions, setActions] = useState(undefined);
  const [currentRequestId, setCurrentRequestId] = useState(undefined);
  const [currentRequestStatus, setCurrentRequestStatus] = useState(undefined);

  const { workingHours } = useDataSource(actions);

  useEffect(() => {
    setWorkingHoursData(workingHours);
  }, [workingHours]);

  useEffect(() => {
    const {
      parsedActions,
      requestId,
      requestStatus,
    } = history.location.state.data;
    setCurrentRequestId(requestId);
    setCurrentRequestStatus(requestStatus);
    setActions(parsedActions);
  }, [history.location.state]);

  const setDayData = (dayIndex, key, val) => {
    const tmpData = [...workingHoursData];
    tmpData[dayIndex][key] = val.value;
    setWorkingHoursData(tmpData);
  };

  const handleCancelButtonClick = () => {
    const resultWorkingHours = createNewWorkingHours(workingHoursData);

    const requestData = {
      requestId: currentRequestId,
      status: requestActionsData.DECLINE,
      workingHours: resultWorkingHours,
    };

    dispatch(RequestActions.approveRequestRequest(requestData));
  };

  const handleApproveButtonClick = () => {
    const resultWorkingHours = createNewWorkingHours(workingHoursData);

    const requestData = {
      requestId: currentRequestId,
      status: requestActionsData.APPROVE,
      workingHours: resultWorkingHours,
    };

    dispatch(RequestActions.approveRequestRequest(requestData));
  };

  return (
    <Layout>
      <CS.PageHeader>
        <CS.PageTitle>
          <Space size="small">
            <Icon
              name="arrow-left"
              onClick={() => history.push(ROUTES.REQUESTS)}
              title={t('backToList')}
              size={16}
            />
            {t('requests:workingHours.pageTitle')}
          </Space>
        </CS.PageTitle>
      </CS.PageHeader>
      <CS.PageBody>
        <S.StyledTable
          dataSource={workingHoursData}
          columns={getColumns(
            handleCancelButtonClick,
            handleApproveButtonClick,
            setDayData,
            currentRequestStatus,
          )}
          pagination={false}
          locale={{ emptyText: <Empty description={t('table.noData')} /> }}
          rowKey="dayOfWeek"
          scroll={{ x: 1000 }}
        />
      </CS.PageBody>
    </Layout>
  );
};

export default WorkingHoursRequest;
