import { put, takeLatest, call } from 'redux-saga/effects';

import cookie from 'utils/cookie';
import history from 'utils/history';
import { open } from 'utils/notification';
import { parseJwt } from 'utils/parseJwt';
import { setItem } from 'utils/localStorage';
import * as ROUTES from 'constants/routes';
import * as LoaderActions from 'components/Loader/actions';
import translate from 'utils/translate';
import { createStandardSaga } from 'utils/createStandardSaga';

import * as Types from './types';
import { loginSteps } from './constants';
import * as LoginActions from './actions';
import {
  loginApi,
  forgotPasswordApi,
  newPasswordApi,
  googleLoginApi,
  getUserPermsApi,
  getPermListApi,
} from './api';

function saveTokenCookie(token) {
  cookie.set('accessToken', token, {});
}

function* loginSaga({ payload }) {
  yield put(LoaderActions.showLoader());
  try {
    const reqBody = { ...payload };
    const result = yield call(loginApi, reqBody);
    if (result.data) {
      yield call(saveTokenCookie, result.data.payload.accessToken);
      yield put(
        LoginActions.loginSuccess(parseJwt(result.data.payload.accessToken)),
      );
      setItem(
        'user',
        JSON.stringify(parseJwt(result.data.payload.accessToken)),
      );
      setItem('currentRole', parseJwt(result.data.payload.accessToken).role);
      history.push(ROUTES.HOME);
    } else {
      open('warning', translate('common:notif.warnTitle'), result.message);
    }
  } catch (error) {
    yield put(LoginActions.loginError(error.message));
  }
  yield put(LoaderActions.hideLoader());
}

function* googleLoginSaga({ payload }) {
  yield put(LoaderActions.showLoader());
  try {
    const reqBody = { ...payload };
    const result = yield call(googleLoginApi, reqBody);
    if (result.data) {
      yield call(saveTokenCookie, result.data.payload.accessToken);
      yield put(
        LoginActions.loginSuccess(parseJwt(result.data.payload.accessToken)),
      );
      setItem(
        'user',
        JSON.stringify(parseJwt(result.data.payload.accessToken)),
      );
      setItem('currentRole', parseJwt(result.data.payload.accessToken).role);
      history.push(ROUTES.HOME);
    } else {
      open('warning', translate('common:notif.warnTitle'), result.message);
    }
  } catch (error) {
    yield put(LoginActions.loginError(error.message));
  }
  yield put(LoaderActions.hideLoader());
}

function* forgotPasswordSaga({ payload }) {
  yield put(LoaderActions.showLoader());
  try {
    const reqBody = { ...payload };
    const result = yield call(forgotPasswordApi, reqBody);
    if (result.data) {
      yield put(LoginActions.forgotPasswordSuccess(result.data));
      yield put(LoginActions.updateStep(loginSteps.ForgotPasswordResult));
    } else {
      yield put(open('warning', 'Uyarı', result.message));
    }
  } catch (error) {
    yield put(LoginActions.forgotPasswordError(error.message));
  }
  yield put(LoaderActions.hideLoader());
}

function* newPasswordSaga({ payload }) {
  yield put(LoaderActions.showLoader());
  try {
    const { newPassword, token } = payload;
    const reqBody = { newPassword, token };
    const result = yield call(newPasswordApi, reqBody);
    if (result.data) {
      yield put(LoginActions.newPasswordSuccess(result.data));
      yield put(LoginActions.updateStep(loginSteps.NewPasswordResult));
    } else {
      yield put(open('warning', 'Uyarı', result.message));
    }
  } catch (error) {
    yield put(LoginActions.newPasswordError(error.message));
  }
  yield put(LoaderActions.hideLoader());
}

const getUserPermsSaga = createStandardSaga({
  callApi: getUserPermsApi,
  successAction: LoginActions.getUserPermsSuccess,
  errorAction: LoginActions.getUserPermsError,
});

const getPermListSaga = createStandardSaga({
  callApi: getPermListApi,
  successAction: LoginActions.getPermListSuccess,
  errorAction: LoginActions.getPermListError,
});

export function* rootSaga() {
  yield takeLatest(Types.LOGIN_REQUEST, loginSaga);
  yield takeLatest(Types.GOOGLE_LOGIN_REQUEST, googleLoginSaga);
  yield takeLatest(Types.FORGOT_PASSWORD_REQUEST, forgotPasswordSaga);
  yield takeLatest(Types.NEW_PASSWORD_REQUEST, newPasswordSaga);
  yield takeLatest(Types.GET_USER_PERMS_REQUEST, getUserPermsSaga);
  yield takeLatest(Types.GET_PERM_LIST_REQUEST, getPermListSaga);
}

export default rootSaga;
