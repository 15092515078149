import { putRequest, getRequest } from 'lib/axios';

import api from './apiRoutes';

export const updateWorkingHoursApi = args =>
  putRequest(api.updateWorkingHours, args);

export const getAutoOpenApi = vendorId => getRequest(api.autoOpen(vendorId));

export const updateAutoOpenApi = args =>
  putRequest(api.autoOpen(args.vendorId), args.data);
