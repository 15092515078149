import { getRequest, putRequest } from 'lib/axios';

import api from './apiRoutes';

export const listVendorProductsApi = args =>
  getRequest(api.listVendorProducts(args));

export const openVendorProductApi = args =>
  putRequest(api.openVendorProduct, args);

export const editVendorProductApi = args =>
  putRequest(api.editVendorProduct, args);

export const closeVendorProductApi = args =>
  putRequest(api.closeVendorProduct, args);

export const getVendorProductApi = args =>
  getRequest(api.getVendorProduct(args));

export const getVendorProductInfoApi = args =>
  getRequest(api.getProductInfo(args));

export const getCategoriesApi = args => getRequest(api.getCategories, args);
