import * as Types from './types';

const initialState = {
  list: undefined,
  error: {},
  isAutoOpenEnabled: false,
  isAutoOpenLoading: false,
  isAutoOpenError: {},
};

function WorkingHoursReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.UPDATE_WORKING_HOURS_SUCCESS:
      return {
        ...state,
        list: payload,
      };
    case Types.UPDATE_WORKING_HOURS_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.UPDATE_AUTO_OPEN_REQUEST:
      return {
        ...state,
        isAutoOpenLoading: true,
      };
    case Types.UPDATE_AUTO_OPEN_SUCCESS:
      return {
        ...state,
        isAutoOpenLoading: false,
        isAutoOpenEnabled: !state.isAutoOpenEnabled,
      };
    case Types.UPDATE_AUTO_OPEN_ERROR:
      return {
        ...state,
        isAutoOpenLoading: false,
        isAutoOpenError: payload,
      };
    case Types.GET_AUTO_OPEN_REQUEST:
      return {
        ...state,
        isAutoOpenLoading: true,
      };
    case Types.GET_AUTO_OPEN_SUCCESS:
      return {
        ...state,
        isAutoOpenLoading: false,
        isAutoOpenEnabled: payload,
      };
    case Types.GET_AUTO_OPEN_ERROR:
      return {
        ...state,
        isAutoOpenLoading: false,
        isAutoOpenError: payload,
      };

    default:
      return state;
  }
}

export default WorkingHoursReducer;
