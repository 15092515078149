import { apiGateway } from 'constants/services';

export default {
  listUsers: `${apiGateway}/`,
  addUser: `${apiGateway}/user-service/admin/users/user`,
  updateUser: userId => `${apiGateway}/user-service/admin/users/user/${userId}`,
  deleteUser: userId => `${apiGateway}/user-service/admin/users/user/${userId}`,
  getUsersByFirm: firmId =>
    `${apiGateway}/vendor-service/firms/all-managers/${firmId}`,
  getUsersByVendor: `${apiGateway}/vendor-service/vendors/vendor/vendor-manager/users`,
  getRolesByRoleGroup: roleGroupName =>
    `${apiGateway}/user-service/roles/?roleGroupName=${roleGroupName}`,
};
