import * as Types from './types';

const initialState = {
  orderDetail: undefined,
  error: {},
  showModal: false,
  showActions: false,
  getAddressNote: {
    data: undefined,
    error: undefined,
  },
  createAddressNote: {
    data: undefined,
    error: undefined,
  },
  updateAddressNote: {
    data: undefined,
    error: undefined,
  },
  deleteAddressNote: {
    data: undefined,
    error: undefined,
  },
};

function OrderDetailReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.ORDERDETAIL_SUCCESS:
      return {
        ...state,
        orderDetail: payload,
        showModal: true,
        showActions: false,
      };
    case Types.ORDERDETAIL_ERROR:
      return {
        ...state,
        error: payload,
        showModal: false,
        showActions: false,
      };
    case Types.ORDERDETAIL_RESET:
      return {
        ...state,
        orderDetail: undefined,
        showModal: false,
        showActions: false,
      };
    case Types.ORDERDETAILWITHACTIONS_SUCCESS:
      return {
        ...state,
        orderDetail: payload,
        showModal: true,
        showActions: true,
      };
    case Types.ORDERDETAILWITHACTIONS_ERROR:
      return {
        ...state,
        orderDetail: payload,
        showModal: false,
        showActions: false,
      };
    case Types.GET_ADDRESS_NOTE_SUCCESS:
      return {
        ...state,
        getAddressNote: {
          data: payload,
        },
      };
    case Types.GET_ADDRESS_NOTE_ERROR:
      return {
        ...state,
        getAddressNote: {
          error: payload,
        },
      };
    case Types.CREATE_ADDRESS_NOTE_SUCCESS:
      return {
        ...state,
        createAddressNote: {
          data: payload,
        },
      };
    case Types.CREATE_ADDRESS_NOTE_ERROR:
      return {
        ...state,
        createAddressNote: {
          error: payload,
        },
      };
    case Types.UPDATE_ADDRESS_NOTE_SUCCESS:
      return {
        ...state,
        updateAddressNote: {
          data: payload,
        },
      };
    case Types.UPDATE_ADDRESS_NOTE_ERROR:
      return {
        ...state,
        updateAddressNote: {
          error: payload,
        },
      };
    case Types.DELETE_ADDRESS_NOTE_SUCCESS:
      return {
        ...state,
        deleteAddressNote: {
          data: payload,
        },
        getAddressNote: {
          data: {
            addressNote: '',
          },
        },
      };
    case Types.DELETE_ADDRESS_NOTE_ERROR:
      return {
        ...state,
        deleteAddressNote: {
          error: payload,
        },
      };
    default:
      return state;
  }
}

export default OrderDetailReducer;
