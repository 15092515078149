import styled, { css } from 'styled-components';
import {
  Button,
  Button as StyledButton,
  Icon as StyledIcon,
  Input,
  Toggle,
} from '@getir/web-components';
import { Table, Button as AntdButton } from 'antd';

import ButtonVOne from 'components/v1/Button';
import Modal from 'components/v1/Modal';
import COLORS from 'constants/colors';
import breakpoint from 'constants/breakpoint';

const ErrorMessage = styled.span`
  display: inline-block;
  color: rgb(219, 71, 30);
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.16px;
  margin-top: 8px;
`;

const StyledToggle = styled(Toggle)`
  ${props =>
    props.checked
      ? css`
          background-color: ${COLORS.toggleSuccessColor};
          border-color: ${COLORS.toggleSuccessColor};
          color: #fff;
          &:after {
            background-color: #fff;
          }
        `
      : css`
          background-color: ${COLORS.toggleDangerColor};
          border-color: ${COLORS.toggleDangerColor};
          color: #fff;
          &:after {
            background-color: #fff;
          }
        `}
`;

const FormFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  div[data-testid='select'] {
    width: 100%;
  }
`;

const PageHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0;
  ${StyledButton.S.Wrapper} {
    border-radius: 0;
  }
`;

const PageTitle = styled.div`
  margin-bottom: 0;
  font-size: 24px;
  margin: 0;

  ${StyledIcon.S.Wrapper} {
    cursor: pointer;
  }
`;

const PageBody = styled.div`
  background: rgb(255, 255, 255);
  box-shadow: rgb(206 206 206 / 25%) 4px 4px 30px;
  padding: 24px;
  width: 100%;

  ${StyledButton.S.Wrapper} {
    border-radius: 0;
  }

  ${Input.S.Input} {
    border: none;
    outline: none;
  }
`;

const PageContent = styled.div`
  padding: 24px;
  background-color: #fff;
  box-shadow: 4px 4px 30px rgba(206, 206, 206, 0.25);
  border-radius: 2px;

  form {
    width: 50%;
    @media (max-width: ${breakpoint.MOBILE.MAX}px) {
      width: 100%;
    }
    > div {
      margin-bottom: 18px;
    }

    .ant-space,
    .ant-space-item {
      width: 100%;
    }
  }

  ${StyledButton.S.Wrapper} {
    border-radius: 0;
    width: 100%;
  }

  ${Input.S.Input} {
    border: none;
  }
`;

const FullWidthButton = styled(ButtonVOne)`
  width: 100%;
`;

const ListTable = styled(Table)`
  ${Button.S.Wrapper} {
    border-radius: 0;
  }

  ${StyledIcon.S.Wrapper} {
    color: #db471e;
    cursor: pointer;
  }

  .ant-table-title {
    display: grid;
    grid-template-columns: 270px 1fr;
    justify-content: space-between;

    ${Input.S.Input} {
      border: none;
    }

    ${Button.S.Wrapper} {
      button {
        div {
          color: ${COLORS.mainGetirColor};
        }
      }
    }
  }

  .ant-table-thead {
    tr {
      th {
        background: #eaeaea !important;
      }
    }
  }
`;

const FooterContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
`;

const StyledModal = styled(Modal)`
  .ant-modal-header {
    padding: 16px 24px;
    color: rgba(0, 0, 0, 0.85);
    background: '#eaeaea';
    background: ${props => props.headerBgPurple && COLORS.mainGetirColor};
    border-bottom: 1px solid rgb(240, 240, 240);
    border-radius: 16px 16px 0px 0px;

    @media (max-width: ${breakpoint.MOBILE.MAX}px) {
      border-radius: 0;
    }
  }

  .ant-modal-footer {
    border-radius: 0px 0px 16px 16px;
    display: flex;
    gap: 12px;

    @media (max-width: ${breakpoint.MOBILE.MAX}px) {
      border-radius: 0;
    }
  }

  .ant-modal-content {
    overflow: auto;
    border-radius: 16px;
    .ant-modal-close-x {
      color: ${props => props.headerBgPurple && '#fff'};
    }
  }

  h3 {
    margin-top: 5px;
  }

  h3.missingtitle {
    text-align: center;
    color: ${COLORS.mainGetirColor};
    margin-top: 0px;
  }

  h4.light {
    text-align: center;
    font-weight: 400;
  }

  label {
    display: flex;
    flex: 1;
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: ${COLORS.mainGetirColor} !important ;
  }

  .ant-radio-checked .ant-radio-inner:after {
    background-color: ${COLORS.mainGetirColor};
  }

  .ant-radio:hover .ant-radio-inner {
    border-color: ${COLORS.mainGetirColor};
  }

  textarea {
    border: none !important;
  }

  ${StyledButton.S.Wrapper} {
    border-radius: 0;
    flex: 1;
  }

  .ant-radio-group,
  .ant-space {
    width: 100% !important;
  }

  @media (max-width: ${breakpoint.MOBILE.MAX}px) {
    top: 0 !important;
    margin: 0 !important;
    max-width: 100vw !important;
    padding-bottom: 0 !important;
  }
`;

const GetirStyledButton = styled(AntdButton)`
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background: ${props =>
    props.disabled ? '#a2a2a2' : `${COLORS.mainGetirColor}`} !important;
  color: white !important;
  border: none !important;
  height: 40px !important;
  font-weight: 600 !important;
`;

export {
  ErrorMessage,
  StyledToggle,
  FormFieldWrapper,
  PageHeader,
  PageBody,
  PageTitle,
  FullWidthButton,
  ListTable,
  PageContent,
  StyledModal,
  GetirStyledButton,
  FooterContainer,
};
