import React from 'react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';

const isProduction = process.env.NODE_ENV === 'production';

const init = () =>
  isProduction &&
  Sentry.init({
    release: `getir-water-panel@${new Date().getTime()}`,
    dsn:
      'https://1495a4b4fcbb4017b62d744a631ac848@o142215.ingest.sentry.io/6535267',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.2,
    ignoreErrors: [/ResizeObserver/, 'Request failed with status code 401'],
    beforeSend(event, hint) {
      if (hint.originalException === 'Timeout') return null;

      return event;
    },
  });

const sentryErrorHandler = (error, componentStack) => {
  Sentry.withScope(scope => {
    scope.setExtra('componentStack', componentStack);
    Sentry.captureException(error);
  });
};

const SentryErrorFallbackComponent = () => 'Some error happened';

const CustomErrorBoundary = ({ children }) => (
  <ErrorBoundary
    FallbackComponent={SentryErrorFallbackComponent}
    onError={sentryErrorHandler}
  >
    {children}
  </ErrorBoundary>
);

CustomErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default {
  init,
  CustomErrorBoundary,
};
