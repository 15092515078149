import React from 'react';

import Layout from 'components/Layout';

import Header from './Header';
import * as S from './styles';
import Table from './Table';
import MessageModal from './MessageModal';

const VendorCommunication = () => (
  <Layout>
    <Header />
    <S.BodyContainer>
      <Table />
      <MessageModal />
    </S.BodyContainer>
  </Layout>
);

export default VendorCommunication;
