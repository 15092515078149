import React from 'react';
import PropTypes from 'prop-types';

import { LinkWrapper, Link as LinkComponent } from './style';

const Link = ({ href, text }) => (
  <LinkWrapper>
    <LinkComponent href={href} target="_blank">
      {text}
    </LinkComponent>
  </LinkWrapper>
);

Link.propTypes = {
  href: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default Link;
