import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { open } from 'utils/notification';
import customFilterTurkishChar from 'utils/customFilterTurkishChar';
import useIsAuthenticated from 'hooks/useIsAuthenticated';
import * as LoaderActions from 'components/Loader/actions';

import { getSelectListItems } from './api';
import * as S from './style';

const SelectBox = ({
  placeholder,
  options,
  data,
  url,
  valueKey,
  labelKey,
  reqType,
  allowClear,
  labelInValue,
  onChange,
  onBlur,
  onFocus,
  onSelect,
  onDeselect,
  onClear,
  reduxPath,
  condition,
  name,
  hasError,
  payloadKey,
  hideLabel,
  ...props
}) => {
  const { t } = useTranslation();
  const [list, setList] = useState([]);
  const { isLoggedIn } = useIsAuthenticated();
  const dispatch = useDispatch();

  function resolve(path, obj, separator = '.') {
    const properties = Array.isArray(path) ? path : path.split(separator);

    return properties.reduce((prev, curr) => prev && prev[curr], obj);
  }

  const stateValue = useSelector(state => resolve(reduxPath, state));

  const getList = useCallback(
    async (apiUrl, apiData) => {
      dispatch(LoaderActions.showLoader());
      try {
        const result = await getSelectListItems(apiUrl, reqType, apiData);
        const payloadData = payloadKey
          ? result.data.payload[payloadKey]
          : result.data.payload;
        const resultList = payloadData
          .filter(item1 => item1[labelKey] !== undefined)
          .map(item => ({
            value: item[valueKey],
            label: item[labelKey],
          }));
        setList(resultList);
      } catch (error) {
        open('warning', t('notif.warnTitle'), error.message);
      }
      dispatch(LoaderActions.hideLoader());
    },
    [t, labelKey, valueKey, reqType, dispatch, payloadKey],
  );

  useEffect(() => {
    if (url.length > 0 && isLoggedIn && condition) {
      getList(url, data);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <S.Container>
      {!hideLabel && (
        <S.SelectBoxPlaceholder>{placeholder}</S.SelectBoxPlaceholder>
      )}
      <S.SelectContainer
        options={options.length ? options : list}
        placeholder={placeholder}
        filterOption={customFilterTurkishChar}
        optionFilterProp={labelKey}
        optionLabelProp="label"
        allowClear={allowClear}
        labelInValue={labelInValue}
        onChange={onChange}
        value={stateValue}
        onBlur={onBlur}
        onFocus={onFocus}
        onSelect={onSelect}
        onDeselect={onDeselect}
        onClear={onClear}
        haserror={hasError !== undefined ? 1 : 0}
        {...props}
      />
    </S.Container>
  );
};

SelectBox.defaultProps = {
  placeholder: '',
  options: [],
  data: {},
  url: '',
  valueKey: '',
  labelKey: '',
  name: '',
  reqType: 'post',
  allowClear: false,
  labelInValue: false,
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
  onSelect: () => {},
  onDeselect: () => {},
  onClear: () => {},
  reduxPath: '',
  condition: true,
  hasError: undefined,
  payloadKey: undefined,
  hideLabel: false,
};

SelectBox.propTypes = {
  placeholder: PropTypes.string,
  options: PropTypes.array,
  data: PropTypes.object,
  url: PropTypes.string,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  name: PropTypes.string,
  reqType: PropTypes.string,
  allowClear: PropTypes.bool,
  labelInValue: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onSelect: PropTypes.func,
  onDeselect: PropTypes.func,
  onClear: PropTypes.func,
  reduxPath: PropTypes.string,
  condition: PropTypes.bool,
  hasError: PropTypes.object,
  payloadKey: PropTypes.string,
  hideLabel: PropTypes.bool,
};

export default SelectBox;
