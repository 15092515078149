import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { GOOGLE_AUTH_REQUEST_TYPES } from 'common/constants';
import useGoogleAuth from 'hooks/useGoogleAuth';
import { open } from 'utils/notification';

import * as LoginActions from './actions';

function GoogleSSOLogin() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['auth']);
  useGoogleAuth({
    authRequest: GOOGLE_AUTH_REQUEST_TYPES.SIGN_IN,
    dispatch,
    Creators: LoginActions,
    onError: res => {
      open('warning', t('common:notif.warnTitle'), res);
    },
  });

  return (
    <div
      id="gsiLogin"
      style={{
        width: '100%',
      }}
    />
  );
}

export default GoogleSSOLogin;
