import { useTranslation } from 'react-i18next';

const useValidations = () => {
  const { t } = useTranslation();

  const validationRules = {
    categoryName: {
      required: t('requiredFieldText'),
    },
    productName: {
      required: t('requiredFieldText'),
    },
    shortProductName_TR: {
      required: t('requiredFieldText'),
    },
    shortProductName_EN: {
      required: t('requiredFieldText'),
    },
    longProductName_TR: {},
    longProductName_EN: {},
    shortDescription_TR: {},
    shortDescription_EN: {},
    longDescription_TR: {},
    longDescription_EN: {},
    isRecommended: {
      required: t('requiredFieldText'),
    },
    firmId: {
      required: t('requiredFieldText'),
    },
    brandId: {
      required: t('requiredFieldText'),
    },
    maxOrderCount: {
      required: t('requiredFieldText'),
      min: {
        value: 1,
        message: t('minValueText', { minValue: 1 }),
      },
    },
    image: {
      required: t('requiredFieldText'),
    },
    productImageUrl: {
      required: t('requiredFieldText'),
    },
  };

  return {
    validationRules,
  };
};

export default useValidations;
