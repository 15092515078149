const Buffer = require('buffer/').Buffer; // eslint-disable-line

export const parseJwt = token => {
  try {
    const tokenDecodablePart = token.split('.')[1];
    const decoded = Buffer.from(tokenDecodablePart, 'base64').toString();

    return JSON.parse(decoded);
  } catch (e) {
    return null;
  }
};
