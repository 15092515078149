const LOGIN = '/giris-yap';
const RESET_PASSWORD = '/vendor-panel/reset';
const CREATE_PASSWORD = '/vendor-panel/newPassword';
const HOME = '/';
const FORGOT_PASSWORD = '/sifremi-unuttum';
const HELP = '/destek';
const USERS = '/isletme-bilgileri/kullanicilar';
const BRANDS = '/isletmeler/markalar';
const BRANDEDIT = '/isletmeler/markalar/duzenle/:id';
const BRANDCREATE = '/isletmeler/markalar/olustur';
const BRANDPRODUCT = '/isletmeler/markalar/listele/:id';
const GENERAL_INFORMATION = '/isletme-bilgileri/genel-bilgiler';
const FINANCIAL_INFORMATION = '/isletme-bilgileri/finansal-bilgiler';
const ACTIVE_ORDERS = '/aktif-siparisler';
const FIRMS = '/isletmeler/firmalar';
const ADD_NEW_FIRM = '/isletmeler/yeni-firma-ekle';
const VENDORS = '/isletmeler/bayiler';
const ADD_NEW_VENDOR = '/isletmeler/yeni-bayi-ekle';
const WORKING_HOURS = '/calisma-saatleri';
const LIST_CATEGORY = '/kategoriler/listele';
const ADD_CATEGORY = '/kategoriler/yeni-kategori-ekle';
const EDIT_CATEGORY = '/kategoriler/duzenle/:id';
const LIST_CATALOG = '/kataloglar/listele';
const ADD_CATALOG = '/kataloglar/yeni-katalog-urunu-ekle';
const EDIT_CATALOG = '/kataloglar/duzenle/:id';
const VENDORPRODUCT = '/urunler';
const VENDORPRODUCTEDIT = '/urunler/duzenle/:id';
const PAYMENT_METHODS = '/odeme-yontemleri';
const PREVIOUS_ORDERS = '/gecmis-siparisler';
const FINANCIAL_TRANSACTIONS = '/odemeler/finansal-hareketler';
const ORDER_DETAIL = '/odemeler/siparis-detay';
const REQUESTS = '/talepler';
const REQUESTS_WORKING_HOURS = '/talepler/calisma-saati-degisikligi';
const REQUESTS_BULK_CHANGE = '/talepler/toplu-fiyat-degisikligi';
const BULK_CHANGE = '/toplu-degisiklik';
const UPLOAD_GEOMAP = '/harita/harita-yukle';
const BAN_GEOMAP = '/harita/harita-banlama';
const ALTERNATIVE_MAP_HOURS = '/harita/alternatif-harita-saati';
const REDIRECT_ACTIVE_ORDERS = '/:vendorId';
const ADD_FINANCIAL_TRANSACTION = '/odemeler/yeni-finansal-hareket';
const AUTH_USERS = '/kullanicilar';
const AUTH_PERMS = '/yetkiler';
const AUTH_ROLES = '/roller';
const ADD_NEW_ROLE = '/roller/yeni-rol-ekle';
const EDIT_ROLE = '/roller/rol-duzenle/:id';
const VENDOR_COMMUNICATION = '/bayi-iletisim';
const KUZEYDEN_REPORT = '/kuzeyden-rapor';

export {
  LOGIN,
  RESET_PASSWORD,
  CREATE_PASSWORD,
  HOME,
  FORGOT_PASSWORD,
  HELP,
  USERS,
  BRANDS,
  BRANDEDIT,
  BRANDCREATE,
  BRANDPRODUCT,
  ACTIVE_ORDERS,
  FIRMS,
  ADD_NEW_FIRM,
  GENERAL_INFORMATION,
  VENDORS,
  ADD_NEW_VENDOR,
  FINANCIAL_INFORMATION,
  WORKING_HOURS,
  LIST_CATEGORY,
  ADD_CATEGORY,
  EDIT_CATEGORY,
  LIST_CATALOG,
  ADD_CATALOG,
  EDIT_CATALOG,
  VENDORPRODUCT,
  VENDORPRODUCTEDIT,
  PAYMENT_METHODS,
  PREVIOUS_ORDERS,
  FINANCIAL_TRANSACTIONS,
  ORDER_DETAIL,
  REQUESTS,
  REQUESTS_WORKING_HOURS,
  REQUESTS_BULK_CHANGE,
  BULK_CHANGE,
  UPLOAD_GEOMAP,
  BAN_GEOMAP,
  ALTERNATIVE_MAP_HOURS,
  REDIRECT_ACTIVE_ORDERS,
  ADD_FINANCIAL_TRANSACTION,
  AUTH_USERS,
  AUTH_PERMS,
  AUTH_ROLES,
  ADD_NEW_ROLE,
  EDIT_ROLE,
  VENDOR_COMMUNICATION,
  KUZEYDEN_REPORT,
};
