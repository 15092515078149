import { apiGateway } from 'constants/services';

export default {
  getRoleList: () => `${apiGateway}/user-service/roles`,
  createRole: `${apiGateway}/user-service/roles`,
  getRoleUsersByRoleId: roleId =>
    `${apiGateway}/user-service/roles/role/${roleId}/users`,
  deleteRole: roleId => `${apiGateway}/user-service/roles/role/${roleId}`,
  updateRole: roleId => `${apiGateway}/user-service/roles/role/${roleId}`,
  getPermissionsByRoleId: roleId =>
    `${apiGateway}/user-service/roles/role/${roleId}/ui-permissions`,
  getRoleGroupNames: () => `${apiGateway}/user-service/role-groups`,
};
