import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Icon } from '@getir/web-components';
import { get, isEmpty, isEqual, noop } from 'lodash';

import * as CS from 'common/style';
import { open } from 'utils/notification';
import customFilterTurkishChar from 'utils/customFilterTurkishChar';
import { SelectMode } from 'constants/enums/selectMode';
import { SelectAllOption } from 'constants/selectAllOption';

import { getSelectListItems } from './api';
import * as S from './style';

const SelectBox = ({
  fieldName,
  control,
  rules,
  placeholder,
  errors,
  options,
  data,
  url,
  valueKey,
  labelKey,
  reqType,
  allowClear,
  labelInValue,
  payloadKey,
  showSearch,
  onChange,
  isDisabled,
  selectMode,
  isSelectAllEnabled,
  ...props
}) => {
  const { t } = useTranslation();
  const [list, setList] = useState([]);

  const handleSelectAll = useCallback((currentOption, currentList) => {
    const isAllOptionSelected = currentOption.some(
      option => option.value === SelectAllOption.value,
    );
    if (isAllOptionSelected) {
      return [SelectAllOption, ...currentList];
    }

    return currentOption;
  }, []);

  const getList = useCallback(
    async (apiUrl, apiData) => {
      try {
        const result = await getSelectListItems(apiUrl, reqType, apiData);
        const payloadData = payloadKey
          ? result.data.payload[payloadKey]
          : result.data.payload;
        const resultList = payloadData
          .filter(item1 => item1[labelKey] !== undefined)
          .map(item => ({
            value: item[valueKey],
            label: item[labelKey],
          }));
        setList(resultList);
      } catch (error) {
        open('warning', t('common:notif.warnTitle'), error.message);
      }
    },
    [t, labelKey, valueKey, reqType, payloadKey],
  );

  useEffect(() => {
    if (url.length > 0) {
      getList(url, data);
    }
    // eslint-disable-next-line
  }, [url]);

  const eligibleOptions = isEmpty(options) ? list : options;

  return (
    <CS.FormFieldWrapper>
      <S.SelectBoxPlaceholder>{placeholder}</S.SelectBoxPlaceholder>
      <Controller
        name={fieldName}
        control={control}
        rules={rules}
        render={({ field }) => (
          <S.SelectContainer
            mode={selectMode}
            options={
              isEqual(selectMode, SelectMode.SINGLE) || !isSelectAllEnabled
                ? eligibleOptions
                : [SelectAllOption].concat(eligibleOptions)
            }
            placeholder={placeholder}
            showSearch={showSearch}
            onChange={value => {
              let newValue = value;
              if (
                isEqual(selectMode, SelectMode.MULTIPLE) &&
                isSelectAllEnabled
              ) {
                newValue = handleSelectAll(value, eligibleOptions);
              }
              onChange(newValue);
              field.onChange(newValue);
            }}
            onDeselect={value => {
              if (
                isEqual(selectMode, SelectMode.MULTIPLE) &&
                isSelectAllEnabled &&
                isEqual(get(value, 'value', value), SelectAllOption.value)
              ) {
                field.onChange([]);
              }
            }}
            onBlur={field.onBlur}
            name={field.name}
            value={field.value}
            filterOption={customFilterTurkishChar}
            optionFilterProp={labelKey}
            optionLabelProp="label"
            allowClear={allowClear}
            disabled={isDisabled}
            errors={errors}
            labelInValue={labelInValue}
            suffixIcon={
              errors[fieldName] && (
                <S.ErrorWrapper>
                  <Icon
                    name="alert-circle"
                    size={22}
                    color="#DB471E"
                    format="small"
                  />
                </S.ErrorWrapper>
              )
            }
            {...props}
          />
        )}
      />
      {errors[fieldName] && (
        <CS.ErrorMessage>{errors[fieldName].message}</CS.ErrorMessage>
      )}
    </CS.FormFieldWrapper>
  );
};

SelectBox.defaultProps = {
  fieldName: '',
  control: {},
  rules: {},
  placeholder: '',
  errors: {},
  options: [],
  data: {},
  url: '',
  valueKey: '',
  labelKey: '',
  reqType: 'post',
  allowClear: false,
  labelInValue: false,
  payloadKey: undefined,
  showSearch: false,
  onChange: noop,
  isDisabled: false,
  selectMode: SelectMode.SINGLE,
  isSelectAllEnabled: false,
};

SelectBox.propTypes = {
  fieldName: PropTypes.string,
  control: PropTypes.object,
  rules: PropTypes.object,
  placeholder: PropTypes.string,
  errors: PropTypes.object,
  options: PropTypes.array,
  data: PropTypes.object,
  url: PropTypes.string,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  reqType: PropTypes.string,
  allowClear: PropTypes.bool,
  labelInValue: PropTypes.bool,
  payloadKey: PropTypes.string,
  showSearch: PropTypes.bool,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  selectMode: PropTypes.oneOf(Object.values(SelectMode)),
  isSelectAllEnabled: PropTypes.bool,
};

export default SelectBox;
