// list types
export const VENDORPRODUCT_LIST_REQUEST = 'VENDORPRODUCT_LIST_REQUEST';
export const VENDORPRODUCT_LIST_SUCCESS = 'VENDORPRODUCT_LIST_SUCCESS';
export const VENDORPRODUCT_LIST_ERROR = 'VENDORPRODUCT_LIST_ERROR';

// brand types
export const VENDORPRODUCT_BRAND_LIST_REQUEST =
  'VENDORPRODUCT_BRAND_LIST_REQUEST';
export const VENDORPRODUCT_BRAND_LIST_SUCCESS =
  'VENDORPRODUCT_BRAND_LIST_SUCCESS';
export const VENDORPRODUCT_BRAND_LIST_ERROR = 'VENDORPRODUCT_BRAND_LIST_ERROR';
export const VENDORPRODUCT_BRAND_LIST_RESET = 'VENDORPRODUCT_BRAND_LIST_RESET';

// open types
export const VENDORPRODUCT_OPEN_REQUEST = 'VENDORPRODUCT_OPEN_REQUEST';
export const VENDORPRODUCT_OPEN_SUCCESS = 'VENDORPRODUCT_OPEN_SUCCESS';
export const VENDORPRODUCT_OPEN_ERROR = 'VENDORPRODUCT_OPEN_ERROR';

// edit types
export const VENDORPRODUCT_EDIT_REQUEST = 'VENDORPRODUCT_EDIT_REQUEST';
export const VENDORPRODUCT_EDIT_SUCCESS = 'VENDORPRODUCT_EDIT_SUCCESS';
export const VENDORPRODUCT_EDIT_ERROR = 'VENDORPRODUCT_EDIT_ERROR';

// close types
export const VENDORPRODUCT_CLOSE_REQUEST = 'VENDORPRODUCT_CLOSE_REQUEST';
export const VENDORPRODUCT_CLOSE_SUCCESS = 'VENDORPRODUCT_CLOSE_SUCCESS';
export const VENDORPRODUCT_CLOSE_ERROR = 'VENDORPRODUCT_CLOSE_ERROR';

// get types
export const VENDORPRODUCT_GET_REQUEST = 'VENDORPRODUCT_GET_REQUEST';
export const VENDORPRODUCT_GET_SUCCESS = 'VENDORPRODUCT_GET_SUCCESS';
export const VENDORPRODUCT_GET_ERROR = 'VENDORPRODUCT_GET_ERROR';
export const VENDORPRODUCT_GET_RESET = 'VENDORPRODUCT_GET_RESET';

// get Product General Info
export const VENDORPRODUCT_PRODUCTINFO_REQUEST =
  'VENDORPRODUCT_PRODUCTINFO_REQUEST';
export const VENDORPRODUCT_PRODUCTINFO_SUCCESS =
  'VENDORPRODUCT_PRODUCTINFO_SUCCESS';
export const VENDORPRODUCT_PRODUCTINFO_ERROR =
  'VENDORPRODUCT_PRODUCTINFO_ERROR';
export const VENDORPRODUCT_PRODUCTINFO_RESET =
  'VENDORPRODUCT_PRODUCTINFO_RESET';

// get Categories
export const VENDORPRODUCT_CATEGORIES_REQUEST =
  'VENDORPRODUCT_CATEGORIES_REQUEST';
export const VENDORPRODUCT_CATEGORIES_SUCCESS =
  'VENDORPRODUCT_CATEGORIES_SUCCESS';
export const VENDORPRODUCT_CATEGORIES_ERROR = 'VENDORPRODUCT_CATEGORIES_ERROR';
