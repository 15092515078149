import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Tooltip } from 'antd';

import ElipsisIcon from 'components/v1/Icons/ElipsisIcon';
import SolidCarIcon from 'components/v1/Icons/SolidCarIcon';
import CheckBadgeIcon from 'components/v1/Icons/CheckBadgeIcon';
import { CheckCircleIcon, ClockIcon, XCircleIcon } from 'components/v1/Icons';
import Badge from 'components/v1/Badge';
import Button from 'components/v1/Button';
import * as OrderDetailActions from 'components/OrderDetailModal/actions';
import * as MapModalActions from 'components/MapModal/actions';
import * as CourierListModalActions from 'components/CourierListModal/actions';
import * as OrderCancelModalActions from 'components/OrderCancelModal/actions';
import {
  ORDER_STATUS,
  ORDER_STATUS_CODES,
  ORDER_STATUS_TRANSLATEKEY,
} from 'constants/orderStatus';
import { DurationCell } from 'components/DurationCell/DurationCell';
import { DURATION_TYPES } from 'constants/durationTypes';
import ActiveOrderMapIcon from 'common/svg/activeordermapicon.svg';

import * as ActiveOrderActions from '../actions';
import * as S from '../style';
import ToggleVisibilityButton from '../toggleVisibilityButton';

const useActiveOrdersColumns = () => {
  const [selectedVendor, setSelectedVendor] = useState(undefined);
  const dispatch = useDispatch();
  const modalOpenAction = id => {
    dispatch(OrderDetailActions.getOrderDetailWithActionsRequest(id));
  };
  const mapModalOpenAction = id => {
    dispatch(MapModalActions.getMapModalRequest(id));
  };
  const sendOrderAction = id => {
    dispatch(CourierListModalActions.sendSmsModalOpen(id));
  };
  const sendAcceptAction = id => {
    dispatch(
      ActiveOrderActions.changeOrderStatusRequest({
        id,
        status: ORDER_STATUS.PREPARING,
      }),
    );
  };
  const sendDeliverAction = id => {
    dispatch(
      ActiveOrderActions.changeOrderStatusRequest({
        id,
        status: ORDER_STATUS.DELIVERED,
      }),
    );
  };

  const cancelModalAction = id => {
    dispatch(
      OrderCancelModalActions.openOrderCancelModal({
        id,
      }),
    );
  };

  const { t } = useTranslation();
  const dateColumn = text => {
    if (text && text.length) {
      const d = new Date(text);

      return dayjs(d).format('DD.MM.YYYY, H:mm:ss');
    }

    return '';
  };

  return {
    selectedVendor: selectedVendor && selectedVendor.vendor,
    columns: [
      {
        title: t('activeOrders:columns.confirmationCode'),
        dataIndex: 'confirmationCode',
        width: 90,
      },
      {
        title: t('activeOrders:columns.vendor'),
        dataIndex: 'vendor',
        render: vendor => (vendor.name ? vendor.name : ''),
      },
      {
        title: t('activeOrders:columns.customer'),
        dataIndex: 'client',
        render: (text, row) => {
          if (text.name) {
            return (
              <S.TransparentButton
                type="button"
                onClick={() => {
                  mapModalOpenAction(row.id);
                  setSelectedVendor(row);
                }}
              >
                <img alt="" src={ActiveOrderMapIcon} />
                {text.name}
              </S.TransparentButton>
            );
          }

          return '';
        },
      },
      {
        title: t('activeOrders:columns.orderPrice'),
        dataIndex: 'totalPrice',
        render: text => `₺${text.toFixed(2)}`,
      },
      {
        title: t('activeOrders:columns.orderDate'),
        dataIndex: 'paymentDate',
        render: dateColumn,
        defaultSortOrder: 'descend',
        sorter: (a, b) =>
          dayjs(a.paymentDate).unix() - dayjs(b.paymentDate).unix(),
      },
      {
        title: t('activeOrders:columns.confirmationDate'),
        dataIndex: 'approvedDate',
        render: dateColumn,
      },
      {
        title: t('activeOrders:columns.status'),
        dataIndex: 'status',
        render: text => {
          let type = 'waiting';
          let icon;

          if (text <= 700) {
            type = 'primary';
            icon = <ClockIcon />;
          } else if (text <= 1000) {
            type = 'success';
          } else {
            type = 'failed';
          }

          return (
            <Badge icon={icon} type={type}>
              {t(ORDER_STATUS_TRANSLATEKEY[ORDER_STATUS_CODES[text]])}
            </Badge>
          );
        },
      },
      {
        title: t('activeOrders:columns.confirmationTime'),
        dataIndex: 'confirmationTime',
        render: (text, row) => {
          if (row.paymentDate) {
            return (
              <DurationCell
                firstDate={row.approvedDate || dayjs().toISOString()}
                secondDate={row.paymentDate}
                limitDuration={DURATION_TYPES.APPROVE}
                isActive={!row.approvedDate}
              />
            );
          }

          return '';
        },
      },
      {
        title: t('activeOrders:columns.totalTime'),
        dataIndex: 'totalTime',
        render: (text, row) => {
          if (row.paymentDate) {
            return (
              <DurationCell
                firstDate={row.deliveryDate || dayjs().toISOString()}
                secondDate={row.paymentDate}
                hideBar
                withBadge
                limitDuration={DURATION_TYPES.DELAYED_ORDER}
                isActive={!row.deliveryDate}
              />
            );
          }

          return '';
        },
      },
      {
        title: t('activeOrders:columns.action'),
        fixed: 'right',
        width: 150,
        render: (text, row) => (
          <S.ActionsArea>
            {row.status === ORDER_STATUS.VERIFYING && (
              <Tooltip title={t('activeOrders:acceptOrder')}>
                <Button
                  kind="secondary"
                  onClick={() => sendAcceptAction(row.id)}
                >
                  <CheckCircleIcon width={20} height={20} fill="#5D3EBC" />
                </Button>
              </Tooltip>
            )}

            {row.status === ORDER_STATUS.PREPARING && (
              <Tooltip title={t('activeOrders:sendOrder')}>
                <Button
                  kind="secondary"
                  onClick={() => {
                    sendOrderAction(row.id);
                  }}
                >
                  <SolidCarIcon width={20} height={20} />
                </Button>
              </Tooltip>
            )}
            {row.status === ORDER_STATUS.ONWAY && (
              <Tooltip title={t('activeOrders:deliverOrder')}>
                <Button
                  kind="primary"
                  onClick={() => sendDeliverAction(row.id)}
                >
                  <CheckBadgeIcon width={20} height={20} />
                </Button>
              </Tooltip>
            )}
            {(row.status === ORDER_STATUS.VERIFYING ||
              row.status === ORDER_STATUS.PREPARING) && (
              <ToggleVisibilityButton secondDate={row.approvedDate}>
                <Tooltip title={t('activeOrders:cancelOrder')}>
                  <Button
                    id="cancelOrder"
                    kind="secondary"
                    onClick={() => {
                      cancelModalAction(row.id);
                      setSelectedVendor(row);
                    }}
                  >
                    <XCircleIcon width={20} height={20} />
                  </Button>
                </Tooltip>
              </ToggleVisibilityButton>
            )}
            <Tooltip title={t('common:detail')}>
              <Button kind="secondary" onClick={() => modalOpenAction(row.id)}>
                <ElipsisIcon width={20} height={20} />
              </Button>
            </Tooltip>
          </S.ActionsArea>
        ),
      },
    ],
  };
};

export { useActiveOrdersColumns };
