import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Button from 'components/v1/Button';
import { rolesWithName } from 'constants/roles';
import useUserInfo from 'hooks/useUserInfo';

import { openMessageModal } from '../actions';
import { MODAL_TYPES } from '../constants';

import * as S from './styles';

const Header = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('vendorCommunication');
  const { vendor } = useSelector(state => state.mainHeader);
  const handleNewMessage = () => {
    dispatch(
      openMessageModal({
        isModalOpen: true,
        modalType: MODAL_TYPES.NEW_MESSAGE,
      }),
    );
  };
  const { userInfo } = useUserInfo();

  const isUserAdmin = userInfo?.role?.includes(rolesWithName.ADMIN) || false;

  return (
    <S.HeaderContainer>
      <S.Title>{t('vendorCommunication')}</S.Title>
      {vendor && !vendor.isFirm && !isUserAdmin && (
        <Button onClick={handleNewMessage} kind="primary">
          {t('sendNewMessage')}
        </Button>
      )}
    </S.HeaderContainer>
  );
};

export default Header;
