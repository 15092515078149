import { postRequest, getRequest } from 'lib/axios';
import { apiGateway } from 'constants/services';

export const getSelectListItems = (url, reqType, args) => {
  if (reqType === 'post') {
    return postRequest(`${apiGateway}/${url}`, args);
  }

  return getRequest(`${apiGateway}/${url}`, args);
};
