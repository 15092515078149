import * as Types from './types';

const initialState = {
  orderDetail: undefined,
  error: {},
  showModal: false,
  mapModalVendor: {
    data: undefined,
    error: undefined,
  },
};

function OrderDetailReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.MAPMODAL_SUCCESS:
      return {
        ...state,
        orderDetail: payload,
        showModal: true,
      };
    case Types.MAPMODAL_ERROR:
      return {
        ...state,
        error: payload,
        showModal: false,
      };
    case Types.MAPMODAL_VENDOR_SUCCESS:
      return {
        ...state,
        mapModalVendor: {
          data: payload,
        },
      };
    case Types.MAPMODAL_VENDOR_ERROR:
      return {
        ...state,
        mapModalVendor: {
          error: payload,
        },
      };
    case Types.MAPMODAL_RESET:
      return {
        ...state,
        orderDetail: undefined,
        showModal: false,
      };
    default:
      return state;
  }
}

export default OrderDetailReducer;
