import * as Types from './types';

const initialState = {
  modalOptions: {
    isModalOpen: false,
    modalType: '',
    threadId: '',
  },
  threadDetail: {
    list: [],
    toUserName: undefined,
    isLocked: false,
  },
  threadList: [],
  paginationSettings: {
    totalElements: 0,
    totalPages: 0,
  },
  error: null,
};

function VendorCommunicationReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    // Modal Open and Close Reducers
    case Types.CLOSE_MESSAGE_MODAL:
      return {
        ...state,
        modalOptions: {
          ...state.modalOptions,
          isModalOpen: false,
          modalType: '',
          threadId: '',
        },
      };
    case Types.OPEN_MESSAGE_MODAL:
      return {
        ...state,
        modalOptions: {
          ...state.modalOptions,
          ...payload,
        },
      };
    // Thread List Reducers
    case Types.GET_THREAD_LIST_SUCCESS:
      return {
        ...state,
        threadList: payload.vendorMessages,
        paginationSettings: {
          totalElements: payload.totalElements,
          totalPages: payload.totalPages,
        },
      };
    case Types.SET_THREAD_DETAIL:
      return {
        ...state,
        threadDetail: {
          ...state.threadDetail,
          ...payload,
        },
      };
    case Types.GET_THREAD_LIST_ERROR:
      return {
        ...state,
        error: payload,
      };
    // Thread Detail Reducers
    case Types.GET_THREAD_DETAIL_SUCCESS:
      return {
        ...state,
        threadDetail: {
          ...state.threadDetail,
          list: payload,
        },
      };
    case Types.GET_THREAD_DETAIL_ERROR:
      return {
        ...state,
        error: payload,
      };
    // change read status
    case Types.CHANGE_READ_STATUS_SUCCESS:
      return {
        ...state,
        threadList: state.threadList.map(thread => {
          if (thread.messageId === payload) {
            return {
              ...thread,
              unreadMessageCount: 0,
            };
          }

          return thread;
        }),
      };
    // Send Message Reducers
    case Types.SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        threadDetail: {
          ...state.threadDetail,
          list: payload.vendorMessageContents,
        },
      };
    case Types.RESET_ALL_STATES:
      return {
        ...initialState,
      };
    case Types.CLEAR_THREAD_DETAIL:
      return {
        ...state,
        threadDetail: {
          list: [],
          fromName: '',
        },
      };
    default:
      return state;
  }
}

export default VendorCommunicationReducer;
