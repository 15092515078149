import React from 'react';

import DotSeparator from 'components/DotSeparator';
import LoginWaterFooterImage from 'common/svg/loginWaterFooter.svg';
import Link from 'components/Link';

import {
  FooterImage,
  FooterWrapper,
  FooterRightContainer,
  FooterItemText,
} from './style';

const Footer = () => (
  <FooterWrapper>
    <FooterImage src={LoginWaterFooterImage} alt="getirsu" title="getirsu" />
    <FooterRightContainer>
      <FooterItemText>©{new Date().getFullYear()} Getir</FooterItemText>
      <DotSeparator />
      <Link
        href="https://getir.com/yardim/gizlilik-politikasi/"
        text="Gizlilik Sözleşmesi"
      />
      <DotSeparator />
      <Link
        href="https://getir.com/yardim/kullanim-kosullari/"
        text="Kullanım Koşulları"
      />
    </FooterRightContainer>
  </FooterWrapper>
);

export default Footer;
