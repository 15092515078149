import styled from 'styled-components';
import { Button } from '@getir/web-components';

import breakpoint from 'constants/breakpoint';

export const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  ${Button.S.Wrapper} {
    margin-bottom: 8px;
  }
`;

export const PdfContent = styled.div`
  width: 100%;
  overflow: scroll;

  canvas {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .annotationLayer {
    height: 0 !important;
  }

  @media (max-width: ${breakpoint.MOBILE.MAX}px) {
    canvas {
      width: 100% !important;
      height: auto !important;
    }
  }
  @media (max-width: ${breakpoint.TABLET.MAX}px) {
    canvas {
      width: 100% !important;
      height: auto !important;
    }
  }
`;

export const PaginationArea = styled.div`
  margin-top: 24px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const PaginationLeftArrow = styled.div`
  margin-right: 8px;
  span {
    margin-right: 5px;
  }
`;

export const PaginationRightArrow = styled.div`
  margin-left: 8px;
  span {
    margin-left: 5px;
  }
`;
