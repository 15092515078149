import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import L from 'leaflet';

import useVendorPolygon from '../../hooks/useVendorPolygon';
import useDistrictPolygon from '../../hooks/useDistrictPolygon';
import useNeighbourhoodPolygon from '../../hooks/useNeighbourhoodPolygon';
import useSelectedNeighbourhoodPolygon from '../../hooks/useSelectedNeighbourhoodPolygon';
import 'leaflet/dist/leaflet.css';

const TILE_LAYERS = {
  OSM: {
    URL: 'http://a.tile.openstreetmap.org/{z}/{x}/{y}.png',
    NAME: 'OSM',
    MAX_ZOOM: 20,
    MIN_ZOOM: 0,
  },
};
function MapWrapper({ geomapStateKey, Actions }) {
  const geomapcenter = useSelector(state => state[geomapStateKey].center);
  const [mapData, setMapData] = useState();
  const TR_CENTER_COOR = {
    lat: '38.9637',
    lon: '35.2433',
  };

  useDistrictPolygon({ geomapStateKey, Actions, mapInstance: mapData });
  useVendorPolygon({ geomapStateKey, Actions, mapInstance: mapData });
  useNeighbourhoodPolygon({ geomapStateKey, Actions, mapInstance: mapData });
  useSelectedNeighbourhoodPolygon({
    geomapStateKey,
    Actions,
    mapInstance: mapData,
  });

  useEffect(() => {
    if (!mapData) {
      const map = L.map('map', { scrollWheelZoom: false }).setView(
        geomapcenter
          ? [geomapcenter.coordinates[1], geomapcenter.coordinates[0]]
          : [TR_CENTER_COOR.lat, TR_CENTER_COOR.lon],
        6,
      );

      setMapData(map);
      map.once('focus', () => {
        map.scrollWheelZoom.enable();
      });

      L.tileLayer(TILE_LAYERS.OSM.URL).addTo(map);
    }
  }, [TR_CENTER_COOR.lat, TR_CENTER_COOR.lon, geomapcenter, mapData]);

  useEffect(() => {
    if (mapData) {
      if (geomapcenter) {
        mapData.setView(
          [geomapcenter.coordinates[1], geomapcenter.coordinates[0]],
          9,
        );

        return;
      }
      mapData.setView([TR_CENTER_COOR.lat, TR_CENTER_COOR.lon], 5);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geomapcenter]);

  return (
    <div
      style={{
        height: 500,
        width: '100%',
      }}
      id="map"
    ></div>
  );
}

MapWrapper.propTypes = {
  geomapStateKey: PropTypes.string.isRequired,
  Actions: PropTypes.shape({
    setSelectedVendor: PropTypes.func,
    setEditedMap: PropTypes.func,
  }).isRequired,
};

export default memo(MapWrapper);
