import { apiGateway } from 'constants/services';

export default {
  getOrders: `${apiGateway}/order-service/get-orders-by-type-status-and-date-interval`,
  getComletedOrders: `${apiGateway}/order-service/get-orders-by-type-status-and-date-interval`,
  changeOrderStatus: `${apiGateway}/order-service/update/status`,
  sendSMSCourier: `${apiGateway}/ivr-service/send-order-info-to-courier`,
  getUserPopup: `${apiGateway}/user-service/users/popups`,
  sendPopupFeedback: `${apiGateway}/user-service/users/feedback`,
  getCourierList: `${apiGateway}/vendor-service/vendors/vendor/users`,
};
