import styled from 'styled-components';

import breakpoint from 'constants/breakpoint';

const FooterWrapper = styled.footer`
  display: grid;
  grid-column: 1 / 3;
  background-color: rgb(245, 245, 245);
  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: ${breakpoint.TABLET_MINI.MIN}px) {
    grid-column: auto / auto;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    place-items: center;
    margin-right: 48px;
  }

  @media screen and (max-width: ${breakpoint.MOBILE.MAX}px) {
    margin: 0;
    margin-top: auto;
    padding: 24px 0 12px 0;
  }
`;

const FooterImage = styled.img`
  height: 100%;
  width: 125px;
  margin: 0px 0px 0px 60px;

  @media screen and (max-width: ${breakpoint.TABLET_MINI.MIN}px) {
    margin: 0;
  }
`;

const FooterRightContainer = styled.div`
  display: grid;
  gap: 12px;
  place-items: center;
  place-content: center;
  grid-auto-flow: column;
`;

const FooterItemText = styled.p`
  margin: 0px;
  color: #a1a1a1;
  letter-spacing: 0.1px;
  text-align: left;
  font-size: 12px;
`;

export { FooterWrapper, FooterImage, FooterRightContainer, FooterItemText };
