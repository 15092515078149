import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styles';

const Table = ({
  data,
  pagination,
  handlePaginationChange,
  columns,
  ...props
}) => (
  <S.Table
    columns={columns}
    dataSource={data}
    pagination={pagination}
    scroll={{ x: 1500 }}
    size="small"
    rowKey="id"
    onChange={handlePaginationChange}
    {...props}
  />
);

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  pagination: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.bool,
  ]),
  handlePaginationChange: PropTypes.func,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
};

Table.defaultProps = {
  pagination: false,
  data: [],
  handlePaginationChange: () => {},
};
export default Table;
