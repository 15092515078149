import React, { useState, useEffect, useRef } from 'react';
import { Empty } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Tabs, Button } from '@getir/web-components';
import dayjs from 'dayjs';

import Layout from 'components/Layout';
import translate from 'utils/translate';
import * as CS from 'common/style';
import * as ROUTES from 'constants/routes';

import * as S from './style';
import FilterArea from './FilterArea';
import tabTypes from './constants/tabTypes';
import * as RequestActions from './actions';
import PriceUpdateModal from './Modals/PriceUpdate';
import StrikedPriceUpdateModal from './Modals/StrikedPriceUpdate';
import createColumns from './hooks/createColumns';

const { TabPane } = Tabs;

const requestTypeEnum = {
  UPDATE_PRICE: 'UPDATE_VENDOR_PRODUCT_PRICE',
  UPDATE_STRIKED_PRICE: 'UPDATE_VENDOR_PRODUCT_STRIKETHROUGH_PRICE',
  UPDATE_WORKING_HOURS: 'UPDATE_WORKING_HOURS',
  UPDATE_BULK_CHANGE: 'UPDATE_BULK_CHANGES',
};

const requestTraEnum = {
  UPDATE_VENDOR_PRODUCT_PRICE: translate('requests:updatePrice'),
  UPDATE_VENDOR_PRODUCT_STRIKETHROUGH_PRICE: translate(
    'requests:updateStrikedPrice',
  ),
  UPDATE_WORKING_HOURS: translate('requests:updateWorkingHours'),
  UPDATE_BULK_CHANGES: translate('requests:updateBulkChange'),
};

const tabList = [
  {
    key: tabTypes.pendingRequests,
    translate: 'requests:firstTabTitle',
  },
  {
    key: tabTypes.confirmedRequests,
    translate: 'requests:secondTabTitle',
  },
  {
    key: tabTypes.declinedRequests,
    translate: 'requests:thirdTabTitle',
  },
];

const Requests = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const [actionData, setActionData] = useState(undefined);
  const [requestId, setRequestId] = useState(undefined);
  const [requestStatus, setRequestStatus] = useState(undefined);
  const [isPriceUpdateModalVisible, setIsPriceUpdateModalVisible] = useState(
    false,
  );
  const [
    isStrikedPriceUpdateModalVisible,
    setIsStrikedPriceUpdateModalVisible,
  ] = useState(false);
  const requestList = useSelector(state => state.requests.requests.data);
  const requestType = useSelector(state => state.requests.requestType);
  const vendorInfo = useSelector(state => state.mainHeader.vendorInfo);

  const [selectedTabKey, setSelectedTabKey] = useState(requestType);

  const priceModalRef = useRef();
  const strikedPriceModalRef = useRef();

  useEffect(() => {
    dispatch(RequestActions.setRequestType(requestType));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(RequestActions.requestsListRequest());
  }, [selectedTabKey, dispatch, vendorInfo.id]);

  const tableColumns = [
    {
      title: translate('requests:cityName'),
      key: 'city',
      dataIndex: 'city',
      render: city => city.name,
      width: '100px',
    },
    {
      title: translate('requests:firmName'),
      key: 'firm',
      dataIndex: 'firm',
      render: firm => firm.name,
      width: 150,
    },
    {
      title: translate('requests:vendorsName'),
      key: 'vendor',
      dataIndex: 'vendor',
      render: vendor => {
        if (vendor) {
          return vendor.name;
        }

        return '';
      },
    },
    {
      title: translate('requests:requestType'),
      key: 'type',
      dataIndex: 'type',
      render: type => requestTraEnum[type],
    },
    {
      title: translate('requests:requestDate'),
      key: 'createdAt',
      dataIndex: 'createdAt',
      render: createdAt => dayjs(createdAt).format('DD/MM/YYYY HH:mm'),
    },
    {
      title: translate('requests:operationalManager'),
      key: 'operationalManager',
      dataIndex: 'operationalManager',
      render: operationalManager => operationalManager.name,
    },
    {
      title: translate('actions'),
      key: 'settings',
      render: (_, record) => (
        <Button kind="secondary" onClick={() => openActionModal(record)}>
          {translate('requests:showDetails')}
        </Button>
      ),
      align: 'center',
      width: '100px',
      fixed: 'right',
    },
  ];

  const columns = createColumns(selectedTabKey, tableColumns);

  const handleChangeTab = key => {
    dispatch(RequestActions.setRequestType(key));
    setSelectedTabKey(key);
  };

  const openActionModal = record => {
    const { vendor } = record;
    const parsedActions = JSON.parse(record.actions);
    if (vendor.id) {
      parsedActions.vendorName = vendor.name;
    }
    setRequestId(record.id);
    setRequestStatus(record.status);
    setActionData(parsedActions);

    if (record.type === requestTypeEnum.UPDATE_PRICE) {
      setIsPriceUpdateModalVisible(true);

      return;
    }
    if (record.type === requestTypeEnum.UPDATE_STRIKED_PRICE) {
      setIsStrikedPriceUpdateModalVisible(true);

      return;
    }
    if (record.type === requestTypeEnum.UPDATE_WORKING_HOURS) {
      history.push({
        pathname: ROUTES.REQUESTS_WORKING_HOURS,
        state: {
          data: {
            parsedActions,
            requestId: record.id,
            requestStatus: record.status,
          },
        },
      });

      return;
    }

    if (record.type === requestTypeEnum.UPDATE_BULK_CHANGE) {
      history.push({
        pathname: ROUTES.REQUESTS_BULK_CHANGE,
        state: {
          data: {
            parsedActions,
            requestId: record.id,
            requestStatus: record.status,
          },
        },
      });
    }
  };

  const handlePriceUpdateModalVisible = value => {
    setIsPriceUpdateModalVisible(value);
  };

  const handleStrikedPriceUpdateModalVisible = value => {
    setIsStrikedPriceUpdateModalVisible(value);
  };

  return (
    <Layout>
      <CS.PageHeader>
        <CS.PageTitle>{t('requests:pageTitle')}</CS.PageTitle>
      </CS.PageHeader>
      <CS.PageBody>
        <S.StyledTab
          defaultActiveKey={selectedTabKey}
          onChange={handleChangeTab}
          size="small"
        >
          {tabList.map(tab => (
            <TabPane tab={t(tab.translate)} key={tab.key}>
              <S.StyledTable
                columns={columns}
                dataSource={requestList}
                rowKey="id"
                pagination={{ size: 'small', position: ['bottomCenter'] }}
                locale={{
                  emptyText: <Empty description={t('table.noData')} />,
                }}
                title={() => <FilterArea />}
                scroll={{ x: 1000 }}
              />
            </TabPane>
          ))}
        </S.StyledTab>
      </CS.PageBody>
      <PriceUpdateModal
        modalRef={priceModalRef}
        size="580px"
        visible={isPriceUpdateModalVisible}
        getVisible={handlePriceUpdateModalVisible}
        data={actionData}
        requestId={requestId}
        requestStatus={requestStatus}
      />
      <StrikedPriceUpdateModal
        modalRef={strikedPriceModalRef}
        size="580px"
        visible={isStrikedPriceUpdateModalVisible}
        getVisible={handleStrikedPriceUpdateModalVisible}
        data={actionData}
        requestId={requestId}
        requestStatus={requestStatus}
      />
    </Layout>
  );
};
export default Requests;
