import { getRequest, postRequest, putRequest, deleteRequest } from 'lib/axios';

import api from './apiRoutes';

export const getRoleListApi = args => getRequest(api.getRoleList(args));
export const createRoleApi = data => postRequest(api.createRole, data);
export const deleteRoleApi = args => deleteRequest(api.deleteRole(args));
export const getRoleUsersByRoleIdApi = args =>
  getRequest(api.getRoleUsersByRoleId(args));
export const updateRoleApi = ({ id, ...args }) =>
  putRequest(api.updateRole(id), args);
export const getPermissionsByRoleIdApi = args =>
  getRequest(api.getPermissionsByRoleId(args));
export const getRoleGroupNamesApi = args =>
  getRequest(api.getRoleGroupNames(args));
