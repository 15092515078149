import * as Types from './types';

const initialState = {
  alternativeMapIntervals: undefined,
  error: undefined,
};

function AlternativeMapHoursReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case Types.ALTERNATIVE_HOURS_GET_SUCCESS:
      return {
        ...state,
        alternativeMapIntervals: payload.alternativeMapIntervals,
      };
    case Types.ALTERNATIVE_HOURS_GET_ERROR:
      return {
        ...state,
        alternativeMapIntervals: undefined,
        error: payload,
      };
    case Types.ALTERNATIVE_HOURS_UPDATE_SUCCESS:
      return {
        ...state,
      };
    case Types.ALTERNATIVE_HOURS_UPDATE_ERROR:
      return {
        ...state,
        alternativeMapIntervals: undefined,
        error: payload,
      };
    default:
      return state;
  }
}

export default AlternativeMapHoursReducer;
