import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { isNull } from 'lodash';

import useQuery from 'hooks/useQuery';

import {
  orderPaymentSelectItems,
  orderStateSelectItems,
  standardPaymentList,
  standardStatusList,
} from './constants';
import * as PreviousOrdersActions from './actions';

const usePreviousOrderActions = (
  statusItems,
  paymentItems,
  dateRangeValues,
  pagination,
) => {
  const dispatch = useDispatch();
  const vendorInfo = useSelector(state => state.mainHeader.vendorInfo);
  const params = useQuery();

  useEffect(() => {
    const paramVendorId = params.get('vendorId');
    const paramOrderDate = params.get('date');

    if (!isNull(paramVendorId) && !isNull(paramOrderDate)) {
      return;
    }
    if (vendorInfo.id) {
      const selectedPaymentItems = paymentItems
        .map(
          item => orderPaymentSelectItems.find(i => i.labelKey === item).values,
        )
        .flat();
      const selectedStatusItems = statusItems
        .map(
          item => orderStateSelectItems.find(i => i.labelKey === item).values,
        )
        .flat();

      const data = {
        statusList:
          selectedStatusItems.length > 0
            ? selectedStatusItems.flat()
            : standardStatusList,
        vendorId: vendorInfo.id,
        startDate: dateRangeValues.startDate
          ? dateRangeValues.startDate
          : dayjs(new Date(2021, 1, 1)).toISOString(),
        endDate: dateRangeValues.endDate
          ? dateRangeValues.endDate
          : dayjs().subtract(1, 'd').endOf('day').toISOString(),
        paymentType:
          selectedPaymentItems.length > 0
            ? selectedPaymentItems.flat()
            : standardPaymentList,
        page: pagination.current - 1,
        size: pagination.pageSize,
        invoiceStatusList: [1, 2, 3, 4],
      };

      dispatch(PreviousOrdersActions.getFilteredOrdersRequest(data));
    }
    // eslint-disable-next-line
  }, [vendorInfo, pagination.current, pagination.pageSize]);
};

export { usePreviousOrderActions };
