import styled from 'styled-components';
import { Input as AntdInput } from 'antd';

const sizes = {
  small: {
    height: '32px',
    padding: '9px 12px',
  },
  medium: {
    height: '48px',
    padding: '15px 12px',
  },
  large: {
    height: '56px',
    padding: '19px 12px',
  },
};
export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  input {
    box-sizing: border-box;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
export const InfoText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #697488;
  ${({ $error }) => $error && `color: #D13333;`}
`;
export const Input = styled(AntdInput)`
  ${({ $size }) =>
    !!$size &&
    `height: ${sizes[$size].height};
     padding: ${sizes[$size].padding};
  `}

  ${({ addonAfter }) =>
    !!addonAfter &&
    `
    border: 0px solid #D4D4D4;
    .ant-input-wrapper{
    height: 100%;
    input{
      height: 100%;
    }
    }
    padding: 0;
    
  `}

  svg {
    height: 20px !important;
    width: 20px !important;
  }
`;
