const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';

const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
const LOGOUT_ERROR = 'LOGOUT_ERROR';

export const CHANGE_USER_ROLE_REQUEST = 'CHANGE_USER_ROLE_REQUEST';
export const CHANGE_USER_ROLE_SUCCESS = 'CHANGE_USER_ROLE_SUCCESS';
export const CHANGE_USER_ROLE_ERROR = 'CHANGE_USER_ROLE_ERROR';

export const GET_ROLES_BY_USER_ID_REQUEST =
  'HEADER_GET_ROLES_BY_USER_ID_REQUEST';
export const GET_ROLES_BY_USER_ID_SUCCESS =
  'HEADER_GET_ROLES_BY_USER_ID_SUCCESS';
export const GET_ROLES_BY_USER_ID_ERROR = 'HEADER_GET_ROLES_BY_USER_ID_ERROR';

export {
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_ERROR,
};
