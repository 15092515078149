import { apiGateway } from 'constants/services';

export default {
  addFinancialTransaction: `${apiGateway}/finance-service/admin/transaction`,
  uploadPremiumFile: `${apiGateway}/finance-service/admin/transaction/bulk/csv`,
  savePremiumTransaction: params => {
    const { bulkPremiumId, note, documentDate } = params;

    return `${apiGateway}/finance-service/admin/transaction/bulk?bulkPremiumId=${bulkPremiumId}&note=${note}&documentDate=${documentDate}`;
  },
};
