import { takeLatest } from 'redux-saga/effects';

import { sendMissingOrderApi } from 'services/MissingProduct/api';
import { getOrderApi } from 'services/OrderDetail/api';
import { createStandardSaga } from 'utils/createStandardSaga';

import * as MissingProductActions from './actions';
import * as Types from './types';

const getOrderDetailSaga = createStandardSaga({
  callApi: getOrderApi,
  successAction: MissingProductActions.getMissingProductSuccess,
  errorAction: MissingProductActions.getMissingProductError,
});

const sendMissingOrderSaga = createStandardSaga({
  callApi: sendMissingOrderApi,
  successAction: MissingProductActions.sendMissingProductSuccess,
  errorAction: MissingProductActions.sendMissingProductError,
});

export function* rootSaga() {
  yield takeLatest(Types.MISSINGPRODUCT_ORDER_REQUEST, getOrderDetailSaga);
  yield takeLatest(Types.MISSINGPRODUCT_SEND_REQUEST, sendMissingOrderSaga);
}

export default rootSaga;
