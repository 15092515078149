import { useSelector, useDispatch } from 'react-redux';
import _, { get } from 'lodash';

import * as VendorActions from 'containers/Stores/Vendor/actions';

const useVendorOnSubmit = ({
  editSelectBoxFormData,
  editUserSelectBoxFormData,
  getFieldsEditable,
}) => {
  const dispatch = useDispatch();
  const vendorInfo = useSelector(state => state.mainHeader.vendorInfo);

  return data => {
    const {
      vendorName,
      vendorPhoneNumber,
      commissionRate,
      city,
      searchAddress,
      address,
      operationalManager,
      regionManager,
      firm,
      maxDeliveryTime,
      minDeliveryTime,
      minBasketAmount,
      scheduledDeliveryOption,
      cashOnDelivery,
      mersisNumber,
      kepAddress,
      label,
      town,
      district,
      brand,
      isExchangeFee,
    } = data;

    const addressData = {
      city: editSelectBoxFormData(city),
      district: editSelectBoxFormData(district),
      town: editSelectBoxFormData(town),
      latitude: searchAddress.cordinates
        ? searchAddress.cordinates[0]
        : searchAddress.lat,
      longitude: searchAddress.cordinates
        ? searchAddress.cordinates[1]
        : searchAddress.long,
      openAddress: address,
      searchAddress: searchAddress.name,
    };

    const resultData = {
      id: vendorInfo.id,
      address: addressData,
      adminOperationManager: editUserSelectBoxFormData(operationalManager),
      adminRegionalManager: editUserSelectBoxFormData(regionManager),
      brands: _.isArray(brand) ? [brand[0].value] : [brand.value],
      firm: editSelectBoxFormData(firm),
      maxDeliveryTime: maxDeliveryTime.value
        ? maxDeliveryTime.value
        : maxDeliveryTime,
      minDeliveryTime: minDeliveryTime.value
        ? minDeliveryTime.value
        : minDeliveryTime,
      minShoppingCartAmount: minBasketAmount ? Number(minBasketAmount) : 0,
      scheduledDeliveryOption,
      cashOnDelivery,
      vendorName,
      mersisNumber,
      kepAddress,
      vendorPhoneNumber,
      label,
      commissionRate: Number(commissionRate),
      isExchangeFee: get(isExchangeFee, 'value', isExchangeFee),
    };

    dispatch(VendorActions.updateVendorRequest(resultData));
    getFieldsEditable(false);
  };
};

export { useVendorOnSubmit };
