import { getRequest, postRequest } from 'lib/axios';

import api from './apiRoutes';

export const getReportsApi = args =>
  postRequest(api.getReports, args.body, {
    params: args.query,
  });

export const createReportApi = args =>
  postRequest(api.createReport, args.body, args.query);

export const getDownloadURLApi = args =>
  getRequest(api.getDownloadURL(args.token), args.query);
