import React, { useCallback, useEffect, useState } from 'react';
import { Divider, Space } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import * as CS from 'common/style';
import Button from 'components/v1/Button';
// import SelectBoxWrapper from 'components/SelectBoxWrapper';
import InputWrapper from 'components/InputWrapper';
import * as ROUTES from 'constants/routes';
import Uploader from 'components/Uploader';
import apiRoutes from 'services/Brand/apiRoutes';
import { getSelectListItems } from 'components/SelectBoxWrapper/api';
import { open } from 'utils/notification';

import * as BrandActions from '../actions';

import { useValidations } from './useValidations';
import * as S from './style';

const BrandForm = ({ id }) => {
  const history = useHistory();
  const [brandInfo, setBrandInfo] = useState({});
  const { t } = useTranslation(['common', 'brands', 'catalog']);
  const brands = useSelector(state => state.brand.brands);
  const dispatch = useDispatch();
  const { validationRules } = useValidations();

  useEffect(() => {
    if (id) {
      const tmpBrandInfo = brands.find(item => item.id === id);
      if (tmpBrandInfo) setBrandInfo(tmpBrandInfo);
    }
  }, [brands, id]);

  useEffect(() => {
    if (!brands) dispatch(BrandActions.brandListRequest());
  }, [dispatch, brands]);

  const setImage = url => {
    setValue('brandLogoUrl', url, {
      shouldValidate: true,
    });
  };

  const removeImage = () => {
    setValue('brandLogoUrl', undefined, {
      shouldValidate: true,
    });
  };

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    mode: 'all',
  });
  const [list, setList] = useState([]);

  useEffect(() => {
    if (Object.keys(brandInfo).length > 0) {
      setValue('brandName', brandInfo.brandName, { shouldValidate: true });
      if (brandInfo.firm && brandInfo.firm.id)
        setValue('firm', brandInfo.firm, { shouldValidate: true });
      setValue('label', brandInfo.label, { shouldValidate: false });
      setValue('brandLogoUrl', brandInfo.brandLogoUrl, {
        shouldValidate: true,
      });
    }
  }, [brandInfo, setValue]);

  const onSubmit = formData => {
    const data = {
      ...formData,
      label: formData.label && formData.label.value ? formData.label.value : '',
      firm: {
        id: formData.firm.value || formData.firm.id,
        name: formData.firm.label || formData.firm.name,
      },
    };

    if (id) data.brandId = id;

    const requestAction = id
      ? BrandActions.updateBrandRequest
      : BrandActions.addBrandRequest;

    dispatch(requestAction(data));
  };

  const getList = useCallback(
    async (apiUrl, apiData) => {
      try {
        const result = await getSelectListItems(apiUrl, 'get', apiData);
        const payloadData = result.data.payload.firms;
        const resultList = payloadData.map(item => ({
          value: item.id,
          label: item.firmName,
        }));
        setList(resultList);
      } catch (error) {
        open('warning', t('common:notif.warnTitle'), error.message);
      }
    },
    [t],
  );

  useEffect(() => {
    getList('firm-service/admin/get-all');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CS.PageContent>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputWrapper
          fieldName="brandName"
          control={control}
          rules={validationRules.brandName}
          placeholder={t('brands:brandname')}
          header={t('brands:brandname')}
          errors={errors}
        />

        <Controller
          name="brandLogoUrl"
          control={control}
          rules={validationRules.brandLogoUrl}
          render={({ field }) => (
            <Uploader
              name="logo"
              uploadUrl={apiRoutes.saveLogo}
              onSuccess={setImage}
              onRemove={removeImage}
              multiple={false}
              field={field}
              errors={errors}
              fieldName="brandLogoUrl"
              style={{ marginBottom: 0, padding: '2px' }}
            />
          )}
        />
        {errors.brandLogoUrl && (
          <CS.ErrorMessage>{errors.brandLogoUrl.message}</CS.ErrorMessage>
        )}

        <CS.FormFieldWrapper>
          <S.SelectBoxPlaceholder>{t('catalogs:firm')}</S.SelectBoxPlaceholder>
          <Controller
            name="firm"
            control={control}
            rules={validationRules.firm}
            render={({ field }) => (
              <S.SelectBox
                options={list}
                placeholder={t('catalogs:firm')}
                inputRef={field.ref}
                onChange={value => {
                  field.onChange(value);
                }}
                onBlur={field.onBlur}
                name={field.name}
                value={list.find(item => {
                  if (field.value !== undefined) {
                    return field.value.id === item.value;
                  }

                  return false;
                })}
                hasError={!!errors.firm}
                showSearch
                labelInValue
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLocaleLowerCase('tr')
                    .includes(input.toLocaleLowerCase('tr'))
                }
              />
            )}
          />
          {errors.firm && (
            <CS.ErrorMessage>{errors.firm.message}</CS.ErrorMessage>
          )}
        </CS.FormFieldWrapper>

        <Divider />
        <Space size="middle">
          <Button
            kind="secondary"
            style={{
              width: '100%',
            }}
            onClick={() => history.push(ROUTES.BRANDS)}
          >
            {t('cancel')}
          </Button>
          <Button
            kind="primary"
            style={{
              width: '100%',
            }}
            type="submit"
          >
            {t('save')}
          </Button>
        </Space>
      </form>
    </CS.PageContent>
  );
};

BrandForm.propTypes = {
  id: PropTypes.string,
};

BrandForm.defaultProps = {
  id: undefined,
};

export default BrandForm;
