import React, { useEffect, useRef, useState } from 'react';
import { Empty } from 'antd';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import * as BrandActions from 'containers/Stores/Brands/actions';
import * as CategoryActions from 'containers/CategoryManagement/Category/actions';
import ConfirmModal from 'components/ConfirmModal';
import { pushToScreenBy } from 'utils/pushToScreenBy';
import * as ROUTES from 'constants/routes';

import { extractProductsDataFrom } from '../helpers/extractProductsDataFrom';
import { filterProducts } from '../helpers/filterProducts';
import * as CatalogActions from '../actions';

import * as S from './style';
import CatalogProductListHeader from './CatalogProductListHeader';
import { useCatalogProductColumns } from './useCatalogProductColumns';

const defaultValues = {
  categoryName: undefined,
  brandName: undefined,
};

const List = () => {
  const [modalState, setModalState] = useState({
    visible: false,
    id: null,
  });
  const { t } = useTranslation();
  const history = useHistory();
  const listHeaderRef = useRef();
  const pushToProductEdit = pushToScreenBy(history, ROUTES.EDIT_CATALOG);
  const [filters, setFilters] = useState({
    brand: undefined,
    category: undefined,
  });
  const [searchText, setSearchText] = useState('');
  const columns = useCatalogProductColumns(pushToProductEdit, setModalState);
  const dispatch = useDispatch();

  const categories = useSelector(state => state.category.categorys);
  const brands = useSelector(state => state.brand.brands);

  const { products: catalogProducts, deleteProduct } = useSelector(
    state => state.catalogProduct,
  );

  useEffect(() => {
    dispatch(CatalogActions.catalogProductListRequest());
  }, [dispatch, deleteProduct]);

  useEffect(() => {
    if (categories === undefined)
      dispatch(CategoryActions.categoryListRequest());
  }, [dispatch, categories]);

  useEffect(() => {
    if (brands === undefined) dispatch(BrandActions.brandListRequest());
  }, [dispatch, brands]);

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    mode: 'onBlur',
  });

  const onSubmit = formData => {
    setFilters({
      brand: formData.brandName.value,
      category: formData.categoryName.value,
    });
  };

  const resetValues = () => {
    setValue('brandName', { value: 0 });
    setValue('categoryName', { value: 0 });
  };
  const onReset = () => {
    resetValues();
    handleSubmit(onSubmit)();
  };

  useEffect(() => {
    if (resetValues) resetValues();
    // eslint-disable-next-line
  }, []);

  const handleDeleteCatalogModalSave = () => {
    dispatch(CatalogActions.deleteCatalogProductRequest({ id: modalState.id }));
    handleDeleteCategoryModalClose();
  };

  const handleDeleteCategoryModalClose = () => {
    setModalState({
      visible: false,
      id: null,
    });
  };

  return (
    <S.ListContainer>
      <CatalogProductListHeader
        ref={listHeaderRef}
        handleSubmit={handleSubmit(onSubmit)}
        control={control}
        onReset={onReset}
        errors={errors}
        defaultValues={defaultValues}
        searchText={searchText}
        setSearchText={setSearchText}
      />
      <S.CatalogProductistTable
        columns={columns}
        dataSource={extractProductsDataFrom(
          filterProducts(catalogProducts || [], filters, searchText),
          brands,
          categories,
        )}
        pagination={{ size: 'small', position: ['bottomCenter'] }}
        locale={{ emptyText: <Empty description={t('table.noData')} /> }}
        rowKey="id"
        scroll={{ x: 800 }}
        bordered
      />
      <ConfirmModal
        size="400px"
        visible={modalState.visible}
        onSave={handleDeleteCatalogModalSave}
        onClose={handleDeleteCategoryModalClose}
        title={t('catalogs:deleteCatalogProductTitle')}
        content={t('catalogs:deleteCatalogProductText')}
        cancelText={t('cancel')}
      />
    </S.ListContainer>
  );
};

export default List;
