import { takeLatest, call } from '@redux-saga/core/effects';
import { put } from 'redux-saga/effects';

import * as LoaderActions from 'components/Loader/actions';
import {
  getBannedVendorsApi,
  getMapApi,
  saveMapApi,
  getVendorsApi,
  getNeighbourhoodsApi,
  getDistrictsApi,
} from 'services/GeoMap/api';
import { createStandardSaga } from 'utils/createStandardSaga';
import { open } from 'utils/notification';
import translate from 'utils/translate';

import * as Types from './types';
import * as BanGeomapActions from './actions';

const geomapGetSaga = createStandardSaga({
  callApi: getMapApi,
  successAction: BanGeomapActions.banGeomapGetSuccess,
  errorAction: BanGeomapActions.banGeomapGetError,
  successMessage: 'geomap:common.messages.getsuccess',
});

const geomapSaveSaga = createStandardSaga({
  callApi: saveMapApi,
  successAction: BanGeomapActions.banGeomapSaveSuccess,
  errorAction: BanGeomapActions.banGeomapSaveError,
  successMessage: 'geomap:common.messages.savesuccess',
});

const geomapGetBannedVendorsSaga = createStandardSaga({
  callApi: getBannedVendorsApi,
  successAction: BanGeomapActions.banGeomapGetBannedVendorsSuccess,
  errorAction: BanGeomapActions.banGeomapGetBannedVendorsError,
  successMessage: 'geomap:common.messages.getsuccess',
});

function* geomapGetNeighbourhoodSaga({ payload }) {
  yield put(LoaderActions.showLoader());
  if (!payload || !payload.districtId) {
    yield put(BanGeomapActions.banGeomapGetNeighbourhoodSuccess([]));
    yield put(BanGeomapActions.banGeomapSetEditedMap([]));
    yield put(LoaderActions.hideLoader());

    return;
  }
  try {
    const result = yield call(getNeighbourhoodsApi, payload);

    if (result.data) {
      const neighbourhood = result.data.payload.features.map(feature => ({
        id: feature.properties.id,
        name: feature.properties.nameTr,
        polygon: feature.geometry.coordinates,
      }));

      yield put(
        BanGeomapActions.banGeomapGetNeighbourhoodSuccess(neighbourhood),
      );

      open(
        'success',
        translate('common:notif.successTitle'),
        translate('geomap:common.messages.neighbourhoodsGetSuccess'),
      );
    }

    if (result.message) {
      open('warning', translate('common:notif.warnTitle'), result.message);
    }
  } catch (error) {
    yield put(BanGeomapActions.banGeomapGetNeighbourhoodError(error.message));
  }
  yield put(LoaderActions.hideLoader());
}

function* geomapGetDistrictSaga({ payload }) {
  yield put(LoaderActions.showLoader());

  try {
    const result = yield call(getDistrictsApi, payload);
    if (result.data) {
      const districts = result.data.payload.features.map(feature => ({
        id: feature.properties.id,
        name: feature.properties.nameTr,
        polygon: feature.geometry.coordinates,
      }));
      yield put(BanGeomapActions.banGeomapSetEditedMap(undefined));
      yield put(BanGeomapActions.banGeomapGetDistrictSuccess(districts));

      open(
        'success',
        translate('common:notif.successTitle'),
        translate('geomap:common.messages.districtsGetSuccess'),
      );
    }

    if (result.message) {
      open('warning', translate('common:notif.warnTitle'), result.message);
    }
  } catch (error) {
    yield put(BanGeomapActions.banGeomapGetDistrictError(error.message));
  }
  yield put(LoaderActions.hideLoader());
}

const geomapGetVendorsSaga = createStandardSaga({
  callApi: getVendorsApi,
  successAction: BanGeomapActions.banGeomapGetVendorsSuccess,
  errorAction: BanGeomapActions.banGeomapGetVendorsError,
  successMessage: 'geomap:common.messages.getVendorsSuccess',
});

export function* rootSaga() {
  yield takeLatest(Types.BAN_GEOMAP_GET_REQUEST, geomapGetSaga);
  yield takeLatest(Types.BAN_GEOMAP_SAVE_REQUEST, geomapSaveSaga);
  yield takeLatest(
    Types.BAN_GEOMAP_GET_BANNED_VENDORS_REQUEST,
    geomapGetBannedVendorsSaga,
  );
  yield takeLatest(
    Types.BAN_GEOMAP_GET_NEIGHBOURHOOD_REQUEST,
    geomapGetNeighbourhoodSaga,
  );
  yield takeLatest(
    Types.BAN_GEOMAP_GET_DISTRICT_REQUEST,
    geomapGetDistrictSaga,
  );
  yield takeLatest(Types.BAN_GEOMAP_GET_VENDORS_REQUEST, geomapGetVendorsSaga);
}

export default rootSaga;
