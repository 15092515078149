import styled from 'styled-components';

export const ModalContent = styled.div``;
export const ModalHeader = styled.div``;
export const BoldText = styled.p`
  font-weight: bold;
`;
export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 10px;
`;

export const FooterInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  svg {
    line-height: 0px;
  }
`;

export const FooterInfoText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  color: #5d3ebc;
`;
