import styled from 'styled-components';

import Table from 'components/v1/Table';

export const CategoryListTable = styled(Table)``;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const InputContainer = styled.div``;
