import { useTranslation } from 'react-i18next';

const useValidations = () => {
  const { t } = useTranslation();

  const validationRules = {
    brandName: {
      required: t('common:requiredFieldText'),
    },
    brandLogoUrl: {
      required: t('common:requiredFieldText'),
    },
    firm: {
      required: t('common:requiredFieldText'),
    },
    commission: {
      required: t('common:requiredFieldText'),
    },
  };

  const defaultValues = {
    brandName: '',
    brandLogoUrl: '',
    firm: '',
    commission: '',
    label: '',
  };

  return {
    defaultValues,
    validationRules,
  };
};

export { useValidations };
