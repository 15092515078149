import { createAction } from 'utils/createAction';

import * as Types from './types';

// get filtered previous orders
export const getFilteredOrdersRequest = createAction(
  Types.GET_FILTERED_ORDERS_REQUEST,
);
export const getFilteredOrdersSuccess = createAction(
  Types.GET_FILTERED_ORDERS_SUCCESS,
);
export const getFilteredOrdersError = createAction(
  Types.GET_FILTERED_ORDERS_ERROR,
);

export const revalidateFilteredOrders = createAction(
  Types.REVALIDATE_PREVIOUS_ORDER,
);
