const requestStatuses = {
  WAITING: 'WAITING',
  APPROVED: 'APPROVED',
  DECLINED: 'DECLINED',
};

const requestStatusesNumber = {
  WAITING: '0',
  APPROVED: '1',
  DECLINED: '2',
};

export { requestStatuses, requestStatusesNumber };
