import * as Types from './types';
import tabTypes from './constants/tabTypes';

const initialState = {
  requests: {
    data: undefined,
    error: undefined,
  },
  approve: {
    data: undefined,
    error: undefined,
  },
  requestType: tabTypes.pendingRequests,
};

function RequestsReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.REQUESTS_LIST_SUCCESS:
      return {
        ...state,
        requests: {
          data: payload,
        },
      };
    case Types.REQUESTS_LIST_ERROR:
      return {
        ...state,
        requests: {
          error: payload,
        },
      };
    case Types.APPROVE_REQUEST_SUCCESS:
      return {
        ...state,
        approve: {
          data: payload,
        },
      };
    case Types.APPROVE_REQUEST_ERROR:
      return {
        ...state,
        approve: {
          error: payload,
        },
      };
    case Types.SET_REQUEST_TYPE:
      return {
        ...state,
        requestType: payload,
      };
    default:
      return state;
  }
}

export default RequestsReducer;
