import { createAction } from 'utils/createAction';

import * as Types from './types';

// send Missing
export const sendMissingProductRequest = createAction(
  Types.MISSINGPRODUCT_SEND_REQUEST,
);
export const sendMissingProductError = createAction(
  Types.MISSINGPRODUCT_SEND_ERROR,
);
export const sendMissingProductSuccess = createAction(
  Types.MISSINGPRODUCT_SEND_SUCCESS,
);

// send Product Information
export const getMissingProductInformation = createAction(
  Types.MISSINGPRODUCT_ORDER_REQUEST,
);
export const getMissingProductError = createAction(
  Types.MISSINGPRODUCT_ORDER_ERROR,
);
export const getMissingProductSuccess = createAction(
  Types.MISSINGPRODUCT_ORDER_SUCCESS,
);
export const getMissingProductReset = createAction(
  Types.MISSINGPRODUCT_ORDER_RESET,
);

export const openMissingProduct = createAction(Types.MISSINGPRODUCT_OPEN);
export const closeMissingProduct = createAction(Types.MISSINGPRODUCT_CLOSE);
