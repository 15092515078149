import { Modal } from 'antd';
import styled from 'styled-components';

const StyledPopupModal = styled(Modal)`
  font-family: 'Open Sans', sans-serif;
  .ant-modal-footer,
  .ant-modal-title {
    font-weight: 500;
    text-align: center;
  }

  .ant-modal-title {
    color: #5d3ebc;
  }

  .ant-modal-header {
    border-bottom: none;
  }

  .ant-modal-body {
    padding: 20px 24px 0 24px;
  }
`;

const StyledPopupButton = styled.div`
  margin: 16px auto;
  background-color: #5d3ebc;
  color: white;
  border-radius: 8px;
  width: 100%;
  padding: 14px 0;
  line-height: 20px;
  font-weight: 700;
  cursor: ${props => (props.isActive ? 'pointer' : 'not-allowed')};
  user-select: none;
  opacity: ${props => (props.isActive ? 1 : 0.5)};
`;

const StyledScoreLayout = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: ${props => (props.isScoreSelected ? '0' : '32px')};
`;

const StyledScoreItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledScoreIconWrapper = styled.div`
  padding: 4px;
  margin-bottom: 8px;
  &:hover {
    cursor: pointer;
  }
`;

const StyledModalCloseIcon = styled.img`
  box-sizing: content-box;
  width: 24px;
  height: 24px;
  padding: 4px;
  border-radius: 8px;
  background-color: #f3f3f4;
`;

const StyledTextarea = styled.textarea`
  border: 2px solid #f3f0fe;
  border-radius: 4px;
  resize: none;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 32px;
  height: 72px;
  padding: 16px;

  &:focus {
    outline: none;
    border: 2px solid #5d3ebc;
  }
`;

const StyledTagListWrapper = styled.div`
  margin: 16px 0 0 0;
`;

const StyledTagList = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > div {
    margin-right: 4px;
  }
`;

const StyledTagItem = styled.div`
  cursor: pointer;
  user-select: none;
  background-color: ${props => (props.isSelected ? '#5D3EBC' : '#F5F5F5')};
  color: ${props => (props.isSelected ? '#FFFFFF' : '#0E0E0E')};
  padding: 8px 16px;
  border-radius: 99px;
  margin-bottom: 8px;
  font-weight: 500;
`;

const StyledExpand = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 8px 0 0 0;
`;

const StyledExpandText = styled.div`
  font-weight: 500;
  line-height: 20px;
  color: #5d3ebc;
`;

const StyledExpandIconWrapper = styled.div`
  margin-left: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export {
  StyledPopupModal,
  StyledPopupButton,
  StyledScoreLayout,
  StyledScoreItem,
  StyledScoreIconWrapper,
  StyledModalCloseIcon,
  StyledTextarea,
  StyledTagListWrapper,
  StyledTagList,
  StyledTagItem,
  StyledExpand,
  StyledExpandText,
  StyledExpandIconWrapper,
};
