import React from 'react';

import { GeoMapStateKey } from '../Common/common';
import GeoMap from '../Common/GeoMap';

import * as UploadGeomapActions from './actions';

const UploadGeoMap = () => (
  <GeoMap
    geomapStateKey={GeoMapStateKey.UPLOAD_GEO_MAP}
    GeomapActions={{
      geomapGetRequest: UploadGeomapActions.uploadGeomapGetRequest,
      geomapGetDistrictRequest:
        UploadGeomapActions.uploadGeomapGetDistrictRequest,
      geomapSaveRequest: UploadGeomapActions.uploadGeomapSaveRequest,
      geomapGetNeighbourhoodRequest:
        UploadGeomapActions.uploadGeomapGetNeighbourhoodRequest,
      setGeomapInterval: UploadGeomapActions.uploadGeomapSetInterval,
      setGeomapBrand: UploadGeomapActions.uploadGeomapSetBrand,
      setGeomapCity: UploadGeomapActions.uploadGeomapSetCity,
      setGeomapCenter: UploadGeomapActions.uploadGeomapSetCenter,
      resetGeomapState: UploadGeomapActions.uploadGeomapResetState,
      setSelectedVendor: UploadGeomapActions.uploadGeomapSetSelectedVendor,
      setNeighbourhoodisVisible: UploadGeomapActions.setNeighbourhoodisVisible,
      setEditedMap: UploadGeomapActions.uploadGeomapSetEditedMap,
      setShowOnlySelectedVendor:
        UploadGeomapActions.uploadGeomapSetShowOnlySelectedVendor,
      setSelectedDistrict: UploadGeomapActions.uploadGeomapSetSelectedDistrict,
      geomapGetVendors: UploadGeomapActions.uploadGeomapGetVendorsRequest,
      geomapGetNeighbourhoodSuccess:
        UploadGeomapActions.uploadGeomapGetNeighbourhoodSuccess,
    }}
  />
);

export default UploadGeoMap;
