import styled from 'styled-components';

const DotSeparatorWrapper = styled.span`
  width: 4px;
  height: 4px;
  background: rgb(93, 62, 188);
  border-radius: 50%;
`;

export { DotSeparatorWrapper };
