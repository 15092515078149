import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button from 'components/v1/Button';
import Layout from 'components/Layout';
import * as CS from 'common/style';
import * as ROUTES from 'constants/routes';

import List from './List';

const Roles = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Layout>
      <CS.PageHeader>
        <CS.PageTitle>{t('authRolesPage:pageTitle')}</CS.PageTitle>
        <Button
          type="primary"
          onClick={() => history.push(ROUTES.ADD_NEW_ROLE)}
        >
          {t('authRolesPage:addNewRole')}
        </Button>
      </CS.PageHeader>
      <CS.PageBody>
        <List />
      </CS.PageBody>
    </Layout>
  );
};

export default Roles;
