import styled from 'styled-components';
import { Table } from 'antd';

export const StyledTable = styled(Table)`
  .ant-table-title {
    display: flex;
    justify-content: flex-end;
    padding: 0 0 24px 0;
  }

  .ant-table-thead {
    tr {
      th {
        background: #eaeaea !important;
      }
    }
  }
`;

export const AlertWrapper = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
