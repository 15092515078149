import { notification } from 'antd';

const open = (
  type = 'open',
  message = 'Title',
  description = 'Description',
  onClick,
  onClose,
) => {
  notification[type]({
    message,
    description,
    onClick,
    onClose,
  });
};

export { open };
