// open & close modal
export const OPEN_MESSAGE_MODAL = 'OPEN_MESSAGE_MODAL';
export const CLOSE_MESSAGE_MODAL = 'CLOSE_MESSAGE_MODAL';

// get Thread list
export const GET_THREAD_LIST_REQUEST = 'GET_THREAD_LIST_REQUEST';
export const GET_THREAD_LIST_SUCCESS = 'GET_THREAD_LIST_SUCCESS';
export const GET_THREAD_LIST_ERROR = 'GET_THREAD_LIST_ERROR';

// get Thread detail
export const GET_THREAD_DETAIL_REQUEST = 'GET_THREAD_DETAIL_REQUEST';
export const GET_THREAD_DETAIL_SUCCESS = 'GET_THREAD_DETAIL_SUCCESS';
export const GET_THREAD_DETAIL_ERROR = 'GET_THREAD_DETAIL_ERROR';

// send message
export const SEND_MESSAGE_REQUEST = 'SEND_MESSAGE_REQUEST';
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_ERROR = 'SEND_MESSAGE_ERROR';

// create new thread
export const CREATE_NEW_THREAD_REQUEST = 'CREATE_NEW_THREAD_REQUEST';
export const CREATE_NEW_THREAD_SUCCESS = 'CREATE_NEW_THREAD_SUCCESS';
export const CREATE_NEW_THREAD_ERROR = 'CREATE_NEW_THREAD_ERROR';

// close thread
export const CLOSE_THREAD_REQUEST = 'CLOSE_THREAD_REQUEST';
export const CLOSE_THREAD_SUCCESS = 'CLOSE_THREAD_SUCCESS';
export const CLOSE_THREAD_ERROR = 'CLOSE_THREAD_ERROR';

// change read status
export const CHANGE_READ_STATUS_REQUEST = 'CHANGE_READ_STATUS_REQUEST';
export const CHANGE_READ_STATUS_SUCCESS = 'CHANGE_READ_STATUS_SUCCESS';
export const CHANGE_READ_STATUS_ERROR = 'CHANGE_READ_STATUS_ERROR';

// reset all states

export const RESET_ALL_STATES = 'RESET_ALL_STATES';
export const CLEAR_THREAD_DETAIL = 'CLEAR_THREAD_DETAIL';

export const SET_THREAD_DETAIL = 'SET_THREAD_DETAIL';
