import _ from 'lodash';

import * as Types from './types';

const initialState = {
  products: {
    data: undefined,
    error: undefined,
  },
  updatePrice: {
    data: undefined,
    error: undefined,
  },
  errorMessage: undefined,
};

function BulkChangeReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.CANNOT_UPDATE_PRICE:
      return {
        ...state,
        errorMessage: payload,
      };
    case Types.CANNOT_UPDATE_PRICE_CLEAR:
      return {
        ...state,
        errorMessage: undefined,
      };
    case Types.GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: {
          data: payload.map(product => ({
            uniqueId: _.uniqueId(),
            ...product,
          })),
        },
      };
    case Types.GET_PRODUCTS_ERROR:
      return {
        ...state,
        products: {
          error: payload,
        },
      };
    case Types.UPDATE_PRICE_SUCCESS:
      return {
        ...state,
        updatePrice: {
          data: payload,
        },
        errorMessage: undefined,
      };
    case Types.UPDATE_PRICE_ERROR:
      return {
        ...state,
        updatePrice: {
          error: payload,
        },
      };
    default:
      return state;
  }
}

export default BulkChangeReducer;
