import { createAction } from 'utils/createAction';

import * as Types from './types';

// get user list
export const getUserListRequest = createAction(Types.GET_USER_LIST_REQUEST);
export const getUserListSuccess = createAction(Types.GET_USER_LIST_SUCCESS);
export const getUserListError = createAction(Types.GET_USER_LIST_ERROR);

// create user
export const createUserRequest = createAction(Types.CREATE_USER_REQUEST);
export const createUserSuccess = createAction(Types.CREATE_USER_SUCCESS);
export const createUserError = createAction(Types.CREATE_USER_ERROR);

// update user
export const updateUserRequest = createAction(Types.UPDATE_USER_REQUEST);
export const updateUserSuccess = createAction(Types.UPDATE_USER_SUCCESS);
export const updateUserError = createAction(Types.UPDATE_USER_ERROR);

// delete user
export const deleteUserRequest = createAction(Types.DELETE_USER_REQUEST);
export const deleteUserSuccess = createAction(Types.DELETE_USER_SUCCESS);
export const deleteUserError = createAction(Types.DELETE_USER_ERROR);

// get roles by role gorup
export const getRolesByRoleGroupRequest = createAction(
  Types.GET_ROLES_BY_ROLE_GROUP_REQUEST,
);
export const getRolesByRoleGroupSuccess = createAction(
  Types.GET_ROLES_BY_ROLE_GROUP_SUCCESS,
);
export const getRolesByRoleGroupError = createAction(
  Types.GET_ROLES_BY_ROLE_GROUP_ERROR,
);

// get roles by user id
export const getRolesByUserIdRequest = createAction(
  Types.GET_ROLES_BY_USER_ID_REQUEST,
);
export const getRolesByUserIdSuccess = createAction(
  Types.GET_ROLES_BY_USER_ID_SUCCESS,
);
export const getRolesByUserIdError = createAction(
  Types.GET_ROLES_BY_USER_ID_ERROR,
);
