import { takeLatest, put, call } from 'redux-saga/effects';

import {
  getUserListApi,
  createUserApi,
  updateUserApi,
  deleteUserApi,
  getRolesByRoleGroupApi,
  getRolesByUserIdApi,
} from 'services/UsersPerms/Users/api';
import { createStandardSaga } from 'utils/createStandardSaga';
import * as LoaderActions from 'components/Loader/actions';
import { open } from 'utils/notification';
import translate from 'utils/translate';

import * as Types from './types';
import * as UsersActions from './actions';

const getUserListSaga = createStandardSaga({
  callApi: getUserListApi,
  successAction: UsersActions.getUserListSuccess,
  errorAction: UsersActions.getUserListError,
});

const createUserSaga = createStandardSaga({
  callApi: createUserApi,
  successAction: UsersActions.createUserSuccess,
  errorAction: UsersActions.createUserError,
  successMessage: 'authUsersPage:addNewUserModal.successRoleMessage',
});

const updateUserSaga = createStandardSaga({
  callApi: updateUserApi,
  successAction: UsersActions.updateUserSuccess,
  errorAction: UsersActions.updateUserError,
  successMessage: 'authUsersPage:editUserModal.successRoleMessage',
});

function* deleteUserSaga({ payload }) {
  yield put(LoaderActions.showLoader());
  try {
    const result = yield call(deleteUserApi, payload);
    if (result.data) {
      yield put(UsersActions.deleteUserSuccess(payload));
      open(
        'success',
        '',
        translate('authUsersPage:deleteUserModal.successRoleMessage'),
      );
    } else {
      open('warning', translate('common:notif.warnTitle'), result.message);
    }
  } catch (error) {
    yield put(UsersActions.deleteUserError(error.message));
  }
  yield put(LoaderActions.hideLoader());
}

const getRolesByRoleGroupSaga = createStandardSaga({
  callApi: getRolesByRoleGroupApi,
  successAction: UsersActions.getRolesByRoleGroupSuccess,
  errorAction: UsersActions.getRolesByRoleGroupError,
});

const getRolesByUserIdSaga = createStandardSaga({
  callApi: getRolesByUserIdApi,
  successAction: UsersActions.getRolesByUserIdSuccess,
  errorAction: UsersActions.getRolesByUserIdError,
});

export function* rootSaga() {
  yield takeLatest(Types.GET_USER_LIST_REQUEST, getUserListSaga);
  yield takeLatest(Types.CREATE_USER_REQUEST, createUserSaga);
  yield takeLatest(Types.UPDATE_USER_REQUEST, updateUserSaga);
  yield takeLatest(Types.DELETE_USER_REQUEST, deleteUserSaga);
  yield takeLatest(
    Types.GET_ROLES_BY_ROLE_GROUP_REQUEST,
    getRolesByRoleGroupSaga,
  );
  yield takeLatest(Types.GET_ROLES_BY_USER_ID_REQUEST, getRolesByUserIdSaga);
}

export default rootSaga;
