import * as Types from './types';

// list firms

export function firmListRequest() {
  return {
    type: Types.FIRM_LIST_REQUEST,
  };
}

export function firmListSuccess(data) {
  return {
    type: Types.FIRM_LIST_SUCCESS,
    payload: data,
  };
}

export function firmListError(err) {
  return {
    type: Types.FIRM_LIST_ERROR,
    payload: err,
  };
}

// add new firm

export function addFirmRequest(data) {
  return {
    type: Types.ADD_FIRM_REQUEST,
    payload: data,
  };
}

export function addFirmSuccess(data) {
  return {
    type: Types.ADD_FIRM_SUCCESS,
    payload: data,
  };
}

export function addFirmError(err) {
  return {
    type: Types.ADD_FIRM_ERROR,
    payload: err,
  };
}

// update firm

export function updateFirmRequest(data) {
  return {
    type: Types.EDIT_FIRM_REQUEST,
    payload: data,
  };
}

export function updateFirmSuccess(data) {
  return {
    type: Types.EDIT_FIRM_SUCCESS,
    payload: data,
  };
}

export function updateFirmError(err) {
  return {
    type: Types.EDIT_FIRM_ERROR,
    payload: err,
  };
}

// delete firm

export function deleteFirmRequest(data) {
  return {
    type: Types.DELETE_FIRM_REQUEST,
    payload: data,
  };
}

export function deleteFirmSuccess(data) {
  return {
    type: Types.DELETE_FIRM_SUCCESS,
    payload: data,
  };
}

export function deleteFirmError(err) {
  return {
    type: Types.DELETE_FIRM_ERROR,
    payload: err,
  };
}

// set firm id

export function setFirmId(data) {
  return {
    type: Types.SET_FIRM_ID,
    payload: data,
  };
}

// get firm info

export function getFirmInfoRequest(data) {
  return {
    type: Types.GET_FIRM_INFO_REQUEST,
    payload: data,
  };
}

export function getFirmInfoSuccess(data) {
  return {
    type: Types.GET_FIRM_INFO_SUCCESS,
    payload: data,
  };
}

export function getFirmInfoError(err) {
  return {
    type: Types.GET_FIRM_INFO_ERROR,
    payload: err,
  };
}
