import { takeLatest } from 'redux-saga/effects';

import { getOrderApi } from 'services/OrderDetail/api';
import { getVendorInfoApi } from 'services/MainHeader/api';
import { createStandardSaga } from 'utils/createStandardSaga';

import * as MapModalActions from './actions';
import * as Types from './types';

const getOrderDetailSaga = createStandardSaga({
  callApi: getOrderApi,
  successAction: MapModalActions.getMapModalSuccess,
  errorAction: MapModalActions.getMapModalError,
});

const getMapModalVendorSaga = createStandardSaga({
  callApi: getVendorInfoApi,
  successAction: MapModalActions.getMapModalVendorSuccess,
  errorAction: MapModalActions.getMapModalVendorError,
});

export function* rootSaga() {
  yield takeLatest(Types.MAPMODAL_REQUEST, getOrderDetailSaga);
  yield takeLatest(Types.MAPMODAL_VENDOR_REQUEST, getMapModalVendorSaga);
}

export default rootSaga;
