import React, { useCallback, useEffect, useState } from 'react';
import { get, isNull } from 'lodash';

import ScorePopupCloseIcon from 'common/svg/scorePopupCloseIcon.svg';
import useStateWithProp from 'hooks/useStateWithProp';
import { open } from 'utils/notification';
import { ReactComponent as PopupModalArrowDown } from 'common/svg/popupModalArrowDown.svg';
import { getModelInjector } from 'utils/getTypeInjector';
import UserPopupResponse from 'models/UserPopupResponse';
import useSet from 'hooks/useSet';
import { ReactComponent as PopupModalThumbsDownIconInactive } from 'common/svg/popupModalThumbsDownInactive.svg';
import { ReactComponent as PopupModalThumbsUpIconInactive } from 'common/svg/popupModalThumbsUpInactive.svg';
import { ReactComponent as PopupModalThumbsDownIconActive } from 'common/svg/popupModalThumbsDownActive.svg';
import { ReactComponent as PopupModalThumbsUpIconActive } from 'common/svg/popupModalThumbsUpActive.svg';

import { getUserPopupRequest, sendPopupFeedbackRequest } from '../actions';

import * as S from './style';
import { DevelopmentPhase, PopupSubmitStatus } from './constants';

const { useTranslation } = require('react-i18next');
const { useDispatch, useSelector } = require('react-redux');

const currentDevelopmentPhase = DevelopmentPhase.PHASE_1;

const SatisfactionPopup = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserPopupRequest());
  }, [dispatch]);

  const userPopupState = useSelector(state => state.activeOrders.userPopup);
  const userPopup = getModelInjector(UserPopupResponse)(
    get(userPopupState, ['popups', '0'], null),
  );

  const [isUserPopupVisible, setUserPopupVisible] = useStateWithProp(
    userPopup?.type,
  );

  const [score, setScore] = useState(null);
  const [comment, setComment] = useState();
  const tags = userPopup?.tags;
  const {
    add: addSelectedTag,
    remove: removeSelectedTag,
    has: hasSelectedTag,
    list: getSelectedTags,
  } = useSet();

  const findIsScoreSelected = useCallback(() => !isNull(score), [score]);

  const handleScoreItemClick = scoreType => {
    setScore(scoreType.value);
  };

  const handlePopupSubmit = () => {
    if (!findIsScoreSelected()) {
      return;
    }
    const requestBody = {
      type: userPopup.type,
      content: comment,
      tags: getSelectedTags(),
      score,
      status: PopupSubmitStatus.COMPLETE,
    };
    dispatch(sendPopupFeedbackRequest(requestBody));
    open('success', t('activeOrders:scoreModal.successMessage'), null);
    setUserPopupVisible(false);
  };

  const handlePopupCancel = () => {
    const requestBody = {
      type: userPopup.type,
      status: PopupSubmitStatus.IGNORED,
    };
    dispatch(sendPopupFeedbackRequest(requestBody));
    setUserPopupVisible(false);
  };

  const scoreTypes = [
    {
      label: t('activeOrders:scoreType.notSatisfied'),
      value: 0,
      activeComponent: PopupModalThumbsDownIconActive,
      inactiveComponent: PopupModalThumbsDownIconInactive,
    },
    {
      label: t('activeOrders:scoreType.satisfied'),
      value: 1,
      activeComponent: PopupModalThumbsUpIconActive,
      inactiveComponent: PopupModalThumbsUpIconInactive,
    },
  ];

  return (
    <S.StyledPopupModal
      title={userPopup?.title}
      open={isUserPopupVisible}
      closeIcon={
        <S.StyledModalCloseIcon src={ScorePopupCloseIcon} alt="close" />
      }
      onCancel={handlePopupCancel}
      footer={
        <S.StyledPopupButton
          onClick={handlePopupSubmit}
          isActive={findIsScoreSelected()}
        >
          {t('send')}
        </S.StyledPopupButton>
      }
    >
      <S.StyledScoreLayout isScoreSelected={findIsScoreSelected()}>
        {scoreTypes.map(scoreType => {
          const InactiveIcon = scoreType.inactiveComponent;
          const ActiveIcon = scoreType.activeComponent;

          return (
            <S.StyledScoreItem key={scoreType.value}>
              <S.StyledScoreIconWrapper
                onClick={() => handleScoreItemClick(scoreType)}
              >
                {score === scoreType.value ? <ActiveIcon /> : <InactiveIcon />}
              </S.StyledScoreIconWrapper>
              <span>{scoreType.label}</span>
            </S.StyledScoreItem>
          );
        })}
      </S.StyledScoreLayout>
      {score === scoreTypes[0].value && (
        <S.StyledTagListWrapper>
          <S.StyledTagList>
            {tags.map(tag => (
              <S.StyledTagItem
                key={tag}
                isSelected={hasSelectedTag(tag)}
                onClick={() =>
                  hasSelectedTag(tag)
                    ? removeSelectedTag(tag)
                    : addSelectedTag(tag)
                }
              >
                {tag}
              </S.StyledTagItem>
            ))}
          </S.StyledTagList>
          {currentDevelopmentPhase === DevelopmentPhase.PHASE_2 && (
            <S.StyledExpand>
              <S.StyledExpandText>
                {t('activeOrders:scoreModal.showAll')}
              </S.StyledExpandText>
              <S.StyledExpandIconWrapper>
                <PopupModalArrowDown />
              </S.StyledExpandIconWrapper>
            </S.StyledExpand>
          )}
        </S.StyledTagListWrapper>
      )}
      {findIsScoreSelected() && (
        <S.StyledTextarea
          onChange={e => setComment(e.target.value)}
          value={comment}
          placeholder={t('activeOrders:scoreModal.textareaPlaceholder')}
        />
      )}
    </S.StyledPopupModal>
  );
};

export default SatisfactionPopup;
