import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  min-height: 100vh;
  padding-top: 14px;
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  height: 100%;
  p {
    margin: 0;
  }
`;
