import * as Types from './types';

const initialState = {
  userList: {
    data: undefined,
    error: undefined,
  },
  createUser: {
    data: undefined,
    error: undefined,
  },
  updateUser: {
    data: undefined,
    error: undefined,
  },
  deleteUser: {
    data: undefined,
    error: undefined,
  },
  roleList: {
    data: undefined,
    error: undefined,
  },
  userRoleList: {
    data: undefined,
    error: undefined,
  },
};

function UserReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_USER_LIST_SUCCESS:
      return {
        ...state,
        userList: {
          data: payload,
        },
      };
    case Types.GET_USER_LIST_ERROR:
      return {
        ...state,
        userList: {
          error: payload,
        },
      };
    case Types.CREATE_USER_SUCCESS:
      return {
        ...state,
        createUser: {
          data: payload,
        },
      };
    case Types.CREATE_USER_ERROR:
      return {
        ...state,
        createUser: {
          error: payload,
        },
      };
    case Types.UPDATE_USER_SUCCESS:
      return {
        ...state,
        updateUser: {
          data: payload,
        },
      };
    case Types.UPDATE_USER_ERROR:
      return {
        ...state,
        updateUser: {
          error: payload,
        },
      };
    case Types.DELETE_USER_SUCCESS:
      return {
        ...state,
        deleteUser: {
          data: payload,
        },
      };
    case Types.DELETE_USER_ERROR:
      return {
        ...state,
        deleteUser: {
          error: payload,
        },
      };
    case Types.GET_ROLES_BY_ROLE_GROUP_SUCCESS:
      return {
        ...state,
        roleList: {
          data: payload,
        },
      };
    case Types.GET_ROLES_BY_ROLE_GROUP_ERROR:
      return {
        ...state,
        roleList: {
          error: payload,
        },
      };
    case Types.GET_ROLES_BY_USER_ID_SUCCESS:
      return {
        ...state,
        userRoleList: {
          data: payload,
        },
      };
    case Types.GET_ROLES_BY_USER_ID_ERROR:
      return {
        ...state,
        userRoleList: {
          error: payload,
        },
      };
    default:
      return state;
  }
}

export default UserReducer;
