const colors = {
  mainGetirColor: '#5D3EBC',
  mainGetirYellowColor: '#ffd10d',
  menuHoverColor: '#7849F7',
  contentBgColor: '#F8F8F8',
  toggleSuccessColor: '#188977',
  toggleDangerColor: '#DB471E',
};

export default colors;
