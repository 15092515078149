import React, { useState, useEffect } from 'react';
import { Space } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal, Button, utilities, Input } from '@getir/web-components';
import { useDispatch } from 'react-redux';

import requestActionsData from '../../constants/requestActions';
import { requestStatuses } from '../../constants/requestStatuses';
import * as RequestActions from '../../actions';

import * as S from './style';

const { noop } = utilities;

const StrikedPriceUpdateModal = ({
  getVisible,
  data,
  requestId,
  requestStatus,
  ...props
}) => {
  const dispatch = useDispatch();
  const [newPrice, setNewPrice] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    if (data) {
      setNewPrice(data?.newPrice);
    }
  }, [data]);

  const handleChangeNewPrice = e => {
    setNewPrice(e.target.value);
  };

  const handleCancelButtonClick = () => {
    const requestData = {
      requestId,
      status: requestActionsData.DECLINE,
      newPrice: Number(newPrice),
    };
    dispatch(RequestActions.approveRequestRequest(requestData));
    getVisible(false);
  };

  const handleSaveButtonClick = () => {
    const requestData = {
      requestId,
      status: requestActionsData.APPROVE,
      newPrice: Number(newPrice),
    };
    dispatch(RequestActions.approveRequestRequest(requestData));
    getVisible(false);
  };

  return (
    <S.StyledModal onCancel={() => getVisible(false)} {...props}>
      <Modal.Header>{t('requests:strikedPriceUpdate.title')}</Modal.Header>
      <Modal.Body>
        <S.ContentRow>
          <S.ContentRowName>{t('requests:vendorName')}:</S.ContentRowName>
          <S.ContentRowValue>{data?.vendorName}</S.ContentRowValue>
        </S.ContentRow>
        <S.ContentRow>
          <S.ContentRowName>{t('requests:categoryName')}:</S.ContentRowName>
          <S.ContentRowValue>{data?.categoryName}</S.ContentRowValue>
        </S.ContentRow>
        <S.ContentRow>
          <S.ContentRowName>{t('requests:productName')}:</S.ContentRowName>
          <S.ContentRowValue>{data?.productName}</S.ContentRowValue>
        </S.ContentRow>
        <S.ContentRowPrice>
          <S.ContentRowName>{t('requests:price')}:</S.ContentRowName>
          <S.ContentRowValuePrice>
            <Input
              addonBefore="₺"
              header={t('requests:oldPrice')}
              type="number"
              value={data?.oldPrice}
              disabled
            />
            <Input
              addonBefore="₺"
              header={t('requests:newPrice')}
              type="number"
              value={newPrice}
              onChange={handleChangeNewPrice}
              disabled={
                requestStatus === requestStatuses.APPROVED ||
                requestStatus === requestStatuses.DECLINED
              }
            />
          </S.ContentRowValuePrice>
        </S.ContentRowPrice>
      </Modal.Body>
      {requestStatus === requestStatuses.WAITING && (
        <Modal.Footer>
          <Space size="small">
            <Button kind="secondary" onClick={handleCancelButtonClick}>
              {t('requests:priceUpdate.cancelButton')}
            </Button>
            <Button onClick={handleSaveButtonClick} disabled={!newPrice}>
              {t('requests:priceUpdate.successButton')}
            </Button>
          </Space>
        </Modal.Footer>
      )}
    </S.StyledModal>
  );
};

StrikedPriceUpdateModal.propTypes = {
  getVisible: PropTypes.func,
  data: PropTypes.object,
  requestId: PropTypes.string,
  requestStatus: PropTypes.string,
};

StrikedPriceUpdateModal.defaultProps = {
  getVisible: noop,
  data: undefined,
  requestId: undefined,
  requestStatus: undefined,
};

export default StrikedPriceUpdateModal;
