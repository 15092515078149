const programTypes = {
  VENDOR: 'VENDOR',
  FIRM: 'FIRM',
};

export const createProgramData = vendor => ({
  programType:
    vendor && vendor.isFirm ? programTypes.FIRM : programTypes.VENDOR,
  programKey: vendor.value,
});
