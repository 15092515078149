import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Empty, Space } from 'antd';
import _ from 'lodash';

import Layout from 'components/Layout';
import * as CS from 'common/style';
import * as BrandActions from 'containers/Stores/Brands/actions';
import SelectBoxAntd from 'components/Antd/FormElements/SelectBox';

import { getSelectList } from '../common';

import * as AlternativeHoursActions from './actions';
import * as S from './style';
import { validationRules } from './validationRules';
import getColumns from './getColumns';
import { defaultNumberOfIntervals, TableMode } from './constants';
import getAlternativeMapIntervalsRequestBody from './utils/getAlternativeMapIntervalsRequestBody';
import { findIfThereIsAnyMixedTimeError } from './utils/errorHandler';
import getInitialAlternativeHours from './utils/getInitialAlternativeMapIntervals';
import mergeAlternativeMapIntervalsWithInitialValue from './utils/mergeAlternativeHoursObjects';
import convertAlternativeMapIntervalsToDataSource from './utils/convertAlternativeMapIntervalsToDataSource';

const AlternativeMapHours = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    formState: { errors },
    control,
  } = useForm({
    mode: 'all',
  });
  const [tableMode, setTableMode] = useState(TableMode.VIEW);
  const brandId = useRef(null);
  const [apiSendToken, setApiSendToken] = useState(null);

  const alternativeHoursData = useSelector(
    brandId.current ? state => state?.alternativeHours : _.noop,
  );
  const [shouldShowTimePickerErrors, setShouldShowTimePickerErrors] = useState(
    false,
  );
  const [numberOfIntervals] = useState(defaultNumberOfIntervals);

  const [alternativeMapIntervals, setAlternativeMapIntervals] = useState([]);
  useEffect(() => {
    if (apiSendToken) {
      dispatch(
        AlternativeHoursActions.updateAlternativeHoursRequest({
          id: brandId.current,
          payload: getAlternativeMapIntervalsRequestBody(
            alternativeMapIntervals,
          ),
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiSendToken]);
  useEffect(() => {
    if (alternativeHoursData?.alternativeMapIntervals) {
      const initialAlternativeHours = getInitialAlternativeHours(
        numberOfIntervals,
      );
      const alternativeMapIntervalsInitial = mergeAlternativeMapIntervalsWithInitialValue(
        initialAlternativeHours,
        alternativeHoursData.alternativeMapIntervals,
      );

      setAlternativeMapIntervals(alternativeMapIntervalsInitial);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alternativeHoursData]);
  const brands = useSelector(state => state.brand.brands);
  const brandList = getSelectList(brands, 'brandName', 'id');

  useEffect(() => {
    if (!brands) dispatch(BrandActions.brandListRequest());
  }, [dispatch, brands]);

  const onChange = value => {
    brandId.current = value;
    setTableMode(TableMode.VIEW);
    dispatch(AlternativeHoursActions.getAlternativeHoursRequest(value));
  };

  const handleCancelOnEditMode = () => {
    setTableMode(TableMode.VIEW);
  };

  const handleSuccessOnEditMode = () => {
    // TODO Add comparator
    const isAlternativeMapIntervalsEqualToFetchedData = false;
    const isThereAnyMixedTimeError = findIfThereIsAnyMixedTimeError(
      alternativeMapIntervals,
    );
    setShouldShowTimePickerErrors(isThereAnyMixedTimeError);
    setApiSendToken(
      isThereAnyMixedTimeError || isAlternativeMapIntervalsEqualToFetchedData
        ? null
        : Symbol('updateAlternativeHoursRequest'),
    );
    setTableMode(isThereAnyMixedTimeError ? TableMode.EDIT : TableMode.VIEW);
  };

  return (
    <Layout>
      <CS.PageTitle>{t('geomap:alternativeMapHour.title')}</CS.PageTitle>
      <CS.PageBody style={{ height: '100%' }}>
        <form>
          <S.InputRow>
            <SelectBoxAntd
              fieldName="brandId"
              errors={errors}
              onChange={onChange}
              rules={validationRules.brandId}
              placeholder={t('geomap:alternativeMapHour.chooseBrand')}
              options={brandList}
              control={control}
              showSearch
            />
          </S.InputRow>
        </form>
        <Space size="middle" direction="vertical">
          <S.AlternativeMapHoursTable
            dataSource={
              alternativeMapIntervals &&
              convertAlternativeMapIntervalsToDataSource(
                alternativeMapIntervals,
              )
            }
            columns={getColumns({
              t,
              numberOfIntervals,
              tableMode,
              setTableMode,
              shouldShowTimePickerErrors,
              alternativeMapIntervals,
              setAlternativeMapIntervals,
            })}
            pagination={false}
            rowKey="dayOfWeek"
            locale={{ emptyText: <Empty description={t('table.noData')} /> }}
          />
          {tableMode === TableMode.EDIT && (
            <Space size="small">
              <CS.FullWidthButton
                kind="secondary"
                onClick={handleCancelOnEditMode}
              >
                {t('geomap:alternativeMapHour.cancelButton')}
              </CS.FullWidthButton>
              <CS.FullWidthButton onClick={handleSuccessOnEditMode}>
                {t('geomap:alternativeMapHour.successButton')}
              </CS.FullWidthButton>
            </Space>
          )}
        </Space>
      </CS.PageBody>
    </Layout>
  );
};

export default AlternativeMapHours;
