import { postRequest, putRequest, getRequest } from 'lib/axios';

import api from './apiRoutes';

export const getOrdersApi = args => postRequest(api.getOrders, args);

export const getCompletedOrdersApi = args =>
  postRequest(api.getComletedOrders, args);

export const changeOrderStatusApi = args =>
  putRequest(api.changeOrderStatus, args);

export const sendSMSCourier = args => postRequest(api.sendSMSCourier, args);

export const getUserPopupApi = args => getRequest(api.getUserPopup, args);

export const sendPopupFeedbackApi = args =>
  postRequest(api.sendPopupFeedback, args);

export const getCourierListApi = args => getRequest(api.getCourierList, args);
