import { deleteRequest, getRequest, postRequest, putRequest } from 'lib/axios';

import api from './apiRoutes';

export const listProductsApi = args => getRequest(api.listProducts, args);
export const addProductApi = args => postRequest(api.addProduct, args);
export const updateProductApi = ({ id, ...args }) =>
  putRequest(api.updateProduct(id), args);
export const getProductApi = ({ id, ...args }) =>
  getRequest(api.getProduct(id), args);
export const deleteProductApi = ({ id, ...args }) =>
  deleteRequest(api.deleteProduct(id), args);
export const getCatalogBrandByFirmId = ({ firmId, ...args }) =>
  getRequest(api.getCatalogBrandByFirmId(firmId), args);
