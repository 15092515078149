export const MAP_COLORS = Object.freeze({
  neigborhood: '#171A21',
  selectedNewNeigborhood: '#279AF1',
  selectedVendor: '#C06E52',
  firmVendors: '#FCBF49',
});

export const POLYGON_ERROR_CODES = Object.freeze({
  10001: {
    code: 10001,
    message: 'Ring polygon dedected',
  },
});

export const MAX_AREA_SIZE_M2 = 1300;
