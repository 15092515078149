import { useState, useCallback, useEffect } from 'react';

import { loadScript } from 'utils/loadScript';
import { getLangKey } from 'utils/getLanguage';

import Config from '../config/env';

/*
 * this can't be stored in the component state as it will be cleared when the component is unmounted
 * whereas the script remains loaded
 */
let googleLibaryLoaded = false;

const useGoogleAuth = ({
  authRequest,
  onError = () => {},
  onReady = () => {},
  Creators = null,
  dispatch = null,
  token = undefined,
}) => {
  const [cToken, setCToken] = useState(token);

  const onSignInCallback = useCallback(
    data => {
      const { credential: clientToken } = data;
      dispatch(Creators.googleLoginRequest({ googleIdToken: clientToken }));
      setCToken(clientToken);
    },
    [Creators, dispatch],
  );

  const signOut = useCallback(
    () =>
      new Promise((resolve, reject) => {
        try {
          window.google.accounts.id.disableAutoSelect();
          resolve(true);
        } catch (error) {
          reject(error);
        }
      }),
    [],
  );

  const initGsiLogin = useCallback(() => {
    try {
      const locale = getLangKey();
      window.google.accounts.id.initialize({
        client_id: Config.GOOGLE_AUTH.CLIENT_ID,
        callback: onSignInCallback,
      });
      if (!cToken) {
        const gsiLogin = document.getElementById('gsiLogin');
        window.google.accounts.id.renderButton(
          gsiLogin,
          {
            theme: 'outline',
            size: 'large',
            locale,
            width: gsiLogin.offsetWidth,
          }, // customization attributes
        );
      }
    } catch (error) {
      onError({ error, authRequest });
    }
  }, [authRequest, onError, onSignInCallback, cToken]);

  useEffect(() => {
    if (googleLibaryLoaded) initGsiLogin();
  }, [initGsiLogin]);

  useEffect(() => {
    if (!googleLibaryLoaded) {
      loadScript(
        'body',
        Config.GOOGLE_AUTH.JS_URI,
        Config.GOOGLE_AUTH.DOM_ID,
        () => {
          window.onGoogleLibraryLoad = () => {
            googleLibaryLoaded = true;
            initGsiLogin();
          };
        },
        error => {
          onError({ error, authRequest });
        },
      );
    }
  }, [authRequest, onError, onReady, onSignInCallback, cToken, initGsiLogin]);

  return { signOut };
};

export default useGoogleAuth;
