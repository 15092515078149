import styled from 'styled-components';

const buttonTypes = {
  primary: {
    background: '#5D3EBC',
    text: '#FFFFFF',
    fill: '#FFFFFF',
  },
  secondary: {
    background: '#F3F0FE',
    text: '#5D3EBC',
    fill: '#5D3EBC',
  },
  small: {
    padding: '8px 16px',
  },
  medium: {
    padding: '14px 18px',
  },
  large: {
    padding: '18px 24px',
  },
};

export const ButtonContainer = styled.button`
  //reset button css styles
  background: none;
  border: none;
  padding: 8px;

  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;

  min-height: 36px;
  min-width: 36px;
  transition: all 200ms ease-in-out;
  &:hover {
    opacity: 0.8;
  }
  ${({ $kind }) => {
    const { background, text, fill } = buttonTypes[$kind];

    return `
      background-color: ${background};
      color: ${text};
      fill: ${fill};
    `;
  }};

  ${({ $size }) => {
    const { padding } = buttonTypes[$size];

    return `
      padding: ${padding};
    `;
  }};

  &:disabled {
    cursor: not-allowed;
    fill: #a1a1a1;
    background-color: #f6f6f6;
    color: #a1a1a1;
  }
`;
