import { getRequest, postRequest, putRequest, deleteRequest } from 'lib/axios';

import api from './apiRoutes';

export const getUserListApi = args => getRequest(api.getUserList(), args);
export const createUserApi = data => postRequest(api.createUser, data);
export const deleteUserApi = args => deleteRequest(api.deleteUser(args));
export const updateUserApi = ({ id, ...args }) =>
  putRequest(api.updateUser(id), args);
export const getRolesByRoleGroupApi = args =>
  getRequest(api.getRolesByRoleGroup(args));
export const getRolesByUserIdApi = args =>
  getRequest(api.getRolesByUserId(args));
