import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Empty, Space, Alert } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'components/v1/Button';
import Layout from 'components/Layout';
import * as CS from 'common/style';
import ConfirmModal from 'components/ConfirmModal';
import * as CategoryActions from 'containers/CategoryManagement/Category/actions';
import * as BrandActions from 'containers/Stores/Brands/actions';

import * as S from './style';
import { useColumns } from './useColumns';
import * as BulkChangeActions from './actions';

const BulkChange = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isEditActive, setIsEditActive] = useState(false);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [isPriceChanged, setIsPriceChanged] = useState(true);

  const categories = useSelector(state => state.category.categorys);
  const brands = useSelector(state => state.brand.brands);

  const { vendor } = useSelector(state => state.mainHeader);
  const productList = useSelector(state => state.bulkChange.products.data);
  const productListError = useSelector(state => state.bulkChange.errorMessage);

  const updateModalRef = useRef();

  useEffect(() => {
    if (vendor) {
      const { value } = vendor;
      dispatch(BulkChangeActions.getProductsRequest(value));
      setIsEditActive(false);
    }

    return () => {
      dispatch(BulkChangeActions.cannotUpdatePriceClear());
    };
  }, [vendor, dispatch]);

  useEffect(() => {
    if (categories === undefined)
      dispatch(CategoryActions.categoryListRequest());
  }, [dispatch, categories]);

  useEffect(() => {
    if (brands === undefined) dispatch(BrandActions.brandListRequest());
  }, [dispatch, brands]);

  const setPriceData = (index, key, val) => {
    const updatedProducts = [...productList];
    updatedProducts[index][key] = val;

    const filteredProducts = updatedProducts.filter(
      product => product.newPrice && product.newPrice !== 0,
    );

    dispatch(BulkChangeActions.getProductsSuccess(updatedProducts));

    if (filteredProducts.length) {
      setIsPriceChanged(false);

      return;
    }
    setIsPriceChanged(true);
  };

  const columns = useColumns(isEditActive, setPriceData, {
    categories,
    brands,
    productList,
  });

  const handleSave = () => {
    setIsConfirmModalVisible(true);
  };

  const handleUpdatePriceModalSave = () => {
    const filteredProducts = productList
      .filter(product => product.newPrice && product.newPrice !== 0)
      .map(item => ({
        productId: item.id,
        newPrice: Number(item.newPrice),
      }));

    const resultData = {
      bulkChangeProductList: filteredProducts,
      firmId: vendor.value,
    };

    dispatch(BulkChangeActions.updateProductRequest(resultData));
    setIsConfirmModalVisible(false);
  };

  const handleUpdatePriceModalClose = () => {
    setIsConfirmModalVisible(false);
  };

  /* eslint-disable */
  const errorMessage =
    productListError && !productListError.success
      ?   <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <p>{productListError.message}</p>
      <ul>
        {productListError.depositPriceInvalidProducts.map(item => (
          <li>{item}</li>
        ))}
      </ul>
    </div>
      : undefined;
  /* eslint-enable */
  const productListTitle = () => {
    if (vendor && vendor.isFirm) {
      if (!isEditActive) {
        return (
          <Button onClick={() => setIsEditActive(true)}>
            {t('bulkChange:btnChangeBulkChange')}
          </Button>
        );
      }

      return (
        <Space size="small">
          <Button kind="secondary" onClick={() => setIsEditActive(false)}>
            {t('giveUp')}
          </Button>
          <Button onClick={handleSave} disabled={isPriceChanged}>
            {t('save')}
          </Button>
        </Space>
      );
    }

    return '';
  };

  return (
    <>
      <Layout>
        <CS.PageHeader>
          <CS.PageTitle>{t('bulkChange:pageTitle')}</CS.PageTitle>
        </CS.PageHeader>
        <CS.PageBody>
          <S.AlertWrapper>
            <Alert
              message={t('common:priceChangeAlertText')}
              type="warning"
              showIcon
            />
            {errorMessage && (
              <Alert message={errorMessage} type="error" showIcon />
            )}
          </S.AlertWrapper>
          <S.StyledTable
            columns={columns}
            dataSource={productList}
            pagination={{ size: 'small', position: ['bottomCenter'] }}
            locale={{ emptyText: <Empty description={t('table.noData')} /> }}
            title={() => productListTitle()}
            rowKey="uniqueId"
          />
        </CS.PageBody>
      </Layout>
      <ConfirmModal
        size="400px"
        modalRef={updateModalRef}
        visible={isConfirmModalVisible}
        onSave={handleUpdatePriceModalSave}
        onClose={handleUpdatePriceModalClose}
        title={t('bulkChange:updateModalTitle')}
        content={t('bulkChange:updateModalContent')}
        cancelText={t('cancel')}
      />
    </>
  );
};

export default BulkChange;
