import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import { Button } from '@getir/web-components';

import * as S from 'common/style';
import { ORDER_STATUS } from 'constants/orderStatus';
import { ORDERCANCEL_REASON } from 'constants/orderCancelReasons';
import * as ActiveOrderActions from 'containers/ActiveOrders/actions';

import * as MissingProductActions from './actions';

const MissingProductModal = ({ vendor }) => {
  const [options, setOptions] = useState([]);
  const [checkedProducts, setCheckedProducts] = useState([]);
  const dispatch = useDispatch();
  const { t } = useTranslation(['ordercancelmodal', 'missingproductmodal']);

  // selectors
  const { isModalOpen, orderId, orderDetail, cancelNote } = useSelector(
    state => state.missingProduct,
  );

  useEffect(() => {
    if (orderId)
      dispatch(MissingProductActions.getMissingProductInformation(orderId));
  }, [orderId, dispatch]);

  useEffect(() => {
    if (orderDetail && orderDetail.products) {
      setOptions(
        orderDetail.products.map(item => ({
          label: item.name,
          value: item.id,
        })),
      );

      return;
    }
    setOptions([]);
  }, [orderDetail]);

  const onChange = checkedValues => {
    setCheckedProducts(checkedValues);
  };

  const closeModal = () => {
    dispatch(MissingProductActions.closeMissingProduct());
  };

  const sendOrderCancelRequest = () => {
    dispatch(
      ActiveOrderActions.changeOrderStatusRequest({
        cancelReason: ORDERCANCEL_REASON.MISSING_PRODUCT,
        id: orderId,
        status: ORDER_STATUS.CANCELED_VENDOR,
        cancelNote,
      }),
    );
    dispatch(
      MissingProductActions.sendMissingProductRequest({
        products: checkedProducts,
        vendorId: vendor && vendor.id,
      }),
    );
  };

  return (
    <S.StyledModal
      isOpen={isModalOpen}
      width={400}
      footer={[
        <Button kind="secondary" key="closeModal" onClick={closeModal}>
          {t('common:giveUp')}
        </Button>,
        <Button
          kind="primary"
          key="CancelOrder"
          disabled={checkedProducts.length === 0}
          onClick={sendOrderCancelRequest}
        >
          {t('ordercancelmodal:cancelOrder')}
        </Button>,
      ]}
      footerOptions={{
        customFooter: (
          <S.FooterContainer>
            <Button kind="secondary" key="closeModal" onClick={closeModal}>
              {t('common:giveUp')}
            </Button>
            <Button
              kind="primary"
              key="CancelOrder"
              disabled={checkedProducts.length === 0}
              onClick={sendOrderCancelRequest}
            >
              {t('ordercancelmodal:cancelOrder')}
            </Button>
          </S.FooterContainer>
        ),
      }}
      headerOptions={{
        title: t('missingproductmodal:modalTitle'),
      }}
      onClose={closeModal}
    >
      <h4 className="light">{t('missingproductmodal:modalDescription')}</h4>
      <h4 className="light">{t('missingproductmodal:modalAttention')}</h4>
      <h4>{t('missingproductmodal:missingProductInOrder')}</h4>
      <Checkbox.Group options={options} onChange={onChange} />
    </S.StyledModal>
  );
};

MissingProductModal.propTypes = {
  vendor: PropTypes.object,
};

MissingProductModal.defaultProps = {
  vendor: undefined,
};

export { MissingProductModal };
