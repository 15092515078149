const LOGIN_REQUEST = 'LOGIN_REQUEST';
const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGIN_ERROR = 'LOGIN_ERROR';

const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';

const NEW_PASSWORD_REQUEST = 'NEW_PASSWORD_REQUEST';
const NEW_PASSWORD_SUCCESS = 'NEW_PASSWORD_SUCCESS';
const NEW_PASSWORD_ERROR = 'NEW_PASSWORD_ERROR';

const GOOGLE_LOGIN_REQUEST = 'GOOGLE_LOGIN_REQUEST';
const GOOGLE_LOGIN_SUCCESS = 'GOOGLE_LOGIN_SUCCESS';
const GOOGLE_LOGIN_ERROR = 'GOOGLE_LOGIN_ERROR';

const UPDATE_STEP = 'UPDATE_STEP';

export const GET_USER_PERMS_REQUEST = 'GET_USER_PERMS_REQUEST';
export const GET_USER_PERMS_SUCCESS = 'GET_USER_PERMS_SUCCESS';
export const GET_USER_PERMS_ERROR = 'GET_USER_PERMS_ERROR';

export const GET_PERM_LIST_REQUEST = 'GET_ALL_PERM_LIST_REQUEST';
export const GET_PERM_LIST_SUCCESS = 'GET_ALL_PERM_LIST_SUCCESS';
export const GET_PERM_LIST_ERROR = 'GET_ALL_PERM_LIST_ERROR';

export {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  NEW_PASSWORD_REQUEST,
  NEW_PASSWORD_SUCCESS,
  NEW_PASSWORD_ERROR,
  UPDATE_STEP,
  GOOGLE_LOGIN_REQUEST,
  GOOGLE_LOGIN_SUCCESS,
  GOOGLE_LOGIN_ERROR,
};
