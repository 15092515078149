import React from 'react';
import PropTypes from 'prop-types';

const CheckCircleIcon = ({ fill, width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 14"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.0001 13.4C10.5347 13.4 13.4001 10.5346 13.4001 7.00001C13.4001 3.46538 10.5347 0.600006 7.0001 0.600006C3.46548 0.600006 0.600098 3.46538 0.600098 7.00001C0.600098 10.5346 3.46548 13.4 7.0001 13.4ZM9.96578 5.96569C10.2782 5.65327 10.2782 5.14674 9.96578 4.83432C9.65336 4.5219 9.14683 4.5219 8.83441 4.83432L6.2001 7.46864L5.16578 6.43432C4.85336 6.1219 4.34683 6.1219 4.03441 6.43432C3.72199 6.74674 3.72199 7.25327 4.03441 7.56569L5.63441 9.16569C5.94683 9.47811 6.45336 9.47811 6.76578 9.16569L9.96578 5.96569Z"
      fill="inherit"
    />
  </svg>
);

CheckCircleIcon.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

CheckCircleIcon.defaultProps = {
  fill: '#05751F',
  width: 14,
  height: 14,
};

export default CheckCircleIcon;
