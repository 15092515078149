import { put, takeLatest } from 'redux-saga/effects';

import { getFirmInfoApi, getBankCodesApi } from 'services/Firm/api';
import { getVendorInfoApi } from 'services/MainHeader/api';
import { createStandardSaga } from 'utils/createStandardSaga';
import translate from 'utils/translate';
import { open } from 'utils/notification';

import * as FinancialInformationActions from './actions';
import * as Types from './types';

const getReferenceVendorSaga = createStandardSaga({
  callApi: getVendorInfoApi,
  successAction: FinancialInformationActions.getReferenceVendorSuccess,
  errorAction: FinancialInformationActions.getReferenceVendorError,
});

const getReferenceFirmSaga = createStandardSaga({
  callApi: getFirmInfoApi,
  successAction: FinancialInformationActions.getReferenceFirmSuccess,
  errorAction: FinancialInformationActions.getReferenceFirmError,
});

// const getBankCodesSaga = createStandardSaga({
//   callApi: getBankCodesApi,
//   successAction: FinancialInformationActions.getBankCodesSuccess,
//   errorAction: FinancialInformationActions.getBankCodesError,
// });

function* getBankCodesSaga() {
  try {
    const response = yield getBankCodesApi();
    if (response.data.payload) {
      const bankCodes = response.data.payload.map(bankCode => ({
        bankIca: bankCode.bankIca,
        eftCode: bankCode.eftCode,
        label: bankCode.bankName,
        value: bankCode.bankId,
      }));
      yield put(FinancialInformationActions.getBankCodesSuccess(bankCodes));
    }

    if (response.message) {
      open('warning', translate('common:notif.warnTitle'), response.message);
    }
  } catch (error) {
    yield put(FinancialInformationActions.getBankCodesError(error));
  }
}

export function* rootSaga() {
  yield takeLatest(Types.GET_REFERENCE_VENDOR_REQUEST, getReferenceVendorSaga);
  yield takeLatest(Types.GET_REFERENCE_FIRM_REQUEST, getReferenceFirmSaga);
  yield takeLatest(Types.GET_BANK_CODES_REQUEST, getBankCodesSaga);
}

export default rootSaga;
