import React from 'react';
import PropTypes from 'prop-types';

const InfoIcon = ({ fill, width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM11.25 5C11.25 5.69036 10.6904 6.25 10 6.25C9.30964 6.25 8.75 5.69036 8.75 5C8.75 4.30964 9.30964 3.75 10 3.75C10.6904 3.75 11.25 4.30964 11.25 5ZM8.75 8.75C8.05964 8.75 7.5 9.30964 7.5 10C7.5 10.6904 8.05964 11.25 8.75 11.25V15C8.75 15.6904 9.30964 16.25 10 16.25H11.25C11.9404 16.25 12.5 15.6904 12.5 15C12.5 14.3096 11.9404 13.75 11.25 13.75V10C11.25 9.30964 10.6904 8.75 10 8.75H8.75Z"
      fill="inherit"
    />
  </svg>
);

InfoIcon.propTypes = {
  fill: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

InfoIcon.defaultProps = {
  fill: 'white',
  width: 20,
  height: 20,
};

export default InfoIcon;
