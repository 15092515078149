import { createAction } from 'utils/createAction';

import * as Types from './types';

// list vendorProduct
export const vendorProductListRequest = createAction(
  Types.VENDORPRODUCT_LIST_REQUEST,
);
export const vendorProductListSuccess = createAction(
  Types.VENDORPRODUCT_LIST_SUCCESS,
);
export const vendorProductListError = createAction(
  Types.VENDORPRODUCT_LIST_ERROR,
);

// list vendor Brand products
export const vendorProductBrandListRequest = createAction(
  Types.VENDORPRODUCT_BRAND_LIST_REQUEST,
);
export const vendorProductBrandListSuccess = createAction(
  Types.VENDORPRODUCT_BRAND_LIST_SUCCESS,
);
export const vendorProductBrandListError = createAction(
  Types.VENDORPRODUCT_BRAND_LIST_ERROR,
);
export const vendorProductBrandListReset = createAction(
  Types.VENDORPRODUCT_BRAND_LIST_RESET,
);

// open Product
export const vendorProductOpenRequest = createAction(
  Types.VENDORPRODUCT_OPEN_REQUEST,
);
export const vendorProductOpenSuccess = createAction(
  Types.VENDORPRODUCT_OPEN_SUCCESS,
);
export const vendorProductOpenError = createAction(
  Types.VENDORPRODUCT_OPEN_ERROR,
);

// edit Product
export const vendorProductEditRequest = createAction(
  Types.VENDORPRODUCT_EDIT_REQUEST,
);
export const vendorProductEditSuccess = createAction(
  Types.VENDORPRODUCT_EDIT_SUCCESS,
);
export const vendorProductEditError = createAction(
  Types.VENDORPRODUCT_EDIT_ERROR,
);

// close Product
export const vendorProductCloseRequest = createAction(
  Types.VENDORPRODUCT_CLOSE_REQUEST,
);
export const vendorProductCloseSuccess = createAction(
  Types.VENDORPRODUCT_CLOSE_SUCCESS,
);
export const vendorProductCloseError = createAction(
  Types.VENDORPRODUCT_CLOSE_ERROR,
);

// get Product
export const vendorProductGetRequest = createAction(
  Types.VENDORPRODUCT_GET_REQUEST,
);
export const vendorProductGetSuccess = createAction(
  Types.VENDORPRODUCT_GET_SUCCESS,
);
export const vendorProductGetError = createAction(
  Types.VENDORPRODUCT_GET_ERROR,
);
export const vendorProductGetReset = createAction(
  Types.VENDORPRODUCT_GET_RESET,
);

// get Product General Info
export const vendorProductInfoRequest = createAction(
  Types.VENDORPRODUCT_PRODUCTINFO_REQUEST,
);
export const vendorProductInfoSuccess = createAction(
  Types.VENDORPRODUCT_PRODUCTINFO_SUCCESS,
);
export const vendorProductInfoError = createAction(
  Types.VENDORPRODUCT_PRODUCTINFO_ERROR,
);
export const vendorProductInfoReset = createAction(
  Types.VENDORPRODUCT_PRODUCTINFO_RESET,
);

// get Categories
export const vendorProductCategoriesRequest = createAction(
  Types.VENDORPRODUCT_CATEGORIES_REQUEST,
);
export const vendorProductCategoriesSuccess = createAction(
  Types.VENDORPRODUCT_CATEGORIES_SUCCESS,
);
export const vendorProductCategoriesError = createAction(
  Types.VENDORPRODUCT_CATEGORIES_ERROR,
);
