import _ from 'lodash';

const isCrossedOutPriceZeroOrUndefined = crossedOutPrice =>
  crossedOutPrice === 0 || _.isUndefined(crossedOutPrice);
const isCrossedOutPriceShownAndGreaterThanPrice = (
  showCrossedOutPrice,
  crossedOutPrice,
  price,
) => showCrossedOutPrice && crossedOutPrice > price;

const obtainValidationRules = (t, crossedOutPrice, showCrossedOutPrice) => ({
  name: {},
  categoryname: {},
  price: {
    required: t('requiredFieldText'),
    validate: {
      isStrikethroughPriceIsGreaterThanPrice: price =>
        !showCrossedOutPrice ||
        isCrossedOutPriceZeroOrUndefined(
          crossedOutPrice,
          showCrossedOutPrice,
        ) ||
        isCrossedOutPriceShownAndGreaterThanPrice(
          showCrossedOutPrice,
          crossedOutPrice,
          price,
        ) ||
        t('strikethroughPriceIsLessThanPriceError'),
    },
  },
  priceStrikeout: {},
  depositPrice: {
    required: t('requiredFieldText'),
  },
});
export {
  obtainValidationRules,
  isCrossedOutPriceZeroOrUndefined,
  isCrossedOutPriceShownAndGreaterThanPrice,
};
