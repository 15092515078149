import * as Types from './types';

const initialState = {
  firms: [],
  error: {},
  addFirm: '',
  firmId: undefined,
  firmInfo: {},
  editFirm: undefined,
  deleteFirm: '',
};

function FirmReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.FIRM_LIST_SUCCESS:
      return {
        ...state,
        firms: payload,
      };
    case Types.FIRM_LIST_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.ADD_FIRM_SUCCESS:
      return {
        ...state,
        addFirm: payload,
      };
    case Types.ADD_FIRM_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.SET_FIRM_ID:
      return {
        ...state,
        firmId: payload,
      };
    case Types.GET_FIRM_INFO_SUCCESS:
      return {
        ...state,
        firmInfo: payload,
      };
    case Types.GET_FIRM_INFO_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.EDIT_FIRM_SUCCESS:
      return {
        ...state,
        editFirm: payload,
      };
    case Types.EDIT_FIRM_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.DELETE_FIRM_SUCCESS: {
      const { result } = payload;

      return {
        ...state,
        deleteFirm: result,
      };
    }
    case Types.DELETE_FIRM_ERROR:
      return {
        ...state,
        error: payload,
      };
    default:
      return state;
  }
}

export default FirmReducer;
