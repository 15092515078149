import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { Checkbox } from '@getir/web-components';

import * as CS from 'common/style';

const CheckboxWrapper = ({
  fieldName,
  control,
  rules,
  label,
  errors,
  isDisabled,
  checkboxValue,
  onChange,
  defaultValues,
}) => (
  <CS.FormFieldWrapper>
    <Controller
      name={fieldName}
      control={control}
      rules={rules}
      render={({ field }) => (
        <Checkbox
          defaultValues={defaultValues}
          inputRef={field.ref}
          onChange={value => {
            if (onChange) onChange(value);
            field.onChange(value);
          }}
          onBlur={field.onBlur}
          name={field.name}
          hasError={!!errors[fieldName]}
          disabled={isDisabled}
          checked={field.value ? field.value : false}
          value={checkboxValue}
        >
          {label}
        </Checkbox>
      )}
    />
    {errors[fieldName] && (
      <CS.ErrorMessage>{errors[fieldName].message}</CS.ErrorMessage>
    )}
  </CS.FormFieldWrapper>
);

CheckboxWrapper.defaultProps = {
  fieldName: '',
  control: {},
  rules: {},
  label: '',
  errors: {},
  isDisabled: false,
  checkboxValue: '',
  onChange: () => {},
  defaultValues: false,
};

CheckboxWrapper.propTypes = {
  fieldName: PropTypes.string,
  control: PropTypes.object,
  rules: PropTypes.object,
  label: PropTypes.string,
  errors: PropTypes.object,
  isDisabled: PropTypes.bool,
  checkboxValue: PropTypes.string,
  onChange: PropTypes.func,
  defaultValues: PropTypes.bool,
};

export default CheckboxWrapper;
