import React, { useEffect, useState } from 'react';
import { Space, Divider, Alert } from 'antd';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import _, { isUndefined } from 'lodash';

import Button from 'components/v1/Button';
import {
  financialInfoTypes,
  financialInfoTypesValues,
} from 'constants/financials';
import Layout from 'components/Layout';
import InputWrapper from 'components/InputWrapper';
import SelectBoxWrapper from 'components/SelectBoxWrapper';
import SelectBoxAntd from 'components/Antd/FormElements/SelectBox';
import AuthorizedComponent from 'components/AuthorizedComponent';
import * as MainHeaderActions from 'components/MainHeader/actions';
import ConfirmModal from 'components/ConfirmModal';
import * as VendorActions from 'containers/Stores/Vendor/actions';

import { createRequestBody } from './utils/createRequestBody';
import { validations } from './validations';
import { useFinancialsOnSubmit } from './useFinancialsOnSubmit';
import * as S from './style';
import * as FinancialInformationActions from './actions';

const firmTypesEnum = {
  1: 'INDIVIDUAL',
  2: 'CORPORATE',
};

const setFormValue = (fieldName, setValue, currentObject, validate = true) => {
  setValue(fieldName, currentObject[`${fieldName}`], {
    shouldValidate: validate,
  });
};

const setNestedFormValue = (
  fieldName,
  setValue,
  currentObject,
  validate = true,
) => {
  setValue(fieldName, currentObject, {
    shouldValidate: validate,
  });
};

const FinancialInformation = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isFieldsEditable, setIsFieldsEditable] = useState(false);
  const [isIndividual, setIsIndividual] = useState(false);
  const [firmVendorList, setFirmVendorList] = useState([]);
  const [isReferenceVendorIsFirm, setIsReferenceVendorIsFirm] = useState(false);
  const [
    isVendorConfirmModalVisible,
    setIsVendorConfirmModalVisible,
  ] = useState(false);

  const vendorInfo = useSelector(state => state.mainHeader.vendorInfo);
  const vendors = useSelector(state => state.mainHeader.vendors);
  const cityList = useSelector(state => state.mainHeader.cities);
  const districtList = useSelector(state => state.vendor.districts);

  const referenceInfo = useSelector(
    state => state.financialInformation.referenceInfo.data,
  );

  const bankCodes = useSelector(
    state => state.financialInformation.bankCodes.data,
  );

  const financialInfo = useSelector(
    state => state.mainHeader.vendorInfo.financialInfo,
  );

  const isSAPAreasEditable = !useSelector(
    state => state.mainHeader.vendorInfo.isPanelRestricted,
  );

  const financialDetail = useSelector(
    state => state.mainHeader.vendorInfo.financialDetail,
  );

  useEffect(() => {
    if (!bankCodes) {
      dispatch(FinancialInformationActions.getBankCodesRequest());
    }
  }, [bankCodes]); // eslint-disable-line

  const firmTypes = [
    { label: t('financialInformation:individual'), value: firmTypesEnum[1] },
    { label: t('financialInformation:corporate'), value: firmTypesEnum[2] },
  ];

  /* eslint-disable */
  const districtOptions = districtList
    ? districtList.map(item => ({
        value: item.id,
        label: item.name,
      }))
    : [];
  /* eslint-enable */

  const {
    handleSubmit,
    formState: { errors, isDirty, isValid, dirtyFields },
    control,
    setValue,
    watch,
    register,
    unregister,
    getValues,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      financialInfoType: financialInfoTypesValues.NEW,
    },
  });

  const selectedFirmType = watch('firmType');
  const selectedFinancialInfoType = watch('financialInfoType');
  const selectedSelectFirmVendor = watch('selectFirmVendor');
  const selectedCity = watch('city');

  const checkFieldDisabled =
    selectedFinancialInfoType === financialInfoTypesValues.FIRM ||
    (financialDetail && financialDetail.sharedReferences
      ? selectedSelectFirmVendor
      : selectedFinancialInfoType !== financialInfoTypesValues.NEW) ||
    !isFieldsEditable;

  const validationRules = validations(isIndividual);

  const clearFields = () => {
    setValue('firmTitle', undefined);
    setValue('firmType', undefined);
    setValue('taxOffice', undefined);
    setValue('firmManager', undefined);
    setValue('managerPhoneNumber', undefined);
    setValue('email', undefined);
    setValue('openAddress', undefined);
    setValue('bank', undefined);
    setValue('iban', undefined);
    setValue('bankAccountName', undefined);
    setValue('identityNumber', undefined);
    setValue('taxNumber', undefined);
    setValue('sapcardCode', undefined);
    setValue('city', undefined);
    setValue('district', undefined);
    setValue('vendorPhoneNumber', undefined);

    setValue('financialInfoType', financialInfoTypesValues.NEW);
    setValue('sharedReferences', undefined);
  };

  useEffect(() => {
    setIsFieldsEditable(false);
    if (financialInfo && Object.keys(financialInfo).length > 0) {
      setFormValue('firmTitle', setValue, financialInfo);
      setFormValue('firmType', setValue, financialInfo);
      setFormValue('taxOffice', setValue, financialInfo);
      setFormValue('firmManager', setValue, financialInfo);
      setFormValue('managerPhoneNumber', setValue, financialInfo);
      setFormValue('email', setValue, financialInfo);
      setFormValue('openAddress', setValue, financialInfo);
      setFormValue('iban', setValue, financialInfo);
      setFormValue('bankAccountName', setValue, financialInfo);
      setFormValue('identityNumber', setValue, financialInfo);
      setFormValue('taxNumber', setValue, financialInfo);
      setFormValue('firmTitle', setValue, financialInfo);
      setFormValue('sapcardCode', setValue, vendorInfo);
      setFormValue('bank', setValue, financialInfo);
      setNestedFormValue(
        'vendorPhoneNumber',
        setValue,
        financialInfo.vendorPhone,
      );
      setNestedFormValue('city', setValue, financialInfo.city);
      setNestedFormValue('district', setValue, financialInfo.town);
      setValue(
        'bank',
        {
          value: Number(financialInfo.bank.id),
          label: financialInfo.bank.name,
        },
        {
          shouldValidate: true,
        },
      );
      setValue('financialInfoType', financialInfoTypesValues.NEW);

      return;
    }
    clearFields();
  }, [financialInfo, vendorInfo]); // eslint-disable-line

  useEffect(() => {
    if (referenceInfo && referenceInfo.financialInfo) {
      const { financialInfo: refFinancialInfo } = referenceInfo;
      setFormValue('firmTitle', setValue, refFinancialInfo);
      setFormValue('firmType', setValue, refFinancialInfo);
      setFormValue('taxOffice', setValue, refFinancialInfo);
      setFormValue('firmManager', setValue, refFinancialInfo);
      setFormValue('iban', setValue, refFinancialInfo);
      setFormValue('bankAccountName', setValue, refFinancialInfo);
      setFormValue('identityNumber', setValue, refFinancialInfo);
      setFormValue('taxNumber', setValue, refFinancialInfo);
      setFormValue('firmTitle', setValue, refFinancialInfo);
      setFormValue('sapcardCode', setValue, referenceInfo);
      setNestedFormValue(
        'vendorPhoneNumber',
        setValue,
        refFinancialInfo.vendorPhone,
      );
      setNestedFormValue(
        'city',
        setValue,
        _.get(referenceInfo, ['financialInfo', 'city'], undefined),
      );
      setNestedFormValue(
        'district',
        setValue,
        _.get(referenceInfo, ['financialInfo', 'town'], undefined),
      );
      setValue(
        'bank',
        {
          value: Number(refFinancialInfo.bank.id),
          label: refFinancialInfo.bank.name,
        },
        {
          shouldValidate: true,
        },
      );
      if (isReferenceVendorIsFirm) {
        setValue('managerPhoneNumber', referenceInfo.managerTelNumber, {
          shouldValidate: true,
        });
        setValue('firmManager', referenceInfo.manager, {
          shouldValidate: true,
        });
        setValue('openAddress', referenceInfo.address, {
          shouldValidate: true,
        });
        setValue('email', referenceInfo.financialInfo.email, {
          shouldValidate: true,
        });

        unregister('vendorPhoneNumber');
        unregister('city');
        unregister('district');

        return;
      }
      setFormValue('managerPhoneNumber', setValue, refFinancialInfo);
      setFormValue('email', setValue, refFinancialInfo);
      setFormValue('openAddress', setValue, refFinancialInfo);
    }
  }, [referenceInfo]); // eslint-disable-line

  useEffect(() => {
    if (financialDetail) {
      const {
        sharedReferences,
        vendorReferenced,
        firmReferenced,
      } = financialDetail;

      if (sharedReferences) {
        const sharedReferenceNames = sharedReferences
          .map(reference => reference.name)
          .join(', ');
        setValue('sharedReferences', sharedReferenceNames);
      } else {
        setValue('sharedReferences', '');
      }

      if (vendorReferenced) {
        setValue('financialInfoType', financialInfoTypesValues.VENDOR);
      }

      if (firmReferenced) {
        setValue('financialInfoType', financialInfoTypesValues.FIRM);
      }
    }
  }, [financialDetail]); // eslint-disable-line

  useEffect(() => {
    if (selectedFirmType) {
      if (
        selectedFirmType.value === firmTypesEnum[1] ||
        selectedFirmType === firmTypesEnum[1]
      ) {
        setIsIndividual(true);
        unregister('taxNumber');
        register('identityNumber');

        if (referenceInfo) {
          setValue(
            'identityNumber',
            _.get(
              referenceInfo,
              ['financialInfo', 'identityNumber'],
              undefined,
            ),
          );
        } else {
          setValue(
            'identityNumber',
            _.get(financialInfo, 'identityNumber', undefined),
          );
        }

        return;
      }
      setIsIndividual(false);
      unregister('identityNumber');
      register('taxNumber');

      if (referenceInfo) {
        setValue(
          'taxNumber',
          _.get(referenceInfo, ['financialInfo', 'taxNumber'], undefined),
        );
      } else {
        setValue('taxNumber', _.get(financialInfo, 'taxNumber', undefined));
      }
    }
  }, [selectedFirmType, financialInfo]); // eslint-disable-line

  useEffect(() => {
    if (selectedFinancialInfoType !== undefined) {
      const onlyFirmsList = vendors.filter(
        vendor => vendor.isFirm === true && vendor.value !== vendorInfo.id,
      );
      const onlyVendorsList = vendors.filter(
        vendor => vendor.isFirm === false && vendor.value !== vendorInfo.id,
      );

      if (selectedFinancialInfoType === financialInfoTypesValues.NEW) {
        setValue('selectFirmVendor', undefined);
      }

      if (selectedFinancialInfoType === financialInfoTypesValues.VENDOR) {
        setFirmVendorList(onlyVendorsList);
      }
      if (selectedFinancialInfoType === financialInfoTypesValues.FIRM) {
        setFirmVendorList(onlyFirmsList);
      }
      setIsReferenceVendorIsFirm(
        selectedFinancialInfoType === financialInfoTypesValues.FIRM,
      );
    }
    setValue('selectFirmVendor', undefined);
  }, [selectedFinancialInfoType]); // eslint-disable-line

  useEffect(() => {
    if (selectedSelectFirmVendor) {
      setValue('sharedReferences', undefined);
      if (isReferenceVendorIsFirm) {
        dispatch(
          FinancialInformationActions.getReferenceFirmRequest(
            selectedSelectFirmVendor,
          ),
        );

        return;
      }
      dispatch(
        FinancialInformationActions.getReferenceVendorRequest(
          selectedSelectFirmVendor,
        ),
      );
    }
  }, [selectedSelectFirmVendor]); // eslint-disable-line

  useEffect(() => {
    if (selectedCity) {
      dispatch(
        VendorActions.getDistrictsRequest(
          typeof selectedCity === 'object' ? selectedCity.value : selectedCity,
        ),
      );
    }

    if (!isUndefined(selectedCity) && dirtyFields.city) {
      setValue('district', undefined);
    }
  }, [selectedCity]); // eslint-disable-line

  useEffect(() => {
    if (financialDetail) {
      const { sharedReferences } = financialDetail;
      if (sharedReferences) {
        unregister('selectFirmVendor');
      } else {
        if (selectedFinancialInfoType === financialInfoTypesValues.NEW) {
          unregister('selectFirmVendor');

          return;
        }
        register('selectFirmVendor', { required: t('requiredFieldText') });
      }
    } else {
      if (selectedFinancialInfoType === financialInfoTypesValues.NEW) {
        unregister('selectFirmVendor');

        return;
      }
      register('selectFirmVendor', { required: t('requiredFieldText') });
    }
  }, [selectedFinancialInfoType, financialDetail]); // eslint-disable-line

  const toggleFieldsEditability = value => {
    setIsFieldsEditable(value);
  };

  const handleVendorConfirmModalSave = () => {
    const values = getValues();
    const data = createRequestBody(
      values,
      isIndividual,
      vendorInfo,
      selectedFinancialInfoType,
    );
    dispatch(VendorActions.updateFinancialInfoRequest(data));
    dispatch(FinancialInformationActions.getReferenceFirmSuccess(undefined));
    setIsVendorConfirmModalVisible(false);
    setIsFieldsEditable(false);
  };

  const handleVendorConfirmModalClose = () => {
    setIsVendorConfirmModalVisible(false);
  };

  const onSubmit = useFinancialsOnSubmit({
    t,
    selectedFinancialInfoType,
    setIsVendorConfirmModalVisible,
    setIsFieldsEditable,
    isIndividual,
    financialDetail,
  });

  const editButtonStatus = vendorInfo && vendorInfo.id;

  const alertMessageStatus = vendorInfo && vendorInfo.id;

  const handleCancelClick = () => {
    dispatch(MainHeaderActions.getVendorInfoRequest(vendorInfo.id));
    dispatch(FinancialInformationActions.getReferenceFirmSuccess(undefined));
    setIsFieldsEditable(false);
  };

  return (
    <Layout>
      <S.PageHeader>
        <S.PageTitle>{t('financialInformation:title')}</S.PageTitle>
        {!isFieldsEditable && (
          <S.PageTitleActions>
            {editButtonStatus && (
              <AuthorizedComponent componentId="edit_financial_info">
                <Space size="small">
                  <Button onClick={() => toggleFieldsEditability(true)}>
                    {t('edit')}
                  </Button>
                </Space>
              </AuthorizedComponent>
            )}
          </S.PageTitleActions>
        )}
      </S.PageHeader>
      <S.PageContent>
        {!alertMessageStatus && (
          <S.AlertWrapper>
            <Alert
              message={t('financialInformation:forOnlyVendors')}
              type="warning"
              showIcon
            />
          </S.AlertWrapper>
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <SelectBoxAntd
            fieldName="financialInfoType"
            control={control}
            rules={validationRules.financialInfoType}
            placeholder={t('financialInformation:financialInformationUsage')}
            errors={errors}
            options={financialInfoTypes.map(item => ({
              value: item.value,
              label: t(`financialInformation:${item.label}`),
            }))}
            isDisabled={checkFieldDisabled || !isSAPAreasEditable}
          />
          {isFieldsEditable && (
            <SelectBoxAntd
              fieldName="selectFirmVendor"
              control={control}
              placeholder={t('financialInformation:chooseFirmOrVendor')}
              errors={errors}
              options={firmVendorList}
              disabled={
                selectedFinancialInfoType === financialInfoTypesValues.NEW ||
                !isSAPAreasEditable
              }
              showSearch
            />
          )}
          {!isFieldsEditable && (
            <InputWrapper
              fieldName="sharedReferences"
              control={control}
              placeholder={t('financialInformation:savedFirmOrVendor')}
              header={t('financialInformation:savedFirmOrVendor')}
              errors={errors}
              isDisabled
            />
          )}
          <InputWrapper
            fieldName="firmTitle"
            control={control}
            rules={validationRules.title}
            placeholder={t('financialInformation:firmTitle')}
            header={t('financialInformation:firmTitle')}
            errors={errors}
            isDisabled={checkFieldDisabled || !isSAPAreasEditable}
          />
          <SelectBoxWrapper
            fieldName="firmType"
            control={control}
            rules={validationRules.type}
            placeholder={t('financialInformation:firmType')}
            errors={errors}
            options={firmTypes}
            isDisabled={checkFieldDisabled || !isSAPAreasEditable}
            labelInValue
          />
          <InputWrapper
            fieldName="taxOffice"
            control={control}
            rules={validationRules.taxOffice}
            placeholder={t('financialInformation:taxOffice')}
            header={t('financialInformation:taxOffice')}
            errors={errors}
            isDisabled={checkFieldDisabled || !isSAPAreasEditable}
          />
          <InputWrapper
            fieldName="identityNumber"
            control={control}
            rules={validationRules?.idNumber}
            placeholder={t('financialInformation:identityNumber')}
            header={t('financialInformation:identityNumber')}
            errors={errors}
            isOnlyNumber
            isDisabled={checkFieldDisabled || !isSAPAreasEditable}
            style={{ display: isIndividual ? 'block' : 'none' }}
          />
          <InputWrapper
            fieldName="taxNumber"
            control={control}
            rules={validationRules?.taxNumber}
            placeholder={t('financialInformation:taxNumber')}
            header={t('financialInformation:taxNumber')}
            errors={errors}
            isDisabled={checkFieldDisabled || !isSAPAreasEditable}
            isOnlyNumber
            style={{ display: isIndividual ? 'none' : 'block' }}
          />
          {!isReferenceVendorIsFirm && (
            <InputWrapper
              fieldName="vendorPhoneNumber"
              control={control}
              placeholder="Bayi Telefonu"
              header="Bayi Telefonu"
              errors={errors}
              isOnlyNumber
              rules={validationRules?.vendorPhoneNumber}
              isDisabled={checkFieldDisabled || !isSAPAreasEditable}
            />
          )}
          <InputWrapper
            fieldName="firmManager"
            control={control}
            rules={validationRules.firmManager}
            placeholder={t('financialInformation:firmManager')}
            header={t('financialInformation:firmManager')}
            errors={errors}
            isDisabled={checkFieldDisabled}
          />
          <InputWrapper
            fieldName="managerPhoneNumber"
            control={control}
            rules={validationRules.managerPhoneNumber}
            placeholder={t('financialInformation:managerPhoneNumber')}
            header={t('financialInformation:managerPhoneNumber')}
            errors={errors}
            isDisabled={checkFieldDisabled}
            isOnlyNumber
          />
          <InputWrapper
            fieldName="email"
            control={control}
            rules={validationRules.email}
            placeholder={t('mail')}
            header={t('mail')}
            errors={errors}
            isDisabled={checkFieldDisabled || !isSAPAreasEditable}
          />
          {!isReferenceVendorIsFirm && (
            <>
              <SelectBoxAntd
                showSearch
                fieldName="city"
                control={control}
                placeholder={t('city')}
                errors={errors}
                options={cityList}
                isDisabled={checkFieldDisabled || !isSAPAreasEditable}
                rules={validationRules.city}
              />
              <SelectBoxAntd
                showSearch
                fieldName="district"
                control={control}
                placeholder={t('district')}
                errors={errors}
                options={districtOptions}
                isDisabled={checkFieldDisabled}
                rules={validationRules.district}
              />
            </>
          )}
          <InputWrapper
            fieldName="openAddress"
            control={control}
            rules={validationRules.address}
            placeholder={t('financialInformation:address')}
            header={t('financialInformation:address')}
            errors={errors}
            isDisabled={checkFieldDisabled || !isSAPAreasEditable}
          />
          <SelectBoxAntd
            showSearch
            fieldName="bank"
            control={control}
            rules={validationRules.bank}
            placeholder={t('financialInformation:bank')}
            errors={errors}
            options={bankCodes || []}
            isDisabled={checkFieldDisabled || !isSAPAreasEditable}
            labelInValue
          />
          <InputWrapper
            fieldName="iban"
            control={control}
            rules={validationRules.iban}
            placeholder={t('financialInformation:iban')}
            header={t('financialInformation:iban')}
            errors={errors}
            isDisabled={checkFieldDisabled || !isSAPAreasEditable}
          />
          <InputWrapper
            fieldName="bankAccountName"
            control={control}
            rules={validationRules.bankAccountName}
            placeholder={t('financialInformation:bankAccountName')}
            header={t('financialInformation:bankAccountName')}
            errors={errors}
            isDisabled
          />
          <InputWrapper
            fieldName="sapcardCode"
            control={control}
            placeholder={t('financialInformation:sapcardCode')}
            header={t('financialInformation:sapcardCode')}
            errors={errors}
            isDisabled={checkFieldDisabled || !isSAPAreasEditable}
          />
          <Divider />
          {isFieldsEditable && (
            <Space size="middle">
              <Button
                style={{
                  width: '100%',
                }}
                kind="secondary"
                onClick={handleCancelClick}
              >
                {t('giveUp')}
              </Button>
              <Button
                style={{
                  width: '100%',
                }}
                type="submit"
                disabled={!isDirty || !isValid}
              >
                {t('save')}
              </Button>
            </Space>
          )}
        </form>
      </S.PageContent>
      <ConfirmModal
        size="400px"
        visible={isVendorConfirmModalVisible}
        onSave={handleVendorConfirmModalSave}
        onClose={handleVendorConfirmModalClose}
        title={t('financialInformation:vendorConfirmModalTitle')}
        content={t('financialInformation:vendorConfirmModalContent')}
        cancelText={t('giveUp')}
        okText={t('yes')}
      />
    </Layout>
  );
};

export default FinancialInformation;
