import styled from 'styled-components';
import { Table } from 'antd';
import { Icon } from '@getir/web-components';

const StyledTable = styled(Table)`
  ${Icon.S.Wrapper} {
    color: #db471e;
    cursor: pointer;
  }
`;

export { StyledTable };
