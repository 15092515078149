/* eslint-disable indent */
import styled from 'styled-components';
import { Layout as LayoutAntd } from 'antd';

import COLORS from 'constants/colors';
import breakpoint from 'constants/breakpoint';

const { Sider } = LayoutAntd;

const LayoutWrapper = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: minmax(min-content, auto) 1fr;
  grid-template-rows: 60px 1fr;
  grid-template-areas:
    'header header'
    'menu content';

  @media (max-width: ${breakpoint.MOBILE.MAX}px) {
    grid-template-rows: 60px 1fr;
    grid-template-columns: 100%;
    grid-template-areas:
      'header'
      'content';
    height: ${props => (props.collapsed ? 'auto' : '100vh')};
    overflow: ${props => (props.collapsed ? 'auto' : 'hidden')};
  }
`;

const StyledSider = styled(Sider)`
  background-color: ${COLORS.mainGetirColor} !important;
  animation-duration: 0ms !important;
  .ant-layout-sider-children {
    height: 100% !important;
  }
  .ant-menu {
    height: 100% !important;
  }

  &.ant-layout-sider {
    @media (max-width: ${breakpoint.MOBILE.MAX}px) {
      position: absolute;
      height: calc(100vh - 60px);
      top: 60px;
      z-index: 10;
    }
  }
`;

const MainContentWrapper = styled.main`
  grid-area: content;
  border-top: 1px solid ${COLORS.menuHoverColor};
  background-color: ${COLORS.contentBgColor};
  width: ${props =>
    props.collapsed ? 'calc(100vw - 80px)' : 'calc(100vw - 285px)'};

  @media (max-width: ${breakpoint.MOBILE.MAX}px) {
    padding: 1rem;
    width: 100vw;
    box-sizing: border-box;
  }
`;

const MainContent = styled.div`
  padding: 1rem 2rem;

  @media (max-width: ${breakpoint.MOBILE.MAX}px) {
    padding: 0;
  }
`;

export { LayoutWrapper, MainContentWrapper, MainContent, StyledSider };
