import { apiGateway } from 'constants/services';

export default {
  listFirms: `${apiGateway}/firm-service/admin/get-all`,
  addFirm: `${apiGateway}/firm-service/admin/create`,
  updateFirm: `${apiGateway}/firm-service/admin/update`,
  deleteFirm: firmId => `${apiGateway}/firm-service/admin/delete/${firmId}`,
  getFirmInfo: firmId => `${apiGateway}/firm-service/get/${firmId}`,
  getBankCodes: `${apiGateway}/bank-info-service`,
};
