import React, { useEffect, useState } from 'react';
import { Empty } from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SearchOutlined } from '@ant-design/icons';

import Input from 'components/v1/Input';
import ConfirmModal from 'components/ConfirmModal';
import * as ROUTES from 'constants/routes';
import { pushToScreenBy } from 'utils/pushToScreenBy';

import { categoryListRequest, deleteCategoryRequest } from '../actions';
import { extractCategoryListDataFrom } from '../helpers/extractCategoryListDataFrom';
import { filterCategoryListDataBy } from '../helpers/filterCategoryListDataBy';

import * as S from './style';
import { useColumns } from './useColumns';

const List = () => {
  const [modalState, setModalState] = useState({
    visible: false,
    id: null,
  });
  const history = useHistory();
  const dispatch = useDispatch();
  const categoryList = useSelector(state => state.category.categorys);
  const pushToEdit = pushToScreenBy(history, ROUTES.EDIT_CATEGORY);
  const columns = useColumns(pushToEdit, setModalState);
  const { t } = useTranslation(['common', 'categories']);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (categoryList === undefined) dispatch(categoryListRequest());
  }, [dispatch, categoryList]);

  const handleDeleteCategoryModalSave = () => {
    dispatch(deleteCategoryRequest(modalState.id));
    setModalState({
      visible: false,
      id: null,
    });
  };

  const handleDeleteCategoryModalClose = () => {
    setModalState({
      visible: false,
      id: null,
    });
  };

  return (
    <S.Container>
      <Input
        placeholder={t('categories:searchCategoryName')}
        value={searchTerm}
        onChange={evt => setSearchTerm(evt.target.value)}
        size="large"
        style={{ width: '300px' }}
        prefix={<SearchOutlined width={20} height={20} />}
      />
      <S.CategoryListTable
        columns={columns}
        dataSource={filterCategoryListDataBy(
          categoryList ? extractCategoryListDataFrom(categoryList) : [],
          searchTerm,
        )}
        rowKey="id"
        pagination={{ size: 'small', position: ['bottomCenter'] }}
        locale={{ emptyText: <Empty description={t('table.noData')} /> }}
        scroll={{ x: 'max-content' }}
      />
      <ConfirmModal
        size="400px"
        visible={modalState.visible}
        onSave={handleDeleteCategoryModalSave}
        onClose={handleDeleteCategoryModalClose}
        title={t('categories:deleteCategory')}
        content={t('categories:deleteCategoryConfirm')}
        cancelText={t('cancel')}
      />
    </S.Container>
  );
};

export default List;
