import React from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';

import Badge from 'components/v1/Badge';
import Button from 'components/v1/Button';

import { getDownloadUrl } from './actions';
import { REQUEST_STATUSES } from './constants';
import { ButtonContainer } from './styles';

const useTableColumns = () => {
  const { t } = useTranslation('reports');
  const dispatch = useDispatch();
  const onDownloadClick = async report => {
    dispatch(
      getDownloadUrl({
        token: report.downloadToken,
        query: {
          // eslint-disable-next-line no-underscore-dangle
          reportRequestId: report._id,
          ownerId: report.reportOwner.ownerId,
        },
      }),
    );
  };
  const columns = [
    {
      title: t('name'),
      dataIndex: 'report',
      key: 'report',
      // eslint-disable-next-line no-underscore-dangle
      render: report => <div>{report.name.tr}</div>,
    },
    {
      title: t('requestDate'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: date => <div>{dayjs(date).format('DD/MM/YYYY HH:mm')}</div>,
    },
    {
      title: t('owner'),
      dataIndex: 'reportOwner',
      key: 'reportOwner',
      render: reportOwner => <div>{reportOwner?.ownerName}</div>,
    },
    {
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
      width: 200,
      render: status => {
        switch (status) {
          case REQUEST_STATUSES.FAILURE:
            return <Badge type="failed">{t('failure')}</Badge>;
          case REQUEST_STATUSES.SUCCESS:
            return <Badge type="success">{t('success')}</Badge>;
          default:
            return <Badge type="waiting">{t('pending')}</Badge>;
        }
      },
    },
    {
      title: t('action'),
      dataIndex: 'downloadToken',
      key: 'downloadToken',
      width: 150,
      render: (downloadToken, report) =>
        downloadToken &&
        report.status === REQUEST_STATUSES.SUCCESS && (
          <ButtonContainer>
            <Button
              kind="primary"
              onClick={() => onDownloadClick(report)}
              target="_blank"
            >
              {t('downloadReport')}
            </Button>
          </ButtonContainer>
        ),
    },
  ];

  return { columns };
};

export default useTableColumns;
