import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { message, Upload } from 'antd';
import { useTranslation } from 'react-i18next';

import cookies from 'utils/cookie';

import * as S from './style';

const { Dragger } = S.StyledUpload;

const validFileTypes = ['text/csv'];

const Uploader = ({
  uploadUrl,
  name,
  onRemove,
  multiple,
  onSuccess,
  title,
  imageWidth,
  imageHeight,
  defaultFileList,
  field,
  accept,
  errorMessage,
  style,
  errors,
  fieldName,
  payloadKey,
  listType,
  maxCount,
}) => {
  const { t } = useTranslation();
  const accessToken = cookies.get('accessToken');
  const [currentFileList, setCurrentFileList] = useState(defaultFileList || []);

  useEffect(() => {
    setCurrentFileList(prevFileList => {
      if (prevFileList && prevFileList.length > 0) {
        if (field.value) {
          return prevFileList.map(listItem => ({
            uid: -1,
            name: listItem.name,
            url: field.value,
          }));
        }

        return [];
      }

      return field.value && [{ uid: '-1', url: field.value }];
    });
  }, [field]);

  const getImageSize = file => {
    const image = new Image();
    image.src = URL.createObjectURL(file);

    return new Promise(resolve => {
      image.onload = () => {
        const width = image.naturalWidth;
        const height = image.naturalHeight;

        if (
          (width !== imageWidth || height !== imageHeight) &&
          (imageWidth !== 0 || imageHeight !== 0)
        ) {
          message.error(
            t('imageUpload.uploadWarning', { imageWidth, imageHeight }),
          );
        }

        if (height !== 0 && width !== 0) {
          resolve({ width, height });
        }
      };
    });
  };

  const beforeUpload = async file => {
    const { type } = file;
    if (!validFileTypes.includes(type)) {
      const result = await getImageSize(file);
      const { width, height } = result;

      if (imageWidth === 0 && imageHeight === 0) {
        return true;
      }

      return width === imageWidth && height === imageHeight
        ? true
        : Upload.LIST_IGNORE;
    }

    return null;
  };

  const props = {
    name,
    accept,
    multiple,
    listType,
    maxCount,
    action: uploadUrl,
    beforeUpload,
    fileList: currentFileList,
    headers: { Authorization: `Bearer ${accessToken}` },
    onChange(info) {
      const { fileList } = info;
      const { status } = info.file;
      setCurrentFileList([...fileList]);

      if (status === 'done') {
        message.success(
          t('imageUpload.uploadSuccess', { fileName: info.file.name }),
        );
        if (onSuccess) onSuccess(info.file.response[payloadKey]);

        return;
      }
      if (status === 'error') {
        message.error(
          errorMessage ||
            t('imageUpload.uploadSuccess', { fileName: info.file.name }),
        );

        return;
      }
      if (status === 'removed') {
        onRemove(fileList);
      }
    },
  };

  return (
    <S.StyledFormItem error={errors[fieldName]} style={style}>
      <Dragger {...props}>
        <p className="ant-upload-drag-icon">
          <img
            src="https://panel-fe.artisandev.getirapi.com/1c8583684f5570d37ae0.png"
            alt="upload"
            title="upload"
          />
        </p>
        <p className="ant-upload-text">{title || t('imageUpload.title')}</p>
      </Dragger>
    </S.StyledFormItem>
  );
};

Uploader.propTypes = {
  urlFileList: PropTypes.array,
  defaultFileList: PropTypes.array,
  defaultImageUrl: PropTypes.string,
  uploadUrl: PropTypes.string,
  name: PropTypes.string,
  multiple: PropTypes.bool,
  onSuccess: PropTypes.func,
  onRemove: PropTypes.func,
  title: PropTypes.string,
  imageWidth: PropTypes.number,
  imageHeight: PropTypes.number,
  field: PropTypes.object,
  accept: PropTypes.array,
  errorMessage: PropTypes.string,
  style: PropTypes.object,
  errors: PropTypes.object,
  fieldName: PropTypes.string,
  payloadKey: PropTypes.string,
  listType: PropTypes.string,
  maxCount: PropTypes.number,
};

Uploader.defaultProps = {
  urlFileList: undefined,
  defaultFileList: [],
  defaultImageUrl: '',
  uploadUrl: '',
  name: '',
  multiple: false,
  onSuccess: undefined,
  onRemove: undefined,
  title: undefined,
  imageWidth: 0,
  imageHeight: 0,
  field: {
    value: undefined,
  },
  accept: [],
  errorMessage: '',
  style: {},
  errors: {},
  fieldName: undefined,
  payloadKey: 'payload',
  listType: 'picture',
  maxCount: 1,
};

export default Uploader;
