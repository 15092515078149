import * as Types from './types';

const initialState = {
  permList: {
    data: undefined,
    error: undefined,
  },
  permChildren: {
    data: undefined,
    error: undefined,
  },
  roleList: {
    data: undefined,
    error: undefined,
  },
  updatePerm: {
    data: undefined,
    error: undefined,
  },
};

function PermReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_PERM_LIST_SUCCESS:
      return {
        ...state,
        permList: {
          data: payload,
        },
      };
    case Types.GET_PERM_LIST_ERROR:
      return {
        ...state,
        permList: {
          error: payload,
        },
      };
    case Types.GET_PERM_LIST_CHILDREN_SUCCESS:
      return {
        ...state,
        permChildren: {
          data: payload,
        },
      };
    case Types.GET_PERM_LIST_CHILDREN_ERROR:
      return {
        ...state,
        permChildren: {
          error: payload,
        },
      };
    case Types.GET_ROLE_LIST_SUCCESS:
      return {
        ...state,
        roleList: {
          data: payload,
        },
      };
    case Types.GET_ROLE_LIST_ERROR:
      return {
        ...state,
        roleList: {
          error: payload,
        },
      };
    case Types.UPDATE_PERM_SUCCESS:
      return {
        ...state,
        updatePerm: {
          data: payload,
        },
      };
    case Types.UPDATE_PERM_ERROR:
      return {
        ...state,
        updatePerm: {
          error: payload,
        },
      };
    default:
      return state;
  }
}

export default PermReducer;
