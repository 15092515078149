const deliveryTimes = (minValue, maxValue, differenceValue) => {
  const list = [];
  for (let i = minValue; i <= maxValue; i += differenceValue) {
    list.push({
      value: i,
      label: `${i}`,
    });
  }

  return list;
};

export default deliveryTimes;
