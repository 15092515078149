export const checkFileType = (fileType, accept) => {
  if (!accept.includes(fileType)) {
    return false;
  }

  return true;
};

export const checkFileSize = (fileSize, maxSizeMb) => {
  const fileSizeMb = fileSize / 1024 / 1024; // in MB

  if (fileSizeMb > maxSizeMb) {
    return false;
  }

  return true;
};
