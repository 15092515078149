import { takeLatest, put, select, call } from '@redux-saga/core/effects';

import { listRequestsApi, approveRequestApi } from 'services/Requests/api';
import * as LoaderActions from 'components/Loader/actions';
import * as ROUTES from 'constants/routes';
import { open } from 'utils/notification';
import translate from 'utils/translate';
import history from 'utils/history';

import * as RequestActions from './actions';
import * as Types from './types';
import { requestType, selectedVendor } from './selectors';
import requestActionsTypes from './constants/requestActions';

function* listRequestsSaga({ payload }) {
  yield put(LoaderActions.showLoader());
  const currentRequestType = yield select(requestType);
  const currentVendor = yield select(selectedVendor);

  try {
    const resultData = {
      status: currentRequestType,
    };

    if (currentVendor) {
      if (currentVendor.isFirm) {
        resultData.firmId = currentVendor.value;
      } else {
        resultData.vendorId = currentVendor.value;
      }
    }

    const merged = { ...payload, ...resultData };
    const result = yield call(listRequestsApi, merged);
    if (result.data) {
      yield put(RequestActions.requestsListSuccess(result.data.payload));
    }
  } catch (error) {
    yield put(RequestActions.requestsListError(error.message));
  }
  yield put(LoaderActions.hideLoader());
}

function* approveRequestSaga({ payload }) {
  yield put(LoaderActions.showLoader());

  try {
    const result = yield call(approveRequestApi, payload);
    if (result.data) {
      yield put(RequestActions.approveRequestSuccess(result.data.payload));

      yield open(
        'success',
        '',
        result.data.payload.status === requestActionsTypes.APPROVE
          ? translate('requests:approveRequestContent')
          : translate('requests:declineRequestContent'),
      );

      yield history.push(ROUTES.REQUESTS);
    }
  } catch (error) {
    yield put(RequestActions.approveRequestError(error.message));
  }
  yield put(LoaderActions.hideLoader());
}

export function* rootSaga() {
  yield takeLatest(
    [Types.REQUESTS_LIST_REQUEST, Types.APPROVE_REQUEST_SUCCESS],
    listRequestsSaga,
  );
  yield takeLatest(Types.APPROVE_REQUEST_REQUEST, approveRequestSaga);
}

export default rootSaga;
