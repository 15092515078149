import styled from 'styled-components';

const badgeColors = {
  success: {
    background: '#EBF6ED',
    text: '#05751F',
  },
  failed: {
    background: '#FFEFEF',
    text: '#D13333',
  },
  waiting: {
    background: '#FEF7E8',
    text: '#97670F',
  },
  primary: {
    background: '#F3F0FE',
    text: '#5D3EBC',
  },
};

export const BadgeContainer = styled.div`
  box-sizing: border-box;
  background-color: ${({ $type }) => badgeColors[$type].background};
  color: ${({ $type }) => badgeColors[$type].text};
  fill: ${({ $type }) => badgeColors[$type].text};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  gap: 4px;
  height: 30px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  border-radius: 6px;
  overflow: hidden;
  white-space: nowrap;
  user-select: none;
  cursor: default;
  svg {
    fill: ${({ $type }) => badgeColors[$type].text};
  }
`;
export const BadgeIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  svg {
    width: 16px;
    height: 16px;
  }
`;
