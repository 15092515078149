import { useSelector, useDispatch } from 'react-redux';

import { open } from 'utils/notification';
import { financialInfoTypesValues } from 'constants/financials';
import * as VendorActions from 'containers/Stores/Vendor/actions';

import { createRequestBody } from './utils/createRequestBody';
import * as FinancialInformationActions from './actions';

export const useFinancialsOnSubmit = ({
  t,
  selectedFinancialInfoType,
  setIsVendorConfirmModalVisible,
  setIsFieldsEditable,
  isIndividual,
  financialDetail,
}) => {
  const vendorInfo = useSelector(state => state.mainHeader.vendorInfo);
  const dispatch = useDispatch();

  return formData => {
    if (!vendorInfo.id) {
      open(
        'warn',
        t('notif.warnTitle'),
        t('financialInformation:noVendorInfo'),
      );

      return;
    }

    if (
      financialDetail &&
      financialDetail.sharedReferences &&
      selectedFinancialInfoType !== financialInfoTypesValues.NEW
    ) {
      setIsVendorConfirmModalVisible(true);

      return;
    }

    const data = createRequestBody(
      formData,
      isIndividual,
      vendorInfo,
      selectedFinancialInfoType,
    );

    dispatch(VendorActions.updateFinancialInfoRequest(data));
    dispatch(FinancialInformationActions.getReferenceFirmSuccess(undefined));
    setIsFieldsEditable(false);
  };
};
