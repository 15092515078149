import { put, takeLatest, call } from 'redux-saga/effects';

import {
  changePasswordApi,
  logoutApi,
  changeUserRoleApi,
  getRolesByUserIdApi,
} from 'services/Header/api';
import * as Types from 'components/Header/types';
import * as LoaderActions from 'components/Loader/actions';
import * as HeaderActions from 'components/Header/actions';
import { open } from 'utils/notification';
import translate from 'utils/translate';
import { logout } from 'utils/auth';
import * as LoginActions from 'containers/Auth/Login/actions';
import { loginSteps } from 'containers/Auth/Login/constants';
import { createStandardSaga } from 'utils/createStandardSaga';

function* changePasswordSaga({ payload }) {
  yield put(LoaderActions.showLoader());
  try {
    const { newPassword, oldPassword } = payload;
    const reqBody = { newPassword, oldPassword };
    const result = yield call(changePasswordApi, reqBody);
    if (result.data.status === 200) {
      yield put(HeaderActions.changePasswordSuccess(result.data.payload));
      open(
        'success',
        translate('common:notif.successTitle'),
        result.data.payload,
      );
    } else {
      open('warning', translate('common:notif.warnTitle'), result.data.payload);
    }
  } catch (error) {
    yield put(HeaderActions.changePasswordError(error.message));
  }
  yield put(LoaderActions.hideLoader());
}

function* logoutSaga({ payload }) {
  yield put(LoaderActions.showLoader());
  try {
    const result = yield call(logoutApi, {
      token: payload,
    });
    if (result.data) {
      yield put(HeaderActions.logoutSuccess(result.data.payload));
      yield put(LoginActions.updateStep(loginSteps.Login));
      logout();
      open('success', '', translate('header:loggedOut'));
    } else {
      open('warning', translate('common:notif.warnTitle'), result.message);
    }
  } catch (error) {
    yield put(HeaderActions.logoutError(error.message));
  }
  yield put(LoaderActions.hideLoader());
}

const changeUserRoleSaga = createStandardSaga({
  callApi: changeUserRoleApi,
  successAction: HeaderActions.changeUserRoleSuccess,
  errorAction: HeaderActions.changeUserRoleError,
  isReloadThePage: true,
});

const getRolesByUserIdSaga = createStandardSaga({
  callApi: getRolesByUserIdApi,
  successAction: HeaderActions.getRolesByUserIdSuccess,
  errorAction: HeaderActions.getRolesByUserIdError,
});

export function* rootSaga() {
  yield takeLatest(Types.CHANGE_PASSWORD_REQUEST, changePasswordSaga);
  yield takeLatest(Types.LOGOUT_REQUEST, logoutSaga);
  yield takeLatest(Types.CHANGE_USER_ROLE_REQUEST, changeUserRoleSaga);
  yield takeLatest(Types.GET_ROLES_BY_USER_ID_REQUEST, getRolesByUserIdSaga);
}

export default rootSaga;
