import React, { useState, useRef } from 'react';
import { Space, Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'components/v1/Button';
import ConfirmModal from 'components/ConfirmModal';

import EditUserModal from '../EditUserModal';
import * as UserActions from '../actions';

import * as S from './style';

const List = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userList = useSelector(state => state.users.userList);
  const [isDeleteUserModalVisible, setIsDeleteUserModalVisible] = useState(
    false,
  );

  const [isEditUserModalVisible, setIsEditUserModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const deleteUserRef = useRef();
  const editUserRef = useRef();

  const handleEditUserSave = () => {
    setIsEditUserModalVisible(false);
  };

  const handleEditUserClose = () => {
    setIsEditUserModalVisible(false);
  };

  const handleEditButton = record => {
    setSelectedUser(record);
    setIsEditUserModalVisible(true);
  };

  const handleDeleteUserModalSave = () => {
    dispatch(UserActions.deleteUserRequest(selectedUser.userId));
    setIsDeleteUserModalVisible(false);
  };

  const handleDeleteUserModalClose = () => {
    setIsDeleteUserModalVisible(false);
  };

  // const handleIvrChange = value => {
  //   const { id, email, fullName, ivrOpen, mobileNumber, role } = value;

  //   const formData = {
  //     id,
  //     isIvrOpen: !ivrOpen,
  //     mobileNumber: String(mobileNumber),
  //     role: role.value,
  //     email,
  //     fullName,
  //   };

  //   dispatch(UserActions.updateUserRequest(formData));
  // };

  const columns = [
    {
      title: 'Adı',
      dataIndex: 'username',
      key: 'username',
      align: 'center',
    },
    {
      title: t('users:email'),
      dataIndex: 'email',
      key: 'email',
      align: 'center',
    },
    {
      title: t('users:phone'),
      dataIndex: 'mobileNumber',
      key: 'mobileNumber',
      align: 'center',
    },
    {
      title: t('users:role'),
      dataIndex: 'roles',
      key: 'roles',
      align: 'center',
      render: row => row.map(item => item.roleName).join(', '),
    },
    // {
    //   title: t('ivr'),
    //   dataIndex: 'ivrOpen',
    //   key: 'ivrOpen',
    //   render: (text, record) => (
    //     <CS.StyledToggle
    //       checked={text}
    //       checkedChildren={t('on')}
    //       uncheckedChildren={t('off')}
    //       onClick={() => handleIvrChange(record)}
    //     />
    //   ),
    //   align: 'center',
    // },
    {
      title: t('actions'),
      key: 'settings',
      render: (_, record) => (
        <Space size="middle">
          <Button kind="secondary" onClick={() => handleEditButton(record)}>
            {t('edit')}
          </Button>
          <Button
            kind="secondary"
            style={{
              backgroundColor: 'transparent',
              padding: 0,
              dispaly: 'flex',
            }}
            onClick={() => {
              setIsDeleteUserModalVisible(true);
              setSelectedUser(record);
            }}
          >
            <DeleteOutlined />
          </Button>
        </Space>
      ),
      align: 'right',
      width: '20%',
    },
  ];

  return (
    <>
      <S.StyledTable
        dataSource={userList && userList}
        columns={columns}
        pagination={{ size: 'small' }}
        scroll={{ x: 'max-content' }}
        bordered
        locale={{ emptyText: <Empty description={t('table.noData')} /> }}
        rowKey="id"
      />
      <EditUserModal
        size="800px"
        visible={isEditUserModalVisible}
        onSave={handleEditUserSave}
        onClose={handleEditUserClose}
        user={selectedUser}
        modalRef={editUserRef}
      />
      <ConfirmModal
        modalRef={deleteUserRef}
        size="400px"
        visible={isDeleteUserModalVisible}
        onSave={handleDeleteUserModalSave}
        onClose={handleDeleteUserModalClose}
        title={t('users:deleteUserTitle')}
        content={t('users:deleteUserText')}
        cancelText={t('cancel')}
      />
    </>
  );
};

export default List;
