const TRANSACTION_TYPE_TRANSLATE = Object.freeze({
  PAYMENT: 'financialTransactions:detailPage.payment',
  REFUND: 'financialTransactions:detailPage.refund',
  PARTIAL_REFUND: 'financialTransactions:detailPage.partialRefund',
  CANCEL: 'financialTransactions:detailPage.cancel',
  MANUEL_DEBIT: 'financialTransactions:detailPage.manuelDebit',
  MANUEL_RECEIVABLE: 'financialTransactions:detailPage.manuelReceivable',
  ALL: 'financialTransactions:detailPage.all',
});

const TRANSACTION_TYPE_REVERSE = Object.freeze({
  0: 'PAYMENT',
  1: 'REFUND',
  2: 'PARTIAL_REFUND',
  3: 'CANCEL',
  4: 'MANUEL_DEBIT',
  5: 'MANUEL_RECEIVABLE',
  10: 'ALL',
});

const TRANSACTION_TYPES = Object.freeze({
  ALL: 10,
  PAYMENT: 0,
  REFUND: 1,
  PARTIAL_REFUND: 2,
  CANCEL: 3,
  MANUEL_DEBIT: 4,
  MANUEL_RECEIVABLE: 5,
});

export {
  TRANSACTION_TYPE_TRANSLATE,
  TRANSACTION_TYPE_REVERSE,
  TRANSACTION_TYPES,
};
