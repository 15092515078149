import { showAsRecomendedProduct } from '../constants';

import { extractDepositTypeFromValue } from './extractDepositOptions';
import { convertToSelectedOptionsRecommended } from './extractRecommended';

const extractProductItemFrom = bulk => ({
  brandId: bulk.brandId,
  categoryId: bulk.categoryId.value ? bulk.categoryId.value : bulk.categoryId,
  descriptionEN: bulk.longDescription_EN,
  descriptionTR: bulk.longDescription_TR,
  displayNameEN: bulk.longProductName_EN,
  displayNameTR: bulk.longProductName_TR,
  firmId: bulk.firmId,
  maxOrderCount: bulk.maxOrderCount ? parseInt(bulk.maxOrderCount, 10) : 0,
  productImageUrl: bulk.productImageUrl,
  productWideImageUrl: bulk.productWideImageUrl,
  productName: bulk.productName,
  productShortNameEN: bulk.shortProductName_EN,
  productShortNameTR: bulk.shortProductName_TR,
  shortDescriptionEN: bulk.shortDescription_EN,
  shortDescriptionTR: bulk.shortDescription_TR,
  depositCarboyBadgeName: bulk.depositCarboy || null,
  depositCarboyBadgeType: extractDepositTypeFromValue(bulk.depositCarboy),
  isRecommended: convertToSelectedOptionsRecommended(
    showAsRecomendedProduct,
    bulk.isRecommended,
  ),
});

export { extractProductItemFrom };
