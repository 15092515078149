import React from 'react';

export default function Underline() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="inherit"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 20.6177V22.6177H5V20.6177H19ZM16 13.8327C15.9671 14.4927 15.7711 15.1343 15.4297 15.7C15.0883 16.2658 14.6121 16.7382 14.0435 17.075C13.475 17.4117 12.8318 17.6024 12.1716 17.6299C11.5114 17.6575 10.8546 17.521 10.26 17.2327C9.57464 16.9362 8.99341 16.4419 8.59077 15.813C8.18813 15.1841 7.98242 14.4493 8 13.7027V5.62274H6V13.8327C6.03383 14.7741 6.28885 15.6944 6.74442 16.5189C7.19998 17.3434 7.84329 18.0491 8.62227 18.5788C9.40125 19.1085 10.294 19.4474 11.2283 19.5679C12.1625 19.6885 13.1121 19.5874 14 19.2727C15.1811 18.8791 16.2059 18.1189 16.9252 17.1028C17.6446 16.0866 18.0211 14.8676 18 13.6227V5.62274H16V13.8327ZM16 5.61774H18H16ZM8 5.61774H6H8Z"
        fill="inherit"
      />
    </svg>
  );
}
