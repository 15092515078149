// upload geomap get request
export const UPLOAD_GEOMAP_GET_REQUEST = 'UPLOAD_GEOMAP_GET_REQUEST';
export const UPLOAD_GEOMAP_GET_SUCCESS = 'UPLOAD_GEOMAP_GET_SUCCESS';
export const UPLOAD_GEOMAP_GET_ERROR = 'UPLOAD_GEOMAP_GET_ERROR';

// upload geomap get neighbourhood request
export const UPLOAD_GEOMAP_GET_NEIGHBOURHOOD_REQUEST =
  'UPLOAD_GEOMAP_GET_NEIGHBOURHOOD_REQUEST';
export const UPLOAD_GEOMAP_GET_NEIGHBOURHOOD_SUCCESS =
  'UPLOAD_GEOMAP_GET_NEIGHBOURHOOD_SUCCESS';
export const UPLOAD_GEOMAP_GET_NEIGHBOURHOOD_ERROR =
  'UPLOAD_GEOMAP_GET_NEIGHBOURHOOD_ERROR';

// upload geomap get district request
export const UPLOAD_GEOMAP_GET_DISTRICT_REQUEST =
  'UPLOAD_GEOMAP_GET_DISTRICT_REQUEST';
export const UPLOAD_GEOMAP_GET_DISTRICT_SUCCESS =
  'UPLOAD_GEOMAP_GET_DISTRICT_SUCCESS';
export const UPLOAD_GEOMAP_GET_DISTRICT_ERROR =
  'UPLOAD_GEOMAP_GET_DISTRICT_ERROR';

// Upload geomap get vendors request
export const UPLOAD_GEOMAP_GET_VENDORS_REQUEST =
  'UPLOAD_GEOMAP_GET_VENDORS_REQUEST';
export const UPLOAD_GEOMAP_GET_VENDORS_SUCCESS =
  'UPLOAD_GEOMAP_GET_VENDORS_SUCCESS';
export const UPLOAD_GEOMAP_GET_VENDORS_ERROR =
  'UPLOAD_GEOMAP_GET_VENDORS_ERROR';

// upload geomap set neighbourhood is visible
export const SET_NEIGHBOURHOOD_IS_VISIBLE = 'SET_NEIGHBOURHOOD_IS_VISIBLE';

// upload geomap set selected district
export const UPLOAD_GEOMAP_SET_SELECTED_DISTRICT =
  'UPLOAD_GEOMAP_SET_SELECTED_DISTRICT';
// upload geomap set Edited map
export const UPLOAD_GEOMAP_SET_EDITED_MAP = 'UPLOAD_GEOMAP_SET_EDITED_MAP';

// upload geomap set show only selected vendor
export const UPLOAD_GEOMAP_SET_SHOW_ONLY_SELECTED_VENDOR =
  'UPLOAD_GEOMAP_SET_SHOW_ONLY_SELECTED_VENDOR';

// upload geomap set selected vendor
export const UPLOAD_GEOMAP_SET_SELECTED_VENDOR =
  'UPLOAD_GEOMAP_SET_SELECTED_VENDOR';

// upload geomap set center
export const UPLOAD_GEOMAP_SET_CITY = 'UPLOAD_GEOMAP_SET_CITY';
export const UPLOAD_GEOMAP_SET_BRAND = 'UPLOAD_GEOMAP_SET_BRAND';
export const UPLOAD_GEOMAP_SET_INTERVAL = 'UPLOAD_GEOMAP_SET_INTERVAL';
export const UPLOAD_GEOMAP_SET_CENTER = 'UPLOAD_GEOMAP_SET_CENTER';

// upload geomap save request
export const UPLOAD_GEOMAP_SAVE_REQUEST = 'UPLOAD_GEOMAP_SAVE_REQUEST';
export const UPLOAD_GEOMAP_SAVE_SUCCESS = 'UPLOAD_GEOMAP_SAVE_SUCCESS';
export const UPLOAD_GEOMAP_SAVE_ERROR = 'UPLOAD_GEOMAP_SAVE_ERROR';

export const UPLOAD_GEOMAP_RESET_STATE = 'UPLOAD_GEOMAP_RESET_STATE';
