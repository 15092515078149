const extractFirmOptionsFrom = bulk => {
  if (bulk) {
    return bulk.map(item => ({
      label: item.firmName,
      value: item.id,
    }));
  }

  return [];
};
export { extractFirmOptionsFrom };
