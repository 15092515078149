export const BOTTLE_RETURN_WITH_SAME_BRAND = 'BOTTLE_RETURN_WITH_SAME_BRAND';
export const BOTTLE_RETURN_WITH_DIFFERENT_BRAND =
  'BOTTLE_RETURN_WITH_DIFFERENT_BRAND';
export const WITHOUT_BOTTLE_RETURN = 'WITHOUT_BOTTLE_RETURN';
export const WITH_BOTTLE_RETURN = 'WITH_BOTTLE_RETURN';

export const DEPOSIT_TYPE = Object.freeze({
  BOTTLE_RETURN_WITH_SAME_BRAND: 1,
  BOTTLE_RETURN_WITH_DIFFERENT_BRAND: 2,
  WITHOUT_BOTTLE_RETURN: 3,
  WITH_BOTTLE_RETURN: 5,
});

export const DEPOSIT_COLOR = Object.freeze({
  GREEEN: 1,
  BLUE: 2,
  GRAY: 3,
});

export const DEPOSIT_OPTIONS = Object.freeze([
  {
    type: 1,
    value: 1,
    key: BOTTLE_RETURN_WITH_SAME_BRAND,
    label: 'catalogs:bottleReturnWithSameBrand',
    color: DEPOSIT_COLOR.GREEEN,
  },
  {
    type: 2,
    value: 2,
    key: BOTTLE_RETURN_WITH_DIFFERENT_BRAND,
    label: 'catalogs:bottleReturnWithDifferentBrand',
    color: DEPOSIT_COLOR.BLUE,
  },
  {
    type: 3,
    value: 3,
    key: WITHOUT_BOTTLE_RETURN,
    label: 'catalogs:withoutBottleReturn',
    color: DEPOSIT_COLOR.GRAY,
  },
  {
    type: 1,
    value: 5,
    key: WITH_BOTTLE_RETURN,
    label: 'catalogs:withBottleReturn',
    color: DEPOSIT_COLOR.GREEEN,
  },
]);

export const showAsRecomendedProduct = Object.freeze({
  true: {
    value: true,
    label: 'catalogs:show',
  },
  false: {
    value: false,
    label: 'catalogs:hide',
  },
});
