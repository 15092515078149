import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Card, Empty, Descriptions } from 'antd';

import useGeoMapTranslation from '../../useGeoMapTranslation';

const VendorInfo = ({ geomapStateKey }) => {
  const selectedVendor = useSelector(
    state => state[geomapStateKey].selectedVendor,
  );
  const { t } = useGeoMapTranslation();

  const vendors = useSelector(state => state[geomapStateKey].vendors);

  const selectedVendorInfo = useMemo(() => {
    if (!selectedVendor || !vendors) return null;
    const vendorInfo = vendors.find(v => v.id === selectedVendor);

    return vendorInfo;
  }, [selectedVendor, vendors]);

  return (
    <Card
      title={t('vendorInfo.vendorInfo')}
      bordered={false}
      style={{ width: '100%' }}
    >
      {!selectedVendor || !selectedVendorInfo ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <Descriptions bordered>
          <Descriptions.Item span={3} label={t('vendorInfo.vendorId')}>
            {selectedVendorInfo.id}
          </Descriptions.Item>
          <Descriptions.Item span={3} label={t('vendorInfo.vendorName')}>
            {selectedVendorInfo.vendorName}
          </Descriptions.Item>
          <Descriptions.Item span={3} label={t('vendorInfo.vendorPhoneNumber')}>
            {selectedVendorInfo.vendorPhoneNumber}
          </Descriptions.Item>
          <Descriptions.Item
            span={3}
            label={t('vendorInfo.vendorManagerEmail')}
          >
            {selectedVendorInfo.vendorManagerEmail}
          </Descriptions.Item>
          <Descriptions.Item label={t('vendorInfo.IsVendorOpen')}>
            {selectedVendorInfo.isOpen
              ? t('vendorInfo.vendorOpen')
              : t('vendorInfo.vendorClose')}
          </Descriptions.Item>
        </Descriptions>
      )}
    </Card>
  );
};

VendorInfo.propTypes = {
  geomapStateKey: PropTypes.string.isRequired,
};

export default VendorInfo;
