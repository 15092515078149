import { deleteRequest, getRequest, postRequest, putRequest } from 'lib/axios';

import api from './apiRoutes';

export const listCategorysApi = args => getRequest(api.listCategorys, args);
export const addCategoryApi = args => postRequest(api.addCategory, args);
export const updateCategoryApi = args => putRequest(api.updateCategory, args);
export const deleteCategoryApi = args =>
  deleteRequest(api.deleteCategory(args));
export const getCategoryApi = args => getRequest(api.getCategory(args));
