import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Divider, utilities } from '@getir/web-components';
import { useDispatch, useSelector } from 'react-redux';

import InputWrapper from 'components/InputWrapper';
import SelectBoxWrapper from 'components/Antd/FormElements/SelectBox';

import * as UsersActions from '../../actions';

import { validations } from './validations';
import * as S from './style';

const { noop } = utilities;

const EditUserModal = ({ onClose, user, ...props }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const validationRules = validations(t);
  const { data: roleList } = useSelector(state => state.authRoles.roleList);
  const { data: userRoleList } = useSelector(
    state => state.authUsers.userRoleList,
  );

  useEffect(() => {
    if (user) {
      const { userId } = user;
      dispatch(UsersActions.getRolesByUserIdRequest(userId));
    }
  }, [user, dispatch]);

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    if (user) {
      const { fullName, email } = user;
      setValue('fullName', fullName, { shouldValidate: true });
      setValue('email', email, { shouldValidate: true });
      setValue(
        'roles',
        userRoleList?.roles.map(role => role.roleId),
        { shouldValidate: true },
      );
    }
  }, [user, userRoleList, setValue]);

  const onSubmit = formData => {
    const { email, fullName, roles } = formData;
    const { userId } = user;
    const data = {
      id: userId,
      email,
      fullName,
      roleIds: roles,
    };
    dispatch(UsersActions.updateUserRequest(data));
    onClose();
  };

  const handleOnClose = () => {
    onClose();
  };

  return (
    <S.StyledModal name="EDIT_USER" onCancel={handleOnClose} {...props}>
      <Modal.Header>{t('users:edit')}</Modal.Header>
      <Modal.Body>
        <S.FormBody>
          <S.EditUserModalForm onSubmit={handleSubmit(onSubmit)}>
            <InputWrapper
              fieldName="fullName"
              control={control}
              rules={validationRules.fullName}
              placeholder={t(`authUsersPage:editUserModal.name`)}
              header={t(`authUsersPage:editUserModal.name`)}
              errors={errors}
            />
            <InputWrapper
              fieldName="email"
              control={control}
              rules={validationRules.email}
              placeholder={t(`authUsersPage:editUserModal.email`)}
              header={t(`authUsersPage:editUserModal.email`)}
              errors={errors}
              isDisabled
            />
            <SelectBoxWrapper
              fieldName="roles"
              control={control}
              rules={validationRules.role}
              placeholder={t(`authUsersPage:editUserModal.role`)}
              header={t(`authUsersPage:editUserModal.role`)}
              errors={errors}
              options={
                roleList &&
                roleList.map(role => ({
                  value: role.roleId,
                  label: role.roleName,
                }))
              }
              mode="multiple"
            />
            <Divider />
            <S.FormFooter>
              <Button type="submit">{t('save')}</Button>
            </S.FormFooter>
          </S.EditUserModalForm>
        </S.FormBody>
      </Modal.Body>
    </S.StyledModal>
  );
};

EditUserModal.propTypes = {
  onClose: PropTypes.func,
  user: PropTypes.object,
};

EditUserModal.defaultProps = {
  onClose: noop,
  user: undefined,
};

export default EditUserModal;
