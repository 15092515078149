import styled from 'styled-components';
import { Input } from '@getir/web-components';

import { PageHeader, PageTitle } from 'common/style';

const InputField = styled(Input)`
  border: none;
`;

export { PageHeader, PageTitle, InputField };
