import { useMemo } from 'react';

import mobileCheck from './mobileAndTabletCheck';

const useMobileCheck = () => {
  const isMobile = useMemo(() => mobileCheck(), []);

  return { isMobile };
};

export default useMobileCheck;
