import * as Types from './types';

const initialState = {
  addTransaction: {
    data: undefined,
  },
  savePremiumTransaction: {
    data: undefined,
    error: undefined,
  },
  error: {},
};

function AddFinancialTransactionsReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.ADD_TRANSACTION_SUCCESS:
      return {
        ...state,
        addTransaction: {
          data: payload,
        },
      };
    case Types.ADD_TRANSACTION_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.SAVE_PREMIUM_TRANSACTION_SUCCESS:
      return {
        ...state,
        savePremiumTransaction: {
          data: payload,
        },
      };
    case Types.SAVE_PREMIUM_TRANSACTION_ERROR:
      return {
        ...state,
        savePremiumTransaction: {
          error: payload,
        },
      };
    default:
      return state;
  }
}

export default AddFinancialTransactionsReducer;
