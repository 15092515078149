import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  border-radius: 8px;
  background: #fff;
  margin-bottom: 32px;
  box-shadow: 0px 2px 4px -1px rgba(28, 55, 90, 0.08),
    0px 3px 12px -1px rgba(28, 52, 84, 0.13);
`;

export const TotalAmountSection = styled.div`
  box-sizing: border-box;
  display: flex;
  padding: 16px 22px;
  align-items: center;
  gap: 10px;
  width: 100%;
  border-radius: 8px;
  background: #f6f6f6;
  position: relative;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  &::before {
    content: '';
    width: 2px;
    height: 30px;
    background: #5d3ebc;
    border-radius: 5px;
    position: absolute;
    left: 10px;
    top: 10px;
  }
`;

export const StyledCell = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  width: 100%;

  button {
    padding: 8px;
    width: 36px;
    height: 36px;
  }
`;
