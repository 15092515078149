const checkAndFilter = (products, key, value) =>
  value
    ? products.filter(item => item[key] && item[key].id === value)
    : products;

export const filterProducts = (products, { brand, category }, searchText) => {
  let tmpProducts = [...products];
  tmpProducts = checkAndFilter(tmpProducts, 'brand', brand);
  tmpProducts = checkAndFilter(tmpProducts, 'category', category);

  if (searchText && searchText.length)
    tmpProducts = tmpProducts.filter(
      item =>
        item.productName.toLowerCase().indexOf(searchText.toLowerCase()) > -1,
    );

  return tmpProducts;
};
