import {
  put,
  takeLatest,
  call,
  select,
  delay,
  take,
  race,
  fork,
} from 'redux-saga/effects';

import { open } from 'utils/notification';
import translate from 'utils/translate';
import * as LoaderActions from 'components/Loader/actions';
import * as WorkingHoursTypes from 'containers/WorkingHours/types';
import * as VendorTypes from 'containers/Stores/Vendor/types';
import {
  getCitiesApi,
  getVendorsApi,
  updateVendorStatusApi,
  getWorkingHoursInfoApi,
  getVendorInfoApi,
  getVendorOpenStatusApi,
} from 'services/MainHeader/api';
import { CHANGE_ORDER_AFFECT_VENDOR } from 'containers/ActiveOrders/types';

import { vendorInfo } from './selectors';
import * as Types from './types';
import * as MainHeaderActions from './actions';

function* getCitiesSaga() {
  yield put(LoaderActions.showLoader());
  try {
    const result = yield call(getCitiesApi);
    if (result.data) {
      yield put(MainHeaderActions.getCitiesSuccess(result.data.payload));
    }
  } catch (error) {
    yield put(MainHeaderActions.getCitiesError(error.message));
  }
  yield put(LoaderActions.hideLoader());
}

function* getVendorsSaga({ payload }) {
  yield put(LoaderActions.showLoader());
  try {
    const result = yield call(getVendorsApi, payload);
    if (result.data) {
      yield put(MainHeaderActions.getVendorsSuccess(result.data.payload));
    }
  } catch (error) {
    yield put(MainHeaderActions.getVendorsError(error.message));
  }
  yield put(LoaderActions.hideLoader());
}

function* updateVendorStatusSaga({ payload }) {
  yield put(LoaderActions.showLoader());
  try {
    const result = yield call(updateVendorStatusApi, payload);
    if (result.data) {
      const selectedVendor = yield select(vendorInfo);
      yield put(MainHeaderActions.updateVendorStatusSuccess(selectedVendor.id));
      open(
        'success',
        '',
        translate(
          payload.isOpen ? 'header:vendorOpened' : 'header:vendorClosed',
        ),
      );
    } else {
      open('warning', translate('common:notif.warnTitle'), result.message);
    }
  } catch (error) {
    yield put(MainHeaderActions.updateVendorStatusError(error.message));
  }
  yield put(LoaderActions.hideLoader());
}

function* getWorkingHoursInfoSaga({ payload }) {
  yield put(LoaderActions.showLoader());
  try {
    const result = yield call(getWorkingHoursInfoApi, payload);
    if (result.data) {
      yield put(
        MainHeaderActions.getWorkingHoursInfoSuccess(result.data.payload),
      );
    }
  } catch (error) {
    yield put(MainHeaderActions.getWorkingHoursInfoError(error.message));
  }
  yield put(LoaderActions.hideLoader());
}

function* getVendorInfoSaga({ payload }) {
  yield put(LoaderActions.showLoader());
  try {
    const result = yield call(getVendorInfoApi, payload);
    if (result.data) {
      const tempResult = result.data.payload.workingHours.map(item => {
        if (item.startTime === '') {
          // eslint-disable-next-line
          item.startTime = null;
        }
        if (item.endTime === '') {
          // eslint-disable-next-line
          item.endTime = null;
        }

        return item;
      });

      result.data.payload.workingHours = tempResult;
      yield put(MainHeaderActions.getVendorInfoSuccess(result.data.payload));
      yield put(MainHeaderActions.openStatusPollStart());
    }
  } catch (error) {
    yield put(MainHeaderActions.getVendorInfoError(error.message));
  }
  yield put(LoaderActions.hideLoader());
}

function* pollVendorInfoSaga() {
  while (true) {
    try {
      yield delay(60000);
      const selectedVendor = yield select(state => state.mainHeader.vendorInfo);
      if (selectedVendor.id) {
        const result = yield call(getVendorOpenStatusApi, selectedVendor.id);
        yield put(
          MainHeaderActions.setVendorPollIsOpenSuccess(result.data.payload),
        );
      }
    } catch (error) {
      yield put(MainHeaderActions.setVendorPollIsOpenError(error.message));
    }
  }
}

function* watchPollSaga() {
  while (true) {
    yield take(Types.ISOPEN_POLL_START);
    yield race([call(pollVendorInfoSaga), take(Types.ISOPEN_POLL_STOP)]);
  }
}

export function* rootSaga() {
  yield takeLatest(Types.GET_CITIES_REQUEST, getCitiesSaga);
  yield takeLatest(
    [
      Types.GET_VENDORS_REQUEST,
      VendorTypes.ADD_VENDOR_SUCCESS,
      VendorTypes.DELETE_VENDOR_SUCCESS,
    ],
    getVendorsSaga,
  );
  yield takeLatest(Types.UPDATE_VENDOR_STATUS_REQUEST, updateVendorStatusSaga);
  yield takeLatest(
    Types.GET_WORKING_HOURS_INFO_REQUEST,
    getWorkingHoursInfoSaga,
  );
  yield takeLatest(
    [
      Types.GET_VENDOR_INFO_REQUEST,
      WorkingHoursTypes.UPDATE_WORKING_HOURS_SUCCESS,
      Types.UPDATE_VENDOR_STATUS_SUCCESS,
      VendorTypes.CLOSE_FOR_SALE_SUCCESS,
      VendorTypes.OPEN_FOR_SALE_SUCCESS,
      VendorTypes.EDIT_FINANCIAL_INFO_SUCCESS,
      VendorTypes.EDIT_VENDOR_SUCCESS,
      CHANGE_ORDER_AFFECT_VENDOR,
    ],
    getVendorInfoSaga,
  );
  yield fork(watchPollSaga);

  // yield watchPollSaga();
}

export default rootSaga;
