import { takeLatest, put, call } from 'redux-saga/effects';

import {
  getPermListRootsApi,
  getPermListChildrenApi,
  getRoleListApi,
  updatePermApi,
} from 'services/UsersPerms/Perms/api';
import { createStandardSaga } from 'utils/createStandardSaga';
import * as LoaderActions from 'components/Loader/actions';
import { open } from 'utils/notification';
import translate from 'utils/translate';

import * as Types from './types';
import * as PermsActions from './actions';

const getPermListRootsSaga = createStandardSaga({
  callApi: getPermListRootsApi,
  successAction: PermsActions.getPermListSuccess,
  errorAction: PermsActions.getPermListSuccess,
});

const getPermListChildrenSaga = createStandardSaga({
  callApi: getPermListChildrenApi,
  successAction: PermsActions.getPermListChildrenSuccess,
  errorAction: PermsActions.getPermListChildrenError,
});

const getRoleListSaga = createStandardSaga({
  callApi: getRoleListApi,
  successAction: PermsActions.getRoleListSuccess,
  errorAction: PermsActions.getRoleListError,
});

function* updatePermSaga({ payload }) {
  yield put(LoaderActions.showLoader());
  try {
    const result = yield call(updatePermApi, payload);
    if (result.data) {
      yield put(PermsActions.updatePermSuccess(payload));
      open(
        'success',
        '',
        translate('authPermsPage:editPermModal.successPermMessage'),
      );
      window.location.reload();
    } else {
      open('warning', translate('common:notif.warnTitle'), result.message);
    }
  } catch (error) {
    yield put(PermsActions.updatePermError(error.message));
  }
  yield put(LoaderActions.hideLoader());
}

export function* rootSaga() {
  yield takeLatest(Types.GET_PERM_LIST_REQUEST, getPermListRootsSaga);
  yield takeLatest(
    Types.GET_PERM_LIST_CHILDREN_REQUEST,
    getPermListChildrenSaga,
  );
  yield takeLatest(Types.GET_ROLE_LIST_REQUEST, getRoleListSaga);
  yield takeLatest(Types.UPDATE_PERM_REQUEST, updatePermSaga);
}

export default rootSaga;
