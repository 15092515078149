import styled from 'styled-components';
import { Menu } from 'antd';

import COLORS from 'constants/colors';
import breakpoint from 'constants/breakpoint';

const { SubMenu } = Menu;

const MenuWrapper = styled.div`
  background: ${COLORS.mainGetirColor};
  color: #fff;
  grid-area: menu;
  border-top: 1px solid ${COLORS.menuHoverColor};
  height: 100%;
  position: relative;
  transition: none !important;
  .ant-menu-inline-collapsed {
    @media (max-width: ${breakpoint.MOBILE.MAX}px) {
      width: 0px !important;
    }
  }
  li {
    transition: none !important;
  }

  .ant-menu,
  .ant-menu-item,
  .ant-menu-item-icon,
  .ant-menu-title-content,
  .ant-menu-item::after,
  .ant-menu-item::before {
    transition: none !important;
  }
  .ant-menu .ant-menu-submenu-title {
    transition: none !important;
  }

  .ant-menu-submenu-selected > .ant-menu-submenu-title {
    color: ${COLORS.mainGetirYellowColor} !important;
  }
  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border-right: none !important;
  }

  > ul {
    height: 100% !important;
    @media (max-width: ${breakpoint.MOBILE.MAX}px) {
      height: auto !important;
      min-height: 100%;
    }
    > li {
      &:last-child {
        position: absolute !important;
        display: flex;
        align-items: center;
        bottom: 0px;
        height: 60px;
        background-color: ${COLORS.menuHoverColor};
        margin-bottom: 0px;
      }
    }

    > li.ant-menu-item-selected {
      &:before {
        position: absolute;
        top: 0px;
        left: 0px;
        bottom: 0px;
        content: '';
        border-left: 5px solid ${COLORS.mainGetirYellowColor};
        border-radius: 0px 3px 3px 0px;
      }
    }
  }
`;

const MenuAntD = styled(Menu)`
  background: ${COLORS.mainGetirColor} !important;
  color: #fff !important;

  ul {
    background: ${COLORS.mainGetirColor} !important;
    color: #fff !important;
  }

  .ant-menu-item {
    margin: 0px !important;
    border-radius: 0px !important;
    margin-top: 4px !important;
    color: #fff !important;
  }
  .ant-menu-submenu-title {
    margin-inline: 0px !important;
    color: #fff !important;
  }

  li,
  .ant-menu-submenu-title {
    width: 100% !important;
    &:hover {
      background: ${COLORS.menuHoverColor} !important;
      color: #fff !important;
    }
  }

  .ant-menu-item-selected {
    background: ${COLORS.mainGetirColor} !important;
    color: ${COLORS.mainGetirYellowColor} !important;
    &:after {
      border-right: none !important;
    }
  }

  .ant-menu-submenu-selected {
    color: ${COLORS.mainGetirYellowColor} !important;
    > div {
      &:before {
        position: absolute;
        top: 0px;
        left: 0px;
        bottom: 0px;
        content: '';
        border-left: 5px solid ${COLORS.mainGetirYellowColor};
        border-radius: 0px 3px 3px 0px;
      }
    }
  }

  .ant-menu-submenu-arrow {
    color: #fff !important;
  }
`;

const MenuItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ActiveOrderCount = styled.div`
  display: inline-block;
  border-radius: 50%;
  min-width: 20px;
  min-height: 20px;
  padding: 5px;
  background: ${COLORS.mainGetirYellowColor};
  color: ${COLORS.mainGetirColor};
  font-weight: 600;
  text-align: center;
  line-height: 1;
  box-sizing: content-box;
  white-space: nowrap;

  &:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    padding-top: 100%;
    height: 0;
  }

  span {
    display: inline-block;
    vertical-align: middle;
  }
`;

const StyledSubMenu = styled(SubMenu)``;
export {
  MenuWrapper,
  MenuAntD,
  StyledSubMenu,
  MenuItemWrapper,
  ActiveOrderCount,
};
