import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { isNull, isUndefined } from 'lodash';
import { Select } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

import ConfirmModal from 'components/ConfirmModal';
import AuthorizedComponent from 'components/AuthorizedComponent';
import * as CS from 'common/style';
import * as UsersActions from 'containers/StoreDetails/Users/actions';
import * as VendorActions from 'containers/Stores/Vendor/actions';
import * as FirmActions from 'containers/Stores/Firm/actions';
import useQuery from 'hooks/useQuery';
import Mapicon from 'common/svg/mapicon.svg';
import Location from 'common/svg/location.svg';

import * as S from './style';
import * as MainHeaderActions from './actions';

const compareVendorsByType = vendors =>
  vendors.sort((firstVendor, secondVendor) => {
    if (firstVendor.isFirm === secondVendor.isFirm) return 0;
    if (firstVendor.isFirm) return -1;

    return 1;
  });

const MainHeader = () => {
  const history = useHistory();
  const { vendorId } = useParams();
  const cityId = useSelector(state => state.mainHeader.cityId);
  const vendor = useSelector(state => state.mainHeader.vendor);
  const cities = useSelector(state => state.mainHeader.cities);
  const vendors = useSelector(state => state.mainHeader.vendors);
  const vendorInfo = useSelector(state => state.mainHeader.vendorInfo);
  const isOpen = useSelector(state => state.mainHeader.isOpen);

  const dispatch = useDispatch();
  const params = useQuery();
  const { t } = useTranslation();
  const [isOpenVendorModalVisible, setIsOpenVendorModalVisible] = useState(
    false,
  );
  const [toggleValue, setToggleValue] = useState(true);

  const toggleModalRef = useRef();

  useEffect(() => {
    if (vendorId) {
      const currentVendor = vendors.find(item => item.value === vendorId);
      dispatch(MainHeaderActions.setVendor(currentVendor));
      dispatch(MainHeaderActions.getVendorInfoRequest(vendorId));
    }
  }, [vendorId, dispatch, vendors]);

  useEffect(() => {
    if (cities.length === 0) {
      dispatch(MainHeaderActions.getCitiesRequest());
      dispatch(MainHeaderActions.getVendorsRequest());
    }
  }, [cities.length, dispatch]);

  useEffect(() => {
    if (vendorInfo.id && toggleValue !== isOpen) {
      setToggleValue(isOpen);
    }
    // eslint-disable-next-line
  }, [vendorInfo, isOpen]);

  useEffect(() => {
    if (vendors && vendors.length === 1) {
      dispatch(MainHeaderActions.setVendor(vendors[0]));
      dispatch(MainHeaderActions.getVendorInfoRequest(vendors[0].value));
    }
  }, [vendors, dispatch]);

  useEffect(() => {
    if (cityId) {
      addQueryParametrs('cityId', cityId);
    }
    if (vendor) {
      addQueryParametrs('vendorId', vendor.value);
    }
    // eslint-disable-next-line
  }, []);

  function addQueryParametrs(name, value) {
    const searchParam = new URLSearchParams(window.location.search);
    searchParam.set(name, value);
    history.replace(`?${searchParam.toString()}`, history.location.state);
  }

  const clearQueryParametrs = value => {
    const searchParam = new URLSearchParams(window.location.search);
    searchParam.delete(value);

    history.replace(`?${searchParam.toString()}`);
  };

  const handleToggleClick = () => {
    if (vendor) {
      setIsOpenVendorModalVisible(true);
    }
  };

  const handleOpenVendorModalSave = () => {
    setIsOpenVendorModalVisible(false);
    dispatch(
      MainHeaderActions.updateVendorStatusRequest(vendor.value, !toggleValue),
    );
  };

  const handleOpenVendorModalClose = () => {
    setIsOpenVendorModalVisible(false);
  };

  const handleChangeCity = (value, isInit) => {
    dispatch(MainHeaderActions.setCityId(value));
    dispatch(
      MainHeaderActions.getVendorsRequest({
        cityId: value,
      }),
    );
    dispatch(MainHeaderActions.setVendor(undefined));
    dispatch(
      MainHeaderActions.getVendorInfoSuccess({
        workingHours: undefined,
        id: undefined,
      }),
    );
    dispatch(MainHeaderActions.getWorkingHoursInfoSuccess(''));
    dispatch(UsersActions.setUserList([]));
    dispatch(VendorActions.vendorListSuccess([]));

    if (value === undefined || value === null) {
      clearQueryParametrs('cityId');

      return;
    }
    addQueryParametrs('cityId', value);

    if (!isInit) {
      clearQueryParametrs('vendorId');
    }
  };

  const handleChangeVendor = selectedVendor => {
    const currentVendor = vendors.find(
      item => item.value === selectedVendor.value,
    );
    dispatch(MainHeaderActions.setVendor(currentVendor));

    if (currentVendor.isFirm) {
      dispatch(
        MainHeaderActions.getVendorInfoSuccess({
          workingHours: undefined,
          id: undefined,
        }),
      );
      dispatch(FirmActions.getFirmInfoRequest(currentVendor.value));
    } else {
      dispatch(MainHeaderActions.getVendorInfoRequest(currentVendor.value));
      dispatch(
        MainHeaderActions.getWorkingHoursInfoRequest(currentVendor.value),
      );
      dispatch(FirmActions.getFirmInfoSuccess({}));
    }
    addQueryParametrs('vendorId', currentVendor.value);
  };

  useEffect(() => {
    const queryCityId = params.get('cityId');
    const queryVendorId = params.get('vendorId');
    const isCityExistAndDefined =
      !isNull(queryCityId) && isUndefined(cityId) && cities.length > 0;

    if (isCityExistAndDefined) {
      if (!cities.some(item => item.value === queryCityId)) {
        clearQueryParametrs('cityId');
      } else {
        handleChangeCity(queryCityId, true);
      }
    }
    if (!isNull(queryVendorId) && isUndefined(vendor)) {
      const currentVendor = vendors.find(item => item.value === queryVendorId);
      if (isUndefined(currentVendor)) return;

      handleChangeVendor(currentVendor);
    }
    // eslint-disable-next-line
  }, [cities]);

  const updateVendorStatus = vendor && !vendor.isFirm;

  return (
    <S.MainHeaderWrapper>
      {updateVendorStatus && (
        <AuthorizedComponent componentId="update_vendor_status">
          <S.MainHeaderToggleWrapper className="vendor-status-switch">
            <CS.StyledToggle
              checked={toggleValue}
              checkedChildren={t('on')}
              uncheckedChildren={t('off')}
              onClick={handleToggleClick}
            />
          </S.MainHeaderToggleWrapper>
        </AuthorizedComponent>
      )}
      <S.MainHeaderArea>
        <S.PrefixIcon>
          <img
            src={Location}
            alt="location"
            title="location"
            width={20}
            height={20}
          />
        </S.PrefixIcon>
        <Select
          placeholder={t('header:chooseCity')}
          onChange={value => handleChangeCity(value, false)}
          showSearch
          options={cities}
          value={cityId}
          filterOption={(input, option) =>
            (option?.label ?? '')
              .toLocaleLowerCase('tr')
              .includes(input.toLocaleLowerCase('tr'))
          }
          allowClear
          getPopupContainer={trigger => trigger.parentNode}
          removeIcon={<CloseCircleOutlined />}
        />
      </S.MainHeaderArea>
      <S.MainHeaderArea>
        <S.PrefixIcon>
          <img src={Mapicon} alt="map" title="map" width={20} height={20} />
        </S.PrefixIcon>
        <Select
          placeholder={t('header:chooseVendor')}
          onChange={handleChangeVendor}
          showSearch
          options={compareVendorsByType(vendors).map(item => ({
            value: item.value,
            label: item.label,
          }))}
          value={vendor}
          filterOption={(input, option) =>
            (option?.label ?? '')
              .toLocaleLowerCase('tr')
              .includes(input.toLocaleLowerCase('tr'))
          }
          labelInValue
          getPopupContainer={trigger => trigger.parentNode}
          removeIcon={<CloseCircleOutlined />}
        />
      </S.MainHeaderArea>

      <ConfirmModal
        modalRef={toggleModalRef}
        size="400px"
        visible={isOpenVendorModalVisible}
        onSave={handleOpenVendorModalSave}
        onClose={handleOpenVendorModalClose}
        title={
          toggleValue
            ? t('header:closeVendorModalTitle')
            : t('header:openVendorModalTitle')
        }
        content={
          toggleValue
            ? t('header:closeVendorModalContent')
            : t('header:openVendorModalContent')
        }
        cancelText={t('cancel')}
      />
    </S.MainHeaderWrapper>
  );
};

export default MainHeader;
