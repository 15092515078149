import * as Types from './types';

const initialState = {
  changePassword: {},
  logout: {},
  error: {},
  changeRole: {
    data: undefined,
    error: undefined,
  },
  roleListByUser: {
    data: undefined,
    error: undefined,
  },
};

function HeaderReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePassword: payload,
      };
    case Types.CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.LOGOUT_SUCCESS:
      return {
        ...state,
        logout: payload,
      };
    case Types.LOGOUT_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.CHANGE_USER_ROLE_SUCCESS:
      return {
        ...state,
        changeRole: {
          data: payload,
        },
      };
    case Types.CHANGE_USER_ROLE_ERROR:
      return {
        ...state,
        changeRole: {
          error: payload,
        },
      };
    case Types.GET_ROLES_BY_USER_ID_SUCCESS:
      return {
        ...state,
        roleListByUser: {
          data: payload.roles,
        },
      };
    case Types.GET_ROLES_BY_USER_ID_ERROR:
      return {
        ...state,
        roleListByUser: {
          error: payload,
        },
      };
    default:
      return state;
  }
}

export default HeaderReducer;
