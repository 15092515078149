import dayjs from 'dayjs';

import { getRequest } from 'lib/axios';
import { apiGateway } from 'constants/services';

const timeEndpoint = `${apiGateway}/ivr-service/get-present-date-time`;

function setMomentOffset(serverTime) {
  const offset = new Date(serverTime).getTime() - Date.now();

  // eslint-disable-next-line
  dayjs.now = function () {
    return dayjs(offset + Date.now());
  };
}

async function getServerTime() {
  getRequest(timeEndpoint).then(response => {
    if (response && response.data) {
      setMomentOffset(response.data);
    } else {
      setMomentOffset(dayjs().unix() * 1000);
    }
  });
}

export { getServerTime };
