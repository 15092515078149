import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const AuthorizedComponent = props => {
  const { children, componentId } = props;
  const [isAuthorized, setIsAuthorized] = useState(false);
  const { data: allPerms } = useSelector(state => state.login.allPerms);

  useEffect(() => {
    if (allPerms) {
      const result = allPerms.some(perm => perm.componentId === componentId);
      setIsAuthorized(result);
    }
  }, [allPerms, componentId]);

  return isAuthorized ? children : '';
};

AuthorizedComponent.defaultProps = {
  children: <div></div>,
  componentId: undefined,
};

AuthorizedComponent.propTypes = {
  children: PropTypes.node,
  componentId: PropTypes.string,
};

export default AuthorizedComponent;
