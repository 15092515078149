import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';

import Input from 'components/v1/Input';

const AdminRefuse = (props, ref) => {
  const { t } = useTranslation('billingUploadModal');
  const [cancelInputValue, setCancelInputValue] = useState('');
  const [cancelInputError, setCancelInputError] = useState(undefined);

  useImperativeHandle(
    ref,
    () => ({
      value: cancelInputValue,
      error: cancelInputError,
      setError: setCancelInputError,
    }),
    [cancelInputError, cancelInputValue],
  );

  return (
    <Input
      label={t('reasonOfRejection')}
      size="large"
      infoText={t('inputInfo')}
      error={cancelInputError}
      value={cancelInputValue}
      onChange={e => {
        if (!e.target.value.length || e.target.value.length < 3) {
          setCancelInputError(t('billingInputError.minLengtThree'));
        } else if (e.target.value.length > 50) {
          setCancelInputError(t('billingInputError.maxLengthFifty'));
        } else if (cancelInputError) {
          setCancelInputError(undefined);
        }

        setCancelInputValue(e.target.value);
      }}
    />
  );
};

export default forwardRef(AdminRefuse);
