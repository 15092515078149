import { getRequest, postRequest, putRequest, deleteRequest } from 'lib/axios';

import api from './apiRoutes';

export const getOrderApi = args => getRequest(api.getOrder(args));
export const getAddressNoteApi = args => {
  const { vendorId, addressId } = args;

  return getRequest(api.getAddressNote(vendorId, addressId));
};
export const createAddressNoteApi = args =>
  postRequest(api.createAddressNote(), args);
export const updateAddressNoteApi = args => {
  const { vendorId, addressId, addressNote } = args;

  return putRequest(api.updateAddressNote(vendorId, addressId), {
    addressNote,
  });
};

export const deleteAddressNoteApi = args => {
  const { vendorId, addressId } = args;

  return deleteRequest(api.deleteAddressNote(vendorId, addressId));
};
