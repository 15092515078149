import React from 'react';
import PropTypes from 'prop-types';

const LocationIcon = ({ fill, height, width }) => (
  <svg
    width={width}
    height={height}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.60667 3.31981C7.03308 0.893398 10.9671 0.893398 13.3935 3.31981C15.8199 5.74621 15.8199 9.6802 13.3935 12.1066L9.00007 16.5L4.60667 12.1066C2.18026 9.68019 2.18026 5.74621 4.60667 3.31981ZM9.00007 9.4884C9.98049 9.4884 10.7753 8.69362 10.7753 7.7132C10.7753 6.73279 9.98049 5.938 9.00007 5.938C8.01965 5.938 7.22487 6.73279 7.22487 7.7132C7.22487 8.69362 8.01965 9.4884 9.00007 9.4884Z"
      fill="inherit"
    />
  </svg>
);

LocationIcon.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

LocationIcon.defaultProps = {
  fill: 'white',
  height: 20,
  width: 22,
};

export default LocationIcon;
