import React, { useEffect, useState } from 'react';
import { Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';

import Button from 'components/v1/Button';
import * as FirmActions from 'containers/Stores/Firm/actions';
import * as VendorActions from 'containers/Stores/Vendor/actions';
import * as MainHeaderActions from 'components/MainHeader/actions';
import ConfirmModal from 'components/ConfirmModal';
import Layout from 'components/Layout';
import { BUSINESS_TYPES } from 'common/constants';
import { VENDOR_SALE_STATUS as vendorSaleStatus } from 'constants/vendorSaleStatus';

import * as S from './style';
import FirmForm from './Forms/Firm';
import VendorForm from './Forms/Vendor';
import SaleModal from './Forms/SaleModal';
import SapErrorModal from './SapErrorModal';

const GeneralInformation = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const firmId = useSelector(state => state.firm.firmId);
  const vendor = useSelector(state => state.mainHeader.vendor);
  const vendorInfo = useSelector(state => state.mainHeader.vendorInfo);
  const isSAPAreasEditable = false;
  const dispatch = useDispatch();
  const [isFieldsEditable, setIsFieldsEditable] = useState(false);
  const [isFirm, setIsFirm] = useState(undefined);

  // firm states
  const [isDeleteFirmModalVisible, setIsDeleteFirmModalVisible] = useState(
    false,
  );

  // vendor states
  const [isOpenSaleModalVisible, setIsOpenSaleModalVisible] = useState(false);
  const [isCloseSaleModalVisible, setIsCloseSaleModalVisible] = useState(false);
  const [isDeleteVendorModalVisible, setIsDeleteVendorModalVisible] = useState(
    false,
  );
  const [isSAPErrorModalVisible, setIsSAPErrorModalVisible] = useState(false);

  useEffect(() => {
    if (location.state) {
      if (location.state.type === BUSINESS_TYPES.FIRM) {
        setIsFirm(true);

        return;
      }
      setIsFirm(false);

      return;
    }

    if (vendor && vendor.isFirm) {
      setIsFirm(true);

      return;
    }

    setIsFirm(false);
  }, [location, vendor]);

  const toggleFieldsEditability = value => {
    setIsFieldsEditable(value);
  };

  const handleDeleteFirmModalSave = () => {
    setIsDeleteFirmModalVisible(false);
    dispatch(FirmActions.deleteFirmRequest(firmId));
  };

  const handleDeleteFirmModalClose = () => {
    setIsDeleteFirmModalVisible(false);
  };

  const handleOpenSaleModalSave = () => {
    dispatch(VendorActions.openForSaleRequest(vendorInfo.id));
    setIsOpenSaleModalVisible(false);
  };

  const handleOpenSaleModalClose = () => {
    setIsOpenSaleModalVisible(false);
  };

  const handleDeleteVendorModalSave = () => {
    if (vendorInfo.id) {
      dispatch(VendorActions.deleteVendorRequest(vendorInfo.id));
      dispatch(MainHeaderActions.setVendor(undefined));
      dispatch(
        MainHeaderActions.getVendorInfoSuccess({
          id: undefined,
          workingHours: undefined,
        }),
      );
    }
    setIsDeleteVendorModalVisible(false);
  };

  const handleDeleteVendorModalClose = () => {
    setIsDeleteVendorModalVisible(false);
  };

  const handleCloseSaleModalSave = () => {
    setIsCloseSaleModalVisible(false);
  };

  const handleCloseSaleModalClose = () => {
    setIsCloseSaleModalVisible(false);
  };

  const handleSaleModalData = formData => {
    const data = {
      vendorId: vendorInfo.id,
      note: formData.note,
      closeReason: formData.reason.value,
    };
    dispatch(VendorActions.closeForSaleRequest(data));
    setIsCloseSaleModalVisible(false);
  };

  const firmActions = () => (
    <Space size="small">
      {!isFieldsEditable && (
        <Button onClick={() => toggleFieldsEditability(true)}>
          {t('edit')}
        </Button>
      )}
      <Button
        disabled={!isSAPAreasEditable}
        onClick={() => setIsDeleteFirmModalVisible(true)}
      >
        {t('firm:generalInfoDeleteFirm')}
      </Button>
    </Space>
  );

  const findVendorInfoStatus = status => {
    if (status === vendorSaleStatus.CLOSE || status === vendorSaleStatus.WAIT) {
      return true;
    }

    return false;
  };

  const vendorOpenToSaleHandler = () => {
    const hasReferenceCode = vendorInfo.referenceCode || vendorInfo.sapcardCode;
    const hasPayerReferenceCode = vendorInfo.payerReferenceCode;

    if (!hasReferenceCode || !hasPayerReferenceCode) {
      setIsSAPErrorModalVisible(true);

      return;
    }

    setIsOpenSaleModalVisible(true);
  };

  const vendorActions = () => (
    <Space size="small">
      {!isFieldsEditable && (
        <Button onClick={() => toggleFieldsEditability(true)}>
          {t('edit')}
        </Button>
      )}
      {findVendorInfoStatus(vendorInfo.status) ? (
        <Button onClick={vendorOpenToSaleHandler}>
          {t('vendor:openToSale')}
        </Button>
      ) : (
        <Button onClick={() => setIsCloseSaleModalVisible(true)}>
          {t('vendor:closeToSale')}
        </Button>
      )}
      <Button
        disabled={!isSAPAreasEditable}
        onClick={() => setIsDeleteVendorModalVisible(true)}
      >
        {t('vendor:deleteVendorButton')}
      </Button>
    </Space>
  );

  const handleFieldsEditable = value => {
    setIsFieldsEditable(value);
  };

  return (
    <Layout>
      <S.PageHeader>
        <S.PageTitle>{t('generalInformation:title')}</S.PageTitle>
        <S.PageTitleActions>
          {isFirm === true ? firmActions() : vendorActions()}
        </S.PageTitleActions>
      </S.PageHeader>
      <S.PageContent>
        {isFirm === true ? (
          <FirmForm
            isFieldsEditable={isFieldsEditable}
            getFieldsEditable={handleFieldsEditable}
          />
        ) : (
          <VendorForm
            isFieldsEditable={isFieldsEditable}
            getFieldsEditable={handleFieldsEditable}
          />
        )}
      </S.PageContent>
      <ConfirmModal
        size="400px"
        visible={isDeleteFirmModalVisible}
        onSave={handleDeleteFirmModalSave}
        onClose={handleDeleteFirmModalClose}
        title={t('firm:deleteFirmModalTitle')}
        content={t('firm:deleteFirmModalContent')}
        cancelText={t('giveUp')}
      />
      <ConfirmModal
        size="400px"
        visible={isOpenSaleModalVisible}
        onSave={handleOpenSaleModalSave}
        onClose={handleOpenSaleModalClose}
        title={t('vendor:openToSale')}
        content={t('vendor:openToSaleContent')}
        cancelText={t('giveUp')}
      />
      <ConfirmModal
        size="400px"
        visible={isCloseSaleModalVisible}
        onSave={handleCloseSaleModalSave}
        onClose={handleCloseSaleModalClose}
        title={t('vendor:closeToSale')}
        content={
          <SaleModal
            onClose={handleCloseSaleModalClose}
            getData={handleSaleModalData}
          />
        }
        cancelText={t('giveUp')}
        visibleFooter={false}
      />
      <ConfirmModal
        size="400px"
        visible={isDeleteVendorModalVisible}
        onSave={handleDeleteVendorModalSave}
        onClose={handleDeleteVendorModalClose}
        title={t('vendor:deleteVendorModalTitle')}
        content={t('vendor:deleteVendorModalContent')}
        cancelText={t('giveUp')}
      />
      {!isFirm && (
        <ConfirmModal
          size="400px"
          visible={isSAPErrorModalVisible}
          title={t('vendor:sapVendorSaleOpenErrorTitle')}
          visibleFooter={false}
          content={
            <SapErrorModal onClose={() => setIsSAPErrorModalVisible(false)} />
          }
        />
      )}
    </Layout>
  );
};

export default GeneralInformation;
