const Breakpoint = {
  MOBILE: {
    MIN: 375,
    MAX: 767,
  },
  TABLET_MINI: {
    MIN: 768,
    MAX: 1023,
  },
  TABLET: {
    MIN: 1024,
    MAX: 1263,
  },
  DESKTOP: {
    MIN: 1264,
    NORMAL: 1440,
  },
  MIN_HEIGHT: 568,
};

export default Breakpoint;
