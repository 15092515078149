import { useState, useEffect } from 'react';

import { getItem } from 'utils/localStorage';

// cleanUp is a boolen value
// if cleanUp is true, it will run the cleanupFn
// if cleanUp is false, it will not run the cleanupFn

function useUserInfo(cleanUp) {
  const user = getItem('user');

  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    if (user) {
      setUserInfo(JSON.parse(user));
    }

    return () => {
      if (cleanUp === true) {
        setUserInfo({});
      }
    };
  }, [user, cleanUp]);

  return { userInfo };
}

export default useUserInfo;
