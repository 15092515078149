import { ORDER_STATUS } from 'constants/orderStatus';
import getRandomString from 'utils/getRandomString';
import { open } from 'utils/notification';
import translate from 'utils/translate';

import * as Types from './types';

const initialState = {
  list: [],
  completedList: [],
  completedTotal: undefined,
  canceledList: [],
  canceledTotal: undefined,
  error: {},
  delayedList: [],
  scheduledOrderList: [],
  successStatusChangeId: null,
  vendorIdList: undefined,
  userPopup: {},
};

function ActiveOrdersReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_ACTIVE_ORDERS_SUCCESS:
      return {
        ...state,
        list: payload.orders,
        vendorIdList:
          payload.vendorIdList.length === 0 ? undefined : payload.vendorIdList,
      };
    case Types.GET_ACTIVE_ORDERS_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.GET_COMPLETED_ORDERS_SUCCESS: {
      return {
        ...state,
        completedList: payload.orders,
        completedTotal: payload.totalPrice,
        vendorIdList:
          payload.vendorIdList.length === 0 ? undefined : payload.vendorIdList,
      };
    }
    case Types.CHANGE_DELAYED_ORDER_LIST: {
      return {
        ...state,
        delayedList: payload,
      };
    }
    case Types.GET_COMPLETED_ORDERS_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.RESET_DELAYED_LIST:
      return {
        ...state,
        delayedList: [],
      };
    case Types.GET_CANCELED_ORDERS_SUCCESS:
      return {
        ...state,
        canceledList: payload.orders,
        canceledTotal: payload.totalPrice,
        vendorIdList:
          payload.vendorIdList.length === 0 ? undefined : payload.vendorIdList,
      };
    case Types.GET_CANCELED_ORDERS_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.GET_SCHEDULED_ORDERS_SUCCESS:
      return {
        ...state,
        scheduledOrderList: payload.orders,
        vendorIdList:
          payload.vendorIdList.length === 0 ? undefined : payload.vendorIdList,
      };
    case Types.GET_SCHEDULED_ORDERS_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.GET_USER_POPUP_SUCCESS:
      return {
        ...state,
        userPopup: payload,
      };
    case Types.GET_USER_POPUP_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.SOCKET_ORDER_ADD: {
      const tempStateList = [...state.list];
      const index = state.list.findIndex(item => item.id === payload.id);
      const deleyedOrders = [...state.delayedList];
      const delayedOrderIndex = state.delayedList.findIndex(
        item => item.id === payload.id,
      );

      if (payload.status === ORDER_STATUS.SCHEDULED) {
        const tempScheduledOrderList = [...state.scheduledOrderList];
        tempScheduledOrderList.push(payload);

        return {
          ...state,
          scheduledOrderList: [
            ...tempScheduledOrderList.filter(item => item !== undefined),
          ],
        };
      }

      if (payload.status < ORDER_STATUS.VERIFYING) {
        return {
          ...state,
        };
      }

      if (
        index > -1 &&
        payload.status >= ORDER_STATUS.VERIFYING &&
        payload.status < ORDER_STATUS.DELIVERED
      ) {
        tempStateList[index] = { ...payload };
        deleyedOrders[delayedOrderIndex] = { ...payload };

        return {
          ...state,
          list: [...tempStateList],
          delayedList: [...deleyedOrders],
        };
      }
      if (index > -1 && payload.status >= ORDER_STATUS.DELIVERED) {
        delete tempStateList[index];
        if (delayedOrderIndex > -1) {
          delete deleyedOrders[delayedOrderIndex];
        }

        return {
          ...state,
          list: [...tempStateList.filter(item => item !== undefined)],
          delayedList: [...deleyedOrders.filter(item => item !== undefined)],
        };
      }

      if (index === -1 && payload.status === ORDER_STATUS.VERIFYING) {
        open('success', translate('activeOrders:newOrder'), '');
        const tempScheduledOrderList = [...state.scheduledOrderList];
        const scheduledIndex = state.scheduledOrderList.findIndex(
          item => item.id === payload.id,
        );
        if (scheduledIndex > -1) {
          delete tempScheduledOrderList[scheduledIndex];
        }

        tempStateList.push(payload);

        return {
          ...state,
          list: [...tempStateList.filter(item => item !== undefined)],
          scheduledOrderList: [
            ...tempScheduledOrderList.filter(item => item !== undefined),
          ],
        };
      }

      return {
        ...state,
        list: [...tempStateList],
      };
    }
    case Types.CHANGE_ORDER_STATUS_ERROR:
      return {
        ...state,
        error: payload,
      };
    case Types.CHANGE_ORDER_STATUS_SUCCESS: {
      return {
        ...state,
        successStatusChangeId: getRandomString(),
      };
    }
    case Types.ADD_ORDERS_TO_DELAYED_LIST: {
      const delayedOrder = state.list.find(order => order.id === payload);
      if (!delayedOrder) {
        return state;
      }

      return {
        ...state,
        delayedList: [...state.delayedList, delayedOrder],
      };
    }
    default:
      return state;
  }
}

export default ActiveOrdersReducer;
