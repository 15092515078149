import React from 'react';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import useResponsive from 'hooks/useResponsive';
import MobileOrdersPage from 'components/v1/MobileOrders';
import VendorStatus from 'components/v1/VendorStatus';

import * as S from '../style';

import { useDelayedOrdersColumns } from './useDelayedOrdersColumns';

const DelayedOrders = () => {
  const { t } = useTranslation();

  const { isMobileView, isMiniTabletView, isTabletView } = useResponsive();
  const isUnderTabletView = isMobileView || isMiniTabletView || isTabletView;

  const delayedOrdersList = useSelector(
    state => state.activeOrders.delayedList,
  );

  const { columns } = useDelayedOrdersColumns();

  return isMobileView ? (
    <MobileOrdersPage isActive={false} orders={delayedOrdersList} />
  ) : (
    <S.Container>
      {isUnderTabletView && <VendorStatus />}
      <S.StyledTable
        columns={columns}
        dataSource={delayedOrdersList}
        locale={{ emptyText: <Empty description={t('table.noData')} /> }}
        bordered
        pagination={{ size: 'small', position: ['bottomCenter'] }}
        rowKey="id"
        scroll={{ x: 1500 }}
      />
    </S.Container>
  );
};

export default DelayedOrders;
