import _ from 'lodash';

import { getEmptyInterval } from './common';

export default function mergeAlternativeMapIntervalsWithInitialValue(
  initialIntervals,
  newIntervals,
) {
  const mergedIntervals = [...initialIntervals];
  newIntervals.forEach(newMapInterval => {
    const numberOfIntervals = newMapInterval.intervals.length;
    for (let i = 0; i < numberOfIntervals; i += 1) {
      let currentIntervalValue = newMapInterval.intervals[i];
      if (_.isNull(currentIntervalValue)) {
        currentIntervalValue = getEmptyInterval();
      }
      mergedIntervals[newMapInterval.dayOfWeek - 1].intervals[i] = {
        ...currentIntervalValue,
      };
    }
  });

  return mergedIntervals;
}
