import { apiGateway } from 'constants/services';

export default {
  listProducts: `${apiGateway}/product-service/get-all-products`,
  addProduct: `${apiGateway}/product-service/create`,
  updateProduct: productId =>
    `${apiGateway}/product-service/update/${productId}`,
  getProduct: productId =>
    `${apiGateway}/product-service/get-product-by-product-id/${productId}`,
  uploadImage: `${apiGateway}/product-service/upload-image`,
  deleteProduct: productId =>
    `${apiGateway}/product-service/delete/${productId}`,
  getCatalogBrandByFirmId: firmId =>
    `${apiGateway}/brand-service/get/all/${firmId}`,
};
