import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SelectBox } from '@getir/web-components';

import * as CS from 'common/style';
import { open } from 'utils/notification';

import { getSelectListItems } from './api';
import * as S from './style';

const SelectBoxWrapper = ({
  fieldName,
  control,
  rules,
  placeholder,
  errors,
  isDisabled,
  options,
  isClearable,
  data,
  url,
  valueKey,
  labelKey,
  reqType,
  payloadKey,
  onChange,
  ...props
}) => {
  const { t } = useTranslation();
  const [list, setList] = useState([]);

  const getList = useCallback(
    async (apiUrl, apiData) => {
      try {
        const result = await getSelectListItems(apiUrl, reqType, apiData);
        const payloadData = payloadKey
          ? result.data.payload[payloadKey]
          : result.data.payload;
        const resultList = payloadData.map(item => ({
          value: item[valueKey],
          label: item[labelKey],
        }));
        setList(resultList);
      } catch (error) {
        open('warning', t('common:notif.warnTitle'), error.message);
      }
    },
    [t, labelKey, valueKey, reqType, payloadKey],
  );

  useEffect(() => {
    if (url.length > 0) {
      getList(url, data);
    }
    // eslint-disable-next-line
  }, [url]);

  return (
    <CS.FormFieldWrapper>
      <S.SelectBoxPlaceholder>{placeholder}</S.SelectBoxPlaceholder>
      <Controller
        name={fieldName}
        control={control}
        rules={rules}
        render={({ field }) => (
          <SelectBox
            options={options.length ? options : list}
            placeholder={placeholder}
            inputRef={field.ref}
            onChange={value => {
              if (onChange) onChange(value);
              field.onChange(value);
            }}
            onBlur={field.onBlur}
            name={field.name}
            value={(options.length ? options : list).find(item => {
              if (field.value !== undefined) {
                return valueKey
                  ? field.value[valueKey] === item.value
                  : field.value === item.value;
              }

              return false;
            })}
            hasError={!!errors[fieldName]}
            isDisabled={isDisabled}
            isClearable={isClearable}
            {...props}
          />
        )}
      />
      {errors[fieldName] && (
        <CS.ErrorMessage>{errors[fieldName].message}</CS.ErrorMessage>
      )}
    </CS.FormFieldWrapper>
  );
};

SelectBoxWrapper.defaultProps = {
  fieldName: '',
  control: {},
  rules: {},
  placeholder: '',
  errors: {},
  isDisabled: false,
  options: [],
  isClearable: false,
  data: {},
  url: '',
  valueKey: undefined,
  labelKey: '',
  reqType: 'post',
  payloadKey: undefined,
  onChange: undefined,
};

SelectBoxWrapper.propTypes = {
  fieldName: PropTypes.string,
  control: PropTypes.object,
  rules: PropTypes.object,
  placeholder: PropTypes.string,
  errors: PropTypes.object,
  isDisabled: PropTypes.bool,
  options: PropTypes.array,
  isClearable: PropTypes.bool,
  data: PropTypes.object,
  url: PropTypes.string,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  reqType: PropTypes.string,
  payloadKey: PropTypes.string,
  onChange: PropTypes.func,
};

export default SelectBoxWrapper;
