import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { WarningOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

import Layout from 'components/Layout';
import * as MainHeaderActions from 'components/MainHeader/actions';
import * as CS from 'common/style';

import * as S from './style';
import * as PaymentMethodsActions from './actions';

const PaymentMethods = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['common', 'paymentmethods']);
  const vendorInfo = useSelector(state => state.mainHeader.vendorInfo);
  const [isCreditChecked, setIsCreditChecked] = useState(false);
  const [isCashChecked, setIsCashChecked] = useState(false);

  useEffect(() => {
    if (vendorInfo && vendorInfo.id) {
      dispatch(MainHeaderActions.getVendorInfoRequest(vendorInfo.id));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (vendorInfo && vendorInfo.id) {
      setIsCreditChecked(vendorInfo.paymentMethods.cardPaymentOpen);
      setIsCashChecked(vendorInfo.paymentMethods.cashPaymentOpen);
    }
  }, [vendorInfo]);

  const handleCreditToggleChange = value => {
    setIsCreditChecked(value);
    dispatch(
      PaymentMethodsActions.updatePaymentMethodsRequest({
        isCardPaymentOpen: value,
        isCashPaymentOpen: isCashChecked,
        vendorId: vendorInfo.id,
      }),
    );
  };

  const handleCashToggleChange = value => {
    setIsCashChecked(value);
    dispatch(
      PaymentMethodsActions.updatePaymentMethodsRequest({
        isCardPaymentOpen: isCreditChecked,
        isCashPaymentOpen: value,
        vendorId: vendorInfo.id,
      }),
    );
  };

  return (
    <Layout>
      <CS.PageHeader>
        <CS.PageBody>
          <S.PaymentWarningContainer>
            <S.PaymentWarningIcon>
              <WarningOutlined />
            </S.PaymentWarningIcon>
            <S.PaymentWarningText>
              {t('paymentmethods:warningText')}
            </S.PaymentWarningText>
          </S.PaymentWarningContainer>
          <S.PaymentMethodsContainer>
            <S.StyledImage
              src="https://cdn.getiryemek.com/ondelivery/icon-card.png"
              alt="getirsu"
              objectFit="contain"
            />

            <S.StyledInput disabled placeholder={t('paymentmethods:credit')} />
            <CS.StyledToggle
              checked={isCreditChecked}
              checkedChildren={t('on')}
              uncheckedChildren={t('off')}
              onClick={() => handleCreditToggleChange(!isCreditChecked)}
            />
          </S.PaymentMethodsContainer>
          <S.PaymentMethodsContainer>
            <S.StyledImage
              src="https://cdn.getiryemek.com/ondelivery/icon-cash.png"
              alt="getirsu"
              objectFit="contain"
            />

            <S.StyledInput disabled placeholder={t('paymentmethods:cash')} />
            <CS.StyledToggle
              checked={isCashChecked}
              checkedChildren={t('on')}
              uncheckedChildren={t('off')}
              onClick={() => handleCashToggleChange(!isCashChecked)}
            />
          </S.PaymentMethodsContainer>
        </CS.PageBody>
      </CS.PageHeader>
    </Layout>
  );
};
export { PaymentMethods };
