import React, { useState, useMemo } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';

import useDebounce from 'hooks/useDebounce';
import { MapModal } from 'components/MapModal/MapModal';
import { MissingProductModal } from 'components/MissingProductModal/MissingProductModal';

import Input from '../Input';
import VendorStatus from '../VendorStatus';
import MobileOrderCard from '../MobileOrderCard';
import Pagination from '../Pagination';
import Info from '../Info';

import * as S from './style';

const DEFAULT_PAGINATION = {
  current: 1,
  pageSize: 5,
  total: 0,
  pageSizeOptions: [5, 10, 15, 20, 30, 50],
};

const MobileOrders = ({ orders, isCanceledOrders, isScheduledOrderPage }) => {
  const [filterValue, setFilterValue] = useState('');
  const debouncedValue = useDebounce(filterValue, 500);
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [selectedVendor, setSelectedVendor] = useState(undefined);
  const { t } = useTranslation();

  const filteredData = useMemo(() => {
    const filteredOrders = orders.filter(order => {
      const { client, confirmationCode, deliveryAddress } = order;
      const filterText = `${client.name}${confirmationCode}${deliveryAddress?.address}`.toLocaleLowerCase(
        'tr',
      );

      return filterText.includes(debouncedValue.toLocaleLowerCase('tr'));
    });

    const sortedToOrderDate = filteredOrders.sort(
      (a, b) => new Date(b.paymentDate) - new Date(a.paymentDate),
    );

    setPagination(state => ({
      ...state,
      total: filteredOrders.length,
      current: 1,
    }));

    return sortedToOrderDate;
  }, [debouncedValue, orders]);

  const paginationData = useMemo(() => {
    const { current, pageSize } = pagination;

    const start = (current - 1) * pageSize;
    const end = start + pageSize;

    return filteredData.slice(start, end);
  }, [filteredData, pagination]);

  const handleChange = event => {
    setFilterValue(event.target.value);
  };

  return (
    <S.Container>
      <Input
        placeholder={t('common:search')}
        size="medium"
        prefix={<SearchOutlined width={20} color="#5D3EBC" height={20} />}
        onChange={handleChange}
      />
      <VendorStatus />
      {isScheduledOrderPage && (
        <Info kind="warning">
          <S.InfoBox>
            <p>{t('activeOrders:scheduledInfoFirst')}</p>
            <p>{t('activeOrders:scheduledInfoSecond')}</p>
          </S.InfoBox>
        </Info>
      )}
      {paginationData.map(order => (
        <MobileOrderCard
          setSelectedVendor={setSelectedVendor}
          key={order.id}
          order={order}
          isCanceledOrders={isCanceledOrders}
        />
      ))}
      {(!paginationData || paginationData.length < 1) && (
        <Empty description={t('table.noData')} />
      )}
      <S.PaginationContainer>
        {paginationData && paginationData.length > 0 && (
          <Pagination
            {...pagination}
            showSizeChanger
            onChange={(page, pageSize) => {
              setPagination({ ...pagination, current: page, pageSize });
            }}
          />
        )}
      </S.PaginationContainer>
      <MapModal vendor={selectedVendor && selectedVendor.vendor} />
      <MissingProductModal vendor={selectedVendor} />
    </S.Container>
  );
};

MobileOrders.propTypes = {
  orders: PropTypes.array.isRequired,
  isCanceledOrders: PropTypes.bool,
  isScheduledOrderPage: PropTypes.bool,
};

MobileOrders.defaultProps = {
  isCanceledOrders: false,
  isScheduledOrderPage: false,
};

export default MobileOrders;
