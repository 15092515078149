import styled from 'styled-components';
import { Table } from 'antd';
import {
  Button as StyledButton,
  Icon as StyledIcon,
} from '@getir/web-components';

export const VendorListTable = styled(Table)`
  ${StyledButton.S.Wrapper} {
    border-radius: 0;
  }

  ${StyledIcon.S.Wrapper} {
    color: #db471e;
    cursor: pointer;
  }

  .ant-table-thead {
    tr {
      th {
        background: #eaeaea !important;
      }
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
