import * as ROUTES from 'constants/routes';

export const routesWithPerms = [
  {
    componentId: '',
    routes: ROUTES.HOME,
  },
  {
    componentId: 'user_list',
    routes: ROUTES.USERS,
  },
  {
    componentId: 'brand_list',
    routes: ROUTES.BRANDS,
  },
  {
    componentId: 'brand_list',
    routes: ROUTES.BRANDEDIT,
  },
  {
    componentId: 'brand_list',
    routes: ROUTES.BRANDCREATE,
  },
  {
    componentId: 'brand_list',
    routes: ROUTES.BRANDPRODUCT,
  },
  {
    componentId: 'active_orders',
    routes: ROUTES.ACTIVE_ORDERS,
  },
  {
    componentId: 'firm_page',
    routes: ROUTES.FIRMS,
  },
  {
    componentId: 'firm_page',
    routes: ROUTES.ADD_NEW_FIRM,
  },
  {
    componentId: 'general_info',
    routes: ROUTES.GENERAL_INFORMATION,
  },
  {
    componentId: 'vendor_page',
    routes: ROUTES.VENDORS,
  },
  {
    componentId: 'vendor_page',
    routes: ROUTES.ADD_NEW_VENDOR,
  },
  {
    componentId: 'financial_info',
    routes: ROUTES.FINANCIAL_INFORMATION,
  },
  {
    componentId: 'working_hours',
    routes: ROUTES.WORKING_HOURS,
  },
  {
    componentId: 'category_list',
    routes: ROUTES.LIST_CATEGORY,
  },
  {
    componentId: 'category_list',
    routes: ROUTES.ADD_CATEGORY,
  },
  {
    componentId: 'category_list',
    routes: ROUTES.EDIT_CATEGORY,
  },
  {
    componentId: 'catalog_list',
    routes: ROUTES.LIST_CATALOG,
  },
  {
    componentId: 'catalog_list',
    routes: ROUTES.ADD_CATALOG,
  },
  {
    componentId: 'catalog_list',
    routes: ROUTES.EDIT_CATALOG,
  },
  {
    componentId: 'product_list',
    routes: ROUTES.VENDORPRODUCT,
  },
  {
    componentId: 'product_list',
    routes: ROUTES.VENDORPRODUCTEDIT,
  },
  {
    componentId: 'payment_methods',
    routes: ROUTES.PAYMENT_METHODS,
  },
  {
    componentId: 'previous_orders',
    routes: ROUTES.PREVIOUS_ORDERS,
  },
  {
    componentId: 'financial_transactions',
    routes: ROUTES.FINANCIAL_TRANSACTIONS,
  },
  {
    componentId: 'financial_transactions',
    routes: ROUTES.ORDER_DETAIL,
  },
  {
    componentId: 'requests',
    routes: ROUTES.REQUESTS,
  },
  {
    componentId: 'requests',
    routes: ROUTES.REQUESTS_WORKING_HOURS,
  },
  {
    componentId: 'requests',
    routes: ROUTES.REQUESTS_BULK_CHANGE,
  },
  {
    componentId: 'bulk_change',
    routes: ROUTES.BULK_CHANGE,
  },
  {
    componentId: 'map_upload',
    routes: ROUTES.UPLOAD_GEOMAP,
  },
  {
    componentId: 'map_ban',
    routes: ROUTES.BAN_GEOMAP,
  },
  {
    componentId: 'alternative_map',
    routes: ROUTES.ALTERNATIVE_MAP_HOURS,
  },
  {
    componentId: 'active_orders',
    routes: ROUTES.REDIRECT_ACTIVE_ORDERS,
  },
  {
    componentId: 'financial_transactions_add',
    routes: ROUTES.ADD_FINANCIAL_TRANSACTION,
  },
  {
    componentId: 'auth_users',
    routes: ROUTES.AUTH_USERS,
  },
  {
    componentId: 'auth_perms',
    routes: ROUTES.AUTH_PERMS,
  },
  {
    componentId: 'auth_roles',
    routes: ROUTES.AUTH_ROLES,
  },
  {
    componentId: 'auth_roles',
    routes: ROUTES.ADD_NEW_ROLE,
  },
  {
    componentId: 'auth_roles',
    routes: ROUTES.EDIT_ROLE,
  },
  {
    componentId: 'vendor_communication',
    routes: ROUTES.VENDOR_COMMUNICATION,
  },
  {
    componentId: 'kuzeyden_reports',
    routes: ROUTES.KUZEYDEN_REPORT,
  },
];
