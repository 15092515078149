import { takeLatest } from 'redux-saga/effects';

import {
  listBrandsApi,
  addBrandApi,
  updateBrandApi,
  getBrandProductApi,
  saveOrderBrandProductApi,
  deleteBrandApi,
} from 'services/Brand/api';
import * as ROUTES from 'constants/routes';
import { createStandardSaga } from 'utils/createStandardSaga';

import * as Types from './types';
import * as BrandActions from './actions';

const listBrandsSaga = createStandardSaga({
  callApi: listBrandsApi,
  successAction: BrandActions.brandListSuccess,
  errorAction: BrandActions.brandListError,
});

const addBrandSaga = createStandardSaga({
  callApi: addBrandApi,
  successAction: BrandActions.addBrandSuccess,
  errorAction: BrandActions.addBrandError,
  successPushRoute: ROUTES.BRANDS,
  successMessage: 'brands:createBrandSuccessMessage',
});

const updateBrandSaga = createStandardSaga({
  callApi: updateBrandApi,
  successAction: BrandActions.updateBrandSuccess,
  errorAction: BrandActions.updateBrandError,
  successPushRoute: ROUTES.BRANDS,
  successMessage: 'brands:updateBrandSuccessMessage',
});

const listBrandProductsSaga = createStandardSaga({
  callApi: getBrandProductApi,
  successAction: BrandActions.brandProductListSuccess,
  errorAction: BrandActions.brandProductListError,
});

const saveOrderBrandProductsSaga = createStandardSaga({
  callApi: saveOrderBrandProductApi,
  successAction: BrandActions.brandProductListSaveSuccess,
  errorAction: BrandActions.brandProductListSaveError,
});

const deleteBrandSaga = createStandardSaga({
  callApi: deleteBrandApi,
  successAction: BrandActions.deleteBrandSuccess,
  errorAction: BrandActions.deleteBrandError,
});

export function* rootSaga() {
  yield takeLatest(Types.BRAND_LIST_REQUEST, listBrandsSaga);
  yield takeLatest(Types.ADD_BRAND_REQUEST, addBrandSaga);
  yield takeLatest(Types.EDIT_BRAND_REQUEST, updateBrandSaga);
  yield takeLatest(Types.BRAND_PRODUCT_LIST_REQUEST, listBrandProductsSaga);
  yield takeLatest(Types.BRAND_PRODUCT_LIST_SAVE, saveOrderBrandProductsSaga);
  yield takeLatest(Types.DELETE_BRAND_REQUEST, deleteBrandSaga);
  yield takeLatest(Types.DELETE_BRAND_SUCCESS, listBrandsSaga);
}

export default rootSaga;
