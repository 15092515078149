import styled from 'styled-components';

const FormBodyWrapper = styled.div`
  display: grid;
  grid-row-gap: 24px;
  text-align: left;
`;

const FormFooterWrapper = styled.div`
  .ant-space {
    display: flex;
    width: 100%;

    .ant-space-item {
      flex: 1;
      > div {
        width: 100%;
        border-radius: 0;
      }
    }
  }
`;

export { FormBodyWrapper, FormFooterWrapper };
