import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import useUserInfo from 'hooks/useUserInfo';
import { ORDER_STATUS } from 'constants/orderStatus';
import { PAYMENT_TYPE } from 'constants/paymentType';

import { getOrderType } from '../utils/getOrderType';
import * as ActiveOrdersActions from '../actions';

const useGetCanceledOrderList = () => {
  const dispatch = useDispatch();
  const vendorInfo = useSelector(state => state.mainHeader.vendorInfo);
  const selectedBusiness = useSelector(state => state.mainHeader.vendor);
  const { userInfo } = useUserInfo();

  const getCanceledOrderList = () => {
    if (!selectedBusiness || !selectedBusiness.value) return;

    if (
      vendorInfo?.letterOfUndertaking?.isApprovedLU ||
      (!vendorInfo?.letterOfUndertaking && Object.keys(userInfo).length > 0)
    ) {
      const data = {
        statusList: [ORDER_STATUS.CANCELED_ADMIN, ORDER_STATUS.CANCELED_VENDOR],
        paymentType: [
          PAYMENT_TYPE.ONLINE_CREDIT_CARD,
          PAYMENT_TYPE.ONLINE_BKM,
          PAYMENT_TYPE.CREDIT_OR_DEBIT_CARD_AT_DELIVERY,
          PAYMENT_TYPE.CASH_AT_DELIVERY,
        ],
        id: selectedBusiness && selectedBusiness.value,
        startDate: dayjs().startOf('day').toISOString(),
        endDate: dayjs().toDate().toISOString(),
        type: getOrderType(selectedBusiness),
      };
      dispatch(ActiveOrdersActions.getCanceledOrdersRequest(data));
    }
  };

  const getCanceledOrderListCallback = useCallback(() => {
    getCanceledOrderList();
    // eslint-disable-next-line
  }, [vendorInfo, dispatch, userInfo]);

  return { getCanceledOrderListCallback, getCanceledOrderList };
};

export { useGetCanceledOrderList };
