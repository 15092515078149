import { createAction } from 'utils/createAction';

import * as Types from './types';

// list catalogs
export const catalogProductListRequest = createAction(
  Types.CATALOGPRODUCT_LIST_REQUEST,
);
export const catalogProductListSuccess = createAction(
  Types.CATALOGPRODUCT_LIST_SUCCESS,
);
export const catalogProductListError = createAction(
  Types.CATALOGPRODUCT_LIST_SUCCESS,
);

// add new catalogProduct
export const addCatalogProductRequest = createAction(
  Types.ADD_CATALOGPRODUCT_REQUEST,
);
export const addCatalogProductSuccess = createAction(
  Types.ADD_CATALOGPRODUCT_SUCCESS,
);
export const addCatalogProductError = createAction(
  Types.ADD_CATALOGPRODUCT_ERROR,
);

// update catalogProduct
export const updateCatalogProductRequest = createAction(
  Types.EDIT_CATALOGPRODUCT_REQUEST,
);
export const updateCatalogProductSuccess = createAction(
  Types.EDIT_CATALOGPRODUCT_SUCCESS,
);
export const updateCatalogProductError = createAction(
  Types.EDIT_CATALOGPRODUCT_SUCCESS,
);

// delete catalogProduct
export const deleteCatalogProductRequest = createAction(
  Types.DELETE_CATALOGPRODUCT_REQUEST,
);
export const deleteCatalogProductSuccess = createAction(
  Types.DELETE_CATALOGPRODUCT_SUCCESS,
);
export const deleteCatalogProductError = createAction(
  Types.DELETE_CATALOGPRODUCT_ERROR,
);

//
export const catalogBrandReset = createAction(Types.CATALOGPRODUCT_BRAND_RESET);
export const catalogBrandRequest = createAction(
  Types.CATALOGPRODUCT_BRAND_REQUEST,
);
export const catalogBrandSuccess = createAction(
  Types.CATALOGPRODUCT_BRAND_SUCCESS,
);
export const catalogBrandError = createAction(Types.CATALOGPRODUCT_LIST_ERROR);
