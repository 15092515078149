/**
 * If the browser supports localStorage and there's enough space to store the data, return true.
 * Otherwise, return false
 * @param type - The type of storage to test. This can be either localStorage or sessionStorage.
 * @returns A boolean value.
 */
function storageAvailable(type) {
  let storage;
  try {
    storage = window[type];
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);

    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    );
  }
}

const setItem = (key, value) => {
  if (storageAvailable('localStorage')) {
    localStorage.setItem(key, value);
  }
};

const getItem = key => {
  if (storageAvailable('localStorage')) {
    return localStorage.getItem(key);
  }

  return undefined;
};

const removeItem = key => {
  if (storageAvailable('localStorage')) {
    localStorage.removeItem(key);
  }
};

const clear = () => {
  if (storageAvailable('localStorage')) {
    localStorage.clear();
  }
};

export { setItem, getItem, removeItem, clear };
