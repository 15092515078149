import translate from 'utils/translate';
const extractRecommendedOptionsFrom = bulk => {
  if (bulk) {
    return Object.keys(bulk).map(item => ({
      label: translate(bulk[item].label),
      value: item,
    }));
  }

  return [];
};

const convertToSelectedOptionsRecommended = (bulk, selectedOption) => {
  if (bulk && selectedOption) {
    return selectedOption.value
      ? bulk[selectedOption.value].value
      : bulk[selectedOption].value;
  }

  return undefined;
};

export { extractRecommendedOptionsFrom, convertToSelectedOptionsRecommended };
