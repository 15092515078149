import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TableComponent from 'components/v1/Table';
import useUserInfo from 'hooks/useUserInfo';
import { rolesWithName } from 'constants/roles';

import { getThreadListRequest, resetAllStates } from '../actions';
import Filter from '../Filter';

import * as S from './styles';
import useTableColumn from './useTableColumn';

const Table = () => {
  const tableColumns = useTableColumn();
  const dispatch = useDispatch();

  const selectedVendorInfo = useSelector(state => state.mainHeader.vendor);
  const threadList = useSelector(state => state.vendorCommunication.threadList);
  const paginationSettings = useSelector(
    state => state.vendorCommunication.paginationSettings,
  );

  const { userInfo } = useUserInfo();

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: paginationSettings.totalElements,
    size: 'small',
    showSizeChanger: true,
    position: ['bottomCenter'],
  });
  useEffect(() => {
    if (paginationSettings.totalPages >= 0) {
      setPagination(state => ({
        ...state,
        total: paginationSettings.totalElements,
      }));
    }
  }, [paginationSettings]);

  useEffect(() => {
    if (!userInfo || !userInfo.role) {
      return;
    }

    if (userInfo && userInfo.role?.includes(rolesWithName.ADMIN)) {
      dispatch(
        getThreadListRequest({
          page: pagination.current - 1,
          size: pagination.pageSize,
        }),
      );

      return;
    }

    if (
      selectedVendorInfo &&
      !selectedVendorInfo.isFirm &&
      selectedVendorInfo.value
    ) {
      dispatch(
        getThreadListRequest({
          vendorId: selectedVendorInfo.value,
          page: pagination.current - 1,
          size: pagination.pageSize,
        }),
      );

      return;
    }

    if (selectedVendorInfo && selectedVendorInfo.isFirm && threadList) {
      dispatch(resetAllStates());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    selectedVendorInfo,
    userInfo,
    pagination.current,
    pagination.pageSize,
  ]);

  const handlePaginationChange = ({ current, pageSize }) => {
    setPagination({ ...pagination, current, pageSize });
  };

  const onFilterClick = useCallback(
    subject => {
      if (!userInfo || !userInfo.role) {
        return;
      }

      if (userInfo && userInfo.role?.includes(rolesWithName.ADMIN)) {
        dispatch(
          getThreadListRequest({
            page: pagination.current - 1,
            size: pagination.pageSize,
            subject,
          }),
        );

        return;
      }

      if (
        selectedVendorInfo &&
        !selectedVendorInfo.isFirm &&
        selectedVendorInfo.value
      ) {
        dispatch(
          getThreadListRequest({
            vendorId: selectedVendorInfo.value,
            page: pagination.current - 1,
            size: pagination.pageSize,
            subject,
          }),
        );

        return;
      }

      if (userInfo && userInfo.role?.startsWith(rolesWithName.ADMIN)) {
        dispatch(
          getThreadListRequest({
            page: pagination.current - 1,
            size: pagination.pageSize,
            subject,
          }),
        );
      }
    },
    [dispatch, pagination, selectedVendorInfo, userInfo],
  );

  const onClearFilterClick = useCallback(() => {
    if (!userInfo || !userInfo.role) {
      return;
    }

    if (userInfo && userInfo.role?.includes(rolesWithName.ADMIN)) {
      dispatch(
        getThreadListRequest({
          page: 0,
          size: 10,
        }),
      );

      return;
    }

    if (
      selectedVendorInfo &&
      !selectedVendorInfo.isFirm &&
      selectedVendorInfo.value
    ) {
      dispatch(
        getThreadListRequest({
          vendorId: selectedVendorInfo.value,
          page: pagination.current - 1,
          size: pagination.pageSize,
        }),
      );
    }
  }, [dispatch, pagination, selectedVendorInfo, userInfo]);

  return (
    <S.Container>
      <Filter
        onClearFilterClick={onClearFilterClick}
        onFilterClick={onFilterClick}
      />
      <TableComponent
        handlePaginationChange={handlePaginationChange}
        columns={tableColumns}
        data={threadList}
        scroll={{ x: 800 }}
        pagination={pagination}
        rowKey="messageId"
      />
    </S.Container>
  );
};

export default Table;
