import React, { useEffect, useMemo, useState } from 'react';
import { Space, Divider, Row, Col } from 'antd';
import { useForm, Controller } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Icon, Button } from '@getir/web-components';
import { useDispatch } from 'react-redux';
import { isArray } from 'lodash';

import Layout from 'components/Layout';
import InputWrapper from 'components/InputWrapper';
import SelectBoxWrapper from 'components/SelectBoxWrapper';
import * as ROUTES from 'constants/routes';
import * as S from 'common/style';
import { getProductApi } from 'services/CatalogProduct/api';
import { open } from 'utils/notification';
import apiRoutes from 'services/CatalogProduct/apiRoutes';
import Uploader from 'components/Uploader';
import SelectBoxAntd from 'components/Antd/Elements/SelectBox';
import TextareaWrapper from 'components/TextareaWrapper';

import { extractFirmOptionsFrom } from '../helpers/extractFirmOptionsFrom';
import { extractBrandOptionsFrom } from '../helpers/extractBrandOptionsFrom';
import { useBrandsFirmsCategories } from '../helpers/useBrandsFirmsCategories';
import * as CatalogActions from '../actions';
import { extractProductItemFrom } from '../helpers/extractProductItemFrom';
import { useSetRemoveImage } from '../helpers/useSetRemoveImage';
import useValidations from '../helpers/useValidations';
import { useDepositStatus } from '../helpers/useDepositStatus';
import { extractCategoryOptionsFrom } from '../helpers/extractCategoryOptionsFrom';
import {
  extractDepositOptionsFrom,
  extractDepositOptionsTo,
} from '../helpers/extractDepositOptions';
import { extractRecommendedOptionsFrom } from '../helpers/extractRecommended';
import { showAsRecomendedProduct } from '../constants';

const Edit = () => {
  const params = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const { validationRules } = useValidations();
  const { brands, firms, categories } = useBrandsFirmsCategories();

  const dispatch = useDispatch();
  const [productInfo, setProductInfo] = useState();
  const [firmId, setFirmId] = useState();
  const isKuzeydenBrand = useMemo(() => {
    if (
      !isArray(brands) ||
      brands.length === 0 ||
      !productInfo ||
      !productInfo.brand.id
    )
      return false;

    const kuzeydenBrand = brands.filter(
      item => item.id === productInfo.brand.id,
    );

    if (kuzeydenBrand.length > 0) {
      return brands[0].brandName.toLowerCase().includes('kuzeyden');
    }

    return false;
  }, [productInfo, brands]);

  const { depositStatus } = useDepositStatus(isKuzeydenBrand);

  useEffect(() => {
    if (productInfo === undefined) {
      getProductApi({ id: params.id }).then(result => {
        if (result.data && result.data.payload)
          setProductInfo(result.data.payload);
        if (result.message) {
          open('warning', t('notif:warnTitle'), result.message);
          setProductInfo({});
        }
      });
    }
    // eslint-disable-next-line
  }, [productInfo]);

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    mode: 'onBlur',
  });

  useEffect(() => {
    setValue('brandId', undefined);
    if (firmId) dispatch(CatalogActions.catalogBrandRequest({ firmId }));
  }, [firmId, setValue, dispatch]);

  const { setImage, removeImage } = useSetRemoveImage(
    setValue,
    'productImageUrl',
    productInfo?.productImageUrl,
  );
  const {
    setImage: setWideImage,
    removeImage: removeWideImage,
  } = useSetRemoveImage(
    setValue,
    'productWideImageUrl',
    productInfo?.productWideImageUrl,
  );

  useEffect(() => {
    if (productInfo && Object.keys(productInfo).length > 0) {
      setValue('firmId', productInfo.firm.id);
      dispatch(
        CatalogActions.catalogBrandRequest({ firmId: productInfo.firm.id }),
      );
      setValue('brandId', productInfo.brand.id);
      setValue('categoryId', productInfo.category.id);
      setValue('longDescription_EN', productInfo.descriptionEN, {
        shouldValidate: true,
      });
      setValue('longDescription_TR', productInfo.descriptionTR, {
        shouldValidate: true,
      });
      setValue('longProductName_EN', productInfo.displayNameEN, {
        shouldValidate: true,
      });
      setValue('longProductName_TR', productInfo.displayNameTR, {
        shouldValidate: true,
      });
      setValue('maxOrderCount', productInfo.maxOrderCount, {
        shouldValidate: true,
      });
      setValue('productWideImageUrl', productInfo.productWideImageUrl, {
        shouldValidate: true,
      });
      setValue('productImageUrl', productInfo.productImageUrl, {
        shouldValidate: true,
      });
      setValue('productName', productInfo.productName, {
        shouldValidate: true,
      });
      setValue('shortProductName_EN', productInfo.productShortNameEN, {
        shouldValidate: true,
      });
      setValue('shortProductName_TR', productInfo.productShortNameTR, {
        shouldValidate: true,
      });
      setValue('shortDescription_EN', productInfo.shortDescriptionEN, {
        shouldValidate: true,
      });
      setValue('shortDescription_TR', productInfo.shortDescriptionTR, {
        shouldValidate: true,
      });

      setValue('isRecommended', String(productInfo.isRecommended), {
        shouldValidate: true,
      });
      setValue(
        'depositCarboy',
        extractDepositOptionsTo(productInfo.depositCarboyBadgeName),
        { shouldValidate: true },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productInfo, setValue, dispatch]);

  const onSubmit = formdata => {
    const extractedData = extractProductItemFrom(formdata);

    dispatch(
      CatalogActions.updateCatalogProductRequest({
        ...extractedData,
        id: params.id,
      }),
    );
  };

  return (
    <Layout>
      <S.PageHeader>
        <Space>
          <Icon
            name="arrow-left"
            onClick={() => history.push(ROUTES.LIST_CATALOG)}
            title={t('backToList')}
          />
          <S.PageTitle>{t('catalogs:updateCatalogProduct')}</S.PageTitle>
        </Space>
      </S.PageHeader>
      <S.PageContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row gutter={16} align="bottom">
            <Col span={24}>
              <SelectBoxWrapper
                fieldName="isRecommended"
                control={control}
                rules={validationRules.isRecommended}
                placeholder={t('catalogs:showAsRecomendedProduct')}
                errors={errors}
                options={extractRecommendedOptionsFrom(showAsRecomendedProduct)}
              />
            </Col>
          </Row>
          <Row gutter={16} align="bottom">
            <Col span={12}>
              <SelectBoxWrapper
                fieldName="categoryId"
                control={control}
                rules={validationRules.categoryId}
                placeholder={t('catalogs:categoryName')}
                errors={errors}
                options={extractCategoryOptionsFrom(categories)}
              />
            </Col>
            <Col span={12}>
              <InputWrapper
                fieldName="productName"
                control={control}
                rules={validationRules.productName}
                placeholder={t('catalogs:productName')}
                header={t('catalogs:productName')}
                errors={errors}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <InputWrapper
                fieldName="shortProductName_TR"
                control={control}
                rules={validationRules.shortProductName_TR}
                placeholder={t('catalogs:shortProductName_TR')}
                header={t('catalogs:shortProductName_TR')}
                errors={errors}
              />
            </Col>
            <Col span={12}>
              <InputWrapper
                fieldName="shortProductName_EN"
                control={control}
                rules={validationRules.shortProductName_EN}
                placeholder={t('catalogs:shortProductName_EN')}
                header={t('catalogs:shortProductName_EN')}
                errors={errors}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <InputWrapper
                fieldName="longProductName_TR"
                control={control}
                rules={validationRules.longProductName_TR}
                placeholder={t('catalogs:longProductName_TR')}
                header={t('catalogs:longProductName_TR')}
                errors={errors}
              />
            </Col>
            <Col span={12}>
              <InputWrapper
                fieldName="longProductName_EN"
                control={control}
                rules={validationRules.longProductName_EN}
                placeholder={t('catalogs:longProductName_EN')}
                header={t('catalogs:longProductName_EN')}
                errors={errors}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <InputWrapper
                fieldName="shortDescription_TR"
                control={control}
                rules={validationRules.shortDescription_TR}
                placeholder={t('catalogs:shortDescription_TR')}
                header={t('catalogs:shortDescription_TR')}
                errors={errors}
              />
            </Col>
            <Col span={12}>
              <InputWrapper
                fieldName="shortDescription_EN"
                control={control}
                rules={validationRules.shortDescription_EN}
                placeholder={t('catalogs:shortDescription_EN')}
                header={t('catalogs:shortDescription_EN')}
                errors={errors}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <TextareaWrapper
                fieldName="longDescription_TR"
                control={control}
                rules={validationRules.longDescription_TR}
                placeholder={t('catalogs:longDescription_TR')}
                header={t('catalogs:longDescription_TR')}
                errors={errors}
              />
            </Col>
            <Col span={12}>
              <TextareaWrapper
                fieldName="longDescription_EN"
                control={control}
                rules={validationRules.longDescription_EN}
                placeholder={t('catalogs:longDescription_EN')}
                header={t('catalogs:longDescription_EN')}
                errors={errors}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Controller
                name="firmId"
                control={control}
                rules={validationRules.firmId}
                render={({ field }) => (
                  <SelectBoxAntd
                    placeholder={t('catalogs:firm')}
                    onChange={value => {
                      setFirmId(value);
                      field.onChange(value);
                    }}
                    showSearch
                    hasError={errors.firmId}
                    value={field.value}
                    options={extractFirmOptionsFrom(firms)}
                    allowClear
                    disabled
                  />
                )}
              />
              {errors.firmId && (
                <S.ErrorMessage>{errors.firmId.message}</S.ErrorMessage>
              )}
            </Col>
            <Col span={12}>
              <Controller
                name="brandId"
                control={control}
                rules={validationRules.brandId}
                render={({ field }) => (
                  <SelectBoxAntd
                    placeholder={t('catalogs:brand')}
                    onChange={value => {
                      field.onChange(value);
                    }}
                    showSearch
                    hasError={errors.brandId}
                    value={field.value}
                    options={extractBrandOptionsFrom(brands)}
                    allowClear
                    disabled
                  />
                )}
              />
              {errors.brandId && (
                <S.ErrorMessage>{errors.brandId.message}</S.ErrorMessage>
              )}
            </Col>
          </Row>
          <Row gutter={16} align="bottom">
            <Col span={12}>
              <InputWrapper
                fieldName="maxOrderCount"
                control={control}
                rules={validationRules.maxOrderCount}
                placeholder={t('catalogs:maxOrderCount')}
                header={t('catalogs:maxOrderCount')}
                errors={errors}
                isOnlyNumber
              />
            </Col>
            <Col span={12}>
              <Controller
                name="depositCarboy"
                control={control}
                render={({ field }) => (
                  <SelectBoxAntd
                    placeholder={t('catalogs:depositStatus')}
                    onChange={value => {
                      field.onChange(value);
                    }}
                    showSearch
                    value={field.value}
                    options={extractDepositOptionsFrom(depositStatus)}
                    allowClear
                  />
                )}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Controller
                name="productWideImageUrl"
                control={control}
                rules={validationRules.productWideImageUrl}
                render={({ field }) => (
                  <Uploader
                    name="file"
                    uploadUrl={apiRoutes.uploadImage}
                    multiple={false}
                    onSuccess={setWideImage}
                    onRemove={removeWideImage}
                    title={t('catalogs:productWideImageUrl')}
                    imageWidth={1400}
                    imageHeight={700}
                    field={field}
                  />
                )}
              />
              {errors.productWideImageUrl && (
                <S.ErrorMessage>
                  {errors.productWideImageUrl.message}
                </S.ErrorMessage>
              )}
            </Col>
            <Col span={12}>
              <Controller
                name="productImageUrl"
                control={control}
                rules={validationRules.productImageUrl}
                render={({ field }) => (
                  <Uploader
                    name="file"
                    uploadUrl={apiRoutes.uploadImage}
                    multiple={false}
                    onSuccess={setImage}
                    onRemove={removeImage}
                    title={t('catalogs:productImageUrl')}
                    imageWidth={700}
                    imageHeight={700}
                    field={field}
                    errors={errors}
                    fieldName="productImageUrl"
                    style={{ marginBottom: 0, padding: '2px' }}
                  />
                )}
              />
              {errors.productImageUrl && (
                <S.ErrorMessage>
                  {errors.productImageUrl.message}
                </S.ErrorMessage>
              )}
            </Col>
          </Row>
          <Divider />
          <Space size="middle">
            <Button
              kind="secondary"
              onClick={() => history.push(ROUTES.LIST_CATALOG)}
            >
              {t('giveUp')}
            </Button>
            <Button type="submit">{t('save')}</Button>
          </Space>
        </form>
      </S.PageContent>
    </Layout>
  );
};

export default Edit;
