export const GET_ROLE_LIST_REQUEST = 'GET_ROLE_LIST_REQUEST';
export const GET_ROLE_LIST_SUCCESS = 'GET_ROLE_LIST_SUCCESS';
export const GET_ROLE_LIST_ERROR = 'GET_ROLE_LIST_ERROR';

export const GET_ROLE_USERS_BY_ROLE_ID_REQUEST =
  'GET_ROLE_USERS_BY_ROLE_ID_REQUEST';
export const GET_ROLE_USERS_BY_ROLE_ID_SUCCESS =
  'GET_ROLE_USERS_BY_ROLE_ID_SUCCESS';
export const GET_ROLE_USERS_BY_ROLE_ID_ERROR =
  'GET_ROLE_USERS_BY_ROLE_ID_ERROR';

export const CREATE_ROLE_REQUEST = 'CREATE_ROLE_REQUEST';
export const CREATE_ROLE_SUCCESS = 'CREATE_ROLE_SUCCESS';
export const CREATE_ROLE_ERROR = 'CREATE_ROLE_ERROR';

export const UPDATE_ROLE_REQUEST = 'UPDATE_ROLE_REQUEST';
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS';
export const UPDATE_ROLE_ERROR = 'UPDATE_ROLE_ERROR';

export const GET_PERMS_BY_ROLE_ID_REQUEST = 'GET_PERMS_BY_ROLE_ID_REQUEST';
export const GET_PERMS_BY_ROLE_ID_SUCCESS = 'GET_PERMS_BY_ROLE_ID_SUCCESS';
export const GET_PERMS_BY_ROLE_ID_ERROR = 'GET_PERMS_BY_ROLE_ID_ERROR';

export const DELETE_ROLE_REQUEST = 'DELETE_ROLE_REQUEST';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export const DELETE_ROLE_ERROR = 'DELETE_ROLE_ERROR';

export const GET_ROLE_GROUP_NAMES_REQUEST = 'GET_ROLE_GROUP_NAMES_REQUEST';
export const GET_ROLE_GROUP_NAMES_SUCCESS = 'GET_ROLE_GROUP_NAMES_SUCCESS';
export const GET_ROLE_GROUP_NAMES_ERROR = 'GET_ROLE_GROUP_NAMES_ERROR';
