import React from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { Input, Button, utilities } from '@getir/web-components';
import { email } from '@sideway/address';
import { useDispatch } from 'react-redux';

import COLORS from 'constants/colors';

import * as LoginActions from './actions';
import * as S from './style';

const { noop } = utilities;

const defaultValues = {
  email: '',
};

const ForgotPassword = ({ onBackClick }) => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    mode: 'onBlur',
    defaultValues,
  });

  const validationRules = {
    email: {
      required: 'E-posta adresi boş olamaz.',
      validate: value => {
        const isValidEmail = email.isValid(value);

        return isValidEmail || 'Lütfen geçerli bir email adresi giriniz.';
      },
    },
  };

  const onSubmit = data => {
    dispatch(LoginActions.forgotPasswordRequest(data));
  };

  return (
    <S.LoginForm onSubmit={handleSubmit(onSubmit)}>
      <S.ForgotPasswordFormHeader>
        <S.IconProvider
          name="chevron-left"
          size={10}
          color={COLORS.mainGetirColor}
          format="small"
          onClick={onBackClick}
        />
        <S.FormTitle>Şifremi Unuttum</S.FormTitle>
      </S.ForgotPasswordFormHeader>
      <S.FormBody>
        <div>
          <Controller
            name="email"
            control={control}
            rules={validationRules.email}
            render={({ field }) => (
              <Input
                inputRef={field.ref}
                onChange={field.onChange}
                onBlur={field.onBlur}
                name={field.name}
                defaultValue={field.value}
                placeholder="E-posta adresi"
                header="E-posta adresi"
                hasError={!!errors.email}
              />
            )}
          />
          {errors.email && (
            <S.ErrorMessage>{errors.email.message}</S.ErrorMessage>
          )}
        </div>
      </S.FormBody>
      <S.FormFooter>
        <Button type="submit">Gönder</Button>
      </S.FormFooter>
    </S.LoginForm>
  );
};

ForgotPassword.defaultProps = {
  onBackClick: noop,
};

ForgotPassword.propTypes = {
  onBackClick: PropTypes.func,
};

export default ForgotPassword;
