import React from 'react';
import dayjs from 'dayjs';
import { Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from '@getir/web-components';
import { useForm, Controller } from 'react-hook-form';

import translate from 'utils/translate';
import SelectBoxAntd from 'components/Antd/FormElements/SelectBox';
import * as MainHeaderActions from 'components/MainHeader/actions';
import { rolesWithName } from 'constants/roles';

import { requestTypes } from '../constants/requestTypes';
import * as RequestActions from '../actions';

import * as S from './style';

const requestOptions = [
  {
    value: requestTypes.UPDATE_VENDOR_PRODUCT_PRICE,
    label: translate('requests:updatePrice'),
  },
  {
    value: requestTypes.UPDATE_VENDOR_PRODUCT_STRIKETHROUGH_PRICE,
    label: translate('requests:updateStrikedPrice'),
  },
  {
    value: requestTypes.UPDATE_WORKING_HOURS,
    label: translate('requests:updateWorkingHours'),
  },
  {
    value: requestTypes.UPDATE_BULK_CHANGES,
    label: translate('requests:updateBulkChange'),
  },
];

const dateFormat = 'DD/MM/YYYY';

const Filters = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const cities = useSelector(state => state.mainHeader.cities);
  const requestType = useSelector(state => state.requests.requestType);
  const { handleSubmit, control, reset } = useForm({
    mode: 'onChange',
  });

  const isFieldExist = field => field !== undefined && field !== null;

  const onSubmit = data => {
    const filterData = {
      cityId: data.city?.value,
      operationalManager: data.operationalManager?.value,
      type: data.type && String(data.type),
      status: requestType,
      startDate: isFieldExist(data.date)
        ? dayjs(data?.date[0]).startOf('d').toISOString()
        : undefined,
      endDate: isFieldExist(data.date)
        ? dayjs(data?.date[1]).endOf('d').toISOString()
        : undefined,
    };

    dispatch(RequestActions.requestsListRequest(filterData));
  };

  const handleOnClickClear = () => {
    reset();
    dispatch(MainHeaderActions.setVendor(undefined));
    dispatch(RequestActions.requestsListRequest({}));
  };

  return (
    <S.StyledForm onSubmit={handleSubmit(onSubmit)}>
      <S.InputArea>
        <Controller
          name="date"
          control={control}
          render={({ field }) => (
            <div>
              <span>{t('selectDate')}</span>
              <S.StyledRangePicker
                name={field.name}
                inputRef={field.ref}
                onChange={field.onChange}
                onBlur={field.onBlur}
                value={
                  field.value && [
                    dayjs(field.value[0], dateFormat),
                    dayjs(field.value[1], dateFormat),
                  ]
                }
                placeholder={[t('startDate'), t('endDate')]}
                format={dateFormat}
                size="middle"
              />
            </div>
          )}
        />
        <SelectBoxAntd
          fieldName="city"
          control={control}
          placeholder={t('requests:chooseCity')}
          showSearch
          allowClear
          options={cities}
          labelInValue
        />
        <SelectBoxAntd
          fieldName="type"
          control={control}
          placeholder={t('requests:chooseRequestType')}
          showSearch
          options={requestOptions}
          allowClear
        />
        <SelectBoxAntd
          fieldName="operationalManager"
          control={control}
          placeholder={t('requests:chooseOPM')}
          showSearch
          url="user-service/roles/role/users"
          valueKey="userId"
          labelKey="fullName"
          data={{ roleName: rolesWithName.ADMIN_OPERATIONAL_MANAGER }}
          allowClear
          labelInValue
          reqType="get"
          payloadKey="roleUsers"
        />
      </S.InputArea>

      <S.ActionsArea>
        <Space size="small">
          <Button kind="secondary" onClick={handleOnClickClear}>
            {t('clear')}
          </Button>
          <Button type="submit">{t('filter')}</Button>
        </Space>
      </S.ActionsArea>
    </S.StyledForm>
  );
};

export default Filters;
