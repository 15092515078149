import styled from 'styled-components';
import { Tabs as AntdTabs } from 'antd';

import Table from 'components/v1/Table';
import COLORS from 'constants/colors';
import breakpoint from 'constants/breakpoint';

export const PageHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0;
  width: 100%;
  justify-content: flex-end;
  button {
    font-weight: 600;
  }
  svg {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
`;

export const PageTitle = styled.p`
  margin-bottom: 0;
  font-size: 24px;
`;

export const StyledTable = styled(Table)``;

export const ActiveOrderContent = styled.div`
  @media (max-width: ${breakpoint.MOBILE.MAX}px) {
    .ant-tabs-nav {
      margin: -1rem;
      margin-bottom: 0;
    }
    .ant-tabs-tab {
      height: 60px;
    }
  }
`;

export const TransparentButton = styled.button`
  border: 0px none;
  background: none;
  text-decoration: underline;

  img {
    margin-right: 10px;
  }
`;

export const ActionsArea = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  justify-content: center;
  button {
    padding: 8px;
    width: 36px;
    height: 36px;
  }
  #cancelOrder {
    background-color: #ffefef !important;
  }
`;

export const CompletedOrdersTableHeader = styled.div`
  font-size: 18px;
  color: rgba(0, 0, 0, 0.85);

  span {
    color: ${COLORS.mainGetirColor};
    margin-left: 8px;
    font-weight: 600;
  }
`;

export const CountContainer = styled.span`
  display: flex;
  padding: 1px 6px;
  align-items: center;
  border-radius: 6px;
  background: #f0f1f3;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  color: #191919;
`;

export const Tabs = styled(AntdTabs)`
  .ant-tabs-nav-operations {
    display: none !important;
  }
  .ant-tabs-tab {
    color: #697488;
    margin-left: 16px !important;
    &:hover {
      color: #5d3ebc !important;
      ${CountContainer} {
        background: #f3f0fe !important;
        color: #5d3ebc !important;
      }
    }
  }
  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: #5d3ebc !important;
    }
    ${CountContainer} {
      color: #5d3ebc !important;
      background: #f3f0fe !important;
    }
  }
  .ant-tabs-nav-list {
    &:first-child {
      margin-left: 0px !important;
    }
  }

  .ant-tabs-ink-bar {
    background-color: #5d3ebc;
    height: 3px !important;
    border-radius: 3px 3px 0px 0px !important;
  }
  #delayedOrders {
    background: #ffefef !important;
    color: #d13333 !important;
  }
`;

export const TabContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  border-radius: 8px;
  background: #fff;
  margin-bottom: 32px;
  box-shadow: 0px 2px 4px -1px rgba(28, 55, 90, 0.08),
    0px 3px 12px -1px rgba(28, 52, 84, 0.13);
`;

export const TotalAmountSection = styled.div`
  box-sizing: border-box;
  display: flex;
  padding: 16px 22px;
  align-items: center;
  gap: 10px;
  width: 100%;
  border-radius: 8px;
  background: #f6f6f6;
  position: relative;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  &::before {
    content: '';
    width: 2px;
    height: 30px;
    background: #5d3ebc;
    border-radius: 5px;
    position: absolute;
    left: 10px;
    top: 10px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  height: 100%;
  p {
    margin: 0;
  }
`;
