export const GET_REFERENCE_VENDOR_REQUEST = 'GET_REFERENCE_VENDOR_REQUEST';
export const GET_REFERENCE_VENDOR_SUCCESS = 'GET_REFERENCE_VENDOR_SUCCESS';
export const GET_REFERENCE_VENDOR_ERROR = 'GET_REFERENCE_VENDOR_ERROR';

export const GET_REFERENCE_FIRM_REQUEST = 'GET_REFERENCE_FIRM_REQUEST';
export const GET_REFERENCE_FIRM_SUCCESS = 'GET_REFERENCE_FIRM_SUCCESS';
export const GET_REFERENCE_FIRM_ERROR = 'GET_REFERENCE_FIRM_ERROR';

export const GET_BANK_CODES_REQUEST = 'GET_BANK_CODES_REQUEST';
export const GET_BANK_CODES_SUCCESS = 'GET_BANK_CODES_SUCCESS';
export const GET_BANK_CODES_ERROR = 'GET_BANK_CODES_ERROR';
