import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import calendarPlug from 'dayjs/plugin/calendar';
import { Tooltip } from 'antd';

import Button from 'components/v1/Button';
import * as OrderDetailActions from 'components/OrderDetailModal/actions';
import ElipsisIcon from 'components/v1/Icons/ElipsisIcon';

// import * as ActiveOrderActions from '../actions';
import * as S from '../style';
import('dayjs/locale/tr');
// import ToggleVisibilityButton from '../toggleVisibilityButton';

const useScheduledOrdersColumns = () => {
  const dispatch = useDispatch();
  const modalOpenAction = id => {
    dispatch(OrderDetailActions.getOrderDetailWithActionsRequest(id));
  };
  dayjs.extend(calendarPlug);
  const { t } = useTranslation();
  const dateColumn = text => {
    if (text && text.length) {
      const d = new Date(text);

      return dayjs(d).format('DD.MM.YYYY, H:mm:ss');
    }

    return '';
  };

  const scheduledDateColumn = order => {
    if (order && order.scheduledDate && order.scheduledDateEnd) {
      const d = new Date(order.scheduledDate);
      const dEnd = new Date(order.scheduledDateEnd);
      const dEndText = dayjs(dEnd).format('HH:mm');
      const startDateText = dayjs(d)
        .locale('tr')
        .calendar(null, {
          sameDay: `[${t('common:today')}] H:mm`,
          nextDay: `[${t('common:tomorrow')}] H:mm`,
          nextWeek: 'dddd H:mm',
          lastDay: `[${t('common:yesterday')}] H:mm`,
          lastWeek: 'dddd H:mm',
          sameElse: 'DD.MM.YYYY H:mm',
        });

      return `${startDateText} - ${dEndText}`;
    }

    return '';
  };

  return {
    selectedVendor: undefined,
    columns: [
      {
        title: t('activeOrders:columns.confirmationCode'),
        dataIndex: 'confirmationCode',
        width: 90,
      },
      {
        title: t('activeOrders:columns.vendor'),
        dataIndex: 'vendor',
        render: vendor => (vendor.name ? vendor.name : ''),
      },
      {
        title: t('activeOrders:columns.customer'),
        dataIndex: 'client',
        render: text => {
          const names = text.name ? text.name.split(' ') : [];

          return `${names[0].charAt(0)}*** ${names[1]?.charAt(0)}.`;
        },
      },
      {
        title: t('activeOrders:columns.orderPrice'),
        dataIndex: 'totalPrice',
        render: text => `₺${text.toFixed(2)}`,
      },
      {
        title: t('activeOrders:columns.orderDate'),
        dataIndex: 'paymentDate',
        render: dateColumn,
        width: 120,
      },
      {
        title: t('activeOrders:columns.scheduledDeliveryTime'),
        dataIndex: 'scheduledDate',
        width: 200,
        defaultSortOrder: 'ascend',
        sorter: (a, b) =>
          dayjs(a.scheduledDate).unix() - dayjs(b.scheduledDate).unix(),
        render: (_, record) => scheduledDateColumn(record),
      },
      {
        title: t('activeOrders:columns.status'),
        dataIndex: 'status',
        render: () => t(`common:orderStatuses.scheduled`),
      },
      {
        title: t('activeOrders:columns.action'),
        fixed: 'right',
        width: 80,
        render: (text, row) => (
          <S.ActionsArea>
            <Tooltip title={t('common:detail')}>
              <Button kind="secondary" onClick={() => modalOpenAction(row.id)}>
                <ElipsisIcon width={20} height={20} />
              </Button>
            </Tooltip>
          </S.ActionsArea>
        ),
      },
    ],
  };
};

export { useScheduledOrdersColumns };
