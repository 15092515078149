import { getRequest, postRequest } from 'lib/axios';

import api from './apiRoutes';

export const getCitiesApi = args => getRequest(api.getCities, args);
export const getVendorsApi = args => getRequest(api.getVendors, args);
export const updateVendorStatusApi = args => {
  const { vendorId, isOpen } = args;

  return postRequest(api.updateVendorStatus(vendorId, isOpen));
};

export const getWorkingHoursInfoApi = args =>
  getRequest(api.getWorkingHoursInfo(args));

export const getVendorInfoApi = args => getRequest(api.getVendorInfo(args));
export const getVendorOpenStatusApi = args =>
  getRequest(api.getVendorOpenStatus(args));
