import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.h1`
  font-size: 24px;
  font-weight: 600;
  color: #000000;
  margin: 0;
`;

export const SettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  padding: 12px 0;
`;

export const Divider = styled.div`
  width: 1px;
  background-color: #000000;
  opacity: 0.1;
`;

export const CreateContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  button {
    width: 100%;
    max-width: 200px;
    align-self: flex-end;
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 8px;
  .ant-picker {
    width: 100%;
    height: 36px;
  }
  button {
    width: 100%;
    max-width: 200px;
    align-self: flex-start;
  }
`;

export const FilterLabel = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  align-self: flex-start;
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
  justify-content: center;
  align-items: center;
`;
