import { createAction } from 'utils/createAction';

import * as Types from './types';

export const getMapModalRequest = createAction(Types.MAPMODAL_REQUEST);
export const getMapModalSuccess = createAction(Types.MAPMODAL_SUCCESS);
export const getMapModalError = createAction(Types.MAPMODAL_ERROR);
export const resetMapModal = createAction(Types.MAPMODAL_RESET);

export const getMapModalVendorRequest = createAction(
  Types.MAPMODAL_VENDOR_REQUEST,
);
export const getMapModalVendorSuccess = createAction(
  Types.MAPMODAL_VENDOR_SUCCESS,
);
export const getMapModalVendorError = createAction(Types.MAPMODAL_VENDOR_ERROR);
