import { takeLatest, call, put } from 'redux-saga/effects';

import { getProductsApi, updatePriceApi } from 'services/BulkChange/api';
import { createStandardSaga } from 'utils/createStandardSaga';
import { open } from 'utils/notification';
import translate from 'utils/translate';
import * as LoaderActions from 'components/Loader/actions';

import * as Types from './types';
import * as BulkChangeActions from './actions';

const getProductsSaga = createStandardSaga({
  callApi: getProductsApi,
  successAction: BulkChangeActions.getProductsSuccess,
  errorAction: BulkChangeActions.getProductsError,
});

function* updatePriceSaga({ payload }) {
  yield put(LoaderActions.showLoader());

  try {
    const result = yield call(updatePriceApi, payload);

    if (result.data.payload.success === false) {
      yield put(BulkChangeActions.cannotUpdatePrice(result.data.payload));
      yield put(LoaderActions.hideLoader());

      return;
    }
    if (result.data) {
      yield put(BulkChangeActions.updateProductSuccess(result.data.payload));

      open(
        'success',
        translate('common:notif.successTitle'),
        translate('common:notif.successContent'),
      );
    }
  } catch (error) {
    yield put(BulkChangeActions.updateProductError(error.message));
  }
  yield put(LoaderActions.hideLoader());
}

export function* rootSaga() {
  yield takeLatest(Types.GET_PRODUCTS_REQUEST, getProductsSaga);
  yield takeLatest(Types.UPDATE_PRICE_REQUEST, updatePriceSaga);
}

export default rootSaga;
