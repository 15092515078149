import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Divider, utilities } from '@getir/web-components';
import { useDispatch, useSelector } from 'react-redux';

import InputWrapper from 'components/InputWrapper';
import SelectBoxWrapper from 'components/Antd/FormElements/SelectBox';
import * as RolesActions from 'containers/UsersPerms/Roles/actions';

import * as UsersActions from '../../actions';

import * as S from './style';
import { validations } from './validations';

const { noop } = utilities;

const AddNewUserModal = ({ onClose, ...props }) => {
  const { t } = useTranslation();
  const validationRules = validations(t);
  const dispatch = useDispatch();
  const { data: roleList } = useSelector(state => state.authRoles.roleList);

  useEffect(() => {
    dispatch(RolesActions.getRoleListRequest());
  }, [dispatch]);

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    mode: 'onBlur',
  });

  const onSubmit = formData => {
    const { email, fullName, roles } = formData;
    const data = {
      email,
      fullName,
      roleIds: roles,
    };
    dispatch(UsersActions.createUserRequest(data));
    handleOnClose();
  };

  const handleOnClose = () => {
    onClose();
    reset();
  };

  return (
    <S.StyledModal name="ADD_NEW_USER" onCancel={handleOnClose} {...props}>
      <Modal.Header>{t('authUsersPage:addNewUserModal.title')}</Modal.Header>
      <Modal.Body>
        <S.FormBody>
          <S.NewUserModalForm onSubmit={handleSubmit(onSubmit)}>
            <InputWrapper
              fieldName="fullName"
              control={control}
              rules={validationRules.fullName}
              placeholder={t(`authUsersPage:addNewUserModal.name`)}
              header={t(`authUsersPage:addNewUserModal.name`)}
              errors={errors}
            />
            <InputWrapper
              fieldName="email"
              control={control}
              rules={validationRules.email}
              placeholder={t(`authUsersPage:addNewUserModal.email`)}
              header={t(`authUsersPage:addNewUserModal.email`)}
              errors={errors}
            />
            <SelectBoxWrapper
              fieldName="roles"
              control={control}
              rules={validationRules.role}
              placeholder={t(`authUsersPage:addNewUserModal.role`)}
              header={t(`authUsersPage:addNewUserModal.role`)}
              errors={errors}
              options={
                roleList &&
                roleList.map(role => ({
                  value: role.roleId,
                  label: role.roleName,
                }))
              }
              mode="multiple"
            />
            <Divider />
            <S.FormFooter>
              <Button type="submit">{t('save')}</Button>
            </S.FormFooter>
          </S.NewUserModalForm>
        </S.FormBody>
      </Modal.Body>
    </S.StyledModal>
  );
};

AddNewUserModal.propTypes = {
  onClose: PropTypes.func,
};

AddNewUserModal.defaultProps = {
  onClose: noop,
};

export default AddNewUserModal;
