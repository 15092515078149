import React from 'react';

import { GeoMapStateKey } from '../Common/common';
import GeoMap from '../Common/GeoMap';

import * as BanGeoMapActions from './actions';

const BanGeoMap = () => (
  <GeoMap
    geomapStateKey={GeoMapStateKey.BAN_GEO_MAP}
    GeomapActions={{
      geomapGetRequest: BanGeoMapActions.banGeomapGetRequest,
      geomapSaveRequest: BanGeoMapActions.banGeomapSaveRequest,
      setGeomapInterval: BanGeoMapActions.banGeomapSetInterval,
      setGeomapBrand: BanGeoMapActions.banGeomapSetBrand,
      setGeomapCity: BanGeoMapActions.banGeomapSetCity,
      setGeomapCenter: BanGeoMapActions.banGeomapSetCenter,
      geomapBannedVendorsRequest:
        BanGeoMapActions.banGeomapGetBannedVendorsRequest,
      resetGeomapState: BanGeoMapActions.banGeomapResetState,
      setSelectedVendor: BanGeoMapActions.banGeomapSetSelectedVendor,
      setNeighbourhoodisVisible: BanGeoMapActions.setNeighbourhoodisVisible,
      setEditedMap: BanGeoMapActions.banGeomapSetEditedMap,
      setShowOnlySelectedVendor:
        BanGeoMapActions.banGeomapSetShowOnlySelectedVendor,
      setSelectedDistrict: BanGeoMapActions.banGeomapSetSelectedDistrict,
      geomapGetVendors: BanGeoMapActions.banGeomapGetVendorsRequest,
      geomapGetDistrictRequest: BanGeoMapActions.banGeomapGetDistrictRequest,
      geomapGetNeighbourhoodRequest:
        BanGeoMapActions.banGeomapGetNeighbourhoodRequest,
      geomapGetNeighbourhoodSuccess:
        BanGeoMapActions.banGeomapGetNeighbourhoodSuccess,
    }}
  />
);

export default BanGeoMap;
