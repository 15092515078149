import { createAction } from 'utils/createAction';

import * as Types from './types';

export function changePasswordRequest(data) {
  return {
    type: Types.CHANGE_PASSWORD_REQUEST,
    payload: data,
  };
}

export function changePasswordSuccess(data) {
  return {
    type: Types.CHANGE_PASSWORD_SUCCESS,
    payload: data,
  };
}

export function changePasswordError(err) {
  return {
    type: Types.CHANGE_PASSWORD_ERROR,
    payload: err,
  };
}

export function logoutRequest(data) {
  return {
    type: Types.LOGOUT_REQUEST,
    payload: data,
  };
}

export function logoutSuccess(data) {
  return {
    type: Types.LOGOUT_SUCCESS,
    payload: data,
  };
}

export function logoutError(err) {
  return {
    type: Types.LOGOUT_ERROR,
    payload: err,
  };
}

// change user role
export const changeUserRoleRequest = createAction(
  Types.CHANGE_USER_ROLE_REQUEST,
);
export const changeUserRoleSuccess = createAction(
  Types.CHANGE_USER_ROLE_SUCCESS,
);
export const changeUserRoleError = createAction(Types.CHANGE_USER_ROLE_ERROR);

// get roles by userid
export const getRolesByUserIdRequest = createAction(
  Types.GET_ROLES_BY_USER_ID_REQUEST,
);
export const getRolesByUserIdSuccess = createAction(
  Types.GET_ROLES_BY_USER_ID_SUCCESS,
);
export const getRolesByUserIdError = createAction(
  Types.GET_ROLES_BY_USER_ID_ERROR,
);
