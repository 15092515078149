import { getRequest, postRequest, putRequest, deleteRequest } from 'lib/axios';

import api from './apiRoutes';

export const listFirmsApi = args => getRequest(api.listFirms, args);

export const addFirmsApi = args => postRequest(api.addFirm, args);

export const updateFirmsApi = args => putRequest(api.updateFirm, args);

export const deleteFirmsApi = args => deleteRequest(api.deleteFirm(args));

export const getFirmInfoApi = args => getRequest(api.getFirmInfo(args));

export const getBankCodesApi = args => getRequest(api.getBankCodes, args);
