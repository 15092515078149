import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, useWatch, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import * as CS from 'common/style';
import Layout from 'components/Layout';
import InputWrapper from 'components/InputWrapper';
import SelectBoxAntd from 'components/Antd/FormElements/SelectBox';
import ConfirmModal from 'components/ConfirmModal';
import allowOnlyNumber from 'utils/allowOnlyNumber';
import Uploader from 'components/Uploader';
import apiRoutes from 'services/AddFinancialTransaction/apiRoutes';
import DatePicker from 'components/v1/DatePicker';

import { validations } from './validationRules';
import * as S from './style';
import * as AddFinancialTransactionActions from './actions';

const AddFinancialTransaction = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const vendorInfo = useSelector(state => state.mainHeader.vendorInfo);
  const confirmModalRef = useRef();
  const premiumFileModalRef = useRef();
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [isPremiumFileModalVisible, setIsPremiumFileModallVisible] = useState(
    false,
  );

  const validationRules = validations(t);

  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    reset,
    resetField,
    setValue,
  } = useForm({
    mode: 'onChange',
  });

  const selectedTransactionType = useWatch({
    control,
    name: 'transactionType',
  });

  const transactionTypeValues = {
    COMMISSION_ENTER: 0,
    COMMISSION_OUT: 1,
    PREMIUM: 2,
    BONUS_ENTER: 4,
    BONUS_OUT: 5,
    EXPENSE_ENTER: 6,
    EXPENSE_OUT: 7,
    PAYMENT_ENTER: 8,
    PAYMENT_OUT: 9,
  };

  const transactionTypes = [
    {
      value: transactionTypeValues.COMMISSION_ENTER,
      label: t('addFinancialTransaction:commissionEnter'),
    },
    {
      value: transactionTypeValues.COMMISSION_OUT,
      label: t('addFinancialTransaction:commissionOut'),
    },
    {
      value: transactionTypeValues.BONUS_ENTER,
      label: t('addFinancialTransaction:bonusEnter'),
    },
    {
      value: transactionTypeValues.BONUS_OUT,
      label: t('addFinancialTransaction:bonusOut'),
    },
    {
      value: transactionTypeValues.EXPENSE_ENTER,
      label: t('addFinancialTransaction:expenseEnter'),
    },
    {
      value: transactionTypeValues.EXPENSE_OUT,
      label: t('addFinancialTransaction:expenseOut'),
    },
    {
      value: transactionTypeValues.PAYMENT_ENTER,
      label: t('addFinancialTransaction:paymentEnter'),
    },
    {
      value: transactionTypeValues.PAYMENT_OUT,
      label: t('addFinancialTransaction:paymentOut'),
    },
    {
      value: transactionTypeValues.PREMIUM,
      label: t('addFinancialTransaction:premiumPayment'),
    },
  ];

  useEffect(() => {
    resetField('amount');
    resetField('note');
    // eslint-disable-next-line
  }, [selectedTransactionType]);

  const handleAddTransactionModalSave = () => {
    const values = getValues();
    const { amount, note, transactionType, documentDate } = values;
    const data = {
      amount: Number(amount),
      note,
      transactionType,
      vendorId: vendorInfo.id,
      documentDate: dayjs(documentDate).toISOString(),
    };
    dispatch(AddFinancialTransactionActions.addTransactionsRequest(data));
    setIsConfirmModalVisible(false);
    reset();
  };

  const handleAddTransactionModalClose = () => {
    setIsConfirmModalVisible(false);
  };

  const onSubmit = () => {
    if (getValues('transactionType') !== transactionTypeValues.PREMIUM) {
      setIsConfirmModalVisible(true);

      return;
    }
    if (getValues('transactionType') === transactionTypeValues.PREMIUM) {
      setIsPremiumFileModallVisible(true);
    }
  };

  const setFile = url => {
    setValue('premiumFile', url, {
      shouldValidate: true,
    });
  };

  const removeFile = () => {
    setValue('premiumFile', undefined, {
      shouldValidate: true,
    });
  };

  const handlePremiumFileModalSave = () => {
    const { premiumFile, note, documentDate } = getValues();
    const data = {
      bulkPremiumId: premiumFile,
      note,
      documentDate: dayjs(documentDate).toISOString(),
    };
    dispatch(
      AddFinancialTransactionActions.savePremiumTransactionsRequest(data),
    );
    setIsPremiumFileModallVisible(false);
    reset();
  };

  const handlePremiumFileModalClose = () => {
    setIsPremiumFileModallVisible(false);
  };

  const confirmModalContent = () => {
    const transactionType = getValues('transactionType');

    if (
      [
        transactionTypeValues.COMMISSION_OUT,
        transactionTypeValues.BONUS_ENTER,
        transactionTypeValues.PAYMENT_ENTER,
        transactionTypeValues.EXPENSE_ENTER,
      ].includes(transactionType)
    ) {
      return t('addFinancialTransaction:receivableWarnMessage');
    }

    return t('addFinancialTransaction:debitWarnMessage');
  };

  return (
    <Layout>
      <CS.PageHeader>
        <CS.PageTitle>{t('addFinancialTransaction:pageTitle')}</CS.PageTitle>
      </CS.PageHeader>
      <CS.PageBody>
        <S.StyledForm onSubmit={handleSubmit(onSubmit)}>
          <SelectBoxAntd
            fieldName="transactionType"
            data-testid="transactionType"
            control={control}
            rules={validationRules.financialType}
            placeholder={t('addFinancialTransaction:lblFinancialTransaction')}
            errors={errors}
            options={transactionTypes}
          />
          {getValues('transactionType') !== transactionTypeValues.PREMIUM && (
            <InputWrapper
              fieldName="amount"
              data-testid="amount"
              control={control}
              rules={validationRules.amount}
              placeholder={t('addFinancialTransaction:lblAmount')}
              header={t('addFinancialTransaction:lblAmount')}
              onKeyPress={allowOnlyNumber}
              errors={errors}
            />
          )}
          <InputWrapper
            fieldName="note"
            data-testid="note"
            control={control}
            rules={validationRules.note}
            placeholder={t('addFinancialTransaction:lblNote')}
            header={t('addFinancialTransaction:lblNote')}
            errors={errors}
          />
          <label>{t('financialTransactions:documentDate')}</label>
          <Controller
            name="documentDate"
            control={control}
            rules={validationRules.documentDate}
            defaultValue={dayjs()}
            render={({ field }) => (
              <DatePicker
                size="large"
                data-testid="documentDate"
                disabledDate={date => date.isAfter(dayjs())}
                allowClear={false}
                format="DD.MM.YYYY"
                {...field}
              />
            )}
          />
          {getValues('transactionType') === transactionTypeValues.PREMIUM && (
            <>
              <Controller
                name="premiumFile"
                control={control}
                rules={validationRules.premiumFile}
                render={({ field }) => (
                  <Uploader
                    style={{ marginBottom: 0, padding: '2px' }}
                    name="csvFile"
                    data-testid="premiumFile"
                    accept={['text/csv']}
                    uploadUrl={apiRoutes.uploadPremiumFile}
                    onSuccess={setFile}
                    onRemove={removeFile}
                    multiple={false}
                    field={field}
                    errorMessage={t('addFinancialTransaction:premiumFileError')}
                    errors={errors}
                    fieldName="premiumFile"
                    payloadKey="id"
                    listType="text"
                  />
                )}
              />
              {errors.premiumFile && (
                <CS.ErrorMessage>{errors.premiumFile.message}</CS.ErrorMessage>
              )}
            </>
          )}

          <CS.GetirStyledButton
            htmlType="submit"
            data-testid="save"
            disabled={
              getValues('transactionType') !== transactionTypeValues.PREMIUM &&
              !vendorInfo.id
            }
          >
            {t('save')}
          </CS.GetirStyledButton>
        </S.StyledForm>
      </CS.PageBody>
      <ConfirmModal
        modalRef={confirmModalRef}
        size="400px"
        visible={isConfirmModalVisible}
        onSave={handleAddTransactionModalSave}
        onClose={handleAddTransactionModalClose}
        title={t('addFinancialTransaction:confirmModalTitle')}
        content={confirmModalContent()}
        cancelText={t('cancel')}
      />
      <ConfirmModal
        modalRef={premiumFileModalRef}
        size="400px"
        visible={isPremiumFileModalVisible}
        onSave={handlePremiumFileModalSave}
        onClose={handlePremiumFileModalClose}
        title={t('addFinancialTransaction:confirmModalTitle')}
        content={t('addFinancialTransaction:premiumFileModalContent')}
        cancelText={t('cancel')}
      />
    </Layout>
  );
};

export default AddFinancialTransaction;
