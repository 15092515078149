import _, { noop } from 'lodash';

import { SelectAllOption } from 'constants/selectAllOption';

export const CommonActionMethodNames = {
  geomapGetRequest: 'geomapGetRequest',
  geomapSaveRequest: 'geomapSaveRequest',
  geomapGetDistrictRequest: 'geomapGetDistrictRequest',
  setGeomapInterval: 'setGeomapInterval',
  setGeomapBrand: 'setGeomapBrand',
  setGeomapCity: 'setGeomapCity',
  setGeomapCenter: 'setGeomapCenter',
  geomapBannedVendorsRequest: 'geomapBannedVendorsRequest',
  resetGeomapState: 'resetGeomapState',
  geomapGetNeighbourhoodRequest: 'geomapGetNeighbourhoodRequest',
  setSelectedVendor: 'setSelectedVendor',
  setNeighbourhoodisVisible: 'setNeighbourhoodisVisible',
  setEditedMap: 'setEditedMap',
  setShowOnlySelectedVendor: 'setShowOnlySelectedVendor',
  setSelectedDistrict: 'setSelectedDistrict',
  geomapGetVendors: 'geomapGetVendors',
  geomapGetNeighbourhoodSuccess: 'geomapGetNeighbourhoodSuccess',
};

export const GeoMapStateKey = {
  UPLOAD_GEO_MAP: 'uploadGeoMap',
  BAN_GEO_MAP: 'banGeoMap',
};

/**
 * @typedef {(...args: any[]) => any} ActionCreator
 * @type {{
 *  geomapGetRequest: ActionCreator,
 *  geomapSaveRequest: ActionCreator,
 *  setGeomapInterval: ActionCreator,
 *  setGeomapBrand: ActionCreator,
 *  setGeomapCity: ActionCreator,
 *  setGeomapCenter: ActionCreator,
 *  geomapBannedVendorsRequest: ActionCreator,
 *  resetGeomapState: ActionCreator,
 * geomapGetNeighbourhoodRequest: ActionCreator,
 * setSelectedVendor: ActionCreator,
 * setNeighbourhoodisVisible: ActionCreator,
 * setEditedMap: ActionCreator,
 * setShowOnlySelectedVendor : ActionCreator,
 * geomapGetDistrictRequest: ActionCreator,
 * geomapGetVendors: ActionCreator,
 * }}
 */
export const defaultGeomapActions = Object.keys(CommonActionMethodNames).reduce(
  (actions, methodName) => ({
    ...actions,
    [methodName]: noop,
  }),
  {},
);

export const getGeoMapIntervalOptions = (t, GeoMapInterval) => [
  {
    label: t('intervalType.mainHours'),
    value: GeoMapInterval.MAIN_HOURS,
  },
  {
    label: t('intervalType.alternativeHours'),
    value: GeoMapInterval.ALTERNATIVE_HOURS,
  },
];

export const filterMultiSelectOptions = options =>
  options.filter(option => option.value !== SelectAllOption.value);

export const reverseCoordinates = coordinates => {
  const tempCoordinates = [];
  _.map(coordinates[0], coord => {
    tempCoordinates.push([coord[1], coord[0]]);
  });

  return [tempCoordinates];
};
