import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import NotFound from 'containers/NotFound';
import { routesWithPerms } from 'constants/routesWithPerms';
import * as LoaderActions from 'components/Loader/actions';

const AuthorizedRoute = ({ component, path, ...rest }) => {
  const dispatch = useDispatch();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const { data: allPerms } = useSelector(state => state.login.allPerms);

  useEffect(() => {
    const notFoundComponent = document.querySelector('.not-found-component');
    dispatch(
      allPerms ? LoaderActions.hideLoader() : LoaderActions.showLoader(),
    );
    if (notFoundComponent) {
      notFoundComponent.style.display = allPerms ? 'block' : 'none';
    }
  }, [allPerms]); // eslint-disable-line

  useEffect(() => {
    if (allPerms) {
      const currentPerm = routesWithPerms.find(perm => perm.routes === path);
      const result = allPerms.some(
        perm => perm.componentId === currentPerm?.componentId,
      );
      setIsAuthorized(result);
    }
  }, [allPerms, path]);

  return (
    <Route
      {...rest}
      path={path}
      component={isAuthorized ? component : NotFound}
    />
  );
};

AuthorizedRoute.propTypes = {
  component: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
};

export default AuthorizedRoute;
