import Snippets from './Snippets';

const TagManager = {
  gtm(args) {
    const snippets = Snippets.tags(args);

    const noScript = () => {
      const noscript = document.createElement('noscript');
      noscript.innerHTML = snippets.iframe;

      return noscript;
    };

    const script = () => {
      const DOMscript = document.createElement('script');
      DOMscript.innerHTML = snippets.script;

      return DOMscript;
    };

    return {
      noScript,
      script,
    };
  },
  initialize({ gtmId }) {
    const gtm = this.gtm({
      id: gtmId,
    });

    document.head.insertBefore(gtm.script(), document.head.childNodes[0]);
    document.body.insertBefore(gtm.noScript(), document.body.childNodes[0]);
  },
};

export default TagManager;
