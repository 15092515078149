import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/v1/Button';

import { AlertIcon, InfoIcon, SuccessIcon } from '../Icons';

import * as S from './styles';

const Info = ({ children, kind, isCloseable }) => {
  const [isVisible, setIsVisible] = useState(true);
  const icon = useMemo(() => {
    switch (kind) {
      case 'primary':
      case 'info':
      case 'warning':
        return <InfoIcon fill="white" />;
      case 'success':
        return <SuccessIcon fill="white" />;
      case 'alert':
        return <AlertIcon fill="white" />;
      default:
        return null;
    }
  }, [kind]);

  return isVisible ? (
    <S.InfoContainer $kind={kind}>
      <S.InfoIconContainer>{icon}</S.InfoIconContainer>
      <S.ContentContainer>
        <S.ContentText>{children}</S.ContentText>
        {isCloseable && (
          <Button onClick={() => setIsVisible(false)} kind="secondary">
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.603498 0.603437C0.917713 0.289222 1.42716 0.289222 1.74137 0.603437L6.00002 4.86209L10.2587 
                0.603437C10.5729 0.289222 11.0823 0.289222 11.3965 0.603437C11.7108 0.917652 11.7108 1.4271 11.3965 1.74131L7.13789
                 5.99996L11.3965 10.2586C11.7108 10.5728 11.7108 11.0823 11.3965 11.3965C11.0823 11.7107 10.5729 11.7107 10.2587 
                 11.3965L6.00002 7.13783L1.74137 11.3965C1.42716 11.7107 0.917713 11.7107 0.603498 11.3965C0.289283 11.0823 0.289283
                  10.5728 0.603498 10.2586L4.86215 5.99996L0.603498 1.74131C0.289283 1.42709 0.289283 0.917652 0.603498 0.603437Z"
                fill="#191919"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.461264 0.461203C0.854032 0.0684338 1.49084 0.0684338 
                1.8836 0.461203L6.00002 4.57762L10.1164 0.461203C10.5092 0.0684344
                 11.146 0.0684343 11.5388 0.461203C11.9315 0.853972 11.9315 1.49078 
                 11.5388 1.88354L7.42236 5.99996L11.5388 10.1164C11.9315 10.5091 11.9315
                  11.1459 11.5388 11.5387C11.146 11.9315 10.5092 11.9315 10.1164 11.5387L6.00002
                   7.4223L1.8836 11.5387C1.49084 11.9315 0.854032 11.9315 0.461264 11.5387C0.0684948 
                   11.1459 0.0684948 10.5091 0.461264 10.1164L4.57768 5.99996L0.461264 1.88354C0.0684948 
                   1.49078 0.0684948 0.853971 0.461264 0.461203ZM1.59914 0.745671C1.36348 0.51001 0.981393
                    0.51001 0.745732 0.745671C0.510071 0.981332 0.510071 1.36341 0.745732 1.59908L5.00438
                     5.85772C5.0421 5.89545 5.0633 5.94661 5.0633 5.99996C5.0633 6.05331 5.0421 6.10447 5.00438 
                     6.14219L0.745732 10.4008C0.510071 10.6365 0.510071 11.0186 0.745732 11.2542C0.981393 11.4899
                      1.36348 11.4899 1.59914 11.2542L5.85779 6.9956C5.93634 6.91705 6.0637 6.91705 6.14225 6.9956L10.4009
                       11.2542C10.6366 11.4899 11.0186 11.4899 11.2543 11.2542C11.49 11.0186 11.49 10.6365 11.2543 10.4008L6.99566 
                       6.14219C6.91711 6.06364 6.91711 5.93628 6.99566 5.85772L11.2543 1.59908C11.49 1.36341 11.49 0.981333 11.2543
                        0.745671C11.0186 0.51001 10.6366 0.51001 10.4009 0.745671L6.14225
                        5.00432C6.0637 5.08287 5.93634 5.08287 5.85779 5.00432L1.59914 0.745671Z"
                fill="#191919"
              />
            </svg>
          </Button>
        )}
      </S.ContentContainer>
    </S.InfoContainer>
  ) : (
    false
  );
};

Info.propTypes = {
  children: PropTypes.node.isRequired,
  kind: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'alert']),
  isCloseable: PropTypes.bool,
};

Info.defaultProps = {
  kind: 'primary',
  isCloseable: false,
};

export default Info;
