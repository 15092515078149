import React, { useState, useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/v1/Button';

import { Link } from '../MenuIcons';

import * as S from './styles';

const LinkExt = ({ editor }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toolRef = useRef(null);
  const buttonRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const close = e => {
      if (
        !toolRef.current?.contains(e.target) &&
        !buttonRef.current?.contains(e.target)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener('click', close);

    return () => document.removeEventListener('click', close);
  }, []);

  const addLink = useCallback(() => {
    const url = inputRef.current?.value;

    // cancelled
    if (url === null) {
      return;
    }

    // empty
    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink().run();

      return;
    }

    // update link
    editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
  }, [editor]);

  return (
    <S.ButtonContainer
      onClick={() => setIsOpen(!isOpen)}
      type="button"
      className="is-active"
      ref={buttonRef}
      disabled={!editor.can().chain().focus().toggleBold().run()}
      style={{
        position: 'relative',
        width: 24,
        height: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: 'none',
        backgroundColor: 'transparent',
        padding: 0,
      }}
    >
      <Link />
      {isOpen && (
        <S.ToolTipContainer
          ref={toolRef}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
          style={{
            position: 'absolute',
            top: 24,
            left: 0,
            backgroundColor: 'white',
            border: '1px solid #D4D4D4',
            borderRadius: 4,
            boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
            padding: 8,
            zIndex: 100,
            width: 'auto',
            display: 'flex',
            gap: 4,
            flexDirection: 'column',
            whiteSpace: 'nowrap',
          }}
        >
          <S.Title>Link URL:</S.Title>
          <S.Input ref={inputRef} type="text" />
          <Button kind="secondary" onClick={addLink}>
            Ekle
          </Button>
        </S.ToolTipContainer>
      )}
    </S.ButtonContainer>
  );
};

LinkExt.propTypes = {
  editor: PropTypes.object.isRequired,
};

export default LinkExt;
