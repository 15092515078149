import React from 'react';
import { Empty } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import * as S from './style';

const RolePerms = ({ perms }) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t('authRolesPage:perms.operation'),
      key: 'title',
      dataIndex: 'title',
    },
    {
      title: t('authRolesPage:perms.permission'),
      key: 'componentDesc',
      dataIndex: 'componentDesc',
    },
  ];

  return (
    <S.StyledTable
      title={() => t('authRolesPage:perms.title')}
      dataSource={perms}
      columns={columns}
      bordered
      size="small"
      locale={{ emptyText: <Empty description={t('table.noData')} /> }}
    />
  );
};

RolePerms.propTypes = {
  perms: PropTypes.array,
};

RolePerms.defaultProps = {
  perms: [],
};

export default RolePerms;
