import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Divider } from '@getir/web-components';
import { useDispatch, useSelector } from 'react-redux';
import { Space } from 'antd';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import * as CS from 'common/style';
import * as ROUTES from 'constants/routes';
import Layout from 'components/Layout';
import InputWrapper from 'components/InputWrapper';
import ArrowBackButton from 'components/ArrowBackButton';
import SelectBoxWrapper from 'components/Antd/FormElements/SelectBox';

import RoleUsers from '../components/RoleUsers';
import RolePerms from '../components/RolePerms';
import * as RolesActions from '../actions';

import * as S from './style';
import { validations } from './validations';

const Edit = () => {
  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const validationRules = validations(t);

  const { data: roleUsers } = useSelector(state => state.authRoles.roleUsers);
  const { data: permList } = useSelector(state => state.authRoles.permList);
  const { data: roleGroupNames } = useSelector(
    state => state.authRoles.roleGroupNames,
  );
  useEffect(() => {
    dispatch(RolesActions.getRoleUserByRoleIdRequest(id));
    dispatch(RolesActions.getPermsByRoleIdRequest(id));
  }, [dispatch, id]);

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    mode: 'onBlur',
  });

  useEffect(() => {
    dispatch(RolesActions.getRoleGroupNamesRequest());
  }, [dispatch]);

  useEffect(() => {
    const { state } = location;
    if (state) {
      const { role } = state;
      const { roleDesc, roleName, roleGroupName } = role;
      setValue('roleName', roleName);
      setValue('roleDesc', roleDesc);
      setValue('roleGroupNames', roleGroupName);
    }
  }, [location, setValue]);

  const onSubmit = formData => {
    const data = {
      id,
      ...formData,
    };
    dispatch(RolesActions.updateRoleRequest(data));
  };

  const handleOnClose = () => {
    history.push(ROUTES.AUTH_ROLES);
  };

  return (
    <Layout>
      <CS.PageHeader>
        <Space>
          <ArrowBackButton route={ROUTES.AUTH_ROLES} size={14} />
          <CS.PageTitle>{t('authRolesPage:editRolePage.title')}</CS.PageTitle>
        </Space>
      </CS.PageHeader>
      <CS.PageBody>
        <S.FormBody>
          <S.NewRoleForm onSubmit={handleSubmit(onSubmit)}>
            <InputWrapper
              fieldName="roleName"
              control={control}
              rules={validationRules.roleName}
              placeholder={t(`authRolesPage:editRolePage.roleName`)}
              header={t(`authRolesPage:editRolePage.roleName`)}
              errors={errors}
            />
            <InputWrapper
              fieldName="roleDesc"
              control={control}
              rules={validationRules.desc}
              placeholder={t(`authRolesPage:editRolePage.desc`)}
              header={t(`authRolesPage:editRolePage.desc`)}
              errors={errors}
            />
            <SelectBoxWrapper
              fieldName="roleGroupNames"
              control={control}
              rules={validationRules.roleType}
              placeholder={t(`authRolesPage:editRolePage.roleType`)}
              header={t(`authRolesPage:editRolePage.roleType`)}
              errors={errors}
              options={
                roleGroupNames &&
                roleGroupNames.map(role => ({
                  value: role.roleGroupName,
                  label: role.roleGroupName,
                }))
              }
              mode="multiple"
            />
            <Divider />
            <RolePerms perms={permList} />
            <Divider />
            <RoleUsers users={roleUsers?.roleUsers} />
            <Divider />
            <S.FormFooter>
              <Space size="small">
                <Button kind="secondary" onClick={handleOnClose}>
                  {t('giveUp')}
                </Button>
                <Button type="submit">{t('save')}</Button>
              </Space>
            </S.FormFooter>
          </S.NewRoleForm>
        </S.FormBody>
      </CS.PageBody>
    </Layout>
  );
};

export default Edit;
