import { Dropdown } from 'antd';
import styled from 'styled-components';

export const MobileMenuDrop = styled(Dropdown)`
  .ant-dropdown {
    width: 224px !important;
  }
`;

export const MobileMenuWrapper = styled.div`
  border-radius: 8px;
  background: #fff;
  width: 224px;
  box-shadow: 0px 2px 6px -1px rgba(28, 55, 90, 0.16),
    0px 8px 24px -4px rgba(28, 50, 79, 0.38);
  display: flex;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  max-height: 278px;
  overflow: auto;

  div::-webkit-scrollbar {
    display: none;
  }
`;

export const MenuItem = styled.div`
  width: 100%;
  display: flex;
  height: 42px;
  padding: 9px 4px 9px 8px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 4px;
  background: #fff;
  cursor: pointer;
  &:hover {
    background: #f3f0fe80;
  }
  svg {
    width: 18px;
    height: 18px;
    fill: #697488 !important;
  }
  span:not(.anticon) {
    color: #191919;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    flex-grow: 1;
    text-align: left;
  }

  ${({ $isSelected }) =>
    $isSelected &&
    `
        background: #F3F0FE;
        span:not(.anticon) {
            color: #5D3EBC;
        }
  `}
`;

export const SubMenuIcon = styled.div`
  justify-self: flex-end;
`;
