import getRandomString from 'utils/getRandomString';

import * as Types from './types';

const initialState = {
  brand: undefined,
  center: undefined,
  city: undefined,
  interval: undefined,
  map: undefined,
  error: undefined,
  saveSuccessId: undefined,
  neighbourhoods: [],
  districts: [],
  editedMap: undefined,
  selectedVendor: undefined,
  isNeighbourhoodVisible: true,
  showOnlySelectedVendor: false,
  selectedDistrict: undefined,
  vendors: [],
};

function UploadGeomapReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.UPLOAD_GEOMAP_GET_SUCCESS:
      return {
        ...state,
        map: payload,
      };
    case Types.UPLOAD_GEOMAP_GET_VENDORS_SUCCESS:
      return {
        ...state,
        vendors: payload,
      };
    case Types.UPLOAD_GEOMAP_SET_SELECTED_DISTRICT:
      return {
        ...state,
        selectedDistrict: payload,
      };
    case Types.UPLOAD_GEOMAP_GET_DISTRICT_SUCCESS:
      return {
        ...state,
        districts: payload,
      };
    case Types.UPLOAD_GEOMAP_SET_EDITED_MAP:
      return {
        ...state,
        editedMap: payload,
      };
    case Types.SET_NEIGHBOURHOOD_IS_VISIBLE: {
      return {
        ...state,
        isNeighbourhoodVisible: payload,
      };
    }
    case Types.UPLOAD_GEOMAP_SET_SHOW_ONLY_SELECTED_VENDOR:
      return {
        ...state,
        showOnlySelectedVendor: payload,
      };
    case Types.UPLOAD_GEOMAP_GET_NEIGHBOURHOOD_SUCCESS: {
      return {
        ...state,
        neighbourhoods: payload,
      };
    }
    case Types.UPLOAD_GEOMAP_GET_ERROR:
      return {
        ...state,
        map: undefined,
        error: payload,
      };
    case Types.UPLOAD_GEOMAP_SET_CENTER:
      return {
        ...state,
        center: payload,
      };
    case Types.UPLOAD_GEOMAP_SET_SELECTED_VENDOR:
      return {
        ...state,
        selectedVendor: payload,
      };
    case Types.UPLOAD_GEOMAP_SET_CITY:
      return {
        ...state,
        city: payload,
      };
    case Types.UPLOAD_GEOMAP_SET_BRAND:
      return {
        ...state,
        brand: payload,
      };
    case Types.UPLOAD_GEOMAP_SET_INTERVAL:
      return {
        ...state,
        interval: payload,
      };
    case Types.UPLOAD_GEOMAP_SAVE_SUCCESS:
      return {
        ...state,
        saveSuccessId: getRandomString(7),
      };
    case Types.UPLOAD_GEOMAP_RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

export default UploadGeomapReducer;
