import styled from 'styled-components';

import breakpoint from 'constants/breakpoint';

const PdfContent = styled.div`
  canvas {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .annotationLayer {
    height: 0 !important;
  }

  @media (max-width: ${breakpoint.MOBILE.MAX}px) {
    canvas {
      width: 100% !important;
      height: auto !important;
    }
  }
  @media (max-width: ${breakpoint.TABLET.MAX}px) {
    canvas {
      width: 100% !important;
      height: auto !important;
    }
  }
`;

const PaginationArea = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: center;
`;

const PaginationLeftArrow = styled.div`
  margin-right: 8px;
  span {
    margin-right: 5px;
  }
`;

const PaginationRightArrow = styled.div`
  margin-left: 8px;
  span {
    margin-left: 5px;
  }
`;

export {
  PaginationArea,
  PdfContent,
  PaginationLeftArrow,
  PaginationRightArrow,
};
