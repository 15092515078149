import { call, put, select, takeLatest } from 'redux-saga/effects';

import {
  getReportsApi,
  createReportApi,
  getDownloadURLApi,
} from 'services/KuzeydenReports/api';
import { open } from 'utils/notification';
import translate from 'utils/translate';

import * as Types from './types';
import * as ReportsActions from './actions';
import { OWNER_TYPE, REPORT_TYPES_ARRAY } from './constants';
function* getReportsSaga({ payload }) {
  try {
    const result = yield call(getReportsApi, payload);

    if (result.data.payload) {
      yield put(ReportsActions.getReportsSuccess(result.data.payload));
    }
  } catch (error) {
    yield put(ReportsActions.getReportsError(error.message));
  }
}

function* createReportSaga({ payload }) {
  try {
    const result = yield call(createReportApi, {
      body: payload,
    });

    if (result.data.payload) {
      yield put(ReportsActions.createReportSuccess(result.data.payload.report));
      const filters = yield select(state => state.kuzeydenReports.filters);
      const DEFAULT_SELECTED_TYPES = REPORT_TYPES_ARRAY.map(
        ({ value }) => value,
      );

      yield put(
        ReportsActions.getReportsRequest({
          query: {
            limit: filters.paginationOptions.pageSize,
            offset:
              filters.paginationOptions.pageSize *
              (filters.paginationOptions.current - 1),
          },
          body: {
            report: filters.selectedReportType
              ? [filters.selectedReportType]
              : DEFAULT_SELECTED_TYPES,
            ownerType: OWNER_TYPE,
          },
        }),
      );
      open(
        'success',
        translate('common:notif.successTitle'),
        translate('reports:createReportSuccessDesc'),
      );
    }
  } catch (error) {
    yield put(ReportsActions.createReportError(error.message));
  }
}

function* getReportDownloadUrl({ payload }) {
  try {
    const result = yield call(getDownloadURLApi, payload);

    if (result.data.payload) {
      window.open(result.data.payload, '_blank');
    }
  } catch (error) {
    yield put(ReportsActions.createReportError(error.message));
  }
}

export function* rootSaga() {
  yield takeLatest(Types.GET_REPORTS_REQUEST, getReportsSaga);
  yield takeLatest(Types.CREATE_REPORT_REQUEST, createReportSaga);
  yield takeLatest(Types.GET_DOWNLOAD_URL, getReportDownloadUrl);
}

export default rootSaga;
