export const GET_PRODUCTS_REQUEST = 'GET_PRODUCTS_REQUEST';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_ERROR = 'GET_PRODUCTS_ERROR';

export const UPDATE_PRICE_REQUEST = 'UPDATE_PRICE_REQUEST';
export const UPDATE_PRICE_SUCCESS = 'UPDATE_PRICE_SUCCESS';
export const UPDATE_PRICE_ERROR = 'UPDATE_PRICE_ERROR';

export const CANNOT_UPDATE_PRICE = 'CANNOT_UPDATE_PRICE';

export const CANNOT_UPDATE_PRICE_CLEAR = 'CANNOT_UPDATE_PRICE_CLEAR';
