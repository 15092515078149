import { takeLatest, call, put, select, all } from 'redux-saga/effects';

import { createStandardSaga } from 'utils/createStandardSaga';
import {
  closeVendorProductApi,
  editVendorProductApi,
  getVendorProductApi,
  getVendorProductInfoApi,
  listVendorProductsApi,
  openVendorProductApi,
  getCategoriesApi,
} from 'services/VendorProduct/api';
import { getBrandProductApi } from 'services/Brand/api';
import * as ROUTES from 'constants/routes';
import * as LoaderActions from 'components/Loader/actions';
import { open } from 'utils/notification';
import translate from 'utils/translate';
import history from 'utils/history';

import * as Types from './types';
import { vendorInfo } from './selectors';
import * as VendorProductActions from './actions';

const listVendorProductSaga = createStandardSaga({
  callApi: listVendorProductsApi,
  successAction: VendorProductActions.vendorProductListSuccess,
  errorAction: VendorProductActions.vendorProductListError,
});

function* listVendorBrandProductSaga() {
  yield put(LoaderActions.showLoader());
  try {
    const currentVendorInfo = yield select(vendorInfo);
    const result = yield all(
      currentVendorInfo.brands.map(brandId =>
        call(getBrandProductApi, brandId),
      ),
    );
    if (result) {
      // eslint-disable-next-line
      const productList = result.reduce((acc, item) => {
        return [...acc, ...item.data.payload];
      }, []);
      yield put(
        VendorProductActions.vendorProductBrandListSuccess(productList),
      );
    }
  } catch (error) {
    yield put(VendorProductActions.vendorProductBrandListError(error.message));
  }
  yield put(LoaderActions.hideLoader());
}

const openVendorProductSaga = createStandardSaga({
  callApi: openVendorProductApi,
  successAction: VendorProductActions.vendorProductOpenSuccess,
  errorAction: VendorProductActions.vendorProductOpenError,
});

const closeVendorProductSaga = createStandardSaga({
  callApi: closeVendorProductApi,
  successAction: VendorProductActions.vendorProductCloseSuccess,
  errorAction: VendorProductActions.vendorProductCloseError,
});

function* editVendorProductSaga({ payload }) {
  yield put(LoaderActions.showLoader());

  try {
    const result = yield call(editVendorProductApi, payload);
    if (result.data) {
      yield put(
        VendorProductActions.vendorProductEditSuccess(result.data.payload),
      );

      open(
        'success',
        translate('common:notif.successTitle'),
        translate('common:notif.successContent'),
      );

      yield history.push(ROUTES.VENDORPRODUCT);

      // if (result.data.status === 500) {
      //   open(
      //     'warning',
      //     translate('common:notif.warnTitle'),
      //     result.data.payload,
      //   );
      // } else {
      //   open(
      //     'success',
      //     translate('common:notif.successTitle'),
      //     result.data.payload,
      //   );
      //   yield history.push(ROUTES.VENDORPRODUCT);
      // }
    }
  } catch (error) {
    yield put(VendorProductActions.vendorProductEditError(error.message));
  }
  yield put(LoaderActions.hideLoader());
}

const getVendorProductSaga = createStandardSaga({
  callApi: getVendorProductApi,
  successAction: VendorProductActions.vendorProductGetSuccess,
  errorAction: VendorProductActions.vendorProductGetError,
});

const vendorProductInfoSaga = createStandardSaga({
  callApi: getVendorProductInfoApi,
  successAction: VendorProductActions.vendorProductInfoSuccess,
  errorAction: VendorProductActions.vendorProductInfoError,
});

const getCategoriesSaga = createStandardSaga({
  callApi: getCategoriesApi,
  successAction: VendorProductActions.vendorProductCategoriesSuccess,
  errorAction: VendorProductActions.vendorProductCategoriesError,
});

export function* rootSaga() {
  yield takeLatest(Types.VENDORPRODUCT_LIST_REQUEST, listVendorProductSaga);
  yield takeLatest(
    Types.VENDORPRODUCT_BRAND_LIST_REQUEST,
    listVendorBrandProductSaga,
  );
  yield takeLatest(Types.VENDORPRODUCT_OPEN_REQUEST, openVendorProductSaga);
  yield takeLatest(Types.VENDORPRODUCT_CLOSE_REQUEST, closeVendorProductSaga);
  yield takeLatest(Types.VENDORPRODUCT_EDIT_REQUEST, editVendorProductSaga);
  yield takeLatest(Types.VENDORPRODUCT_GET_REQUEST, getVendorProductSaga);
  yield takeLatest(
    Types.VENDORPRODUCT_PRODUCTINFO_REQUEST,
    vendorProductInfoSaga,
  );
  yield takeLatest(Types.VENDORPRODUCT_CATEGORIES_REQUEST, getCategoriesSaga);
}

export default rootSaga;
